import gql from 'graphql-tag';

export const SIGN_UP = gql`
  mutation signUp( $input: signUpInput!) {
    signUp(input:$input) {
      id
      userId
      userType
      userName
      email
      role
      isActive
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser( $input: userInput!) {
    updateUser(input:$input) {
      id
      userId
      userType
      userName
      email
      role
      isActive
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser( $id: ID!) {
    deleteUser(id:$id)
  }
`;


export const UPDATE_PASSWORD = gql`
mutation  updateUserPassword($oldPassword: String,$newPassword: String){
  updateUserPassword(oldPassword:$oldPassword,newPassword:$newPassword)
}
`