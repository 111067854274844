import gql from 'graphql-tag';

export const UPDATELOCATION_SUBSCRIBE = gql`
subscription IssueReturnDetailChange {
  IssueReturnDetailChange {
    keyType
    data{
      id
  issueReturnMasterId
  location
  barcode
  lotNoId{
    id
    lotNo
  }
  pktNoId{
    id
    pktNo
  }
  labId{
    id
    partyName
  }
  certificateNo
  rfId
  cts
  colorId{
    id
    shortName
  }
  shapeId{
    id
    shortName
  }
  purityId{
    id
    shortName
  }
  cutId{
    id
    shortName
  }
  polishId{
    id
    shortName
  }
  symmId{
    id
    shortName
  }
  fluId{
    id
    shortName
  }
  measurment
  gradePer
  rateRap
  stkDiscPer
  rateAfterDisc
  rapAmt
  rateZar
  amtZar
  rateRs
  createdBy
  updatedBy
  createdAt
  updatedAt
    }
  }
}
`;