import React from 'react'
import _ from 'lodash'
import { Grid, InputLabel, Table } from '@material-ui/core';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';

import { AutoSizer, List, CellMeasurer, CellMeasurerCache } from 'react-virtualized';

import { Tofixed } from '../../../../components/Comman/Tofix'
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#8CB836",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 13,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const useStyles = makeStyles(() => ({
    denseTable: {
        '& td': { padding: "2px 5px", cursor: "pointer", textAlign: "center" },
        '& th': { padding: "5px 5px", cursor: "pointer", textAlign: "center" }
    },
    loader: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: window.innerHeight - 540,
        pointerEvents: 'none',
        opacity: 0.5
    }
}))
export const LotNoWise = ({ Data, loading }) => {
    const classes = useStyles();
    const cache = React.useRef(
        new CellMeasurerCache({
            fixedWidth: true,
            defaultHeight: 100
        })
    )
    const ListRef = React.useRef();
    if (loading) {
        return <Grid xs={3} item container className={classes.loader}  ><img src="https://i.gifer.com/4V0b.gif" alt="loading" /></Grid>
    } else
        return <>
            <Grid xs={3} item  >
                <Grid container justify="flex-end">
                    <Grid xs={6} item>
                        <InputLabel style={{ fontSize: 16, paddingBottom: 10, color: "rgb(70, 81, 142)" }}>{"Kapan Wise Stock"}</InputLabel>
                    </Grid>
                    <Grid xs={6} item>
                        <InputLabel style={{ fontSize: 16, paddingBottom: 10, color: "rgb(70, 81, 142)", textAlign: "end" }}>&nbsp;{`Tot Pkts: ${_.sumBy(Data, (d) => d.TotalPkt) || 0} | Cts: ${Tofixed(_.sumBy(Data, (d) => d.TotalCts))}`}</InputLabel>
                    </Grid>
                </Grid>

                <TableContainer component={Paper}>
                    <Table className={classes.denseTable}>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell style={{ width: '50%', textAlign: "center" }} >{"Lot No "}</StyledTableCell>
                                <StyledTableCell style={{ width: '25%', textAlign: "center" }} >{"Total Pkts"}</StyledTableCell>
                                <StyledTableCell style={{ width: '25%', textAlign: "center" }} >{"Total CTS"}</StyledTableCell>
                            </TableRow>
                        </TableHead>
                    </Table>

                    <div style={{ width: "100%", height: 500, marginBottom: 10 }}>
                        <AutoSizer>
                            {({ width, height }) => {
                                return <List
                                    ref={ListRef}
                                    height={height}
                                    width={width}
                                    rowHeight={20}
                                    deferredMeasurementCache={cache.current}
                                    rowCount={Data?.length || 0}
                                    rowRenderer={({ key, index, style, parent }) => {
                                        const row = Data[index]
                                        return <CellMeasurer key={key} cache={cache.current} parent={parent} columnIndex={0} rowIndex={index}>
                                            <div style={style}>
                                                <div className="tbody"  >
                                                    <Table aria-label="customized table" className={classes.denseTable} style={{ padding: 10 }}>
                                                        <TableBody  >
                                                            <StyledTableRow key={key} >
                                                                <StyledTableCell style={{ width: '50%', textAlign: "center" }}>
                                                                    {row?.lotNoId?.lotNo || ""}
                                                                </StyledTableCell>
                                                                <StyledTableCell style={{ width: '25%', textAlign: "center" }}>
                                                                    {row["TotalPkt"]}
                                                                </StyledTableCell>
                                                                <StyledTableCell style={{ width: '25%', textAlign: "center" }}>
                                                                    {Tofixed(row["TotalCts"])}
                                                                </StyledTableCell>
                                                            </StyledTableRow>
                                                        </TableBody>
                                                    </Table>
                                                </div>
                                            </div>
                                        </CellMeasurer>
                                    }}
                                />
                            }}
                        </AutoSizer>
                    </div>
                </TableContainer>

            </Grid>
        </>
}