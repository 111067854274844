/********************* Libray **************** */
import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";

import { Grid, Table, Button, CircularProgress } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { InputLabel } from '@material-ui/core';
import _ from 'lodash'
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks'
import { toast } from 'react-toastify'
/********************* Component Imports **************** */
import { ToDepartment } from '../../../components/DropdownSelect/ToDepartment'
import { ProcessSelect } from '../../../components/DropdownSelect/ProcessSelect'
import { PacketType } from '../../../components/DropdownSelect/PacketType'
import '../../PowerMenu/StockMaster/stock.css'
import { MasterView } from './MasterView'
import { Fromtable } from './FromTable'
import { Totable } from './ToTable'
import { NEWDEPARTMENTISSUERETURN, DELETEDEPARTMENTISSUERETURNDETAIL } from './Mutation'
import { FormatError } from '../../../components/Comman/FormatError'
import { DEPARTMENTISSUERETURNDETAIL } from './Query'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FormControl, TextField } from '@material-ui/core';
import { ALLDEPARTMENT } from '../../Master/Department/DepartmentQuery'
import { packetTypeForDepartmentIssueReturn } from '../../../components/Constant/index'
import { Tofixed } from '../../../components/Comman/Tofix'
import { RoughStockFrom } from './Query'
import { Accordion, AccordionSummary, AccordionDetails } from '../../../components/Comman/Accordian'

const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1, },
  noMargin: { margin: 0 },

  GridLeftSpace: {
    paddingLeft: 5
  },
  GridFillter: {
    textAlign: "end"
  },
  SetAccordianHeade: {
    paddingTop: 0,
    marginTop: "0px !important"
  },
  fitGrid: {
    minHeight: 150,
    maxHeight: 170,
    overflowY: "scroll"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  Select: {
    marginTop: "0px !important",
    paddingTop: 0,
    // paddingRight: 10
  },
  buttonProgress: {
    color: "#f50357",
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  denseTable: {
    '& td': { padding: "2px 5px", textAlign: "center" },
    '& th': { padding: "5px 5px", textAlign: "center" }
  },
  tableHeading: {
    background: '#8CB836 !important',
    color: '#f5f5f5'
  },
  form: {
    background: '#8CB836 !important',
    color: '#f5f5f5',
  },
  returnCtsInput: {
    "& input": {
      padding: 0
    }
  }
}));


function DepartmentIssueReturn(props) {
  const classes = useStyles();
  const [Loader, SetLoader] = useState(false)
  const [FromDepartments, SetFromDepartment] = useState([])
  const [FromDepartmentDatas, SetFromDepartmentDatas] = useState([])
  const [ToDepartmentDatas, SetToDepartmentDatas] = useState([])
  const [FromTableData, SetFromTableData] = useState([])
  const [ToDepartments, SetToDepartment] = useState([])
  const [Process, SetProcess] = useState('')
  const [Totabledatas, SetTotabledatas] = useState([])
  const [PacketTypes, SetPacketType] = useState('')
  const [expanded, setExpanded] = useState(false);
  const [MasterSelectedData, setMasterSelectedData] = useState([]);
  const [toDepartmentTableData, setToDepartmentTableData] = useState([]);
  const [masterTableKey, setMasterTableKey] = useState(Math.ceil(Math.random() * 100));
  // const permissions = props?.session?.me?.role?.permissions;
  // const role = props?.session?.me?.role?.role;
  // const path = props?.location?.pathname;
  // let permission = _.find(permissions, function (o) { return o.name === path; })
  const [AddNewDepartmentIssueReturn] = useMutation(NEWDEPARTMENTISSUERETURN)
  const [DeleteDepartmentIssueReturn] = useMutation(DELETEDEPARTMENTISSUERETURNDETAIL)



  /************************************Todepartment api calls  start*/
  const { loading: toDepartmentLoading, error: toDepartmentError, data: toDepartmentData, refetch: toDepartmentRefetch } = useQuery(DEPARTMENTISSUERETURNDETAIL, {
    variables: { id: MasterSelectedData?.id || null },
    fetchPolicy: 'cache-and-network',
  })
  useEffect(() => {
    SetTotabledatas(toDepartmentData?.getDepartmentIssueReturnDetail || [])
  }, [toDepartmentData]);
  useEffect(() => {
    SetTotabledatas([])
  }, [toDepartmentError]);



  /************************************Todepartment api calls  start*/

  /*********From Table Data **************/
  const { loading, error, data, refetch } = useQuery(RoughStockFrom, {
    variables: { departmentId: FromDepartments?.id || null },
    fetchPolicy: 'cache-and-network',
    // skip use beacause the without FromDepartments.id is null and query call on page
    skip: !FromDepartments?.id,
  })
  useEffect(() => {
    let Data = data?.getRoughStock || []
    if (Data.length !== 0) {
      Data = Data.map(d => {
        d.retCts = d.issueCts
        d.lossCts = 0
        d.extraCts = 0
        return d
      })
    }
    SetFromTableData(Data)
  }, [data])

  useEffect(() => {
    SetFromTableData([])
  }, [error])

  const SaveButtonHandler = (e) => {
    e.stopPropagation();
    if (ToDepartments.length === 0) {
      toast.error('please select todepartment')
    } else if (FromDepartments.length === 0) {
      toast.error('please select fromdepartment')
    } else if (Process === "") {
      toast.error('please select process')
    } else {
      let flag = false
      let TableDataWithId = []
      let NewFromDepartmentDatas = _.cloneDeep(FromDepartmentDatas)
      NewFromDepartmentDatas.map((d) => {
        let SubPktLength = d?.subPkt?.length || 0
        if (SubPktLength !== 0) {
          d.lossCts = d.subPkt[SubPktLength - 1].RemaingCts
        }
        if (d.retCts === 0) {
          flag = true
        }
        let a = d
        delete a.tableData
        a.process = a?.process?.id || null
        a.lotNoId = a?.lotNoId?.id || null
        a.subPktMasterId = a?.pktNoId?.subPktMasterId || a?.pktNoId?.id || null
        a.isSubPkt = a?.pktNoId?.isSubPkt || false
        a.pktNoId = a?.pktNoId?.id || null
        a.purityId = a?.purityId?.id || null
        a.colorId = a?.colorId?.id || null
        a.shapeId = a?.shapeId?.id || null
        a.cutId = a?.cutId?.id || null
        a.polishId = a?.polishId?.id || null
        a.symmId = a?.symmId?.id || null
        a.fluId = a?.fluId?.id || null
        a.jagadNo = a?.returnId?.jagadNo || null
        TableDataWithId.push(a)
        return d
      })
      if (!flag) {
        let newData = {
          process: Process,
          fromDept: FromDepartments.id,
          toDept: ToDepartments.id,
          packetType: PacketTypes,
          data: TableDataWithId
        }
        SetLoader(true)
        AddNewDepartmentIssueReturn({ variables: { input: newData } }).then((data) => {
          toast.success('save sucessfully');
          refetch();
          getToTableDataByDepartment({
            variables: {
              departmentId: ToDepartments.id
            }
          })
          setMasterTableKey(Math.ceil(Math.random() * 100))
          toDepartmentRefetch();
          SetFromDepartmentDatas([])
          SetLoader(false)
        }).catch((e) => {
          SetLoader(false)
          toast.error(FormatError(e))
        })
      } else {
        toast.warn('retCts is not zero(0)')
      }
    }
  }

  const RemoveButtonHandler = (e) => {
    e.stopPropagation();
    return new Promise((resolve, reject) => {
      let newData = [...ToDepartmentDatas]
      SetLoader(true)
      DeleteDepartmentIssueReturn({ variables: { ids: newData } }).then((data) => {
        SetLoader(false)
        toast.success('Remove sucessfully');
        toDepartmentRefetch()
        refetch()
        SetToDepartmentDatas([])
        resolve()
      }).catch((e) => {
        SetLoader(false)
        reject()
        toast.error(FormatError(e))
      })
    })
  }

  /***************End ********************/

  /*********TO Table Data **************/
  const [getToTableDataByDepartment, { loading: TotableLoading, error: TotableError, data: totableData }] = useLazyQuery(RoughStockFrom, {
    variables: { departmentId: ToDepartments?._id || null },
    fetchPolicy: 'cache-and-network',
  })
  useEffect(() => {
    let Data = totableData?.getRoughStock || []
    if (Data.length !== 0) {
      Data = Data.map(d => {
        d.retCts = d.issueCts
        d.lossCts = 0
        d.extraCts = 0
        return d
      })
    }
    setToDepartmentTableData(Data)
  }, [totableData])

  useEffect(() => {
    setToDepartmentTableData([])
  }, [TotableError])

  /***************TO table End ********************/

  const SetFromDepartmentFun = (e, data) => {
    if (data !== null) {
      SetFromDepartment(data)
      SetToDepartment([])
      SetProcess('')
      SetPacketType('')
    }
  }
  const SetToDepartmentFun = (e, data) => {
    if (data !== null) {
      SetToDepartment(data)
    }
  }
  const SetProcessFun = (DataId) => {
    SetProcess(DataId);
    getToTableDataByDepartment({
      variables: {
        departmentId: ToDepartments.id
      }
    })
  }
  const SetProcessTypeFun = (e) => {
    SetPacketType(e.target.value)
  }

  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "rgb(70, 81, 142)",
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  const setDataFromMasterView = (data) => {
    SetFromDepartment(data?.fromDept || null)
    SetToDepartment(data?.toDept || null)
    SetProcess(data?.process?.id || null)
    setMasterSelectedData(data)
  }

  // get from & To department list
  const { error: AllDepartmentError, data: AllDepartmentData } = useQuery(ALLDEPARTMENT, {
    fetchPolicy: 'cache-and-network',
  })
  if (AllDepartmentError)
    return toast('network error')
  let Data = AllDepartmentData?.getAllDepartment || []
  const defaultProps = {
    options: Data,
    getOptionLabel: (option) => option.deptName || ""
  };
  let GroupByProcess = _.groupBy(FromTableData, 'process.procName')
  let GroupByLotNo = _.groupBy(FromTableData, 'lotNoId.lotNo')
  let GroupByLotNo2 = _.groupBy(Totabledatas, 'lotNoId.lotNo')

  if (GroupByProcess.undefined) {
    delete GroupByProcess.undefined
  }
  if (GroupByLotNo.undefined) {
    delete GroupByLotNo.undefined
  }
  const RefreshButtonHandler = (e) => {
    e.stopPropagation();
    SetFromDepartment([])
    SetToDepartment([])
    SetProcess('')
    setMasterSelectedData('')
    SetPacketType('')
  }

  return (
    <div className={classes.root}>
      <Accordion expanded={expanded} className="AccordianHeader" onChange={() => setExpanded(!expanded)} >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ width: 'auto' }}
        >
          <Grid container>
            <Grid item xs={4}>
              <Button variant="contained" color="primary" style={{ marginRight: 10, background: '#8CB836' }} onClick={(e) => RefreshButtonHandler(e)} >refresh</Button>
              {FromDepartmentDatas.length !== 0 &&
                <Button type="submit" className={classes.form} variant="contained" disabled={Loader} onClick={e => SaveButtonHandler(e)} color="primary" >
                  {Loader && <CircularProgress size={24} className={classes.buttonProgress} />} Save
                </Button>
              }
              {
                ToDepartmentDatas.length !== 0 &&
                <Button type="submit" className={classes.form} variant="contained" disabled={Loader} onClick={e => RemoveButtonHandler(e)} color="primary" >
                  {Loader && <CircularProgress size={24} className={classes.buttonProgress} />} Remove
                </Button>
              }
            </Grid>
            <Grid xs={4} item container justify="center">
              <Typography className={classes.heading}> Department Issue Return Master</Typography>
            </Grid>
            <Grid xs={4} item container justify="flex-end">
              <Typography className={classes.heading}>Master  View</Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Typography component={'span'} variant={'body2'}>
            <MasterView
              key={masterTableKey}
              MasterSelectedData={(data) => setDataFromMasterView(data)}
              closeAccordian={(val) => setExpanded(val)}
            />
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Grid container alignContent="space-between">
        <Grid xs={2} item>
          <Grid container>
            <Grid xs={7} item>
              <InputLabel style={{ fontSize: 18, paddingBottom: 10, color: "rgb(0, 0, 0)" }} id="demo-simple-select-label">Process Wise Stock</InputLabel>
            </Grid>
            <Grid xs={4} item>
              <InputLabel style={{ fontSize: 18, paddingBottom: 10, color: "rgb(0, 0, 0)", textAlign: "end" }} id="demo-simple-select-label">Tot PKT {FromTableData ? FromTableData.length : 0} </InputLabel>
            </Grid>
          </Grid>
          <TableContainer component={Paper} className={classes.fitGrid} >
            <Table className={classes.denseTable} aria-label="customized table" >
              <TableHead>
                <TableRow>
                  <StyledTableCell className={classes.tableHeading}>Process</StyledTableCell>
                  <StyledTableCell className={classes.tableHeading}>Tot. PKT.</StyledTableCell>
                  <StyledTableCell className={classes.tableHeading}>Tot. Cts.</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody >
                {Object.keys(GroupByProcess).map((row, key) => {
                  return (<StyledTableRow key={key}>
                    <StyledTableCell >{row}</StyledTableCell>
                    <StyledTableCell >{FromTableData.length}</StyledTableCell>
                    <StyledTableCell >{Tofixed(_.sum(_.map(_.filter(FromTableData, (d) => d?.process?.procName || '' === row), 'issueCts')))}</StyledTableCell>
                  </StyledTableRow>)
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid xs={2} item  >
          <Grid container>
            <Grid xs={7} item>
              <InputLabel style={{ fontSize: 18, paddingBottom: 10, color: "rgb(0, 0, 0)" }} id="demo-simple-select-label">Kapan Wise Stock</InputLabel>
            </Grid>
            <Grid xs={4} item>
              <InputLabel style={{ fontSize: 18, paddingBottom: 10, color: "rgb(0, 0, 0)", textAlign: "end" }} id="demo-simple-select-label">Tot PKT {FromTableData ? FromTableData.length : 0}</InputLabel>
            </Grid>
          </Grid>
          <TableContainer component={Paper} className={classes.fitGrid}>
            <Table className={classes.denseTable} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell className={classes.tableHeading}>Lot No</StyledTableCell>
                  <StyledTableCell className={classes.tableHeading}>Tot. Pkt</StyledTableCell>
                  <StyledTableCell className={classes.tableHeading}>Tot. Cts.</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(GroupByLotNo).map((row, key) => {
                  return <StyledTableRow key={key}>
                    <StyledTableCell >{row}</StyledTableCell>
                    <StyledTableCell >{_.filter(FromTableData, (d) => {
                      let a = d?.lotNoId?.lotNo || ''; return a === row
                    }).length}</StyledTableCell>
                    <StyledTableCell >{Tofixed(_.sum(_.map(_.filter(FromTableData, (d) => { let a = d?.lotNoId?.lotNo || ''; return a === row }), 'issueCts'
                    )))}</StyledTableCell>
                  </StyledTableRow>
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid xs={4} style={{ paddingTop: 40 }} item container justify="center" className={classes.SetAccordianHeade}>
          <Grid xs={6} item>
            <TableContainer component={Paper} className={classes.fitGrid}>
              <Table className={classes.denseTable} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell className={classes.tableHeading}>Lot No</StyledTableCell>
                    <StyledTableCell className={classes.tableHeading}>Tot. Pkt</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(GroupByLotNo2).map((row, key) => {
                    return <StyledTableRow key={key}>
                      <StyledTableCell >{row === "undefined" ? "-" : row}</StyledTableCell>
                      <StyledTableCell >{_.filter(Totabledatas, (d) => d?.lotNoId?.lotNo || '' === row).length}</StyledTableCell>
                    </StyledTableRow>
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Grid xs={1} style={{ paddingTop: 10 }} item container justify="flex-end" className={classes.SetAccordianHeade}>
          {MasterSelectedData.jagadNo && `Jangad No ${MasterSelectedData.jagadNo} `}
        </Grid>

        <Grid xs={3} item className={classes.GridFillter}>
          <Grid item xs={12} >
            <FormControl className={classes.formControl}>
              <Autocomplete
                {...defaultProps}
                onChange={(e, newValue) => SetFromDepartmentFun(e, newValue)}
                value={FromDepartments}
                disableClearable
                renderInput={(params) => <TextField label="From Department"   {...params} margin="normal" />}
              />
            </FormControl>
            <ToDepartment FromDepartmentIds={FromDepartments} Ids={ToDepartments} SetToDepartmentFun={SetToDepartmentFun} />
          </Grid>
          <Grid item xs={12} >
            <ProcessSelect SetPacketType={(d) => SetPacketType(d)} fromDept={FromDepartments.id} toDept={ToDepartments.id} Ids={Process} SetProcessFun={SetProcessFun} />
            <PacketType Data={packetTypeForDepartmentIssueReturn} packetTypedisable={true} PacketTypes={PacketTypes} FromDepartments={FromDepartments} Ids={PacketTypes} SetProcessTypeFun={SetProcessTypeFun} />
          </Grid>
        </Grid>
      </Grid>

      <Grid container alignContent="space-between">
        <Grid xs={6} item className="Fromtablebetweenspace">
          <Fromtable
            loading={loading}
            FromTableData={FromTableData}
            SetFromTableData={(data) => SetFromTableData(data)}
            departmentId={FromDepartments}
            FromDepartments={FromDepartments}
            ToDepartments={ToDepartments}
            SetFromDepartmentDatas={(data) => SetFromDepartmentDatas(data)} />
        </Grid>
        <Grid xs={6} item className="Totablebetweenspace">
          <Totable
            ToDepartments={ToDepartments}
            Totabledatas={toDepartmentTableData}
            SetToDepartmentDatas={(data) => SetToDepartmentDatas(data)}
            loading={TotableLoading}
          />
        </Grid>
      </Grid>
    </div >
  );
}
export default withRouter(DepartmentIssueReturn)