import _ from "lodash";

export const GetRapeRate = (price_list, cts, clarity, color, shape, shortCodeData) => {

    let ShortCodeData1 = shortCodeData.getAllType || []
    shape = _.filter(ShortCodeData1, (d) => d.id === shape)[0]?.shortName || "-"
    clarity = _.filter(ShortCodeData1, (d) => d.id === clarity)[0]?.shortName || "-"
    color = _.filter(ShortCodeData1, (d) => d.id === color)[0]?.shortName || "-"

    let rap = _.filter(price_list, (price) => {
        return price.low_size <= cts && price.high_size >= cts && price.shape === shape.toLowerCase() && price.color === color.toLowerCase() && price.clarity === clarity.toLowerCase()
    })
    let Raprate
    if (rap.length && rap.length !== 0) {
        Raprate = {
            rapRate: rap[0].caratprice,
            rapAmount: rap[0].caratprice * cts
        }
    } else {
        Raprate = {
            rapRate: 0,
            rapAmount: 0
        }
    }

    return Raprate
}
