import React, { useRef, useEffect, useState } from 'react'
import { Grid } from '@material-ui/core';
import MaterialTable from 'material-table';
import { useQuery, useMutation } from '@apollo/react-hooks'
import { ROUGHDETAIL } from './RoughQuery'
import { ADD_ROUGHDETAIL, UPDATE_ROUGHDETAIL, DELETE_ROUGHDETAIL } from './RoughMutation'
import { toast } from 'react-toastify'
import _, { toInteger } from 'lodash'
import { TextField } from '@material-ui/core';
import { FormatError } from '../../../components/Comman/FormatError'
import { Tofixed } from '../../../components/Comman/Tofix';

const calculateroughAmountDollar = (rowData) => {
  let cts = rowData?.cts || 0;
  let rate = rowData?.rate || 0;
  return Tofixed(cts * rate)
}
const calculateamountZar = (rowData) => {
  let rateZar = rowData?.rateZar || 0
  return Tofixed(calculateroughAmountDollar(rowData) * rateZar)
}

const columns = [
  {
    title: 'CTS *',
    field: 'cts',
    type: "numeric",
    editComponent: (props) => {
      //   let valueDefault = props?.rowData?.brokerPer || 0;
      return (
        <TextField
          value={props?.rowData?.cts}
          type="number"
          onChange={(e) => {
            let Data = { ...props.rowData };
            Data.cts = e.target.value;
            props.onRowDataChange(Data);
          }}
        />
      );
    },
    render: (rowData) => Tofixed(rowData?.cts || 0)
  },
  {
    title: 'Rate *',
    field: 'rate',
    type: "numeric",
    editComponent: (props) => {
      //   let valueDefault = props?.rowData?.brokerPer || 0;
      return (
        <TextField
          value={props?.rowData?.rate}
          type="number"
          onChange={(e) => {
            let Data = { ...props.rowData };
            Data.rate = e.target.value;
            props.onRowDataChange(Data);
          }}
        />
      );
    },
    render: (rowData) => Tofixed(rowData?.rate || 0)
  },
  {
    title: 'Rough Cost($)*',
    field: 'roughAmountDollar',
    type: "numeric",
    editComponent: props => {
      return calculateroughAmountDollar(props.rowData)
    },
    render: (rowData) => Tofixed(rowData?.roughAmountDollar || 0)
  },
  {
    title: 'Zar Rate *',
    field: 'rateZar',
    type: "numeric",
    editComponent: (props) => {
      //   let valueDefault = props?.rowData?.brokerPer || 0;
      return (
        <TextField
          value={props?.rowData?.rateZar}
          type="number"
          onChange={(e) => {
            let Data = { ...props.rowData };
            Data.rateZar = e.target.value;
            props.onRowDataChange(Data);
          }}
        />
      );
    },
    render: (rowData) => Tofixed(rowData?.rateZar || 0)
  },
  {
    title: 'Zar Amount *',
    field: 'amountZar',
    type: "numeric",
    render: (rowData) => Tofixed(rowData?.amountZar || 0),
    editComponent: props => {
      return calculateamountZar(props.rowData)
    }
  },
]
export const Roughchild = (props) => {
  const tableRef = useRef();
  const permissions = props?.session?.me?.role?.permissions;
  const role = props?.session?.me?.role?.role;
  const path = props?.location?.pathname;
  let permission = _.find(permissions, function (o) { return o.name === path; })

  const { error, data, refetch } = useQuery(ROUGHDETAIL, {
    variables: { id: props.Ids },
    fetchPolicy: 'cache-and-network'
  })

  const HideRow = (rows) => {
    let aa = document.getElementsByClassName('childTable')
    for (let index = 0; index < aa.length; index++) {
      setTimeout(() => {
        if (rows && rows !== 0 && aa[index]) {
          let newD = aa[index].getElementsByClassName('MuiTableBody-root')[0].rows[aa[index].getElementsByClassName('MuiTableBody-root')[0].rows.length - 1]
          if (newD.getElementsByClassName('MuiTableCell-sizeSmall')[0]) {
            newD.getElementsByClassName('MuiTableCell-sizeSmall')[0].innerText = 'Total'
            newD.style.background = 'rgb(212, 218, 255)'
          }
        }
      }, 300);
    }
  }

  const getRoughDetail = () => {
    return new Promise((resolve, reject) => {
      if (error)
        reject(error)
      let result = [...data?.getAllRoughDetail || []]
      if (result?.length > 0) {
        let LastRow = { 'cts': '', 'rate': '', 'roughAmountDollar': 0, 'rateZar': '', 'amountZar': 0, 'rateRs': '', 'amountRs': 0 }
        LastRow.roughAmountDollar = Tofixed(_.sumBy(result, (d) => d?.roughAmountDollar))
        LastRow.amountZar = Tofixed(_.sumBy(result, (d) => d?.amountZar))
        LastRow.cts = Tofixed(_.sumBy(result, (d) => d?.cts))
        result.push(LastRow)
      }
      HideRow(result.length)
      resolve({
        data: result,
      })
    })
  }
  const [AddRoughDetailMutation] = useMutation(ADD_ROUGHDETAIL)
  const [UpdateRoughDetailMutation] = useMutation(UPDATE_ROUGHDETAIL)
  const [DeleteRoughDetailMutation] = useMutation(DELETE_ROUGHDETAIL)

  const updateRoughMasterNetCost = (Data, type, updateid) => {
    let oldData = props.RoughMasterData.filter(d => d.id === props.Ids)
    let newData1 = [...oldData]
    let result = [...data?.getAllRoughDetail || []]
    let TotalCost
    let TotalCts
    let TotalZarAmount
    if (type === 'Add') {
      if (result.length === 0) {
        TotalCost = Data.netCost
        TotalCts = Data.Cts
        TotalZarAmount = Data.amountZar
        newData1[0].totalPcs = toInteger(result.length) + 1
        newData1[0].avgCts = parseFloat((TotalCts / 1)).toFixed(2)
      } else {
        TotalCost = (_.sum(_.map(result, 'roughAmountDollar'))) + Data.netCost
        TotalCts = (_.sum(_.map(result, 'cts'))) + Data.Cts
        TotalZarAmount = (_.sum(_.map(result, 'amountZar'))) + Data.amountZar
        newData1[0].totalPcs = toInteger(result.length) + 1
        newData1[0].avgCts = parseFloat((TotalCts / (toInteger(result.length + 1))).toFixed(2))
      }
    } else if (type === 'Update') {
      newData1[0].totalPcs = toInteger(result.length)
      TotalCost = (_.sum(_.map(_.filter(result, (d) => d.id !== updateid), 'roughAmountDollar'))) + Data.netCost
      TotalCts = (_.sum(_.map(_.filter(result, (d) => d.id !== updateid), 'cts'))) + Data.Cts
      TotalZarAmount = (_.sum(_.map(_.filter(result, (d) => d.id !== updateid), 'amountZar'))) + Data.amountZar
      newData1[0].avgCts = parseFloat((TotalCts / (toInteger(result.length))).toFixed(2))
    } else if (type === 'Delete') {
      newData1[0].totalPcs = toInteger(result.length) - 1
      TotalCost = (_.sum(_.map(_.filter(result, (d) => d.id !== updateid), 'roughAmountDollar')))
      TotalCts = (_.sum(_.map(_.filter(result, (d) => d.id !== updateid), 'cts')))
      TotalZarAmount = (_.sum(_.map(_.filter(result, (d) => d.id !== updateid), 'amountZar')))
      newData1[0].avgCts = parseFloat((TotalCts / (toInteger(result.length - 1))).toFixed(2))
    }
    newData1[0].totalCts = TotalCts
    newData1[0].totalZarAmt = TotalZarAmount
    newData1[0].totalCost = TotalCost
    newData1[0].netCost = TotalCost

    newData1[0].avgRate = parseFloat((TotalCost / TotalCts).toFixed(2))
    newData1[0].zarRate = parseFloat((TotalZarAmount / TotalCost).toFixed(2))

    let a = newData1[0].tableData
    delete newData1[0].tableData
    if (props.UpdateRough(newData1[0], oldData[0])) {
      newData1[0].tableData = a
      return true
    } else {
      return false
    }
  }

  const AddRoughChild = (newData) => {
    return new Promise((resolve, reject) => {
      refetch();
      newData.roughMasterId = props.Ids
      newData.cts = parseFloat(Tofixed(newData.cts))
      newData.rate = parseFloat(Tofixed(newData.rate))
      newData.roughAmountDollar = parseFloat(Tofixed(calculateroughAmountDollar(newData)))
      newData.rateZar = parseFloat(Tofixed(newData.rateZar))
      newData.amountZar = parseFloat(Tofixed(calculateamountZar(newData)))
      if (newData.cts && newData.rate && newData.roughAmountDollar && (0 <= newData.rateZar) && (0 <= newData.amountZar)) {
        let log = {
          action: 'INSERT',
          actionOn: 'roughDetail',
          actionName: 'addNewRoughDetail',
          oldValue: "",
          message: `${newData?.cts || 0} carats roughDetail was created.`
        }
        if (updateRoughMasterNetCost({ "netCost": newData.roughAmountDollar, "Cts": newData.cts, "amountZar": newData.amountZar }, 'Add')) {
          AddRoughDetailMutation({
            variables: { input: newData, log }
          }).then((data) => {
            refetch();
            toast.success('rough detail created successfully');
            resolve()
          })
            .catch((e) => {
              reject()
              toast.error(FormatError(e))
            })
        } else {
          toast.warn('problem in master data update')
        }
      } else {
        toast.warn('please, fill all required(*) fields.')
        reject()
      }
    })
  }
  const UpdateRoughChild = (newData, oldData) => {
    return new Promise((resolve, reject) => {
      refetch()
      delete newData.createdBy
      delete newData.createdAt
      delete newData.__typename
      newData.roughMasterId = props.Ids
      newData.cts = parseFloat(Tofixed(newData.cts))
      newData.rate = parseFloat(Tofixed(newData.rate))
      newData.roughAmountDollar = parseFloat(Tofixed(calculateroughAmountDollar(newData)))
      newData.rateZar = parseFloat(Tofixed(newData.rateZar))
      newData.amountZar = parseFloat(Tofixed(calculateamountZar(newData)))
      if (newData.cts && newData.rate && newData.roughAmountDollar && (0 <= newData.rateZar) && (0 <= newData.amountZar)) {
        let log = {
          action: 'UPDATE',
          actionOn: 'roughDetail',
          actionName: 'updateRoughDetail',
          oldValue: JSON.stringify(oldData),
          message: `${oldData?.cts || 0} carats roughDetail was updated.`
        }
        if (updateRoughMasterNetCost({ "netCost": newData.roughAmountDollar, "Cts": newData.cts, "amountZar": newData.amountZar }, 'Update', newData.id)) {
          UpdateRoughDetailMutation({ variables: { input: newData, log } }).then((data) => {
            refetch()
            toast.success('rough detail updated successfully');
            resolve()
          }).catch((e) => {
            reject()
            toast.error(FormatError(e))
          })
        } else {
          toast.warn('problem in master data update')
        }
      } else {
        toast.warn('please, fill all required(*) fields.')
        reject()
      }
    })
  }
  const DeleteRoughChild = (oldData) => {
    return new Promise((resolve, reject) => {
      refetch()
      let log = {
        action: 'DELETE',
        actionOn: 'roughDetail',
        actionName: 'deleteRoughDetail',
        oldValue: JSON.stringify(oldData),
        message: `${oldData?.cts || 0} carats roughDetail was deleted.`
      }
      if (updateRoughMasterNetCost('', 'Delete', oldData.id)) {
        DeleteRoughDetailMutation({ variables: { id: oldData.id, log } }).then((data) => {
          toast.success('rough detail deleted successfully.  ');
          resolve()
        }).catch((e) => {
          reject()
          toast.error(FormatError(e))
        })
      } else {
        toast.warn('problem in master data update')
      }
    })
  }
  useEffect(() => {
    tableRef.current && tableRef.current.onQueryChange()
  }, [data]);
  useEffect(() => {
    refetch()
  }, [])
  const addButton = (val) => {
    if (val || (role === 'admin'))
      return { onRowAdd: (newData) => AddRoughChild(newData) }
    else
      return {}
  }

  return <Grid
    container
    direction="row"
    justify="flex-end" item xs={12} >
    <div className="childTable increaseWidth" >
      <MaterialTable
        tableRef={tableRef}
        columns={columns}
        data={() => getRoughDetail()}
        options={{
          emptyRowsWhenPaging: false,
          padding: 'dense',
          pageSize: 10,
          pageSizeOptions: [5, 10, 15, 20, 25, 50],
          addRowPosition: 'first',
          filtering: false,
          sorting: false,
          search: false,
          paging: false,
          filterCellStyle: { padding: '5px', },
          maxBodyHeight: window.innerHeight - 215,
          showTitle: false,
          headerStyle: { backgroundColor: 'whitesmoke' }
        }}
        editable={{
          isEditHidden: (rowData) => (role === 'admin') ? false : !permission.isUpdate,
          isDeleteHidden: (rowData) => (role === 'admin') ? false : !permission.isDelete,
          ...addButton(permission?.isInsert),
          onRowUpdate: (newData, oldData) => UpdateRoughChild(newData, oldData),
          onRowDelete: (oldData) => DeleteRoughChild(oldData)
        }}
      />
    </div>
  </Grid>
}