import gql from 'graphql-tag';

export const ADDNEWBRANCHTRANS = gql`
mutation addNewBranchTrans($input: branchTransMasterInput!){
  addNewBranchTrans(input:$input){
    id
    btNo
    transDate
    pktType
    deptName
    remark
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}
`;
export const DELETEBRANCHTRANSFERDETAIL = gql`
mutation deleteBranchTransferDetail($ids: [ID]){
  deleteBranchTransferDetail(ids:$ids)
}
`;
