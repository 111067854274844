import gql from 'graphql-tag';

export const ADD_BANKDETAIL = gql`
mutation addNewBankDetail($input: bankDetailInput!,$log: LogInput){
  addNewBankDetail(input:$input,log: $log){
    id
    bankID
    accNo
    bankName
    branchCode
    detOf
    dolAccNO
    narration
    partyID
    companyID
    swiftCode
    isActive
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}
`;

export const UPDATE_BANKDETAIL = gql`
mutation updateBankDetail($input:bankDetailUpdate!,$log: LogInput){
  updateBankDetail(input:$input,log: $log){
    id
    bankID
    accNo
    bankName
    branchCode
    detOf
    dolAccNO
    narration
    partyID
    companyID
    swiftCode
    isActive
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
 }
`;

export const DELETE_BANKDETAIL = gql`
  mutation deleteBankDetail( $id: ID!,$log: LogInput) {
    deleteBankDetail(id:$id,log: $log)
  }
`;
