import React, { useState, useEffect, useRef } from 'react'
import { withRouter } from "react-router-dom";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { TextField, Button, Typography, FormControl, Grid, Select, MenuItem } from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks'
import MaterialTable from 'material-table';
import moment from 'moment'
import STOCKSALESOUTSTANDING from './SalesOutstandingQuery';
import _ from 'lodash'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CompanyAndPartyAllData } from '../../../components/DropdownSelect/SelectComponents/companyAndPartySelectComponent';

import GetAppIcon from '@material-ui/icons/GetApp';
import './salesout.css'
import { Accordion, AccordionSummary, AccordionDetails, useStyles } from '../../../components/Comman/Accordian'
import { Tofixed } from '../../../components/Comman/Tofix'
import { CommponExport } from '../../../components/ExportReport/CommponExport'
import { PrintDiv, exportTableToExcel } from '../../../components/Comman/exportPdfAndExcel'
import ExcelPng from '../../../../Excel.png'
import { ExportCsv } from '../../../components/ExportReport/ExportCsv'
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx/xlsx.mjs';

const calReceivedAmount = (rowData) => {
  if (rowData.date === "Grand Total") {
    return rowData.receivedAmount
  } else {
    if (rowData && rowData.currency && (rowData.currency === "$ TO $" || rowData.currency === "ZAR TO $")) {
      return Tofixed(rowData.totalAmount - (rowData.remainingAmt))
    }
    else if (rowData && rowData.currency && (rowData.currency === "ZAR TO ZAR" || rowData.currency === "$ TO ZAR")) {
      return Tofixed(rowData.netZarAmt - (rowData.remainingAmt))
    }
  }
}
const Detailscolumns = [
  {
    title: 'Bill Date', field: 'date',
    render: (rowData) => {
      if (rowData.date === "Grand Total") { return rowData.date }
      else return moment(rowData.date).format('DD/MM/YYYY')
    }
  },
  { title: 'Party Name', field: 'partyId.partyName' },
  { title: 'Bill No', field: 'billNo' },
  { title: 'Currency', field: 'currency' },
  {
    title: 'Bill Amt($)',
    field: 'totalAmount',
    render: (rowData) => Tofixed(rowData?.totalAmount || 0)

  },
  {
    title: 'Bill Amt(ZAR)',
    field: 'netZarAmt',
    render: (rowData) => Tofixed(rowData?.netZarAmt || 0)
  },
  { title: 'Receive Date', field: 'lab' },
  {
    title: 'Receive Amount',
    field: 'receivedAmount',
    render: (rowData) => Tofixed(calReceivedAmount(rowData))
  },
  {
    title: 'Pending Amount',
    field: 'remainingAmt',
    render: (rowData) => Tofixed(rowData?.netZarAmt || 0)
  }
]

const Summarycolumns = [
  { title: 'Currency', field: 'currency' },
  { title: 'Party Name', field: 'partyId.partyName' },
  { title: 'Total Bills', field: 'totalBillNo' },
  { title: 'Total Bill Amt($)', field: 'totalAmount', },
  { title: 'Total Bill Amt(ZAR)', field: 'TotalNetZarAmt' },
  { title: 'Total Receive Amount', field: 'TotalReceivedAmount' },
  { title: 'Total Pending Amount', field: 'TotalRemainingAmt' }
]

function SalesOutstanding() {
  const classes = useStyles();
  const tableRef = useRef();
  const [filter, setFilter] = useState({})
  const [type, setType] = useState('');
  const [partyName, setPartyName] = useState('');
  const [AllDataDetails, setAllDataDetails] = useState([]);
  const [AllDataSummary, setAllDataSummary] = useState([]);
  const { refetch, data, loading } = useQuery(STOCKSALESOUTSTANDING, {
    variables: { filter: JSON.stringify(filter) },
    fetchPolicy: 'cache-and-network',
  })
  useEffect(() => {
    let OriginalData = data?.getSalesOutStandingReport || []
    let result = _.cloneDeep(OriginalData)
    let GetGrandTotalData = result.filter(d => d.date === "Grand Total")
    if (result.length !== 0 && GetGrandTotalData.length === 0) {
      let LastRow = {
        date: "Grand Total",
        billNo: result?.length || 0,
        totalAmount: Tofixed(_.sumBy(result, (d) => d.totalAmount)),
        netZarAmt: Tofixed(_.sumBy(result, (d) => d.netZarAmt)),
        remainingAmt: Tofixed(_.sumBy(result, (d) => d.remainingAmt)),
        receivedAmount: Tofixed(_.sumBy(result, (d) => {
          if (d && d.currency && (d.currency === "$ TO $" || d.currency === "ZAR TO $")) {
            return d.totalAmount - (d.remainingAmt)
          }
          else if (d && d.currency && (d.currency === "ZAR TO ZAR" || d.currency === "$ TO ZAR")) {
            return d.netZarAmt - (d.remainingAmt)
          }
        })),
      }
      result.push(LastRow)
    }
    setAllDataDetails(result)
  }, [data])

  useEffect(() => {
    let result = data?.getSalesOutStandingReport || []
    let Currency = _.groupBy(result, 'currency')
    let SummaryData = []
    Object.keys(Currency).map(d => {
      let TempData = {}
      TempData["partyId"] = Currency[d][0]["partyId"]
      TempData["totalBillNo"] = Currency[d].length
      TempData["currency"] = d
      TempData["totalAmount"] = Tofixed(_.sumBy(Currency[d], (d) => d.totalAmount))
      TempData["TotalNetZarAmt"] = Tofixed(_.sumBy(Currency[d], (d) => d.netZarAmt))
      TempData["TotalRemainingAmt"] = Tofixed(_.sumBy(Currency[d], (d) => d.remainingAmt))
      TempData["TotalReceivedAmount"] = Tofixed(_.sumBy(Currency[d], (d) => {
        if (d && d.currency && (d.currency === "$ TO $" || d.currency === "ZAR TO $")) {
          return d.totalAmount - (d.remainingAmt)
        }
        else if (d && d.currency && (d.currency === "ZAR TO ZAR" || d.currency === "$ TO ZAR")) {
          return d.netZarAmt - (d.remainingAmt)
        }
      }))
      SummaryData.push(TempData)
      return null
    })

    let GetGrandTotalData = SummaryData.filter(d => d.currency === "Grand Total")
    if (SummaryData.length !== 0 && GetGrandTotalData.length === 0) {
      let LastRow = {
        currency: "Grand Total",
        totalAmount: Tofixed(_.sumBy(SummaryData, (d) => d.totalAmount)),
        TotalNetZarAmt: Tofixed(_.sumBy(SummaryData, (d) => d.TotalNetZarAmt)),
        TotalRemainingAmt: Tofixed(_.sumBy(SummaryData, (d) => d.TotalRemainingAmt)),
        TotalReceivedAmount: Tofixed(_.sumBy(SummaryData, (d) => d.TotalReceivedAmount)),
      }
      SummaryData.push(LastRow)
    }
    setAllDataSummary(SummaryData)
  }, [data])

  let parties = CompanyAndPartyAllData("PARTY");

  useEffect(() => {
    let data = JSON.parse(localStorage.getItem("SalesOutstandingDetailsReport") || "{}")
    if (Object.keys(data).length > 0) {
      setType(data.type)
      setPartyName(_.find(parties, (d) => d.id === data.partyId))
      setFilter({ "partyId": data.partyId })
    }
  }, [])


  function exportExcelFile(workbook) {
    return XLSX.writeFile(workbook, `SalesOutstandingReport${moment()}.xlsx`);
  }

  const exportCsvData = () => {
    var workbook = XLSX.utils.book_new();
    var worksheet_data = document.getElementById("table-to-xls");
    var worksheet = XLSX.utils.table_to_sheet(worksheet_data);
    workbook.SheetNames.push("Test");
    workbook.Sheets["Test"] = worksheet;
    exportExcelFile(workbook);
  }

  const getStockMasterByFilter = () => {
    let id = partyName?.id || ''

    if (type === '') {
      toast.warn('Please Select Report Type')
    }
    else if (id === '') {
      toast.warn('Please Select Party Name')
    } else {
      setFilter({ "partyId": partyName.id })
      localStorage.setItem("SalesOutstandingDetailsReport", JSON.stringify({ "partyId": partyName.id, type }))
    }
  }

  const pdfHeader = () => {
    return <div>
      <h2 style={{ textAlign: "center" }}> Sales Outstanding Report </h2>
      <p style={{ margin: 0, fontSize: 18, fontWeight: "bold" }}>Company Name : KISMET DIAMONDS</p>
      <div style={{ display: "flex", width: "100%" }}>
        <div style={{ width: "50%" }}>
          < p style={{ margin: 0, fontSize: 18, fontWeight: "bold" }}> Party Name : {partyName.partyName || "All"}</p>
        </div>
      </div >
    </div >
  }

  const TableHeader = () => {
    return <>
      <tr>
        <td colSpan={COLUMN.length}><div ><h2 style={{ textAlign: "center" }}>Sales Outstanding Report</h2></div></td>
      </tr>
      <tr>
        <td colSpan={COLUMN.length}>
          <p style={{ margin: 0, fontSize: 18, fontWeight: "bold" }}>Party : {partyName?.partyName || "All"}</p>
        </td>
      </tr>

    </>
  }
  const ClearViewHandler = () => {
    setType('')
    setPartyName('')
    localStorage.removeItem('SalesOutstandingDetailsReport')
    setAllDataDetails([])
    setAllDataSummary([])
    setFilter({}, () => {
      refetch()
    })
  }
  let TITLE = type === "summary" ? "Sales Outstanding Summary Report" : "Sales Outstanding Details Report"
  let COLUMN = type === "summary" ? Summarycolumns : Detailscolumns
  let DATA = type === "summary" ? AllDataSummary : AllDataDetails
  let AllData = _.cloneDeep(DATA)
  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Filters</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container justify="space-around">
            <form className={"makeStyles-root-30"} noValidate autoComplete="off">
              <FormControl className={classes.formControl} style={{ paddingTop: 0 }}>
                <Select
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                  displayEmpty
                  className={classes.selectEmpty}
                  inputProps={{ 'aria-label': 'Without label' }}>
                  <MenuItem value="" >
                    <span style={{ color: "#808080" }}>Report Type</span>
                  </MenuItem>
                  <MenuItem value={"detail"}>DETAIL</MenuItem>
                  <MenuItem value={"summary"}>SUMMARY</MenuItem>
                </Select>
              </FormControl>
              <FormControl className={classes.formControl} style={{ paddingTop: 0 }}>
                <Autocomplete
                  options={parties}
                  value={partyName}
                  getOptionLabel={(option) => option.partyName}
                  onChange={(e, newdata) => {
                    if (newdata) {
                      setPartyName(newdata)
                    }
                  }}
                  className="lotmarginCustom"
                  renderInput={(params) => <TextField label="Party Name"  {...params} margin="normal" />}
                />
              </FormControl>
              <Button
                variant="contained"
                color="primary"
                style={{ height: 34, marginLeft: 5, marginTop: 12 }}
                onClick={e => getStockMasterByFilter()}>
                Preview
              </Button>
              <Button
                onClick={() => ClearViewHandler()}
                variant="contained"
                color="primary"
                style={{ height: 34, marginLeft: 10, marginTop: 12 }}>
                clear
              </Button>
            </form>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Grid container>
        <Grid item xs={12} style={{ position: "relative" }}>
          <img className="download-table-xls-button" src={ExcelPng} alt="alternative text" title="csv" onClick={() => exportCsvData()} />
          <ExportCsv
            columns={COLUMN}
            TableHeader={TableHeader()}
            AllData={AllData.filter(d => {
              d["partyId.partyName"] = d?.partyId?.partyName
              d["receivedAmount"] = calReceivedAmount(d)
              return d
            })}
          />
          <MaterialTable
            title={TITLE}
            columns={COLUMN}
            tableRef={tableRef}
            isLoading={loading}
            data={DATA}
            actions={[
              {
                icon: 'print',
                tooltip: 'PRINT',
                isFreeAction: true,
                onClick: (event) => PrintDiv()
              },
              // {
              //   icon: GetAppIcon,
              //   tooltip: 'EXPORT',
              //   isFreeAction: true,
              //   onClick: (e) => exportTableToExcel("ExportTable", `${TITLE}_${moment()}`, e)
              // }
            ]}
            options={{
              emptyRowsWhenPaging: false,
              padding: 'dense',
              paging: false,
              pageSize: 10,
              pageSizeOptions: [5, 10, 15, 20, 25, 50],
              addRowPosition: 'first',
              filtering: true,
              sorting: true,
              search: false,
              filterCellStyle: { padding: '5px', },
              maxBodyHeight: window.innerHeight - 215,
              rowStyle: rowData => ({
                backgroundColor: (rowData.date === "Grand Total" || rowData.currency === "Grand Total") ? 'rgb(212, 218, 255)' : '#f5f5f5',
                fontWeight: (rowData.date === "Grand Total" || rowData.currency === "Grand Total") && 'bolder'
              }),
              headerStyle: { backgroundColor: 'whitesmoke' }
            }}
          />
        </Grid>
      </Grid>
      <CommponExport
        columns={COLUMN}
        pdfHeader={pdfHeader()}
        AllData={AllData.filter(d => {
          d["partyId.partyName"] = d?.partyId?.partyName
          d["receivedAmount"] = calReceivedAmount(d)
          return d
        })}
      />
    </div>
  );
}

export default withRouter(SalesOutstanding)