import React, { useRef, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import MaterialTable from "material-table";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { SalesStockFromLotPktAndStatus } from "../../../components/DropdownSelect/SalesStockFromLotPktAndStatus";
import { RoughStockFromLotandPktForIssue } from "../../../components/DropdownSelect/RoughStockFromLotandPktForIssue";
import { TextField } from "@material-ui/core";
import { ISSUERETURNDETAILQUERY } from "./IssueReturnMasterQuery";
import { ADD_ISSUERETURN_DETAIL, DELETE_ISSUERETURN_DETAIL, UPDATE_ISSUERETURN_DETAIL } from "./IssueReturnMasterMutation";
import _ from "lodash";
import { toast } from "react-toastify";
import { LotNoSelectComponent } from "../../../components/DropdownSelect/SelectComponents/LotSelectComponent";
import { PacketSelectComponent } from "../../../components/DropdownSelect/SelectComponents/PktNoSelectComponent";
import { GradingSelectComponent, GradingLocationSelectComponent } from "../../../components/DropdownSelect/SelectComponents/ShortCodeSelectComponent";
import { GETALLSHORTCODETYPE } from "../../Utility/SortCode/SortCodeQuery";
import { BrokerSelectComponent } from "../../../components/DropdownSelect/SelectComponents/BrokerSelectComponent";
import { FormatError } from "../../../components/Comman/FormatError";
import { Tofixed } from "../../../components/Comman/Tofix";
import { PRICE_LIST } from '../../Utility/RapaPortUser/RapaPortUserQuery'
import { GetRapeRate } from '../../../components/DropdownSelect/SelectComponents/Getraprate'
import { headerStyle, cellStyle, headerStyle50, cellStyle50, headerStyle100, cellStyle100 } from '../../../components/Comman/StyleCell'
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { Button } from "@material-ui/core";
import { MultiplalAddInTable } from "./MultipleAddTable";
import history from '../../../../constants/history';


function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: "90%",
    backgroundColor: theme.palette.background.paper,
    padding: 5
  }
}));

const NetRateAfterDiscount = (rowData) => {
  let rateRap = rowData?.rateRap || 0;
  let stkDiscPer = rowData?.stkDiscPer || 0;
  let discountedAmt = (rateRap * stkDiscPer) / 100;
  let totalAmount = parseInt(rateRap) + parseInt(discountedAmt);
  return totalAmount;
};

const NetRapeAmtCalculator = (rowData) => {
  let rapAmt = parseFloat(rowData?.cts || 0.0) * parseFloat(rowData?.rateRap || 0.0);
  let discounedAmt = (parseFloat(rapAmt || 0.0) * parseFloat(rowData?.stkDiscPer || 0.0)) / 100;
  let rapAmtTotal = parseFloat(rapAmt) + parseFloat(discounedAmt);
  return rapAmtTotal;
};

const NetZarAmtCalculator = (rowData) => {
  let rateZar = parseFloat(rowData?.rateZar || 0);
  let rapAmt = parseFloat(rowData?.cts || 0.0) * parseFloat(rowData?.rateRap || 0.0);
  let discounedAmt = (parseFloat(rapAmt || 0.0) * parseFloat(rowData?.stkDiscPer || 0.0)) / 100;
  let totalRapAmt = parseFloat(rapAmt) + parseFloat(discounedAmt);

  return parseFloat(totalRapAmt) * parseFloat(rateZar);
};

// const NetRsAmtCalculator = (rowData) => {
//   let rateRs = parseFloat(rowData?.rateRs || 0.0);
//   let rapAmt = parseFloat(rowData?.cts || 0.0) * parseFloat(rowData?.rateRap || 0.0);
//   let discounedAmt = (parseFloat(rapAmt || 0.0) * parseFloat(rowData?.stkDiscPer || 0.0)) / 100;
//   let totalRapAmt = parseFloat(rapAmt) + parseFloat(discounedAmt);

//   return parseFloat(totalRapAmt) * parseFloat(rateRs);
// };
let issueReturnType = "";



export const IssueReturnMasterChild = (props) => {
  const columns = [
    { title: 'Stock', sorting: false, field: 'isRoughStock', type: "boolean", initialEditValue: false },
    {
      title: "Lot No*",
      field: "lotNoId",
      sorting: false,
      render: (rowData) => rowData?.lotNoId?.lotNo || "",
      headerStyle: headerStyle,
      cellStyle: cellStyle,
    },
    {
      title: "Pkt No*",
      field: "pktNoId",
      sorting: false,
      render: (rowData) => rowData?.pktNoId?.pktNo || "",
      headerStyle: headerStyle,
      cellStyle: cellStyle,
      editComponent: (props) => {
        let valueDefault = props?.rowData?.lotNoId?.id || props?.rowData?.lotNoId || null;
        let valueDefaultPacketNo = props?.rowData?.pktNoId?.id || props?.rowData?.pktNoId || null;
        let isRoughStockDetail = props?.rowData?.isRoughStock 
        return (
          <PacketSelectComponent
            field={"pktNoId"}
            propsData={props}
            ids={valueDefault}
            valueDefaultPacketNo={valueDefaultPacketNo}
            PartyId={PartyIdDefault} 
            type={type}   
            isRoughStockDetail={isRoughStockDetail}          
          />
        );
      },
    },
    {
      title: "Cts*",
      field: "cts",
      type: "numeric",
      headerStyle: headerStyle50,
      cellStyle: cellStyle50,
      render: (rowData) => Tofixed(rowData?.cts || 0),
      validate: (rowData) =>
        rowData.cts && rowData.cts !== ""
          ? true
          : { isValid: false, helperText: `can't be empty` },
    },
    {
      title: "Location*",
      validate: (rowData) =>
        rowData.location && rowData.location !== ""
          ? true
          : { isValid: false, helperText: `can't be empty` },
      field: "location",
      headerStyle: headerStyle,
      cellStyle: cellStyle,
      sorting: false,
    },
    {
      title: "Barcode",
      field: "barcode",
      sorting: false,
      headerStyle: headerStyle,
      cellStyle: cellStyle,
    },
    {
      title: "Lab*",
      field: "labId",
      sorting: false,
      render: (rowData) => rowData?.labId?.partyName || "",
      headerStyle: headerStyle,
      cellStyle: cellStyle,
      editComponent: (props) => {
        if (props?.rowData?.cts) {
          let valueDefault = props?.rowData?.labId?.id || props?.rowData?.labId || null;
          return (
            <BrokerSelectComponent
              props={props}
              valueDefault={valueDefault}
              TYPE={"LAB1"}
            />
          );
        } else {
          return "";
        }
      },
    },
    {
      title: "Certicate No.",
      field: "certificateNo",
      sorting: false,
      headerStyle: headerStyle,
      cellStyle: cellStyle,
  
    },
    {
      title: "RFID",
      field: "rfId",
      sorting: false,
      headerStyle: headerStyle,
      cellStyle: cellStyle,
  
    },
  
    {
      title: "Color",
      field: "colorId",
      sorting: false,
      headerStyle: headerStyle,
      cellStyle: cellStyle,
      render: (rowData) => rowData?.colorId?.shortName || "",
    },
    {
      title: "Shape",
      field: "shapeId",
      sorting: false,
      headerStyle: headerStyle,
      cellStyle: cellStyle,
      render: (rowData) => rowData?.shapeId?.shortName || "",
      editComponent: ({ rowData }) => {
        return rowData?.shapeId?.shortName || "";
      },
    },
    {
      title: "Purity",
      sorting: false,
      field: "purityId",
      headerStyle: headerStyle,
      cellStyle: cellStyle,
      render: (rowData) => rowData?.purityId?.shortName || "",
      editComponent: ({ rowData }) => {
        return rowData?.purityId?.shortName || "";
      },
    },
    {
      title: "Cut",
      sorting: false,
      field: "cutId",
      headerStyle: headerStyle,
      cellStyle: cellStyle,
      render: (rowData) => rowData?.cutId?.shortName || "",
      editComponent: ({ rowData }) => {
        return rowData?.cutId?.shortName || "";
      },
    },
    {
      title: "Polish",
      sorting: false,
      field: "polishId",
      headerStyle: headerStyle,
      cellStyle: cellStyle,
      render: (rowData) => rowData?.polishId?.shortName || "",
      editComponent: ({ rowData }) => {
        return rowData?.polishId?.shortName || "";
      },
    },
    {
      title: "Symm.",
      sorting: false,
      field: "symmId",
      headerStyle: headerStyle,
      cellStyle: cellStyle,
      render: (rowData) => rowData?.symmId?.shortName || "",
      editComponent: ({ rowData }) => {
        return rowData?.symmId?.shortName || "";
      },
    },
    {
      title: "Flou.",
      sorting: false,
      field: "fluId",
      headerStyle: headerStyle,
      cellStyle: cellStyle,
      render: (rowData) => rowData?.fluId?.shortName || "",
      editComponent: ({ rowData }) => {
        return rowData?.fluId?.shortName || "";
      },
    },
    {
      title: "Measurment",
      sorting: false,
      field: "measurment",
      headerStyle: headerStyle,
      cellStyle: cellStyle,
    },
    {
      title: 'Depth',
      sorting: false,
      field: 'depth',
      headerStyle: headerStyle,
      cellStyle: cellStyle,
    },
    {
      title: 'Table',
      sorting: false,
      field: 'table',
      headerStyle: headerStyle,
      cellStyle: cellStyle,
    },
    {
      title: "Grd %",
      field: "gradePer",
      sorting: false,
      type: "numeric",
      headerStyle: headerStyle50,
      cellStyle: cellStyle50,
      editComponent: (props) => {
        if (props?.rowData?.cts) {
          return (
            <TextField
              defaultValue={props?.rowData?.gradePer || 0}
              type="number"
              onChange={(e) => {
                let Data = { ...props.rowData };
                Data.gradePer = e.target.value;
                if (Data.gradePer >= 0 && Data.gradePer <= 100) {
                  props.onRowDataChange(Data);
                } else {
                  toast.warn(
                    "please, enter grade percentage value between 0 to 100."
                  );
                }
              }}
            />
          );
        } else {
          return 0;
        }
      },
    },
    {
      title: "Rap Rate",
      field: "rateRap",
      sorting: false,
      type: "numeric",
      render: (rowData) => rowData?.rateRap ? Tofixed(rowData?.rateRap || 0) : "",
      headerStyle: headerStyle100,
      cellStyle: cellStyle100,
      editComponent: (props) => {
        //   let valueDefault = props?.rowData?.brokerPer || 0;
        return (
          <TextField
            value={props?.rowData?.rateRap}
            type="number"
            onChange={(e) => {
              let Data = { ...props.rowData };
              Data.rateRap = e.target.value;
              props.onRowDataChange(Data);
            }}
          />
        );
      },
    },
    {
      title: "STK Disc.%",
      field: "stkDiscPer",
      sorting: false,
      type: "numeric",
      headerStyle: headerStyle50,
      cellStyle: cellStyle50,
      editComponent: (props) => {
        return (
          <TextField
            value={props?.rowData?.stkDiscPer}
            type="number"
            onChange={(e) => {
              let Data = { ...props.rowData };
              Data.stkDiscPer = e.target.value;
              if (Data.stkDiscPer >= 0 && Data.stkDiscPer <= 100) {
                props.onRowDataChange(Data);
              } else {
                toast.warn(
                  "please, enter stkDisc percentage value between 0 to 100."
                );
              }
            }}
          />
        );
      },
    },
    {
      title: "Rate After Disc",
      sorting: false,
      field: "rateAfterDisc",
      render: (rowData) => rowData?.rateAfterDisc ? Tofixed(rowData?.rateAfterDisc || 0) : "",
      type: "numeric",
      headerStyle: headerStyle,
      cellStyle: cellStyle,
      editComponent: ({ rowData }) => {
        let rateRap = rowData?.rateRap || 0;
        let stkDiscPer = rowData?.stkDiscPer || 0;
        let discountedAmt = (rateRap * stkDiscPer) / 100;
        let totalAmount = parseFloat(rateRap) + parseFloat(discountedAmt);
        return totalAmount;
      },
    },
    {
      title: "Rap Amt($)",
      sorting: false,
      field: "rapAmt",
      render: (rowData) => Tofixed(rowData?.rapAmt || 0),
      type: "numeric",
      headerStyle: headerStyle,
      cellStyle: cellStyle,
      editComponent: (props) => {
        let Data = { ...props.rowData };
        let rapAmt = parseFloat(Data?.cts || 0.0) * parseFloat(Data?.rateRap || 0.0);
        let discounedAmt = (parseFloat(rapAmt || 0.0) * parseFloat(Data?.stkDiscPer || 0.0)) / 100;
        let rapAmtTotal = parseFloat(rapAmt) + parseFloat(discounedAmt);
        return rapAmtTotal;
      },
    },
    {
      title: "Zar Rate", field: "rateZar", type: "numeric",
      sorting: false,
  
      render: (rowData) => rowData?.rateZar ? Tofixed(rowData?.rateZar || 0) : "",
      editComponent: (props) => {
        //   let valueDefault = props?.rowData?.brokerPer || 0;
        return (
          <TextField
            value={props?.rowData?.rateZar}
            type="number"
            onChange={(e) => {
              let Data = { ...props.rowData };
              Data.rateZar = e.target.value;
              props.onRowDataChange(Data);
            }}
          />
        );
      },
      headerStyle: headerStyle,
      cellStyle: cellStyle,
  
    },
    {
      title: "Zar Amt",
      sorting: false,
      field: "amtZar",
      render: (rowData) => Tofixed(rowData?.amtZar || 0),
      type: "numeric",
      editComponent: (props) => {
        let Data = { ...props.rowData };
        let rateZar = parseFloat(Data?.rateZar || 0);
        let rapAmt = parseFloat(Data?.cts || 0.0) * parseFloat(Data?.rateRap || 0.0);
        let discounedAmt = (parseFloat(rapAmt || 0.0) * parseFloat(Data?.stkDiscPer || 0.0)) / 100;
        let totalRapAmt = parseFloat(rapAmt) + parseFloat(discounedAmt);
        let totalRapAmtCal = parseFloat(totalRapAmt) * parseFloat(rateZar);
        return totalRapAmtCal;
      },
      headerStyle: headerStyle,
      cellStyle: cellStyle,
    },
  ];
  const [modalStyle] = React.useState(getModalStyle);
  const classes = useStyles();
  const tableRef = useRef();
  const permissions = props?.session?.me?.role?.permissions;
  const role = props?.session?.me?.role?.role;
  const path = props?.location?.pathname;
  const [pricelistData, setpricelistData] = useState([]);
  const [TotalRows, setTotalRows] = useState();

  let permission = _.find(permissions, function (o) {
    return o.name === path;
  });

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const { error, data, refetch } = useQuery(ISSUERETURNDETAILQUERY, {
    variables: { id: props.Ids },
    fetchPolicy: 'cache-and-network'
  });
  issueReturnType = props.IssueReturnType;
  let PartyIdDefault = props?.IssueReturnMasterData?.partyId?.id || null;
  let type = props?.IssueReturnMasterData?.type || null
  let issuereturnMasterId =props?.IssueReturnMasterData?.id


  const { data: shortCodeData } = useQuery(
    GETALLSHORTCODETYPE,
    {
      variables: { type: "All" },
      fetchPolicy: "cache-and-network",
    }
  );

  const { loading, data: priceList } = useQuery(PRICE_LIST, {
    fetchPolicy: 'cache-and-network',
  })


  useEffect(() => {
    setpricelistData(priceList?.getRapaportPriceList || [])
  }, [priceList]);

  const getShortCodeData = (TYPE) => {
    return _.filter(shortCodeData?.getAllType || [], (d) => d.type === TYPE);
  };

  const SetDefaultValue = (value) => {
    return value?.id || value;
  };

  let issueChildReturnType = props.IssueReturnType

  columns.map((d) => {
    if (d.field === "depth") {
      if (issueChildReturnType === "LAB RETURN") {
        d.editable = "always"
      } else {
        d.editable = "never"
      }
    }
    if (d.field === "table") {
      if (issueChildReturnType === "LAB RETURN") {
        d.editable = "always"
      } else {
        d.editable = "never"
      }
    }
    if (d.field === "lotNoId") {
      d.editComponent = (props) => {
        let isRoughStockDetail = props?.rowData?.isRoughStock 
        let valueDefault = props?.rowData?.lotNoId?.id || props?.rowData?.lotNoId || null
        return (
          <LotNoSelectComponent field="lotNoId" propsData={props} valueDefault={valueDefault} PartyId={PartyIdDefault} type={type} issuereturnMasterId={issuereturnMasterId} isRoughStockDetail={isRoughStockDetail}/>
        );
      };
    }
    if (d.field === "location") {
      d.editComponent = (props) => {
        let Cts = props?.rowData?.cts || null
        if (Cts) {
          return <GradingLocationSelectComponent propsData={props} TYPE="location" data={getShortCodeData("LOCATION")} valueDefault={SetDefaultValue(props?.rowData?.location || null)} />
        } else return ""
      }
    }
    if (d.field === "colorId") {
      d.editComponent = (props) => {
        let lotNoId = props?.rowData?.lotNoId?.id || props?.rowData?.lotNoId || null;
        let pktNoId = props?.rowData?.pktNoId?.id || props?.rowData?.pktNoId || null;
        let isRoughStock = props?.rowData?.isRoughStock || null;
        if (lotNoId && pktNoId && !isRoughStock) {
          let InwardData = SalesStockFromLotPktAndStatus(
            issueReturnType,
            lotNoId,
            pktNoId,
            PartyIdDefault
          );
          let Data = { ...props.rowData };
          let Ids = InwardData[0]?.id || null;
          let Ids2 = Data?.setids || "";
          if (Ids && Ids2 !== Ids) {
            Data.colorId = InwardData[0]?.colorId || "";
            Data.fluId = InwardData[0]?.fluId || "";
            Data.shapeId = InwardData[0]?.shapeId || "";
            Data.purityId = InwardData[0]?.purityId || "";
            Data.cutId = InwardData[0]?.cutId || "";
            Data.polishId = InwardData[0]?.polishId || "";
            Data.symmId = InwardData[0]?.symmId || "";
            Data.cts = InwardData[0]?.cts || 0;
            Data.labId = InwardData[0]?.lab || "";
            Data.rateRap = InwardData[0]?.rapRate || 0;
            Data.rapAmt = InwardData[0]?.rapAmount || 0;
            Data.stkDiscPer = InwardData[0]?.stkPer || 0;
            Data.certificateNo = InwardData[0]?.certificateId || "";
            Data.rfId = InwardData[0]?.rfId || "";
            Data.measurment = InwardData[0]?.measurment || "0";
            Data.location = InwardData[0]?.location || "";
            Data.setids = InwardData[0].id;
            Data.gradePer = InwardData[0].gradPer;
            props.onRowDataChange(Data);
          }
          if (props?.rowData?.cts || null) {
            return (
              <GradingSelectComponent
                propsData={props}
                TYPE="colorId"
                componentName="IssueReturn"
                shortCodeData={shortCodeData}
                pricelistData={pricelistData}
                data={getShortCodeData("COLOR")}
                valueDefault={SetDefaultValue(props?.rowData?.colorId) || ""}
              />
            );
          } else {
            return "";
          }
        }
        else if (lotNoId && pktNoId && isRoughStock) {
          let InwardData1 = RoughStockFromLotandPktForIssue(
            issueReturnType,
            lotNoId,
            pktNoId,
            PartyIdDefault
          );
          let Data = { ...props.rowData };
          let Ids1 = InwardData1[0]?.id || null;
          let Ids2 = Data?.setids || "";
          if (Ids1 && Ids2 !== Ids1) {
            Data.colorId = InwardData1[0]?.colorId || "";
            Data.fluId = InwardData1[0]?.fluId || "";
            Data.shapeId = InwardData1[0]?.shapeId || "";
            Data.purityId = InwardData1[0]?.purityId || "";
            Data.cutId = InwardData1[0]?.cutId || "";
            Data.polishId = InwardData1[0]?.polishId || "";
            Data.symmId = InwardData1[0]?.symmId || "";
            Data.cts = InwardData1[0]?.issueCts || 0;
            Data.labId = InwardData1[0]?.lab || "";
            Data.rateRap = InwardData1[0]?.rapRate || 0;
            Data.rapAmt = InwardData1[0]?.rapAmount || 0;
            Data.stkDiscPer = InwardData1[0]?.stkPer || 0;
            Data.certificateNo = InwardData1[0]?.certificateId || "";
            Data.rfId = InwardData1[0]?.rfId || "";
            Data.measurment = InwardData1[0]?.measurment || "0";
            Data.location = InwardData1[0]?.location || "";
            Data.setids = InwardData1[0].id;
            Data.gradePer = InwardData1[0].gradPer;
            props.onRowDataChange(Data);
          }
          if (props?.rowData?.cts || null) {
            return (
              <GradingSelectComponent
                propsData={props}
                TYPE="colorId"
                componentName="IssueReturn"
                shortCodeData={shortCodeData}
                pricelistData={pricelistData}
                data={getShortCodeData("COLOR")}
                valueDefault={SetDefaultValue(props?.rowData?.colorId) || ""}
              />
            );
          } else {
            return "";
          }
          //write code here for the RoughStock
        }
        else {
          return "";
        }
      };
    }
    if (d.field === "shapeId") {
      d.editComponent = (props) => {
        if (props?.rowData?.cts || null) {
          return (
            <GradingSelectComponent
              propsData={props}
              componentName="IssueReturn"
              shortCodeData={shortCodeData}
              pricelistData={pricelistData}
              TYPE="shapeId"
              data={getShortCodeData("SHAPE")}
              valueDefault={SetDefaultValue(props?.rowData?.shapeId) || ""}
            />
          );
        } else {
          return "";
        }
      };
    }
    if (d.field === "purityId") {
      d.editComponent = (props) => {
        if (props?.rowData?.cts || null) {
          return (
            <GradingSelectComponent
              propsData={props}
              TYPE="purityId"
              componentName="IssueReturn"
              shortCodeData={shortCodeData}
              pricelistData={pricelistData}
              data={getShortCodeData("PURITY")}
              valueDefault={SetDefaultValue(props?.rowData?.purityId) || ""}
            />
          );
        } else {
          return "";
        }
      };
    }
    if (d.field === "cutId") {
      d.editComponent = (props) => {
        if (props?.rowData?.cts || null) {
          return (
            <GradingSelectComponent propsData={props} TYPE="cutId" data={getShortCodeData("CUT")} valueDefault={SetDefaultValue(props?.rowData?.cutId) || ""}
            />
          );
        } else {
          return "";
        }
      };
    }
    if (d.field === "polishId") {
      d.editComponent = (props) => {
        if (props?.rowData?.cts || null) {
          return (
            <GradingSelectComponent
              propsData={props}
              TYPE="polishId"
              data={getShortCodeData("POLISH")}
              valueDefault={SetDefaultValue(props?.rowData?.polishId) || ""}
            />
          );
        } else {
          return "";
        }
      };
    }
    if (d.field === "symmId") {
      d.editComponent = (props) => {
        if (props?.rowData?.cts || null) {
          return (
            <GradingSelectComponent
              propsData={props}
              TYPE="symmId"
              data={getShortCodeData("SYMMENTRY")}
              valueDefault={SetDefaultValue(props?.rowData?.symmId) || ""}
            />
          );
        } else {
          return "";
        }
      };
    }
    if (d.field === "fluId") {
      d.editComponent = (props) => {
        if (props?.rowData?.cts || null) {
          return (
            <GradingSelectComponent
              propsData={props}
              TYPE="fluId"
              data={getShortCodeData("FLOURSENCE")}
              valueDefault={SetDefaultValue(props?.rowData?.fluId) || ""}
            />
          );
        } else {
          return "";
        }
      };
    }
    if (d.field === "cts") {
      d.editComponent = (props) => {
        let lotNoId = props?.rowData?.lotNoId?.id || props?.rowData?.lotNoId || '';
        let pktNoId = props?.rowData?.pktNoId?.id || props?.rowData?.pktNoId || '';
        if (lotNoId && pktNoId) {
          return <TextField value={props?.rowData?.cts || 0} onChange={(e) => {
            let Data = { ...props.rowData }
            Data.cts = e.target.value
            let shape = Data?.shapeId?.id || Data?.shapeId || null
            let purity = Data?.purityId?.id || Data?.purityId || null
            let color = Data?.colorId?.id || Data?.colorId || null
            let cts = parseFloat(Tofixed(Data?.cts || null))
            if (shape && purity && color && cts) {
              let raprate = GetRapeRate(pricelistData, cts, purity, color, shape, shortCodeData)
              Data.rateRap = raprate?.rapRate || 0
              Data.rapAmt = raprate?.rapAmount || 0
            }
            props.onRowDataChange(Data)
          }} />
        } else {
          return 0
        }
      }
    }
    return null
  });

  // const FooterRowCalculation = (rows) => {
  //   let aa = document.getElementsByClassName("childTable");
  //   for (let index = 0; index < aa.length; index++) {
  //     setTimeout(() => {
  //       if (rows && rows !== 0 && aa[index]) {
  //         let newD = aa[index].getElementsByClassName("MuiTableBody-root")[0].rows[aa[index].getElementsByClassName("MuiTableBody-root")[0].rows.length - 1];
  //         if (newD.getElementsByClassName("MuiTableCell-sizeSmall")[0]) {
  //           newD.getElementsByClassName("MuiTableCell-sizeSmall")[0].innerText = "Total";
  //           newD.getElementsByClassName("MuiTableCell-sizeSmall")[1].innerText = "";
  //           newD.style.background = "rgb(212, 218, 255)";
  //         }
  //       }
  //     }, 300);
  //   }
  // };

  const IssueReturnMasterChildDetail = (query) => {
    return new Promise((resolve, reject) => {
      if (error) {
        reject(error);
      } else {
        let result = [...data?.getAllIssueReturnDetail || []]
        if (query?.orderBy?.field === 'cts') {
          result = _.orderBy(result, [query.orderBy.field], [query.orderDirection])
        }

        if (result.length !== 0) {
          let LastRow = { barcode: 0, cts: "", rapAmt: 0, amtZar: 0, amtRs: 0 };
          LastRow.rapAmt = _.sum(_.map(result, "rapAmt")).toFixed(2);
          LastRow.cts = _.sum(_.map(result, "cts")).toFixed(2);
          LastRow.amtZar = _.sum(_.map(result, "amtZar")).toFixed(2);
          // LastRow.amtRs = _.sum(_.map(result, "amtRs")).toFixed(2);
          LastRow.barcode = result.length;
          // result.push(LastRow);
          setTotalRows(LastRow)
        }
        // FooterRowCalculation(result.length);

        resolve({
          data: result,
        });
      }
    });
  };

  const [addNewIssueReturnDetailMutation] = useMutation(ADD_ISSUERETURN_DETAIL);
  const [updateIssueReturnDetailMutation] = useMutation(
    UPDATE_ISSUERETURN_DETAIL
  );

  const [deleteIssueReturnDetailMutation] = useMutation(
    DELETE_ISSUERETURN_DETAIL
  );

  const AddNewIssueReturnChild = (newData) => {
    return new Promise((resolve, reject) => {
      refetch()
      newData.cts = parseFloat(newData.cts);
      newData.labId = newData?.labId?.id || newData?.labId || null;
      newData.colorId = newData?.colorId?.id || newData?.colorId || null;
      newData.shapeId = newData?.shapeId?.id || newData.shapeId || null;
      newData.purityId = newData?.purityId?.id || newData?.purityId || null;
      newData.cutId = newData?.cutId?.id || newData?.cutId || null;
      newData.polishId = newData?.polishId?.id || newData?.polishId || null;
      newData.symmId = newData?.symmId?.id || newData?.symmId || null;
      newData.fluId = newData?.fluId?.id || newData?.fluId || null;
      newData.issueReturnMasterId = props.Ids;
      newData.issueNo = props.IssueReturnMasterData.issueNo;
      newData.partyId = props.IssueReturnMasterData.partyId.id;
      newData.type = props.IssueReturnMasterData.type;

      let result = [...data?.getAllIssueReturnDetail || []]

      newData.gradePer = parseFloat(Tofixed(newData.gradePer));
      newData.stkDiscPer = parseFloat(Tofixed(newData.stkDiscPer));
      newData.rateZar = parseFloat(Tofixed(newData.rateZar));
      newData.amtZar = parseFloat(Tofixed(NetZarAmtCalculator(newData)));
      newData.rateRap = parseFloat(Tofixed(newData.rateRap));
      newData.rapAmt = parseFloat(Tofixed(NetRapeAmtCalculator(newData)));
      newData.rateAfterDisc = parseFloat(Tofixed(NetRateAfterDiscount(newData)));
      newData.totalCts = parseFloat(
        Tofixed(_.sum(_.map(result, "cts")) + newData.cts)
      );
      newData.totalAmt = parseFloat(
        Tofixed(_.sum(_.map(result, "rapAmt")) + newData.rapAmt)
      );
      newData.totalAmtZar = parseFloat(
        Tofixed(_.sum(_.map(result, "amtZar")) + newData.amtZar)
      );
      newData.totalPcs = parseInt(result.length) + 1;

      if (!newData.setids) {
        reject();
        toast.warn("Packet no is in progress");
      } else {
        delete newData.setids;
        delete newData.lab;
        if (newData.lotNoId && newData.pktNoId && newData.labId && newData.cts) {
          let log = {
            action: 'INSERT',
            actionOn: 'IssueReturnDetail',
            actionName: 'addNewIssueReturnDetail',
            oldValue: "",
            message: `Issue Return Detail was created.`
          }
          addNewIssueReturnDetailMutation({ variables: { input: newData, log } })
            .then((data) => {
              toast.success("issue return master detail created successfully.");
              refetch()
              resolve();
            })
            .catch((e) => {
              reject();
              toast.error(FormatError(e));
            });
          } else {
            reject();
            toast.warn("please, fill all required(*) fields.");
          }
      }
    });
  };

  const UpdateNewIssueReturnChild = (newData, oldData) => {
    return new Promise((resolve, reject) => {
      refetch()
      delete newData.createdBy;
      delete newData.createdAt;
      delete newData.updatedAt;
      delete newData.updatedBy;
      delete newData.__typename;
      newData.cts = parseFloat(newData.cts);
      newData.lotNoId = newData?.lotNoId?.id;
      newData.pktNoId = newData?.pktNoId?.id;
      newData.labId = newData?.lab || newData?.labId?.id || null;
      newData.colorId = newData?.colorId?.id || newData?.colorId || null;
      newData.shapeId = newData?.shapeId?.id || newData?.shapeId || null;
      newData.purityId = newData?.purityId?.id || newData?.purityId || null;
      newData.cutId = newData?.cutId?.id || newData?.cutId || null;
      newData.polishId = newData?.polishId?.id || newData?.polishId || null;
      newData.symmId = newData?.symmId?.id || newData?.symmId || null;
      newData.fluId = newData?.fluId?.id || newData?.fluId || null;
      newData.gradePer = parseFloat(Tofixed(newData?.gradePer || 0));
      newData.rapAmt = parseFloat(Tofixed(NetRapeAmtCalculator(newData)));
      newData.rateRap = parseFloat(Tofixed(newData?.rateRap || 0));
      newData.stkDiscPer = parseFloat(Tofixed(newData?.stkDiscPer || 0));
      newData.rateAfterDisc = parseFloat(Tofixed(NetRateAfterDiscount(newData)));
      newData.rateZar = parseFloat(Tofixed(newData?.rateZar || 0));
      newData.amtZar = parseFloat(Tofixed(NetZarAmtCalculator(newData)));
      newData.rateRs = parseFloat(Tofixed(newData?.rateRs || 0));
      newData.issueReturnMasterId = props.Ids;
      delete newData.lab
      if (newData.lotNoId && newData.pktNoId && newData.labId && newData.cts) {
        let log = {
          action: 'UPDATE',
          actionOn: 'IssueReturnDetail',
          actionName: 'updateIssueReturnDetail',
          oldValue: JSON.stringify(oldData),
          message: `Issue Return Detail of packet name ${oldData?.pktNoId?.pktNo || ''} was updated.`
        }
        updateIssueReturnDetailMutation({ variables: { input: newData, log } })
          .then((data) => {
            toast.success("issue return master detail updated successfully.");
            resolve();
          })
          .catch((e) => {
            reject();
            toast.error(FormatError(e));
          });
      } else {
        reject();
        toast.warn("please, fill all required(*) fields.");
      }
    });
  };

  const DeleteNewIssueReturnChild = (oldData) => {
    return new Promise((resolve, reject) => {
      refetch()
      let log = {
        action: 'DELETE',
        actionOn: 'IssueReturnDetail',
        actionName: 'deleteIssueReturnDetail',
        oldValue: JSON.stringify(oldData),
        message: `Issue Return Detail of packet name ${oldData?.pktNoId?.pktNo || ''} was deleted.`
      }
      deleteIssueReturnDetailMutation({ variables: { id: oldData.id, log } })
        .then((data) => {
          toast.success("issue return master detail deleted successfully.");
          resolve();
        })
        .catch((e) => {
          reject();
          toast.error(FormatError(e));
        });
    });
  };
  useEffect(() => {
    tableRef.current && tableRef.current.onQueryChange();
  }, [data]);

  const addButton = (val) => {
    if (val || role === "admin")
      return { onRowAdd: (newData) => AddNewIssueReturnChild(newData) };
    else return {};
  };

  return (
    <Grid container direction="row" justify="flex-end" item xs={12} style={{ position: "relative" }}>
      <div style={{ position: "absolute", zIndex: 1, left: 200, display: "flex", alignItems: "center" }}>
        <div style={{ paddingRight: 20 }}>
          <Button variant="contained" className="btn btn-primary" onClick={() => {
            history.push({
              pathname: `/addissuereturn/${props.Ids}`,
              state: { PartyIdDefault: PartyIdDefault, issueReturnType: issueReturnType, issueNo: props?.IssueReturnMasterData?.issueNo }
            })
          }} >Add </Button>
        </div>
        <div>
          {["APPRO RETURN", "LAB RETURN", "REPAIR RETURN"].includes(issueChildReturnType) &&
            <Button variant="contained" className="btn btn-primary" onClick={handleOpen} >Add Muliple Data</Button>
          }
        </div>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <div style={{ width: 250 }}> Total PKT :-  {TotalRows?.barcode || 0}</div>
          <div style={{ width: 250 }}> Total cts :- {TotalRows?.cts || 0} </div>
          <div style={{ width: 250 }}> Total Rape Amount  :- {TotalRows?.rapAmt || 0} </div>
          <div style={{ width: 250 }}> Total Zar Amount  :- {TotalRows?.amtZar || 0} </div>
        </div>
      </div>

      <div className="childTable">
        <MaterialTable
          tableRef={tableRef}
          columns={columns}
          data={(query) => IssueReturnMasterChildDetail(query)}
          options={{
            emptyRowsWhenPaging: false,
            padding: "dense",
            pageSize: 10,
            pageSizeOptions: [5, 10, 15, 20, 25, 50],
            addRowPosition: "first",
            filtering: false,
            sorting: true,
            search: false,
            paging: false,
            filterheaderStyle: { padding: "5px" },
            maxBodyHeight: window.innerHeight - 215,
            showTitle: false,
            selection: true,
            headerStyle: { backgroundColor: 'whitesmoke' }
          }}
          onSelectionChange={(rows) => {
            let Ids = _.map(rows, (d) => d.id)
            props.setSelectedRowData([props?.IssueReturnMasterData])
            return props?.setselectedChildRowData(Ids)
          }}
          editable={{
            isEditHidden: (rowData) =>
              role === "admin" ? false : !permission.isUpdate,
            isDeleteHidden: (rowData) =>
              role === "admin" ? false : !permission.isDelete,
            ...addButton(permission?.isInsert),
            // onRowAdd: (newData) => AddNewIssueReturnChild(newData),
            onRowUpdate: (newData, oldData) =>
              UpdateNewIssueReturnChild(newData, oldData),
            onRowDelete: (oldData) => DeleteNewIssueReturnChild(oldData),
          }}
        />
      </div>

      <Modal open={open} onClose={handleClose}>
        <div style={modalStyle} className={classes.paper}>
          <MultiplalAddInTable
            pricelistData={pricelistData}
            handleClose={handleClose}
            issueNo={props.IssueReturnMasterData.issueNo}
            issueReturnMasterId={props.Ids}
            status={issueChildReturnType}
            PartyIdDefault={PartyIdDefault}
            getShortCodeData={getShortCodeData}
            shortCodeData={shortCodeData}
          />
        </div>
      </Modal>
    </Grid>
  );
};

