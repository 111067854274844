import gql from 'graphql-tag';

const MAIL_SETTING = gql`
    query getMailSetting($page: Int, $limit: Int, $filter: String, $sort: mailSettingSort!){
      getMailSetting(page:$page,limit:$limit,filter:$filter,sort:$sort){
        count
        data{
          id
          transID
          departmentName
          mailID
          password
          isActive
          createdBy
          updatedBy
          createdAt
          updatedAt
        }
      }
    }
`;

export default MAIL_SETTING

