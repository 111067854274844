import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { ALLBROKERDATA } from '../../../pages/Master/PartyMaster/PartyMasterQuery';
import Autocomplete from '@material-ui/lab/Autocomplete';
import _ from 'lodash'
import TextField from '@material-ui/core/TextField';

export const BrokerSelectData = (TYPE) => {
  TYPE = TYPE === "LAB1" ? "LAB" : TYPE
  const { data } = useQuery(ALLBROKERDATA, {
    variables: { type: TYPE },
    fetchPolicy: 'cache-and-network',
  })
  let Brokernewdata = data?.getSelectedParties || []
  return Brokernewdata
}

export const BrokerLookup = (TYPE) => {
  let Data = BrokerSelectData(TYPE)
  let newBrokerdata = {}
  Data.map((d, index) => {
    newBrokerdata[d.id] = d.partyName
    return null
  })

  return newBrokerdata
}

export const BrokerSelectComponent = (props) => {
  let Data = BrokerSelectData(props.TYPE)
  const changeselect = (e, newValue) => {
    let propsData = props.props
    let Data = { ...propsData.rowData }
    if (props.TYPE === "BROKER")
      Data.brokerId = newValue.id
    else if (props.TYPE === "LAB") {
      Data.lab = newValue.id
    }
    else if (props.TYPE === "LAB1") {
      Data.labId = newValue.id
    } else if (props.TYPE === "BANK") {
      Data.bankId = newValue.id
    }
    else
      Data.salesmanId = newValue.id
    propsData.onRowDataChange(Data)
  }
  const defaultProps = {
    options: Data,
    getOptionLabel: (option) => option.partyName
  };
  let DefaultValues = _.find(Data, (newd) => newd.id === props.valueDefault)
  return <Autocomplete
    {...defaultProps}
    disableClearable
    onChange={(e, newValue) => changeselect(e, newValue)}
    value={DefaultValues}
    renderInput={(params) => <TextField   {...params} margin="normal" />}
  />

}