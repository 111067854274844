import React, { useState, useEffect, useRef } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks'
import MaterialTable from 'material-table';
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { GRADING } from './GradQuery';
import { ADD_GRADINGMASTER, UPDATE_GRADINGMASTER, DELETE_GRADINGMASTER } from './GradMutation';
import _ from 'lodash';
import moment from 'moment';
import { GETALLSHORTCODETYPE } from '../../Utility/SortCode/SortCodeQuery';
import { GradingSelectComponent, GradingLookup } from '../../../components/DropdownSelect/SelectComponents/ShortCodeSelectComponent'
import { LotNoSelectComponent, LotNoData } from '../../../components/DropdownSelect/SelectComponents/LotSelectComponent'
import { PacketSelectComponent } from '../../../components/DropdownSelect/SelectComponents/PktNoSelectComponent'
import { GradingDetailFromRoughStock } from '../../../components/DropdownSelect/GradingDetailFromRoughStock'
import { UserListAllData } from "../../../components/DropdownSelect/SelectComponents/UserNameSelectComponent";
import { headerStyle, cellStyle } from '../../../components/Comman/StyleCell'
import { FormatError } from '../../../components/Comman/FormatError'
import { CustomFilterComponent } from '../../../components/CustomFilterComponent/index'
import { Tofixed } from '../../../components/Comman/Tofix'
const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1, },
  noMargin: { margin: 0 }
}));

const columns = [
  {
    title: 'Date*',
    field: 'gradDate',
    type: "date",
    render: (rowData) => {
      if (rowData.gradDate)
        return moment(rowData?.gradDate || 0).format('DD/MM/YYYY')
    },
    initialEditValue: moment()
  },
  {
    title: 'Lot No*',
    field: 'lotNoId',
    render: (rowData) => rowData?.lotNoId?.lotNo || "-",
    headerStyle: headerStyle,
    cellStyle: cellStyle,
    editComponent: props => {
      let valueDefault = props?.rowData?.lotNoId?.id || []
      return <LotNoSelectComponent field={"lotNoId"} propsData={props} valueDefault={valueDefault} />
    }
  },
  {
    title: 'Pkt No*',
    field: 'packetNoId',
    filtering: false,
    headerStyle: headerStyle,
    cellStyle: cellStyle,
    render: (rowData) => rowData?.packetNoId?.pktNo || "-",
    editComponent: props => {
      let valueDefault = props?.rowData?.lotNoId?.id || props?.rowData?.lotNoId || 0
      let valueDefaultPacketNo = props?.rowData?.packetNoId?.id || props?.rowData?.packetNoId || 0
      return <PacketSelectComponent field="packetNoId" propsData={props} ids={valueDefault} valueDefaultPacketNo={valueDefaultPacketNo} />
    }
  },
  {
    title: 'Shape*',
    field: 'shapeId',
    headerStyle: headerStyle,
    cellStyle: cellStyle,
    render: (rowData) => rowData?.shapeId?.shortName || "_",
  },
  {
    title: 'Color*',
    field: 'colorId',
    headerStyle: headerStyle,
    cellStyle: cellStyle,
    render: (rowData) => rowData?.colorId?.shortName || "_",
  },
  {
    title: 'Purity*',
    field: 'purityId',
    headerStyle: headerStyle,
    cellStyle: cellStyle,
    render: (rowData) => rowData?.purityId?.shortName || "_",
  },
  {
    title: 'Polish*',
    field: 'polishId',
    headerStyle: headerStyle,
    cellStyle: cellStyle,
    render: (rowData) => rowData?.polishId?.shortName || "_",
  },
  {
    title: 'Flo*',
    field: 'flourenceId',
    headerStyle: headerStyle,
    cellStyle: cellStyle,
    render: (rowData) => rowData?.flourenceId?.shortName || "_",
  },
  {
    title: 'Milky*',
    field: 'milkyId',
    headerStyle: headerStyle,
    cellStyle: cellStyle,
    render: (rowData) => rowData?.milkyId?.shortName || "NONE",
  },
  {
    title: 'Center Black*',
    field: 'centerBlackId',
    headerStyle: headerStyle,
    cellStyle: cellStyle,
    render: (rowData) => rowData?.centerBlackId?.shortName || 0,
  },
  {
    title: 'Center White*',
    field: 'centerWhiteId',
    headerStyle: headerStyle,
    cellStyle: cellStyle,
    render: (rowData) => rowData?.centerWhiteId?.shortName || 0,
  },
  {
    title: 'Table Open*',
    field: 'tableOpenId',
    headerStyle: headerStyle,
    cellStyle: cellStyle,
    render: (rowData) => rowData?.tableOpenId?.shortName || 0,
  },
  {
    title: 'Pavillion Open*',
    field: 'pavillionId',
    headerStyle: headerStyle,
    cellStyle: cellStyle,
    render: (rowData) => rowData?.pavillionId?.shortName || 0,
  },
  {
    title: 'Weight*',
    field: 'weight',
    render: (rowData) => Tofixed(rowData?.weight || 0),
  },
  {
    title: 'Tint*',
    field: 'tintId',
    headerStyle: headerStyle,
    cellStyle: cellStyle,
    render: (rowData) => rowData?.tintId?.shortName || "SELECT TINT",
  },
  {
    title: 'Cut*',
    field: 'cutId',
    headerStyle: headerStyle,
    cellStyle: cellStyle,
    render: (rowData) => rowData?.cutId?.shortName || "_",
  },
  {
    title: 'Symm*',
    field: 'symmId',
    headerStyle: headerStyle,
    cellStyle: cellStyle,
    render: (rowData) => rowData?.symmId?.shortName || "_",
  },
  {
    title: 'H&A*',
    field: 'hAndAId',
    headerStyle: headerStyle,
    cellStyle: cellStyle,
    render: (rowData) => rowData?.hAndAId?.shortName || "_",
  },
  {
    title: 'Eye Clean*',
    field: 'eyeCleanId',
    headerStyle: headerStyle,
    cellStyle: cellStyle,
    render: (rowData) => rowData?.eyeCleanId?.shortName || "SELECT EYE CLEAN",
  },
  {
    title: 'Side Black*',
    field: 'sideBlackId',
    headerStyle: headerStyle,
    cellStyle: cellStyle,
    render: (rowData) => rowData?.sideBlackId?.shortName || 0,
  },
  {
    title: 'Side White*',
    field: 'sideWhiteId',
    headerStyle: headerStyle,
    cellStyle: cellStyle,
    render: (rowData) => rowData?.sideWhiteId?.shortName || 0,
  },
  {
    title: 'Crown Open*',
    field: 'crownOpenId',
    headerStyle: headerStyle,
    cellStyle: cellStyle,
    render: (rowData) => rowData?.crownOpenId?.shortName || 0,
  },
  {
    title: 'Comment1',
    headerStyle: headerStyle,
    cellStyle: cellStyle,
    field: 'comment1'
  },
  {
    title: 'Comment2',
    headerStyle: headerStyle,
    cellStyle: cellStyle,
    field: 'comment2'
  },
  { title: "User", field: "createdBy", render: (rowData) => rowData?.createdBy?.userName || " ", editable: "never" }

]

function Grad(props) {
  const classes = useStyles();
  const tableRef = useRef();

  const [pageSize, setPageSize] = useState(10)
  const [selectedRow, setSelectedRow] = useState(null);

  const permissions = props?.session?.me?.role?.permissions;
  const role = props?.session?.me?.role?.role;
  const path = props?.location?.pathname;
  let permission = _.find(permissions, function (o) { return o.name === path; })

  const [addGradingMutation] = useMutation(ADD_GRADINGMASTER)
  const [updateGradingMutation] = useMutation(UPDATE_GRADINGMASTER)
  const [deleteGradingMutation] = useMutation(DELETE_GRADINGMASTER)


  const { fetchMore, subscribeToMore  , refetch} = useQuery(GRADING, {
    variables: { page: 1, limit: 10, filter: JSON.stringify({}), sort: { key: 'createdAt', type: -1 } },
    fetchPolicy: 'cache-and-network'
  })

  const { data: shortCodeData } = useQuery(GETALLSHORTCODETYPE, {
    variables: { type: "All" },
    fetchPolicy: 'cache-and-network',
  })

  const getShortCodeData = (TYPE) => {
    return _.filter(shortCodeData?.getAllType || [], (d) => d.type === TYPE);
  }

  const setdefaultValues = (Value) => {
    return Value?.id || Value
  }
  columns.map((d, key) => {
    if (d.field === "createdBy") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: UserListAllData(), getOptionLabel: (option) => option.userName, multiple: true }} />
      }
    }
    if (d.field === "lotNoId") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: LotNoData(), getOptionLabel: (option) => option.lotNo, multiple: true }} />
      }
    }
    if (d.field === "colorId") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: getShortCodeData("COLOR"), getOptionLabel: (option) => option.shortName, multiple: true }} />
      }
      d.editComponent = (props) => {
        let lotNoId = props?.rowData?.lotNoId?.id || props?.rowData?.lotNoId || null
        let pktNoId = props?.rowData?.packetNoId?.id || props?.rowData?.packetNoId || null
        if (lotNoId && pktNoId) {
          let GradingData = GradingDetailFromRoughStock(lotNoId, pktNoId)
          let Data = { ...props.rowData }
          let Ids = GradingData[0]?.id || null
          let Ids2 = Data?.setids || ''
          if (Ids && Ids2 !== Ids) {
            Data.colorId = GradingData[0]?.colorId || "-"
            Data.flourenceId = GradingData[0]?.fluId || "-"
            Data.shapeId = GradingData[0]?.shapeId || "-"
            Data.purityId = GradingData[0]?.purityId || "-"
            Data.cutId = GradingData[0]?.cutId || "-"
            Data.polishId = GradingData[0]?.polishId || "-"
            Data.symmId = GradingData[0]?.symmId || "-"
            Data.weight = GradingData[0]?.issueCts === 0 ? GradingData[0].orgCts : GradingData[0].issueCts
            Data.setids = GradingData[0].id
            props.onRowDataChange(Data)
          }
          if (props?.rowData?.colorId || null) {
            return <GradingSelectComponent key={key} propsData={props} TYPE="colorId" data={getShortCodeData("COLOR")} valueDefault={setdefaultValues(props?.rowData?.colorId) || "-"} />
          } else {
            return "-"
          }
        } else {
          return "-"
        }
      }
    }
    if (d.field === "shapeId") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: getShortCodeData("SHAPE"), getOptionLabel: (option) => option.shortName, multiple: true }} />
      }
      d.editComponent = (props) => {
        if (props?.rowData?.shapeId || null)
          return <GradingSelectComponent key={key} propsData={props} TYPE="shapeId" data={getShortCodeData("SHAPE")} valueDefault={setdefaultValues(props?.rowData?.shapeId) || "-"} />
        else
          return "-"
      }
    }
    if (d.field === "purityId") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: getShortCodeData("PURITY"), getOptionLabel: (option) => option.shortName, multiple: true }} />
      }
      d.editComponent = (props) => {
        if (props?.rowData?.purityId || null)
          return <GradingSelectComponent propsData={props} TYPE="purityId" data={getShortCodeData("PURITY")} valueDefault={setdefaultValues(props?.rowData?.purityId) || "-"} />
        else
          return "-"
      }
    }
    if (d.field === "polishId") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: getShortCodeData("POLISH"), getOptionLabel: (option) => option.shortName, multiple: true }} />
      }
      d.editComponent = (props) => {
        if (props?.rowData?.polishId || null)
          return <GradingSelectComponent key={key} propsData={props} TYPE="polishId" data={getShortCodeData("POLISH")} valueDefault={setdefaultValues(props?.rowData?.polishId) || "-"} />
        else
          return "-"
      }
    }
    if (d.field === "flourenceId") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: getShortCodeData("FLOURSENCE"), getOptionLabel: (option) => option.shortName, multiple: true }} />
      }
      d.editComponent = (props) => {
        if (props?.rowData?.flourenceId || null)
          return <GradingSelectComponent propsData={props} TYPE="flourenceId" data={getShortCodeData("FLOURSENCE")} valueDefault={setdefaultValues(props?.rowData?.flourenceId) || "-"} />
        else
          return "-"
      }
    }
    if (d.field === "milkyId") {
      d.lookup = GradingLookup(getShortCodeData("MILKY"))
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: getShortCodeData("MILKY"), getOptionLabel: (option) => option.shortName, multiple: true }} />
      }
      d.editComponent = (props) => {
        return <GradingSelectComponent key={key} propsData={props} TYPE="milkyId" data={getShortCodeData("MILKY")} valueDefault={setdefaultValues(props.rowData.milkyId)} />
      }
    }
    if (d.field === "centerBlackId") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: getShortCodeData("CENTER BLACK"), getOptionLabel: (option) => option.shortName, multiple: true }} />
      }
      d.editComponent = (props) => {
        return <GradingSelectComponent key={key} propsData={props} TYPE="centerBlackId" data={getShortCodeData("CENTER BLACK")} valueDefault={setdefaultValues(props.rowData.centerBlackId)} />
      }
    }
    if (d.field === "centerWhiteId") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: getShortCodeData("CENTER WHITE"), getOptionLabel: (option) => option.shortName, multiple: true }} />
      }
      d.editComponent = (props) => {
        return <GradingSelectComponent key={key} propsData={props} TYPE="centerWhiteId" data={getShortCodeData("CENTER WHITE")} valueDefault={setdefaultValues(props.rowData.centerWhiteId)} />
      }
    }
    if (d.field === "tableOpenId") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: getShortCodeData("TABLE OPEN"), getOptionLabel: (option) => option.shortName, multiple: true }} />
      }
      d.editComponent = (props) => {
        return <GradingSelectComponent key={key} propsData={props} TYPE="tableOpenId" data={getShortCodeData("TABLE OPEN")} valueDefault={setdefaultValues(props.rowData.tableOpenId)} />
      }
    }
    if (d.field === "pavillionId") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: getShortCodeData("PAVILLION OPEN"), getOptionLabel: (option) => option.shortName, multiple: true }} />
      }
      d.editComponent = (props) => {
        return <GradingSelectComponent key={key} propsData={props} TYPE="pavillionId" data={getShortCodeData("PAVILLION OPEN")} valueDefault={setdefaultValues(props.rowData.pavillionId)} />
      }
    }
    if (d.field === "tintId") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: getShortCodeData("TINT"), getOptionLabel: (option) => option.shortName, multiple: true }} />
      }
      d.editComponent = (props) => {
        return <GradingSelectComponent key={key} propsData={props} TYPE="tintId" data={getShortCodeData("TINT")} valueDefault={setdefaultValues(props.rowData.tintId)} />
      }
    }
    if (d.field === "cutId") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: getShortCodeData("CUT"), getOptionLabel: (option) => option.shortName, multiple: true }} />
      }
      d.editComponent = (props) => {
        if (props?.rowData?.cutId || null)
          return <GradingSelectComponent key={key} propsData={props} TYPE="cutId" data={getShortCodeData("CUT")} valueDefault={setdefaultValues(props?.rowData?.cutId) || "-"} />
        else
          return "-"
      }
    }
    if (d.field === "symmId") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: getShortCodeData("SYMMENTRY"), getOptionLabel: (option) => option.shortName, multiple: true }} />
      }
      d.editComponent = (props) => {
        if (props?.rowData?.symmId || null)
          return <GradingSelectComponent key={key} propsData={props} TYPE="symmId" data={getShortCodeData("SYMMENTRY")} valueDefault={setdefaultValues(props?.rowData?.symmId) || "-"} />
        else
          return "-"
      }
    }
    if (d.field === "hAndAId") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: getShortCodeData("H & A"), getOptionLabel: (option) => option.shortName, multiple: true }} />
      }
      d.editComponent = (props) => {
        return <GradingSelectComponent key={key} propsData={props} TYPE={"hAndAId"} data={getShortCodeData("H & A")} valueDefault={setdefaultValues(props.rowData.hAndAId)} />
      }
    }
    if (d.field === "eyeCleanId") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: getShortCodeData("EYE CLEAN"), getOptionLabel: (option) => option.shortName, multiple: true }} />
      }
      d.editComponent = (props) => {
        return <GradingSelectComponent key={key} propsData={props} TYPE={"eyeCleanId"} data={getShortCodeData("EYE CLEAN")} valueDefault={setdefaultValues(props.rowData.eyeCleanId)} />
      }
    }
    if (d.field === "sideBlackId") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: getShortCodeData("SIDE BLACK"), getOptionLabel: (option) => option.shortName, multiple: true }} />
      }
      d.editComponent = (props) => {
        return <GradingSelectComponent key={key} propsData={props} TYPE={"sideBlackId"} data={getShortCodeData("SIDE BLACK")} valueDefault={setdefaultValues(props.rowData.sideBlackId)} />
      }
    }
    if (d.field === "sideWhiteId") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: getShortCodeData("SIDE WHITE"), getOptionLabel: (option) => option.shortName, multiple: true }} />
      }
      d.editComponent = (props) => {
        return <GradingSelectComponent key={key} propsData={props} TYPE={"sideWhiteId"} data={getShortCodeData("SIDE WHITE")} valueDefault={setdefaultValues(props.rowData.sideWhiteId)} />
      }
    }
    if (d.field === "crownOpenId") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: getShortCodeData("CROWN OPEN"), getOptionLabel: (option) => option.shortName, multiple: true }} />
      }
      d.editComponent = (props) => {
        return <GradingSelectComponent key={key} propsData={props} TYPE={"crownOpenId"} data={getShortCodeData("CROWN OPEN")} valueDefault={setdefaultValues(props.rowData.crownOpenId)} />
      }
    }
    return null
  })

  const getGradindData = (query) => {
    refetch()
    return new Promise(async (resolve, reject) => {
      let filter = {}, sort = { key: 'createdAt', type: -1 }
      query && query.filters.forEach(item => {
        if ((item.column.field === "gradDate")) {
          if (item.value)
            filter = { ...filter, [item.column.field]: moment(item.value).format("YYYY-MM-DD") }
          else {
            delete filter[item.column.field]
            filter = { ...filter }
          }
        }
        else if ((item.column.field === "weight")) {
          if (item.value)
            filter = { ...filter, [item.column.field]: parseFloat(item.value) }
          else {
            delete filter[item.column.field]
            filter = { ...filter }
          }
        }
        else if (item.value.length === 0) {
          delete filter[`${item.column.field}`]
          filter = { ...filter }
        } else
          filter = { ...filter, [item.column.field]: item.value }
      });
      if (query && query.orderBy && query.orderDirection !== "") {
        sort = { key: query.orderBy.field, type: query.orderDirection === 'asc' ? 1 : -1 }
      }
      setPageSize(query.pageSize)
      try {
        let result = await fetchMore({
          variables: { page: query.page + 1, limit: query.pageSize, filter: JSON.stringify(filter), sort: sort },
          fetchPolicy: 'network-only',
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return fetchMoreResult;
          }
        })
        resolve({
          data: result?.data?.getGradingMaster?.data || [],
          page: query.page,
          totalCount: result?.data?.getGradingMaster?.count || 0,
        })
      } catch (error) {
        console.log(FormatError(error))
      }
    })
  }

  const AddGrading = (newData) => {
    return new Promise((resolve, reject) => {
      refetch()
      delete newData.setids
      newData.weight = parseFloat(newData.weight).toFixed(3)
      newData.weight = parseFloat(newData.weight)
      newData.colorId = newData?.colorId?.id || newData?.colorId || null
      newData.shapeId = newData?.shapeId?.id || newData?.shapeId || null
      newData.purityId = newData?.purityId?.id || newData?.purityId
      newData.cutId = newData?.cutId?.id || newData?.cutId || null
      newData.polishId = newData?.polishId?.id || newData?.polishId || null
      newData.symmId = newData?.symmId?.id || newData?.symmId || null
      newData.flourenceId = newData?.flourenceId?.id || newData?.flourenceId || null
      newData.milkyId = newData?.milkyId?.id || newData?.milkyId || null
      newData.centerBlackId = newData?.centerBlackId?.id || newData?.centerBlackId || null
      newData.centerWhiteId = newData?.centerWhiteId?.id || newData?.centerWhiteId || null
      newData.tableOpenId = newData?.tableOpenId?.id || newData?.tableOpenId || null
      newData.pavillionId = newData?.pavillionId?.id || newData?.pavillionId || null
      newData.tintId = newData?.tintId?.id || newData?.tintId || null
      newData.hAndAId = newData?.hAndAId?.id || newData?.hAndAId || null
      newData.eyeCleanId = newData?.eyeCleanId?.id || newData?.eyeCleanId || null
      newData.sideBlackId = newData?.sideBlackId?.id || newData?.sideBlackId || null
      newData.sideWhiteId = newData?.sideWhiteId?.id || newData?.sideWhiteId || null
      newData.crownOpenId = newData?.crownOpenId?.id || newData?.crownOpenId || null
      newData.lotNoId = newData?.lotNoId?.id || newData.lotNoId
      newData.packetNoId = newData?.packetNoId?.id || newData.packetNoId
      if (newData.shapeId && newData.colorId && newData.purityId && newData.polishId && newData.symmId && newData.flourenceId && newData.milkyId && newData.centerBlackId && newData.centerWhiteId && newData.tableOpenId && newData.pavillionId && newData.tintId && newData.hAndAId && newData.eyeCleanId && newData.sideBlackId && newData.sideWhiteId && newData.crownOpenId && newData.cutId && newData.weight) {
        let log = {
          action: 'INSERT',
          actionOn: 'GradingMaster',
          actionName: 'addNewGrading',
          oldValue: "",
          message: `Grading was created.`
        }
        addGradingMutation({ variables: { input: newData, log } }).then((data) => {
          toast.success('grading created successfully.');
          resolve()
        }).catch((e) => {
          toast.error(FormatError(e))
          reject()
        })
      }
      else {
        toast.warn('please, fill all required(*) fields.')
        reject()
      }
    })
  }

  const updateGrading = (newData, oldData) => {
    return new Promise((resolve, reject) => {
      refetch()
      delete newData.createdAt
      delete newData.createdBy
      delete newData.updatedAt
      delete newData.updatedBy
      delete newData.__typename
      delete newData.setids
      newData.colorId = newData?.colorId?.id || newData?.colorId || null
      newData.shapeId = newData?.shapeId?.id || newData?.shapeId || null
      newData.purityId = newData?.purityId?.id || newData?.purityId
      newData.cutId = newData?.cutId?.id || newData?.cutId || null
      newData.polishId = newData?.polishId?.id || newData?.polishId || null
      newData.symmId = newData?.symmId?.id || newData?.symmId || null
      newData.flourenceId = newData?.flourenceId?.id || newData?.flourenceId || null
      newData.milkyId = newData?.milkyId?.id || newData?.milkyId || null
      newData.centerBlackId = newData?.centerBlackId?.id || newData?.centerBlackId || null
      newData.centerWhiteId = newData?.centerWhiteId?.id || newData?.centerWhiteId || null
      newData.tableOpenId = newData?.tableOpenId?.id || newData?.tableOpenId || null
      newData.pavillionId = newData?.pavillionId?.id || newData?.pavillionId || null
      newData.tintId = newData?.tintId?.id || newData?.tintId || null
      newData.hAndAId = newData?.hAndAId?.id || newData?.hAndAId || null
      newData.eyeCleanId = newData?.eyeCleanId?.id || newData?.eyeCleanId || null
      newData.sideBlackId = newData?.sideBlackId?.id || newData?.sideBlackId || null
      newData.sideWhiteId = newData?.sideWhiteId?.id || newData?.sideWhiteId || null
      newData.crownOpenId = newData?.crownOpenId?.id || newData?.crownOpenId || null
      newData.lotNoId = newData?.lotNoId?.id || newData.lotNoId
      newData.packetNoId = newData?.packetNoId?.id || newData.packetNoId
      newData.weight = parseFloat(newData.weight).toFixed(3)
      newData.weight = parseFloat(newData.weight)
      if (newData.shapeId && newData.colorId && newData.purityId && newData.polishId && newData.symmId && newData.flourenceId && newData.milkyId && newData.centerBlackId && newData.centerWhiteId && newData.tableOpenId && newData.pavillionId && newData.tintId && newData.hAndAId && newData.eyeCleanId && newData.sideBlackId && newData.sideWhiteId && newData.crownOpenId && newData.cutId && newData.weight) {
        let log = {
          action: 'UPDATE',
          actionOn: 'GradingMaster',
          actionName: 'updateGrading',
          oldValue: JSON.stringify(oldData),
          message: `Grading of packet name ${oldData?.packetNoId?.pktNo || ''} was updated.`
        }
        updateGradingMutation({ variables: { input: newData, log } }).then((data) => {
          toast.success('grading updated successfully.');
          resolve()
        }).catch((e) => {
          toast.error(FormatError(e))
          reject()
        })
      } else {
        toast.warn('please, fill all required(*) fields.')
        reject()
      }
    })
  }

  const DeleteGrading = (oldData) => {

    return new Promise((resolve, reject) => {
      refetch()
      let log = {
        action: 'DELETE',
        actionOn: 'GradingMaster',
        actionName: 'deleteGrading',
        oldValue: JSON.stringify(oldData),
        message: `Grading of packet name ${oldData?.packetNoId?.pktNo || ''} was deleted.`
      }
      deleteGradingMutation({ variables: { id: oldData.id, log } }).then((data) => {
        toast.success('grading deleted successfully.');
        resolve()
      }).catch((e) => {
        toast.error(FormatError(e))
        reject()
      })
      resolve()
    })
  }

  const addButton = (val) => {
    if (val || (role === 'admin'))
      return { onRowAdd: (newData) => AddGrading(newData) }
    else
      return {}
  }
  useEffect(() => {
    document.getElementsByClassName("parentTable")[0].firstElementChild.firstElementChild.firstChild.remove()
  }, [])
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <div className="parentTable">
            <MaterialTable
              title="Grading"
              tableRef={tableRef}
              columns={columns}
              data={query => getGradindData(query)}
              icons={{ Add: props => { return (<div> <i className="fa fa-plus-square" ></i> Grading </div>) } }}
              options={{
                emptyRowsWhenPaging: false,
                showTitle: false,
                debounceInterval: 300,
                padding: 'dense',
                pageSize: pageSize,
                pageSizeOptions: [5, 10, 15, 20, 25, 50],
                addRowPosition: 'first',
                filtering: true,
                sorting: true,
                search: false,
                filterCellStyle: { padding: '5px', },
                maxBodyHeight: window.innerHeight - 215,
                rowStyle: rowData => ({
                  backgroundColor: (selectedRow === rowData.tableData.id) ? 'rgb(212, 218, 255)' : '#f5f5f5'
                }),
                headerStyle: { backgroundColor: 'whitesmoke' }
              }}
              editable={{
                isEditHidden: (rowData) => (role === 'admin') ? false : !permission.isUpdate,
                isDeleteHidden: (rowData) => (role === 'admin') ? false : !permission.isDelete,
                ...addButton(permission?.isInsert),
                onRowUpdate: (newData, oldData) => updateGrading(newData, oldData),
                onRowDelete: (oldData) => DeleteGrading(oldData)
              }}
              onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default withRouter(Grad)