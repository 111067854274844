import { useQuery } from '@apollo/react-hooks'
import { SALESDETAILFROMROUGHSTOCK } from '../../pages/Transaction/Sales/SalesQuery';
import { SALESDETAILFROMSALESSTOCK } from '../../pages/Transaction/Sales/SalesQuery';
export const SalesDetailFromLotNoAndPacketNo = (lotNo, pktNo, TYPE) => {
  const { data } = useQuery(TYPE === "ROUGH" ? SALESDETAILFROMROUGHSTOCK : SALESDETAILFROMSALESSTOCK, {
    variables: { lotNoId: lotNo, pktNoId: pktNo },
    fetchPolicy: 'cache-and-network',
  })
  let Data = TYPE === "ROUGH" ? data?.salesDetailFromRoughStock || [] : data?.salesDetailFromSalesStock || []
  return Data
}

