import React from 'react'
import { Grid, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash'
import { Table } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Tofixed } from '../../../../components/Comman/Tofix'
import { AutoSizer, List, CellMeasurer, CellMeasurerCache } from 'react-virtualized';

const useStyles = makeStyles(() => ({
    AccountTableCss: {
        "& .MuiToolbar-regular": {
            minHeight: 0
        }
    },
    LabelStart: {
        fontSize: 16,
        paddingBottom: 10,
        color: "rgb(70, 81, 142)"
    },
    Labelend: {
        fontSize: 16,
        paddingBottom: 10,
        color: "rgb(70, 81, 142)",
        textAlign: "end"
    },
    denseTable: {
        '& td': { padding: "2px 5px", cursor: "pointer", textAlign: "center" },
        '& th': { padding: "5px 5px", cursor: "pointer", textAlign: "center" }
    },
}))
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#8CB836",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

export const ApproIssueFromRoughStock = ({ data, loading, setFillter }) => {
    const cache = React.useRef(
        new CellMeasurerCache({
            fixedWidth: true,
            defaultHeight: 100
        })
    )
    const classes = useStyles();
    const ListRef = React.useRef();
    let TableData = data?.getApproIssueRoughStock || []

    return <Grid item={true} xs={4} container alignContent="space-between" style={{ float: "left", paddingRight: 5 }}>
        <Grid xs={12} item container>
            <Grid xs={6} item={true}>
                <InputLabel className={classes.LabelStart} >Appro Issue Stock</InputLabel>
            </Grid>
            <Grid xs={6} item={true}>
                <InputLabel className={classes.Labelend}>{`Tot Pkts: ${TableData.length || 0} | Cts: ${Tofixed(_.sumBy(TableData || [], (d) => d.issueCts))}`}</InputLabel>
            </Grid>
        </Grid>

        <Grid xs={12} item className={`${classes.AccountTableCss} DenseTable`}>
            <Grid xs={12} item className={`${classes.AccountTableCss} DenseTable`}>
                <TableContainer component={Paper}>
                    <Table className={classes.denseTable}>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell style={{ width: '20%', textAlign: "center" }}>Lot No.</StyledTableCell>
                                <StyledTableCell style={{ width: '15%', textAlign: "center" }}>Pkt No.</StyledTableCell>
                                <StyledTableCell style={{ width: '50%', textAlign: "center" }}>Party Name</StyledTableCell>
                                <StyledTableCell style={{ width: '25%', textAlign: "center" }}>Cts</StyledTableCell>
                            </TableRow>
                        </TableHead>
                    </Table>
                    <div style={{ width: "100%", height: 200 ,marginBottom:10 }}>
                        <AutoSizer>
                            {({ width, height }) => {
                                return <List
                                    ref={ListRef}
                                    height={height}
                                    width={width}
                                    rowHeight={20}
                                    deferredMeasurementCache={cache.current}
                                    rowCount={TableData.length}
                                    rowRenderer={({ key, index, style, parent }) => {
                                        const rowData = TableData[index]
                                        return <CellMeasurer key={key} cache={cache.current} parent={parent} columnIndex={0} rowIndex={index}>
                                            <div style={style}>
                                                <div className="tbody"  >
                                                    <Table>
                                                        <TableBody>
                                                            <StyledTableRow >
                                                                <StyledTableCell style={{ textAlign: "center", width: "20%" }}>{rowData?.lotNoId?.lotNo || ""}</StyledTableCell>
                                                                <StyledTableCell style={{ textAlign: "center", width: "15%" }}>{rowData?.pktNoId?.pktNo || ""}</StyledTableCell>
                                                                <StyledTableCell style={{ textAlign: "center", width: "50%" }}>{rowData?.issuePartyId?.partyName || ""}</StyledTableCell>
                                                                <StyledTableCell style={{ textAlign: "center", width: "25%" }}>{Tofixed(rowData?.issueCts || 0)}</StyledTableCell>
                                                            </StyledTableRow>
                                                        </TableBody>
                                                    </Table>
                                                </div>
                                            </div>
                                        </CellMeasurer>
                                    }}
                                />
                            }}
                        </AutoSizer>
                    </div>
                </TableContainer>
            </Grid>
        </Grid>

    </Grid>
}