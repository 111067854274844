import React from 'react'
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import _ from 'lodash'
import { RoleAll } from '../Roles'

export const RolesSelectComponent = (props) => {
  let rolesData = RoleAll()
  let roles = rolesData?.data || []

  const changeselect = (e, newValue) => {
    let propsData = props.props
    let Data = { ...propsData.rowData }
    Data.role = newValue.id
    propsData.onRowDataChange(Data)
  }

  const defaultProps = {
    options: roles,
    getOptionLabel: (option) => option.role
  };

  return <Autocomplete
    {...defaultProps}
    disableClearable
    onChange={(e, newValue) => changeselect(e, newValue)}
    defaultValue={_.filter(roles, (newd) => newd.id === props.valueDefault)[0]}
    renderInput={(params) => <TextField   {...params} margin="normal" />}
  />

}