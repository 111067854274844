import React, { useState } from 'react';
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

var CryptoJS = require('crypto-js')

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        backgroundColor: "#47528e"
    },
    buttonProgress: {
        color: "#f50357",
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    textRight: {
        justifyContent: 'flex-end'
    }
}));

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/">
                Kismet Diamonds
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

function Welcome(props) {

    const classes = useStyles();
    const [url, setUrl] = useState("")
    const [loading, setLoading] = useState(false)

    const onSubmit = (event) => {
        event.preventDefault();
        if (url) {
            var ciphertext = CryptoJS.AES.encrypt(url, 'kismet').toString()
            sessionStorage.setItem('be', ciphertext)
            props.history.push('/login');
            setTimeout(() => {
                window.location.reload(false);
            }, 100);

        } else {
            toast.warn("please write url")
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}> <LockOutlinedIcon /> </Avatar>
                <Typography component="h1" variant="h5"> Sign in </Typography>

                <form className={classes.form} noValidate onSubmit={event => onSubmit(event)}>
                    <div className="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-fullWidth MuiInputBase-formControl">
                        <input
                            placeholder="Url*"
                            value={url}
                            onChange={(e) => { setUrl(e.target.value) }}
                            className="disable-input MuiFormControl-root MuiTextField-root MuiFormControl-marginDense  MuiOutlinedInput-notchedOutline"
                            style={{ border: "1px solid" }}
                            autocomplete="off"
                        />
                    </div>

                    <Button type="submit" fullWidth variant="contained" disabled={loading} color="primary" className={classes.submit} >
                        {loading && <CircularProgress size={24} className={classes.buttonProgress} />} Submit
                    </Button>
                </form>

            </div>
            <Box mt={8}> <Copyright /> </Box>
        </Container >
    );
}


export default withRouter(Welcome)










