import { useQuery } from '@apollo/react-hooks'
import { SALESDETAILFROMROUGHSTOCK } from '../../pages/Transaction/Sales/SalesQuery'
export const GradingDetailFromRoughStock = (lotNoId, pktNoId) => {
  const { data } = useQuery(SALESDETAILFROMROUGHSTOCK, {
    variables: { lotNoId: lotNoId, pktNoId: pktNoId },
    fetchPolicy: 'cache-and-network',
  })
  let Data = data?.salesDetailFromRoughStock || []
  return Data
}
