import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks'
// import { Mutation } from 'react-apollo';
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import SendIcon from '@material-ui/icons/Send';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {SEND_RESET_MAIL} from './Master/MailSetting/MailSettingMutation'

// import SIGN_IN from './LoginMutation';

const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
		backgroundColor: "#47528e"
	},
	buttonProgress: {
		color: "#f50357",
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
	textRight: {
		justifyContent: 'flex-end'
	}
}));

function Copyright() {
	return (
		<Typography variant="body2" color="textSecondary" align="center">
			{'Copyright © '}
			<Link color="inherit" href="https://material-ui.com/">
				Kismet Diamonds
      </Link>{' '}
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	);
}

function ForgotPassword(props) {

	const classes = useStyles();
	const [loading, setLoading] = useState(false)
	const [loadingUp, setLoadingUp] = useState(false)
	const [email, setEmail] = useState("")
	const [code, setCode] = useState("")
	const [password, setPassword] = useState("")
	const [confpassword, setConfPassword] = useState("")
	const [isResetPassword, setIsResetPassword] = useState(true)

	const [SendEmailResetPassword] = useMutation(SEND_RESET_MAIL)

	const onSubmitForgot = (e) => {
		e.preventDefault();
		return new Promise((resolve, reject) => {
	
		  if (email && (email !== '' || email !== "")) {
			  	let emailObj = email
				setLoading(true)
				SendEmailResetPassword({ variables: { input: {email:emailObj,isResetPassword:true} } }).then((data) => {
					toast.success('check your email to reset your password');
					setIsResetPassword(false)
					setEmail('')
					resolve()
				}).catch((e) => {
					toast.warn(e.message.replace('GraphQL error: ',''))
					setLoading(false)
					reject()
				})
		  } else {
			toast.warn('please enter email.')
			setLoading(false)
			reject()
		  }
		})
	}

	const onResetPassword = (e) => {
		e.preventDefault();
		return new Promise((resolve, reject) => {
	
			if (code && (code !== '' || code !== "") && (password !== '' || password !== "") && (confpassword !== '' || confpassword !== "")) {
				setLoadingUp(true)
				if(password === confpassword){
					SendEmailResetPassword({ variables: { input: {code:code,password:password,isResetPassword:false} } }).then((data) => {
						toast.success('user password updated successfully');
						setIsResetPassword(true)
						setCode('')
						setPassword('')
						setConfPassword('')
						props.history.push({
							pathname: "/",
						});
						resolve()
					  }).catch((e) => {
						toast.warn("update failed, maybe code is wrong!")
						setLoadingUp(false)
						reject()
					  })
				}
				else{
					toast.warn('password and confirm password not matched')
					setLoadingUp(false)
					reject()
				}
			
			} else {
				toast.warn('please,fill all required(*) fields.')
				setLoadingUp(false)
			  reject()
			}
		  })
	};


	return (
		<Container component="main" maxWidth="xs">
			<CssBaseline />
			<div className={classes.paper}>
				<Avatar className={classes.avatar}> <SendIcon /> </Avatar>
				<Typography component="h1" variant="h5"> Forgot Password </Typography>
			{
				isResetPassword ? 
					<form className={classes.form} onSubmit={onSubmitForgot}>
						 <TextField variant="outlined" margin="normal" required fullWidth id="email" label="Email Address" name="email" autoComplete="email" autoFocus
						value={email} onChange={(e) => { setEmail(e.target.value) }}/>
						<Button 
							type="submit"
							fullWidth variant="contained"
							disabled={loading}
							color="primary" className={classes.submit} >
						   {loading && <CircularProgress color={"white"} size={16} />}
      						{!loading && 'Submit'}
                		</Button>
				</form>: 
					<form className={classes.form} onSubmit={onResetPassword}>
						<TextField variant="outlined" margin="normal" required fullWidth id="code" label="Enter Code" name="code" autoComplete="code" autoFocus
							value={code} onChange={(e) => { setCode(e.target.value) }} />
						
						<TextField variant="outlined" margin="normal" required fullWidth id="password" type="password" label="Enter Password" name="password" autoComplete="password" autoFocus
							value={password} onChange={(e) => { setPassword(e.target.value) }}
						/>
						<TextField variant="outlined" margin="normal" required fullWidth id="confpassword" type="password" label="Enter Confirm Password" name="confpassword" autoComplete="confpassword" autoFocus
							value={confpassword} onChange={(e) => { setConfPassword(e.target.value) }}
						/>
						<Button
							type="submit" 
							fullWidth variant="contained"
							disabled={loadingUp}
							color="primary" className={classes.submit} >
                   		  	{loadingUp && <CircularProgress color={"white"} size={16} />}
      						{!loadingUp && 'Reset'}
                	</Button>
					</form>
			}
				<Grid container className={classes.textRight}>
						<Grid item>
							<Link href="/" variant="body2"> {"remember password? Login"} </Link>
						</Grid>
					</Grid>
				{/* )
          }}
        </Mutation> */}
			</div>
			<Box mt={8}> <Copyright /> </Box>
		</Container>
	);
}


export default withRouter(ForgotPassword)










