/* Libray */
import React, { useState, useEffect } from 'react'
import { withRouter } from "react-router-dom";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { TextField, Button, Typography, Grid } from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks'
import { KAPANWISECOSTING } from './KapanWiseQuery';
import _ from 'lodash'
import PrintIcon from '@material-ui/icons/Print';
import DateFnsUtils from "@date-io/date-fns";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from 'moment'
import GetAppIcon from '@material-ui/icons/GetApp';

/* Component Imports */
import { LotNo } from '../../../components/DropdownSelect/LotNo';
import { CompanyAndPartyAllData } from '../../../components/DropdownSelect/SelectComponents/companyAndPartySelectComponent';
import Rough from './KapanWiseCostingPages/Rough'
import CostZar from './KapanWiseCostingPages/Costzar'
import Sales from './KapanWiseCostingPages/Sales'
import { Tofixed } from '../../../components/Comman/Tofix'
import { Accordion, AccordionSummary, AccordionDetails, useStyles } from '../../../components/Comman/Accordian'
import { PdfView } from './PdfView'
import { ExcelView } from './Excel'
import { PrintDiv, exportTableToExcel } from '../../../components/Comman/exportPdfAndExcel'
import * as XLSX from 'xlsx/xlsx.mjs';
import ExcelPng from '../../../../Excel.png'
/* CSS */
import './kapan.css'

function KapanWiseCosting() {
  const classes = useStyles();
  const [filter, setFilter] = useState({})
  const [AllData, setAllData] = useState([])
  const [partyName, setPartyName] = useState('');
  const [lotNo, setLotNo] = useState('');
  const { data, refetch, loading } = useQuery(KAPANWISECOSTING, {
    variables: { filter: JSON.stringify(filter) },
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    if (lotNo) {
      let Datas = _.orderBy(data?.getKapanWiseCostingReport || [], ['pktNo'])
      let All = _.map(Datas, (d) => {
        d.totalCost = (d.roughCost) + (d.laserCost) + (d.galaxyCost) + (d.polishCost) + (d.certiCost) + (d.expense) + (d.exportCharge) + (d.importCharge) + (d.interest) + (d.majuri) + (d.rateDiff)
        return d
      })
      let Datanew = All && All.filter(d => d.lotNo === "Grand Total")
      if (All.length !== 0 && Datanew.length === 0) {
        let LastRow = {
          lotNo: "Grand Total",
          pktNo: All.length,
          roughCts: Tofixed(_.sum(_.map(All, (rowData) => {
            return (rowData?.roughCts === 0 && rowData?.polishCts === 0 && rowData?.kachuCts === 0 && rowData?.extraTops === 0) ? rowData?.salesStock?.cts || 0 : rowData?.roughCts || 0
          }))),
          polishCts: Tofixed(_.sum(_.map(All, 'polishCts'))),
          kachuCts: Tofixed(_.sum(_.map(All, 'kachuCts'))),
          extraTops: Tofixed(_.sum(_.map(All, 'extraTops'))),
          roughCostDollar: Tofixed(_.sum(_.map(All, 'roughCostDollar'))),
          zarRate: Tofixed(_.sum(_.map(All, 'zarRate'))),
          roughCost: Tofixed(_.sum(_.map(All, 'roughCost'))),
          laserCost: Tofixed(_.sum(_.map(All, 'laserCost'))),
          galaxyCost: Tofixed(_.sum(_.map(All, 'galaxyCost'))),
          boilCost: Tofixed(_.sum(_.map(All, 'boilCost'))),
          heliumCost: Tofixed(_.sum(_.map(All, 'heliumCost'))),
          polishCost: Tofixed(_.sum(_.map(All, 'polishCost'))),
          certiCost: Tofixed(_.sum(_.map(All, 'certiCost'))),
          expense: Tofixed(_.sum(_.map(All, 'expense'))),
          exportCharge: Tofixed(_.sum(_.map(All, 'exportCharge'))),
          importCharge: Tofixed(_.sum(_.map(All, 'importCharge'))),
          interest: Tofixed(_.sum(_.map(All, 'interest'))),
          majuri: Tofixed(_.sum(_.map(All, 'majuri'))),
          adatExpense: Tofixed(_.sum(_.map(All, 'adatExpense'))),
          polishExpense: Tofixed(_.sum(_.map(All, 'polishExpense'))),
          rateDiff: Tofixed(_.sum(_.map(All, 'rateDiff'))),
          totalCost: Tofixed(_.sum(_.map(All, 'totalCost'))),
          salesDetail: {
            polishExpAmt: Tofixed(_.sum(_.map(All, 'salesDetail.polishExpAmt'))),
            netRaprateAmt: Tofixed(_.sum(_.map(All, 'salesDetail.netRaprateAmt'))),
            amountZar: Tofixed(_.sum(_.map(All, 'salesDetail.amountZar'))),
            commision: Tofixed(_.sum(_.map(All, 'salesDetail.commision'))),
            netAmtZar: Tofixed(_.sum(_.map(All, 'salesDetail.netAmtZar'))),
          }
        }
        All.push(LastRow)
      }
      setAllData(All)
    } else {
      setAllData([])
    }
  }, [data, lotNo]);

  let defaultProps = LotNo('', '', '').defaultProps
  let parties = CompanyAndPartyAllData("PARTY");

  const PreViewHandler = () => {
    let Data = {}
    if (lotNo) {
      Data = { ...Data, "lotNoId": lotNo.id }
    }
    if (partyName) {
      Data = { ...Data, "partyId": partyName.id }
    }
    localStorage.setItem("KapanWiseCostingReport", JSON.stringify(Data))
    setFilter(Data, () => {
      refetch()
    })
  }

  const ClearViewHandler = () => {
    setLotNo('')
    setPartyName('')
    localStorage.removeItem("KapanWiseCostingReport")
    setAllData([])
    setFilter({}, () => {
      refetch()
    })
  }

  useEffect(() => {
    let Data = JSON.parse(localStorage.getItem("KapanWiseCostingReport"))
    setLotNo(_.find(defaultProps.options, (d) => d.id === Data?.lotNoId))
    setPartyName(_.find(parties, (d) => d.id === Data?.partyId))
    if (Data)
      setFilter(Data)
  }, [])

  function exportExcelFile(workbook) {
    return XLSX.writeFile(workbook, `kapanWiseCostingReport${moment()}.xlsx`);
  }

  const exportCsvData = () => {
    var workbook = XLSX.utils.book_new();
    var worksheet_data = document.getElementById("table-to-xls");
    var worksheet = XLSX.utils.table_to_sheet(worksheet_data);
    workbook.SheetNames.push("Test");
    workbook.Sheets["Test"] = worksheet;
    exportExcelFile(workbook);
  }

  return (
    <div className={classes.root}>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="AccordianHeader"
        >
          <Grid container className="mt-0" style={{ position: "relative" }}>
            <Grid xs={1} item >
              <PrintIcon onClick={(e) => PrintDiv(e)} style={{ width: "50px", marginTop: 10 }} />
              <div className="Kapan-wise-ReportExcel">
                <img className="download-table-xls-buttonforKapan" src={ExcelPng} alt="alternative text" title="csv" onClick={() => exportCsvData()} style={{ position: "absolute", left: 50, top: 10 }} />
              </div>
            </Grid>
            <Grid xs={10} item container justify="center" className="mt-0">
              <Typography className={classes.heading}>Kapan Wise Costing Report</Typography>
            </Grid>
            <Grid xs={1} item container justify="flex-end" className="mt-0">
              <Typography className={classes.heading}>Filter</Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid xs={12} item container>
              <Grid xs={4} item>
              </Grid>
              <Grid xs={6} item container>
                <Grid xs={3} item>
                  <Autocomplete
                    value={lotNo}
                    {...defaultProps}
                    onChange={(e, newdata) => {
                      if (newdata) {
                        setLotNo(newdata)
                      }
                    }}
                    renderInput={(params) => <TextField label="Lot No"  {...params} margin="normal" />}
                  />
                </Grid>&nbsp;&nbsp;&nbsp;&nbsp;
                <Grid xs={3} item>
                  <Autocomplete
                    value={partyName}
                    options={parties}
                    getOptionLabel={(option) => option.partyName}
                    onChange={(e, newdata) => {
                      if (newdata) {
                        setPartyName(newdata)
                      }
                    }}
                    renderInput={(params) => <TextField label="Party Name"  {...params} margin="normal" />}
                  />
                </Grid>
                <Grid xs={4} item style={{ marginLeft: 0 }}>
                  <Button
                    onClick={() => PreViewHandler()}
                    variant="contained"
                    color="primary"
                    style={{ marginLeft: 15, marginTop: 20 }}>
                    Preview
                  </Button>
                  <Button
                    onClick={() => ClearViewHandler()}
                    variant="contained"
                    color="primary"
                    style={{ height: 34, marginLeft: 10, marginTop: 12 }}>
                    clear
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        </AccordionDetails>
      </Accordion>
      <Grid style={{ width: "100%", overflowY: "scroll" }}>
        <Grid xs={12} item container >
          <div style={{ display: "flex", height: window.innerHeight - 250 }}>
            <Rough AllData={AllData} loading={loading} />
            <CostZar AllData={AllData} loading={loading} />
            <Sales AllData={AllData} loading={loading} />
          </div>
        </Grid>
      </Grid>
      <PdfView
        AllData={AllData}
        partyName={partyName?.partyName || "All"}
        lotNo={lotNo?.lotNo || "All"} />
      <ExcelView
        AllData={AllData}
        partyName={partyName?.partyName || "All"}
        lotNo={lotNo?.lotNo || "All"} />

    </div >
  );
}
export default withRouter(KapanWiseCosting)