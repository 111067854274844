import React, { useRef, useState, useEffect } from 'react'
import MaterialTable from 'material-table';
import { FilledInput } from '@material-ui/core'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { GETALLSHORTCODETYPE } from '../../Utility/SortCode/SortCodeQuery';
import { useQuery } from '@apollo/react-hooks'
import { GradingSelectComponent, GradingLocationSelectComponent } from '../../../components/DropdownSelect/SelectComponents/ShortCodeSelectComponent'

const columns = [
  {
    title: 'Lot No',
    field: 'lotNoId',
    customFilterAndSearch: (data, rowData) => {
      let regex = new RegExp(data, 'i')
      return (rowData?.lotNoId?.lotNo || "").match(regex)
    },
    render: (rowData) => rowData?.lotNoId?.lotNo || null,
    cellStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    headerStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    editable: "never",
  },
  {
    title: 'Pkt No',
    field: 'pktNo',
    customFilterAndSearch: (data, rowData) => {
      let regex = new RegExp(data, 'i')
      return (rowData?.pktNoId?.pktNo || "").match(regex)
    },
    cellStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    headerStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    render: (rowData) => rowData?.pktNoId?.pktNo || null,
    editable: "never",
  },
  {
    title: 'Location',
    field: 'location',
    cellStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    headerStyle: {
      minWidth: 150,
      textAlign: "center"
    },
  },
  {
    title: 'RFID',
    field: 'rfId',
    cellStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    headerStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    editComponent: (props) => <FilledInput type="text" defaultValue={props?.rowData?.rfId || 0} style={{ width: 120 }} onChange={(e) => {
      let Data = { ...props.rowData }
      Data.rfId = e.target.value
      props.onRowDataChange(Data)
    }} />
  },
  {
    title: 'Cts',
    cellStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    headerStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    field: 'issueCts',
    editable: "never",
  },
  {
    title: 'Color',
    field: 'colorId',
    cellStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    headerStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    customFilterAndSearch: (data, rowData) => {
      let regex = new RegExp(data, 'i')
      return (rowData?.colorId?.shortName || rowData?.roughStockId?.colorId?.shortName || "").match(regex)
    },
    render: (rowData) => rowData?.colorId?.shortName || rowData?.roughStockId?.colorId?.shortName || "",
    editable: "never",
  },

  {
    title: 'Shape',
    field: 'shapeId',
    cellStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    headerStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    customFilterAndSearch: (data, rowData) => {
      let regex = new RegExp(data, 'i')
      return (rowData?.shapeId?.shortName || rowData?.roughStockId?.shapeId?.shortName || "").match(regex)
    },
    render: (rowData) => rowData?.shapeId?.shortName || rowData?.roughStockId?.shapeId?.shortName || "",
    editable: "never",
  },

  {
    title: 'Purity',
    field: 'purityId',
    cellStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    headerStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    customFilterAndSearch: (data, rowData) => {
      let regex = new RegExp(data, 'i')
      return (rowData?.purityId?.shortName || rowData?.roughStockId?.purityId?.shortName || "").match(regex)
    },
    render: (rowData) => rowData?.purityId?.shortName || rowData?.roughStockId?.purityId?.shortName || "",
    editable: "never",
  },

  {
    title: 'Cut',
    field: 'cutId',
    cellStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    headerStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    customFilterAndSearch: (data, rowData) => {
      let regex = new RegExp(data, 'i')
      return (rowData?.cutId?.shortName || rowData?.roughStockId?.cutId?.shortName || "").match(regex)
    },
    render: (rowData) => rowData?.cutId?.shortName || rowData?.roughStockId?.cutId?.shortName || "",
  },

  {
    title: 'Polish',
    field: 'polishId',
    cellStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    headerStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    customFilterAndSearch: (data, rowData) => {
      let regex = new RegExp(data, 'i')
      return (rowData?.polishId?.shortName || rowData?.roughStockId?.polishId?.shortName || "").match(regex)
    },
    render: (rowData) => rowData?.polishId?.shortName || rowData?.roughStockId?.polishId?.shortName || ""
  },
  {
    title: 'Symm',
    field: 'symmId',
    cellStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    headerStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    customFilterAndSearch: (data, rowData) => {
      let regex = new RegExp(data, 'i')
      return (rowData?.symmId?.shortName || rowData?.roughStockId?.symmId?.shortName || "").match(regex)
    },
    render: (rowData) => rowData?.symmId?.shortName || rowData?.roughStockId?.symmId?.shortName || ""
  },
  {
    title: 'Flue',
    field: 'fluId',
    cellStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    headerStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    customFilterAndSearch: (data, rowData) => {
      let regex = new RegExp(data, 'i')
      return (rowData?.fluId?.shortName || rowData?.roughStockId?.fluId?.shortName || "").match(regex)
    },
    render: (rowData) => rowData?.fluId?.shortName || rowData?.roughStockId?.fluId?.shortName || ""
  },
  {
    title: 'Measure',
    field: 'measurment',
    cellStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    headerStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    editComponent: (props) => <FilledInput type="text" defaultValue={props?.rowData?.measurment || 0} style={{ width: 80 }} onChange={(e) => {
      let Data = { ...props.rowData }
      Data.measurment = e.target.value
      props.onRowDataChange(Data)
    }} />
  },

  {
    title: 'Brown',
    field: 'brown',
    cellStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    headerStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    editComponent: (props) => <FilledInput type="text" defaultValue={props?.rowData?.brown || 0} style={{ width: 80 }} onChange={(e) => {
      let Data = { ...props.rowData }
      Data.brown = e.target.value
      props.onRowDataChange(Data)
    }} />
  },
  {
    title: 'Grad %',
    field: 'gradPer',
    cellStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    headerStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    editComponent: (props) => <FilledInput type="number" defaultValue={props?.rowData?.gradPer || 0} style={{ width: 80 }} onChange={(e) => {
      let Data = { ...props.rowData }
      Data.gradPer = parseInt(e.target.value)
      if (Data.gradPer >= 0 && Data.gradPer <= 100) {
        props.onRowDataChange(Data)
      } else {
        toast.warn("please, enter grad percentage value between 0 to 100.")
      }
    }} />
  }
]

export const Fromtable = (props) => {
  const tableRef = useRef();
  //   const [Data, SetData] = useState('')
  const [ShortCodeData1, SetShortCodeData] = useState([])

  const { data: shortCodeData } = useQuery(GETALLSHORTCODETYPE, {
    variables: { type: "All" },
    fetchPolicy: 'cache-and-network',
  })
  const setdefaultValues = (Value) => {
    return Value?.id || Value
  }
  const getShortCodeData = (TYPE) => {
    return _.filter(shortCodeData?.getAllType || [], (d) => d.type === TYPE);
  }
  let pktType = props?.pktType || ''
  columns.map((d, key) => {
    if (d.field === "issueCts") {
      d.render = (rowData) => {
        return pktType === "EXTRA TOPS" ? rowData?.cts || 0 : rowData?.issueCts || 0
      }
    }
    if (d.field === "location") {
      d.editComponent = (props) => {
        return <GradingLocationSelectComponent key={key} propsData={props} TYPE="location" data={getShortCodeData("LOCATION")} valueDefault={setdefaultValues(props?.rowData?.location)} />
      }
    }
    if (d.field === "cutId") {
      d.editComponent = (props) => {
        return <GradingSelectComponent key={key} propsData={props} TYPE="cutId" data={getShortCodeData("CUT")} valueDefault={setdefaultValues(props?.rowData?.cutId || props?.rowData?.roughStockId?.cutId)} />
      }
    }
    if (d.field === "polishId") {
      d.editComponent = (props) => {
        return <GradingSelectComponent key={key} propsData={props} TYPE="polishId" data={getShortCodeData("POLISH")} valueDefault={setdefaultValues(props?.rowData.polishId || props?.rowData?.roughStockId?.polishId)} />
      }
    }
    if (d.field === "symmId") {
      d.editComponent = (props) => {
        return <GradingSelectComponent key={key} propsData={props} TYPE="symmId" data={getShortCodeData("SYMMENTRY")} valueDefault={setdefaultValues(props?.rowData.symmId || props?.rowData?.roughStockId?.symmId)} />
      }
    }
    if (d.field === "fluId") {
      d.editComponent = (props) => {
        return <GradingSelectComponent key={key} propsData={props} TYPE="fluId" data={getShortCodeData("FLOURSENCE")} valueDefault={setdefaultValues(props?.rowData.fluId || props?.rowData?.roughStockId?.fluId)} />
      }
    }
    return null
  })

  useEffect(() => {
    SetShortCodeData(shortCodeData?.getAllType || [])
  }, [shortCodeData]);

  const updateFromTable = (newData, oldData) => {
    return new Promise((resolve, reject) => {
      newData.cutId = _.filter(ShortCodeData1, (d) => d.id === newData.cutId)[0]
      newData.polishId = _.filter(ShortCodeData1, (d) => d.id === newData.polishId)[0]
      newData.symmId = _.filter(ShortCodeData1, (d) => d.id === newData.symmId)[0]
      newData.fluId = _.filter(ShortCodeData1, (d) => d.id === newData.fluId)[0]
      let updated = _.map(props.StockData, (d) => {
        if (d.id === newData.id)
          return newData
        else
          return d
      })
      props.SetStockData(updated)
      resolve()
    });
  }

  return <MaterialTable
    ref={tableRef}
    title={'STOCK Stock'}
    columns={columns}
    isLoading={props.loading}
    data={props.StockData}
    options={{
      emptyRowsWhenPaging: false,
      padding: 'dense',
      selection: true,
      search: false,
      pageSize: 20,
      pageSizeOptions: [20, 50, 100, 150, 200, 250, 300, 400, 500],
      paging: true,
      sorting: false,
      filtering: true,
      minBodyHeight: window.innerHeight - 500,
      maxBodyHeight: window.innerHeight - 500,
      headerStyle: {
        backgroundColor: '#8CB836',
        color: '#f5f5f5'
      }
    }}
    editable={{
      onRowUpdate: (newData, oldData) => updateFromTable(newData, oldData),
    }}
    onSelectionChange={(rows) => {
      props.SetSelectedData(rows)
    }}
  />
}