import React, { useState, useEffect, useRef } from 'react';
import { useQuery, useSubscription } from '@apollo/react-hooks'
import MaterialTable from 'material-table';
import { withRouter } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { SPACEBOARDDATA } from './SizeWiseStockSubscription'
import { Table } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { GETALLSHORTCODETYPE } from '../../Utility/SortCode/SortCodeQuery';
import { CustomFilterComponent } from '../../../components/CustomFilterComponent/index'

const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1, },
  noMargin: { margin: 0 },
  mt20: { marginTop: '20px !important' },
  boxLabel: {
    display: 'flex',
    fontSize: '16px',
    fontWeight: 800,
    marginBottom: '5px'
  },
  GridLeftSpace: {
    paddingLeft: 5
  },
  GridFillter: {
    textAlign: "end",
    paddingRight: 30
  },
  SetAccordianHeade: {
    paddingTop: 0,
    marginTop: "0px !important"
  },
  fitGrid: {
    maxHeight: 170,
    overflowY: "scroll"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  Select: {
    marginTop: "0px !important",
    paddingTop: 0,
    // paddingRight: 10
  },
  denseTable: {
    '& td': { padding: "2px 5px", cursor: "pointer", textAlign: "center" },
    '& th': { padding: "5px 5px", cursor: "pointer", textAlign: "center" }
  },
  tableHeading: {
    background: '#8CB836 !important',
    color: '#f5f5f5'
  },
  setTable: {
    position: "relative",
    '& th': { padding: "5px 5px", cursor: "pointer", textAlign: "center" }
  },

  Totalboxscan: {
    paddingTop: 0,
    marginTop: "0px !important",
    marginBottom: 5,
    display: "flex", alignItems: "center",
    '& .MuiGrid-container': {
      margin: "0px !important"
    },
    '& label': {
      fontSize: 16, color: "rgb(70, 81, 142)", paddingLeft: 15
    },
    '& .invaliedpkt': {
      display: "flex",
      justifyContent: "center",
    },
    '& .missingpkt': {
      display: "flex",
      justifyContent: "flex-end",
      color: 'red',
      paddingRight: "15px",
      '& label': {
        fontSize: 16, color: "red"
      }
    }
  },
  Totalboxscan1: {
    paddingTop: 0,
    marginTop: "0px !important",
    marginBottom: 5,
    display: "flex", alignItems: "center",
    '& label': {
      fontSize: 16, color: "red", paddingLeft: 10
    },
    '& .totalboxNumber': {
      display: "flex",
      justifyContent: "flex-end",
      paddingRight: "15px"
    }
  },

}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "rgb(70, 81, 142)",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const columns = [
  { title: 'Lot No.', field: 'lotNoId.lotNo' },
  { title: 'Pkt No.', field: 'pktNoId.pktNo' },
  { title: 'Lab', field: 'lab.partyName' },
  { title: 'Certificate Id', field: 'certificateId' },
  { title: 'Cts', field: 'cts' },
  { title: 'Color', field: 'colorId', render: (rowData) => rowData?.colorId?.shortName || "_" },
  { title: 'Shape', field: 'shapeId', render: (rowData) => rowData?.shapeId?.shortName || "_" },
  { title: 'Purity', field: 'purityId', render: (rowData) => rowData?.purityId?.shortName || "_" },
  { title: 'Cut', field: 'cutId', render: (rowData) => rowData?.cutId?.shortName || "_" },
  { title: 'Polish', field: 'polishId', render: (rowData) => rowData?.polishId?.shortName || "_" },
  { title: 'Symm', field: 'symmId', render: (rowData) => rowData?.symmId?.shortName || "_" },
  { title: 'Flu', field: 'flourenceseId', render: (rowData) => rowData?.flourenceseId?.shortName || "_" },
  { title: 'Status', field: 'status', render: () => "ON HAND" },
  { title: 'Measurment', field: 'measurment' },
  { title: 'Brown', field: 'brown' },
  { title: 'Grd %', field: 'grd' },
  { title: 'Raprate', field: 'rapRate' },
  { title: 'Rap Amt', field: 'rapAmount' },
  { title: 'STK %', field: 'stkPer' },
  { title: 'Net Raprate', field: 'netRaprate' },
  { title: 'Net Amount', field: 'netAmount' },
  { title: 'Certified.?', field: 'isCertified', type: 'boolean' }
]

function SizeWiseStockMaster(props) {
  const classes = useStyles();
  const tableRef = useRef();

  const [box, setBox] = useState({})
  const [totalScan, setTotalScan] = useState(0)
  const [salesStock, setSalesStock] = useState([])
  const [invalidStock, setInvalidStock] = useState([])
  const [selectedRow, setSelectedRow] = useState(null);
  const [missedPkts, setMissedPkts] = useState([]);
  const [invalidPkts, setInvalidPkts] = useState([]);
  const [clickedStatus, setClickedStatus] = useState("");

  const { data: shortCodeData } = useQuery(GETALLSHORTCODETYPE, {
    variables: { type: "All" },
    fetchPolicy: 'cache-and-network',
  })

  const { error, data, loading, } = useSubscription(SPACEBOARDDATA)
  console.log("🚀 ~ file: index.jsx:171 ~ SizeWiseStockMaster ~ data:", data)



  const getShortCodeData = (TYPE) => {
    return _.filter(shortCodeData?.getAllType || [], (d) => d.type === TYPE);
  }
  columns.map(d => {
    if (d.field === "shapeId") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: getShortCodeData("SHAPE"), getOptionLabel: (option) => option.shortName, multiple: true }} />
      }
    }
    if (d.field === "colorId") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: getShortCodeData("COLOR"), getOptionLabel: (option) => option.shortName, multiple: true }} />
      }
    }
    if (d.field === "purityId") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: getShortCodeData("PURITY"), getOptionLabel: (option) => option.shortName, multiple: true }} />
      }
    }
    if (d.field === "polishId") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: getShortCodeData("POLISH"), getOptionLabel: (option) => option.shortName, multiple: true }} />
      }
    }
    if (d.field === "flourenceseId") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: getShortCodeData("FLOURSENCE"), getOptionLabel: (option) => option.shortName, multiple: true }} />
      }
    }
    if (d.field === "cutId") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: getShortCodeData("CUT"), getOptionLabel: (option) => option.shortName, multiple: true }} />
      }
    }
    if (d.field === "symmId") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: getShortCodeData("SYMMENTRY"), getOptionLabel: (option) => option.shortName, multiple: true }} />
      }
    }
    return null
  })

  useEffect(() => {
    if (data && data.Spaceboard) {
      setBox(data?.Spaceboard?.box)
      setTotalScan(data?.Spaceboard?.totalScan)
      setSalesStock(data?.Spaceboard?.salesStock)
      setMissedPkts(data?.Spaceboard?.missedPkts)
      setInvalidPkts(data?.Spaceboard?.invalid?.invalidPkts)

      let iStock = _.forEach(data?.Spaceboard?.invalid?.invalidStock, function (item) {
        let boxx = _.find(data?.Spaceboard?.invalid?.boxes, (b) => item.cts <= b.toSize && item.cts >= b.fromSize);
        item['boxNo'] = boxx ? boxx.boxNo : 'not found';
      });

      setInvalidStock(iStock)

    }
  }, [data, error]);


  // let mssingByGroup1 = missedPkts.length > 0 ? _.groupBy(missedPkts, 'status') : null;
  // console.log("-=-=-==-", mssingByGroup1);
  let mssingByGroup = missedPkts.length > 0 ? { "APPRO ISSUE": [], "MISSING PACKETS": [] } : {}

  let temp = missedPkts.length > 0 ? _.map(missedPkts, (d) => {
    if (d.status === "APPRO ISSUE") {
      mssingByGroup["APPRO ISSUE"].push(d)
    } else {
      if (!["LAB ISSUE", "REPAIR ISSUE", "PURCHASE"].includes(d.status))
        mssingByGroup["MISSING PACKETS"].push(d)
    }
    return d
  }) : null;

  return (
    <div className={classes.root}>
      <Grid container >
        <Grid xs={2} item className={classes.mt20}>
          <Grid xs={12} item container >
            <Grid item xs={6}>
              <label className={classes.boxLabel}>Box No</label>
              <label>{box?.boxNo}</label>
            </Grid>
            <Grid item xs={6}>
              <label className={classes.boxLabel}>RFID</label>
              <label>{box?.rfId}</label>
            </Grid>
          </Grid>
          <Grid xs={12} item container className={classes.mt20}>
            <Grid item xs={6}>
              <label className={classes.boxLabel}>From Cts</label>
              <label>{box?.fromSize}</label>

            </Grid>
            <Grid item xs={6}>
              <label className={classes.boxLabel}>To Cts</label>
              <label>{box?.toSize}</label>
            </Grid>

          </Grid>

        </Grid>
        <Grid xs={4} item container className={classes.fitGrid} className="pr-5">
          <Grid xs={12} container item className={classes.Totalboxscan}>
            <Grid xs={4} item={true} >
              <label>Total Scan : {totalScan}</label>
            </Grid>
            <Grid xs={4} item={true} className={"invaliedpkt"}>
              <label>Total Found : {salesStock?.length}</label>
            </Grid>
            <Grid xs={4} item={true} className={'missingpkt'}>
              <label>Invalid Packets : {invalidPkts?.length || 0}</label>
            </Grid>
          </Grid>
          <TableContainer component={Paper}>
            <Table className={classes.denseTable}>
              <TableHead>
                <TableRow>
                  <StyledTableCell style={{ width: '50%', textAlign: "center" }} className={classes.tableHeading}>RFID</StyledTableCell>
                  <StyledTableCell style={{ width: '50%', textAlign: "center" }} className={classes.tableHeading}>Box No</StyledTableCell>
                </TableRow>
              </TableHead>
            </Table>
            <div style={{ height: 160 }}>
              <Table aria-label="customized table" className={classes.denseTable}>
                <TableBody>
                  {invalidStock.map((row, index) => {
                    return (<StyledTableRow key={index}>
                      <StyledTableCell style={{ width: '50%', textAlign: "center" }}>{row.rfId}</StyledTableCell>
                      <StyledTableCell style={{ width: '50%', textAlign: "center" }}>{row.boxNo}</StyledTableCell>
                    </StyledTableRow>)
                  })}
                </TableBody>
              </Table>
            </div>
          </TableContainer>
        </Grid>
        <Grid xs={3} item container className={classes.fitGrid} className="pr-5">
          <Grid xs={12} container item className={classes.Totalboxscan1}>
            <Grid xs={7} item={true} >
              <label>Missing Packets</label>
            </Grid>
            <Grid xs={5} item={true} className="totalboxNumber">
              <label>{_.filter(missedPkts, (d) => d.status !== "LAB ISSUE").length}</label>
            </Grid>
          </Grid>
          <TableContainer component={Paper} className={classes.denseTable, classes.setTable}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell style={{ width: '50%', textAlign: "center" }} className={classes.tableHeading}>Description</StyledTableCell>
                  <StyledTableCell style={{ width: '50%', textAlign: "center" }} className={classes.tableHeading}>Total Pkts</StyledTableCell>
                </TableRow>
              </TableHead>
            </Table>
            <div style={{ height: 160 }}>
              <Table aria-label="customized table" className={classes.denseTable}>
                <TableBody>
                  {mssingByGroup && Object.keys(mssingByGroup).map((status, index) => {
                    return (<StyledTableRow key={index} onClick={() => { setClickedStatus(status) }} >
                      <StyledTableCell style={{ width: '50%', textAlign: "center" }}>{status}</StyledTableCell>
                      <StyledTableCell style={{ width: '50%', textAlign: "center" }}>{mssingByGroup[status].length}</StyledTableCell>
                    </StyledTableRow>)
                  })}
                </TableBody>
              </Table>
            </div>
          </TableContainer>
        </Grid>
        <Grid xs={3} item container className={classes.fitGrid} className="pr-5">
          <Grid xs={12} container item className={classes.Totalboxscan1}>
            <Grid xs={7} item={true} >
              <label>{clickedStatus ? clickedStatus : ""}</label>
            </Grid>
            <Grid xs={5} item={true} className="totalboxNumber">
              <label>{mssingByGroup && clickedStatus !== "" ? mssingByGroup[clickedStatus].length : 0}</label>
            </Grid>
          </Grid>
          <TableContainer component={Paper} className={classes.denseTable}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell style={{ width: '50%', textAlign: "center" }} className={classes.tableHeading}>Lot No</StyledTableCell>
                  <StyledTableCell style={{ width: '50%', textAlign: "center" }} className={classes.tableHeading}>Pkt No</StyledTableCell>
                </TableRow>
              </TableHead>
            </Table>
            <div style={{ height: 160 }}>
              <Table aria-label="customized table" className={classes.denseTable}>
                <TableBody>
                  {mssingByGroup && clickedStatus !== "" && mssingByGroup[clickedStatus].map((row, index) => {
                    return (<StyledTableRow key={index}>
                      <StyledTableCell style={{ width: '50%', textAlign: "center" }}>{row?.lotNoId?.lotNo}</StyledTableCell>
                      <StyledTableCell style={{ width: '50%', textAlign: "center" }}>{row?.pktNoId?.pktNo}</StyledTableCell>
                    </StyledTableRow>)
                  })}
                </TableBody>
              </Table>
            </div>
          </TableContainer>
        </Grid>
      </Grid >
      <Grid container>
        <Grid item xs={12}>
          <MaterialTable
            title="Size Wise Stock Master"
            tableRef={tableRef}
            columns={columns}
            // isLoading={loading}
            data={salesStock}
            options={{
              emptyRowsWhenPaging: false,
              padding: 'dense',
              paging: false,
              // pageSizeOptions: [5, 10, 15, 20, 25, 50],
              addRowPosition: 'first',
              filtering: true,
              sorting: true,
              search: false,
              filterCellStyle: { padding: '5px', },
              maxBodyHeight: window.innerHeight - 215,
              rowStyle: rowData => ({
                backgroundColor: (selectedRow === rowData.tableData.id) ? 'rgb(212, 218, 255)' : '#f5f5f5'
              }),
              headerStyle: { backgroundColor: 'whitesmoke' }
            }}
            onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
          />
        </Grid>
      </Grid>
    </div >
  );
}

export default withRouter(SizeWiseStockMaster)
