import gql from 'graphql-tag';

export const GRADING = gql`
query getGradingMaster($page: Int, $limit: Int, $filter: String, $sort: gradingMasterSort){
  getGradingMaster(page:$page, limit:$limit, filter:$filter, sort:$sort){
      count
    data{
      id
      gradNo
      gradDate
      lotNoId{
        id
        lotNo
      }
      packetNoId{
        id
        pktNo
      }
      shapeId{
        id
        shortName
      }
      colorId{
        id
        shortName
      }
      purityId{
        id
        shortName
      }
      polishId{
        id
        shortName
      }
      flourenceId{
        id
        shortName
      }
      milkyId{
        id
        shortName
      }
      centerBlackId{
        id
        shortName
      }
      centerWhiteId{
        id
        shortName
      }
      tableOpenId{
        id
        shortName
      }
      pavillionId{
        id
        shortName
      }
      weight
      tintId{
        id
        shortName
      }
      cutId{
        id
        shortName
      }
      symmId{
        id
        shortName
      }
         hAndAId{
        id
        shortName
      }
      eyeCleanId{
        id
        shortName
      }
      sideBlackId{
        id
        shortName
      }
      sideWhiteId{
        id
        shortName
      }
      crownOpenId{
        id
        shortName
      }
      comment1
      comment2
      createdBy{
        userName
      }
      updatedBy
      createdAt
      updatedAt
    }
  }
}

`;


export const PKTNOFROMLOTNO = gql`
query getPacketFromLotNo($id: ID,$partyId:String,$type: String,$salesType:String,$isRoughStockDetail:Boolean){
  getPacketFromLotNo(id:$id,partyId:$partyId,type:$type,salesType:$salesType,isRoughStockDetail:$isRoughStockDetail){
    id
    pktNo
  }
}
`