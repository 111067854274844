import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useQuery } from "@apollo/react-hooks";
import _ from 'lodash'
import { Tofixed } from '../../../components/Comman/Tofix'
import { SALESDETAIL } from "./SalesQuery";

export const PdfInvoice = ({ selectedRowData, selectedChildRowData }) => {
    const [Result, setResult] = useState([])
    let Data = selectedRowData[0]
    const { data } = useQuery(SALESDETAIL, {
        variables: { id: Data?.id || '' },
        fetchPolicy: 'cache-and-network'
    });
    useEffect(() => {
        let res = data?.getAllSalesDetail || []
        let Tempres = []
        res.map((d) => {
            let temp = {
                lotNo: `${d?.lotNoId?.lotNo || ""}-${d?.pktNoId?.pktNo || ""}`,
                certificateNo: d?.certificateNo || "",
                cts: d?.cts || 0,
                color: d?.colorId?.shortName || "",
                purity: d?.purityId?.shortName || "",
                cut: d?.cutId?.shortName || "",
                polish: d?.polishId?.shortName || "",
                symm: d?.symmId?.shortName || "",
                flo: d?.floId?.shortName || "",
                lab: d?.lab?.partyName || "",
                rapAmt: d?.rapRate || 0,
                salesDiscPer: d?.salesDiscPer || 0,
                netRaprateAmt: d?.netRaprateAmt || 0,
            }
            if (selectedChildRowData.length > 0) {
                if (selectedChildRowData.includes(d.id)) {
                    Tempres.push(temp)
                }
            } else {
                Tempres.push(temp)
            }
            return null
        })
        setResult(Tempres)
    }, [data])

    useEffect(() => {
        let res = data?.getAllSalesDetail || []
        let Tempres = []
        res.map((d) => {
            let temp = {
                lotNo: d?.lotNoId?.lotNo || "",
                certificateNo: d?.certificateNo || "",
                cts: d?.cts || 0,
                color: d?.colorId?.shortName || "",
                purity: d?.purityId?.shortName || "",
                cut: d?.cutId?.shortName || "",
                polish: d?.polishId?.shortName || "",
                symm: d?.symmId?.shortName || "",
                flo: d?.floId?.shortName || "",
                lab: d?.lab?.partyName || "",
                rapAmt: d?.rapAmt || 0,
                salesDiscPer: d?.salesDiscPer || 0,
                netRaprateAmt: d?.netRaprateAmt || 0,
            }
            if (selectedChildRowData.length > 0) {
                if (selectedChildRowData.includes(d.id)) {
                    Tempres.push(temp)
                }
            } else {
                Tempres.push(temp)
            }
            return null
        })
        setResult(Tempres)
    }, [selectedChildRowData])

    return <div
        style={{ display: "none" }}
        id="printdivcontent">
        <div style={{ border: "1px solid #666", marginBottom: 10 }}>
            <div style={{ textAlign: "center" }}>
                <h1>Kismet Diamonds</h1>
            </div>
            <div style={{ display: "flex", width: "100%" }}>
                <div style={{ width: "50%" }}>
                    {
                        ["CK REG NO:", "VAT : 4050262452", "CONTACT NUMBER : +27110259982", "CONTACT PERSON : ROSHAN DAYA", "EMAIL : kismetdiamonds@gmail.com", "Tel : +27110259882, 0"].map((d, key) => {
                            return (
                                <div key={key}>
                                    <p style={{ margin: 0, fontSize: 13 }}>{d}</p>
                                </div>
                            )
                        })

                    }
                </div>
                <div style={{ width: "50%" }}>
                    <p style={{ margin: 0, fontSize: 13, textAlign: "end" }}>{"F1N1 - 1ST FLOOR,THE PARAGON II, 1 KRAMER ROAD, BEDFORDVIEW, JOHANNESBURG-, , SOUTH AFRICA"}</p>
                </div>
            </div>
            <div style={{ background: "rgb(192,192,192)", height: 25, display: "flex", alignItems: "center", justifyContent: "center" }}>
                <h3 style={{ margin: 0 }}>INVOICE</h3>
            </div>
            <div >
                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                    <tbody style={{ display: "table-row-group" }}>
                        <tr>
                            <td colSpan={2} style={{ border: "1px solid #666", fontSize: 13, width: "60%", fontWeight: "bold" }} > Customer Details</td>
                            <td style={{ border: "1px solid #666", fontSize: 13, width: "20%", fontWeight: "bold", textAlign: "end" }} rowSpan='1'  >Invoice No:	</td>
                            <td style={{ border: "1px solid #666", fontSize: 13, width: "20%", }} >{Data?.billNo || ''}</td>
                        </tr>
                        <tr>
                            <td style={{ border: "1px solid #666", fontSize: 13, width: "20%", fontWeight: "bold" }} >Name:</td>
                            <td style={{ border: "1px solid #666", fontSize: 13, width: "40%", }} >{Data?.partyId?.partyName || ""}</td>
                            <td style={{ border: "1px solid #666", fontSize: 13, width: "20%", fontWeight: "bold", textAlign: "end" }} >Date:	</td>
                            <td style={{ border: "1px solid #666", fontSize: 13, width: "20%" }} >{moment(Data?.date || 0).format('DD-MM-YYYY')}</td>
                        </tr>
                        <tr>
                            <td style={{ border: "1px solid #666", fontSize: 13, width: "20%", fontWeight: "bold" }} rowSpan='2' colSpan='1' >Address</td>
                            <td style={{ border: "1px solid #666", fontSize: 13, width: "40%" }} rowSpan='2' colSpan='1' >{`${Data?.street || ""},${Data?.road || ""},${Data?.city || ""}`},</td>
                            <td style={{ border: "1px solid #666", fontSize: 13, width: "40%" }} rowSpan='2' colSpan='2' >Term : {Data?.termId?.termName || ""} </td>
                        </tr>
                        <tr>
                        </tr>
                        <tr>
                            <td style={{ border: "1px solid #666", fontSize: 13, width: "20%", fontWeight: "bold" }} >Phone:</td>
                            <td style={{ border: "1px solid #666", fontSize: 13, width: "40%" }} >{Data?.partyId?.mobile1 || Data?.partyId?.phone1 || ''}</td>
                            <td style={{ border: "1px solid #666", fontSize: 13, width: "40%" }} rowSpan='1' colSpan='2' >VAT: {Data?.vat || ''} </td>
                        </tr>

                    </tbody>
                </table>
            </div>
            <div style={{ marginTop: 10 }}>
                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                    <thead>
                        <tr>
                            <th style={{ border: "1px solid #666", fontSize: 13, fontWeight: "bold", background: "RGB(87, 193, 247)" }}> Stone No</th>
                            <th style={{ border: "1px solid #666", fontSize: 13, fontWeight: "bold", background: "RGB(87, 193, 247)" }}> Certificate No	</th>
                            <th style={{ border: "1px solid #666", fontSize: 13, fontWeight: "bold", background: "RGB(87, 193, 247)" }}>Weight</th>
                            <th style={{ border: "1px solid #666", fontSize: 13, fontWeight: "bold", background: "RGB(87, 193, 247)" }}>Col</th>
                            <th style={{ border: "1px solid #666", fontSize: 13, fontWeight: "bold", background: "RGB(87, 193, 247)" }}>Clarity	</th>
                            <th style={{ border: "1px solid #666", fontSize: 13, fontWeight: "bold", background: "RGB(87, 193, 247)" }}>Cut		</th>
                            <th style={{ border: "1px solid #666", fontSize: 13, fontWeight: "bold", background: "RGB(87, 193, 247)" }}>Pol		</th>
                            <th style={{ border: "1px solid #666", fontSize: 13, fontWeight: "bold", background: "RGB(87, 193, 247)" }}>sym</th>
                            <th style={{ border: "1px solid #666", fontSize: 13, fontWeight: "bold", background: "RGB(87, 193, 247)" }}>flr</th>
                            <th style={{ border: "1px solid #666", fontSize: 13, fontWeight: "bold", background: "RGB(87, 193, 247)" }}>lab</th>
                            <th style={{ border: "1px solid #666", fontSize: 13, fontWeight: "bold", background: "RGB(87, 193, 247)" }}>Rap Price</th>
                            <th style={{ border: "1px solid #666", fontSize: 13, fontWeight: "bold", background: "RGB(87, 193, 247)" }}>Disc</th>
                            <th style={{ border: "1px solid #666", fontSize: 13, fontWeight: "bold", background: "RGB(87, 193, 247)" }}>Total</th>
                        </tr>
                    </thead>
                    <tbody style={{ display: "table-row-group" }}>
                        {Result.map((d, key) => {

                            return <tr key={key}>
                                <td style={{ border: "1px solid #666", fontSize: 13, textAlign: "center", height: 15 }} >{d?.lotNo}</td>
                                <td style={{ border: "1px solid #666", fontSize: 13, textAlign: "center", height: 15 }} >{d?.certificateNo}</td>
                                <td style={{ border: "1px solid #666", fontSize: 13, textAlign: "center", height: 15 }} >{Tofixed(d?.cts)}</td>
                                <td style={{ border: "1px solid #666", fontSize: 13, textAlign: "center", height: 15 }} >{d.color}</td>
                                <td style={{ border: "1px solid #666", fontSize: 13, textAlign: "center", height: 15 }} >{d.purity}</td>
                                <td style={{ border: "1px solid #666", fontSize: 13, textAlign: "center", height: 15 }} >{d.cut}</td>
                                <td style={{ border: "1px solid #666", fontSize: 13, textAlign: "center", height: 15 }} >{d.polish}</td>
                                <td style={{ border: "1px solid #666", fontSize: 13, textAlign: "center", height: 15 }} >{d.symm}</td>
                                <td style={{ border: "1px solid #666", fontSize: 13, textAlign: "center", height: 15 }} >{d.flo}</td>
                                <td style={{ border: "1px solid #666", fontSize: 13, textAlign: "center", height: 15 }} >{d.lab}</td>
                                <td style={{ border: "1px solid #666", fontSize: 13, textAlign: "center", height: 15 }} >{Tofixed(d.rapAmt)}</td>
                                <td style={{ border: "1px solid #666", fontSize: 13, textAlign: "center", height: 15 }} >{d.salesDiscPer}</td>
                                <td style={{ border: "1px solid #666", fontSize: 13, textAlign: "center", height: 15 }} >{Tofixed(d.netRaprateAmt)}</td>
                            </tr>
                        })
                        }
                        {Result.length < 37 ?
                            [...Array(37 - Result.length)].map((d, key) => {
                                return <tr key={key}>
                                    <td style={{ border: "1px solid #666", height: 15 }} ></td>
                                    <td style={{ border: "1px solid #666", height: 15 }} ></td>
                                    <td style={{ border: "1px solid #666", height: 15 }} ></td>
                                    <td style={{ border: "1px solid #666", height: 15 }} ></td>
                                    <td style={{ border: "1px solid #666", height: 15 }} ></td>
                                    <td style={{ border: "1px solid #666", height: 15 }} ></td>
                                    <td style={{ border: "1px solid #666", height: 15 }} ></td>
                                    <td style={{ border: "1px solid #666", height: 15 }} ></td>
                                    <td style={{ border: "1px solid #666", height: 15 }} ></td>
                                    <td style={{ border: "1px solid #666", height: 15 }} ></td>
                                    <td style={{ border: "1px solid #666", height: 15 }} ></td>
                                    <td style={{ border: "1px solid #666", height: 15 }} ></td>
                                    <td style={{ border: "1px solid #666", height: 15 }} ></td>
                                </tr>
                            })
                            : ""
                        }
                        <tr>
                            <td colSpan={2} style={{ border: "1px solid #666", fontWeight: "bold", fontSize: 13, height: 15 }}>Total: {Result.length}</td>
                            <td style={{ border: "1px solid #666", fontWeight: "bold", fontSize: 13, height: 15 }}>{Tofixed(_.sumBy(Result, (d) => d.cts))}</td>
                            <td colSpan={7} style={{ border: "1px solid #666", fontWeight: "bold", fontSize: 13, height: 15 }}></td>
                            <td colSpan={2} style={{ border: "1px solid #666", fontWeight: "bold", fontSize: 13, height: 15 }}>Total $</td>
                            <td style={{ border: "1px solid #666", fontWeight: "bold", fontSize: 13, height: 15, textAlign: "end" }}>{Tofixed(Data?.totalAmount)}</td>
                        </tr>
                        <tr>
                            <td rowSpan={5} colSpan={10} style={{ border: "1px solid #666", fontWeight: "bold", fontSize: 13, height: 15 }}></td>
                            <td colSpan={2} style={{ border: "1px solid #666", fontWeight: "bold", fontSize: 13, height: 15 }}>Rate</td>
                            <td style={{ border: "1px solid #666", fontWeight: "bold", fontSize: 13, height: 15, textAlign: "end" }}>{Tofixed(Data?.totalZarAmount ? Data?.totalZarAmount / Data?.totalAmount : 0)}</td>
                        </tr>
                        <tr>
                            <td colSpan={2} style={{ border: "1px solid #666", fontWeight: "bold", fontSize: 13, height: 15 }}>Total Zar	</td>
                            <td style={{ border: "1px solid #666", fontWeight: "bold", fontSize: 13, height: 15, textAlign: "end" }}>{Tofixed((Data?.totalZarAmount || 0))}  </td>
                        </tr>
                        <tr>
                            <td colSpan={2} style={{ border: "1px solid #666", fontWeight: "bold", fontSize: 13, height: 15 }}>VAT</td>
                            <td style={{ border: "1px solid #666", fontWeight: "bold", fontSize: 13, height: 15, textAlign: "end" }}>{Tofixed(Data?.vat || 0)}</td>
                        </tr>
                        <tr>
                            <td colSpan={2} style={{ border: "1px solid #666", fontWeight: "bold", fontSize: 13, height: 15 }}>Total Zar	</td>
                            <td style={{ border: "1px solid #666", fontWeight: "bold", fontSize: 13, height: 15, textAlign: "end" }}>{Tofixed(Data?.totalZarAmount || 0)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
        <div style={{ border: "1px solid #666" }}>
            <p style={{ margin: 0, fontSize: 14, padding: 5 }}>
                *The diamonds herein invoiced have been purchased from legitimate sources not involved in funding conflict and in compliance with United Nations Resolutions. The Seller hereby guaranteess that these diamonds are conflict free, based on personal knowledge and/or written guarantees provided by the supplier of these diamonds.
            </p>
        </div>
    </div >
}