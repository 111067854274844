import gql from "graphql-tag";

export const INWARD = gql`
  query getInwardMaster( $page: Int $limit: Int $filter: String $sort: inwardMasterSort ) {
    getInwardMaster(page: $page, limit: $limit, filter: $filter, sort: $sort) {
      count
      data {
        id
        inwardNo
        inwardType
        date
        partyId {
          id
          partyName
        }
        termId {
          id
          termName
        }
        lessPer1
        lessPer2
        brokerId {
          id
          partyName
        }
        invoiceNo
        dueDays
        dueDate
        billType
        salesmanId {
          id
          partyName
        }
        prefix
        comment
        totalPackets
        totalCts
        amountZar
        amountTotalNet
        brinksCharges
        dbCharges
        bankCharges
        netAmount
        createdBy{
          userName
        }
        updatedBy
        createdAt
        updatedAt
      }
    }
  }
`;
export const INWARDDETAIL = gql`
  query getAllInwardDetail($id: ID!) {
    getAllInwardDetail(id:$id) {
      id
      inwardMasterId
      srNo
      location
      lotNo {
        id
        lotNo
      }
      packetNo {
        id
        pktNo
      }
      lab {
        id
        partyName
      }
      certifiNo
      refNo
      rfId
      cts
      colorId {
        id
        shortName
      }
      shapeId {
        id
        shortName
      }
      purityId {
        id
        shortName
      }
      cutId {
        id
        shortName
      }
      polishId {
        id
        shortName
      }
      symmId {
        id
        shortName
      }
      fluId {
        id
        shortName
      }
      measurment
      brown
      gridlePer
      rateRap
      amountRap
      purityDiscountPer
      stkDiscountPer
      lessPer1
      lessPer2
      totalAmount
      brokeragePer
      brokerageAmount
      transExpensesPer
      transExpensesPerAmount
      netAmountDollar
      rateZar
      amountZar
      btTransId
      btSrNo
      length
      width
      depth
      table
      crnag
      crnht
      pavag
      pavht
      depthPer
      tablePer
      grad
      status
      certificateStatus
      eyeClean
      culSize
      repCommission
      gradePer
      strLn
      lrHalf
      rateAfterDisc
      packetFrom
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;

export const GETINWARDDETAILFTOMLOTANDPACKETNO = gql`
  query getInwardDetailFromLotAndPktNo($lotNo: ID, $pktNo: ID) {
    getInwardDetailFromLotAndPktNo(lotNo: $lotNo, pktNo: $pktNo) {
      id
      location
      lab {
        id
        partyName
      }
      certifiNo
      refNo
      rfId
      cts
      colorId {
        id
        shortName
      }
      shapeId {
        id
        shortName
      }
      purityId {
        id
        shortName
      }
      cutId {
        id
        shortName
      }
      polishId {
        id
        shortName
      }
      symmId {
        id
        shortName
      }
      fluId {
        id
        shortName
      }
      measurment
      brown
      stkDiscountPer
      rateRap
      amountRap
      gradePer
    }
  }
`;
export const GETROUGHSTOCKFORINWARDDETAIL = gql`
  query getRoughStockForInwardDetail($lotNoId: ID, $pktNoId: ID) {
    getRoughStockForInwardDetail(lotNoId: $lotNoId, pktNoId: $pktNoId) {
      rapRate
      rapAmount
      data {
        id
        lotNoId {
          id
          lotNo
        }
        pktNoId {
          id
          pktNo
        }
        issueCts
        colorId {
          id
          shortName
        }
        shapeId {
          id
          shortName
        }
        purityId {
          id
          shortName
        }
        cutId {
          id
          shortName
        }
        polishId {
          id
          shortName
        }
        symmId {
          id
          shortName
        }
        fluId {
          id
          shortName
        }
        location
        rfId
        measurment
        brown
      }
    }
  }
`;

export const GETSALESSTOCKDETAILFTOMLOTANDPACKETNOSTSTUS = gql`
  query getSalesStockFromStatus($status: String! $lotNoId: ID$pktNoId: ID $partyId: ID) {
    getSalesStockFromStatus(
      status: $status
      lotNoId: $lotNoId
      pktNoId: $pktNoId
      partyId: $partyId
    ) {
      id
      location
      rfId
      lab {
        id
        partyName
      }
      certificateId
      cts
      colorId {
        id
        shortName
      }
      shapeId {
        id
        shortName
      }
      cutId {
        id
        shortName
      }
      polishId {
        id
        shortName
      }
      symmId {
        id
        shortName
      }
      fluId {
        id
        shortName
      }
      purityId {
        id
        shortName
      }
      polishId {
        id
        shortName
      }
      measurment
      brown
      rapRate
      stkPer
      rapAmount
      gradPer
    }
  }
`;

export const GETSALESSTOCKDETAILFTOMLOTANDPACKETNOSTSTUS1 = gql`
  query getSalesStockFromStatus1($status: String $lotNoId: ID$pktNoId: ID $partyId: ID) {
    getSalesStockFromStatus1(
      status: $status
      lotNoId: $lotNoId
      pktNoId: $pktNoId
      partyId: $partyId
    ) {
      id
      location
      rfId
      lab {
        id
        partyName
      }
      certificateId
      cts
      colorId {
        id
        shortName
      }
      shapeId {
        id
        shortName
      }
      cutId {
        id
        shortName
      }
      polishId {
        id
        shortName
      }
      symmId {
        id
        shortName
      }
      fluId {
        id
        shortName
      }
      purityId {
        id
        shortName
      }
      polishId {
        id
        shortName
      }
      measurment
      brown
      rapRate
      stkPer
      rapAmount
      gradPer
    }
  }
`;