import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks'
import MaterialTable from 'material-table';
import { withRouter } from "react-router-dom";
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { toast } from 'react-toastify';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import BACKUP from './BackupQuery';
import RESTORE from './BackupMutation';
import { FormatError } from '../../../components/Comman/FormatError';

const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1, },
  noMargin: { margin: 0 },
  dateStyle: { maxWidth: '150px' },
  button: {
    margin: theme.spacing(1),
    marginTop: 0,
    marginBottom: 0
  },
}));


function Backup(props) {
  const classes = useStyles();
  const tableRef = React.createRef()
  const [loader, setLoader] = useState(false)
  const [pageSize, setPageSize] = useState(10)
  const [selectedRow, setSelectedRow] = useState(null);
  const userName = props?.session?.me?.userName;

  const columns = [

    {
      title: 'Date & Time',
      field: 'createdAt',
      type: 'datetime',
    },
    {
      title: 'Name',
      field: 'name',
    },
    {
      title: 'URL',
      field: 'url',
      render: (rowData) => {
        return (
          <div>
            <Button variant="contained" color="primary" size="small" className={classes.button} startIcon={<CloudDownloadIcon />} onClick={() => window.open(rowData.url)}>  Download  </Button>
            <Button variant="contained" color="default" size="small" className={classes.button} startIcon={<CloudUploadIcon />} onClick={() => restoreBackup(rowData.url, rowData.name)}> Restore </Button>
          </div>
        )
      }
    },
  ]

  const [restoreMutation] = useMutation(RESTORE)

  const { fetchMore } = useQuery(BACKUP, {
    variables: { page: 1, limit: 10, filter: JSON.stringify({}), sort: { key: 'createdAt', type: -1 } },
    fetchPolicy: 'cache-and-network'
  })

  const restoreBackup = (url, name) => {
    if (window.confirm("Are you sure?")) {
      return new Promise((resolve, reject) => {
        if (url) {
          setLoader(true)
          restoreMutation({ variables: { url: url, name: name } }).then((data) => {
            toast.success('restore successfully.');
            setLoader(false)
            resolve()
          }).catch((e) => {
            reject()
            setLoader(false)
            toast.error('Please,try after some time.')
          })
        } else {
          toast.error('No backup found!')
        }
      })
    }
  }

  const getData = (query) => {
    setLoader(true)
    return new Promise(async (resolve, reject) => {
      let filter = {}, sort = { key: 'createdAt', type: -1 }
      query && query.filters.forEach(item => {
        if (item.value.length === 0) {
          delete filter[`${item.column.field}`]
          filter = { ...filter }
        } else
          filter = { ...filter, [item.column.field]: item.value }
      });

      if (query && query.orderBy && query.orderDirection !== "") {
        sort = { key: query.orderBy.field, type: query.orderDirection === 'asc' ? 1 : -1 }
      }
      setPageSize(query.pageSize)
      try {
        let result = await fetchMore({
          variables: { page: query.page + 1, limit: query.pageSize, filter: JSON.stringify(filter), sort: sort },
          fetchPolicy: 'network-only',
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return fetchMoreResult;
          }
        })
        setLoader(false)
        resolve({
          data: result?.data?.getBackups?.data || [],
          page: query.page,
          totalCount: result?.data?.getBackups?.count || 0,
        })
      } catch (error) {
        console.log(FormatError(error))
        setLoader(false)
      }
    })
  }

  if (userName === 'superadmin') {
    return (
      <div className={classes.root}>
        <Grid container>
          <Grid item xs={12}>
            <MaterialTable
              title="Backup & Restore"
              columns={columns}
              isLoading={loader}
              tableRef={tableRef}
              data={query => getData(query)}
              options={{
                emptyRowsWhenPaging: false,
                debounceInterval: 300,
                padding: 'dense',
                pageSize: pageSize,
                pageSizeOptions: [5, 10, 15, 20, 25, 50],
                addRowPosition: 'first',
                filtering: true,
                sorting: true,
                search: false,
                filterCellStyle: { padding: '5px', },
                maxBodyHeight: window.innerHeight - 215,
                rowStyle: rowData => ({
                  backgroundColor: (selectedRow === rowData.tableData.id) ? 'rgb(212, 218, 255)' : '#f5f5f5'
                }),
                headerStyle: { backgroundColor: 'whitesmoke' }
              }}
              onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
            />
          </Grid>
        </Grid>
      </div>
    );
  } else {
    return (<></>)
  }
}

export default withRouter(Backup)