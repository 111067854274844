import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { PKTNOFROMLOTNO } from '../../../pages/Transaction/Grad/GradQuery';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import _ from 'lodash'
import { FormatError } from '../../Comman/FormatError'


export const PacketNoData = (props) => {
  const { error, data } = useQuery(PKTNOFROMLOTNO, {
    variables: { id:props?.id ,type:props?.type ,partyId:props?.PartyId,salesType:props?.salesType,isRoughStockDetail:props?.isRoughStockDetail},
    fetchPolicy: 'cache-and-network',

  })
  let PktNoFromLotNodata = []
  if (error) {
    console.log(FormatError(error));
  }
  PktNoFromLotNodata = data?.getPacketFromLotNo || []
  return PktNoFromLotNodata
}

export const PacketNoLookup = (ids) => {
  const pktData = PacketNoData(ids)
  let Packetdata = {}
  pktData.map((d, index) => {
    Packetdata[d.id] = d.lotNo
    return null
  })
  return Packetdata
}

export const PacketSelectComponent = (props) => {
  let pktData = PacketNoData({id:props.ids,PartyId:props?.PartyId,type:props?.type,salesType:props?.propsData?.rowData?.type, isRoughStockDetail: props?.isRoughStockDetail})
  const changeselect = (e, newValue) => {
    let propsData = props.propsData
    let Data = { ...propsData.rowData }
    if (props.field === "packetNoId") {
      Data.packetNoId = newValue.id
    }
    else if (props.field === "pktNoId") {
      Data.pktNoId = newValue.id
    } else {
      Data.packetNo = newValue.id
    }
    delete Data.cts
    delete Data.setids
    delete Data.colorId
    delete Data.shapeId
    delete Data.purityId
    delete Data.cutId
    delete Data.polishId
    delete Data.symmId
    delete Data.fluId
    delete Data.cts
    delete Data.rateRap
    delete Data.amountRap
    delete Data.setids
    delete Data.brown
    delete Data.location
    delete Data.measurment
    delete Data.rfId
    propsData.onRowDataChange(Data);
  }
  const defaultProps = {
    options: pktData,
    getOptionLabel: (option) => option.pktNo
  };
  let defaultValue = _.filter(pktData, (newd) => newd.id === props.valueDefaultPacketNo)[0]
  return <Autocomplete
    {...defaultProps}
    disableClearable
    onChange={(e, newValue) => changeselect(e, newValue)}
    defaultValue={defaultValue}
    renderInput={(params) => <TextField   {...params} margin="normal" />}
  />
}