import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { Tofixed } from '../../../components/Comman/Tofix'
const column = [
  { title: 'Kapan', field: 'lotNo' },
  { title: 'Purchase Date', field: 'purchaseDate' },
  { title: 'Purchase Party Name', field: 'partyId' },
  { title: 'Pkt No', field: 'pktNo' },
  { title: 'Rough weight', field: 'roughCts' },
  { title: 'Polish cts', field: 'polishCts' },
  { title: 'Kachu cts', field: 'kachuCts' },
  { title: 'Extra tops', field: 'extraTops' },
  { title: 'shape', field: 'shapeId' },
  { title: 'color', field: 'colorId' },
  { title: 'purity', field: 'purityId' },
  { title: 'cut', field: 'cutId' },
  { title: 'polish', field: 'polishId' },
  { title: 'Symm', field: 'symmId' },
  { title: 'flu', field: 'fluId' },
  { title: 'Department Name', field: 'departmentId' },
  { title: 'Process', field: 'gradDate' },
  { title: 'Rough Cost($)', field: 'roughCostDollar' },
  { title: 'Zar Rate', field: 'zarRate' },
  { title: 'Rough Cost', field: 'roughCost' },
  { title: 'Laser Cost', field: 'laserCost' },
  { title: 'Galexy Cost', field: 'galaxyCost' },
  { title: 'Boil Cost', field: 'boilCost' },
  { title: 'Helium Cost', field: 'heliumCost' },
  { title: 'Polish Cost', field: 'polishCost' },
  { title: 'Certi Cost', field: 'certiCost' },
  { title: 'Expense', field: 'expense' },
  { title: 'Export Charge', field: 'exportCharge' },
  { title: 'Import Charge', field: 'importCharge' },
  { title: 'Interest', field: 'interest' },
  { title: 'Majuri', field: 'majuri' },
  { title: 'Adat Expense', field: 'adatExpense' },
  { title: 'Polish Expense', field: 'polishExpense' },
  { title: 'Rate Diffrence', field: 'rateDiff' },
  { title: 'Total Cost', field: 'totalCost' },
  { title: 'Type', field: 'type' },
  { title: 'Rape Price', field: 'rapRate' },
  { title: 'Back %', field: 'diffPer' },
  { title: 'Amount($)', field: 'netRaprateAmt' },
  { title: 'Rate', field: 'rateZar' },
  { title: 'Amount(Zar)', field: 'amountZar' },
  { title: 'Comm.%', field: 'commisionPer' },
  { title: 'Comm. amount', field: 'commision' },
  { title: 'Total Amount', field: 'netAmtZar' },
  { title: 'Party Name', field: 'partyName' },
  { title: 'Bill Date', field: 'billDate' },
  { title: 'Bill No', field: 'billNo' }
]
export const ExcelView = (props) => {
  const [AllData, SetAllData] = useState([])

  useEffect(() => {
    let Data = props?.AllData || []
    let AllData = []
    Data.map(d => {
      let salesDetail = d?.salesDetail || null
      let issuePartyId = d?.salesStock?.issuePartyId?.partyName || null
      let departmentId = d?.roughStock?.departmentId?.deptName || null

      let status = d?.salesStock?.issuePartyId?.partyName || null
      let roughStockProcess = d?.roughStock?.process?.procName || null

      let TempData = {
        type: d?.salesDetail?.type || "",
        rapRate: d?.salesDetail?.rapRate || "",
        diffPer: d?.salesDetail?.diffPer || "-",
        netRaprateAmt: d?.salesDetail?.netRaprateAmt || "",
        rateZar: d?.salesDetail?.rateZar || "",
        amountZar: d?.salesDetail?.amountZar || "",
        commisionPer: d?.salesDetail?.commisionPer || "",
        commision: d?.salesDetail?.commision || "",
        netAmtZar: d?.salesDetail?.netAmtZar || "",
        partyName: d?.salesDetail?.partyName?.partyName || "",
        billDate: d?.salesDetail?.billDate ? moment(d?.salesDetail?.billDate || null).format('DD-MM-YYYY') : "",
        billNo: d?.salesDetail?.billNo || "",
        lotNo: d?.lotNo,
        purchaseDate: d.purchaseDate ? moment(d?.purchaseDate || 0).format('DD/MM/YYYY') : "",
        partyId: d?.partyId?.partyName || "",
        pktNo: d?.pktNo,
        roughCts: (d?.roughCts === 0 && d?.polishCts === 0 && d?.kachuCts === 0 && d?.extraTops === 0) ? d?.salesStock?.cts || 0 : d?.roughCts || 0,
        polishCts: d.polishCts,
        kachuCts: d?.kachuCts,
        extraTops: d?.extraTops,
        shapeId: d?.roughStock?.shapeId?.shortName || d?.salesStock?.shapeId?.shortName || "",
        colorId: d?.roughStock?.colorId?.shortName || d?.salesStock?.colorId?.shortName || "",
        purityId: d?.roughStock?.purityId?.shortName || d?.salesStock?.purityId?.shortName || "",
        cutId: d?.roughStock?.cutId?.shortName || d?.salesStock?.cutId?.shortName || "",
        polishId: d?.roughStock?.polishId?.shortName || d?.salesStock?.polishId?.shortName || "",
        symmId: d?.roughStock?.symmId?.shortName || d?.salesStock?.symmId?.shortName || "",
        fluId: d?.roughStock?.fluId?.shortName || d?.salesStock?.fluId?.shortName || "",
        departmentId: d.lotNo === "Grand Total" ? "" : salesDetail.type !== null ? "Sold" : issuePartyId !== null ? issuePartyId : departmentId !== null ? departmentId : "",
        gradDate: d.lotNo === "Grand Total" ? "" : salesDetail.type !== null ? "Sold" : status !== null ? status : roughStockProcess !== null ? roughStockProcess : "",
        roughCostDollar: Tofixed(d?.roughCostDollar || 0),
        zarRate: Tofixed(d?.zarRate || 0),
        roughCost: Tofixed(d?.roughCost || 0),
        laserCost: Tofixed(d?.laserCost || 0),
        galaxyCost: Tofixed(d?.galaxyCost || 0),
        boilCost: Tofixed(d?.boilCost || 0),
        heliumCost: Tofixed(d?.heliumCost || 0),
        polishCost: Tofixed(d?.polishCost || 0),
        certiCost: Tofixed(d?.certiCost || 0),
        expense: Tofixed(d?.expense || 0),
        exportCharge: Tofixed(d?.exportCharge || 0),
        importCharge: Tofixed(d?.importCharge || 0),
        interest: Tofixed(d?.interest || 0),
        majuri: Tofixed(d?.majuri || 0),
        adatExpense: d?.adatExpense || 0,
        polishExpense: d?.polishExpense || 0,
        rateDiff: d?.rateDiff || 0,
        totalCost: Tofixed(d?.totalCost || 0),
      }
      AllData.push(TempData)
      return null
    })
    SetAllData(AllData)
  }, [props.AllData])

  return <div id="printdivcontent"
    style={{ display: "none" }}
  >
    <table style={{ width: '100%', border: "1px solid", borderCollapse: "collapse" }} id="table-to-xls">

      <tr>
        <td colSpan={column.length}>
          <h2 style={{ textAlign: "center" }}>Kapan wise Costing Report</h2>
        </td>
      </tr>

      <tr> <td colSpan={column.length}> <p style={{ margin: 0, fontSize: 18, fontWeight: "bold" }}>Party : {props.partyName}</p></td> </tr>
      <tr> <td colSpan={column.length}> <p style={{ margin: 0, fontSize: 18, fontWeight: "bold" }}>Lotno : {props?.lotNo}</p> </td></tr>
      <tr>
        <th colSpan={7}></th>
        <th>Rough</th>
        <th colSpan={7} style={{ borderRight: "1px solid black" }}></th>
        <th colSpan={8}></th>
        <th>Cost(ZAR)</th>
        <th colSpan={9} style={{ borderRight: "1px solid black" }}></th>
        <th colSpan={6} ></th>
        <th>Sales</th>
        <th colSpan={6} ></th>
      </tr>
      <tr >
        {
          _.map(column, (d) => {
            return <th style={{ width: 100, fontSize: 10, border: "1px solid #ddd", textAlign: "center" }}> {d.title}</th>
          })
        }
      </tr>
      {_.map(AllData, (Roughd) => {
        return <tr >
          {
            _.map(column, (d) => {
              return <td style={{ fontSize: 10, border: "1px solid #ddd", textAlign: "center" }}>{Roughd[d.field]}</td>
            })
          }
        </tr>
      })}
    </table>
  </div >
}