import gql from 'graphql-tag';

export const ADD_KAPANDETAIL = gql`
mutation addNewKapanDetail($input: kapanDetailInput!,$log: LogInput){
  addNewKapanDetail(input: $input,log: $log){
    id
    kapanMasterId
    srNo
    lotNo
    pcs
    cts
    avgCts
    rate
    amount
    labourRate
    labourAmount
    expense
    netAmount
    rateZar
    amountZar
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}

`;

export const UPDATE_KAPANMASTER = gql`
mutation updateKapanMaster($input: kapanMasterUpdate,$log: LogInput){
  updateKapanMaster(input: $input,log: $log){
    id
    roughId
    billNo
    date
    partyId
    lotNoList
    remainCts
    totalCts
    netCost
    purType
    amountZar
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}
`;
export const UPDATE_KAPANDETAIL = gql`
mutation updateKapanDetail($input: kapanDetailUpdate!,$log: LogInput){
  updateKapanDetail(input:$input,log: $log){
    id
    kapanMasterId
    srNo
    lotNo
    pcs
    cts
    avgCts
    rate
    amount
    labourRate
    labourAmount
    expense
    netAmount
    rateZar
    amountZar
  }
}
`;


export const DELETE_KAPANDETAIL = gql`
  mutation deleteKapanDetail($id: ID!,$log: LogInput){
    deleteKapanDetail(id:$id,log: $log)
  }
`;
