import gql from 'graphql-tag';

export const PACKETHISTORY = gql`
query getPacketHistory($filter: String,$sort: packetHistorySort){
    getPacketHistory(filter:$filter,sort:$sort){
      count
      data{
        id
        transNo
        fromDept{
          id
          deptName
        }
        toDept{
          id
          deptName
        }
        partyId{
          id
          partyName
        }
        pktType
        cts
        retCts
        lossCts
        lossPer
        extraCts
        colorId{
          id
          shortName
        }
        shapeId{
          id
          shortName
        }
        purityId{
          id
             shortName
        }
        cutId{
          id
          shortName
        }
        polishId{
          id
          shortName
        }
        symmId{
          id
          shortName
        }
        fluId{
          id
          shortName
        }
        expectedCts
        expPolAmt
        entryDate
        totalSubPkt
        totalSubPktCts
        subPkt
        subPktCts
        lotNoId{
          id
          lotNo
        }
        pktNoId{
          id
          pktNo
        }
        orgCts
        transName
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
    }
  }
`;