import React, { useState, useEffect, useRef } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import MaterialTable from "material-table";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import moment from "moment";
import { TextField } from "@material-ui/core";
import { RoughStockFromLotNoAndPacketNo } from "../../../components/DropdownSelect/RoughStockFromLotNoAndPacketNo";
import { LotNoSelectComponent, LotNoData } from "../../../components/DropdownSelect/SelectComponents/LotSelectComponent";
import { GradingSelectComponent } from "../../../components/DropdownSelect/SelectComponents/ShortCodeSelectComponent";
import { PacketSelectComponent } from "../../../components/DropdownSelect/SelectComponents/PktNoSelectComponent";
import { ROUGHSTOCKQUERY } from "./RoughPlanningQuery";
import { ADD_ROUGHPLANNING, UPDATE_ROUGHPLANNING, DELETE_ROUGHPLANNING } from "./RoughPlanningMutation";
import { CompanyAndPartySelectComponent, CompanyAndPartyAllData } from "../../../components/DropdownSelect/SelectComponents/companyAndPartySelectComponent";
import { FormatError } from "../../../components/Comman/FormatError";
import { UserListAllData } from "../../../components/DropdownSelect/SelectComponents/UserNameSelectComponent";
import { CustomFilterComponent } from "../../../components/CustomFilterComponent/index";
import { headerStyle, cellStyle } from '../../../components/Comman/StyleCell'
import { GETALLSHORTCODETYPE } from '../../Utility/SortCode/SortCodeQuery';
import { Tofixed } from '../../../components/Comman/Tofix'
const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1 },
  noMargin: { margin: 0 },
}));

const columns = [
  {
    title: "Approve?",
    field: "isApprove",
    type: "boolean",
    initialEditValue: true,
    sorting: false,
  },
  {
    title: "Planner Name*",
    field: "partyId",
    headerStyle: headerStyle,
    render: (rowData) => rowData?.partyId?.partyName || "SELECT PARTY",
    editComponent: (props) => {
      let valueDefault = props?.rowData?.partyId?.id || props?.rowData?.partyId || 0;
      return <CompanyAndPartySelectComponent QueryName={"PARTY"} valueDefault={valueDefault} prosData={props} />
    },
    cellStyle: { minWidth: 160, textAlign: "center" }
  },
  {
    title: "Barcode",
    field: "barcode",
  },
  {
    title: "Lot No*",
    field: "lotNoId",
    render: (rowData) => rowData?.lotNoId?.lotNo || "-",
    headerStyle: headerStyle,
    cellStyle: cellStyle,
    editable: "onAdd",
  },
  {
    title: "Pkt No*",
    field: "pktNoId",
    filtering: false,
    render: (rowData) => rowData?.pktNoId?.pktNo || "-",
    headerStyle: headerStyle,
    cellStyle: cellStyle,
    editable: "onAdd",
    editComponent: (props) => {
      let valueDefault = props?.rowData?.lotNoId?.id || props?.rowData?.lotNoId || 0;
      let valueDefaultPacketNo = props?.rowData?.pktNoId?.id || props?.rowData?.pktNoId || 0;
      return <PacketSelectComponent field={"pktNoId"} propsData={props} ids={valueDefault} valueDefaultPacketNo={valueDefaultPacketNo} />
    },
  },
  {
    title: "Plan No",
    field: "planNo",
    type: "numeric",
    headerStyle: headerStyle,
    cellStyle: cellStyle,
    editable: "onAdd",
  },
  {
    title: "Part",
    field: "part",
    headerStyle: headerStyle,
    cellStyle: cellStyle,
    editable: "onAdd",
  },
  {
    title: "Org.Cts*",
    field: "orgCts",
    type: "numeric",
    render: (rowData) => Tofixed(rowData?.orgCts || rowData || 0),
    editable: "never",
  },
  {
    title: "Exp.Cts",
    field: "expCts",
    type: "numeric",
    render: (rowData) => Tofixed(rowData?.expCts || 0),
    editComponent: (props) => {
      return (
        <TextField
          value={props?.rowData?.expCts}
          type="number"
          onChange={(e) => {
            let Data = { ...props.rowData };
            Data.expCts = e.target.value;
            props.onRowDataChange(Data);
          }}
        />
      );
    },
  },
  {
    title: "Color*",
    field: "colorId",
    headerStyle: headerStyle,
    cellStyle: cellStyle,
    render: (rowData) => rowData?.colorId?.shortName || "-",
  },
  {
    title: "Shape*",
    field: "shapeId",
    headerStyle: headerStyle,
    cellStyle: cellStyle,
    render: (rowData) => rowData?.shapeId?.shortName || "-",
    editComponent: ({ rowData }) => {
      return rowData?.shapeId?.shortName || "-";
    },
  },
  {
    title: "Purity*",
    field: "purityId",
    headerStyle: headerStyle,
    cellStyle: cellStyle,
    render: (rowData) => rowData?.purityId?.shortName || "-",
    editComponent: ({ rowData }) => {
      return rowData?.purityId?.shortName || "-";
    },
  },
  {
    title: "Cut*",
    field: "cutId",
    headerStyle: headerStyle,
    cellStyle: cellStyle,
    render: (rowData) => rowData?.cutId?.shortName || "-",
    editComponent: ({ rowData }) => {
      return rowData?.cutId?.shortName || "-";
    },
  },
  {
    title: "Polish*",
    field: "polishId",
    headerStyle: headerStyle,
    cellStyle: cellStyle,
    render: (rowData) => rowData?.polishId?.shortName || "-",
    editComponent: ({ rowData }) => {
      return rowData?.polishId?.shortName || "-";
    },
  },
  {
    title: "Symm.*",
    field: "symmId",
    render: (rowData) => rowData?.symmId?.shortName || "-",
    editComponent: ({ rowData }) => {
      return rowData?.symmId?.shortName || "-";
    },
  },
  {
    title: "Flou.*",
    field: "fluId",
    headerStyle: headerStyle,
    cellStyle: cellStyle,
    render: (rowData) => rowData?.fluId?.shortName || "-",
    editComponent: ({ rowData }) => {
      return rowData?.fluId?.shortName || "-";
    },
  },
  {
    title: "Comment", field: "comment", headerStyle: headerStyle,
    cellStyle: cellStyle, editable: "onAdd"
  },
  {
    title: "Make?",
    field: "isMake",
    type: "boolean",
    initialEditValue: true,
    sorting: false,
    editable: "onAdd",
  },
  { title: "User", field: "createdBy", render: (rowData) => rowData?.createdBy?.userName || " ", editable: "never" }
];

function RoughPlanning(props) {
  const classes = useStyles();
  const tableRef = useRef();

  const [pageSize, setPageSize] = useState(10);
  const [selectedRow, setSelectedRow] = useState(null);

  const [addRoughPlanningMutation] = useMutation(ADD_ROUGHPLANNING);
  const [updateRoughPlanningMutation] = useMutation(UPDATE_ROUGHPLANNING);
  const [deleteRoughPlanningMutation] = useMutation(DELETE_ROUGHPLANNING);

  const { fetchMore, subscribeToMore , refetch } = useQuery(ROUGHSTOCKQUERY, {
    variables: { page: 1, limit: 10, filter: JSON.stringify({}), sort: { key: 'createdAt', type: -1 } },
    fetchPolicy: 'cache-and-network'
  });

  const { data: shortCodeData } = useQuery(GETALLSHORTCODETYPE, {
    variables: { type: "All" },
    fetchPolicy: 'cache-and-network',
  })

  const getShortCodeData = (TYPE) => {
    return _.filter(shortCodeData?.getAllType || [], (d) => d.type === TYPE);
  }

  const SetDefaultValue = (value) => {
    return value?.id || value;
  };
  columns.map((d) => {
    if (d.field === "createdBy") {
      if (d.field === "createdBy") {
        d.filterComponent = (props) => {
          return <CustomFilterComponent propsData={props} defaultProps={{ options: UserListAllData(), getOptionLabel: (option) => option.userName, multiple: true }} />
        }
      }
    }
    if (d.field === "partyId") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: CompanyAndPartyAllData("PARTY"), getOptionLabel: (option) => option.partyName, multiple: true }} />
      }
    }
    if (d.field === "lotNoId") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: LotNoData(), getOptionLabel: (option) => option.lotNo, multiple: true }} />
      }
      d.editComponent = (props) => {
        let valueDefault = props?.rowData?.lotNoId?.id || props?.rowData?.lotNoId || '0'
        return <LotNoSelectComponent field="lotNoId" propsData={props} valueDefault={valueDefault}
        />
      };
    }
    if (d.field === "colorId") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: getShortCodeData("COLOR"), getOptionLabel: (option) => option.shortName, multiple: true }} />
      }
      d.editComponent = (props) => {
        let lotNoId = props?.rowData?.lotNoId?.id || props?.rowData?.lotNoId || '0'
        let pktNoId = props?.rowData?.pktNoId?.id || props?.rowData?.pktNoId || '0';
        if (lotNoId && pktNoId) {
          let RoughStockData = RoughStockFromLotNoAndPacketNo(lotNoId, pktNoId);
          let Data = { ...props.rowData };
          let Ids = RoughStockData[0]?.id || null;
          let Ids2 = Data?.setids || "";
          if (Ids && Ids2 !== Ids) {
            Data.colorId = RoughStockData[0]?.colorId || "-";
            Data.fluId = RoughStockData[0]?.fluId || "-";
            Data.shapeId = RoughStockData[0]?.shapeId || "-";
            Data.purityId = RoughStockData[0]?.purityId || "-";
            Data.cutId = RoughStockData[0]?.cutId || "-";
            Data.polishId = RoughStockData[0]?.polishId || "-";
            Data.symmId = RoughStockData[0]?.symmId || "-";
            Data.orgCts = RoughStockData[0]?.orgCts || "0";
            Data.setids = RoughStockData[0].id;
            props.onRowDataChange(Data);
          }
          if (props?.rowData?.colorId || null) {
            return <GradingSelectComponent propsData={props} TYPE="colorId" data={getShortCodeData("COLOR")} valueDefault={SetDefaultValue(props?.rowData?.colorId) || "-"} />
          } else {
            return "-";
          }
        } else return "-"
      };
    }
    if (d.field === "shapeId") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: getShortCodeData("SHAPE"), getOptionLabel: (option) => option.shortName, multiple: true }} />
      }
      d.editComponent = (props) => {
        if (props?.rowData?.shapeId || null) {
          return <GradingSelectComponent propsData={props} TYPE="shapeId" data={getShortCodeData("SHAPE")} valueDefault={SetDefaultValue(props?.rowData?.shapeId) || "-"} />
        } else return "-";
      };
    }
    if (d.field === "purityId") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: getShortCodeData("PURITY"), getOptionLabel: (option) => option.shortName, multiple: true }} />
      }
      d.editComponent = (props) => {
        if (props?.rowData?.purityId || null) {
          return <GradingSelectComponent propsData={props} TYPE="purityId" data={getShortCodeData("PURITY")} valueDefault={SetDefaultValue(props?.rowData?.purityId) || "-"} />
        } else return "-"
      };
    }
    if (d.field === "cutId") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: getShortCodeData("CUT"), getOptionLabel: (option) => option.shortName, multiple: true }} />
      }
      d.editComponent = (props) => {
        if (props?.rowData?.cutId || null) {
          return <GradingSelectComponent propsData={props} TYPE="cutId" data={getShortCodeData("CUT")} valueDefault={SetDefaultValue(props?.rowData?.cutId) || "-"} />
        } else return "-"
      };
    }
    if (d.field === "polishId") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: getShortCodeData("POLISH"), getOptionLabel: (option) => option.shortName, multiple: true }} />
      }
      d.editComponent = (props) => {
        if (props?.rowData?.polishId || null) {
          return <GradingSelectComponent propsData={props} TYPE="polishId" data={getShortCodeData("POLISH")} valueDefault={SetDefaultValue(props?.rowData?.polishId) || "-"} />
        } else return "-"
      };
    }
    if (d.field === "symmId") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: getShortCodeData("SYMMENTRY"), getOptionLabel: (option) => option.shortName, multiple: true }} />
      }
      d.editComponent = (props) => {
        if (props?.rowData?.symmId || null) {
          return <GradingSelectComponent propsData={props} TYPE="symmId" data={getShortCodeData("SYMMENTRY")} valueDefault={SetDefaultValue(props?.rowData?.symmId) || "-"} />
        } else return "-";
      };
    }
    if (d.field === "fluId") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: getShortCodeData("FLOURSENCE"), getOptionLabel: (option) => option.shortName, multiple: true }} />
      }
      d.editComponent = (props) => {
        if (props?.rowData?.fluId || null) {
          return <GradingSelectComponent propsData={props} TYPE="fluId" data={getShortCodeData("FLOURSENCE")} valueDefault={SetDefaultValue(props?.rowData?.fluId) || "-"} />
        } else return "-";
      };
    }
    return null
  });

  const getRoughPlanningData = (query) => {
    refetch();
    return new Promise(async (resolve, reject) => {
      let filter = {}, sort = { key: 'createdAt', type: -1 }
      query && query.filters.forEach(item => {
        if ((item.column.field === "date")) {
          if (item.value)
            filter = { ...filter, [item.column.field]: moment(item.value).format("YYYY-MM-DD") }
          else {
            delete filter[item.column.field]
            filter = { ...filter }
          }
        }
        else if ((item.column.field === "currencyRate" || item.column.field === "currencyAmount" || item.column.field === "partyAmtZar" || item.column.field === "adgAmtZar")) {
          if (item.value)
            filter = { ...filter, [item.column.field]: parseFloat(item.value) }
          else {
            delete filter[item.column.field]
            filter = { ...filter }
          }
        }
        else if (item.value.length === 0) {
          delete filter[`${item.column.field}`]
          filter = { ...filter }
        } else
          filter = { ...filter, [item.column.field]: item.value }
      });
      if (query && query.orderBy && query.orderDirection !== "") {
        sort = { key: query.orderBy.field, type: query.orderDirection === 'asc' ? 1 : -1 }
      }
      setPageSize(query.pageSize)
      try {
        let result = await fetchMore({
          variables: { page: query.page + 1, limit: query.pageSize, filter: JSON.stringify(filter), sort: sort },
          fetchPolicy: 'network-only',
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return fetchMoreResult;
          }
        })
        resolve({
          data: result?.data?.getRoughPlanning?.data || [],
          page: query.page,
          totalCount: result?.data?.getRoughPlanning?.count || 0,
        })
      } catch (error) {
        console.log(FormatError(error))
      }
    })
  };

  const AddRoughPlanning = (newData) => {
    return new Promise((resolve, reject) => {
      refetch()
      let createObj = {
        isApprove: newData.isApprove,
        partyId: newData.partyId ? newData.partyId : null,
        barcode: newData.barcode ? newData.barcode : "",
        lotNoId: newData.lotNoId,
        pktNoId: newData.pktNoId,
        planNo: newData.planNo ? newData.planNo : 0,
        part: newData.part ? newData.part : "",
        orgCts: newData.orgCts ? parseFloat(newData.orgCts) : 0,
        expCts: newData.expCts ? parseFloat(newData.expCts) : 0,
        colorId: newData.colorId && newData.colorId.id ? newData.colorId.id : newData.colorId,
        shapeId: newData.shapeId && newData.shapeId.id ? newData.shapeId.id : newData.shapeId,
        purityId: newData.purityId && newData.purityId.id ? newData.purityId.id : newData.purityId,
        cutId: newData.cutId && newData.cutId.id ? newData.cutId.id : newData.cutId,
        polishId: newData.polishId && newData.polishId.id ? newData.polishId.id : newData.polishId,
        symmId: newData.symmId && newData.symmId.id ? newData.symmId.id : newData.symmId,
        fluId: newData.fluId && newData.fluId.id ? newData.fluId.id : newData.fluId,
        comment: newData.comment ? newData.comment : "",
        isMake: newData.isMake ? newData.isMake : false,
      };

      if (createObj.orgCts >= createObj.expCts) {
        if (createObj.partyId && createObj.lotNoId && createObj.pktNoId && createObj.orgCts > -1) {
          let log = {
            action: 'INSERT',
            actionOn: 'RoughPlanning',
            actionName: 'addNewRoughPlanning',
            oldValue: "",
            message: ` Planning was created.`
          }
          addRoughPlanningMutation({ variables: { input: createObj, log } })
            .then((data) => {
              toast.success("rough planning created successfully.");
              resolve();
            })
            .catch((e) => {
              reject();
              toast.error(FormatError(e));
            });
        } else {
          reject();
          toast.warn("please, fill all required(*) fields.");
        }
      }
      else {
        reject();
        toast.error('expect carats is max than carats ')
      }

    });
  };

  const UpdateRoughPlanning = (newData, oldData) => {
    return new Promise((resolve, reject) => {
      refetch()
      delete newData.createdAt;
      delete newData.createdBy;
      delete newData.updatedAt;
      delete newData.updatedBy;
      delete newData.__typename;
      let updateObj = {
        isApprove: newData.isApprove,
        partyId: newData.partyId.id ? newData.partyId.id : newData.partyId,
        barcode: newData.barcode ? newData.barcode : "",
        lotNoId: newData.lotNoId.id ? newData.lotNoId.id : newData.lotNoId,
        pktNoId: newData.pktNoId.id ? newData.pktNoId.id : newData.pktNoId,
        planNo: newData.planNo ? newData.planNo : 0,
        part: newData.part ? newData.part : "",
        orgCts: newData.orgCts ? parseFloat(newData.orgCts) : 0,
        expCts: newData.expCts ? parseFloat(newData.expCts) : 0,
        colorId: newData.colorId && newData.colorId.id ? newData.colorId.id : newData.colorId,
        shapeId: newData.shapeId && newData.shapeId.id ? newData.shapeId.id : newData.shapeId,
        purityId: newData.purityId && newData.purityId.id ? newData.purityId.id : newData.purityId,
        cutId: newData.cutId && newData.cutId.id ? newData.cutId.id : newData.cutId,
        polishId: newData.polishId && newData.polishId.id ? newData.polishId.id : newData.polishId,
        symmId: newData.symmId && newData.symmId.id ? newData.symmId.id : newData.symmId,
        fluId: newData.fluId && newData.fluId.id ? newData.fluId.id : newData.fluId,
        comment: newData.comment ? newData.comment : "",
        isMake: newData.isMake ? newData.isMake : false,
        id: newData.id,
      };
      if (
        updateObj.partyId &&
        updateObj.lotNoId &&
        updateObj.pktNoId &&
        updateObj.orgCts
      ) {
        let log = {
          action: 'UPDATE',
          actionOn: 'RoughPlanning',
          actionName: 'updateRoughPlanning',
          oldValue: JSON.stringify(oldData),
          message: `Planning of Packet name ${oldData?.pktNoId?.pktNo || ''} was updated.`
        }
        updateRoughPlanningMutation({ variables: { input: updateObj, log } })
          .then((data) => {
            toast.success("rough planning updated successfully.");
            resolve();
          })
          .catch((e) => {
            reject();
            toast.error(FormatError(e));
          });
      } else {
        toast.warn("please, fill all required(*) fields.");
      }
    });
  };

  const DeleteRoughPlanning = (oldData) => {
    return new Promise((resolve, reject) => {
      refetch()
      let log = {
        action: 'DELETE',
        actionOn: 'RoughPlanning',
        actionName: 'deleteRoughPlanning',
        oldValue: JSON.stringify(oldData),
        message: `Planning of Packet name ${oldData?.pktNoId?.pktNo || ''} was deleted.`
      }
      deleteRoughPlanningMutation({ variables: { id: oldData.id, log } })
        .then((data) => {
          toast.success("rough planning deleted successfully.");
          resolve();
        })
        .catch((e) => {
          reject();
          toast.error("err -", e.message);
        });
    });
  };

  useEffect(() => {
    document.getElementsByClassName("parentTable")[0].firstElementChild.firstElementChild.firstChild.remove()
  }, [])
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <div className="parentTable">
            <MaterialTable
              title="Rough Planning"
              tableRef={tableRef}
              columns={columns}
              data={(query) => getRoughPlanningData(query)}
              icons={{
                Add: props => {
                  return (<div> <i className="fa fa-plus-square" ></i> Rough Planning </div>
                  )
                }
              }}
              options={{
                emptyRowsWhenPaging: false,
                showTitle: false,
                debounceInterval: 300,
                padding: "dense",
                pageSize: pageSize,
                pageSizeOptions: [5, 10, 15, 20, 25, 50],
                addRowPosition: "first",
                filtering: true,
                sorting: true,
                search: false,
                filterCellStyle: { padding: "5px" },
                maxBodyHeight: window.innerHeight - 215,
                rowStyle: (rowData) => ({
                  backgroundColor:
                    selectedRow === rowData.tableData.id
                      ? "rgb(212, 218, 255)"
                      : "#FFF",
                }),
                headerStyle: { backgroundColor: 'whitesmoke' }
              }}
              editable={{
                onRowAdd: (newData) => AddRoughPlanning(newData),
                onRowUpdate: (newData, oldData) =>
                  UpdateRoughPlanning(newData, oldData),
                onRowDelete: (oldData) => DeleteRoughPlanning(oldData),
              }}
              onRowClick={(evt, selectedRow) =>
                setSelectedRow(selectedRow.tableData.id)
              }
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default withRouter(RoughPlanning);
