import React, { useRef, useEffect, useState } from 'react'
import { Grid, TextField } from '@material-ui/core';
import MaterialTable from 'material-table';
import { useQuery, useMutation } from '@apollo/react-hooks'
import { INWARDDETAIL } from './InwordQuery'
import { ADD_INWARDDETAIL, DELETE_INWARDDETAIL, UPDATE_UPDATEINWARDDETAIL } from './InwardMutation'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { GradingSelectComponent, GradingLocationSelectComponent } from '../../../components/DropdownSelect/SelectComponents/ShortCodeSelectComponent'
import { GETALLSHORTCODETYPE } from '../../Utility/SortCode/SortCodeQuery';
import { LotNoSelectComponent } from '../../../components/DropdownSelect/SelectComponents/LotSelectComponent'
import { PacketSelectComponent } from '../../../components/DropdownSelect/SelectComponents/PktNoSelectComponent'
import { FormatError } from '../../../components/Comman/FormatError'
import { BrokerSelectComponent } from '../../../components/DropdownSelect/SelectComponents/BrokerSelectComponent'
import { Tofixed } from '../../../components/Comman/Tofix'
import { GetRoughStockForInwardDetail } from '../../../components/DropdownSelect/GetRoughStockForInwardDetail'
import { PRICE_LIST } from '../../Utility/RapaPortUser/RapaPortUserQuery'
import { GetRapeRate } from '../../../components/DropdownSelect/SelectComponents/Getraprate'
import { headerStyle, cellStyle } from '../../../components/Comman/StyleCell'

const RapAmountCalculate = (rowData) => {
  let cts = rowData?.cts || 0
  let rateRap = rowData?.rateRap || 0
  return (cts * rateRap)
}
const TotalAmountCalculate = (rowData) => {
  // Extract values from rowData or set defaults
  let amountRap = rowData?.amountRap || 0;
  let purityDiscountPer = rowData?.purityDiscountPer || 0;
  let lessPer1 = rowData?.lessPer1 || 0;
  let lessPer2 = rowData?.lessPer2 || 0;
  let stkDiscountPer = rowData?.stkDiscountPer || 0;

  // Calculate amount after purity discount
  let amountAfterPurityDiscount = amountRap - (amountRap * purityDiscountPer / 100);

  // Apply other discounts
  let totalAmount = amountAfterPurityDiscount
    - (amountAfterPurityDiscount * lessPer1 / 100)
    - (amountAfterPurityDiscount * lessPer2 / 100)
    + (amountAfterPurityDiscount * stkDiscountPer / 100); // Apply stkDiscountPer as an addition

  return totalAmount;
};

const BrokrageAmountCalculate = (rowData) => {
  let brokeragePer = rowData?.brokeragePer || 0
  return ((TotalAmountCalculate(rowData) * brokeragePer) / 100)
}
const TransectionExpAmountCalculate = (rowData) => {
  let transExpensesPer = rowData?.transExpensesPer || 0
  return ((TotalAmountCalculate(rowData) * transExpensesPer) / 100)
}
const NetAmountDollerCalculate = (rowData) => {
  return ((TotalAmountCalculate(rowData)) + (BrokrageAmountCalculate(rowData)) + (TransectionExpAmountCalculate(rowData)))
}
const ZarAmountCalculate = (rowData) => {
  let zarRate = rowData?.rateZar || 0
  return (NetAmountDollerCalculate(rowData) * zarRate)
}

const columns = [
  {
    title: 'Lot No*',
    field: 'lotNo',
    render: (rowData) => rowData?.lotNo?.lotNo,
    headerStyle: headerStyle,
    cellStyle: cellStyle,
  },
  {
    title: 'Pkt No*',
    field: 'packetNo',
    render: (rowData) => rowData?.packetNo?.pktNo,
    headerStyle: headerStyle,
    cellStyle: cellStyle,
  },
  {
    title: 'CTS*',
    field: 'cts',
    type: "numeric",
    editComponent: (props) => {
      //   let valueDefault = props?.rowData?.brokerPer || 0;
      return (
        <TextField
          value={props?.rowData?.cts}
          type="number"
          onChange={(e) => {
            let Data = { ...props.rowData };
            Data.cts = e.target.value;
            props.onRowDataChange(Data);
          }}
        />
      );
    },
    render: (rowData) => Tofixed(rowData?.cts || 0),
    headerStyle: headerStyle,
    cellStyle: cellStyle,
  },
  {
    title: 'Location',
    field: 'location',
    headerStyle: headerStyle,
    cellStyle: cellStyle,
  },
  {
    title: 'Lab',
    field: 'lab',
    render: (rowData) => rowData?.lab?.partyName,
    editComponent: props => {
      let valueDefault = props?.rowData?.lab?.id || 0
      return <BrokerSelectComponent props={props} valueDefault={valueDefault} TYPE={"LAB"} />
    }
    , headerStyle: headerStyle,
    cellStyle: cellStyle,
  },
  {
    title: 'Certificate No',
    field: 'certifiNo',
    headerStyle: headerStyle,
    cellStyle: cellStyle,
  },
  {
    title: 'Ref No*',
    field: 'refNo',
    type: "numeric",
    headerStyle: headerStyle,
    cellStyle: cellStyle,
  },
  {
    title: 'RFID',
    field: 'rfId',
    headerStyle: headerStyle,
    cellStyle: cellStyle,
  },
  {
    title: 'Color*',
    field: 'colorId',
    render: (rowData) => rowData?.colorId?.shortName || "",
    headerStyle: headerStyle,
    cellStyle: cellStyle,
  },
  {
    title: 'Shape*',
    field: 'shapeId',
    render: (rowData) => rowData?.shapeId?.shortName || "",
    headerStyle: headerStyle,
    cellStyle: cellStyle,
  },
  {
    title: 'Purity*',
    field: 'purityId',
    render: (rowData) => rowData?.purityId?.shortName || "",
    headerStyle: headerStyle,
    cellStyle: cellStyle,
  },
  {
    title: 'Cut*',
    field: 'cutId',
    render: (rowData) => rowData?.cutId?.shortName || "",
    headerStyle: headerStyle,
    cellStyle: cellStyle,
  },
  {
    title: 'Polish*',
    field: 'polishId',
    render: (rowData) => rowData?.polishId?.shortName || "",
    headerStyle: headerStyle,
    cellStyle: cellStyle,
  },
  {
    title: 'Symm.*',
    field: 'symmId',
    render: (rowData) => rowData?.symmId?.shortName || "",
    headerStyle: headerStyle,
    cellStyle: cellStyle,
  },
  {
    title: 'Flu*',
    field: 'fluId',
    render: (rowData) => rowData?.fluId?.shortName || "",
    headerStyle: headerStyle,
    cellStyle: cellStyle,
  },
  {
    title: 'Measurment',
    field: 'measurment',
    headerStyle: headerStyle,
    cellStyle: cellStyle,
  },
  {
    title: 'Depth',
    field: 'depth',
    headerStyle: headerStyle,
    cellStyle: cellStyle,
  },
  {
    title: 'Table',
    field: 'table',
    headerStyle: headerStyle,
    cellStyle: cellStyle,
  },
  {
    title: 'Brown',
    field: 'brown',
    headerStyle: headerStyle,
    cellStyle: cellStyle,
  },
  {
    title: 'Gridle%',
    field: 'gridlePer',
    type: "numeric",
    editComponent: props => {
      return <TextField defaultValue={props?.rowData?.gridlePer || 0} type="number" onChange={(e) => {
        let Data = { ...props.rowData }
        Data.gridlePer = e.target.value
        if (Data.gridlePer >= 0 && Data.gridlePer <= 100) {
          props.onRowDataChange(Data)
        } else {
          toast.warn("please, enter gridlePer percentage value between 0 to 100.")
        }
      }} />;
    }
    , headerStyle: headerStyle,
    cellStyle: cellStyle,
  },
  {
    title: 'Rape Rate',
    field: 'rateRap',
    type: "numeric",
    editComponent: (props) => {
      //   let valueDefault = props?.rowData?.brokerPer || 0;
      return (
        <TextField
          value={props?.rowData?.rateRap}
          type="number"
          onChange={(e) => {
            let Data = { ...props.rowData };
            Data.rateRap = Number(e.target.value);
            props.onRowDataChange(Data);
          }}
        />
      );
    },
    render: (rowData) => rowData?.rateRap ? Tofixed(rowData?.rateRap || 0) : "",
    headerStyle: headerStyle,
    cellStyle: cellStyle,
  },
  {
    title: 'Rape Amt.',
    field: 'amountRap',
    editComponent: ({ rowData }) => RapAmountCalculate(rowData),
    render: (rowData) => rowData?.amountRap ? Tofixed(rowData?.amountRap || 0) : "",
    headerStyle: headerStyle,
    cellStyle: cellStyle,
  },
  {
    title: 'Pur. Disc.%',
    field: 'purityDiscountPer',
    type: "numeric",
    editComponent: props => {
      let valueDefault = props?.rowData?.purityDiscountPer || 0
      return <TextField defaultValue={valueDefault} type="number" onChange={(e) => {
        let Data = { ...props.rowData }
        Data.purityDiscountPer = Number(e.target.value)
        if (Data.purityDiscountPer >= 0 && Data.purityDiscountPer <= 100) {
          props.onRowDataChange(Data)
        } else {
          toast.warn("please, enter Pur. Disc percentage value between 0 to 100.")
        }
      }} />;
    },
    headerStyle: headerStyle,
    cellStyle: cellStyle,
  },
  {
    title: 'STK Disc.%',
    field: 'stkDiscountPer',
    type: "numeric",
    editComponent: props => {
      let valueDefault = props?.rowData?.stkDiscountPer || 0
      return <TextField defaultValue={valueDefault} type="number" onChange={(e) => {
        let Data = { ...props.rowData }
        Data.stkDiscountPer = Number(e.target.value)
        if (Data.stkDiscountPer >= 0 && Data.stkDiscountPer <= 100) {
          props.onRowDataChange(Data)
        } else {
          toast.warn("please, enter STK Disc percentage value between 0 to 100.")
        }
      }} />;
    },
    headerStyle: headerStyle,
    cellStyle: cellStyle,
  },
  {
    title: 'less 1%',
    field: 'lessPer1',
    type: "numeric",
    editComponent: props => {
      let valueDefault = props?.rowData?.lessPer1 || 0
      return <TextField defaultValue={valueDefault} type="number" onChange={(e) => {
        let Data = { ...props.rowData }
        Data.lessPer1 = e.target.value
        if (Data.lessPer1 >= 0 && Data.lessPer1 <= 100) {
          props.onRowDataChange(Data)
        } else {
          toast.warn("please, enter less 1 percentage value between 0 to 100.")
        }
      }} />;
    },
    headerStyle: headerStyle,
    cellStyle: cellStyle,
  },
  {
    title: 'less 2%',
    field: 'lessPer2',
    type: "numeric",
    editComponent: props => {
      let valueDefault = props?.rowData?.lessPer2 || 0
      return <TextField defaultValue={valueDefault} type="number" onChange={(e) => {
        let Data = { ...props.rowData }
        Data.lessPer2 = e.target.value
        if (Data.lessPer2 >= 0 && Data.lessPer2 <= 100) {
          props.onRowDataChange(Data)
        } else {
          toast.warn("please, enter less 2 percentage value between 0 to 100.")
        }
      }} />;
    },
    headerStyle: headerStyle,
    cellStyle: cellStyle,
  },
  {
    title: 'Total Amt.',
    field: 'totalAmount',
    render: (rowData) => rowData?.totalAmount ? Tofixed(rowData?.totalAmount || 0) : "",
    editComponent: ({ rowData }) => TotalAmountCalculate(rowData),
    headerStyle: headerStyle,
    cellStyle: cellStyle,
  },
  {
    title: 'Brokerage%',
    field: 'brokeragePer',
    type: "numeric",
    editComponent: props => {
      let valueDefault = props?.rowData?.brokeragePer || 0
      return <TextField defaultValue={valueDefault} type="number" onChange={(e) => {
        let Data = { ...props.rowData }
        Data.brokeragePer = Number(e.target.value)
        if (Data.brokeragePer >= 0 && Data.brokeragePer <= 100) {
          props.onRowDataChange(Data)
        } else {
          toast.warn("please, enter broker percentage value between 0 to 100.")
        }
      }} />;
    },
    headerStyle: headerStyle,
    cellStyle: cellStyle,
  },
  {
    title: 'Brokerage Amt',
    field: 'brokerageAmount',
    render: (rowData) => rowData?.brokerageAmount ? Tofixed(rowData?.brokerageAmount || 0) : "",
    editComponent: ({ rowData }) => BrokrageAmountCalculate(rowData),
    headerStyle: headerStyle,
    cellStyle: cellStyle,
  },
  {
    title: 'Trans. Exp%',
    field: 'transExpensesPer',
    type: "numeric",
    editComponent: props => {
      let valueDefault = props?.rowData?.transExpensesPer || 0
      return <TextField defaultValue={valueDefault} type="number" onChange={(e) => {
        let Data = { ...props.rowData }
        Data.transExpensesPer = e.target.value
        if (Data.transExpensesPer >= 0 && Data.transExpensesPer <= 100) {
          props.onRowDataChange(Data)
        } else {
          toast.warn("please, enter Trans. Exp percentage value between 0 to 100.")
        }
      }} />;
    },
    headerStyle: headerStyle,
    cellStyle: cellStyle,
  },
  {
    title: 'Trans. Exp% Amt',
    field: 'transExpensesPerAmount',
    render: (rowData) => rowData?.transExpensesPerAmount ? Tofixed(rowData?.transExpensesPerAmount || 0) : "",
    editComponent: ({ rowData }) => TransectionExpAmountCalculate(rowData), headerStyle: headerStyle,
    cellStyle: cellStyle,
  },
  {
    title: 'Net Amt($)',
    field: 'netAmountDollar',
    render: (rowData) => Tofixed(rowData?.netAmountDollar || 0),
    editComponent: ({ rowData }) => NetAmountDollerCalculate(rowData).toFixed(2),
    headerStyle: headerStyle,
    cellStyle: cellStyle,
  },
  {
    title: 'Zar Rate',
    field: 'rateZar',
    type: "numeric",
    render: (rowData) => rowData?.rateZar ? Tofixed(rowData?.rateZar || 0) : "",
    editComponent: (props) => {
      //   let valueDefault = props?.rowData?.brokerPer || 0;
      return (
        <TextField
          value={props?.rowData?.rateZar}
          type="number"
          onChange={(e) => {
            let Data = { ...props.rowData };
            Data.rateZar = e.target.value;
            props.onRowDataChange(Data);
          }}
        />
      );
    },
    headerStyle: headerStyle,
    cellStyle: cellStyle,
  },
  {
    title: 'Zar Amt.',
    field: 'amountZar',
    render: (rowData) => Tofixed(rowData?.amountZar || 0),
    editComponent: ({ rowData }) => ZarAmountCalculate(rowData).toFixed(2),
    headerStyle: headerStyle,
    cellStyle: cellStyle,
  },
]
export const Inwardchild = (props) => {
  const tableRef = useRef();
  const permissions = props?.session?.me?.role?.permissions;
  const role = props?.session?.me?.role?.role;
  const path = props?.location?.pathname;
  let pricelistData = [];
  let permission = _.find(permissions, function (o) { return o.name === path; })


  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);


  const { error, data } = useQuery(INWARDDETAIL, {
    variables: { id: props.Ids },
    fetchPolicy: 'cache-and-network'
  })

  useEffect(() => {
    if (data?.getAllInwardDetail) {
      getRoughDetail();
    }
  }, [data?.getAllInwardDetail]);

  const { data: shortCodeData } = useQuery(GETALLSHORTCODETYPE, {
    variables: { type: "All" },
    fetchPolicy: 'cache-and-network',
  })
  const { data: priceList } = useQuery(PRICE_LIST, {
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    pricelistData = priceList?.getRapaportPriceList || [];
  }, [priceList]);
  const getShortCodeData = (TYPE) => {
    return _.filter(shortCodeData.getAllType, (d) => d.type === TYPE);
  }
  const SetDefaultValue = (value, type) => {
    return value?.id || value
  }
  let inwardType = props.InwardMasterData.inwardType
  columns.map((d, key) => {
    if (d.field === "packetNo") {
      d.editComponent = props => {
        if (inwardType !== "SERVICE INWARD") {
          return <TextField key={key} id="standard-basic" defaultValue={props?.rowData?.packetNo?.pktNo} onChange={(e) => {
            const { value } = e.target
            let Data = { ...props.rowData }
            Data.packetNo = value
            props.onRowDataChange(Data);
          }} />
        } else {
          let valueDefault = props?.rowData?.lotNo?.id || props?.rowData?.lotNo || '0'
          let valueDefaultPacketNo = props?.rowData?.packetNo?.id || props?.rowData?.packetNo || '0'
          return <PacketSelectComponent key={key} unsetData={true} propsData={props} ids={valueDefault} valueDefaultPacketNo={valueDefaultPacketNo} />
        }
      }
    }
    if (d.field === "depth") {
      if (inwardType === "PURCHASE") {
        d.editable = "always"
      } else {
        d.editable = "never"
      }
    }
    if (d.field === "table") {
      if (inwardType === "PURCHASE") {
        d.editable = "always"
      } else {
        d.editable = "never"
      }
    }
    if (d.field === "lotNo") {
      d.editComponent = props => {
        if (inwardType !== "SERVICE INWARD") {
          return <TextField id="standard-basic" defaultValue={props?.rowData?.lotNo?.lotNo || ""} onChange={(e) => {
            const { value } = e.target
            let Data = { ...props.rowData }
            Data.lotNo = value
            props.onRowDataChange(Data);
          }} />
        } else {
          let valueDefault = props?.rowData?.lotNo?.id || []
          return <LotNoSelectComponent propsData={props} valueDefault={valueDefault} />
        }
      }
    }
    if (d.field === "colorId") {
      d.editComponent = (props) => {
        let lotNoId = props?.rowData?.lotNo?.id || props?.rowData?.lotNo || null
        let pktNoId = props?.rowData?.packetNo?.id || props?.rowData?.packetNo || null
        if (lotNoId && pktNoId) {
          let InwardData = GetRoughStockForInwardDetail(lotNoId, pktNoId)
          let Data = { ...props.rowData }
          let Ids = null, Ids2 = ''
          Ids = InwardData?.data ? InwardData?.data[0].id : null
          Ids2 = Data?.setids || ''
          if (Ids && Ids2 !== Ids) {
            Data.colorId = InwardData?.data[0]?.colorId || "_"
            Data.shapeId = InwardData?.data[0]?.shapeId || "_"
            Data.purityId = InwardData?.data[0]?.purityId || "_"
            Data.cutId = InwardData?.data[0]?.cutId || "_"
            Data.polishId = InwardData?.data[0]?.polishId || "_"
            Data.symmId = InwardData?.data[0]?.symmId || "_"
            Data.fluId = InwardData?.data[0]?.fluId || "_"
            Data.cts = InwardData?.data[0]?.issueCts || null
            Data.rateRap = InwardData?.rapRate || 0
            Data.amountRap = InwardData?.rapAmount || 0
            Data.brown = InwardData?.data[0]?.brown || ""

            Data.measurment = InwardData?.data[0]?.measurment || ""
            Data.rfId = InwardData?.data[0]?.rfId || ""
            Data.setids = InwardData?.data[0]?.id
            props.onRowDataChange(Data)

          }
          if (props?.rowData?.cts || null) {
            return <GradingSelectComponent componentName="Inward" shortCodeData={shortCodeData} pricelistData={pricelistData} propsData={props} TYPE="colorId" data={getShortCodeData("COLOR")} valueDefault={SetDefaultValue(props?.rowData?.colorId)} />
          } else
            return ""
        } else {
          return ""
        }
      }
    }
    if (d.field === "location") {
      d.editComponent = (props) => {
        let Cts = props?.rowData?.cts || null
        if (Cts) {

          return <GradingLocationSelectComponent propsData={props} TYPE="location" data={getShortCodeData("LOCATION")} valueDefault={SetDefaultValue(props?.rowData?.location || null)} />
        } else return ""
      }
    }
    if (d.field === "shapeId") {
      d.editComponent = (props) => {
        let cts = props?.rowData?.cts || null
        if (cts) {
          return <GradingSelectComponent componentName="Inward" shortCodeData={shortCodeData} pricelistData={pricelistData} propsData={props} TYPE="shapeId" data={getShortCodeData("SHAPE")} valueDefault={SetDefaultValue(props?.rowData?.shapeId)} />
        } else
          return ""
      }
    }
    if (d.field === "purityId") {
      d.editComponent = (props) => {
        if (props?.rowData?.cts || null) {
          return <GradingSelectComponent componentName="Inward" shortCodeData={shortCodeData} pricelistData={pricelistData} propsData={props} TYPE="purityId" data={getShortCodeData("PURITY")} valueDefault={SetDefaultValue(props?.rowData?.purityId)} />
        } else
          return ""
      }
    }
    if (d.field === "cutId") {
      d.editComponent = (props) => {

        // let shape = props?.rowData?.shapeId?.shortName || props?.rowData?.shapeId || null
        // let purity = props?.rowData?.purityId?.shortName || props?.rowData?.purityId || null
        // let color = props?.rowData?.colorId?.shortName || props?.rowData?.colorId || null
        // let cts = parseFloat(Tofixed(props?.rowData?.cts || null))
        // let raprate = ''

        // if (shape && purity && color && cts) {
        //   if (CTS !== cts || PURITY !== purity || COLOR !== color || SHAPE !== shape) {
        //     CTS = cts
        //     PURITY = purity
        //     COLOR = color
        //     SHAPE = shape
        //     raprate = GetRapeRate(pricelistData, cts, purity, color, shape, shortCodeData)
        //     let Data = { ...props.rowData }
        //     Data.rateRap = raprate?.rapRate || 0
        //     Data.amountRap = raprate?.rapAmount || 0
        //     props.onRowDataChange(Data)
        //   }
        // }

        if (props?.rowData?.cts || null) {
          return <GradingSelectComponent propsData={props} TYPE="cutId" data={getShortCodeData("CUT")} valueDefault={SetDefaultValue(props?.rowData?.cutId)} />
        } else
          return ""
      }
    }
    if (d.field === "polishId") {
      d.editComponent = (props) => {
        if (props?.rowData?.cts || null) {
          return <GradingSelectComponent propsData={props} TYPE="polishId" data={getShortCodeData("POLISH")} valueDefault={SetDefaultValue(props?.rowData?.polishId)} />
        } else
          return ""
      }
    }
    if (d.field === "symmId") {
      d.editComponent = (props) => {
        if (props?.rowData?.cts || null) {
          return <GradingSelectComponent propsData={props} TYPE="symmId" data={getShortCodeData("SYMMENTRY")} valueDefault={SetDefaultValue(props?.rowData?.symmId)} />
        } else
          return ""
      }
    }
    if (d.field === "fluId") {
      d.editComponent = (props) => {
        if (props?.rowData?.cts || null) {
          return <GradingSelectComponent propsData={props} TYPE="fluId" data={getShortCodeData("FLOURSENCE")} valueDefault={SetDefaultValue(props?.rowData?.fluId)} />
        } else
          return ""
      }
    }
    if (d.field === "cts") {
      d.editComponent = (props) => {
        let lotNoId = props?.rowData?.lotNo?.id || props?.rowData?.lotNo || null
        let pktNoId = props?.rowData?.packetNo?.id || props?.rowData?.packetNo || null
        if (lotNoId && pktNoId) {
          return <TextField value={props?.rowData?.cts || 0} onChange={(e) => {
            let Data = { ...props.rowData }
            Data.cts = e.target.value
            let shape = Data?.shapeId?.id || Data?.shapeId || null
            let purity = Data?.purityId?.id || Data?.purityId || null
            let color = Data?.colorId?.id || Data?.colorId || null
            let cts = parseFloat(Tofixed(Data?.cts || null))
            if (shape && purity && color && cts) {
              let raprate = GetRapeRate(pricelistData, cts, purity, color, shape, shortCodeData)
              Data.rateRap = raprate?.rapRate || 0
              Data.amountRap = raprate?.rapAmount || 0
            }
            props.onRowDataChange(Data)
          }} />
        } else {
          return 0
        }
      }
    }
    return null
  })
  const HideRow = (rows) => {
    let aa = document.getElementsByClassName('childTable')
    for (let index = 0; index < aa.length; index++) {
      setTimeout(() => {
        if (rows && rows !== 0 && aa[index]) {
          let newD = aa[index].getElementsByClassName('MuiTableBody-root')[0].rows[aa[index].getElementsByClassName('MuiTableBody-root')[0].rows.length - 1]
          if (newD.getElementsByClassName('MuiTableCell-sizeSmall')[0]) {
            newD.getElementsByClassName('MuiTableCell-sizeSmall')[0].innerText = 'Total'
            newD.style.background = 'rgb(212, 218, 255)'
          }
        }
      }, 500);
    }
  }
  const getRoughDetail = () => {
    return new Promise((resolve, reject) => {
      if (error)
        reject(error)
      let result = [...data?.getAllInwardDetail || []]
      if (result.length !== 0) {
        let LastRow = {
          'packetNo': (result.length).toString(),
          'cts': Tofixed(_.sum(_.map(result, 'cts'))),
          'netAmountDollar': Tofixed(_.sum(_.map(result, 'netAmountDollar'))),
          'amountZar': Tofixed(_.sum(_.map(result, 'amountZar'))),
          // 'amountRs': Tofixed(_.sum(_.map(result, 'amountRs'))),
        }
        result.push(LastRow)
      }
      HideRow(result.length)
      resolve({
        data: result,
      })
    }).then(res => {
      setTableData(res.data);
      setLoading(false);
    })
      .catch(err => {
        console.error("Error fetching data:", err);
        setLoading(false);
      });
  }
  const UpdateInwardMaster = (TYPE, datas) => {
    let TotalPackets = 0
    let TotalCts = 0
    let ZarAmount = 0
    let NetAmount = 0
    let NewData = props.InwardMasterData
    let result = [...data?.getAllInwardDetail || []]
    if (TYPE === "ADD") {

      TotalPackets = result.length === 0 ? 1 : result.length
      TotalCts = _.sum(_.map(result, 'cts')) + datas.cts
      ZarAmount = _.sum(_.map(result, 'amountZar')) + datas.amountZar
      NetAmount = _.sum(_.map(result, 'netAmountDollar')) + datas.netAmountDollar
    }
    if (TYPE === "UPDATE") {

      TotalPackets = result.length
      TotalCts = _.sum(_.map(_.filter(result, (d) => d.id !== datas.id), 'cts')) + datas.cts
      ZarAmount = _.sum(_.map(_.filter(result, (d) => d.id !== datas.id), 'amountZar')) + datas.amountZar
      NetAmount = _.sum(_.map(_.filter(result, (d) => d.id !== datas.id), 'netAmountDollar')) + datas.netAmountDollar
    }
    if (TYPE === "DELETE") {
      TotalPackets = result.length - 1
      TotalCts = _.sum(_.map(_.filter(result, (d) => d.id !== datas.id), 'cts'))
      ZarAmount = _.sum(_.map(_.filter(result, (d) => d.id !== datas.id), 'amountZar'))
      NetAmount = _.sum(_.map(_.filter(result, (d) => d.id !== datas.id), 'netAmountDollar'))
    }
    NewData.totalCts = parseFloat(Tofixed(TotalCts))
    NewData.totalPackets = parseFloat(Tofixed(TotalPackets))
    NewData.amountZar = parseFloat(Tofixed(ZarAmount))
    NewData.netAmount = parseFloat(Tofixed(NetAmount))
    NewData.amountTotalNet = parseFloat(Tofixed(NetAmount + NewData.bankCharges + NewData.dbCharges + NewData.brinksCharges))
    let a = NewData.tableData
    delete NewData.tableData
    if (props.UpdateThrueInwardDetail(NewData)) {
      // props.updateMasterData(NewData, props.InwardMasterData)
      NewData.tableData = a
      return true
    } else {
      return false
    }
  }
  const [AddInwardDetailMutation] = useMutation(ADD_INWARDDETAIL)
  const [UpdateUpdateDetailMutation] = useMutation(UPDATE_UPDATEINWARDDETAIL)
  const [DeleteInwardDetailMutation] = useMutation(DELETE_INWARDDETAIL)
  const AddInwardChild = (newData) => {
    newData.colorId = newData?.colorId?.id ? newData?.colorId?.id : newData?.colorId || null
    newData.shapeId = newData?.shapeId?.id ? newData?.shapeId?.id : newData?.shapeId || null
    newData.purityId = newData?.purityId?.id ? newData?.purityId?.id : newData?.purityId || null
    newData.cutId = newData?.cutId?.id ? newData?.cutId?.id : newData?.cutId || null
    newData.polishId = newData?.polishId?.id ? newData?.polishId?.id : newData?.polishId || null
    newData.symmId = newData?.symmId?.id ? newData?.symmId?.id : newData?.symmId || null
    newData.fluId = newData?.fluId?.id ? newData?.fluId?.id : newData?.fluId || null
    newData.amountRap = parseFloat(Tofixed(RapAmountCalculate(newData)))
    newData.totalAmount = parseFloat(Tofixed(TotalAmountCalculate(newData)))
    newData.brokerageAmount = parseFloat(Tofixed(BrokrageAmountCalculate(newData)))
    newData.transExpensesPerAmount = parseFloat(Tofixed(TransectionExpAmountCalculate(newData)))
    newData.netAmountDollar = parseFloat(Tofixed(NetAmountDollerCalculate(newData)))
    newData.amountZar = parseFloat(Tofixed(ZarAmountCalculate(newData)))
    newData.refNo = parseInt(newData.refNo)
    newData.cts = parseFloat(Tofixed(newData.cts))
    newData.gridlePer = parseFloat(Tofixed(newData.gridlePer))
    newData.rateRap = parseFloat(Tofixed(newData.rateRap))
    newData.purityDiscountPer = parseFloat(Tofixed(newData.purityDiscountPer))
    newData.stkDiscountPer = parseFloat(Tofixed(newData.stkDiscountPer))
    newData.lessPer1 = parseFloat(Tofixed(newData.lessPer1))
    newData.lessPer2 = parseFloat(Tofixed(newData.lessPer2))
    newData.brokeragePer = parseFloat(Tofixed(newData.brokeragePer))
    newData.transExpensesPer = parseFloat(Tofixed(newData.transExpensesPer))
    newData.rateZar = parseFloat(Tofixed(newData.rateZar))
    newData.inwardMasterId = props.Ids
    newData.lab = newData.lab ? newData.lab : null
    newData.inwardNo = props.InwardMasterData.inwardNo
    newData.inwardType = props.InwardMasterData.inwardType
    newData.partyId = props.InwardMasterData.partyId.id
    delete newData.setids
    return new Promise((resolve, reject) => {
      if (newData.cts) {
        if (newData.lotNo && newData.packetNo && newData.refNo && newData.colorId && newData.shapeId && newData.purityId && newData.cutId && newData.polishId && newData.symmId && newData.fluId
        ) {

          if (UpdateInwardMaster("ADD", newData)) {
            let log = {
              action: 'INSERT',
              actionOn: 'InwardDetail',
              actionName: 'addNewInwardDetail',
              oldValue: "",
              message: `Inward detail was created.`
            }
            AddInwardDetailMutation({ variables: { input: newData, log } }).then((data) => {
              toast.success('inward detail created successfully');
              props.tableRef.current.onQueryChange();
              // props.refetch()

              resolve()
            }).catch((e) => {
              reject()
              toast.error(FormatError(e))
            })
          } else {
          }
        } else {
          reject()
          toast.warn('please, fill all required(*) fields')
        }
      } else {
        toast.warn('pkt not issued in branch Transfer')
      }
    })
  }
  const UpdateInwardChild = (newData, oldData) => {
    return new Promise((resolve, reject) => {
      delete newData.createdBy
      delete newData.createdAt
      delete newData.__typename
      delete newData.updatedBy
      delete newData.updatedAt
      delete newData.setids
      if (inwardType !== "SERVICE INWARD") {
        newData.lotNo = newData?.lotNo?.lotNo || newData.lotNo;
        newData.packetNo = newData?.packetNo?.pktNo || newData.packetNo;
      } else {
        newData.lotNo = newData?.lotNo?.id || newData.lotNo
        newData.packetNo = newData?.packetNo?.id || newData.packetNo
      }
      newData.colorId = newData?.colorId?.id ? newData?.colorId?.id : newData?.colorId || null
      newData.shapeId = newData?.shapeId?.id ? newData?.shapeId?.id : newData?.shapeId || null
      newData.purityId = newData?.purityId?.id ? newData?.purityId?.id : newData?.purityId || null
      newData.cutId = newData?.cutId?.id ? newData?.cutId?.id : newData?.cutId || null
      newData.polishId = newData?.polishId?.id ? newData?.polishId?.id : newData?.polishId || null
      newData.symmId = newData?.symmId?.id ? newData?.symmId?.id : newData?.symmId || null
      newData.fluId = newData?.fluId?.id ? newData?.fluId?.id : newData?.fluId || null
      newData.lab = newData?.lab?.id || newData?.lab || null
      newData.inwardMasterId = props.Ids
      newData.amountRap = parseFloat(Tofixed(RapAmountCalculate(newData)))
      newData.totalAmount = parseFloat(Tofixed(TotalAmountCalculate(newData)))
      newData.brokerageAmount = parseFloat(Tofixed(BrokrageAmountCalculate(newData)))
      newData.transExpensesPerAmount = parseFloat(Tofixed(TransectionExpAmountCalculate(newData)))
      newData.netAmountDollar = parseFloat(Tofixed(NetAmountDollerCalculate(newData)))
      newData.amountZar = parseFloat(Tofixed(ZarAmountCalculate(newData)))
      newData.refNo = parseInt(newData.refNo)
      newData.cts = parseFloat(Tofixed(newData.cts))
      newData.gridlePer = parseFloat(Tofixed(newData.gridlePer))
      newData.rateRap = parseFloat(Tofixed(newData.rateRap))
      newData.purityDiscountPer = parseFloat(Tofixed(newData.purityDiscountPer))
      newData.stkDiscountPer = parseFloat(Tofixed(newData.stkDiscountPer))
      newData.lessPer1 = parseFloat(Tofixed(newData.lessPer1))
      newData.lessPer2 = parseFloat(Tofixed(newData.lessPer2))
      newData.brokeragePer = parseFloat(Tofixed(newData.brokeragePer))
      newData.transExpensesPer = parseFloat(Tofixed(newData.transExpensesPer))
      newData.rateZar = parseFloat(Tofixed(newData.rateZar))
      delete newData.setids
      newData.inwardNo = props?.InwardMasterData?.inwardNo
      newData.inwardType = props?.InwardMasterData?.inwardType
      newData.partyId = props?.InwardMasterData?.partyId.id
      if (newData.lotNo && newData.packetNo && newData.refNo && newData.cts && newData.colorId && newData.shapeId && newData.purityId && newData.cutId && newData.polishId && newData.symmId && newData.fluId
      ) {
        if (UpdateInwardMaster("UPDATE", newData)) {
          let log = {
            action: 'UPDATE',
            actionOn: 'InwardDetail',
            actionName: 'updateInwardDetail',
            oldValue: JSON.stringify(oldData),
            message: `Inward detail of packet name ${oldData?.packetNo?.pktNo || ''} was updated.`
          }
          UpdateUpdateDetailMutation({ variables: { input: newData, log } }).then((data) => {
            toast.success('inward detail updated successfully');
            props.tableRef.current.onQueryChange();
            resolve()
          }).catch((e) => {
            reject()
            toast.error(FormatError(e))
          })
        }
      } else {
        reject()
        toast.error('please, fill all required(*) fields')
      }
    })
  }
  const DeleteInwardChild = (oldData) => {
    return new Promise((resolve, reject) => {
      let log = {
        action: 'DELETE',
        actionOn: 'InwardDetail',
        actionName: 'deleteInwardDetail',
        oldValue: JSON.stringify(oldData),
        message: `Inward detail of packet name ${oldData?.packetNo?.pktNo || ''} was deleted.`
      }
      if (UpdateInwardMaster("DELETE", oldData)) {
        DeleteInwardDetailMutation({ variables: { id: oldData.id, log } }).then((data) => {
          toast.success('inward detail deleted successfully');
          // props.tableRef.current.onQueryChange();
          resolve()
        }).catch((e) => {
          reject()
          toast.error(FormatError(e))
        })
      }
    })
  }

  const addButton = (val) => {
    if (val || (role === 'admin'))
      return { onRowAdd: (newData) => AddInwardChild(newData) }
    else
      return {}
  }

  return <Grid
    container
    direction="row"
    justify="flex-end" item xs={12} >
    <div className="childTable" >
      <MaterialTable
        loading={loading}
        tableRef={tableRef}
        columns={columns}
        data={tableData}
        options={{
          emptyRowsWhenPaging: false,
          padding: 'dense',
          pageSize: 10,
          pageSizeOptions: [5, 10, 15, 20, 25, 50],
          addRowPosition: 'first',
          filtering: false,
          sorting: false,
          search: false,
          paging: false,
          filterCellStyle: { padding: '5px', },
          maxBodyHeight: window.innerHeight - 215,
          showTitle: false,
          headerStyle: { backgroundColor: 'whitesmoke' }
        }}
        editable={{
          isEditHidden: (rowData) => (role === 'admin') ? false : !permission.isUpdate,
          isDeleteHidden: (rowData) => (role === 'admin') ? false : !permission.isDelete,
          ...addButton(permission?.isInsert),
          onRowUpdate: (newData, oldData) => UpdateInwardChild(newData, oldData),
          onRowDelete: (oldData) => DeleteInwardChild(oldData)
        }}
      />

    </div>
  </Grid>
}
