import gql from 'graphql-tag';

export const ADD_ISSUERETURNMASTER = gql`
mutation addNewIssueReturn($input: issueReturnMasterInput!, $log: LogInput) {
    addNewIssueReturn(input: $input, log: $log) {
      id
      issueNo
      date
      type
      partyId
      totalPcs
      totalCts
      totalAmt
      amtZar
      remark
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;


export const UPDATE_ISSUERETURNMASTER = gql`
mutation updateIssueReturn($input:issueReturnMasterUpdate!, $log: LogInput){
    updateIssueReturn(input:$input, log: $log){
      issueNo
      date
      type
      partyId
      totalPcs
      totalCts
      totalAmt
      amtZar
      remark
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;


export const DELETE_ISSUERETURNMASTER = gql`
mutation  deleteIssueReturn($id: ID!, $log: LogInput){
    deleteIssueReturn(id:$id, log: $log)
  }
`;

export const ADD_MUTIPLE_ISSUERETURN_DETAIL = gql`
mutation addMultipleIssueReturnDetail($input: [issueReturnDetailInput1]!, $log: LogInput){
  addMultipleIssueReturnDetail(input: $input, log: $log) {
    id
    issueReturnMasterId
    location
    barcode
    lotNoId
    pktNoId
    labId
    certificateNo
    rfId
    cts
    colorId
    shapeId
    purityId
    cutId
    polishId
    symmId
    fluId
    measurment
    depth
    table
    gradePer
    rateRap
    stkDiscPer
    rateAfterDisc
    rapAmt
    rateZar
    amtZar
    isRoughStock
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}
`;
export const ADD_ISSUERETURN_DETAIL = gql`
mutation addNewIssueReturnDetail($input: issueReturnDetailInput!, $log: LogInput){
  addNewIssueReturnDetail(input: $input, log: $log) {
    id
    issueReturnMasterId
    location
    barcode
    lotNoId
    pktNoId
    labId
    certificateNo
    rfId
    cts
    colorId
    shapeId
    purityId
    cutId
    polishId
    symmId
    fluId
    measurment
    depth
    table
    gradePer
    rateRap
    stkDiscPer
    rateAfterDisc
    rapAmt
    rateZar
    amtZar
    isRoughStock
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}
`;


export const UPDATE_ISSUERETURN_DETAIL = gql`
mutation updateIssueReturnDetail($input: issueReturnDetailUpdate!, $log: LogInput){
  updateIssueReturnDetail(input:$input, log: $log){
    id
    issueReturnMasterId
    location
    barcode
    lotNoId
    pktNoId
    labId
    certificateNo
    rfId
    cts
    colorId
    shapeId
    purityId
    cutId
    polishId
    symmId
    fluId
    measurment
    depth
    table
    gradePer
    rateRap
    stkDiscPer
    rateAfterDisc
    rapAmt
    rateZar
    amtZar
    isRoughStock
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}
`;


export const DELETE_ISSUERETURN_DETAIL = gql`
  mutation deleteIssueReturnDetail($id: ID!, $log: LogInput){
    deleteIssueReturnDetail(id:$id, log: $log)
  }
`;
