import gql from 'graphql-tag';

export const ADD_BOXSIZE = gql`
    mutation addNewBox($input:boxSizeInput!,$log: LogInput){
      addNewBox(input:$input,log: $log){
        id
        boxNo
        fromSize
        toSize
        rfId
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
    }
`;

export const UPDATE_BOXSIZE = gql`
    mutation boxSizeUpdate($input: boxSizeUpdate!,$log: LogInput){
      updateBox(input:$input,log: $log){
        id
        boxNo
        fromSize
        toSize
        rfId
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
    }
`;

export const DELETE_BOXSIZE = gql`
  mutation deleteBox( $id: ID!,$log: LogInput) {
    deleteBox(id:$id,log: $log)
  }
`;
