import gql from 'graphql-tag';

const TERMS = gql`
    query getTermMaster($page: Int, $limit: Int, $filter:String, $sort:termMasterSort!){
      getTermMaster(page:$page, limit:$limit, filter:$filter, sort:$sort){
        count
        data{
          id
          TERMID
          termName
          Days
          alertDays
          isActive
          createdBy
          updatedBy
          createdAt
          updatedAt
        }
      }
    }
`;
export const ALLTERMS = gql`
  query getAllTerms{
    getAllTerms{
      id
      termName
      Days
    }
  }
`;

export default TERMS

