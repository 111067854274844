import gql from 'graphql-tag';

export const ADD_PARTY = gql`
mutation addNewParty($input: newParty!,$log: LogInput){
  addNewParty(input:$input,log: $log){
    id
    partyId
    partyName
    partyType
    street
    road
    city
    country
    ownerName
    contactName
    restricted
    chequeClear
    pdcAllow
    zip
    mobile1
    mobile2
    phone1
    phone2
    intercom
    fax
    invMsg
    joinDate
    refName
    termId
    leaveDate
    courierName
    shipmentName
    cif
    intPer
    openBal
    balType
    currency
    crLimit
    vatNo
    panNo
    tanNo
    cstNo
    gstNo
    giaAcNo
    ibanNo
    clearCode
    swiftCode
    branchName
    chipsNo
    fedwireNo
    location
    portdisch
    finalDest
    brokerPer
    commisionPer
    printName
    email
    acGroup
    acGroupName
    salesmanId
    salesmanName
    brokerId
    brokerName
    isActive
    createdBy
    createdAt
    updatedAt
  }
}
`;

export const UPDATE_PARTY = gql`
mutation updateParty($input:partyInput!,$log: LogInput){
  updateParty(input:$input,log: $log){
    id
    partyId
    partyName
    partyType
    street
    road
    city
    country
    ownerName
    contactName
    restricted
    chequeClear
    pdcAllow
    zip
    mobile1
    mobile2
    phone1
    phone2
    intercom
    fax
    invMsg
    joinDate
    refName
    termId
    leaveDate
    courierName
    shipmentName
    cif
    intPer
    openBal
    balType
    currency
    crLimit
    vatNo
    panNo
    tanNo
    cstNo
    gstNo
    giaAcNo
    ibanNo
    clearCode
    swiftCode
    branchName
    chipsNo
    fedwireNo
    location
    portdisch
    finalDest
    brokerPer
    commisionPer
    printName
    email
    acGroup
    acGroupName
    salesmanId
    salesmanName
    brokerId
    brokerName
    isActive
    createdBy
    createdAt
    updatedAt
  }
}
`;

export const DELETE_PARTY = gql`
mutation deleteParty($id:ID!,$log: LogInput){
  deleteParty(id:$id,log: $log)
}
`;
