import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { useQuery, useMutation } from '@apollo/react-hooks'
import Grid from '@material-ui/core/Grid';
import MaterialTable from 'material-table';
import { withRouter } from "react-router-dom";
import moment from 'moment'
import PARTYMASTER from './PartyMasterQuery';
import { TextField } from '@material-ui/core';
import { ADD_PARTY, UPDATE_PARTY, DELETE_PARTY } from './PartyMasterMutation';
import { PARTY_SUBSCRIBE } from './PartySubscription';
import { ValidateEmail, ValidateMobile, ValidateStringWithNumber } from '../../../components/Comman/validate.js'
import _, { toInteger } from 'lodash'
import { toast } from 'react-toastify';
import { PartyType, BalanceType } from '../../../components/Constant/index'
import { FormatError } from '../../../components/Comman/FormatError'
import { headerStyle, cellStyle } from '../../../components/Comman/StyleCell'
import { TermsSelectComponent, TransAllData } from '../../../components/DropdownSelect/SelectComponents/TermsSelectComponent'
import { CustomFilterComponent } from '../../../components/CustomFilterComponent/index'
const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1, },
  noMargin: { margin: 0 }
}));
const columns = [
  {
    title: 'Party Name*',
    field: 'partyName',
    validate: rowData => (rowData.partyName && rowData.partyName !== "") ? true : { isValid: false, helperText: `can't be empty` }
  },
  {
    title: 'A/c Group Name',
    field: 'acGroupName'
  },
  {
    title: 'Party Type*',
    field: 'partyType',
    lookup: PartyType,
    validate: rowData => (rowData.partyType && rowData.partyType !== undefined) ? true : { isValid: false, helperText: `can't be empty` }
  },
  {
    title: 'Print Name',
    field: 'printName'
  },
  {
    title: 'Owner Name',
    field: 'ownerName'
  },
  {
    title: 'Contact Name',
    field: 'contactName'
  },
  {
    title: 'Street',
    field: 'street'
  },
  {
    title: 'Road',
    field: 'road'
  },
  {
    title: 'City',
    field: 'city'
  },
  {
    title: 'Zip',
    field: 'zip',
    type: 'numeric'
  },
  {
    title: 'Country',
    field: 'country'
  },
  {
    title: 'Phone(1)',
    field: 'phone1',
    validate: rowData => {
      if (rowData.phone1 && (rowData.phone1 !== "" || rowData.phone1 !== undefined)) {
        return ValidateStringWithNumber(rowData.phone1) ? true : { isValid: false, helperText: `phone number is not valid` }
      }
      else {
        return true
      }
    }
  },
  {
    title: 'Phone(2)',
    field: 'phone2',
    validate: rowData => {
      if (rowData.phone2 && (rowData.phone2 !== "" || rowData.phone2 !== undefined)) {
        return ValidateStringWithNumber(rowData.phone2) ? true : { isValid: false, helperText: `phone number is not valid` }
      }
      else {
        return true
      }
    }
  },
  {
    title: 'Mobile(1)',
    field: 'mobile1',
    validate: rowData => {
      if (rowData.mobile1 && (rowData.mobile1 !== "" || rowData.mobile1 !== undefined)) {
        return ValidateMobile(rowData.mobile1) ? true : { isValid: false, helperText: `mobile is not valid` }
      }
      else {
        return true
      }
    }
  },
  {
    title: 'Mobile(2)',
    field: 'mobile2',
    validate: rowData => {
      if (rowData.mobile2 && (rowData.mobile2 !== "" || rowData.mobile2 !== undefined)) {
        return ValidateMobile(rowData.mobile2) ? true : { isValid: false, helperText: `mobile is not valid` }
      }
      else {
        return true
      }
    }
  },
  {
    title: 'Intercom No',
    field: 'intercom'
  },
  {
    title: 'Fax No',
    field: 'fax'
  },
  {
    title: 'Email',
    field: 'email',
    validate: rowData => {
      if (rowData.email && (rowData.email !== "" || rowData.email !== undefined)) {
        return ValidateEmail(rowData.email) ? true : { isValid: false, helperText: `email is not valid` }
      }
      else {
        return true
      }
    }
  },
  {
    title: 'Broker Name',
    field: 'brokerName'
  },
  {
    title: 'Terms*',
    field: 'termId',
    lookup: {},
    cellStyle: cellStyle,
    headerStyle: headerStyle,
    render: (rowData) => rowData?.termId?.termName || "NO ANY TERMS",
    editComponent: props => {
      let valueDefault = props?.rowData?.termId?.id || props?.rowData?.termId || null
      return <TermsSelectComponent valueDefault={valueDefault} props={props} />
    }

  },
  { title: 'Courier', field: 'courierName' },
  { title: 'shipment On Name', field: 'shipmentName' },
  { title: 'CIF', field: 'cif' },
  { title: 'Ref. Name', field: 'refName' },
  {
    title: 'Join Date*', field: 'joinDate', type: "date",
    render: (rowData) => {
      if (rowData.joinDate)
        return moment(rowData?.joinDate || 0).format('DD/MM/YYYY')
    },
    validate: rowData => (rowData.joinDate && rowData.joinDate !== undefined) ? true : { isValid: false, helperText: `can't be empty` }
  },
  {
    title: 'Leave Date*', field: 'leaveDate', type: "date",
    render: (rowData) => {
      if (rowData.leaveDate)
        return moment(rowData?.leaveDate || 0).format('DD/MM/YYYY')
    },
    validate: rowData => (rowData.leaveDate && rowData.leaveDate !== undefined) ? true : { isValid: false, helperText: `can't be empty` }
  },
  {
    title: 'Broker(%)', field: 'brokerPer', type: "numeric",
    editComponent: props => {
      let valueDefault = props?.rowData?.brokerPer || 0
      return <TextField defaultValue={valueDefault} type="number" onChange={(e) => {
        let Data = { ...props.rowData }
        Data.brokerPer = e.target.value
        if (Data.brokerPer >= 0 && Data.brokerPer <= 100) {
          props.onRowDataChange(Data)
        } else {
          toast.warn("please, enter broker percentage value between 0 to 100.")
        }
      }} />;
    }
  },
  {
    title: 'Commision(%)', field: 'commisionPer', type: "numeric",
    editComponent: props => {
      let valueDefault = props?.rowData?.commisionPer || 0
      return <TextField defaultValue={valueDefault} type="number" onChange={(e) => {
        let Data = { ...props.rowData }
        Data.commisionPer = e.target.value
        if (Data.commisionPer >= 0 && Data.commisionPer <= 100) {
          props.onRowDataChange(Data)
        } else {
          toast.warn("please, enter commission percentage value between 0 to 100.")
        }
      }} />;
    }
  },
  {
    title: 'Interest(%)', field: 'intPer', type: "numeric",
    editComponent: props => {
      let valueDefault = props?.rowData?.intPer || 0
      return <TextField defaultValue={valueDefault} type="number" onChange={(e) => {
        let Data = { ...props.rowData }
        Data.intPer = e.target.value
        if (Data.intPer >= 0 && Data.intPer <= 100) {
          props.onRowDataChange(Data)
        } else {
          toast.warn("please, enter broker intetest percentage value between 0 to 100.")
        }
      }} />
    }
  },
  {
    title: 'Opening Balance', field: 'openBal', type: "numeric",
    editComponent: (props) => {
      return (
        <TextField
          value={props?.rowData?.openBal}
          type="number"
          onChange={(e) => {
            let Data = { ...props.rowData };
            Data.openBal = e.target.value;
            props.onRowDataChange(Data);
          }}
        />
      );
    },
  },
  { title: 'Type*', field: 'balType', initialEditValue: "CREDIT", lookup: BalanceType },
  { title: 'Currency', field: 'currency' },
  {
    title: 'Credit Limit', field: 'crLimit', type: "numeric",
    editComponent: (props) => {
      return (
        <TextField
          value={props?.rowData?.crLimit}
          type="number"
          onChange={(e) => {
            let Data = { ...props.rowData };
            Data.crLimit = e.target.value;
            props.onRowDataChange(Data);
          }}
        />
      );
    },
  },
  { title: 'Invoice  Message', field: 'invMsg' },
  { title: 'Party Active', field: 'isActive', type: "boolean", initialEditValue: true, sorting: false },
  { title: 'Restricted', field: 'restricted', type: "boolean", initialEditValue: true, sorting: false },
  { title: 'Cheque Clearing?', field: 'chequeClear', type: "boolean", initialEditValue: true, sorting: false },
  { title: 'PDC Allow?', field: 'pdcAllow', type: "boolean", initialEditValue: true, sorting: false },
]

function PartyMaster(props) {
  const classes = useStyles();
  const tableRef = React.createRef()
  const [loader, setLoader] = useState(false)
  const [pageSize, setPageSize] = useState(10)
  const [selectedRow, setSelectedRow] = useState(null);

  const permissions = props?.session?.me?.role?.permissions;
  const role = props?.session?.me?.role?.role;
  const path = props?.location?.pathname;
  let permission = _.find(permissions, function (o) { return o.name === path; })

  const { fetchMore, subscribeToMore } = useQuery(PARTYMASTER, {
    variables: { page: 1, limit: 10, filter: JSON.stringify({}), sort: { key: 'createdAt', type: -1 } },
    fetchPolicy: 'cache-and-network',
  })

  columns.map(col => {
    if (col.field === 'termId')
      col.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: TransAllData(), getOptionLabel: (option) => option.termName, multiple: true }} />
      }
    return null
  })

  const [AddPartyMutation] = useMutation(ADD_PARTY)
  const [updatePartyMutation] = useMutation(UPDATE_PARTY)
  const [deletePartyMutation] = useMutation(DELETE_PARTY)

  useEffect(() => {
    if (subscribeToMore) {
      const unsubscribe = subscribeToMore({
        document: PARTY_SUBSCRIBE,
        updateQuery: (previousResult, { subscriptionData }) => {
          if (!subscriptionData.data) {
            return previousResult;
          }
          tableRef.current && tableRef.current.onQueryChange()
          return previousResult;
        },
      });
      return () => unsubscribe();
    }
  }, [subscribeToMore]);

  const AddParty = (newData) => {
    return new Promise((resolve, reject) => {
      setLoader(true)
      newData.intPer = parseFloat(newData.intPer).toFixed(2)
      newData.intPer = parseFloat(newData.intPer)
      newData.openBal = parseFloat(newData.openBal).toFixed(2)
      newData.openBal = parseFloat(newData.openBal)
      newData.crLimit = parseFloat(newData.crLimit).toFixed(2)
      newData.crLimit = parseFloat(newData.crLimit)
      newData.brokerPer = parseFloat(newData.brokerPer).toFixed(2)
      newData.brokerPer = parseFloat(newData.brokerPer)
      newData.commisionPer = parseFloat(newData.commisionPer).toFixed(2)
      newData.commisionPer = parseFloat(newData.commisionPer)
      newData.zip = toInteger(newData.zip)
      if (newData.partyName && newData.partyType && newData.joinDate && newData.leaveDate && newData.balType && newData.termId) {
        let log = {
          action: 'INSERT',
          actionOn: 'party',
          actionName: 'addNewParty',
          oldValue: "",
          message: `${newData?.partyName || ''} party was created.`
        }
        AddPartyMutation({ variables: { input: newData, log } }).then((data) => {
          toast.success('party created successfully.');
          resolve()
          setLoader(false)
        }).catch((e) => {
          setLoader(false)
          reject()
          toast.error(FormatError(e))
        })
      } else {
        setLoader(false)
        toast.warn('please, fill all required(*) fields.')
        reject()
      }
    })
  }

  const UpdateParty = (newData, oldData) => {
    delete newData.createdBy
    delete newData.updatedBy
    delete newData.updatedAt
    delete newData.__typename
    delete newData.createdAt

    newData.intPer = parseFloat(newData.intPer).toFixed(2)
    newData.intPer = parseFloat(newData.intPer)
    newData.openBal = parseFloat(newData.openBal).toFixed(2)
    newData.openBal = parseFloat(newData.openBal)
    newData.crLimit = parseFloat(newData.crLimit).toFixed(2)
    newData.crLimit = parseFloat(newData.crLimit)
    newData.brokerPer = parseFloat(newData.brokerPer).toFixed(2)
    newData.brokerPer = parseFloat(newData.brokerPer)
    newData.commisionPer = parseFloat(newData.commisionPer).toFixed(2)
    newData.commisionPer = parseFloat(newData.commisionPer)
    newData.zip = toInteger(newData.zip)
    newData.termId = newData?.termId?.id || newData?.termId || null
    for (var member in newData) {
      if (newData[member] == null)
        delete newData[member]
    }
    return new Promise((resolve, reject) => {
      setLoader(true)
      if (newData.partyName && newData.partyType && newData.joinDate && newData.leaveDate && newData.balType && newData.termId) {
        let log = {
          action: 'UPDATE',
          actionOn: 'party',
          actionName: 'updateParty',
          oldValue: JSON.stringify(oldData),
          message: `${oldData?.partyName || ''} party was updated.`
        }
        updatePartyMutation({ variables: { input: newData, log } }).then((data) => {
          setLoader(false)
          toast.success('party updated successfully.');
          // refetch()
          resolve()
        }).catch((e) => {
          setLoader(false)
          reject()
          toast.error(FormatError(e))
        })
      } else {
        setLoader(false)
        toast.warn('please, fill all required(*) fields.')
        reject()
      }
    })
  }
  const DeleteParty = (oldData) => {
    return new Promise((resolve, reject) => {
      setLoader(true)
      let log = {
        action: 'DELETE',
        actionOn: 'party',
        actionName: 'deleteParty',
        oldValue: JSON.stringify(oldData),
        message: `${oldData?.partyName || ''} party was deleted.`
      }
      deletePartyMutation({ variables: { id: oldData.id, log } }).then((data) => {
        toast.success('party deleted successfully.');
        setLoader(false)
        resolve()
      }).catch((e) => {
        setLoader(false)
        reject()
        toast.error(FormatError(e))
      })
    })
  }

  const getPartyData = (query) => {
    setLoader(true)
    return new Promise(async (resolve, reject) => {
      let filter = {}, sort = { key: 'createdAt', type: -1 }
      query && query.filters.forEach(item => {
        if (item.column.field === "isActive" || item.column.field === "restricted" || item.column.field === "chequeClear" || item.column.field === "pdcAllow") {
          if (item.value)
            filter = { ...filter, [item.column.field]: item.value === "checked" ? true : false }
          else {
            delete filter.isActive
            filter = { ...filter }
          }
        } else if (item.column.field === "joinDate" || item.column.field === "leaveDate") {
          if (item.value)
            filter = { ...filter, [item.column.field]: moment(item.value).format('YYYY-MM-DD') }
          else {
            delete filter[`${item.column.field}`]
            filter = { ...filter }
          }
        }
        else if (item.value.length === 0) {

          delete filter[`${item.column.field}`]
          filter = { ...filter }
        } else
          filter = { ...filter, [item.column.field]: item.value }
      });
      if (query && query.orderBy && query.orderDirection !== "") {
        sort = { key: query.orderBy.field, type: query.orderDirection === 'asc' ? 1 : -1 }
      }
      setPageSize(query.pageSize)
      try {
        let result = await fetchMore({
          variables: { page: query.page + 1, limit: query.pageSize, filter: JSON.stringify(filter), sort: sort },
          fetchPolicy: 'network-only',
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return fetchMoreResult;
          }
        })
        setLoader(false)
        resolve({
          data: result?.data?.getParties?.data || [],
          page: query.page,
          totalCount: result?.data?.getParties?.count || 0,
        })
      } catch (error) {
        console.log(FormatError(error))
        setLoader(false)
      }
    })
  }

  const addButton = (val) => {
    if (val || (role === 'admin'))
      return { onRowAdd: (newData) => AddParty(newData) }
    else
      return {}
  }

  useEffect(() => {
    document.getElementsByClassName("parentTable")[0].firstElementChild.firstElementChild.firstChild.remove()
  }, [])

  return (<>

    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <div className="parentTable">
            <MaterialTable
              title="Party"
              isLoading={loader}
              columns={columns}
              tableRef={tableRef}
              data={query => getPartyData(query)}
              icons={{ Add: props => { return (<div> <i className="fa fa-plus-square" ></i> Party </div>) } }}
              options={{
                emptyRowsWhenPaging: false,
                debounceInterval: 300,
                showTitle: false,
                padding: 'dense',
                pageSize: pageSize,
                pageSizeOptions: [5, 10, 15, 20, 25, 50],
                addRowPosition: 'first',
                filtering: true,
                sorting: true,
                search: false,
                filterCellStyle: { padding: '5px', },
                maxBodyHeight: window.innerHeight - 215,
                rowStyle: rowData => ({
                  backgroundColor: (selectedRow === rowData.tableData.id) ? 'rgb(212, 218, 255)' : '#f5f5f5'
                }),
                headerStyle: { backgroundColor: 'whitesmoke' }
              }}
              onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
              editable={{
                isEditHidden: (rowData) => (role === 'admin') ? false : !permission.isUpdate,
                isDeleteHidden: (rowData) => (role === 'admin') ? false : !permission.isDelete,
                ...addButton(permission?.isInsert),
                // onRowAdd: (newData) => AddParty(newData),
                onRowUpdate: (newData, oldData) => UpdateParty(newData, oldData),
                onRowDelete: (oldData) => DeleteParty(oldData)
              }}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  </>
  );
}
export default withRouter(PartyMaster)