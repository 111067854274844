import React, { useRef, useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { withRouter } from "react-router-dom";
import { Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery, useMutation } from '@apollo/react-hooks'
import { ADD_PACKETMASTER, UPDATE_PACKETMASTER, DELETE_PACKETMASTER } from './PacketMutation'
import { PACKETMASTER } from './PacketQuery'
import { PacketEntryType } from '../../../components/Constant'
import { toast } from 'react-toastify'
import _ from 'lodash'
import { Packetchild } from './PacketChild'
import { FormatError } from '../../../components/Comman/FormatError'
import moment from 'moment'
import { CompanyAndPartySelectComponent, CompanyAndPartyAllData } from '../../../components/DropdownSelect/SelectComponents/companyAndPartySelectComponent'
import { LotNoSelectComponent, LotNoData } from '../../../components/DropdownSelect/SelectComponents/LotSelectComponent'
import { UserListAllData } from "../../../components/DropdownSelect/SelectComponents/UserNameSelectComponent";
import { CustomFilterComponent } from '../../../components/CustomFilterComponent/index'
import { Tofixed } from '../../../components/Comman/Tofix'

const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1, },
  noMargin: { margin: 0 }
}));

const columns = [
  {
    title: 'Entry Type *',
    field: 'entryType',
    lookup: PacketEntryType
  },
  {
    title: 'Trans. Date *',
    field: 'transDate',
    type: "date",
    initialEditValue: moment(),
    render: (rowData) => {
      if (rowData.transDate)
        return moment(rowData?.transDate || 0).format('DD/MM/YYYY')
    }
  },
  {
    title: 'Party Name *', field: 'partyId', render: (rowData) => {
      return rowData?.partyId?.partyName || "No Any Party"
    },
    editComponent: props => {
      let type = props?.rowData?.entryType || null
      let valueDefault = props?.rowData?.partyId?.id || props?.rowData?.partyId || '0'
      if (type) {
        return <CompanyAndPartySelectComponent key={Math.ceil(Math.random() * 100)} TYPE={type} QueryName={"PARTY"} valueDefault={valueDefault} prosData={props} />
      }
      else {
        return <TextField label={"PARTY"} disabled />
      }
    },
    cellStyle: { minWidth: 160 }
  },
  {
    title: 'Tender No *',
    field: 'tenderId',
    render: (rowData) => rowData?.tenderId || 0,
    editComponent: props => {
      let type = props?.rowData?.entryType || null
      if (type === "MAJURI") {
        return <TextField defaultValue="TENDER NO" disabled />
      } else {
        return <TextField label={"TENDER NO"} type="number" disabled={type === "ROUGH" ? true : false} onChange={(e) => {
          let Data = { ...props.rowData }
          Data.tenderId = e.target.value
          props.onRowDataChange(Data);
        }} />
      }
    },
  },
  {
    title: 'Lot No *', field: 'lotNoId', render: (rowData) => {
      return rowData?.lotNoId?.lotNo || "-"
    },
    editComponent: props => {
      let type = props?.rowData?.entryType || null
      let PartyIdDefault = props.rowData.partyId === 'object' ? props?.rowData?.partyId?.id : props?.rowData?.partyId
      let defaultSetId = props?.rowData?.lotNoId === 'object' ? props?.rowData?.lotNoId?.id : props?.rowData?.lotNoId
      if (type === "ROUGH" && PartyIdDefault) {
        return <LotNoSelectComponent TYPE={type} field="lotNoId" PartyId={PartyIdDefault} propsData={props} valueDefault={defaultSetId} />
      } else {
        return <TextField label={"LOT NO"} defaultValue={props?.rowData?.lotNoId?.lotNo || null
        } onChange={(e) => {
          let Data = { ...props.rowData }
          Data.lotNoId = e.target.value
          props.onRowDataChange(Data);
        }} />
      }
    }
  },
  {
    title: 'Total Pcs *', field: 'totalPcs', type: "numeric", render: (rowData) => rowData?.totalPcs || 0,
    editComponent: props => {
      let type = props?.rowData?.entryType || null
      let totalPcs = props?.rowData?.totalPcs || null
      if (type === "ROUGH") {
        return <TextField value={totalPcs ? totalPcs : ''} disabled />
      } else
        return <TextField type="number" defaultValue={props?.rowData?.totalPcs || 0} onChange={(e) => {
          let Data = { ...props.rowData }
          Data.totalPcs = e.target.value
          props.onRowDataChange(Data);
        }} />
    },
  },
  {
    title: 'Total Cts *', field: 'totalCts', type: "numeric",
    render: (rowData) => Tofixed(rowData?.totalCts || 0),
    editComponent: props => {
      let type = props?.rowData?.entryType || null
      let totalCts = props?.rowData?.totalCts || null
      if (type === "ROUGH") {
        return <TextField value={totalCts ? totalCts : ''} disabled />
      } else
        return <TextField type="number" defaultValue={props?.rowData?.totalCts || 0} onChange={(e) => {
          let Data = { ...props.rowData }
          Data.totalCts = e.target.value
          props.onRowDataChange(Data);
        }} />
    },
  },
  { title: 'Remark', field: 'Remark' },
  { title: "User", field: "createdBy", render: (rowData) => rowData?.createdBy?.userName || " ", editable: "never" }
]

function Packet(props) {
  const classes = useStyles();
  const tableRef = useRef();

  const [pageSize, setPageSize] = useState(10)
  const [selectedRow, setSelectedRow] = useState(null);

  const permissions = props?.session?.me?.role?.permissions;
  const role = props?.session?.me?.role?.role;
  const path = props?.location?.pathname;
  let permission = _.find(permissions, function (o) { return o.name === path; })

  columns.map(d => {
    if (d.field === "partyId") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{
          options: CompanyAndPartyAllData("PARTY"),
          getOptionLabel: (option) => option.partyName,
          multiple: true
        }} />
      }
    } if (d.field === "lotNoId") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{
          options: LotNoData(),
          getOptionLabel: (option) => option.lotNo,
          multiple: true
        }} />
      }
    }
    if (d.field === "createdBy") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{
          options: UserListAllData(),
          getOptionLabel: (option) => option.userName,
          multiple: true
        }} />
      }
    }
    return null
  })

  const [AddPacketMutation] = useMutation(ADD_PACKETMASTER)
  const [UpdatePacketMutation] = useMutation(UPDATE_PACKETMASTER)
  const [DeletePacketMutation] = useMutation(DELETE_PACKETMASTER)

  const { fetchMore } = useQuery(PACKETMASTER, {
    variables: { page: 1, limit: 10, filter: JSON.stringify({}), sort: { key: 'createdAt', type: -1 } },
    fetchPolicy: 'cache-and-network'
  })

  const getPacketData = (query) => {
    return new Promise(async (resolve, reject) => {
      let filter = {}, sort = { key: 'createdAt', type: -1 }
      query && query.filters.forEach(item => {
        if ((item.column.field === "transDate")) {
          if (item.value)
            filter = { ...filter, [item.column.field]: moment(item.value).format("YYYY-MM-DD") }
          else {
            delete filter[item.column.field]
            filter = { ...filter }
          }
        }
        else if ((item.column.field === "totalPcs")) {
          if (item.value)
            filter = { ...filter, [item.column.field]: parseInt(item.value) }
          else {
            delete filter[item.column.field]
            filter = { ...filter }
          }
        }
        else if ((item.column.field === "totalCts" || item.column.field === "tenderId")) {
          if (item.value)
            filter = { ...filter, [item.column.field]: parseFloat(item.value) }
          else {
            delete filter[item.column.field]
            filter = { ...filter }
          }
        }
        else if (item.value.length === 0) {
          delete filter[`${item.column.field}`]
          filter = { ...filter }
        } else
          filter = { ...filter, [item.column.field]: item.value }
      });
      if (query && query.orderBy && query.orderDirection !== "") {
        sort = { key: query.orderBy.field, type: query.orderDirection === 'asc' ? 1 : -1 }
      }
      setPageSize(query.pageSize)
      try {
        let result = await fetchMore({
          variables: { page: query.page + 1, limit: query.pageSize, filter: JSON.stringify(filter), sort: sort },
          fetchPolicy: 'network-only',
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return fetchMoreResult;
          }
        })
        resolve({
          data: result?.data?.getPacketMaster?.data || [],
          page: query.page,
          totalCount: result?.data?.getPacketMaster?.count || 0,
        })
      } catch (error) {
        console.log(FormatError(error))
      }
    })
  }

  const AddPacket = (newData) => {
    newData.totalPcs = parseInt(newData.totalPcs)
    newData.totalCts = parseFloat(newData.totalCts)
    newData.tenderId = parseFloat(newData.tenderId)
    return new Promise((resolve, reject) => {
      if (newData.entryType) {
        if (newData.entryType === "ROUGH") {
          if (!newData.transDate || !newData.partyId || !newData.lotNoId) {
            reject()
            toast.warn('please, fill all required(*) fields.')
            return false
          }
        } else if (newData.entryType === "TENDER") {
          if (!newData.transDate || !newData.partyId || (newData.tenderId === undefined) || !newData.lotNoId || !newData.totalPcs || !newData.totalCts) {
            reject()
            toast.warn('please, fill all required(*) fields.')
            return false
          }
        }
        else if (newData.entryType === "MAJURI") {
          if (!newData.transDate || !newData.partyId || !newData.lotNoId || !newData.totalPcs || !newData.totalCts) {
            reject()
            toast.warn('please, fill all required(*) fields.')
            return false
          }
        }
      } else {
        reject()
        toast.warn('please, fill all required(*) fields.')
        return false
      }
      let log = {
        action: 'INSERT',
        actionOn: 'PacketMaster',
        actionName: 'addNewPacket',
        oldValue: "",
        message: `${newData?.entryType || ''} type Packet was created.`
      }
      AddPacketMutation({ variables: { input: newData, log } }).then((data) => {
        toast.success('packet master created successfully.');
        resolve()
      }).catch((e) => {
        reject()
        toast.error(e && e.message && e.message.replace('GraphQL error: Unexpected error value:', ''))
      })
    })
  }

  const UpdatePacket = (newData) => {
    delete newData.createdBy
    delete newData.createdAt
    delete newData.__typename
    delete newData.updatedBy
    delete newData.updatedAt

    newData.lotNoId = newData?.lotNoId?.id || null
    newData.partyId = newData?.partyId?.id || null

    newData.totalPcs = parseInt(newData.totalPcs)
    newData.totalCts = parseFloat(newData.totalCts)
    newData.tenderId = parseFloat(newData.tenderId)
    return new Promise((resolve, reject) => {
      UpdatePacketMutation({ variables: { input: newData } }).then((data) => {
        tableRef.current.onQueryChange();
        resolve()
      }).catch((e) => {
        reject()
        toast.error(FormatError(e))
      })
    })
  }
  const DeletePacket = (oldData) => {
    return new Promise((resolve, reject) => {
      let log = {
        action: 'DELETE',
        actionOn: 'PacketMaster',
        actionName: 'deletePacket',
        oldValue: JSON.stringify(oldData),
        message: `${oldData?.entryType || ''} type Packet was deleted.`
      }
      DeletePacketMutation({ variables: { id: oldData.id, log } }).then((data) => {
        toast.success('packet master deleted successfully.');
        resolve()
      }).catch((e) => {
        reject()
        toast.error(FormatError(e))
      })
    })
  }
  useEffect(() => {
    document.getElementsByClassName("parentTable")[0].firstElementChild.firstElementChild.firstChild.remove()
  }, [])
  const addButton = (val) => {
    if (val || (role === 'admin'))
      return {
        onRowAdd: (newData) => AddPacket(newData)
      }
    else
      return {}
  }

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <div className="parentTable">
            <MaterialTable
              title="Packet"
              tableRef={tableRef}
              columns={columns}
              data={query => getPacketData(query)}
              icons={{ Add: props => { return (<div> <i className="fa fa-plus-square" ></i> Packet </div>) } }}
              options={{
                emptyRowsWhenPaging: false,
                showTitle: false,
                debounceInterval: 300,
                padding: 'dense',
                pageSize: pageSize,
                pageSizeOptions: [5, 10, 15, 20, 25, 50],
                addRowPosition: 'first',
                filtering: true,
                sorting: true,
                search: false,
                filterCellStyle: { padding: '5px', },
                maxBodyHeight: window.innerHeight - 215,
                rowStyle: rowData => ({ backgroundColor: (selectedRow === rowData.tableData.id) ? 'rgb(212, 218, 255)' : '#f5f5f5' }),
                headerStyle: { backgroundColor: 'whitesmoke' }
              }}
              editable={{
                isDeleteHidden: (rowData) => (role === 'admin') ? false : !permission.isDelete,
                ...addButton(permission?.isInsert),
                onRowDelete: (oldData) => DeletePacket(oldData)
              }}
              detailPanel={rowData => {
                return (
                  <Packetchild
                    key={rowData.id}
                    session={props?.session}
                    location={props?.location}
                    Ids={rowData.id}
                    UpdatePacket={UpdatePacket}
                    PacketMasterData={rowData}
                    tableRef={tableRef}
                    updateMasterData={query => getPacketData(query)}
                  />
                )
              }}
              onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
            />
          </div>
        </Grid>
      </Grid>
    </div >
  );
}
export default withRouter(Packet)