import gql from 'graphql-tag';

export const SHORT_SUBSCRIBE = gql`
  subscription ShortCodeChange {
    ShortCodeChange {
      keyType
      data{
        id
        codeId
        shortName
        fullName
        type
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
    }
  }
`;
