import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Dialog, Grid, Button } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import { toast } from 'react-toastify';
import { DELETE_FILE_IN_STOCK } from './StockMasterMutation';
import { useMutation } from 'react-apollo';

var CryptoJS = require('crypto-js')
let Emails = sessionStorage.getItem('be') || ''
var bytes = CryptoJS.AES.decrypt(Emails, 'kismet')
var originalText = bytes.toString(CryptoJS.enc.Utf8)
const DATABASE_IP = originalText || ''

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            {children}
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

export default function VideoAndImageViewModel({ modalData, modalClose, open, refetch }) {
    const [loader, setLoader] = useState(false)
    const [Image, setImage] = useState('')
    const [Video, setvideo] = useState('')
    const [isCertificate, setIsCertificate] = useState('')
    const [videoLink, setVideoLink] = useState('')
    const [deletefilefromStockMaster] = useMutation(DELETE_FILE_IN_STOCK)
    const [editable, setEditable] = useState(false);

    useEffect(() => {
        if (modalData.title === "Image" && modalData?.url?.length > 0) {
            setImage(modalData.url)
        }
        else if ((modalData.title === "isCertified" && modalData?.url?.length > 0)) {
            setIsCertificate(modalData.url)
        }
        else if ((modalData.title === "Video" && modalData?.url?.length > 0)) {
            setvideo(modalData.url)
        }
        else {
            setImage('')
            setvideo('')
            setIsCertificate('')
            setVideoLink('')
            setEditable('')
        }
    }, [modalData])
    
    useEffect(() => {
        if ((modalData?.V360Url && modalData?.V360Url?.length > 0)) {
            setVideoLink(modalData?.V360Url)
        }
    }, [modalData?.V360Url])

    const deleteImageAndVideo = (data, type) => {
        return new Promise((resolve, reject) => {

            if (window.confirm(`Are you sure you wan't to delete the ${data.title}!`)) {
                let newData = {
                    dataId: modalData.id,
                    isVideo: type === "Video" ? 'true' : 'false',
                    isCertificate: type === "isCertificate" ? 'true' : 'false'
                }
                setLoader(true)
                deletefilefromStockMaster({ variables: { input: newData } })
                    .then((data) => {
                        setLoader(false)
                        if (type === "Video") {
                            setvideo('')
                        } else {
                            setImage(``)
                        }
                        toast.success('successfully deleted')
                        modalClose()
                        refetch()
                    }).catch(error => {
                        setLoader(false)
                        console.log(error)
                    });
            }
        })
    }

    const Upload = (event, Name) => {
        setImage('')
        if (event?.file) {
            const { target: { validity, files: [file] } } = event;
            if (validity.valid) {
                let data = new FormData();
                data.append('file', file);
                data.append('fileName', file.name);
                data.append('dataId', modalData.id);
                data.append('isVideo', Name === "Video" ? 'true' : 'false');
                data.append('isCertificate', Name === "isCertificate");
                data.append('apiType', videoLink);
                setLoader(true)
                fetch(`https://${DATABASE_IP}/uploadFile`, {
                    method: 'POST',
                    body: data,
                })
                    .then(response => response.json())
                    .then(success => {
                        setLoader(false)
                        if (Name === "Video") {
                            setvideo(success?.data?.url)
                        } else {
                            setImage(success?.data?.url)
                        }
                        toast.success(success?.message)
                        modalClose()
                        refetch()

                    }).catch(error => {
                        setLoader(false)
                    });
            }
        } else {
            let data = new FormData();
            data.append('dataId', modalData.id);
            data.append('videoV360Url', videoLink)
            fetch(`https://${DATABASE_IP}/uploadFile`, {
                method: 'POST',
                body: data,
            })
                .then(response => response.json())
                .then(success => {
                    setLoader(false)
                    modalClose()
                    refetch()

                }).catch(error => {
                    setLoader(false)
                });
        }
    }
    let url = `https://drive.google.com/file/d/${Video}/preview`
    return (
        <div >
            <Dialog fullWidth={true} maxWidth={"sm"} onClose={() => modalClose()} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle style={{ padding: "0px 0px 2px 22px" }} onClose={() => modalClose()}>
               
                    {modalData.title === "Video" && !modalData?.V360Url && (
                        <div style={{ marginTop: "20px" }}>
                             <label htmlFor="">URI : </label>
                            <input
                                type="text"
                                style={{ padding: "9px", width: "60%" }}
                                value={videoLink}
                                onChange={(e) => setVideoLink(e.target.value)}
                            />
                            <Button
                                style={{
                                    background: "rgba(63, 81, 181)",
                                    color: "white",
                                    marginLeft: "9px",
                                }}
                                onClick={(e) => {
                                    if (editable) {
                                        Upload(e, "VideoUrl");
                                    } else {
                                        setEditable(true);
                                    }
                                }}
                            >
                                {!modalData?.V360Url ? 'Submit' : 'Edit'}
                            </Button>
                        </div>
                    )}
                    {modalData?.V360Url && (
                        <div style={{ marginTop: "10px" }}>
                             <label htmlFor="">URI : </label>
                            <a href={modalData?.V360Url} target="_blank">
                                {modalData?.V360Url}
                            </a>
                            {editable && (
                                <div style={{marginTop: "5px"}}>
                                     <label htmlFor="">URI : </label>
                                     <input
                                        type="text"
                                        style={{ padding: "9px", width: "60%" }}
                                        value={videoLink}
                                        onChange={(e) => setVideoLink(e.target.value)}
                                    />
                                    <Button
                                        style={{
                                            background: "rgba(63, 81, 181)",
                                            color: "white",
                                            marginLeft: "9px",
                                        }}
                                        onClick={(e) => Upload(e, "VideoUrl")}

                                    >
                                        SUBMIT
                                    </Button>
                                   
                                </div>
                            )}
                            {!editable && (
                                <Button
                                    style={{
                                        background: "rgba(63, 81, 181)",
                                        color: "white",
                                        marginLeft: "9px",
                                    }}
                                    onClick={() => setEditable(true)}
                                >
                                    Edit
                                </Button>
                            )}
                        </div>
                    )}



                    <Grid container >
                        {modalData.title === "Image" ?
                            <Grid xs={2} container item justify="space-between">
                                {Image?.length === 0 ?
                                    <IconButton
                                        variant="contained"
                                        component="label"
                                    >
                                        <AddCircleOutlineIcon style={{ color: "#3f51b5" }} />
                                        <input
                                            type="file"
                                            name="file"
                                            style={{ display: "none" }}
                                            onChange={(e) => Upload(e, "Image")}
                                        />
                                    </IconButton>
                                    : <>
                                        <IconButton
                                            variant="contained"
                                            component="label"
                                        >
                                            <DeleteIcon style={{ color: "red" }} onClick={() => deleteImageAndVideo(modalData, "Image")} />
                                        </IconButton>

                                    </>
                                }
                            </Grid>
                            :
                            modalData.title === "isCertified" ?
                                <Grid xs={2} container item justify="space-between">
                                    {isCertificate?.length === 0 ?
                                        <IconButton
                                            variant="contained"
                                            component="label"
                                        >
                                            <AddCircleOutlineIcon style={{ color: "#3f51b5" }} />
                                            <input
                                                type="file"
                                                name="file"
                                                style={{ display: "none" }}
                                                onChange={(e) => Upload(e, "isCertificate")}
                                            />
                                        </IconButton>
                                        : <>
                                            <IconButton
                                                variant="contained"
                                                component="label"
                                            >
                                                <DeleteIcon style={{ color: "red" }} onClick={() => deleteImageAndVideo(modalData, "isCertificate")} />
                                            </IconButton>

                                        </>
                                    }
                                </Grid>
                                :
                                <Grid xs={2} container item justify="space-between">
                                    {Video?.length === 0 ?
                                        <IconButton
                                            variant="contained"
                                            component="label"
                                        >
                                            <AddCircleOutlineIcon style={{ color: "#3f51b5" }} />
                                            <input
                                                type="file"
                                                name='file'
                                                style={{ display: "none" }}
                                                onChange={(e) => Upload(e, "Video")}
                                            />
                                        </IconButton>
                                        : <IconButton
                                            variant="contained"
                                            component="label"
                                        >
                                            <DeleteIcon style={{ color: "red" }} onClick={() => deleteImageAndVideo(modalData, "Video")} />
                                        </IconButton>}
                                </Grid>
                        }
                    </Grid>
                </DialogTitle>
                <DialogContent style={{ padding: "10px 10px 10px 10px ", width: "100%" }}>
                    {
                        loader ? 'loading ......' :
                            modalData.title === "Video" ?
                                Video?.length !== 0 ? <>
                                    <div style={{ width: "100%", textAlign: "right" }}>
                                        <input type="text" value={url} id="myInput" style={{ display: "none" }} />
                                        <Button
                                            onClick={() => {
                                                var copyText = document.getElementById("myInput");
                                                copyText.select();
                                                copyText.setSelectionRange(0, 99999);
                                                navigator.clipboard.writeText(copyText.value);
                                                toast.success('Copied text successfully')
                                            }} style={{ background: "rgba(63, 81, 181)", color: "white", marginBottom: 30, marginRight: 15 }} variant="contained"> Copy Link </Button>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <iframe width="560" title="video" height="400" src={url} frameborder="0" allowfullscreen></iframe>
                                    </div>
                                </> : "Please Add Video"
                                : Image?.length !== 0 ?
                                    <img alt="imgess" src={`https://drive.google.com/uc?export=view&id=${Image}`} style={{ height: 400, width: "100%" }} />
                                    : isCertificate?.length !== 0 ?
                                        <img alt="imgess" src={`https://drive.google.com/uc?export=view&id=${isCertificate}`} style={{ height: 400, width: "100%" }} /> : "Please Add Image"
                    }
                </DialogContent>
            </Dialog>
        </div >
    );
}