import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: 151,
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    playIcon: {
        height: 38,
        width: 38,
    },
    MuiChip_Root: {
        height: "25px",
        padding: "4px 0px",
        fontSize: "85%",
        backgroundColor: "#8CB836",
        color: "rgb(255, 255, 255)",
        border: "none",
        cursor: "default",
        display: "inline-flex",
        outline: 0,
        boxSizing: "border-box",
        transition: "background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        alignItems: "center",
        fontFamily: "Nunito,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol",
        whiteSpace: " nowrap",
        borderRadius: "16px",
        verticalAlign: "middle",
        justifyContent: "center",
        textDecoration: "none",
    },
    MuiChip_label: {
        overflow: "hidden",
        whiteSpace: "nowrap",
        paddingLeft: "12px",
        paddingRight: "12px",
        textOverflow: "ellipsis",
    },
    todateLogo: {
        margin: "0px !important"
    },
    main3div: {
        margin: "0px 0px 20px !important"
    },
    kiGFCc_Span: {
        color: "rgb(76, 175, 80)",
        paddingRight: "10px",
        fontWeight: "700"
    },
    maindiv: {
        height: window.innerHeight - 100,
        background: "rgb(249, 249, 252)",
        margin: "0px !important"
    },
    loader: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: window.innerHeight - 120,
        pointerEvents: 'none',
        opacity: 0.5
      }
}));
