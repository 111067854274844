import gql from 'graphql-tag';

export const DEPARTMENT_SUBSCRIBE = gql`
  subscription DepartmentChange {
    DepartmentChange {
      keyType
      data{
        id
        deptCode
        deptName
        deptType
        catagory
        defaultProcess{
          id
          procName
        }
        isActive
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
    }
  }
`;
