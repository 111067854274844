
export const RoleTypes = { "ADMIN": 'ADMIN', "LOCAL USER": 'LOCAL USER' };
export const InvoiceTypes = { "PERFOMA": 'PERFOMA', "TAX": 'TAX', "CASH": 'CASH' };
export const DetailOf = { "PARTY": 'PARTY', "COMPANY": 'COMPANY' };
export const PartyType = { "BROKER": 'BROKER', "CASH": 'CASH', "COSTING": 'COSTING', "EXPENSE": 'EXPENSE', "LAB": 'LAB', "MFG": 'MFG', "PURCHASE": 'PURCHASE', "SALES": 'SALES', "SALESMAN": 'SALESMAN', "BANK": 'BANK' };
export const BalanceType = { "CREDIT": 'CREDIT', "DEBIT": 'DEBIT' };
export const CashOrCareditType = { "CASH": 'CASH', "CREDIT": 'CREDIT' };
export const DepartmentType = { "CLV": 'CLV', "GALAXY": 'GALAXY', "LASER": 'LASER', "PLANNING": 'PLANNING' };
export const DepartmentCatagory = { "CLV": 'CLV', "MFG": 'MFG' }
export const BillType = { "RETAIL": 'RETAIL', "TAX": 'TAX' }
export const InwardType = { "PURCHASE": 'PURCHASE', "UNCERTIFIED PURCHASE": 'UNCERTIFIED PURCHASE', "OPENING": 'OPENING', "SERVICE INWARD": 'SERVICE INWARD' }
export const ShortType = { "COLOR": 'COLOR', "CUT": 'CUT', "POLISH": 'POLISH', "PURITY": 'PURITY', "SHAPE": 'SHAPE', "SYMMENTRY": 'SYMMENTRY', "FLOURSENCE": 'FLOURSENCE', "CROWN OPEN": 'CROWN OPEN', "SIDE WHITE": 'SIDE WHITE', "SIDE BLACK": 'SIDE BLACK', "EYE CLEAN": 'EYE CLEAN', "H & A": 'H & A', "TINT": 'TINT', "PAVILLION OPEN": 'PAVILLION OPEN', "TABLE OPEN": 'TABLE OPEN', "CENTER WHITE": 'CENTER WHITE', "CENTER BLACK": 'CENTER BLACK', "MILKY": 'MILKY', "FLS": 'FLS', "TENSION": 'TENSION', 'LOCATION': 'LOCATION' }
export const PacketEntryType = { "ROUGH": 'ROUGH', "TENDER": 'TENDER', "MAJURI": 'MAJURI' };
export const PayMode = { "CHEQUE": "CHEQUE", "CASH": "CASH" }
export const PayOff = { "JOB WORK": "JOB WORK", "SALES": "SALES" }
export const Currency = { "$ TO $": "$ TO $", "$ TO ZAR": "$ TO ZAR", "ZAR TO $": "ZAR TO $", "ZAR TO ZAR": "ZAR TO ZAR" }
export const TransType = { "PAYMENT": "PAYMENT", "RECEIVE": "RECEIVE" }
export const PaymentMasterDetailReference = { "AGAINST BILL": "AGAINST BILL" }
export const IssueReturnMasterType = { "LAB ISSUE": "LAB ISSUE", "LAB RETURN": "LAB RETURN", "APPRO ISSUE": "APPRO ISSUE", "APPRO RETURN": "APPRO RETURN", "REPAIR ISSUE": "REPAIR ISSUE", "REPAIR RETURN": "REPAIR RETURN" }
export const LabType = { "GIA": "GIA", "DIA": "DIA", "EGL": "EGL", "IDL": "IDL", "IGI": "IGI", "HRD VISHAL": "HRD VISHAL", "MAHENDRA LAB": "MAHENDRA LAB", "GIA HK": "GIA HK" }
export const SalesType = { "ROUGH": "ROUGH", "POLISH": "POLISH" }
export const packetTypeForDepartmentIssueReturn = {
  "ROUGH": "ROUGH",
  "KACHU": "KACHU",
  "FINAL POLISH": "FINAL POLISH",
  "MAKABLE": "MAKABLE",
  "POLISH": "POLISH",
}
export const packetTypeForBranchTransfer = {
  "FINAL POLISH": "FINAL POLISH",
  "EXTRA TOPS": "EXTRA TOPS",
  "FAILED": "FAILED",
  "KACHU": "KACHU",
}

export const adminUsersRole = [
  { value: 'admin', label: 'Admin' },
  { value: 'standard', label: 'Standard' },
  { value: 'special', label: 'Special' },
];

export const warehouseUsersRole = [
  { value: 'warehouse', label: 'Warehouse' },
  { value: 'contractor', label: 'Contractor' },
  { value: 'lastMile', label: 'Last miles' },
];

export const RateType = [
  { value: 'Order', label: 'Order' },
  { value: 'Order Item', label: 'Order Item' },
  { value: 'Shipment', label: 'Shipment' },
  { value: 'Default', label: 'Default' },
];

export const Percentage = [
  { value: '$', label: '$' },
  { value: '%', label: '%' },
];

export const PerItems = [
  { value: 'Per Pound', label: 'Per Pound' },
  { value: 'Per Package', label: 'Per Package' },
  { value: 'Of Order Value', label: 'Of Order Value' },
  { value: 'Per Order', label: 'Per Order' },
  { value: 'Per Phone', label: 'Per Phone' },
  { value: 'Per Laptop', label: 'Per Laptop' },
  { value: 'Per Cubic Feet', label: 'Per Cubic Feet' },
];

export const receivingServices = [
  { value: 'standard', label: 'Standard' },
  { value: 'verified', label: 'Verified' },
];

export const deliveryServices = [
  { value: 'DTD', label: 'DTD' },
  { value: 'pickup', label: 'Pickup' },
];

export const forms = ["/dashboard", "/company", "/partymaster", "/department", "/boxsize", "/process", "/terms", "/bankdetails", "/mailsetting", "/deptwiseprocess", "/rough", "/roughplanning",
  "/code", "/rapaport", "/inward", "/kapan", "/costing", "/grad", "/stock", "/sales", "/departmentisuuereturn", "/branchtransfer", "/payment",
  "/packet", "/jobpkt", "/packetdeasso", "/sizewisestock", "/recertifiedmaster", "/issuereturn", "/mfgkapanstock", "/kapanwisecosting", "/purchasesales",
  "/planningcomparision", "/jobwork", "/branchtransferreport", "/salesreport", "/labpending", "/appropending", "/salesoutstanding", "/livescreen", "/polishlivescreen",
  "/paymentdashboard", "/updatelocation", "/packethistory"]



export const CostType = {
  "ADAT EXPENSE": "OFFICE EXPENSE",
  "BOIL": "BOIL",
  "CERTIFICATE": "CERTIFICATE",
  "EXPENSE": "EXPENSE",
  "EXPORT CHARGE": "EXPORT CHARGE",
  "GALAXY": "GALAXY/HELIUM",
  "HELIUM": "5% LEVY",
  "IMPORT CHARGE": "IMPORT CHARGE",
  // "INDIA CERTIFICATE": "INDIA CERTIFICATE",
  "INTEREST": "INTEREST",
  "LASER": "LASER",
  "POLISH": "MFG COST",
  "POLISH EXPENSE": "POLISH EXPENSE",
  "RATE DIFFERENCE": "RATE DIFFERENCE",
  // "SA CERTIFICATE": "SA CERTIFICATE"
}
