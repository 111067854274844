import React from 'react'
import _ from 'lodash'
import { Grid, InputLabel, Table } from '@material-ui/core';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import * as XLSX from 'xlsx/xlsx.mjs';
import moment from 'moment';
import ExcelPng from '../../../../../Excel.png'
import { ExportCsv } from '../../../../components/ExportReport/ExportCsv'
import { AutoSizer, List, CellMeasurer, CellMeasurerCache } from 'react-virtualized';

import { Tofixed } from '../../../../components/Comman/Tofix'
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#8CB836",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 13,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const useStyles = makeStyles(() => ({
    denseTable: {
        '& td': { padding: "2px 5px", cursor: "pointer", textAlign: "center" },
        '& th': { padding: "5px 5px", cursor: "pointer", textAlign: "center" }
    },
    loader: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: window.innerHeight - 540,
        pointerEvents: 'none',
        opacity: 0.5,
        width: "80%",
        paddingRight: 10
    }
}))


const columns = [
    { title: 'Lot No', field: 'lotNoId' },
    { title: 'Packet No', field: 'pktNoId' },
    { title: 'Cts', field: 'cts' },
    { title: 'Color', field: 'colorId' },
    { title: 'Shape', field: 'shapeId' },
    { title: 'Purity', field: 'purityId' },
    { title: 'Cut', field: 'cutId' },
    { title: 'Polish', field: 'polishId' },
    { title: 'Symn', field: 'symmId' },
    { title: 'Flu', field: 'fluId' },
]

export const SelectedData = ({ Data, loading }) => {
    const classes = useStyles();

    const cache = React.useRef(
        new CellMeasurerCache({
            fixedWidth: true,
            defaultHeight: 100
        })
    )

    const TableHeader = () => {
        return <>
            <tr>
                <td colSpan={columns.length}>
                    <h2 style={{ textAlign: "center" }}>Department wise Report  </h2>
                </td>
            </tr>
            <tr >
                <td colSpan={columns.length - 2}>
                    < p style={{ margin: 0, fontSize: 18, fontWeight: "bold" }}>Tot Pkts: : {Data?.length || 0}</p>
                </td>
                <td colSpan={2} style={{ textAlign: "end" }}>
                    < p style={{ margin: 0, fontSize: 18, fontWeight: "bold" }}>Cts : {Tofixed(_.sumBy(Data, (d) => d.cts))}</p>
                </td>
            </tr>
        </>
    }

    function exportExcelFile(workbook) {
        return XLSX.writeFile(workbook, `DepartMentWiseReport${moment()}.xlsx`);
    }

    const exportCsvData = () => {
        var workbook = XLSX.utils.book_new();
        var worksheet_data = document.getElementById("table-to-xls-for-polish");
        var worksheet = XLSX.utils.table_to_sheet(worksheet_data);
        workbook.SheetNames.push("Test");
        workbook.Sheets["Test"] = worksheet;
        exportExcelFile(workbook);
    }

    const ListRef = React.useRef();
    if (loading) {
        return <Grid xs={9} item container className={classes.loader}  ><img src="https://i.gifer.com/4V0b.gif" alt="loading" /></Grid>
    } else
        return <>
            <Grid xs={9} item className="pr-10">
                <Grid container >
                    <Grid xs={6} item style={{ position: "relative" }}>
                        <img id="amits" src={ExcelPng} alt="alternative text" title="csv" onClick={() => exportCsvData()} />
                        <ExportCsv
                            tableid="table-to-xls-for-polish"
                            TableHeader={TableHeader()}
                            columns={columns}
                            AllData={
                                _.map(_.cloneDeep(Data ? Data : []), (d) => {
                                    d.lotNoId = d?.lotNoId?.lotNo || ''
                                    d.pktNoId = d?.pktNoId?.pktNo || ''
                                    d.colorId = d?.colorId?.shortName || ''
                                    d.shapeId = d?.shapeId?.shortName || ''
                                    d.purityId = d?.purityId?.shortName || ''
                                    d.cutId = d?.cutId?.shortName || ''
                                    d.polishId = d?.polishId?.shortName || ''
                                    d.symmId = d?.symmId?.shortName || ''
                                    d.fluId = d?.fluId?.shortName || ''
                                    return d
                                })
                            }
                        />
                    </Grid>
                    <Grid xs={6} item>
                        <InputLabel style={{ fontSize: 16, paddingBottom: 10, color: "rgb(70, 81, 142)", textAlign: "end" }}>&nbsp;{`Tot Pkts: ${Data?.length || 0} | Cts: ${Tofixed(_.sumBy(Data, (d) => d.cts))}`}</InputLabel>
                    </Grid>
                </Grid>

                <TableContainer component={Paper}>
                    <Table className={classes.denseTable}>
                        <TableHead>
                            <TableRow>
                                {columns.map((d) => {
                                    return <StyledTableCell style={{ width: '8.18%', textAlign: "center" }} >{d.title}</StyledTableCell>
                                })
                                    // <StyledTableCell style={{ width: '8.18%', textAlign: "center" }} >{"Packet No "}</StyledTableCell>
                                    // <StyledTableCell style={{ width: '8.18%', textAlign: "center" }} >{"Cts"}</StyledTableCell>
                                    // <StyledTableCell style={{ width: '8.18%', textAlign: "center" }} >{"Color"}</StyledTableCell>
                                    // <StyledTableCell style={{ width: '8.18%', textAlign: "center" }} >{"Shape"}</StyledTableCell>
                                    // <StyledTableCell style={{ width: '8.18%', textAlign: "center" }} >{"Purity"}</StyledTableCell>
                                    // <StyledTableCell style={{ width: '8.18%', textAlign: "center" }} >{"Cut"}</StyledTableCell>
                                    // <StyledTableCell style={{ width: '8.18%', textAlign: "center" }} >{"Polish"}</StyledTableCell>
                                    // <StyledTableCell style={{ width: '8.18%', textAlign: "center" }} >{"Symm"}</StyledTableCell>
                                    // <StyledTableCell style={{ width: '8.18%', textAlign: "center" }} >{"Flu"}</StyledTableCell>
                                }
                            </TableRow>
                        </TableHead>
                    </Table>

                    <div style={{ width: "100%", height: 500, marginBottom: 10 }}>
                        <AutoSizer>
                            {({ width, height }) => {
                                return <List
                                    ref={ListRef}
                                    height={height}
                                    width={width}
                                    rowHeight={20}
                                    deferredMeasurementCache={cache.current}
                                    rowCount={Data?.length || 0}
                                    rowRenderer={({ key, index, style, parent }) => {
                                        const row = Data[index]
                                        return <CellMeasurer key={key} cache={cache.current} parent={parent} columnIndex={0} rowIndex={index}>
                                            <div style={style}>
                                                <div className="tbody"  >
                                                    <Table aria-label="customized table" className={classes.denseTable} style={{ padding: 10 }}>
                                                        <TableBody  >
                                                            <StyledTableRow key={key} >
                                                                <StyledTableCell style={{ width: '8.18%', textAlign: "center" }}>{row?.lotNoId?.lotNo || "-"}</StyledTableCell>
                                                                <StyledTableCell style={{ width: '8.18%', textAlign: "center" }}>{row?.pktNoId?.pktNo || "-"}</StyledTableCell>
                                                                <StyledTableCell style={{ width: '8.18%', textAlign: "center" }}>{Tofixed(row?.cts || 0)}</StyledTableCell>
                                                                <StyledTableCell style={{ width: '8.18%', textAlign: "center" }}>{row?.colorId?.shortName || "-"}</StyledTableCell>
                                                                <StyledTableCell style={{ width: '8.18%', textAlign: "center" }}>{row?.shapeId?.shortName || "-"}</StyledTableCell>
                                                                <StyledTableCell style={{ width: '8.18%', textAlign: "center" }}>{(row?.purityId?.shortName || "-")}</StyledTableCell>
                                                                <StyledTableCell style={{ width: '8.18%', textAlign: "center" }}>{(row?.cutId?.shortName || "-")}</StyledTableCell>
                                                                <StyledTableCell style={{ width: '8.18%', textAlign: "center" }}>{(row?.polishId?.shortName || "-")}</StyledTableCell>
                                                                <StyledTableCell style={{ width: '8.18%', textAlign: "center" }}>{(row?.symmId?.shortName || "-")}</StyledTableCell>
                                                                <StyledTableCell style={{ width: '8.18%', textAlign: "center" }}>{(row?.fluId?.shortName || "-")}</StyledTableCell>
                                                            </StyledTableRow>
                                                        </TableBody>
                                                    </Table>
                                                </div>
                                            </div>
                                        </CellMeasurer>
                                    }}
                                />
                            }}
                        </AutoSizer>
                    </div>
                </TableContainer>

            </Grid>
        </>
}