import gql from 'graphql-tag';

const BACKUP = gql`
    query getBackups($page: Int, $limit: Int, $filter:String, $sort:backupSort){
      getBackups(page:$page,limit:$limit,filter:$filter,sort:$sort){
        count
        data {
          id
          name
          url
          isActive
          createdAt
          updatedAt
      }
    }
  }

`;


export default BACKUP

