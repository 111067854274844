import React, { useState } from 'react'
import MaterialTable from 'material-table'
// import { DEPARTMENTISSUERETURNDETAIL } from './Query'
// import { useQuery, useMutation } from '@apollo/react-hooks'
import _ from 'lodash'
import { Button } from '@material-ui/core';
import SubPacketModal from '../../../components/Modals/SubPacket'
import ToTableSubPkt from './ToTableSubPkt'
import { Tofixed } from '../../../components/Comman/Tofix'
const columns = [
  {
    title: 'Lot No',
    field: 'lotNoId',
    render: (rowData) => rowData?.lotNoId?.lotNo || "-",
    cellStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    headerStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    customFilterAndSearch: (data, rowData) => {
      let regex = new RegExp(data, 'i')
      return (rowData?.lotNoId?.lotNo || "").match(regex)
    },
  },
  {
    title: 'Pkt No',
    field: 'pktNoId',
    customFilterAndSearch: (data, rowData) => {
      let regex = new RegExp(data, 'i')
      return (rowData?.pktNoId?.pktNo || "").match(regex)
    },
    cellStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    headerStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    render: (rowData) => rowData?.pktNoId?.pktNo || 0
  },
  {
    title: 'Pkt Type',
    field: 'pktType',
    cellStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    headerStyle: {
      minWidth: 150,
      textAlign: "center"
    },
  },
  {
    title: 'Org. cts',
    field: 'orgCts',
    cellStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    headerStyle: {
      minWidth: 150,
      textAlign: "center"
    },
  },
  {
    title: 'Iss. Cts',
    field: 'issueCts',
    cellStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    headerStyle: {
      minWidth: 150,
      textAlign: "center"
    },
  },
  {
    title: 'Ret Cts',
    field: 'retCts',
    cellStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    headerStyle: {
      minWidth: 150,
      textAlign: "center"
    },
  },
  {
    title: 'Sub Pkt',
    field: 'subPkt',
    editComponent: ({ rowData }) => rowData?.subPkt || null,
    cellStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    headerStyle: {
      minWidth: 150,
      textAlign: "center"
    }
  },
  {
    title: 'Loss Cts',
    field: 'lossCts',
    cellStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    headerStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    render: (rowData) => Tofixed(rowData?.lossCts || 0.00),
  },
  {
    title: 'Color',
    field: 'colorId',
    cellStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    headerStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    customFilterAndSearch: (data, rowData) => {
      let regex = new RegExp(data, 'i')
      return (rowData?.colorId?.shortName || "").match(regex)
    },
    render: (rowData) => rowData?.colorId?.shortName || "-"
  },
  {
    title: 'Shape',
    field: 'shapeId',
    cellStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    headerStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    customFilterAndSearch: (data, rowData) => {
      let regex = new RegExp(data, 'i')
      return (rowData?.shapeId?.shortName || "").match(regex)
    },
    render: (rowData) => rowData?.shapeId?.shortName || "-"
  },
  {
    title: 'Purity',
    field: 'purityId',
    cellStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    headerStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    customFilterAndSearch: (data, rowData) => {
      let regex = new RegExp(data, 'i')
      return (rowData?.purityId?.shortName || "").match(regex)
    },
    render: (rowData) => rowData?.purityId?.shortName || "-"
  },
  {
    title: 'Exp. Cts',
    field: 'expectedCts',
    cellStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    headerStyle: {
      minWidth: 150,
      textAlign: "center"
    },
  },
  {
    title: 'Exp.Pol Amt',
    field: 'expPolAmt',
    cellStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    headerStyle: {
      minWidth: 150,
      textAlign: "center"
    },
  },
]
export const Totable = (props) => {
  const [open, setOpen] = useState(false);
  //   const tableRef = useRef();
  //   const [Result, setResult] = useState([])
  const [SubPKt, setSubPKt] = useState([])

  const modalOpen = (d, rowData) => {
    setSubPKt(rowData)
    setOpen(!open);
  }

  columns.map((d, key) => {
    if (d.field === "subPkt") {
      d.render = (rowData) => {
        return <Button key={key} variant="contained" color="primary" className="sub-packet" onClick={(d) => modalOpen(d, rowData)}>
          {`subPkt (${rowData?.subPkt?.length || 0})`}
        </Button>
      }
    }
    return null
  })

  return <>
    <SubPacketModal modalOpen={modalOpen} modalClose={modalOpen} open={open} Title="Sub Packets">
      <ToTableSubPkt SubPKt={SubPKt} />
    </SubPacketModal>
    <MaterialTable
      title={`${props?.ToDepartments?.deptName || ''} Stock`}
      isLoading={props.loading}
      columns={columns}
      data={props?.Totabledatas || []}
      options={{
        emptyRowsWhenPaging: false,
        padding: 'dense',
        pageSize: 20,
        pageSizeOptions: [20, 50, 100, 150, 200, 250, 300, 400, 500],
        selection: true,
        search: false,
        paging: true,
        sorting: false,
        filtering: true,
        minBodyHeight: window.innerHeight - 500,
        maxBodyHeight: window.innerHeight - 500,
        headerStyle: {
          backgroundColor: '#8CB836',
          color: '#f5f5f5'
        }
      }}
      onSelectionChange={(rows) => {
        let Ids = _.map(rows, 'id')
        props.SetToDepartmentDatas(Ids)
      }}
    />
  </>

}