import React, { useState, useEffect, useRef } from 'react'
import { FormControl, Grid, Typography, Button, TextField } from '@material-ui/core';
import { withRouter } from "react-router-dom";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DateFnsUtils from "@date-io/date-fns";
import { useQuery } from '@apollo/react-hooks'
import MaterialTable from 'material-table';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import moment from 'moment'
import { GETLABPENDINGREPORT } from './LabPendingQuery';
import _ from 'lodash'
import { LotNo } from '../../../components/DropdownSelect/LotNo';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CompanyAndPartyAllData } from '../../../components/DropdownSelect/SelectComponents/companyAndPartySelectComponent';
import './lab.css'
import { Tofixed } from '../../../components/Comman/Tofix';
import { useStyles, Accordion, AccordionSummary, AccordionDetails } from '../../../components/Comman/Accordian'
import GetAppIcon from '@material-ui/icons/GetApp';
import { CommponExport } from '../../../components/ExportReport/CommponExport'
import { ExportCsv } from '../../../components/ExportReport/ExportCsv'
import { PrintDiv } from '../../../components/Comman/exportPdfAndExcel'
import * as XLSX from 'xlsx/xlsx.mjs';
import ExcelPng from '../../../../Excel.png'
const columns = [
  {
    title: 'Date', field: 'date', render: (rowData) => {
      if (rowData.date === "Grand Total") return rowData.date
      else return moment(rowData.date).format('DD/MM/YYYY')
    }
  },
  { title: 'Lab Name', field: 'partyId.partyName' },
  { title: 'Lot No', field: 'lotNoId.lotNo' },
  {
    title: 'Packet No', field: 'pktNo',
    render: (rowData) => {
      if (rowData.date === "Grand Total") {
        return rowData?.pktNo || ''
      }
      return rowData?.pktNoId?.pktNo || ''
    }
  },
  {
    title: 'Cts',
    field: 'cts',
    render: (rowData) => Tofixed(rowData?.cts || 0)
  },
]

function LabPending() {

  const classes = useStyles();
  const tableRef = useRef();
  const [AllData, setAllData] = useState([]);
  const [selectedFromDate, setSelectedFromDate] = useState(moment().subtract(7, "days"));
  const [selectedToDate, setSelectedToDate] = useState(new Date());
  const [filter, setFilter] = useState({})
  const [partyName, setPartyName] = useState('');
  const [lotNo, setLotNo] = useState('');
  const [Data, setData] = useState('');

  const { refetch, data, loading } = useQuery(GETLABPENDINGREPORT, {
    variables: { filter: JSON.stringify(filter) },
    fetchPolicy: 'cache-and-network',
  })

  //   useEffect(() => {
  //     tableRef.current && tableRef.current.onQueryChange()
  //   }, [data]);
  useEffect(() => {
    let result = data?.getLabPendingReport || []
    let GetGrandTotalData = result.filter(d => d.date === "Grand Total")
    if (result.length !== 0 && GetGrandTotalData.length === 0) {
      let LastRow = {
        date: "Grand Total",
        pktNo: result?.length || 0,
        cts: Tofixed(_.sum(_.map(result, 'cts')))
      }
      result.push(LastRow)
    }
    setData(result)
    let Data = _.cloneDeep(result)
    setAllData(Data)
  }, [data])


  let defaultProps = LotNo('', '', '').defaultProps
  let parties = CompanyAndPartyAllData("PARTY");

  useEffect(() => {
    let Data = JSON.parse(localStorage.getItem('LabPendingReport') || "{}")
    let newString = {}
    if (Data.lotNoId) {
      newString = { ...newString, "lotNoId": Data.lotNoId }
      setLotNo(_.find(defaultProps?.options || [], (d) => d.id === Data.lotNoId))
    }
    if (Data.partyId) {
      newString = { ...newString, "partyId": Data.partyId }
      setPartyName(_.find(parties || [], (d) => d.id === Data.partyId))
    }
    if (Data.fromDate) {
      newString = { ...newString, "fromDate": moment(Data.fromDate).format('YYYY-MM-DD') }
      setSelectedFromDate(moment(Data.fromDate))
    }
    if (Data.toDate) {
      newString = { ...newString, "toDate": moment(Data.toDate).format('YYYY-MM-DD') }
      setSelectedToDate(moment(Data.toDate))
    }
    setFilter(newString)
  }, [])

  const PreViewHandler = () => {
    let Data = {}

    if (lotNo) {
      Data = { ...Data, "lotNoId": lotNo.id }
    }
    if (partyName) {
      Data = { ...Data, "partyId": partyName.id }
    }
    if (selectedFromDate) {
      Data = { ...Data, "fromDate": moment(selectedFromDate).format('YYYY-MM-DD') }
    }
    if (selectedToDate) {
      Data = { ...Data, "toDate": moment(selectedToDate).format('YYYY-MM-DD') }
    }
    setFilter(Data)
    localStorage.setItem('LabPendingReport', JSON.stringify(Data))
  }

  const ClearViewHandler = () => {
    setLotNo('')
    setPartyName('')
    setSelectedFromDate(moment().subtract(7, "days"))
    setSelectedToDate(new Date())
    localStorage.removeItem('LabPendingReport')
    setAllData([])
    setFilter({}, () => {
      refetch()
    })
  }

  const pdfHeader = () => {
    return <div>
      <h2 style={{ textAlign: "center" }}>Lab Pending Report</h2>
      <p style={{ margin: 0, fontSize: 18, fontWeight: "bold" }}>Company Name : KISMET DIAMONDS</p>
      <p style={{ margin: 0, fontSize: 18, fontWeight: "bold" }}>{`Lab Name : ${partyName?.partyName || "All"} `}</p>
      <div style={{ display: "flex", width: "100%" }}>
        <div style={{ width: "50%" }}>
          < p style={{ margin: 0, fontSize: 18, fontWeight: "bold" }}> Lot No : {lotNo?.lotNo || "All"}</p>
        </div>
        <div>
          < p style={{ margin: 0, fontSize: 18, fontWeight: "bold" }}>{`Date : ${moment(selectedFromDate).format('DD-MM-YYYY')} To: ${moment(selectedToDate).format('DD-MM-YYYY')}`}</p>
        </div>
      </div >
    </div >
  }

  const TableHeader = () => {
    return <>
      <tr>
        <td colSpan={columns.length}>
          <h2 style={{ textAlign: "center" }}>Lab Pending Report</h2>
        </td>
      </tr>
      <tr >
        <td colSpan={columns.length - 2}>
          <p style={{ margin: 0, fontSize: 18, fontWeight: "bold" }}>Company Name : KISMET DIAMONDS</p>

        </td>
        <td colSpan={2} style={{ textAlign: "center" }}>
          <p style={{ margin: 0, fontSize: 18, fontWeight: "bold" }}>{`Lab Name : ${partyName?.partyName || "All"} `}</p>
        </td>
      </tr>
      <tr>
        <td colSpan={columns.length - 2}>
          < p style={{ margin: 0, fontSize: 18, fontWeight: "bold" }}> Lot No : {lotNo?.lotNo || "All"}</p>
        </td>
        <td colSpan={2} style={{ textAlign: "center" }}>
          < p style={{ margin: 0, fontSize: 18, fontWeight: "bold" }}>{`Date : ${moment(selectedFromDate).format('DD-MM-YYYY')} To: ${moment(selectedToDate).format('DD-MM-YYYY')}`}</p>
        </td>
      </tr>
    </>
  }

  function exportExcelFile(workbook) {
    return XLSX.writeFile(workbook, `LabPendingReport${moment()}.xlsx`);
  }

  const exportCsvData = () => {
    var workbook = XLSX.utils.book_new();
    var worksheet_data = document.getElementById("table-to-xls");
    var worksheet = XLSX.utils.table_to_sheet(worksheet_data);
    workbook.SheetNames.push("Test");
    workbook.Sheets["Test"] = worksheet;
    exportExcelFile(workbook);
  }


  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Filters</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid item container justify="space-around">
            <form className={"makeStyles-root-30"} noValidate autoComplete="off">

              <FormControl className={classes.formControl} style={{ paddingTop: 0 }}>
                <Autocomplete
                  value={lotNo}
                  {...defaultProps}
                  onChange={(e, newdata) => {
                    if (newdata) {
                      setLotNo(newdata)
                    }
                  }}
                  className="lotmarginCustom"
                  renderInput={(params) => <TextField label="Lot No"  {...params} margin="normal" />}
                />
              </FormControl>
              <FormControl className={classes.formControl} style={{ paddingTop: 0 }}>
                <Autocomplete
                  value={partyName}
                  options={parties}
                  getOptionLabel={(option) => option.partyName}
                  onChange={(e, newdata) => {
                    if (newdata) {
                      setPartyName(newdata)
                    }
                  }}
                  className="lotmarginCustom"
                  renderInput={(params) => <TextField label="Party Name"  {...params} margin="normal" />}
                />
              </FormControl>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-from"
                  label="From Date"
                  format="dd/MM/yyyy"
                  value={selectedFromDate}
                  onChange={e => setSelectedFromDate(e)}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                  style={{ marginTop: 0 }}
                />&nbsp;&nbsp;&nbsp;&nbsp;
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-to"
                  label="To Date"
                  format="dd/MM/yyyy"
                  value={selectedToDate}
                  onChange={e => setSelectedToDate(e)}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                  style={{ marginTop: 0 }}
                />
              </MuiPickersUtilsProvider>
              <Button
                onClick={() => PreViewHandler()}
                variant="contained"
                color="primary"
                style={{ height: 34, marginLeft: 10, marginTop: 12 }}>
                Preview
              </Button>
              <Button
                onClick={() => ClearViewHandler()}
                variant="contained"
                color="primary"
                style={{ height: 34, marginLeft: 10, marginTop: 12 }}>
                clear
              </Button>
              {/* <Button
                variant="contained"
                color="primary"
                style={{ height: 34, marginLeft: 10, marginTop: 12 }}>
                Print
                 </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ height: 34, marginLeft: 10, marginTop: 12 }}>
                Export
               </Button>
          */}
            </form>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Grid container>
        <Grid item xs={12} style={{ position: "relative" }}>
          <img className="download-table-xls-button" src={ExcelPng} alt="alternative text" title="csv" onClick={() => exportCsvData()} />

          <ExportCsv
            TableHeader={TableHeader()}
            columns={columns}
            AllData={AllData.filter(d => {
              d["partyId.partyName"] = d?.partyId?.partyName
              d["lotNoId.lotNo"] = d?.lotNoId?.lotNo
              d["pktNoId.pktNo"] = d?.pktNoId?.pktNo
              return d
            })}
          />
          <MaterialTable
            title="Lab Pending Report"
            columns={columns}
            isLoading={loading}
            tableRef={tableRef}
            data={Data}
            actions={[
              {
                icon: 'print',
                tooltip: 'PRINT',
                isFreeAction: true,
                onClick: (event) => PrintDiv()
              },
              // {
              //   icon: GetAppIcon,
              //   tooltip: 'EXPORT',
              //   isFreeAction: true,
              //   onClick: (e) => exportTableToExcel("ExportTable", `${"Lab_Pending_Report"}_${moment()}`, e)
              // }
            ]}
            options={{
              emptyRowsWhenPaging: false,
              padding: 'dense',
              addRowPosition: 'first',
              filtering: true,
              sorting: true,
              paging: false,
              search: false,
              filterCellStyle: { padding: '5px', },
              maxBodyHeight: window.innerHeight - 215,
              rowStyle: rowData => ({
                backgroundColor: (rowData.date === "Grand Total") ? 'rgb(212, 218, 255)' : '#f5f5f5',
                fontWeight: (rowData.date === "Grand Total" || rowData.currency === "Grand Total") && 'bolder'
              }),
              headerStyle: { backgroundColor: 'whitesmoke' }
            }}
          />
        </Grid>
      </Grid>
      {/* ==================================printDiv Start========================================= */}

      <CommponExport
        columns={columns}
        pdfHeader={pdfHeader()}
        AllData={AllData.filter(d => {
          d["partyId.partyName"] = d?.partyId?.partyName
          d["lotNoId.lotNo"] = d?.lotNoId?.lotNo
          d["pktNoId.pktNo"] = d?.pktNoId?.pktNo
          return d
        })}
      />
      {/* ==================================printDiv End========================================= */}
    </div>
  );
}
export default withRouter(LabPending)