import gql from 'graphql-tag';

export const ADD_ROUGHMASTER = gql`
mutation addNewRough($input: roughMasterInput!, $log: LogInput){
  addNewRough(input:$input,log: $log){
    id
    billNo
    date
    brokerId
    dueDays
    dueDate
    brokerPer
    brokerAmount
    exportExpense
    netCost
    purType
    totalCts
    totalCost
    totalZarAmt
    totalPcs
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}
`;
export const ADD_ROUGHDETAIL = gql`
mutation addNewRoughDetail($input: roughDetailInput!, $log: LogInput){
  addNewRoughDetail(input:$input,log: $log){
    id
    roughMasterId
    srNo
    cts
    rate
    roughAmountDollar
    rateZar
    amountZar
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}
`;

export const UPDATE_ROUGHMASTER = gql`
mutation updateRough($input: roughMasterUpdate!, $log: LogInput){
  updateRough(input:$input,log: $log){
    id
    billNo
    date
    brokerId
    dueDays
    dueDate
    brokerPer
    brokerAmount
    exportExpense
    netCost
    purType
    totalCts
    totalCost
    totalZarAmt
    totalPcs
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}
`;
export const UPDATE_ROUGHDETAIL = gql`
mutation updateRoughDetail($input: roughDetailUpdate!, $log: LogInput){
  updateRoughDetail(input:$input,log: $log){
    id
    roughMasterId
    srNo
    cts
    rate
    roughAmountDollar
    rateZar
    amountZar
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}
`;

export const DELETE_ROUGHMASTER = gql`
  mutation deleteRough($id: ID!, $log: LogInput){
    deleteRough(id:$id,log: $log)
  }
`;

export const DELETE_ROUGHDETAIL = gql`
  mutation deleteRoughDetail($id: ID!, $log: LogInput){
    deleteRoughDetail(id:$id,log: $log)
  }
`;
