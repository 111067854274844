import gql from 'graphql-tag';

export const ADD_PACKETMASTER = gql`
    mutation  addNewPacket($input: packetMasterInput!, $log: LogInput){
      addNewPacket(input:$input, log: $log){
        id
        transNo
        transDate
        lotNoId
        totalPcs
        totalCts
        totalCost
        partyId
        tenderId
        Remark
        entryType
        pktPartyName
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
    }
`;
export const UPDATE_PACKETMASTER = gql`
    mutation updatePacket($input: packetMasterUpdate!){
      updatePacket(input:$input){
        id
        transNo
        transDate
        lotNoId
        totalPcs
        totalCts
        totalCost
        partyId
        tenderId
        Remark
        entryType
        pktPartyName
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
    }
`;

export const DELETE_PACKETMASTER = gql`
mutation deletePacket($id: ID!, $log: LogInput){
  deletePacket(id:$id, log: $log)
}
`;
export const DELETE_PACKETDFETAIL = gql`
mutation  deletePacketDetail($id: ID!, $log: LogInput){
  deletePacketDetail(id:$id, log: $log)
}
`;
export const ADD_PACKETDETAIL = gql`
    mutation addNewPacketDetail($input: packetDetailInput!, $log: LogInput){
      addNewPacketDetail(input:$input, log: $log){
        id
        packetMasterId
        srNo
        pktNo
        pcs
        cts
        status
        subPktMasterId
        isSubPkt
        colorId
        shapeId
        bcCode
        flsId
        isPlanning
        tensionId
        majuriRate
        zarRate
        purityId
        expcts
        subPkt
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
    }
`;
export const UPDATE_PACKETDETAIL = gql`
    mutation  updatePacketDetail($input: packetDetailUpdate!, $log: LogInput){
      updatePacketDetail(input:$input, log: $log){
        id
        packetMasterId
        srNo
        pktNo
        pcs
        cts
        status
        subPktMasterId
        isSubPkt
        colorId
        shapeId
        bcCode
        flsId
        isPlanning
        tensionId
        majuriRate
        zarRate
        purityId
        expcts
        subPkt
        updatedBy
        updatedAt
      }
    }
`;

