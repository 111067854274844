import React, { Component } from "react";
import withSession from './ui/components/Session/withSession';
import AppRouter from './router';
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render(){
    return (
      <AppRouter/>
    )
  }
}

export default withSession(App)

