import gql from 'graphql-tag';

export const PACKETDEASSOCIATION = gql`
query getSalesStockForPacketDeassociation($page:Int,$limit:Int ,$filter:String ,$sort:stockMasterSort ){
  getSalesStockForPacketDeassociation(page:$page,limit:$limit,filter:$filter,sort:$sort){
    count
  data {
    id
    lotNoId{
      id
      lotNo
    }
    pktNoId{
      id
      pktNo
    }
    rfId
  }
}
}
`;