import React from 'react'
import { withRouter } from "react-router-dom";
import MaterialTable from 'material-table';
import { Tofixed } from "../../../../components/Comman/Tofix";

const columns = [
  {
    title: 'Department Name', field: 'departmentId',
    render: (rowData) => {
      let salesDetail = rowData?.salesDetail || null
      let issuePartyId = rowData?.salesStock?.status || null
      let departmentId = ["ROUGH", "MFG"].includes(rowData?.roughStock?.status) ? rowData?.roughStock?.departmentId?.deptName || null : rowData?.roughStock?.status
      return rowData.lotNo === "Grand Total" ? "" : salesDetail.type !== null ? "Sold" : issuePartyId !== null ? issuePartyId : departmentId !== null ? departmentId : ""
    },
    cellStyle: {
      minWidth: 300
    },
    headerStyle: {
      backgroundColor: '#00c9',
    }
  },
  {
    title: 'Process', field: 'gradDate',
    cellStyle: {
      minWidth: 300
    },
    render: (rowData) => {
      let salesDetail = rowData?.salesDetail || null
      let status = rowData?.salesStock?.issuePartyId?.partyName || null
      let roughStockProcess = rowData?.roughStock?.process?.procName || null
      return rowData.lotNo === "Grand Total" ? "" : salesDetail.type !== null ? "Sold" : status !== null ? status : roughStockProcess !== null ? roughStockProcess : ""
    },
    headerStyle: {
      backgroundColor: '#00c9',
    }
  },
  {
    title: 'Rough Cost($)', field: 'roughCostDollar',
    render: (rowData) => Tofixed(rowData?.roughCostDollar || 0),
    headerStyle: {
      backgroundColor: '#00c9',
    }
  },
  {
    title: 'Zar Rate', field: 'zarRate',
    render: (rowData) => Tofixed(rowData?.zarRate || 0),
    headerStyle: {
      backgroundColor: '#00c9',
    }
  },
  {
    title: 'Rough Cost', field: 'roughCost',
    render: (rowData) => Tofixed(rowData?.roughCost || 0),
    headerStyle: {
      backgroundColor: '#00c9',
    }
  },
  {
    title: 'Laser Cost', field: 'laserCost',
    render: (rowData) => Tofixed(rowData?.laserCost || 0),
    headerStyle: {
      backgroundColor: '#00c9',
    }
  },
  {
    title: 'Galexy/Helium Cost', field: 'galaxyCost',
    render: (rowData) => Tofixed(rowData?.galaxyCost || 0),
    headerStyle: {
      backgroundColor: '#00c9',
    }
  },
  // {
  //   title: 'Boil Cost', field: 'boilCost',
  //   render: (rowData) => Tofixed(rowData?.boilCost || 0),
  //   headerStyle: {
  //     backgroundColor: '#00c9',
  //   }
  // },
  {
    title: '5% LEVY', field: 'heliumCost',
    render: (rowData) => Tofixed(rowData?.heliumCost || 0),
    headerStyle: {
      backgroundColor: '#00c9',
    }
  },
  {
    title: 'MFG Cost', field: 'polishCost',
    render: (rowData) => Tofixed(rowData?.polishCost || 0),
    headerStyle: {
      backgroundColor: '#00c9',
    }
  },
  {
    title: 'Certi Cost', field: 'certiCost',
    render: (rowData) => Tofixed(rowData?.certiCost || 0),
    headerStyle: {
      backgroundColor: '#00c9',
    }
  },
  {
    title: 'Expense', field: 'expense',
    render: (rowData) => Tofixed(rowData?.expense || 0),
    headerStyle: {
      backgroundColor: '#00c9',
    }
  },
  {
    title: 'Export Charge', field: 'exportCharge',
    render: (rowData) => Tofixed(rowData?.exportCharge || 0),
    headerStyle: {
      backgroundColor: '#00c9',
    }
  },
  {
    title: 'Import Charge', field: 'importCharge',
    render: (rowData) => Tofixed(rowData?.importCharge || 0),
    headerStyle: {
      backgroundColor: '#00c9',
    }
  },
  {
    title: 'Interest', field: 'interest',
    render: (rowData) => Tofixed(rowData?.interest || 0),
    headerStyle: {
      backgroundColor: '#00c9',
    }
  },
  {
    title: 'Majuri', field: 'majuri',
    render: (rowData) => Tofixed(rowData?.majuri || 0),
    headerStyle: {
      backgroundColor: '#00c9',
    }
  },
  {
    title: 'Office Expense', field: 'adatExpense',
    render: (rowData) => Tofixed(rowData?.adatExpense || 0),
    headerStyle: {
      backgroundColor: '#00c9',
    }
  },
  // {
  //   title: 'Polish Expense', field: 'polishExpense',
  //   render: (rowData) => Tofixed(rowData?.polishExpense || 0),
  //   headerStyle: {
  //     backgroundColor: '#00c9',
  //   }
  // },
  {
    title: 'Rate Diffrence', field: 'rateDiff',
    render: (rowData) => Tofixed(rowData?.rateDiff || 0),

    headerStyle: {
      backgroundColor: '#00c9',
    }
  },
  {
    title: 'Total Cost', field: 'totalCost',
    render: (rowData) => Tofixed(rowData?.totalCost || 0),
    headerStyle: {
      backgroundColor: '#00c9',
    }
  },
]
function Cost(props) {
  return (
    <MaterialTable
      title="Cost(ZAR)"
      columns={columns}
      tableRef={props.tableRef}
      data={props.AllData}
      isLoading={props.loading}
      options={{
        emptyRowsWhenPaging: false,
        padding: 'dense',
        pageSizeOptions: [5, 10, 15, 20, 25, 50],
        addRowPosition: 'first',
        filtering: false,
        sorting: true,
        search: false,
        paging: false,
        filterCellStyle: { padding: '5px', },
        rowStyle: rowData => ({
          backgroundColor: (rowData.lotNo === 'Grand Total') ? 'rgb(212, 218, 255)' : '#f5f5f5',
          fontWeight: rowData.lotNo === "Grand Total" && 'bolder'

        })
      }}
    />
  );
}
export default withRouter(Cost)
