import { useQuery } from '@apollo/react-hooks'
import { ROUGHSTOCKPLANNINGDATA } from '../../pages/Transaction/RoughPlanning/RoughPlanningQuery';
export const RoughStockFromLotNoAndPacketNo = (lotNo, pktNo) => {
  const { data } = useQuery(ROUGHSTOCKPLANNINGDATA, {
    variables: { lotNoId: lotNo, pktNoId: pktNo },
    fetchPolicy: 'cache-and-network',
  })
  let Data = data?.getPlannigLotNoData || [];

  return Data
}

