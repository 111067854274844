import React, { useState, useRef, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks'
import MaterialTable from 'material-table';
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import { Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MAIL_SETTING from './MailSettingQuery';
import { ADD_MAILSETTING, UPDATE_MAILSETTING, DELETE_MAILSETTING } from './MailSettingMutation';
import { ValidateEmail } from '../../../components/Comman/validate.js'
import { MAILSETTING_SUBSCRIBE } from './MailSettingSubscribe'
import _ from 'lodash'
import { FormatError } from '../../../components/Comman/FormatError'

const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1, },
  noMargin: { margin: 0 }
}));

const columns = [
  {
    title: 'Department Name*', field: 'departmentName',
    validate: rowData => (rowData.departmentName && rowData.departmentName !== "") ? true : { isValid: false, helperText: `can't be empty` }
  },
  {
    title: 'EMail*', field: 'mailID',
    validate: rowData => {
      if (rowData.mailID && rowData.mailID !== "")
        return ValidateEmail(rowData.mailID) ? true : { isValid: false, helperText: `email is not valid` }
      else
        return { isValid: false, helperText: `can't be empty` }
    }
  },
  {
    title: 'Password*',
    field: 'password',
    filtering: false,
    sorting: false,
    render: (rowData) => {
      let data = []
      let l = (rowData.password).length;
      for (let index = 0; index < l; index++) {
        data.push('.')
      }
      return <label style={{ fontSize: 25 }}> {data.join("")}</label>
    },
    editComponent: (props) => <TextField id="standard-basic" onChange={(e) => {
      let Data = { ...props.rowData }
      Data.password = e.target.value
      props.onRowDataChange(Data)

    }} />
  },
  { title: 'Status', field: 'isActive', type: "boolean", initialEditValue: true, sorting: false },
]

function MailSetting(props) {

  const classes = useStyles();
  const tableRef = useRef();

  const [loader, setLoader] = useState(false);
  const [pageSize, setPageSize] = useState(10)
  const [selectedRow, setSelectedRow] = useState(null);

  const permissions = props?.session?.me?.role?.permissions;
  const role = props?.session?.me?.role?.role;
  const path = props?.location?.pathname;
  let permission = _.find(permissions, function (o) { return o.name === path; })

  const { fetchMore, subscribeToMore } = useQuery(MAIL_SETTING, {
    variables: { page: 1, limit: 10, filter: JSON.stringify({}), sort: { key: 'createdAt', type: -1 } },
    fetchPolicy: 'cache-and-network'
  })


  const [AddMailSettingMutation] = useMutation(ADD_MAILSETTING)
  const [updateMailSettingMutation] = useMutation(UPDATE_MAILSETTING)
  const [deleteMailSettingMutation] = useMutation(DELETE_MAILSETTING)

  useEffect(() => {
    if (subscribeToMore) {
      const unsubscribe = subscribeToMore({
        document: MAILSETTING_SUBSCRIBE,
        updateQuery: (previousResult, { subscriptionData }) => {
          if (!subscriptionData.data) {
            return previousResult;
          }
          tableRef.current && tableRef.current.onQueryChange()
          return previousResult;
        },
      });
      return () => unsubscribe();
    }
  }, [subscribeToMore]);


  const getMailSettingData = (query) => {

    setLoader(true)
    return new Promise(async (resolve, reject) => {
      let filter = {}, sort = { key: 'createdAt', type: -1 }
      query && query.filters.forEach(item => {
        if (item.column.field === "isActive") {
          if (item.value)
            filter = { ...filter, [item.column.field]: item.value === "checked" ? true : false }
          else {
            delete filter.isActive
            filter = { ...filter }
          }
        }
        else if (item.value.length === 0) {
          delete filter[`${item.column.field}`]
          filter = { ...filter }
        } else
          filter = { ...filter, [item.column.field]: item.value }
      });
      if (query && query.orderBy && query.orderDirection !== "") {
        sort = { key: query.orderBy.field, type: query.orderDirection === 'asc' ? 1 : -1 }
      }
      setPageSize(query.pageSize)
      try {
        let result = await fetchMore({
          variables: { page: query.page + 1, limit: query.pageSize, filter: JSON.stringify(filter), sort: sort },
          fetchPolicy: 'network-only',
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return fetchMoreResult;
          }
        })
        setLoader(false)
        resolve({
          data: result?.data?.getMailSetting?.data || [],
          page: query.page,
          totalCount: result?.data?.getMailSetting?.count || 0,
        })
      } catch (error) {
        console.log(FormatError(error))
        setLoader(false)
      }
    })
  }

  const AddMailSetting = (newData) => {
    return new Promise((resolve, reject) => {
      setLoader(true)
      if (newData.password) {
        let log = {
          action: 'INSERT',
          actionOn: 'mailSetting',
          actionName: 'addNewMailSetting',
          oldValue: "",
          message: `${newData?.departmentName || ''} mailSetting was created.`
        }
        AddMailSettingMutation({ variables: { input: newData, log } }).then((data) => {
          setLoader(false)
          toast.success('mail setting created successfully.');
          resolve()
        }).catch((e) => {
          setLoader(false)
          reject()
          toast.warn(FormatError(e))
        })
      } else {
        setLoader(false)
        toast.warn('please,fill all required(*) fields.')
        reject()
      }
    })
  }

  const UpdateMailSetting = (newData, oldData) => {
    return new Promise((resolve, reject) => {
      delete newData.createdBy
      delete newData.updatedBy
      delete newData.createdAt
      delete newData.updatedAt
      delete newData.__typename
      setLoader(true)
      if (newData.password) {
        let log = {
          action: 'UPDATE',
          actionOn: 'mailSetting',
          actionName: 'updateMailSetting',
          oldValue: JSON.stringify(oldData),
          message: `${oldData?.departmentName || ''} mailSetting was updated.`
        }
        updateMailSettingMutation({ variables: { input: newData, log } }).then((data) => {
          setLoader(false)
          toast.success('mail setting updated successfully.');
          resolve()
        }).catch((e) => {
          setLoader(false)
          reject()
          toast.warn(FormatError(e))
        })
      } else {
        setLoader(false)
        toast.warn('please,fill all required(*) fields.')
        reject()
      }
    })
  }
  const DeleteMailSetting = (oldData) => {
    return new Promise((resolve, reject) => {
      setLoader(true)
      let log = {
        action: 'DELETE',
        actionOn: 'mailSetting',
        actionName: 'deleteMailSetting',
        oldValue: JSON.stringify(oldData),
        message: `${oldData?.departmentName || ''} mailSetting was deleted.`
      }
      deleteMailSettingMutation({ variables: { id: oldData.id, log } }).then((data) => {
        toast.success('mail setting deleted successfully.');
        setLoader(false)
        resolve()
      }).catch((e) => {
        setLoader(false)
        reject()
        toast.warn(FormatError(e))
      })
    })
  }

  const addButton = (val) => {
    if (val || (role === 'admin'))
      return { onRowAdd: (newData) => AddMailSetting(newData) }
    else
      return {}
  }
  useEffect(() => {
    document.getElementsByClassName("parentTable")[0].firstElementChild.firstElementChild.firstChild.remove()
  }, [])

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <div className="parentTable">
            <MaterialTable
              title="Mail Setting "
              tableRef={tableRef}
              columns={columns}
              isLoading={loader}
              data={query => getMailSettingData(query)}
              icons={{ Add: props => { return (<div> <i className="fa fa-plus-square" ></i> Mail Setting </div>) } }}
              options={{
                emptyRowsWhenPaging: false,
                padding: 'dense',
                debounceInterval: 300,
                pageSize: pageSize,
                showTitle: false,
                pageSizeOptions: [5, 10, 15, 20, 25, 50],
                addRowPosition: 'first',
                filtering: true,
                sorting: true,
                search: false,
                filterCellStyle: { padding: '5px', },
                maxBodyHeight: window.innerHeight - 215,
                rowStyle: rowData => ({
                  backgroundColor: (selectedRow === rowData.tableData.id) ? 'rgb(212, 218, 255)' : '#f5f5f5'
                }),
                headerStyle: { backgroundColor: 'whitesmoke' }
              }}

              editable={{
                isEditHidden: (rowData) => (role === 'admin') ? false : !permission.isUpdate,
                isDeleteHidden: (rowData) => (role === 'admin') ? false : !permission.isDelete,
                ...addButton(permission?.isInsert),
                onRowUpdate: (newData, oldData) => UpdateMailSetting(newData, oldData),
                onRowDelete: (oldData) => DeleteMailSetting(oldData)
              }}
              onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}


export default withRouter(MailSetting)