import React, { useState, useEffect, useRef } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks'
import MaterialTable from 'material-table';
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import { Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BOXSIZE from './BoxSizeQuery';
import { ADD_BOXSIZE, UPDATE_BOXSIZE, DELETE_BOXSIZE } from './BoxSizeMutation';
import { BOXSIZE_SUBSCRIBE } from './BoxSizeSubscribe'
import _ from 'lodash'
const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1, },
  noMargin: { margin: 0 }
}));

const columns = [
  {
    title: 'Box No*', field: 'boxNo', type: "numeric",
    validate: rowData => {
      return (rowData.boxNo && rowData.boxNo !== undefined) ? true : { isValid: false, helperText: `can't be empty` }
    }
  },
  {
    title: 'From size*', field: 'fromSize', type: "numeric",
    editComponent: (props) => {
      //   let valueDefault = props?.rowData?.brokerPer || 0;
      return (
        <TextField
          value={props?.rowData?.fromSize}
          type="number"
          onChange={(e) => {
            let Data = { ...props.rowData };
            Data.fromSize = e.target.value;
            props.onRowDataChange(Data);
          }}
        />
      );
    },
    validate: rowData => {
      return (rowData.fromSize && rowData.fromSize !== undefined) ? true : { isValid: false, helperText: `can't be empty` }
    }
  },
  {
    title: 'To Size*', field: 'toSize', type: "numeric",
    editComponent: (props) => {
      //   let valueDefault = props?.rowData?.brokerPer || 0;
      return (
        <TextField
          value={props?.rowData?.toSize}
          type="number"
          onChange={(e) => {
            let Data = { ...props.rowData };
            Data.toSize = e.target.value;
            props.onRowDataChange(Data);
          }}
        />
      );
    },
    validate: rowData => {
      return (rowData.toSize && rowData.toSize !== undefined) ? true : { isValid: false, helperText: `can't be empty` }
    }
  },
  {
    title: 'Rfid*', field: 'rfId', type: "numeric",
    validate: rowData => {
      return (rowData.rfId && rowData.rfId !== undefined) ? true : { isValid: false, helperText: `can't be empty` }
    }
  },
]

function BoxSize(props) {
  const classes = useStyles();
  const tableRef = useRef();

  const [pageSize, setPageSize] = useState(10)
  const [selectedRow, setSelectedRow] = useState(null);
  const [loader, setLoader] = useState(false);

  const permissions = props?.session?.me?.role?.permissions;
  const role = props?.session?.me?.role?.role;
  const path = props?.location?.pathname;
  let permission = _.find(permissions, function (o) { return o.name === path; })

  const { fetchMore, subscribeToMore } = useQuery(BOXSIZE, {
    variables: { page: 1, limit: 10, filter: JSON.stringify({}), sort: { key: 'createdAt', type: -1 } },
    fetchPolicy: 'cache-and-network'
  })
  const [AddBoxSizeMutation] = useMutation(ADD_BOXSIZE)
  const [updateBoxSizeMutation] = useMutation(UPDATE_BOXSIZE)
  const [deleteBoxSizeMutation] = useMutation(DELETE_BOXSIZE)

  useEffect(() => {
    if (subscribeToMore) {
      const unsubscribe = subscribeToMore({
        document: BOXSIZE_SUBSCRIBE,
        updateQuery: (previousResult, { subscriptionData }) => {
          if (!subscriptionData.data) {
            return previousResult;
          }
          tableRef.current && tableRef.current.onQueryChange()
          return previousResult;
        },
      });
      return () => unsubscribe();
    }
  }, [subscribeToMore]);

  const getBoxSizeData = (query) => {
    setLoader(true)
    return new Promise(async (resolve, reject) => {
      let filter = {}, sort = { key: 'createdAt', type: -1 }
      query && query.filters.forEach(item => {
        if (item.value.length === 0) {
          delete filter[`${item.column.field}`]
          filter = { ...filter }
        } else
          filter = { ...filter, [item.column.field]: item.value }
      });
      if (query && query.orderBy && query.orderDirection !== "") {
        sort = { key: query.orderBy.field, type: query.orderDirection === 'asc' ? 1 : -1 }
      }
      setPageSize(query.pageSize)
      try {
        let result = await fetchMore({
          variables: { page: query.page + 1, limit: query.pageSize, filter: JSON.stringify(filter), sort: sort },
          fetchPolicy: 'network-only',
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return fetchMoreResult;
          }
        })
        setLoader(false)
        resolve({
          data: result?.data?.getBoxSize?.data || [],
          page: query.page,
          totalCount: result?.data?.getBoxSize?.count || 0,
        })
      } catch (error) {
        setLoader(false)
      }
    })
  }
  const AddBoxSize = (newData) => {
    return new Promise((resolve, reject) => {
      setLoader(true)
      newData.boxNo = parseInt(newData.boxNo)
      newData.fromSize = parseFloat(newData.fromSize)
      newData.toSize = parseFloat(newData.toSize)
      newData.rfId = newData.rfId.toString()
      if ((newData.rfId) && (newData.fromSize || newData.fromSize === 0) && (newData.toSize || newData.toSize === 0) && (newData.boxNo || newData.boxNo === 0)) {
        let log = {
          action: 'INSERT',
          actionOn: 'boxSize',
          actionName: 'addNewBox',
          oldValue: "",
          message: `${newData?.fromSize || ''}-${newData?.toSize || ''} boxSize was created.`
        }
        AddBoxSizeMutation({ variables: { input: newData, log } }).then((data) => {
          toast.success('box size created successfully.');
          setLoader(false)
          resolve()
        }).catch((e) => {
          setLoader(false)
          toast.warn("data duplication found,please try another.")
          reject()
        })
      } else {
        setLoader(false)
        toast.warn('please,fill all required(*) fields.')
        reject()
      }
    })
  }
  const UpdateBoxSize = (newData, oldData) => {
    newData.boxNo = parseInt(newData.boxNo)
    newData.fromSize = parseFloat(newData.fromSize)
    newData.toSize = parseFloat(newData.toSize)
    newData.rfId = newData.rfId.toString()
    delete newData.createdBy
    delete newData.updatedBy
    delete newData.createdAt
    delete newData.updatedAt
    return new Promise((resolve, reject) => {
      setLoader(true)
      delete newData.__typename
      if ((newData.rfId) && (newData.fromSize || newData.fromSize === 0) && (newData.toSize || newData.toSize === 0) && (newData.boxNo || newData.boxNo === 0)) {
        let log = {
          action: 'UPDATE',
          actionOn: 'boxSize',
          actionName: 'updateBox',
          oldValue: JSON.stringify(oldData),
          message: `${oldData?.fromSize || ''}-${oldData?.toSize || ''} boxSize was updated.`
        }
        updateBoxSizeMutation({ variables: { input: newData, log } }).then((data) => {
          toast.success('box size updated successfully.');
          setLoader(false)
          resolve()
        }).catch((e) => {
          setLoader(false)
          toast.warn("data duplication found,please try another.")
          reject()
        })
      } else {
        setLoader(false)
        toast.warn('please,fill all required(*) fields.')
        reject()
      }
    })
  }
  const DeleteBoxSize = (oldData) => {
    return new Promise((resolve, reject) => {
      setLoader(true)
      let log = {
        action: 'DELETE',
        actionOn: 'boxSize',
        actionName: 'deleteBox',
        oldValue: JSON.stringify(oldData),
        message: `${oldData?.fromSize || ''}-${oldData?.toSize || ''} boxSize was deleted.`
      }
      deleteBoxSizeMutation({ variables: { id: oldData.id, log } }).then((data) => {
        toast.success('box size deleted successfully.');
        setLoader(false)
        resolve()
      }).catch((e) => {
        setLoader(false)
        reject()
        toast.error('err -', e)
      })
    })
  }

  const addButton = (val) => {
    if (val || (role === 'admin'))
      return { onRowAdd: (newData) => AddBoxSize(newData) }
    else
      return {}
  }
  useEffect(() => {
    document.getElementsByClassName("parentTable")[0].firstElementChild.firstElementChild.firstChild.remove()
  }, [])
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <div className="parentTable">
            <MaterialTable
              title="Box Size"
              tableRef={tableRef}
              columns={columns}
              isLoading={loader}
              data={query => getBoxSizeData(query)}
              icons={{ Add: props => { return (<div> <i className="fa fa-plus-square" ></i> Box Size </div>) } }}
              options={{
                emptyRowsWhenPaging: false,
                debounceInterval: 300,
                padding: 'dense',
                showTitle: false,
                pageSize: pageSize,
                pageSizeOptions: [5, 10, 15, 20, 25, 50],
                addRowPosition: 'first',
                filtering: true,
                sorting: true,
                search: false,
                filterCellStyle: { padding: '5px', },
                maxBodyHeight: window.innerHeight - 215,
                rowStyle: rowData => ({
                  backgroundColor: (selectedRow === rowData.tableData.id) ? 'rgb(212, 218, 255)' : '#f5f5f5'
                }),
                headerStyle: { backgroundColor: 'whitesmoke' }
              }}
              editable={{
                isEditHidden: (rowData) => (role === 'admin') ? false : !permission.isUpdate,
                isDeleteHidden: (rowData) => (role === 'admin') ? false : !permission.isDelete,
                ...addButton(permission?.isInsert),
                onRowUpdate: (newData, oldData) => UpdateBoxSize(newData, oldData),
                onRowDelete: (oldData) => DeleteBoxSize(oldData)
              }}
              onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}


export default withRouter(BoxSize)










