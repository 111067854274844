import gql from 'graphql-tag';

const STOCKMASTER = gql`
query getStockMasterData($page: Int, $limit: Int, $filter: String, $sort: stockMasterSort){
  getStockMasterData(page:$page, limit:$limit, filter:$filter, sort:$sort){
    count
    data{
      id
        inwardDetailId
        lotNoId{
          id
          lotNo
        }
        pktNoId{
          id
          pktNo
        }
        status
        location
        rfId
        cts
        certificateId
        colorId {
          id
          shortName
        }
        polishId{
          id
          shortName
        }
        shapeId {
          id
          shortName
        }
        cutId{
          id
          shortName
        }
        purityId{
          id
          shortName
        }
        symmId{
          id
          shortName
        }
        fluId {
          id
          shortName
        }
        measurment
        depth
        table
        lab{
          id
          partyName
        }
        stkPer
        netRaprate
        netAmount
        videoUrl
        videoV360Url
        imageUrl
        isCertified
        brown
        gradPer
        rapRate
        rapAmount
        createdBy
        updatedBy
        createdAt
        updatedAt
    }
  }
}
`;

export default STOCKMASTER

