import React, { useState, useEffect, useRef } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks'
import MaterialTable, { MTableHeader } from 'material-table';
import { withRouter } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import { toast } from 'react-toastify'
import { PACKETDEASSOCIATION } from './PacketDeassociationQuery'
import { UPDATERFIDFORDEASSOCIATION } from './PacketDeassociationMutaion'
import { MuiPickersUtilsProvider, } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField, CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { LotNo } from '../../../components/DropdownSelect/LotNo';
import { PktNoFromLotNo } from '../../../components/DropdownSelect/PktNoFromLotNo';
import { FormatError } from '../../../components/Comman/FormatError';

const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1, },
  noMargin: { margin: 0 }
}));


const columns = [
  {
    title: 'Lot No',
    field: 'lotNo',
    render: (rowData) => rowData?.lotNoId?.lotNo
  },
  {
    title: 'Pkt No',
    field: 'packetNo',
    render: (rowData) => rowData?.pktNoId?.pktNo
  },
  { title: 'Rfid', field: 'rfId' },
]

function PacketDeassociation(props) {
  const classes = useStyles();
  const tableRef = useRef();

  const [filter, setFilter] = useState({})
  const [pageSize, setPageSize] = useState(25)
  const [page, setPage] = useState(0)
  const [selectedRow, setSelectedRow] = useState([]);
  const [sort, setSort] = useState({ key: 'createdAt', type: -1 });
  const [orderBy, setOrderBy] = useState(-1);
  const [orderDirection, setOrderDirection] = useState("");
  const [selectedData, setSelectedData] = useState("");
  const [lotNo, setLotNo] = useState(null);
  const [pktNo, setPktNo] = useState(null);
  const [Loader, SetLoader] = useState(false)

  const [UpdateRfidMaster] = useMutation(UPDATERFIDFORDEASSOCIATION)

  const { error, data, loading, refetch } = useQuery(PACKETDEASSOCIATION, {
    variables: { page: page + 1, limit: pageSize, filter: JSON.stringify(filter), sort: sort },
    fetchPolicy: 'cache-and-network'
  })

  const getPacketDeassociationData = () => {
    return new Promise((resolve, reject) => {
      if (error)
        reject(error)
      let result = data?.getSalesStockForPacketDeassociation?.data || []
      resolve({
        data: result,
        page: page,
        totalCount: data?.getSalesStockForPacketDeassociation?.count || 0,
      })
    })
  }

  useEffect(() => {
    tableRef.current && tableRef.current.onQueryChange()
  }, [data]);

  const getRecertyMasterDataV1 = () => {
    setFilter({ "lotNoId": lotNo, "pktNoId": pktNo })
  }

  let defaultProps = LotNo('', '', '').defaultProps
  let PktData = PktNoFromLotNo(lotNo ? lotNo : null)
  const defaultProps2 = {
    options: PktData,
    getOptionLabel: (option) => option.pktNo
  };
  const saveButtonHandler = () => {
    return new Promise((resolve, reject) => {
      let newData = {
        Ids: _.map(selectedData, 'id')
      }
      SetLoader(true)
      UpdateRfidMaster({ variables: { input: newData } }).then((data) => {
        SetLoader(false)
        toast.success('packet deassociation sucessfully');
        setSelectedData([])
        refetch()
        resolve()
      }).catch((e) => {
        SetLoader(false)
        reject()
        toast.error(FormatError(e))
      })
    })
  }
  const setSorting = (colId, type) => {
    if (!type) {
      setSort({ key: 'createdAt', type: -1 })
      setOrderBy(-1)
      setOrderDirection("")
    } else {
      setSort({ key: columns[colId].field, type: type === 'asc' ? 1 : -1 })
      setOrderBy(colId)
      setOrderDirection(type)
    }
  }

  return (
    <div className={classes.root}>
      <div className="row">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid xs={12} item container>
            <Grid xs={4} item>
              {selectedData.length !== 0 &&
                <Button type="submit" className={classes.form} variant="contained" disabled={Loader} onClick={e => saveButtonHandler(e)} color="primary" >
                  {Loader && <CircularProgress size={24} className={classes.buttonProgress} />} Save
                </Button>
              }
            </Grid>
            <Grid xs={5} item container>
              <Grid xs={4} item>
                <Autocomplete
                  {...defaultProps}
                  onChange={(e, newdata) => {
                    if (newdata) {
                      setLotNo(newdata.id)
                    }
                  }}
                  renderInput={(params) => <TextField label="Lot No"  {...params} margin="normal" />}
                />
              </Grid>

              <Grid xs={4} item style={{ marginLeft: 5 }}>
                <Autocomplete
                  {...defaultProps2}
                  onChange={(e, newdata) => {
                    if (newdata) {
                      setPktNo(newdata.id)
                    }

                  }}
                  renderInput={(params) => <TextField label="PKT No"  {...params} margin="normal" />}
                />
              </Grid>
              <Grid xs={3} item style={{ marginTop: 15 }}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ height: 34, marginLeft: 15, marginTop: 12 }} onClick={e => getRecertyMasterDataV1()}>
                  Find
                </Button>
              </Grid>
            </Grid>

          </Grid>
        </MuiPickersUtilsProvider>
      </div>

      <Grid container>
        <Grid item xs={12}>
          <MaterialTable
            title="Packet Deassociation Master"
            tableRef={tableRef}
            columns={columns}
            isLoading={loading}
            data={query => getPacketDeassociationData()}
            options={{
              emptyRowsWhenPaging: false,
              padding: 'dense',
              pageSize: pageSize,
              pageSizeOptions: [25, 50, 100, 200, 250, 350, 400, 500],
              addRowPosition: 'first',
              filtering: false,
              sorting: true,
              search: false,
              filterCellStyle: { padding: '5px', },
              maxBodyHeight: window.innerHeight - 405,
              selection: true,
              rowStyle: rowData => ({
                backgroundColor: (selectedRow === rowData.tableData.id) ? 'rgb(212, 218, 255)' : '#f5f5f5'
              }),
              headerStyle: { backgroundColor: 'whitesmoke' }
            }}
            onChangePage={(page) => setPage(page)}
            onChangeRowsPerPage={(perPage) => setPageSize(perPage)}
            onSelectionChange={(rows) => setSelectedData(rows)}
            onRowClick={(a, rowData) => setSelectedRow(rowData.tableData.id)}
            components={{
              Header: props => {
                return <MTableHeader
                  {...props}
                  orderBy={orderBy}
                  orderDirection={orderDirection}
                  onOrderChange={(orderBy, orderDirection) => {
                    props.onOrderChange(orderBy, orderDirection)
                    setSorting(orderBy, orderDirection)
                  }}
                />
              }
            }}

          />
        </Grid>
      </Grid>
    </div>
  );
}



export default withRouter(PacketDeassociation)
