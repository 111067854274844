import React, { useRef, useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { GETSALESDATAFORDASHBOARD } from './Query'
import MaterialTable from 'material-table';
import _ from 'lodash'
import moment from 'moment';

import { RemoveDecimmal, Tofixed } from '../../../components/Comman/Tofix';
import { Currency } from '../../../components/Constant/index'
import { CustomFilterComponent } from '../../../components/CustomFilterComponent/index'
import { CompanyAndPartyAllData } from '../../../components/DropdownSelect/SelectComponents/companyAndPartySelectComponent'

const columns = [
  {
    title: 'currency',
    field: 'currency',
    render: (rowData) => {
      return rowData?.currency === "Total" ? "Total" : rowData?.currency || "-"
    },
    lookup: Currency,
    cellStyle: {
      minWidth: 150
    }
  },
  {
    title: 'Bill No',
    field: 'billNo',
    cellStyle: {
      minWidth: 150
    }
  },
  {
    title: 'Bill Date',
    type: 'date',
    field: 'date',
    headerStyle: {
      minWidth: 150
    },
    render: (rowData) => {
      let Dates = rowData?.date || null
      if (Dates)
        return moment(Dates).format('DD-MM-YYYY')
      else return ""
    },
  },
  {
    title: 'Due Date',
    type: 'date',
    field: 'dueDate',
    headerStyle: {
      minWidth: 150
    },
    render: (rowData) => {
      let Dates = rowData?.dueDate || null
      if (Dates)
        return moment(Dates).format('DD-MM-YYYY')
      else return ""
    },
  },
  {
    title: 'Party Name',
    field: 'partyId',
    render: (rowData) => rowData?.partyId?.partyName || "-",
    cellStyle: {
      minWidth: 150
    }
  },
  {
    title: 'Total Amt',
    field: 'totalZarAmount',
    render: (rowData) => {
      if (rowData.currency === "$ TO ZAR" || rowData.currency === "ZAR TO ZAR")
        return RemoveDecimmal(rowData?.totalZarAmount || rowData?.totalAmount || 0)
      else
        return RemoveDecimmal(rowData?.totalAmount || 0)
    },
    cellStyle: {
      minWidth: 150
    }

  },
  {
    title: 'Remain Zar Amt',
    field: 'remainingAmt',
    headerStyle: {
      minWidth: 200
    },
    render: (rowData) => {
      if (rowData.totalZarAmount !== 0) {
        if (rowData.currency === "$ TO ZAR" || rowData.currency === "ZAR TO ZAR") {
          return RemoveDecimmal(rowData?.remainingAmt || 0)
        } else if (rowData.currency === "Total") {
          return RemoveDecimmal(rowData?.RemaingZarAmt || 0)
        }
        else {
          return 0
        }
      } else {
        return 0
      }
    }
  },
  {
    title: 'Remain $ Amt',
    field: 'remainingAmt',
    cellStyle: {
      minWidth: 150
    },
    render: (rowData) => {
      if (rowData.totalZarAmount === 0) {
        return RemoveDecimmal(rowData?.remainingAmt || 0)
      }
      else if (rowData.currency === "$ TO $" || rowData.currency === "ZAR TO $") {
        return RemoveDecimmal(rowData?.remainingAmt || 0)
      } else if (rowData.currency === "Total") {
        return RemoveDecimmal(rowData?.RemaingdollerAmt || 0)
      }
      else {
        return 0
      }
    }
  }
]

export const DayPayment = (props) => {
  const tableRef = useRef();
  const [pageSize, setPageSize] = useState(13)
  const [loader, setLoader] = useState(false);
  const { fetchMore } = useQuery(GETSALESDATAFORDASHBOARD, {
    variables: { page: 1, limit: 20, filter: JSON.stringify({ "paymentDashboard": true }), sort: { key: 'createdAt', type: -1 } },
    fetchPolicy: 'cache-and-network'
  })


  columns.map(d => {
    if (d.field === "partyId") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: CompanyAndPartyAllData("PARTY"), getOptionLabel: (option) => option.partyName, multiple: true }} />
      }
    }
    return null
  })
  const getDayPaymentData = (query) => {
    setLoader(true)
    return new Promise(async (resolve, reject) => {
      let filter1 = { "paymentDashboard": true }, sort = { key: 'createdAt', type: -1 }
      query && query.filters.forEach(item => {
        if (item.column.field === "date" || item.column.field === "dueDate") {
          if (item.value)
            filter1 = { ...filter1, [item.column.field]: moment(item.value).format('YYYY-MM-DD') }
          else {
            delete filter1[`${item.column.field}`]
            filter1 = { ...filter1 }
          }
        } else if (item.value.length === 0) {
          delete filter1[`${item.column.field}`]
          filter1 = { ...filter1 }
        } else {
          if (item.column.field === "invoiceNo") {
            item.value = parseInt(item.value)
          }
          if (item.column.field === "totalCts" || item.column.field === "totalCost" || item.column.field === "totalCostZar") {
            item.value = parseFloat(item.value)
          }
          filter1 = { ...filter1, [item.column.field]: item.value }
        }
      });
      if (query && query.orderBy && query.orderDirection !== "") {
        sort = { key: query.orderBy.field, type: query.orderDirection === 'asc' ? 1 : -1 }
      }
      setPageSize(query.pageSize)
      try {
        let result = await fetchMore({
          variables: { page: query.page + 1, limit: query.pageSize, filter: JSON.stringify(filter1), sort: sort },
          fetchPolicy: 'network-only',
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return fetchMoreResult;
          }
        })
        setLoader(false)
        let Result = result?.data?.getSalesMasterForDayPayment?.data || []
        let TotalsData = Result.filter(d => d.currency === "Total")
        if (Result.length !== 0 && TotalsData.length === 0) {
          let LastRow = {
            currency: "Total",
            totalAmount: Tofixed(_.sumBy(Result, (d) => {
              if (d.currency === "$ TO ZAR" || d.currency === "ZAR TO ZAR")
                return d?.totalZarAmount || d?.totalAmount || 0
              else
                return d?.totalAmount || 0
            })),
            RemaingZarAmt: Tofixed(_.sumBy(Result, (d) => {
              if (d.totalZarAmount !== 0) {
                if (d.currency === "$ TO ZAR" || d.currency === "ZAR TO ZAR") {
                  return d.remainingAmt
                }
              }
            })),
            RemaingdollerAmt: Tofixed(_.sumBy(Result, (d) => {
              if (d.totalZarAmount === 0) {
                return d.remainingAmt
              }
              if (d.currency === "$ TO $" || d.currency === "ZAR TO $") {
                return d.remainingAmt
              }
            })),
          }
          Result.push(LastRow)
        }
        resolve({
          data: Result,
          page: query.page,
          totalCount: result?.data?.getSalesMasterForDayPayment?.count || 0,
        })
      } catch (error) {
        setLoader(false)
      }
    })
  }


  return <div>
    <MaterialTable
      ref={tableRef}
      title={`Day Payment`}
      columns={columns}
      isLoading={loader}
      data={(query) => getDayPaymentData(query)}
      options={{
        emptyRowsWhenPaging: false,
        padding: 'dense',
        pageSize: pageSize + 1,
        debounceInterval: 300,
        search: false,
        paging: true,
        sorting: false,
        pageSizeOptions: [50, 100, 150, 200, 250, 500],
        filtering: true,
        minBodyHeight: window.innerHeight - 275,
        maxBodyHeight: window.innerHeight - 275,
        headerStyle: {
          backgroundColor: '#8CB836',
          color: '#f5f5f5'
        },
        rowStyle: rowData => ({
          backgroundColor: (rowData.currency === "Total") ? 'rgb(212, 218, 255)' : '#f5f5f5'
        })
      }}
    />
  </div>
}