import gql from 'graphql-tag';

export const JOBWORKPKTLTD = gql`
query getJobWorkPkt($page: Int, $limit: Int, $filter: String, $sort: jobWorkPktSort){
  getJobWorkPkt(page: $page, limit: $limit, filter: $filter, sort: $sort){
    count
      data {
        id
        date
        lotNo
        pktNo
        cts
        shapeId{
            id
            shortName
            fullName
            type
        }
        colorId{
            id
            shortName
            fullName
            type
        }
        purityId{
            id
            shortName
            fullName
            type
        }
        flourenceseId{
            id
            shortName
            fullName
            type
        }
        symmId{
            id
            shortName
            fullName
            type
        }
        cutId{
            id
            shortName
            fullName
            type
        }
        polishId{
            id
            shortName
            fullName
            type
        }
        createdBy{
            id
            userName
        }
        updatedBy
      }
    }
  }
`;