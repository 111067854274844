import gql from 'graphql-tag';

export const ADD_JOBPKTLTD = gql`
mutation addNewJobWorkPkt($input: jobWorkPktInput!) {
  addNewJobWorkPkt(input: $input) {
    id
    date
    lotNo
    pktNo
    cts
    shapeId
    colorId
    purityId
    polishId
    flourenceseId
    symmId
    cutId
    createdBy
    createdAt
    updatedAt
    updatedBy
    }
  }
`;


export const UPDATE_JOBPKTLTD = gql`
mutation updateJobWorkPkt($input:jobWorkPktUpdate!){
  updateJobWorkPkt(input:$input){
    id
    date
    lotNo
    pktNo
    cts
    shapeId
    colorId
    purityId
    polishId
    flourenceseId
    symmId
    cutId
    createdBy
    createdAt
    updatedAt
    updatedBy
    }
  } 
`;


export const DELETE_JOBPKTLTD = gql`
mutation  deleteJobWorkPkt($id: ID!){
  deleteJobWorkPkt(id:$id)
  }
`;