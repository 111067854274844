import React from 'react'
import { Grid } from '@material-ui/core';
import MaterialTable from 'material-table';
// import { makeStyles } from '@material-ui/core/styles';

// const useStyles = makeStyles((theme) => ({
//   button: {
//     margin: theme.spacing(1),
//   },
// }));
const columns = [
  {
    title: 'Lot No',
    field: 'lotNoId.lotNo'
  },
  {
    title: 'Pkt No',
    field: 'pktNo',
  },
  {
    title: 'Pkt Type',
    field: 'pktType',
  },
  {
    title: 'Org Cts *',
    field: 'SubPktCts',
    type: "numeric"
  },
  {
    title: 'iss Cts',
    field: 'SubPktCts',
  },
  {
    title: 'loss Cts',
    field: 'lossCts',
    render: (rowData) => "0"
  },
  {
    title: 'Color',
    field: 'colorId',
    render: (rowData) => "-"
  },
  {
    title: 'Shape',
    field: 'shapeId',
    render: (rowData) => "-"
  },
  {
    title: 'Purtity',
    field: 'purityId',
    render: (rowData) => "-"
  },
  {
    title: 'Exp. Cts',
    field: 'SubPktCts',
  },
  {
    title: 'Exp.pol Amt',
    field: 'flsId',
    render: (rowData) => "0"
  },
]

function ToTableSubPkt(props) {
  return (
    <>

      <Grid
        container
        direction="row"
        justify="flex-end" item xs={12} >
        <div className="SubPacketTable" >
          <MaterialTable
            columns={columns}
            data={props?.SubPKt?.subPkt || []}
            options={{
              emptyRowsWhenPaging: false,
              padding: 'dense',
              pageSize: 10,
              pageSizeOptions: [5, 10, 15, 20, 25, 50],
              addRowPosition: 'first',
              filtering: false,
              sorting: false,
              search: false,
              paging: false,
              filterCellStyle: { padding: '5px', },
              maxBodyHeight: window.innerHeight - 215,
              showTitle: false
            }}
          />
        </div>
      </Grid>
    </>
  )
}
export default ToTableSubPkt