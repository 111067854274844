import gql from 'graphql-tag';

export const ADD_MAILSETTING = gql`
    mutation addNewMailSetting($input: mailSettingInput!,$log: LogInput){
      addNewMailSetting(input:$input,log: $log){
        id
        transID
        departmentName
        mailID
        password
        isActive
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
    }
`;

export const UPDATE_MAILSETTING = gql`
    mutation updateMailSetting($input: mailSettingUpdate!,$log: LogInput){
      updateMailSetting(input:$input,log: $log){
        id
        transID
        departmentName
        mailID
        password
        isActive
        createdBy
          updatedBy
        createdAt
        updatedAt
      }
    }
`;

export const DELETE_MAILSETTING = gql`
    mutation deleteMailSetting($id: ID!,$log: LogInput){
      deleteMailSetting(id:$id,log: $log)
    }
`;

export const SEND_RESET_MAIL = gql`
    mutation sendForgotPassMailNotification($input: resetInputRequest!){
      sendForgotPassMailNotification(input:$input){
       data
      }
    }
`;