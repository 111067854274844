import gql from 'graphql-tag';

export const TERMS_SUBSCRIBE = gql`
  subscription TermChange {
    TermChange {
      keyType
      data{
        id
        TERMID
        termName
        Days
        alertDays
        isActive
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
    }
  }
`;
