import gql from 'graphql-tag';

export const UPDATELOCATION = gql`
query getIssueReturnDetailForUpdateLocation($page: Int, $limit: Int, $filter: String, $sort: issueReturnDetailSort){
    getIssueReturnDetailForUpdateLocation(page: $page, limit: $limit, filter: $filter, sort: $sort){
      count
      data{
        id
        cts
        location
        certificateNo
        stkDiscPer
        gradePer
        rateRap
        rapAmt
        measurment
        lotNoId{
          id
          lotNo
        }
        labId{
          id
          partyName
        }
        pktNoId{
            id
            pktNo
        }
        colorId{
          id
          shortName
        }
        shapeId{
          id
          shortName
        }
        purityId{
          id
          shortName
        }
        cutId{
          id
          shortName
        }
        polishId{
          id
          shortName
        }
        symmId{
          id
          shortName
        }
        fluId{
          id
          shortName
        }
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
    }
  }
`;
