import gql from 'graphql-tag';

const DEPTWISEPROCESS_SUBSCRIBE = gql`
  subscription DeptWiseProcessChange {
    DeptWiseProcessChange {
      keyType
      data{
        id
        fromDept{
          id
          deptName
        }
        toDept{
          id
          deptName
        }
        process{
          id
          procName
        }
        pktType
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
    }
  }
`;

export default DEPTWISEPROCESS_SUBSCRIBE