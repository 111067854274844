import { useQuery } from '@apollo/react-hooks'
import { GETROUGHSTOCKFORCOSTINGDETAIL } from '../../pages/Transaction/Costing/CostingQuery';
export const GetRoughStockForCosting = (lotNo, pktNo) => {
  const { data } = useQuery(GETROUGHSTOCKFORCOSTINGDETAIL, {
    variables: { lotNoId: lotNo, pktNoId: pktNo },
    fetchPolicy: 'cache-and-network',
  })
  let Data = data?.getRoughStockForCosting || []
  return Data
}

