import React, { Component } from 'react';

class AccessDenied extends Component {
  render() {
    return (
      <div>
        <div className="containermain" style={{ height: "500px" }}>
          <div className="message animated bounceInDown">
            <div className="rivet top-left"></div>
            <div className="rivet top-right"></div>
            <div className="rivet bottom-left"></div>
            <div className="rivet bottom-right"></div>
            <div className="message-inner">
              <h1 className="message-title">Access <br />Denied</h1>
              <a href='/dashboard'>
                <button className="btn btn-primary btn-text border-0 bg-searchBlue" >Back to Dashboard</button>
              </a>
            </div>
          </div>
        </div >
      </div >
    );
  }
}

export default AccessDenied;