import gql from 'graphql-tag';

export const GETALLROUGHSTOCK = gql`
query getAllRoughStock{
    getAllRoughStock{
    process{
      id
      procName
    }
    lotNoId{
      id
      lotNo
    }
    extraCtsTransferId
    orgCts
    pktType
    extraCts
    issueCts
    departmentId{
      id
      deptName
    }
    pktNoId{
      id
      pktNo
    }
    returnId{
      id
      jagadNo
    }
  }
}

`;
export const GETROUGHSTOCKFOREXTRATOPS = gql`
    query getExtraTops($pktType: String){
      getExtraTops(pktType:$pktType){
        lotNoId{
          lotNo
        }
        pktNoId{
          pktNo
        }
        cts
        roughStockId{
          orgPktType
        }
      }
    }
`;


export const GETDEPARTMENTWISEDATAFORLIVEECREEN = gql`
    query getDepartmentWiseDatForLiveScreen{
      getDepartmentWiseDatForLiveScreen{
        deptName{
          deptName
          id
        }
        totalPkt
        totalCts
      }
    }
`;

export const GETUNISSUEDATAFORLIVESCREEN = gql`
    query getUnissueDataforLiveScreen{
      getUnissueDataforLiveScreen{
        lotNoId{
          id
          lotNo      
        }
        pktNoId{
          id
          pktNo   
        }
      issueCts
      }
    }
`;

export const GETKACHUDATAFORLIVESCREEN = gql`
    query getKachuDataforLiveScreen{
      getKachuDataforLiveScreen{
      lotNoId{
        id
        lotNo
      }    
        totalPkt
        totalCts
      }
    }
`;
export const GETEXTRATOPSDATAFORLIVESCREEN = gql`
    query getExtraTopsDataforLiveScreen{
      getExtraTopsDataforLiveScreen{
            lotNoId{
              id
              lotNo
            }
        totalPkt
        totalCts
      }
    }
`;

export const GETFILTERDATAFORLIVESCREEN = gql`
    query getFilterDataforLiveScreen($filter:String){
      getFilterDataforLiveScreen(filter:$filter){
      process{
        id
        procName
      }
      lotNoId{
        id
        lotNo
      }
      cts
      extraCtsTransferId
      orgCts
      pktType
      extraCts
      issueCts
      pktNoId{
        id
        pktNo
      }
      returnId{
        id
        jagadNo
      }
    }
    }
`;

export const APPROISSUEFROMROUGHSTOCK = gql `
query getApproIssueRoughStock{
  getApproIssueRoughStock{
    id
    lotNoId{
      id
      lotNo
    }
    pktNoId{
      id
      pktNo
    }
   issuePartyId{
    id
    partyName
  }
    issueCts
  }
}
`