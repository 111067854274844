import gql from 'graphql-tag';

export const REPAPORT = gql`
query getRapaportUser($page: Int, $limit: Int, $filter: String, $sort: rapaportUserSort){
  getRapaportUser(page:$page, limit:$limit, filter:$filter, sort:$sort){
    count
    data{
      id
      rapaportUsername
      password
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
}
`;

export const PRICE_LIST = gql`
query getRapaportPriceList{
  getRapaportPriceList{
    shape
    low_size
    high_size
    color
    clarity
    caratprice
    date
  }
}
`;

