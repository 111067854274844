
import gql from 'graphql-tag';

export const INSERT_ROLE = gql`
  mutation insertRole( $input: roleInput!,$log: LogInput) {
    insertRole(input:$input,log: $log) {
      id
      role
      isActive
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_ROLE = gql`
  mutation updateRole( $input: roleInput!,$log: LogInput) {
    updateRole(input:$input,log: $log) {
      id
      role
      isActive
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_ROLE = gql`
  mutation deleteRole( $id: ID!,$log: LogInput) {
    deleteRole(id:$id,log: $log)
  }
`;
