import gql from 'graphql-tag';

const BOXSIZE = gql`
    query getBoxSize($page: Int, $limit: Int, $filter:String, $sort:boxSizeSort!){
      getBoxSize(page:$page, limit:$limit, filter:$filter, sort:$sort){
        count
        data{
          id
          boxNo
        fromSize
          toSize
          rfId
          createdBy
          updatedBy
          createdAt
          updatedAt
        }
      }
    }
`;

export default BOXSIZE

