import React, { useRef, useState } from 'react'
import MaterialTable from 'material-table';
import { makeStyles } from '@material-ui/core/styles';
import { FilledInput } from '@material-ui/core'
import _ from 'lodash'
import { toast } from 'react-toastify';
import { Tofixed } from '../../../components/Comman/Tofix'
import { Grid, Button } from '@material-ui/core';
import SubPacketModal from '../../../components/Modals/SubPacket'
import SubPacket from './SubPacket'

const columns = [
  {
    title: 'Lot No',
    field: 'lotNoId',
    cellStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    headerStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    customFilterAndSearch: (data, rowData) => {
      let regex = new RegExp(data, 'i')
      return (rowData?.lotNoId?.lotNo || "").match(regex)
    },
    render: (rowData) => rowData?.lotNoId?.lotNo || null,
    editComponent: ({ rowData }) => rowData?.lotNoId?.lotNo || null
  },
  {
    title: 'Pkt No',
    field: 'pktNo',
    cellStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    headerStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    render: (rowData) => rowData?.pktNoId?.pktNo || null,
    customFilterAndSearch: (data, rowData) => {
      let regex = new RegExp(data, 'i')
      return (rowData?.pktNoId?.pktNo || "").match(regex)
    },
    editComponent: ({ rowData }) => rowData?.pktNoId?.pktNo || null
  },
  {
    title: 'Process',
    field: 'process',
    cellStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    headerStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    customFilterAndSearch: (data, rowData) => {
      let regex = new RegExp(data, 'i')
      return (rowData?.process?.procName || "").match(regex)
    },
    render: (rowData) => rowData?.process?.procName || null,
    editComponent: ({ rowData }) => rowData?.process?.procName || null
  },
  {
    title: 'Pkt Type',
    field: 'pktType',
    cellStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    headerStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    customFilterAndSearch: (data, rowData) => {
      let regex = new RegExp(data, 'i')
      return (rowData?.pktType || "").match(regex)
    },
    editComponent: ({ rowData }) => rowData?.pktType || null
  },
  {
    title: 'Issue cts',
    field: 'issueCts',
    cellStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    headerStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    editComponent: ({ rowData }) => rowData?.issueCts || null
  },
  {
    title: 'Ret Cts',
    field: 'retCts',
    cellStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    headerStyle: {
      minWidth: 150,
      textAlign: "center"
    },
  },
  {
    title: 'Sub Pkt',
    field: 'subPkt',
    cellStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    headerStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    editComponent: ({ rowData }) => rowData?.subPkt || null
  },
  {
    title: 'Loss cts',
    field: 'lossCts',
    cellStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    headerStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    render: (rowData) => Tofixed(rowData?.lossCts || 0.00),
    editComponent: ({ rowData }) => Tofixed(rowData?.lossCts || 0.00)
  },
  {
    title: 'Extra Cts',
    field: 'extraCts',
    cellStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    headerStyle: {
      minWidth: 150,
      textAlign: "center"
    },
  },

  {
    title: 'Color',
    field: 'colorId',
    cellStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    headerStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    customFilterAndSearch: (data, rowData) => {
      let regex = new RegExp(data, 'i')
      return (rowData?.colorId?.shortName || "").match(regex)
    },
    render: (rowData) => rowData?.colorId?.shortName || "-",
    editComponent: ({ rowData }) => rowData?.colorId?.shortName || null
  },

  {
    title: 'Shape',
    field: 'shapeId',
    cellStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    headerStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    customFilterAndSearch: (data, rowData) => {
      let regex = new RegExp(data, 'i')
      return (rowData?.shapeId?.shortName || "").match(regex)
    },
    render: (rowData) => rowData?.shapeId?.shortName || "-",
    editComponent: ({ rowData }) => rowData?.shapeId?.shortName || null
  },

  {
    title: 'Purity',
    field: 'purityId',
    cellStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    headerStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    render: (rowData) => rowData?.purityId?.shortName || "-",
    customFilterAndSearch: (data, rowData) => {
      let regex = new RegExp(data, 'i')
      return (rowData?.purityId?.shortName || "").match(regex)
    },
    editComponent: ({ rowData }) => rowData?.purityId?.shortName || null
  },
  {
    title: 'Exp.Cts',
    field: 'expectedCts',
    cellStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    headerStyle: {
      minWidth: 150,
      textAlign: "center"
    },
  },

  {
    title: 'Exp.Pol Amt',
    field: 'expPolAmt',
    cellStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    headerStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    editComponent: ({ rowData }) => rowData?.expPolAmt?.shortName || null
  }
]

const useStyles = makeStyles((theme) => ({
  returnCtsInput: {
    "& input": {
      padding: 0
    }
  }
}));

export const Fromtable = (props) => {
  const tableRef = useRef();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [SubPacketMasterData, setSubPacketMasterData] = useState([]);

  const modalOpen = (d, rowData) => {
    if (rowData) {
      if (rowData.retCts === 0) {
        toast.warn('please enter Return Cts')
      } else {
        setSubPacketMasterData(rowData)
        setOpen(!open);
      }
    }
  }
  const modalClose = () => {
    setOpen(!open);
  }
  const setReturnCts = (e, rowData) => {
    return new Promise((resolve, reject) => {
      let Data = rowData
      Data.retCts = parseFloat(e.target.value) || 0
      if (Data.retCts === 0) {
        Data.lossCts = 0
      } else
        Data.lossCts = Tofixed(Data.issueCts - Data.retCts)

      if (Data.retCts === 0 || parseFloat(e.target.value) <= parseFloat(Data.issueCts)) {
        let updated = _.map(props.FromTableData, (d) => {
          if (d.id === Data.id)
            return Data
          else
            return d
        })
        props.SetFromTableData(updated)
        resolve()
      } else {
        toast.warn('enter valid cts.')
      }
    });
  }
  const setExtraCts = (e, rowData) => {
    return new Promise((resolve, reject) => {
      let Data = rowData
      Data.extraCts = parseFloat(e.target.value) || 0.00
      Data.lossCts = Tofixed((Data.issueCts - Data.retCts) - Data.extraCts)
      let updated = _.map(props.FromTableData, (d) => {
        if (d.id === Data.id)
          return Data
        else
          return d
      })
      props.SetFromTableData(updated)
    });
  }
  const expectedCts = (e, rowData) => {
    return new Promise((resolve, reject) => {
      let Data = rowData

      Data.expectedCts = parseInt(e.target.value)
      let updated = _.map(props.FromTableData, (d) => {
        if (d.id === Data.id)
          return Data
        else
          return d
      })
      props.SetFromTableData(updated)
    });
  }
  columns.map((d, key) => {
    const { FromDepartments, ToDepartments } = props
    if (d.field === "retCts") {
      d.render = (rowData) => {
        return <FilledInput key={key} type="number" style={{ width: 70, background: "transparent" }} className={classes.returnCtsInput} value={rowData?.retCts || 0} onChange={(e) => setReturnCts(e, rowData)} />
      }
    }
    if (d.field === "extraCts") {
      d.render = (rowData) => {
        return <FilledInput key={key} type="number" value={rowData?.extraCts || 0} style={{ width: 70, background: "transparent" }} className={classes.returnCtsInput} onChange={(e) => setExtraCts(e, rowData)} />
      }
    }
    if (d.field === "expectedCts") {
      d.render = (rowData) => {
        return <FilledInput key={key} type="number" value={rowData?.expectedCts || 0} style={{ width: 70, background: "transparent" }} className={classes.returnCtsInput} onChange={(e) => expectedCts(e, rowData)} />
      }

    }

    if (d.field === "subPkt") {
      d.render = (rowData) => {
        if ((FromDepartments.deptName === "LASER" && ToDepartments.deptName === "PLANNING") || (FromDepartments.deptName === "WATERJET" && ToDepartments.deptName === "PLANNING") || (FromDepartments.deptName === "PLANNING" && ToDepartments.deptName === "LASER")) {
          return <Button key={key} variant="contained" color="primary" style={{ background: '#8CB836' }} className="sub-packet"
            onClick={(d) => modalOpen(d, rowData)}>
            subPkt
          </Button>
        } else {
          return <Button key={key} variant="contained" color="primary" className="sub-packet" disabled>
            subPkt
          </Button>
        }
      }
    }
    return null
  })

  return <Grid>
    <SubPacketModal modalOpen={modalOpen} modalClose={modalClose} open={open} Title="Sub Packet Master">
      <SubPacket SubPacketMasterData={SubPacketMasterData} SetFromTableData={props.SetFromTableData} FromTableData={props.FromTableData} />
    </SubPacketModal>

    <MaterialTable
      ref={tableRef}
      title={`${props?.FromDepartments?.deptName || ''} Stock`}
      columns={columns}
      isLoading={props.loading}
      data={props.FromTableData}
      options={{
        emptyRowsWhenPaging: false,
        padding: 'dense',
        selection: true,
        pageSize: 20,
        pageSizeOptions: [20, 50, 100, 150, 200, 250, 300, 400, 500],
        search: false,
        paging: true,
        sorting: false,
        filtering: "true",
        minBodyHeight: window.innerHeight - 500,
        maxBodyHeight: window.innerHeight - 500,
        headerStyle: {
          backgroundColor: '#8CB836',
          color: '#f5f5f5'
        },
      }}
      onSelectionChange={(rows) => {
        props.SetFromDepartmentDatas(rows)
      }}
    />
  </Grid>
}