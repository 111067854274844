import gql from 'graphql-tag';

export const GETFILTERROUGHSTOCK = gql`
query getFilterRoughStock($filter: String!){
  getFilterRoughStock(filter:$filter){
    count
    data{
      id
      lotNoId{
        id
        lotNo
      }
      orgCts
      issueCts
      departmentId{
        id
        deptName
      }
      transferId
      pktNoId{
        id
        pktNo
        isPlanning
        isSubPkt
      }
      process{
        id
        procName
      }
      pktType
      extraCts
    }
      }
    }
`;