export const ValidateEmail = (mail) => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
    return true
  else
    return false
}
export const ValidateMobile = (phoneNo) => {
  if ((phoneNo.toString().length >= 10 && phoneNo.toString().length <= 13) && !isNaN(phoneNo)) {
    return true
  }
  else
    return false
}
export const ValidateStringWithNumber = (value) => {
  return !isNaN(value)
}