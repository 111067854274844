import React, { useState, useRef, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks'
import MaterialTable from 'material-table';
import _ from 'lodash';
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import DEPARTMENT from './DepartmentQuery';
import { ADD_DEPARTMENT, UPDATE_DEPARTMENT, DELETE_DEPARTMENT } from './DepartmentMutation';
import { DEPARTMENT_SUBSCRIBE } from './DepartmentSubscribe'
import { DepartmentType, DepartmentCatagory } from '../../../components/Constant'
import { ProcessSelectComponent, ProcessAllData } from '../../../components/DropdownSelect/SelectComponents/ProcesSelectComponent';
import { FormatError } from '../../../components/Comman/FormatError'
import { CustomFilterComponent } from '../../../components/CustomFilterComponent/index'


const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1, },
  noMargin: { margin: 0 }
}));

const columns = [
  {
    title: 'Department Name*',
    field: 'deptName',
    validate: rowData => (rowData.deptName && rowData.deptName !== "") ? true : { isValid: false, helperText: `can't be empty` }
  },
  { title: 'Department Type*', field: 'deptType', initialEditValue: "CLV", lookup: DepartmentType },
  { title: 'Category*', field: 'catagory', initialEditValue: "CLV", lookup: DepartmentCatagory },
  {
    title: 'Default Process*', field: 'defaultProcess',
    render: (rowData) => rowData?.defaultProcess?.procName || "NO ANY PROCESS",
    editComponent: props => {
      let valueDefault = props?.rowData?.defaultProcess?.id || null
      return <ProcessSelectComponent field="defaultProcess" valueDefault={valueDefault} propsData={props} />
    },
  },
  { title: 'Status*', field: 'isActive', type: "boolean", initialEditValue: true, },
]

function DepartmentMaster(props) {
  const classes = useStyles();

  const tableRef = useRef();
  const [loader, setLoader] = useState(false);
  const [pageSize, setPageSize] = useState(10)
  const [selectedRow, setSelectedRow] = useState(null);
  const permissions = props?.session?.me?.role?.permissions;
  const role = props?.session?.me?.role?.role;
  const path = props?.location?.pathname;
  let permission = _.find(permissions, function (o) { return o.name === path; })

  const { fetchMore, subscribeToMore } = useQuery(DEPARTMENT, {
    variables: { page: 1, limit: 10, filter: JSON.stringify({}), sort: { key: 'createdAt', type: -1 } },
    fetchPolicy: 'cache-and-network'
  })

  columns.map(d => {
    if (d.field === "defaultProcess") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: ProcessAllData(), getOptionLabel: (option) => option.procName, multiple: true }} />
      }
    }
    return null
  })
  const [AddDepartmentMutation] = useMutation(ADD_DEPARTMENT)
  const [updateDepartmentMutation] = useMutation(UPDATE_DEPARTMENT)
  const [deleteDepartmentMutation] = useMutation(DELETE_DEPARTMENT)

  const getDepartmentData = (query) => {
    setLoader(true)
    return new Promise(async (resolve, reject) => {
      let filter = {}, sort = { key: 'createdAt', type: -1 }
      query && query.filters.forEach(item => {
        if (item.column.field === "isActive") {
          if (item.value)
            filter = { ...filter, [item.column.field]: item.value === "checked" ? true : false }
          else {
            delete filter.isActive
            filter = { ...filter }
          }
        }
        else if (item.value.length === 0) {
          delete filter[`${item.column.field}`]
          filter = { ...filter }
        } else
          filter = { ...filter, [item.column.field]: item.value }
      });
      if (query && query.orderBy && query.orderDirection !== "") {
        sort = { key: query.orderBy.field, type: query.orderDirection === 'asc' ? 1 : -1 }
      }
      setPageSize(query.pageSize)
      try {
        let result = await fetchMore({
          variables: { page: query.page + 1, limit: query.pageSize, filter: JSON.stringify(filter), sort: sort },
          fetchPolicy: 'network-only',
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return fetchMoreResult;
          }
        })
        setLoader(false)
        resolve({
          data: result?.data?.getDepartmentMaster?.data || [],
          page: query.page,
          totalCount: result?.data?.getDepartmentMaster?.count || 0,
        })
      } catch (error) {
        console.log(FormatError(error))
        setLoader(false)
      }


      //   let result = data?.getProcessMaster?.data || []
      //   resolve({
      //     data: result,
      //     page: page,
      //     totalCount: data?.getProcessMaster?.count || 0,
      //   })
    })
  }

  const AddDepartment = (newData) => {
    return new Promise((resolve, reject) => {
      setLoader(true)
      if (newData.deptName && newData.deptType && newData.catagory && newData.defaultProcess) {
        let log = {
          action: 'INSERT',
          actionOn: 'departmentMaster',
          actionName: 'addNewDepartment',
          oldValue: "",
          message: `${newData?.deptName || ''} department was created.`
        }
        AddDepartmentMutation({ variables: { input: newData, log } }).then((data) => {
          toast.success('department created successfully.');
          setLoader(false)
          resolve()
        }).catch((e) => {
          reject()
          setLoader(false)
          toast.error(FormatError(e))
        })
      } else {
        setLoader(false)
        toast.warn('please, fill all required(*) fields.')
        reject()
      }
    })
  }
  const UpdateDepartment = (newData, oldData) => {
    delete newData.__typename
    delete newData.createdBy
    delete newData.updatedBy
    delete newData.createdAt
    delete newData.updatedAt
    setLoader(true)
    return new Promise((resolve, reject) => {
      newData.defaultProcess = newData?.defaultProcess?.id || newData?.defaultProcess || null
      if (newData.deptName && newData.deptType && newData.catagory && newData.defaultProcess) {
        // resolve()
        let log = {
          action: 'UPDATE',
          actionOn: 'departmentMaster',
          actionName: 'updateDepartment',
          oldValue: JSON.stringify(oldData),
          message: `${oldData?.deptName || ''} department was updated.`
        }
        updateDepartmentMutation({ variables: { input: newData, log } }).then((data) => {
          setLoader(false)
          toast.success('department updated successfully.');
          resolve()
        }).catch((e) => {
          setLoader(false)
          reject()
          toast.error(FormatError(e))
        })
      } else {
        setLoader(false)
        toast.warn('please, fill all required(*) fields.')
        reject()
      }
    })
  }
  const DeleteDepartment = (oldData) => {
    return new Promise((resolve, reject) => {
      let log = {
        action: 'DELETE',
        actionOn: 'departmentMaster',
        actionName: 'deleteDepartment',
        oldValue: JSON.stringify(oldData),
        message: `${oldData?.deptName || ''} department was deleted.`
      }
      setLoader(true)
      deleteDepartmentMutation({ variables: { id: oldData.id, log } }).then((data) => {
        toast.success('department deleted successfully.');
        setLoader(false)
        resolve()
      }).catch((e) => {
        setLoader(false)
        reject()
        toast.error(FormatError(e))
      })
    })
  }

  useEffect(() => {
    if (subscribeToMore) {
      const unsubscribe = subscribeToMore({
        document: DEPARTMENT_SUBSCRIBE,
        updateQuery: (previousResult, { subscriptionData }) => {
          if (!subscriptionData.data) {
            return previousResult;
          }
          tableRef.current && tableRef.current.onQueryChange()
          return previousResult;
        },
      });
      return () => unsubscribe();
    }
  }, [subscribeToMore]);

  const addButton = (val) => {
    if (val || (role === 'admin'))
      return { onRowAdd: (newData) => AddDepartment(newData) }
    else
      return {}
  }
  useEffect(() => {
    document.getElementsByClassName("parentTable")[0].firstElementChild.firstElementChild.firstChild.remove()
  }, [])
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <div className="parentTable">
            <MaterialTable
              title="Department "
              columns={columns}
              tableRef={tableRef}
              isLoading={loader}
              data={query => getDepartmentData(query)}
              icons={{ Add: props => { return (<div> <i className="fa fa-plus-square" ></i> Department </div>) } }}
              options={{
                emptyRowsWhenPaging: false,
                padding: 'dense',
                showTitle: false,
                debounceInterval: 300,
                pageSize: pageSize,
                pageSizeOptions: [5, 10, 15, 20, 25, 50],
                addRowPosition: 'first',
                filtering: true,
                sorting: true,
                search: false,
                filterCellStyle: { padding: '5px', },
                maxBodyHeight: window.innerHeight - 215,
                rowStyle: rowData => ({
                  backgroundColor: (selectedRow === rowData.tableData.id) ? 'rgb(212, 218, 255)' : '#f5f5f5'
                }),
                headerStyle: { backgroundColor: 'whitesmoke' }
              }}
              editable={{
                isEditHidden: (rowData) => (role === 'admin') ? false : !permission.isUpdate,
                isDeleteHidden: (rowData) => (role === 'admin') ? false : !permission.isDelete,
                ...addButton(permission?.isInsert),
                onRowUpdate: (newData, oldData) => UpdateDepartment(newData, oldData),
                onRowDelete: (oldData) => DeleteDepartment(oldData)
              }}
              onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}


export default withRouter(DepartmentMaster)










