import gql from 'graphql-tag';

export const GETROUGHSTOCKFROMPKT = gql`
query getRoughStockFromPktType($pktType: String!){
  getRoughStockFromPktType(pktType:$pktType){
    id
    lotNoId{
      id
      lotNo
    }
    pktNoId{
      id
      pktNo
    }
    orgPktType
    location
    rfId
    issueCts
    shapeId{
      id
      shortName
    }
    colorId{
      id
         shortName
    }
    purityId{
      id
      shortName
    }
    cutId{
      id
      shortName
    }
    polishId{
      id
      shortName
    }
    symmId{
      id
      shortName
    }
    extraCts
    fluId{
      id
      shortName
    }
    brown
    gradPer
    rapRate
    measurment
  }
}
`;
export const GETBRANCHTRANSFERDETAILS = gql`
query getBranchTransferDetail($id: ID!){
  getBranchTransferDetail(id:$id){
   id
   btTransMasterId
   lotNoId{
     id
     lotNo
   }
   pktNoId{
     id
     pktNo
   }
   location
   rfId
   cts
   colorId{
     id
     shortName
   }
   shapeId{
     id
     shortName
   }
   purityId{
     id
     shortName
   }
   cutId{
     id
     shortName
   }
   polishId{
     id
     shortName
   }
   symmId{
     id
     shortName
   }
   fluId{
     id
     shortName
   }
   measurment
   brown
   gradPer
   rapRate
   createdBy
   updatedBy
   createdAt
   updatedAt
 }
 }
`;

export const GETBRANCHTRANSFERMASTER = gql`
query getBranchTransMaster($page: Int, $limit: Int, $filter: String, $sort: branchTransMasterSort){
  getBranchTransMaster(page:$page,limit:$limit,filter:$filter,sort:$sort){
    count
    data{
      id
      btNo
      transDate
      pktType
      deptName{
        id
        deptName
      }
      remark
      createdBy{
        userName
      }
      updatedBy
      createdAt
      updatedAt
    }
  }
}
`;

export const EXTRATOPSDATA = gql`
query getExtraTops($pktType: String){
  getExtraTops(pktType:$pktType){
    id
    lotNoId{
      id
      lotNo
    }
    pktNoId{
      id
      pktNo
    }
    cts
    roughStockId{
      colorId{
        id
        shortName
      }
      shapeId{
        id
        shortName
      }
      purityId{
        id
        shortName
      }
      cutId{
        id
        shortName
      }
      polishId{
        id
        shortName
      }
      symmId{
        id
        shortName
      }
      fluId{
        id
        shortName
      }
      orgPktType
    }
  }
}
`;



