import gql from 'graphql-tag';

export const BANKDETAILS_SUBSCRIBE = gql`
  subscription BankDetailChange {
    BankDetailChange {
      keyType
      data{
        id
        bankID
        accNo
        bankName
        branchCode
        detOf
        dolAccNO
        narration
        partyID{
          id
          partyName
        }
        companyID{
          id
          companyName
        }
        swiftCode
        isActive
        createdBy
              updatedBy
        createdAt
        updatedAt
      }
    }
  }
`;
