import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { getMainDefinition } from 'apollo-utilities';
import { ApolloLink, split } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { RetryLink } from 'apollo-link-retry'
import { WebSocketLink } from 'apollo-link-ws';
import { onError } from 'apollo-link-error';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { persistCache } from 'apollo-cache-persist'
import history from './constants/history';
import App from './App';
import './index.css';

var CryptoJS = require('crypto-js')
let Emails = sessionStorage.getItem('be') || ''
var bytes = CryptoJS.AES.decrypt(Emails, 'kismet')
var originalText = bytes.toString(CryptoJS.enc.Utf8)
// var originalText = 'localhost:8000'
const DATABASE_IP = originalText || ''

const signOut = client => {
  localStorage.clear()
  sessionStorage.clear() 
  client.resetStore();
  history.push('/');
};

const retry = new RetryLink({ attempts: { max: Infinity } })
const httpLink = new HttpLink({
  uri: `https://${DATABASE_IP}/graphql`
});

const wsLink = new WebSocketLink({
  uri: `wss://${DATABASE_IP}/graphql`,
  options: {
    reconnect: true,
  },
});

const terminatingLink = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return (
      kind === 'OperationDefinition' && operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
  retry
);

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => {
    const token = localStorage.getItem('token');

    if (token) {
      headers = { ...headers, 'x-token': token };
    }

    return { headers };
  });

  return forward(operation);
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, extensions, path }) => {
      console.log('GraphQL error---', message);
      // if (extensions.code === 'UNAUTHENTICATED') {
      //   signOut(client);
      // }
    });
  }

  if (networkError) {
    console.log('Network error---', networkError);

    if (networkError.statusCode === 400) {
      signOut(client);
    }
  }
});

const link = ApolloLink.from([authLink, errorLink, terminatingLink]);

const cache = new InMemoryCache();
const storage = window.localStorage
const waitOnCache = persistCache({ cache, storage })

const client = new ApolloClient({
  link,
  cache,
});

// const AppCom = () => {
//   return <ApolloProvider client={client}>
//     <App />
//   </ApolloProvider>
// }
waitOnCache.then(() => {
  ReactDOM.render(
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>,
    document.getElementById('root'),
  )
});

