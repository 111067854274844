import gql from 'graphql-tag';

export const ADD_COMPANY = gql`
mutation addNewCompany( $input :newCompany!, $log: LogInput){
  addNewCompany(input:$input, log: $log){
    id
    companyName
    shortName
    contactName
    address1
    address2
    address3
    city
    state
    pinCode
    country
    phone1
    phone2
    mobile
    fax
    intercom
    email
    website
    RapnetId
    tanNo
    vatNo
    panNo
    gstTinNo
    cstTinNo
    ckRegNo
    currency
    contactPerson1Name
    mobileNo1
    contactPerson2Name
    mobileNo2
    isActive
  }
}
`;

export const UPDATE_COMPANY = gql`
mutation updateCompany($input:companyInput!, $log: LogInput){
  updateCompany(input:$input, log: $log){
    id
    companyName
    shortName
    contactName
    address1
    address2
    address3
    city
    state
    pinCode
    country
    phone1
    phone2
    mobile
    fax
    intercom
    email
    website
    RapnetId
    tanNo
    vatNo
    panNo
    gstTinNo
    cstTinNo
    ckRegNo
    currency
    contactPerson1Name
    mobileNo1
    contactPerson2Name
    mobileNo2
    isActive
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}
`;

export const DELETE_COMPANY = gql`
mutation deleteCompany($id:ID!, $log: LogInput){
  deleteCompany(id:$id, log: $log)
}
`;
