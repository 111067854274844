import gql from 'graphql-tag';

export const PAYMENT_SUBSCRIBE = gql`
  subscription PaymentMasterChange {
    PaymentMasterChange {
      keyType
      data{
        id
        payMasterNo
        transDate
        payMode
        payOff
        transType
        currency
        partyId{
          id
          partyName
        }
        currencyRate
        currencyAmount
        payedBillNo
        partyAmtZar
        adgAmtZar
        createdBy{
          id
          userName
        }
        updatedBy
      }
    }
  }
`;

export const PAYMENTMASTERDETAIL_SUBSCRIBE = gql`
  subscription PaymentMasterDetailChange {
    PaymentMasterDetailChange {
      keyType
      data{
        id
	      bankName
        chequeNo
        reference
        billNo
        billAmtZar
        disc
        discAmt
        billNoName
        otherAmt
        roundOff
        netAmountZar
        remark
        paymentMasterId
        createdBy
        createdAt
        updatedAt
        updatedBy
      }
    }
  }
`;
