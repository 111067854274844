import React from 'react'
import { makeStyles, Select, InputLabel, MenuItem, FormControl } from '@material-ui/core';
import _ from 'lodash'
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  Select: {
    marginTop: "0px !important",
    paddingTop: 0
  }
}));
export const PacketType = (props) => {
  const classes = useStyles();
  return <FormControl className={classes.formControl}>
    <InputLabel id="demo-simple-select-label">Packet Type </InputLabel>
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      disabled={props.packetTypedisable}
      value={props.PacketTypes}
      onChange={props.setPacketTypeFun}
    >
      {

        _.map(props.Data, (val, key) => {
          return <MenuItem key={key} value={key}>{val}</MenuItem>
        })

      }
    </Select>
  </FormControl>
}
