import React, { useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import Typography from '@material-ui/core/Typography';
import { Grid, Button } from '@material-ui/core';
import './LiveScreen.css'

import { useQuery } from '@apollo/react-hooks'
import { GETDEPARTMENTWISEDATAFORLIVEECREEN, GETUNISSUEDATAFORLIVESCREEN, GETKACHUDATAFORLIVESCREEN, GETEXTRATOPSDATAFORLIVESCREEN, APPROISSUEFROMROUGHSTOCK } from './LiveScreenQuery'
import { DepartmentWiseStockPage } from './Pages/DepartmentWiseStockPage'
// import { UnIssueStockPage } from './Pages/UnIssueStockPage'
// import { KachuStockPage } from './Pages/KachuStockPage'
import { ApproIssueFromRoughStock } from './Pages/ApproIssue'
import { ExtraTopsStockPage } from './Pages/ExtraTopsStockPage'
import { SelectedData } from './Pages/SelectedData'
import { PrintPdf } from './Pages/PrinPdf'
import { PrintDiv } from '../../../components/Comman/exportPdfAndExcel'
const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);
const useStyles = makeStyles(() => ({
    loader: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: window.innerHeight - 120,
        pointerEvents: 'none',
        opacity: 0.5
    }
}))

const LiveScreen = () => {
    const classes = useStyles();
    const [Fillter, setFillter] = useState({})
    const [isRefresh, setisRefresh] = useState(false)
    const { data: DepartmentData, loading: DepartmentLoading, refetch: DepartmentRefetch } = useQuery(GETDEPARTMENTWISEDATAFORLIVEECREEN, {
        fetchPolicy: 'cache-and-network'
    })
    const { data: unIssueData, loading: unIssueLoading, refetch: unIssueRefetch } = useQuery(GETUNISSUEDATAFORLIVESCREEN, {
        fetchPolicy: 'cache-and-network'
    })
    const { data: kachuData, loading: kachuLoading, refetch: kachuRefetch } = useQuery(GETKACHUDATAFORLIVESCREEN, {
        fetchPolicy: 'cache-and-network'
    })
    const { data: approIssueData, loading: approIssueLoading, refetch: approIssueRefetch } = useQuery(APPROISSUEFROMROUGHSTOCK, {
        fetchPolicy: 'cache-and-network'
    })
    const { data: extraTopsData, loading: extraTopsLoading, refetch: extraTopsRefetch } = useQuery(GETEXTRATOPSDATAFORLIVESCREEN, {
        fetchPolicy: 'cache-and-network'
    })


    const RefreshHandler = () => {
        DepartmentRefetch()
        unIssueRefetch()
        kachuRefetch()
        extraTopsRefetch()
        approIssueRefetch()
        setFillter({})
        setisRefresh(true)
    }
    const getdisableAndLoading = () => {
        if (isRefresh) {
            if (DepartmentLoading || unIssueLoading || kachuLoading || extraTopsLoading || approIssueLoading) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }
    if (DepartmentLoading || unIssueLoading || kachuLoading || extraTopsLoading || approIssueLoading) {
        return <Grid container className={classes.loader} ><img src="https://i.gifer.com/4V0b.gif" alt="loading" /></Grid>
    } else
        return (
            <>
                <div>
                    <Accordion>
                        <Grid xs={12} item={true} container className="live-screen">
                            <Grid item={true} xs={4} style={{ marginLeft: 10 }}>
                                <Button variant="contained" color="primary" onClick={() => PrintDiv()} style={{ marginRight: 10 }}>Print</Button>
                                <Button type="submit" variant="contained" disabled={getdisableAndLoading()} color="primary" onClick={() => RefreshHandler()}>
                                    {getdisableAndLoading() && <CircularProgress size={24} />} Refresh
                            </Button>
                            </Grid>
                            <Grid xs={4} item={true} style={{ textAlign: "center" }}>
                                <Typography> Live Screen</Typography>
                            </Grid>
                        </Grid>
                    </Accordion>

                    <DepartmentWiseStockPage setFillter={setFillter} data={DepartmentData} loading={DepartmentLoading} />
                    {/* <UnIssueStockPage data={unIssueData} loading={unIssueLoading} />
                    <KachuStockPage setFillter={setFillter} data={kachuData} loading={kachuLoading} /> */}
                    <ApproIssueFromRoughStock setFillter={setFillter} data={approIssueData} loading={approIssueLoading} />
                    <ExtraTopsStockPage setFillter={setFillter} data={extraTopsData} loading={extraTopsLoading} />
                    {Object.keys(Fillter).length > 0 ? <SelectedData Fillter={Fillter} /> : ""}
                </div>
                <PrintPdf />

            </>
        )
}

export default LiveScreen;