import React from 'react'
import moment from 'moment'
export const CommponExport = (props) => {
  return <div id="printdivcontent"
    style={{ display: "none" }}
  >
    <div>
      {props.pdfHeader}
      <table className="exportTable" style={{ width: "100%", border: "1px solid", borderCollapse: "collapse" }} id="ExportTable">
        <tbody>
          <tr>
            {props.columns.map((d, key) => {
              return <th key={key} style={{ fontSize: 10, border: "1px solid #ddd", textAlign: "center" }}>{d.title}  </th>
            })}
          </tr>

          {props.AllData.map((d, i) => {
            return <tr key={i} style={{ textAlign: "center" }}>
              {props.columns.map((newd, key) => {
                if (newd.field === "imageUrl" || newd.field === "videoUrl") {
                  return <td key={key} style={{ fontSize: 10, border: "1px solid #ddd", textAlign: "center" }}>
                    <a href={d[newd.field]}>{newd.field === "imageUrl" ? 'Image' : 'Video'}</a>
                  </td>
                }
                else if ((newd.field === "date" || newd.field === "billDate" || newd.field === "purDate") && d[newd.field] !== "Grand Total") {
                  if (d[newd.field] === null || d[newd.field] === undefined) {
                    return <td key={key} style={{ fontSize: 10, border: "1px solid #ddd", textAlign: "center" }}></td>
                  } else {
                    return <td key={key} style={{ fontSize: 10, border: "1px solid #ddd", textAlign: "center" }}>
                      {moment(d[newd.field]).format('DD-MM-YYYY')}  </td>
                  }
                } else
                  return <td key={key} style={{ fontSize: 10, border: "1px solid #ddd", textAlign: "center" }}>
                    {d[newd.field]}  </td>
                // }
              })}
            </tr>
          })}
        </tbody>
      </table>
    </div>
  </div >
}