import React, { useEffect, useRef, useState } from 'react'
import { Grid } from '@material-ui/core';
import MaterialTable from 'material-table';
import { toast } from 'react-toastify'
import _ from 'lodash'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { FormatError } from '../../../components/Comman/FormatError'
import { Tofixed } from '../../../components/Comman/Tofix'
import { GradingSelectComponent } from '../../../components/DropdownSelect/SelectComponents/ShortCodeSelectComponent'
import { GETALLSHORTCODETYPE } from '../../Utility/SortCode/SortCodeQuery';
import { GETSUBPACKET } from '../DepartmentIsuueReturn/Query';


const columns = [
  {
    title: 'Sub Pkt *',
    field: 'pktNo'
  },
  {
    title: 'Color *',
    field: 'colorId',
    render: (rowData) => rowData?.colorId?.shortName || ""
  },
  {
    title: 'Shape *',
    field: 'shapeId',
    render: (rowData) => rowData?.shapeId?.shortName || "",
  },
  {
    title: 'Purity *',
    field: 'purityId',
    render: (rowData) => rowData?.purityId?.shortName || ""
  },
  {
    title: 'Exp Cts *',
    field: 'expcts',
    type: "numeric"
  },
  {
    title: 'Floursence',
    field: 'flsId',
    render: (rowData) => rowData?.flsId?.shortName || ""
  },
  {
    title: 'Tension *',
    field: 'tensionId',
    render: (rowData) => rowData?.tensionId?.shortName || ""
  }
]

export const SubPacket = (props) => {
  let Temp = props?.TotalSubPacket || [];
  let PacketChild = props?.PacketChildData?.id || []
  const { fetchMore } = useQuery(GETSUBPACKET , {
    variables:{id:props?.PacketChildData?.id} 
  })
  let Data = _.filter(Temp, (d => {
    let subPktMasterId = d?.subPktMasterId?.id || null
    return subPktMasterId === PacketChild
  }))
  const getPacketSubDetail = () => {
    return new Promise(async (resolve, reject) => {
      let result = await fetchMore({
        variables:{id:props?.PacketChildData?.id},
        fetchPolicy: 'network-only',
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;
          return fetchMoreResult;
        }
      })
      resolve({
          data:result?.data?.getSubPkt
      })
    })
  }


  const { data: shortCodeData, loading } = useQuery(GETALLSHORTCODETYPE, {
    variables: { type: "All" },
    fetchPolicy: 'cache-and-network',
  })

  const getShortCodeData = (TYPE) => {
    return _.filter(shortCodeData?.getAllType || [], (d) => d.type === TYPE);
  }

  columns.map((d, key) => {

    if (d.field === "colorId") {
      d.editComponent = props => {
        let valueDefault = props?.rowData?.colorId?.id || props?.rowData?.colorId || null
        return <GradingSelectComponent propsData={props} TYPE={"colorId"} data={getShortCodeData("COLOR")} valueDefault={valueDefault} />
      }
    }
    if (d.field === "shapeId") {
      d.editComponent = props => {
        let valueDefault = props?.rowData?.shapeId?.id || props?.rowData?.shapeId || null
        return <GradingSelectComponent propsData={props} TYPE={"shapeId"} data={getShortCodeData("SHAPE")} valueDefault={valueDefault} />
      }
    }
    if (d.field === "purityId") {
      d.editComponent = props => {
        let valueDefault = props?.rowData?.purityId?.id || props?.rowData?.purityId || null
        return <GradingSelectComponent propsData={props} TYPE={"purityId"} data={getShortCodeData("PURITY")} valueDefault={valueDefault} />
      }
    }
    if (d.field === "flsId") {
      d.editComponent = props => {
        let valueDefault = props?.rowData?.flsId?.id || props?.rowData?.flsId || null
        return <GradingSelectComponent propsData={props} TYPE={"flsId"} data={getShortCodeData("FLOURSENCE")} valueDefault={valueDefault} />
      }
    }
    if (d.field === "tensionId") {
      d.editComponent = props => {
        let valueDefault = props?.rowData?.tensionId?.id || props?.rowData?.tensionId || null
        return <GradingSelectComponent propsData={props} TYPE={"tensionId"} data={getShortCodeData("TENSION")} valueDefault={valueDefault} />
      }
    }
    return null
  })

  const AddSubPacket = (newData) => {
    return new Promise((resolve, reject) => {
      newData.packetMasterId = props.PacketMasterData.id
      newData.entryType = props.PacketMasterData.entryType
      newData.partyId = props.PacketMasterData.partyId.id
      newData.lotNoId = props.PacketMasterData.lotNoId?.id || props.PacketMasterData.lotNoId
      newData.cts = parseFloat(Tofixed(newData?.cts || 0))
      newData.expcts = parseFloat(Tofixed(newData?.expcts || 0))
      newData.majuriRate = parseFloat(Tofixed(newData?.majuriRate || 0))
      newData.zarRate = parseFloat(Tofixed(newData?.zarRate || 0))
      newData.subPktMasterId = props?.PacketChildData?.id || null
      newData.isSubPkt = true
      let CalculateexpCts = parseFloat(Tofixed(_.sum(_.map(Data, 'expcts')) + newData.expcts))
      let cts = parseFloat(Tofixed(props?.PacketChildData?.cts || 0))
      if (CalculateexpCts <= cts) {
        props.AddPacketDetailMutation({
          variables: { input: newData }
        }).then((data) => {
          toast.success('packet detail created successfully.');
          props.tableRef.current.onQueryChange();
          resolve()
        }).catch((e) => {
          reject()
          toast.error(FormatError(e))
        })
      } else {
        reject()
        toast.error('expect carats is max than Packet  carats ')
      }
    })
  }
  const UpdateSubPacket = (newData, oldData) => {
    return new Promise((resolve, reject) => {
      delete newData.createdBy
      delete newData.createdAt
      delete newData.__typename
      delete newData.updatedAt
      delete newData.updatedBy
      delete newData.isPlanning

      newData.colorId = newData?.colorId?.id || newData?.colorId || null
      newData.tensionId = newData?.tensionId?.id || newData?.tensionId || null
      newData.purityId = newData?.purityId?.id || newData?.purityId || null
      newData.flsId = newData?.flsId?.id || newData?.flsId || null
      newData.shapeId = newData?.shapeId?.id || newData?.shapeId || null

      newData.packetMasterId = props.PacketMasterData.id
      newData.cts = parseFloat(Tofixed(newData?.cts || 0))
      newData.expcts = parseFloat(Tofixed(newData?.expcts || 0))
      newData.majuriRate = parseFloat(Tofixed(newData?.majuriRate || 0))
      newData.zarRate = parseFloat(Tofixed(newData?.zarRate || 0))
      newData.subPktMasterId = props?.PacketChildData?.id || null
      newData.isSubPkt = true
      let CalculateexpCts = parseFloat(Tofixed(_.sum(_.map(_.filter(Data, (d) => d.id !== newData.id), 'expcts')) + newData.expcts))
      let cts = parseFloat(Tofixed(props?.PacketChildData?.cts || 0))
      if (CalculateexpCts <= cts) {
        props.UpdatePacketDetailMutation({ variables: { input: newData } }).then((data) => {
          toast.success('packet master updated successfully.');
          props.tableRef.current.onQueryChange();
          resolve()
        }).catch((e) => {
          reject()
          toast.error(FormatError(e))
        })
      } else {
        reject()
        toast.error('expect carats is max than Packet  carats ')
      }
    })
  }
  const DeletePacketChild = (oldData) => {
    return new Promise((resolve, reject) => {
      props.DeletePacketDetailMutation({ variables: { id: oldData.id } }).then((data) => {
        toast.success('packet detail deleted successfully!');
        props.tableRef.current.onQueryChange();
        resolve()
      }).catch((e) => {
        reject()
        toast.error(FormatError(e))
      })
    })
  }
  return <>
    <Grid xs={12} container>
      <Grid xs={6}>
      </Grid>
      <Grid xs={6} justify="flex-end" container>
        <Grid xs={6}>

        </Grid>
        <Grid xs={3}>
          Packet No : {props?.PacketChildData?.pktNo}
        </Grid>
        <Grid xs={3}>
          Packet Cts : {props?.PacketChildData?.cts}
        </Grid>
      </Grid>
    </Grid>
    <Grid
      container
      direction="row"
      justify="flex-end" item xs={12} >
      <div className="SubPacketTable" >
        {!loading && <MaterialTable
          columns={columns}
          data={() => getPacketSubDetail()}
          options={{
            emptyRowsWhenPaging: false,
            padding: 'dense',
            pageSize: 10,
            pageSizeOptions: [5, 10, 15, 20, 25, 50],
            addRowPosition: 'first',
            filtering: false,
            sorting: false,
            search: false,
            paging: false,
            filterCellStyle: { padding: '5px', },
            maxBodyHeight: window.innerHeight - 215,
            showTitle: false
          }}
          editable={{
            onRowAdd: (newData) => AddSubPacket(newData),
            onRowUpdate: (newData, oldData) => UpdateSubPacket(newData, oldData),
            onRowDelete: (oldData) => DeletePacketChild(oldData)
          }}
        />
        }
      </div>
    </Grid>
  </>
}