/********************* Libray **************** */
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withRouter } from "react-router-dom";
import { Grid } from '@material-ui/core';

import { DayPayment } from './DayPayment'
import { PurchaseTermsRemainder } from './PurchaseTermsRemainder'
import { JobWorkDuePayment } from './JobWorkDuePayment'
import { Accordion } from '../../../components/Comman/Accordian'

function PaymentDashboard(props) {
    return (
        <>
            <Accordion style={{ borderBottom: "1px solid #E3E3E3" }}>
                <Grid xs={12} item={true} container className="live-screen">
                    <Grid item={true} xs={4} style={{ marginLeft: 10 }}>
                    </Grid>
                    <Grid xs={4} item={true} style={{ textAlign: "center" }}><Typography> Payment Dashboard</Typography></Grid>
                </Grid>
            </Accordion>
            <div >
                <Grid container justify="space-between" spacing={2}>
                    <Grid xs={6} item>
                        <DayPayment />
                    </Grid>
                    <Grid xs={6} item>
                        <PurchaseTermsRemainder />
                        <div style={{ marginTop: 10 }}>
                            <JobWorkDuePayment />
                        </div>
                    </Grid>
                </Grid>
            </div >
        </>
    );
}
export default withRouter(PaymentDashboard)
