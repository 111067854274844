import gql from 'graphql-tag';

const COMPANY = gql`
    query getCompanies($page: Int, $limit: Int, $filter:String, $sort:companySort){
      getCompanies(page:$page,limit:$limit,filter:$filter,sort:$sort){
        count
        data{
        id
        companyName
        shortName
        contactName
        address1
        address2
        address3
        city
        state
        pinCode
        country
        phone1
        phone2
        mobile
        fax
        intercom
        email
        website
        RapnetId
        tanNo
        vatNo
        panNo
        gstTinNo
        cstTinNo
        ckRegNo
        currency
        contactPerson1Name
        mobileNo1
        contactPerson2Name
        mobileNo2
        isActive
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
    }
  }

`;
export const ALLCOMPANYDETAILS = gql`
query getAllCompanies{
  getAllCompanies{
  id
  companyName
}
}
`

export default COMPANY

