import { useQuery } from '@apollo/react-hooks'
import { PKTNOFROMLOTNO } from '../../pages/Transaction/Grad/GradQuery';
import { FormatError } from '../Comman/FormatError'
export const PktNoFromLotNo = (id) => {
  const { error, data } = useQuery(PKTNOFROMLOTNO, {
    variables: { id: id },
    fetchPolicy: 'cache-and-network',
  })
  if (error)
    console.log(FormatError(error));
  let PktNoFromLotNodata = data?.getPacketFromLotNo || []

  return PktNoFromLotNodata
}