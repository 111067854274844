import React, { useState } from 'react'
import { CompanyAndPartyAllData } from '../../../../components/DropdownSelect/SelectComponents/companyAndPartySelectComponent';
import _ from 'lodash'
import MaterialTable, { MTableFilterRow, MTableHeader } from 'material-table';
import { SEND_STOCK_EMAIL } from '../StockMasterMutation';
import { useMutation, useQuery } from '@apollo/react-hooks'
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { ALLPARTYMASTERDETAILS } from '../../../Master/PartyMaster/PartyMasterQuery';

const partyColumn = [
    { title: 'Party Name', field: 'partyName' },
    { title: 'Email', field: 'email' },
]

export const PartyTable = ({ emailData, refetch }) => {
    const [partyData, setPartyData] = useState([])
    const { error, data, loading } = useQuery(ALLPARTYMASTERDETAILS, {
        fetchPolicy: 'cache-and-network',
    })

    const [selectedEmails, selectedSetEmails] = useState([]);

    const [SendEmailStockNotification] = useMutation(SEND_STOCK_EMAIL)

    const sendEmailNotification = () => {
        return new Promise((resolve, reject) => {
            let emails = _.map(selectedEmails, 'email')
            var divContents = document.getElementById("printdivcontent")
            if (emails && emails.length > 0) {
                if (emailData.length !== 0) {
                    // console.log("content: _.map(emailData, 'id'", _.map(emailData, 'id'));
                    SendEmailStockNotification({ variables: { input: { email: emails, content: _.map(emailData, 'id') } } }).then((data) => {
                        toast.success('stock email notification sended successfully.');
                        selectedSetEmails([])
                        // setischange(true)
                        // setChecked(true)
                        refetch()
                        resolve()
                    }).catch((e) => {
                        toast.warn("failed to send stock email notification")
                        reject()
                    })
                } else {
                    toast.warn("please select data ")
                }
            } else {
                toast.warn('please select any email.')
                reject()
            }
        })
    }


    useEffect(() => {
        let parties = data?.getAllParties || []
        let salesParties = _.filter(parties, function (party) {
            if (party.email) {
                return party.email != null
            }
        })
        setPartyData(salesParties)
    }, [data])


    if (error) {
        return <>error......</>
    } else if (loading) {
        return <>loading......</>
    } else
        return <MaterialTable
            title="Party"
            columns={partyColumn}
            data={partyData}
            options={{
                emptyRowsWhenPaging: false,
                padding: 'dense',
                paging: false,
                selection: true,
                filtering: true,
                sorting: true,
                search: false,
                filterCellStyle: { padding: '5px', },
                maxBodyHeight: 260,
                // rowStyle: rowData => ({
                //   backgroundColor: (selectedRow === rowData.tableData.id) ? 'rgb(212, 218, 255)' : '#f5f5f5'
                // })
            }}
            actions={[
                {
                    tooltip: 'Send Email Notification',
                    icon: 'email',
                    onClick: (evt, data) => { sendEmailNotification() }
                }
            ]}
            // onRowClick={((evt, selectedRow) => setSelectedPartyRow(selectedRow.tableData.id))}
            // onSelectionChange={(rows) => selectedSetEmails(rows)}
            onSelectionChange={(rows) => {
                selectedSetEmails(rows)
            }}
        // components={{
        //     FilterRow: props => {
        //         return <MTableFilterRow
        //             {...props}
        //             columns={props.columns}
        //             onFilterChanged={(columnId, value) => {
        //                 // props.onFilterChanged(columnId, value);
        //                 // generateFilterString(props.columns[columnId].field, value)
        //             }}
        //         />
        //     },
        //     Header: props => {
        //         return <MTableHeader
        //             {...props}
        //             orderBy={orderBy}
        //             orderDirection={orderDirection}
        //             onOrderChange={(orderBy, orderDirection) => {
        //                 // props.onOrderChange(orderBy, orderDirection)
        //                 // setSorting(orderBy, orderDirection)
        //             }}
        //         />
        //     }
        // }}
        />
}