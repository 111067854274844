import gql from 'graphql-tag';

export const COSTING = gql`
query getCostingMaster($page: Int, $limit: Int, $filter: String, $sort: costingMasterSort){
  getCostingMaster(page:$page, limit:$limit, filter:$filter, sort:$sort){
    count
    data{
      id
      invoiceNo
      date
      lotNoId{
        id
        lotNo
      }
      partyId{
        id
        partyName
      }
      costId
      costType
      totalCts
      totalCost
      totalCostZar
      createdBy{
        userName
      }
      updatedBy
      createdAt
      updatedAt
    }
  }
}
`;
export const COSTINGDETAIL = gql`
  query getAllCostingDetail($id: ID!){
    getAllCostingDetail(id:$id){
      id
      srNo
      costingMasterId
      lotNoId{
        id
        lotNo
      }
      pktNoId{
        id
        pktNo
      }
      isEditable
      cts
      rate
      cost
      rateZar
      costZar
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;
export const GETROUGHSTOCKFORCOSTINGDETAIL = gql`
query getRoughStockForCosting($lotNoId: ID,$pktNoId: ID){
  getRoughStockForCosting(lotNoId:$lotNoId,pktNoId:$pktNoId){
    id
    lotNoId{
      id
      lotNo
    }
    pktNoId{
      pktNo
    }
    issueCts
    orgCts
  }
}
`;
