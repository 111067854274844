import React, { useState, useEffect, useRef } from 'react'
import { withRouter } from "react-router-dom";
import { TextField, Button, FormControl, Grid, Select, MenuItem, Typography } from '@material-ui/core';
import DateFnsUtils from "@date-io/date-fns";
import { useQuery } from '@apollo/react-hooks'
import MaterialTable, { MTableFilterRow } from 'material-table';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment'
import { GETSALESREPORT } from './SalesReportQuery';
import _, { result } from 'lodash'
import { LotNo } from '../../../components/DropdownSelect/LotNo';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CompanyAndPartyAllData } from '../../../components/DropdownSelect/SelectComponents/companyAndPartySelectComponent';

import { BrokerSelectData } from '../../../components/DropdownSelect/SelectComponents/BrokerSelectComponent';
import { Tofixed } from '../../../components/Comman/Tofix'
import './sales.css'
import { useStyles, Accordion, AccordionSummary, AccordionDetails } from '../../../components/Comman/Accordian'
import { CommponExport } from '../../../components/ExportReport/CommponExport'
import { ExportCsv } from '../../../components/ExportReport/ExportCsv'
import { PrintDiv } from '../../../components/Comman/exportPdfAndExcel'
import ExcelPng from '../../../../Excel.png'
import * as XLSX from 'xlsx/xlsx.mjs';

const Detailscolumns = [
  {
    title: 'Date', field: 'date',
    type: 'date',
    render: (rowData) => {
      if (rowData.date === "Grand Total") {
        return rowData.date
      } else
        return moment(rowData.date).format('DD/MM/YYYY')
    }
  },
  { title: 'Party Name', field: 'partyId.partyName' },
  { title: 'Broker Name', field: 'brokerId.partyName' },
  { title: 'Bill No', field: 'billNo' },
  {
    title: 'Lot No', field: 'lotNoId.lotNo'
  },
  {
    title: 'Pkt No', field: 'pktNoId.pktNo'
  },
  { title: 'Certi No', field: 'certificateNo' },
  {
    title: 'Shape', field: 'shapeId.shortName',
  },
  {
    title: 'Cts',
    field: 'cts',
    render: (rowData) => Tofixed(rowData?.cts || 0)
  },
  {
    title: 'clr', field: 'colorId.shortName',
  },
  {
    title: 'Purity', field: 'purityId.shortName'
  },
  {
    title: 'Cut', field: 'cutId.shortName',
  },
  {
    title: 'Polish', field: 'polishId.shortName',
  },
  {
    title: 'symm', field: 'symmId.shortName',
  },
  {
    title: 'flo', field: 'floId.shortName',
  },
  {
    title: 'Rape Rate',
    field: 'rapRate',
    render: (rowData) => rowData?.rapRate ? Tofixed(rowData?.rapRate || 0) : ""
  },
  {
    title: 'Disc %',
    field: 'diffPer'
  },
  {
    title: 'Net Rate',
    field: 'netRaprate',
    render: (rowData) => rowData?.netRaprate ? Tofixed(rowData?.netRaprate || 0) : ""

  },
  {
    title: 'Net Amt',
    field: 'netRaprateAmt',
    render: (rowData) => Tofixed(rowData?.netRaprateAmt || 0)

  },
  {
    title: 'Dom Rate',
    field: 'rateZar',
    render: (rowData) => rowData?.rateZar ? Tofixed(rowData?.rateZar || 0) : ""

  },
  {
    title: 'Dom amt',
    field: 'amountZar',
    render: (rowData) => Tofixed(rowData?.amountZar || 0)

  },
  {
    title: 'Com %',
    field: 'commisionPer'
  },
  {
    title: 'Com ',
    field: 'commision',
    render: (rowData) => Tofixed(rowData?.commision || 0)

  },
  {
    title: 'Pol Exp %',
    field: 'polishExpPer'
  },
  {
    title: 'Pol Exp Amt',
    field: 'polishExpAmt',
    render: (rowData) => Tofixed(rowData?.polishExpAmt || 0)

  },
  {
    title: 'Zar Amt',
    field: 'netAmtZar',
    render: (rowData) => Tofixed(rowData?.netAmtZar || 0)

  },

]
const Summarycolumns = [
  {
    title: 'Date', field: 'date',
    render: (rowData) => {
      if (rowData.date === "Grand Total") {
        return rowData.date
      } else
        return moment(rowData.date).format('DD/MM/YYYY')
    }
  },
  { title: 'Party Name', field: 'partyName' },
  { title: 'Broker Name', field: 'brokerName' },
  { title: 'Bill No', field: 'billNo' },
  { title: 'Packets ', field: 'packetNo' },
  { title: 'Cts', field: 'cts' },
  { title: 'Total Amt', field: 'netRaprateAmt' },
  { title: 'Dom Amt', field: 'amountZar' },
  { title: 'Com Amt', field: 'commision' },
  { title: 'Zar Amt', field: 'netAmtZar' },
  { title: 'vat Amt', field: 'vatAmount' },
  { title: 'Net Zar', field: 'netAmtZar' },
  { title: 'cash/credit', field: 'type' },
]

function Sales() {
  const classes = useStyles();
  const tableRef = useRef();
  const [AllDataSummary, setAllDataSummary] = useState([])
  const [AllDataDetails, setAllDataDetails] = useState([])
  const [Result, _Result] = useState([])
  const [type, setType] = useState('detail');
  const [invoiceType, setInvoiceType] = useState('');
  const [partyName, setPartyName] = useState('');
  const [lotNo, setLotNo] = useState({});
  const [BrokerName, setBrokerName] = useState('');
  const [selectedFromDate, setSelectedFromDate] = useState(moment().subtract(7, "days"));
  const [filterTest, setFilterTest] = useState([])
  const [loader, setloader] = useState(false)

  const [selectedToDate, setSelectedToDate] = useState(
    new Date()
  );
  const [filter, setFilter] = useState({ fromDate: selectedFromDate, toDate: selectedToDate })
  const { data, refetch, loading, error } = useQuery(GETSALESREPORT, {
    variables: { filter: JSON.stringify(filter) },
    fetchPolicy: 'cache-and-network',
  })
  useEffect(() => {
    setloader(loading)
  }, [loading])

  const getDetailTypeData = (Data) => {
    return {
      date: "Grand Total",
      cts: Tofixed(_.sum(_.map(Data, 'cts'))),
      netAmtZar: Tofixed(_.sum(_.map(Data, 'netAmtZar'))),
      polishExpAmt: Tofixed(_.sum(_.map(Data, 'polishExpAmt'))),
      commision: Tofixed(_.sum(_.map(Data, 'commision'))),
      amountZar: Tofixed(_.sum(_.map(Data, 'amountZar'))),
      netRaprateAmt: Tofixed(_.sum(_.map(Data, 'netRaprateAmt'))),
      diffPer: Tofixed(_.sum(_.map(Data, 'diffPer')) / Data.length),
      pktNoId: {
        pktNo: Data.length
      }
    }
  }
  const getSummeryTypeData = (AllDataSummary) => {
    return {
      date: "Grand Total",
      packetNo: AllDataSummary.length,
      cts: Tofixed(_.sum(_.map(AllDataSummary, 'cts'))),
      netRaprateAmt: Tofixed(_.sum(_.map(AllDataSummary, 'netRaprateAmt'))),
      commision: Tofixed(_.sum(_.map(AllDataSummary, 'commision'))),
      amountZar: Tofixed(_.sum(_.map(AllDataSummary, 'amountZar'))),
      vatAmount: Tofixed(_.sum(_.map(AllDataSummary, 'vatAmount'))),
      netAmtZar: Tofixed(_.sum(_.map(AllDataSummary, 'netAmtZar'))),
    }
  }



  useEffect(() => {
    let Data = data?.getSalesReport || []
    let Datanew = Data && Data.filter(d => d.date === "Grand Total")
    if (Data.length !== 0 && Datanew.length === 0 && type !== "summary") {
      let LastRow = getDetailTypeData(Data)
      Data.push(LastRow)
    }
    setAllDataDetails(Data)
  }, [data]);

  useEffect(() => {
    let GroupBySummary = _.groupBy(AllDataDetails || [], 'billNo')
    let SumaryData = []
    _.map(Object.keys(GroupBySummary), (billNo => {
      if (billNo !== "undefined") {
        let newD = {}
        newD.billNo = billNo
        newD.packetNo = GroupBySummary[billNo].length
        newD.cts = Tofixed(_.sum(_.map(GroupBySummary[billNo], 'cts')))
        newD.netRaprateAmt = Tofixed(_.sum(_.map(GroupBySummary[billNo], 'netRaprateAmt')))
        newD.amountZar = Tofixed(_.sum(_.map(GroupBySummary[billNo], 'amountZar')))
        newD.commision = Tofixed(_.sum(_.map(GroupBySummary[billNo], 'commision')))
        newD.netAmtZar = Tofixed(_.sum(_.map(GroupBySummary[billNo], 'netAmtZar')))
        newD.vatAmount = Tofixed(_.sum(_.map(GroupBySummary[billNo], 'vatAmount')))
        GroupBySummary[billNo].map(d => {
          newD.type = d.billtype
          newD.date = d.date
          newD.partyName = d?.partyId?.partyName || ''
          newD.brokerName = d?.brokerId?.partyName || ''
          return null
        })
        SumaryData.push(newD)
      }
    }))
    let Datanew = SumaryData && SumaryData.filter(d => d.date === "Grand Total")
    if (AllDataSummary.length !== 0 && Datanew.length === 0 && type === "summary") {
      let LastRow = getSummeryTypeData(AllDataSummary)
      SumaryData.push(LastRow)
    }
    setAllDataSummary(SumaryData)
  }, [data]);

  let defaultProps = LotNo('', '', '').defaultProps
  let parties = CompanyAndPartyAllData("PARTY");
  let Broker = BrokerSelectData("BROKER");

  useEffect(() => {
    let Data = JSON.parse(localStorage.getItem('SalesdetailsReport') || "{}")
    let type = localStorage.getItem('SalesdetailsReporttype')
    if (type) {
      setType(type)
    }
    if (Data.type) {
      setInvoiceType(Data.type)
    }
    if (Data.lotNoId) {
      setLotNo(_.find(defaultProps?.options || [], (d) => d.id === Data.lotNoId))
    }
    if (Data.partyId) {
      setPartyName(_.find(parties || [], (d) => d.id === Data.partyId))
    }
    if (Data.brokerId) {
      setBrokerName(_.find(Broker || [], (d) => d.id === Data.brokerId))
    }
    if (Data.fromDate) {
      setSelectedFromDate(moment(Data.fromDate))
    }
    if (Data.toDate) {
      setSelectedToDate(moment(Data.toDate))
    }
    if (Object.keys(Data).length > 0)
      setFilter(Data)
  }, [])

  const PreViewHandler = () => {
    let Data = {}
    if (invoiceType) {
      Data = { ...Data, "type": invoiceType }
    }
    if (lotNo) {
      Data = { ...Data, "lotNoId": lotNo.id }
    }
    if (partyName) {
      Data = { ...Data, "partyId": partyName.id }
    }
    if (selectedFromDate) {
      Data = { ...Data, "fromDate": moment(selectedFromDate).format('YYYY-MM-DD') }
    }
    if (selectedToDate) {
      Data = { ...Data, "toDate": moment(selectedToDate).format('YYYY-MM-DD') }
    }
    if (BrokerName) {
      Data = { ...Data, "brokerId": BrokerName.id }
    }
    localStorage.setItem('SalesdetailsReport', JSON.stringify(Data))
    localStorage.setItem('SalesdetailsReporttype', type)
    setFilter(Data, () => {
      refetch()
    })
  }

  const ClearViewHandler = () => {
    setInvoiceType('')
    setLotNo('')
    setPartyName('')
    setSelectedFromDate(moment().subtract(7, "days"))
    setSelectedToDate(new Date())
    setBrokerName('')
    localStorage.removeItem('SalesdetailsReport')
    localStorage.removeItem('SalesdetailsReporttype')
    setFilter({}, () => {
      refetch()
    })
  }

  const pdfHeader = () => {
    return <div style={{ marginBottom: 15 }}>
      <h2 style={{ textAlign: "center" }}>Sales details Report</h2>
      <p style={{ margin: 0, fontSize: 18, fontWeight: "bold" }}>Party : {partyName?.partyName || "All"}</p>
      <div style={{ display: "flex", width: "100%" }}>
        <div style={{ width: "50%" }}>
          < p style={{ margin: 0, fontSize: 18, fontWeight: "bold" }}>Lotno : {lotNo?.lotNo || "All"}</p>
        </div>
        <div style={{ textAlign: "end", width: "50%" }}>
          < p style={{ margin: 0, fontSize: 18, fontWeight: "bold" }}>{`Date : ${moment(selectedFromDate).format('DD-MM-YYYY')} To: ${moment(selectedToDate).format('DD-MM-YYYY')}`}</p>
        </div>
      </div>
      <div style={{ display: "flex", width: "100%" }}>
        <div style={{ width: "50%" }}>
          < p style={{ margin: 0, fontSize: 18, fontWeight: "bold" }}>Broker Name : {BrokerName?.partyName || "All"}</p>
        </div>
        <div style={{ textAlign: "end", width: "50%" }}>
          < p style={{ margin: 0, fontSize: 18, fontWeight: "bold" }}>Invoice Type: {invoiceType ? invoiceType : "All"}</p>
        </div>
      </div>
    </div>
  }

  const TableHeader = () => {
    return <>
      <tr>
        <td colSpan={COLUMN.length}><div ><h2 style={{ textAlign: "center" }}>Sales details Report</h2></div></td>
      </tr>
      <tr >
        <td colSpan={COLUMN.length - 4}>
          <p style={{ margin: 0, fontSize: 18, fontWeight: "bold" }}>Party : {partyName?.partyName || "All"}</p>

        </td>
        <td colSpan={4}>
          < p style={{ margin: 0, fontSize: 18, fontWeight: "bold" }}>{`Date : ${moment(selectedFromDate).format('DD-MM-YYYY')} To: ${moment(selectedToDate).format('DD-MM-YYYY')}`}</p>
        </td>
      </tr>
      <tr>
        <td colSpan={COLUMN.length - 4}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            < p style={{ margin: 0, fontSize: 18, fontWeight: "bold" }}>Lotno : {lotNo?.lotNo || "All"}</p>
          </div>
        </td>
        <td colSpan={4}>
          < p style={{ margin: 0, fontSize: 18, fontWeight: "bold" }}>Invoice Type: {invoiceType ? invoiceType : "All"}</p>
        </td>
      </tr>
      <tr>
        <td colSpan={COLUMN}>
          < p style={{ margin: 0, fontSize: 18, fontWeight: "bold" }}>Broker Name : {BrokerName?.partyName || "All"}</p>
        </td>
      </tr>
    </>
  }


  useEffect(() => {
    let Temp = type === "summary" ? AllDataSummary : AllDataDetails
    _Result(Temp)
  }, [type])


  useEffect(() => {
    let Temp = type === "summary" ? AllDataSummary : AllDataDetails
    _Result(Temp)
  }, [AllDataSummary])

  useEffect(() => {
    let Temp = type === "summary" ? AllDataSummary : AllDataDetails
    _Result(Temp)
  }, [AllDataDetails])


  let TITLE = type === "summary" ? "Sales summary Report" : "Sales details Report"
  let COLUMN = type === "summary" ? Summarycolumns : Detailscolumns
  let NewData = _.cloneDeep(Result)

  const SalesData = (query) => {
    return new Promise((resolve, reject) => {
      let TempNewData = _.cloneDeep(Result)
      if (query.filters.length > 0)
        TempNewData = _.filter(TempNewData, (d) => d.date !== "Grand Total")
      let Result1 = []
      query && query.filters.forEach(item => {
        console.log("item.column.field", item.column.field);
        if (item.value === "") {
          Result1 = TempNewData
        }
        else if (item.column.field === "date") {
          Result1 = _.filter(TempNewData, (d) => {
            let date = moment(d?.date).format('DD/MM/YYYY')
            return date === moment(item.value).format('DD/MM/YYYY')
          })
        }
        else if (item.column.field === "partyId.partyName") {
          Result1 = _.filter(TempNewData, (d) => {
            let PartyName = d?.partyId?.partyName || ''
            return PartyName.toUpperCase() === (item.value).toUpperCase()
          })
        }

        else if (item.column.field === "brokerId.partyName") {
          Result1 = _.filter(TempNewData, (d) => {
            let BrokerName = d?.brokerId?.partyName || ''
            return BrokerName.toUpperCase() === (item.value).toUpperCase()
          })
        }
        else if (item.column.field === "billNo") {
          Result1 = _.filter(TempNewData, (d) => {
            let billNo = d?.billNo || ''
            return parseInt(billNo) === parseInt(item.value)
          })
        }
        else if (item.column.field === "lotNoId.lotNo") {
          Result1 = _.filter(TempNewData, (d) => {
            let lotNo = d?.lotNoId?.lotNo || ''
            return lotNo.toUpperCase() === (item.value).toUpperCase()
          })
        }
        else if (item.column.field === "pktNoId.pktNo") {
          Result1 = _.filter(TempNewData, (d) => {
            let pktNo = d?.pktNoId?.pktNo || ''
            return pktNo.toUpperCase() === (item.value).toUpperCase()
          })
        }
        else if (item.column.field === "certificateNo") {
          Result1 = _.filter(TempNewData, (d) => {
            let certificateNo = d?.certificateNo || ''
            return certificateNo === item.value
          })
        }
        else if (item.column.field === "shapeId.shortName") {
          Result1 = _.filter(TempNewData, (d) => {
            let shapeId = d?.shapeId?.shortName || ''
            return shapeId.toUpperCase() === (item.value).toUpperCase()
          })
        }
        else if (item.column.field === "cts") {
          Result1 = _.filter(TempNewData, (d) => {
            let cts = d?.cts || ''
            return parseFloat(cts) >= parseFloat(item.value) && parseFloat(cts) < Math.floor(parseFloat(item.value) + 1)
          })
        }
        else if (item.column.field === "colorId.shortName") {
          Result1 = _.filter(TempNewData, (d) => {
            let colorId = d?.colorId?.shortName || ''
            return colorId.toUpperCase() === (item.value).toUpperCase()
          })
        }
        else if (item.column.field === "purityId.shortName") {
          Result1 = _.filter(TempNewData, (d) => {
            let purityId = d?.purityId?.shortName || ''
            return purityId.toUpperCase() === (item.value).toUpperCase()
          })
        }
        else if (item.column.field === "cutId.shortName") {
          Result1 = _.filter(TempNewData, (d) => {
            let cutId = d?.cutId?.shortName || ''
            return cutId.toUpperCase() === (item.value).toUpperCase()
          })
        }
        else if (item.column.field === "polishId.shortName") {
          Result1 = _.filter(TempNewData, (d) => {
            let polishId = d?.polishId?.shortName || ''
            return polishId.toUpperCase() === (item.value).toUpperCase()
          })
        }
        else if (item.column.field === "symmId.shortName") {
          Result1 = _.filter(TempNewData, (d) => {
            let symmId = d?.symmId?.shortName || ''
            return symmId.toUpperCase() === (item.value).toUpperCase()
          })
        }
        else if (item.column.field === "floId.shortName") {
          Result1 = _.filter(TempNewData, (d) => {
            let floId = d?.floId?.shortName || ''
            return floId.toUpperCase() === (item.value).toUpperCase()
          })
        }
        else if (item.column.field === "rapRate") {
          Result1 = _.filter(TempNewData, (d) => {
            let rapRate = d?.rapRate || ''
            return parseFloat(rapRate) >= parseFloat(item.value) && parseFloat(rapRate) < Math.floor(parseFloat(item.value) + 1)
          })
        }
        else if (item.column.field === "diffPer") {
          Result1 = _.filter(TempNewData, (d) => {
            let diffPer = d?.diffPer || ''
            return parseFloat(diffPer) >= parseFloat(item.value) && parseFloat(diffPer) < Math.floor(parseFloat(item.value) + 1)
          })
        }
        else if (item.column.field === "netRaprate") {
          Result1 = _.filter(TempNewData, (d) => {
            let netRaprate = d?.netRaprate || ''
            return parseFloat(netRaprate) >= parseFloat(item.value) && parseFloat(netRaprate) < Math.floor(parseFloat(item.value) + 1)
          })
        }
        else if (item.column.field === "netRaprateAmt") {
          Result1 = _.filter(TempNewData, (d) => {
            let netRaprateAmt = d?.netRaprateAmt || ''
            return parseFloat(netRaprateAmt) >= parseFloat(item.value) && parseFloat(netRaprateAmt) < Math.floor(parseFloat(item.value) + 1)
          })
        }
        else if (item.column.field === "rateZar") {
          Result1 = _.filter(TempNewData, (d) => {
            let rateZar = d?.rateZar || ''
            return parseFloat(rateZar) >= parseFloat(item.value) && parseFloat(rateZar) < Math.floor(parseFloat(item.value) + 1)
          })
        }
        else if (item.column.field === "amountZar") {
          Result1 = _.filter(TempNewData, (d) => {
            let amountZar = d?.amountZar || ''
            return parseFloat(amountZar) >= parseFloat(item.value) && parseFloat(amountZar) < Math.floor(parseFloat(item.value) + 1)
          })
        }
        else if (item.column.field === "commisionPer") {
          Result1 = _.filter(TempNewData, (d) => {
            let commisionPer = d?.commisionPer || ''
            return parseFloat(commisionPer) >= parseFloat(item.value) && parseFloat(commisionPer) < Math.floor(parseFloat(item.value) + 1)
          })
        }
        else if (item.column.field === "polishExpPer") {
          Result1 = _.filter(TempNewData, (d) => {
            let polishExpPer = d?.polishExpPer || ''
            return parseFloat(polishExpPer) >= parseFloat(item.value) && parseFloat(polishExpPer) < Math.floor(parseFloat(item.value) + 1)
          })
        }
        else if (item.column.field === "polishExpAmt") {
          Result1 = _.filter(TempNewData, (d) => {
            let polishExpAmt = d?.polishExpAmt || ''
            return parseFloat(polishExpAmt) >= parseFloat(item.value) && parseFloat(polishExpAmt) < Math.floor(parseFloat(item.value) + 1)
          })
        }
        else if (item.column.field === "netAmtZar") {
          Result1 = _.filter(TempNewData, (d) => {
            let netAmtZar = d?.netAmtZar || ''
            return parseFloat(netAmtZar) >= parseFloat(item.value) && parseFloat(netAmtZar) < Math.floor(parseFloat(item.value) + 1)
          })
        }
        else if (item.column.field === "type") {
          Result1 = _.filter(TempNewData, (d) => {
            let type = d?.type || ''
            return type.toUpperCase() === (item.value).toUpperCase()
          })
        }
        else if (item.column.field === "vatAmount") {
          Result1 = _.filter(TempNewData, (d) => {
            let vatAmount = d?.vatAmount || ''
            return parseFloat(vatAmount) >= parseFloat(item.value) && parseFloat(vatAmount) < Math.floor(parseFloat(item.value) + 1)
          })
        }
        else if (item.column.field === "commision") {
          Result1 = _.filter(TempNewData, (d) => {
            let commision = d?.commision || ''
            return parseFloat(commision) >= parseFloat(item.value) && parseFloat(commision) < Math.floor(parseFloat(item.value) + 1)
          })
        }
        else if (item.column.field === "packetNo") {
          Result1 = _.filter(TempNewData, (d) => {
            let packetNo = d?.packetNo || ''
            return parseInt(packetNo) === parseInt(item.value)
          })
        }
        else if (item.column.field === "brokerName") {
          Result1 = _.filter(TempNewData, (d) => {
            let brokerName = d?.brokerName || ''
            return brokerName.toUpperCase() === (item.value).toUpperCase()
          })
        }
        else if (item.column.field === "partyName") {
          Result1 = _.filter(TempNewData, (d) => {
            let partyName = d?.partyName || ''
            return partyName.toUpperCase() === (item.value).toUpperCase()
          })
        }
        if (type === "summary" && Result1.length > 0) {
          let LastRow = getSummeryTypeData(Result1)
          Result1 = [...Result1, { ...LastRow }]
        }
        if (type === "detail" && Result1.length > 0) {
          let LastRow = getDetailTypeData(Result1)
          Result1 = [...Result1, { ...LastRow }]
        }
      })
      if (error)
        reject(error)
      resolve({
        data: query.filters.length > 0 ? Result1 : TempNewData,
      })
    })
  }
  useEffect(() => {
    tableRef.current && tableRef.current.onQueryChange()
  }, [data, Result]);

  function exportExcelFile(workbook) {
    return XLSX.writeFile(workbook, "bookName.xlsx");
  }

  const exportCsvData = () => {
    var workbook = XLSX.utils.book_new();
    var worksheet_data = document.getElementById("table-to-xls");
    var worksheet = XLSX.utils.table_to_sheet(worksheet_data);
    workbook.SheetNames.push("Test");
    workbook.Sheets["Test"] = worksheet;
    exportExcelFile(workbook);
  }

  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Filters</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.root} id="filterresposiveDesign">
            <Grid container spacing={2}>
              <Grid item lg={2} xs={4} sm={3}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-from"
                    label="From Date"
                    format="dd/MM/yyyy"
                    value={selectedFromDate}
                    onChange={e => setSelectedFromDate(e)}
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                    style={{ marginTop: 0 }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item lg={2} xs={4} sm={3}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-to"
                    label="To Date"
                    format="dd/MM/yyyy"
                    value={selectedToDate}
                    onChange={e => setSelectedToDate(e)}
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                    style={{ marginTop: 0 }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item lg={2} xs={4} sm={3} style={{ marginTop: 8 }} className="selectOption">
                <FormControl className={classes.formControl}>
                  <Select
                    value={type}
                    onChange={(e) => {
                      setType(e.target.value)
                      refetch()
                    }
                    }
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}>
                    <MenuItem value="" >
                      <span style={{ color: "#808080" }}>Report Type</span>
                    </MenuItem>
                    <MenuItem value={"detail"}>DETAIL</MenuItem>
                    <MenuItem value={"summary"}>SUMMARY</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={2} xs={4} sm={3} className="selectOption">
                <FormControl className={classes.formControl} >
                  <Select
                    value={invoiceType}
                    onChange={(e) => setInvoiceType(e.target.value)}
                    displayEmpty
                    className={classes.selectEmpty}
                    inputProps={{ 'aria-label': 'Without label' }}>
                    <MenuItem value="" >
                      <span style={{ color: "#808080" }}>Invoice Type</span>
                    </MenuItem>
                    <MenuItem value={"ALL"}>ALL</MenuItem>
                    <MenuItem value={"PERFOMA"}>PERFOMA</MenuItem>
                    <MenuItem value={"TAX"}>TAX</MenuItem>
                    <MenuItem value={"CASH"}>CASH</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item lg={2} xs={4} sm={3} className="selectOption">
                <FormControl className={classes.formControl} style={{ paddingTop: 0 }}>
                  <Autocomplete
                    value={lotNo}
                    {...defaultProps}
                    onChange={(e, newdata) => {
                      if (newdata) {
                        setLotNo(newdata)
                      }
                    }}
                    className="lotmarginCustom"
                    renderInput={(params) => <TextField label="Lot No"  {...params} margin="normal" />}
                  />
                </FormControl>
              </Grid>
              <Grid item lg={2} xs={4} sm={3} className="selectOption">
                <FormControl className={classes.formControl} style={{ paddingTop: 0 }}>
                  <Autocomplete
                    value={partyName}
                    options={parties}
                    getOptionLabel={(option) => option.partyName}
                    onChange={(e, newdata) => {
                      if (newdata) {
                        setPartyName(newdata)
                      }
                    }}
                    className="lotmarginCustom"
                    renderInput={(params) => <TextField label="Party Name"  {...params} margin="normal" />}
                  />
                </FormControl>
              </Grid>
              <Grid item lg={2} xs={4} sm={3} className="selectOption">
                <FormControl className={classes.formControl} style={{ paddingTop: 0 }}>
                  <Autocomplete
                    value={BrokerName}
                    options={Broker}
                    getOptionLabel={(option) => option.partyName}
                    onChange={(e, newdata) => {
                      if (newdata) {
                        setBrokerName(newdata)
                      }
                    }}
                    className="lotmarginCustom"
                    renderInput={(params) => <TextField label="Broker Name"  {...params} margin="normal" />}
                  />
                </FormControl>
              </Grid>
              <Grid item lg={4} xs={8} sm={6} className="selectOption" style={{ background: "red" }}>
                <Button
                  onClick={() => PreViewHandler()}
                  variant="contained"
                  color="primary"
                  style={{ height: 34, width: 100 }}>
                  Preview
                </Button>
                <Button
                  onClick={() => ClearViewHandler()}
                  variant="contained"
                  color="primary"
                  style={{ height: 34, width: 100 }}>
                  clear
                </Button>
              </Grid>
            </Grid>
          </div>

        </AccordionDetails>
      </Accordion>
      < Grid container >
        <Grid item xs={12} style={{ position: "relative" }}>
          <img className="download-table-xls-button" src={ExcelPng} alt="alternative text" title="csv" onClick={() => exportCsvData()} />
          <ExportCsv
            Title={TITLE}
            columns={COLUMN}
            TableHeader={TableHeader()}
            AllData={NewData.filter(d => {
              if (d.date !== "Grand Total") {
                d["partyId.partyName"] = d?.partyId?.partyName
                d["brokerId.partyName"] = d?.brokerId?.partyName
                d["lotNoId.lotNo"] = d?.lotNoId?.lotNo
                d["pktNoId.pktNo"] = d?.pktNoId?.pktNo
                d["shapeId.shortName"] = d?.shapeId?.shortName
                d["colorId.shortName"] = d?.colorId?.shortName
                d["purityId.shortName"] = d?.purityId?.shortName
                d["cutId.shortName"] = d?.cutId?.shortName
                d["polishId.shortName"] = d?.polishId?.shortName
                d["symmId.shortName"] = d?.symmId?.shortName
                d["floId.shortName"] = d?.floId?.shortName
              }
              return d
            })}
          />
          < MaterialTable
            title={TITLE}
            columns={COLUMN}
            tableRef={tableRef}
            isLoading={loader}
            data={(query) => SalesData(query)}
            actions={
              [
                {
                  icon: 'print',
                  tooltip: 'PRINT',
                  isFreeAction: true,
                  onClick: (event) => PrintDiv()
                },
                // {
                //   icon: GetAppIcon,
                //   tooltip: 'EXPORT',
                //   isFreeAction: true,
                //   onClick: (e) => exportTableToExcel("ExportTable", `${TITLE}_${moment()}`, e)
                // }
              ]}
            options={{
              emptyRowsWhenPaging: false,
              debounceInterval: 3000,
              padding: 'dense',
              addRowPosition: 'first',
              filtering: true,
              sorting: true,
              search: false,
              filterCellStyle: { padding: '5px', },
              maxBodyHeight: window.innerHeight - 215,
              paging: false,
              rowStyle: rowData => {
                return ({
                  backgroundColor: (rowData.date === 'Grand Total') ? 'rgb(212, 218, 255)' : '#f5f5f5',
                  fontWeight: (rowData.date === "Grand Total" || rowData.currency === "Grand Total") && 'bolder'
                })
              },
              headerStyle: { backgroundColor: 'whitesmoke' }
            }
            }
          // components={{
          //   FilterRow: props => {
          //     return <MTableFilterRow
          //       {...props}
          //       columns={props.columns}
          //       onFilterChanged={(columnId, value) => {
          //         props.onFilterChanged(columnId, value);
          //         generateFilterString(props.columns[columnId].field, value)
          //       }}
          //     />
          //   }
          // }}
          />

        </Grid>
      </Grid >
      <CommponExport
        Title={TITLE}
        columns={COLUMN}
        pdfHeader={pdfHeader()}
        AllData={NewData.filter(d => {
          if (d.date !== "Grand Total") {
            d["partyId.partyName"] = d?.partyId?.partyName
            d["brokerId.partyName"] = d?.brokerId?.partyName
            d["lotNoId.lotNo"] = d?.lotNoId?.lotNo
            d["pktNoId.pktNo"] = d?.pktNoId?.pktNo
            d["shapeId.shortName"] = d?.shapeId?.shortName
            d["colorId.shortName"] = d?.colorId?.shortName
            d["purityId.shortName"] = d?.purityId?.shortName

          }
          return d
        })} />
    </div >
  );
}
export default withRouter(Sales)
