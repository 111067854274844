import gql from 'graphql-tag';

export const PARTY_SUBSCRIBE = gql`
  subscription PartyChange {
    PartyChange {
      keyType
      data{
        id
      partyId
      partyName
      partyType
      street
      road
      city
      country
      ownerName
      contactName
      restricted
      chequeClear
      pdcAllow
      zip
      mobile1
      mobile2
      phone1
      phone2
      intercom
      fax
      invMsg
      joinDate
      refName
      termId{
        id
        termName
      }
      leaveDate
      courierName
      shipmentName
      cif
      intPer
      openBal
      balType
      currency
      crLimit
      vatNo
      panNo
      tanNo
      cstNo
      gstNo
      giaAcNo
      ibanNo
      clearCode
      swiftCode
      branchName
      chipsNo
      fedwireNo
      location
      portdisch
      finalDest
      brokerPer
      commisionPer
      printName
      email
      acGroup
      acGroupName
      salesmanId
      salesmanName
      brokerId
      brokerName
      isActive
      createdBy
      createdAt
      updatedAt
      }
    }
  }
`;
