import React from "react";
import { withRouter } from "react-router-dom";
import MaterialTable from "material-table";
import moment from "moment";
import { Tofixed } from '../../../../components/Comman/Tofix'
// import _ from "lodash";

// import './planning.css'

const columns = [
  {
    title: "Grade Date",
    field: "gradDate",
    render: (rowData) => {
      let Data = rowData?.gradingDetail?.gradDate || null;
      return Data ? moment(Data).format("DD/MM/YYYY") : "";
    },
    headerStyle: {
      backgroundColor: "#00c9",
    },
  },
  {
    title: "Cts",
    field: "weight",
    render: (rowData) => Tofixed(rowData?.gradingDetail?.weight || 0),
    headerStyle: {
      backgroundColor: "#00c9",
    },
  },
  {
    title: "Clr ",
    field: "colorId",
    render: (rowData) => rowData?.gradingDetail?.colorId?.shortName || "",
    headerStyle: {
      backgroundColor: "#00c9",
    },
  },
  {
    title: "Shape ",
    field: "shapeId",
    render: (rowData) => rowData?.gradingDetail?.shapeId?.shortName || "",
    headerStyle: {
      backgroundColor: "#00c9",
    },
  },
  {
    title: "Purity ",
    field: "purityId",
    render: (rowData) => rowData?.gradingDetail?.purityId?.shortName || "",
    headerStyle: {
      backgroundColor: "#00c9",
    },
  },
  {
    title: "Cut ",
    field: "cutId",
    render: (rowData) => rowData?.gradingDetail?.cutId?.shortName || "",
    headerStyle: {
      backgroundColor: "#00c9",
    },
  },
  {
    title: "Polish ",
    field: "polishId",
    render: (rowData) => rowData?.gradingDetail?.polishId?.shortName || "",
    headerStyle: {
      backgroundColor: "#00c9",
    },
  },
  {
    title: "Symm ",
    field: "symmId",
    render: (rowData) => rowData?.gradingDetail?.symmId?.shortName || "",
    headerStyle: {
      backgroundColor: "#00c9",
    },
  },
  {
    title: "flu ",
    field: "flourenceId",
    render: (rowData) => rowData?.gradingDetail?.flourenceId?.shortName || "",
    headerStyle: {
      backgroundColor: "#00c9",
      textAlign: "center",
    },
    cellStyle: {
      minWidth: 150,
      textAlign: "center",
    },
  },
];
function Grading(props) {
  //   const [selectedRow, setSelectedRow] = useState(null);
  return (
    <MaterialTable
      title="Grading"
      columns={columns}
      tableRef={props.tableRef}
      data={props.AllData}
      isLoading={props.loading}
      options={{
        emptyRowsWhenPaging: false,
        padding: "dense",
        // pageSize: pageSize,
        pageSizeOptions: [5, 10, 15, 20, 25, 50],
        addRowPosition: "first",
        filtering: false,
        sorting: true,
        search: false,
        paging: false,
        filterCellStyle: { padding: "5px" },
        // maxBodyHeight: window.innerHeight - 215,
        rowStyle: (rowData) => ({
          backgroundColor: rowData.lotNo === "Grand Total" ? "rgb(212, 218, 255)" : "#FFF",
          fontWeight: rowData.lotNo === "Grand Total" && 'bolder'

        }),
      }}
    />
  );
}
export default withRouter(Grading);
