import React from 'react'
import { withRouter } from "react-router-dom";
import MaterialTable from 'material-table';
import moment from 'moment'
import { Tofixed } from '../../../../components/Comman/Tofix'


const columns = [

  {
    title: 'Kapan', field: 'lotNo',
    cellStyle: {
      minWidth: 150
    },
    headerStyle: {
      backgroundColor: '#039be5'
    }
  },
  {
    title: 'Purchase Date', field: 'purchaseDate',
    render: (rowData) => {
      if (rowData.purchaseDate)
        return moment(rowData?.purchaseDate || 0).format('DD/MM/YYYY')
    },
    headerStyle: {
      backgroundColor: '#039be5',
    }
  },
  {
    title: 'Purchase Party Name', field: 'partyId',
    render: (rowData) => rowData?.partyId?.partyName || "",
    headerStyle: {
      backgroundColor: '#039be5',
      minWidth: 150
    },
    cellStyle: {
      minWidth: 250
    }
  },
  {
    title: 'Pkt No', field: 'pktNo',
    cellStyle: {
      minWidth: 150
    },
    headerStyle: {
      backgroundColor: '#039be5',
    }
  },
  {
    title: 'Rough weight', field: 'roughCts',
    render: (rowData) => {
      if (rowData.lotNo === "Grand Total") {
        return rowData?.roughCts || 0
      } else
        return Tofixed((rowData?.roughCts === 0 && rowData?.polishCts === 0 && rowData?.kachuCts === 0 && rowData?.extraTops === 0) ? rowData?.salesStock?.cts || 0 : rowData?.roughCts || 0)
    },
    headerStyle: {
      backgroundColor: '#039be5',
    }
  },
  {
    title: 'Polish cts',
    field: 'polishCts',
    render: (rowData) => Tofixed(rowData?.polishCts || 0),
    headerStyle: {
      backgroundColor: '#039be5',
    }
  },
  {
    title: 'Kachu cts', field: 'kachuCts',
    render: (rowData) => Tofixed(rowData?.kachuCts || 0),
    headerStyle: {
      backgroundColor: '#039be5',
    }
  },
  {
    title: 'Extra tops', field: 'extraTops',
    render: (rowData) => Tofixed(rowData?.extraTops || 0),
    headerStyle: {
      backgroundColor: '#039be5',
    }
  },
  {
    title: 'shape', field: 'shapeId',
    render: (rowData) => rowData?.roughStock?.shapeId?.shortName || rowData?.salesStock?.shapeId?.shortName || "",
    headerStyle: {
      backgroundColor: '#039be5',
    }
  },
  {
    title: 'color', field: 'colorId',
    render: (rowData) => rowData?.roughStock?.colorId?.shortName || rowData?.salesStock?.colorId?.shortName || "",
    headerStyle: {
      backgroundColor: '#039be5',
    }
  },
  {
    title: 'purity', field: 'purityId',
    render: (rowData) => rowData?.roughStock?.purityId?.shortName || rowData?.salesStock?.purityId?.shortName || "",
    headerStyle: {
      backgroundColor: '#039be5',
    }
  },
  {
    title: 'cut', field: 'cutId',
    render: (rowData) => rowData?.roughStock?.cutId?.shortName || rowData?.salesStock?.cutId?.shortName || "",
    headerStyle: {
      backgroundColor: '#039be5',
    }
  },
  {
    title: 'polish', field: 'polishId',
    render: (rowData) => rowData?.roughStock?.polishId?.shortName || rowData?.salesStock?.polishId?.shortName || "",
    headerStyle: {
      backgroundColor: '#039be5',
    }
  },
  {
    title: 'Symm', field: 'symmId',
    render: (rowData) => rowData?.roughStock?.symmId?.shortName || rowData?.salesStock?.symmId?.shortName || "",
    headerStyle: {
      backgroundColor: '#039be5',
    }
  },
  {
    title: 'flu', field: 'fluId',
    render: (rowData) => rowData?.roughStock?.fluId?.shortName || rowData?.salesStock?.fluId?.shortName || "",
    cellStyle: {
      minWidth: 200
    },
    headerStyle: {
      backgroundColor: '#039be5',
    }
  },

]

function Rough(props) {
  return (
    <MaterialTable
      title="Rough"
      columns={columns}
      tableRef={props.tableRef}
      data={props.AllData}
      isLoading={props.loading}
      options={{
        emptyRowsWhenPaging: false,
        padding: 'dense',
        // pageSize: pageSize,
        pageSizeOptions: [5, 10, 15, 20, 25, 50],
        addRowPosition: 'first',
        filtering: false,
        sorting: true,
        search: false,
        paging: false,
        filterCellStyle: { padding: '5px', },
        rowStyle: rowData => ({
          backgroundColor: (rowData.lotNo === 'Grand Total') ? 'rgb(212, 218, 255)' : '#f5f5f5',
          fontWeight: rowData.lotNo === "Grand Total" && 'bolder'
        })
      }}
    />
  );
}
export default withRouter(Rough)
