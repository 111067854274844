import gql from 'graphql-tag';

export const MAILSETTING_SUBSCRIBE = gql`
  subscription MailSettingChange {
    MailSettingChange {
      keyType
      data{
        id
        transID
        departmentName
        mailID
        password
        isActive
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
    }
  }
`;
