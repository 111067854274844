import gql from 'graphql-tag';

const STOCKSALESOUTSTANDING = gql`
query getSalesOutStandingReport($filter: String!){
  getSalesOutStandingReport(filter:$filter){
        date
        partyId{
            partyName
        }
        billNo
        currency
        totalAmount
        netZarAmt
        remainingAmt
    }
}
`;

export default STOCKSALESOUTSTANDING

