import gql from 'graphql-tag';

export const ROUGHSTOCKQUERY = gql`
    query getRoughPlanning($page: Int, $limit: Int, $filter: String, $sort: roughPlanningSort){
      getRoughPlanning(page:$page,limit:$limit,filter:$filter,sort:$sort){
        count
        data{
          id
          isApprove
          partyId{
            id
            partyName
          }
          barcode
          lotNoId{
            id
            lotNo
          }
          pktNoId{
            id
            pktNo
          }
          planNo
          part
          orgCts
          expCts
          colorId{
              id
              shortName
          }
          shapeId{
            id
            shortName
          }
          purityId{
            id
            shortName
          }
          cutId{
            id
            shortName
          }
          polishId{
            id
            shortName
          }
          symmId{
            id
            shortName
          }
          fluId{
            id
            shortName
          }
          comment
          isMake
          createdBy{
            userName
          }
          updatedBy
          createdAt
          updatedAt

        }
      }
    }


`;
export const ROUGHSTOCKPLANNINGDATA = gql`
query getPlannigLotNoData($lotNoId: ID!, $pktNoId: ID!){
  getPlannigLotNoData(lotNoId:$lotNoId,pktNoId:$pktNoId){
    id
    orgCts
    colorId{
      id
      shortName
    }
    shapeId{
      id
            shortName
    }
    purityId{
      id
      shortName
    }
    cutId{
      id
      shortName
    }
    polishId{
      id
      shortName
    }
    symmId{
      id
      shortName
    }
    fluId{
      id
      shortName
    }
  }
}
`;
