import React, { Component } from "react";
import Header from './Header';
// import SideBar from './SideBar';

class NavBar extends Component {

  render() {

    let { session } = this.props;

    if (session?.me) {
      // const role = session.me.userType
      return (
        <div >
          <div className="clearfix"> <Header  {...this.props} /> </div>

          {/* {(role === "ADMIN") && <SideBar {...this.props} />} */}
        </div>
      )
    } else
      return (<div />)

  }
}

export default NavBar