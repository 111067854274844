import React, { useState, useEffect, useRef } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks'
import MaterialTable from 'material-table';
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import Grid from '@material-ui/core/Grid';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MuiAccordion from '@material-ui/core/Accordion';
import _ from 'lodash';
import moment from 'moment';
import PrintIcon from '@material-ui/icons/Print';
import { IssueReturnMasterChild } from './IssueReturnMasterChild'
import { IssueReturnMasterType } from '../../../components/Constant';
import { ISSUERETURNQUERY } from './IssueReturnMasterQuery'
import { ADD_ISSUERETURNMASTER, UPDATE_ISSUERETURNMASTER, DELETE_ISSUERETURNMASTER } from './IssueReturnMasterMutation'
import { CompanyAndPartySelectComponent, CompanyAndPartyAllData } from '../../../components/DropdownSelect/SelectComponents/companyAndPartySelectComponent'
import { FormatError } from '../../../components/Comman/FormatError'
import { UserListAllData } from "../../../components/DropdownSelect/SelectComponents/UserNameSelectComponent";
import { CustomFilterComponent } from '../../../components/CustomFilterComponent/index'
import { PdfInvoice } from './pdfInvoice'
import { PdfInvoiceForApproveIssue } from './pdfInvoiceForApproveIssue'
import { PrintDiv } from '../../../components/Comman/exportPdfAndExcel'
import { Tofixed } from '../../../components/Comman/Tofix'

const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1, },
  noMargin: { margin: 0 }
}));
const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const columns = [
  {
    title: 'Issue No', field: 'issueNo',
    editable: "never",
    type: "numeric"
  },
  {
    title: 'Date*', field: 'date', type: "date",
    initialEditValue: moment(),
    render: (rowData) => {
      if (rowData.date)
        return moment(rowData?.date || 0).format('DD/MM/YYYY')
    },
    validate: rowData => (rowData.date && rowData.date !== "") ? true : { isValid: false, helperText: `can't be empty` }
    , editable: "onAdd"
  },
  {
    title: 'Type*', field: 'type', lookup: IssueReturnMasterType,
    validate: rowData => (rowData.type && rowData.type !== "") ? true : { isValid: false, helperText: `can't be empty` }
    , editable: "onAdd"
  },
  {
    title: 'Party Name*', field: 'partyId', render: (rowData) => rowData?.partyId?.partyName || 'SELECT PARTY'
    ,
    editComponent: props => {
      let valueDefault = props?.rowData?.partyId?.id || 0
      return <CompanyAndPartySelectComponent QueryName={"PARTY"} valueDefault={valueDefault} prosData={props} />
    }
    , editable: "onAdd"
  },
  {
    title: 'Total PCS',
    field: 'totalPcs',
    editComponent: props => {
      return props?.rowData?.totalPcs || 0
    }
    , editable: "onAdd"
  },
  {
    title: 'Total Cts',
    field: 'totalCts',
    render: (rowData) => Tofixed(rowData?.totalCts || 0),
    editComponent: props => {
      return props?.rowData?.totalCts || 0
    }
    , editable: "onAdd"
  },
  {
    title: 'Total Amt.', field: 'totalAmt',
    render: (rowData) => Tofixed(rowData?.totalAmt || 0),
    editComponent: props => {
      return props?.rowData?.totalAmt || 0
    }
    , editable: "onAdd"
  },
  {
    title: 'Zar Amt.', field: 'amtZar',
    render: (rowData) => Tofixed(rowData?.amtZar || 0),

    editComponent: props => {
      return props?.rowData?.amtZar || 0
    }
    , editable: "onAdd"
  },
  { title: 'Remark', field: 'remark' },
  { title: "User", field: "createdBy", render: (rowData) => rowData?.createdBy?.userName || " ", editable: "never" }
]

function IssueReturnMaster(props) {
  const classes = useStyles();
  const tableRef = useRef();

  const [pageSize, setPageSize] = useState(10)
  const [selectedRow, setSelectedRow] = useState(null);

  const [selectedRowData, setSelectedRowData] = useState([]);
  const [selectedChildRowData, setselectedChildRowData] = useState([]);

  const permissions = props?.session?.me?.role?.permissions;
  const role = props?.session?.me?.role?.role;
  const path = props?.location?.pathname;
  let permission = _.find(permissions, function (o) { return o.name === path; })

  const [addIssueReturnMaster] = useMutation(ADD_ISSUERETURNMASTER)
  const [updateIssueReturnMaster] = useMutation(UPDATE_ISSUERETURNMASTER)
  const [deleteIssueReturnMaster] = useMutation(DELETE_ISSUERETURNMASTER)

  const { fetchMore, refetch } = useQuery(ISSUERETURNQUERY, {
    variables: { page: 1, limit: 10, filter: JSON.stringify({}), sort: { key: 'createdAt', type: -1 } },
    fetchPolicy: 'cache-and-network'
  })

  columns.map(d => {
    if (d.field === "partyId") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: CompanyAndPartyAllData("PARTY"), getOptionLabel: (option) => option.partyName, multiple: true }} />
      }
    }
    if (d.field === "createdBy") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: UserListAllData(), getOptionLabel: (option) => option.userName, multiple: true }} />
      }
    }
    return null
  })

  const getIssueReturnData = (query) => {
    refetch()
    return new Promise(async (resolve, reject) => {
      let filter = {}, sort = { key: 'createdAt', type: -1 }
      query && query.filters.forEach(item => {
        if ((item.column.field === "date")) {
          if (item.value)
            filter = { ...filter, [item.column.field]: moment(item.value).format("YYYY-MM-DD") }
          else {
            delete filter[item.column.field]
            filter = { ...filter }
          }
        }
        else if ((item.column.field === "currencyRate" || item.column.field === "currencyAmount" || item.column.field === "partyAmtZar" || item.column.field === "adgAmtZar")) {
          if (item.value)
            filter = { ...filter, [item.column.field]: parseFloat(item.value) }
          else {
            delete filter[item.column.field]
            filter = { ...filter }
          }
        }
        else if (item.value.length === 0) {
          delete filter[`${item.column.field}`]
          filter = { ...filter }
        } else
          filter = { ...filter, [item.column.field]: item.value }
      });
      if (query && query.orderBy && query.orderDirection !== "") {
        sort = { key: query.orderBy.field, type: query.orderDirection === 'asc' ? 1 : -1 }
      }
      setPageSize(query.pageSize)
      try {
        let result = await fetchMore({
          variables: { page: query.page + 1, limit: query.pageSize, filter: JSON.stringify(filter), sort: sort },
          fetchPolicy: 'network-only',
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return fetchMoreResult;
          }
        })
        resolve({
          data: result?.data?.getIssueReturnMaster?.data || [],
          page: query.page,
          totalCount: result?.data?.getIssueReturnMaster?.count || 0,
        })
      } catch (error) {
        console.log(FormatError(error))
      }
    })
  }

  const AddNewIssueReturn = (newData) => {
    return new Promise((resolve, reject) => {
      refetch()
      newData.totalPcs = 0
      newData.totalAmt = 0
      newData.totalCts = 0
      newData.amtZar = 0
      if (newData.partyId) {
        let log = {
          action: 'INSERT',
          actionOn: 'IssueReturnMaster',
          actionName: 'addNewIssueReturn',
          oldValue: "",
          message: `${newData?.type || ''} type Issue was created.`
        }
        addIssueReturnMaster({ variables: { input: newData, log } }).then((data) => {
          toast.success('issue return master created successfully.');
          resolve()
        }).catch((e) => {
          reject()
          toast.error(FormatError(e))
        })
      } else {
        reject()
        toast.warn('please, fill all required(*) fields.')
      }
    })
  }

  const UpdateNewIssueReturn = (newData, oldData) => {
    return new Promise((resolve, reject) => {
      refetch()
      delete newData.createdAt
      delete newData.createdBy
      delete newData.updatedAt
      delete newData.updatedBy
      delete newData.__typename
      newData.partyId = newData?.partyId?.id || null
      newData.totalPcs = 0
      newData.totalAmt = 0
      newData.totalCts = 0
      newData.amtZar = 0
      if (newData.partyId) {
        let log = {
          action: 'UPDATE',
          actionOn: 'IssueReturnMaster',
          actionName: 'updateIssueReturn',
          oldValue: JSON.stringify(oldData),
          message: `${oldData?.type || ''} type Issue was updated.`
        }
        updateIssueReturnMaster({ variables: { input: newData, log } }).then((data) => {
          toast.success('issue return master updated successfully.');
          resolve()
        }).catch((e) => {
          reject()
          toast.error(FormatError(e))
        })
      } else {
        toast.warn('please, fill all required(*) fields.')
      }
    })
  }

  const DeleteIssueReturnMaster = (oldData) => {
    return new Promise((resolve, reject) => {
      refetch()
      let log = {
        action: 'DELETE',
        actionOn: 'IssueReturnMaster',
        actionName: 'deleteIssueReturn',
        oldValue: JSON.stringify(oldData),
        message: `${oldData?.type || ''} type Issue was deleted.`
      }
      deleteIssueReturnMaster({ variables: { id: oldData.id, log } }).then((data) => {
        toast.success('issue return master deleted successfully.');
        resolve()
      }).catch((e) => {
        reject()
        toast.error(FormatError(e))
      })
    })
  }

  const addButton = (val) => {
    if (val || (role === 'admin'))
      return { onRowAdd: (newData) => AddNewIssueReturn(newData) }
    else
      return {}
  }
  // useEffect(() => {
  //   document.getElementsByClassName("parentTable")[0].firstElementChild.firstElementChild.firstChild.remove()
  // }, [])
  const PrintPdfData = () => {
    if (selectedRowData.length !== 0) {
      if (selectedRowData.length === 1) {
        if (selectedRowData[0].type === "LAB ISSUE" || selectedRowData[0].type === "LAB RETURN " || selectedRowData[0].type === "APPRO ISSUE") {
          PrintDiv()
        } else {
          toast.error('Please, Select Only Lab Issue, Lab Return or Appro Issue Print Possible OR unselect record ')
        }
      } else {
        toast.error('Sorry, Select Only One Jangad To Print! ')
      }
    } else {
      toast.error('Please, Select Jangad To Print! ')
    }
  }
  return (
    <div className={classes.root}>
      <Accordion>
        <Grid xs={12} item={true} container className="live-screen">
          <Grid item={true} xs={4} style={{ marginLeft: 10 }}>
            <PrintIcon onClick={(e) => PrintPdfData(e)} style={{ width: "50px", marginTop: 10 }} />
          </Grid>
          <Grid xs={4} item={true} style={{ textAlign: "center" }}>
            <Typography> Issue Return Master </Typography>
          </Grid>
        </Grid>
      </Accordion>
      <Grid container>
        <Grid item xs={12}>
          <div className="parentTable">
            <MaterialTable
              title="Issue Return Master"
              tableRef={tableRef}
              columns={columns}
              data={query => getIssueReturnData(query)}
              icons={{ Add: props => { return (<div> <i className="fa fa-plus-square" ></i>  </div>) } }}
              options={{
                emptyRowsWhenPaging: false,
                showTitle: false,
                padding: 'dense',
                debounceInterval: 300,
                pageSize: pageSize,
                pageSizeOptions: [5, 10, 15, 20, 25, 50],
                addRowPosition: 'first',
                filtering: true,
                sorting: true,
                search: false,
                selection: true,
                filterCellStyle: { padding: '5px', },
                maxBodyHeight: window.innerHeight - 215,
                rowStyle: rowData => ({
                  backgroundColor: (selectedRow === rowData.tableData.id) ? 'rgb(212, 218, 255)' : '#f5f5f5'
                }),
                headerStyle: { backgroundColor: 'whitesmoke' }
              }}
              editable={{
                isEditHidden: (rowData) => (role === 'admin') ? false : !permission.isUpdate,
                isDeleteHidden: (rowData) => (role === 'admin') ? false : !permission.isDelete,
                ...addButton(permission?.isInsert),
                onRowUpdate: (newData, oldData) => UpdateNewIssueReturn(newData, oldData),
                onRowDelete: (oldData) => DeleteIssueReturnMaster(oldData)
              }}
              onSelectionChange={(rows) => setSelectedRowData(rows)}
              detailPanel={rowData => {
                return (
                  <IssueReturnMasterChild
                    setSelectedRowData={(d) => setSelectedRowData(d)}
                    setselectedChildRowData={(d) => setselectedChildRowData(d)}
                    session={props?.session}
                    location={props?.location}
                    IssueReturnType={rowData.type}
                    Ids={rowData.id}
                    IssueReturnMasterData={rowData} />
                )
              }}
              onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
            />
          </div>
        </Grid>
      </Grid>
      {
        selectedRowData.length > 0 && selectedRowData[0].type !== "APPRO ISSUE" && <PdfInvoice selectedRowData={selectedRowData} selectedChildRowData={selectedChildRowData} />
      }
      {
        selectedRowData.length > 0 && selectedRowData[0].type === "APPRO ISSUE" &&
        <PdfInvoiceForApproveIssue selectedRowData={selectedRowData} selectedChildRowData={selectedChildRowData} />
      }
    </div>
  );
}

export default withRouter(IssueReturnMaster)