import React, { useState, useEffect, useRef } from 'react'
import { withRouter } from "react-router-dom";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from "@date-io/date-fns";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useQuery } from '@apollo/react-hooks'
import { FormControl } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MaterialTable from 'material-table';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import moment from 'moment'
import MenuItem from '@material-ui/core/MenuItem';
import { GETBRANCHTRANSFERREPORT } from './BranchTransferQuery';
import _ from 'lodash';
import GetAppIcon from '@material-ui/icons/GetApp';
import { LotNo } from '../../../components/DropdownSelect/LotNo';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Accordion, AccordionSummary, AccordionDetails, useStyles } from '../../../components/Comman/Accordian'
import './branch.css'
import { Tofixed } from '../../../components/Comman/Tofix'
import { CommponExport } from '../../../components/ExportReport/CommponExport'
import { ExportCsv } from '../../../components/ExportReport/ExportCsv'
import { PrintDiv } from '../../../components/Comman/exportPdfAndExcel'
import * as XLSX from 'xlsx/xlsx.mjs';
import ExcelPng from '../../../../Excel.png'

const Summarycolumns = [
  {
    title: 'Date', field: 'date', render: (rowData) => {
      if (rowData.date === "Grand Total") {
        return rowData.date
      } else
        return moment(rowData.date).format('DD/MM/YYYY')
    }
  },
  { title: 'BT No', field: 'btNo' },
  { title: 'Packet Type', field: 'pktType' },
  {
    title: 'Total Pkt', field: 'TotalPkt'
  },
  { title: 'Cts', field: 'Totalcts' },
  { title: 'Rough Cts', field: 'TotalroughCts' },
]
const Detailscolumns = [
  {
    title: 'Date', field: 'date', render: (rowData) => {
      if (rowData.date === "Grand Total") {
        return rowData.date
      } else
        return moment(rowData.date).format('DD/MM/YYYY')
    }
  },
  { title: 'BT No', field: 'btNo' },
  { title: 'Packet Type', field: 'pktType' },
  { title: 'Lot No', field: 'lotNoId.lotNo' },
  { title: 'Pkt No', field: 'pktNoId.pktNo' },
  { title: 'Cts', field: 'cts', render: (rowData) => Tofixed(rowData?.cts || 0) },
  { title: 'Rough Cts', field: 'roughCts', render: (rowData) => Tofixed(rowData?.roughCts || 0) },
]

function BranchTransfer() {

  const classes = useStyles();
  const tableRef = useRef();
  const [AllDataSummary, setAllDataSummary] = useState([])
  const [AllDataDetails, setAllDataDetails] = useState([])
  const [filter, setFilter] = useState({})
  const [type, setType] = useState('detail');
  const [invoiceType, setInvoiceType] = useState('ALL');
  const [lotNo, setLotNo] = useState('');
  const [selectedFromDate, setSelectedFromDate] = useState(moment().subtract(7, "days"));
  const [selectedToDate, setSelectedToDate] = useState(new Date());

  const { loading, data, refetch } = useQuery(GETBRANCHTRANSFERREPORT, {
    variables: { filter: JSON.stringify(filter) },
    fetchPolicy: 'cache-and-network',
  })


  useEffect(() => {
    let Data = data?.getBranchTransferReport || []
    setAllDataDetails(Data)
    let GroupBySummary = _.groupBy(data?.getBranchTransferReport || [], 'btNo')
    let SumaryData = []
    _.map(Object.keys(GroupBySummary), (btNo => {
      let newD = {}
      newD.btNo = btNo
      newD.TotalPkt = GroupBySummary[btNo].length
      newD.Totalcts = Tofixed(_.sum(_.map(GroupBySummary[btNo], 'cts')))
      newD.TotalroughCts = Tofixed(_.sum(_.map(GroupBySummary[btNo], 'roughCts')))
      GroupBySummary[btNo].map(d => {
        newD.btNo = d.btNo
        newD.date = d.date
        newD.pktType = d.pktType
        return null
      })
      SumaryData.push(newD)
    }))
    setAllDataSummary(SumaryData)
  }, [data]);

  useEffect(() => {
    let Datanew = AllDataDetails && AllDataDetails.filter(d => d.lotNo === "Total")
    if (AllDataDetails.length !== 0 && Datanew.length === 0 && type !== "summary") {
      let LastRow = {
        date: "Grand Total",
        cts: Tofixed(_.sum(_.map(AllDataDetails, 'cts'))),
        roughCts: Tofixed(_.sum(_.map(AllDataDetails, 'roughCts')))
      }
      AllDataDetails.push(LastRow)
    }
    setAllDataDetails(AllDataDetails)
  }, [AllDataDetails])

  useEffect(() => {
    let Datanew = AllDataSummary && AllDataSummary.filter(d => d.date === "Total")
    if (AllDataSummary.length !== 0 && Datanew.length === 0 && type === "summary") {
      let LastRow = {
        date: "Grand Total",
        Totalcts: Tofixed(_.sum(_.map(AllDataSummary, 'Totalcts'))),
        TotalroughCts: Tofixed(_.sum(_.map(AllDataSummary, 'TotalroughCts')))
      }
      AllDataSummary.push(LastRow)
    }
    setAllDataSummary(AllDataSummary)
  }, [AllDataSummary])

  let defaultProps = LotNo('', '', '').defaultProps

  useEffect(() => {
    let Data = JSON.parse(localStorage.getItem('BranchTransferDetailsReport') || "{}")
    let type = localStorage.getItem('BranchTransferDetailsReportType')
    if (type) {
      setType(type)
    }
    if (Data.type) {
      setInvoiceType(Data.type)
    }

    if (Data.lotNoId) {
      setLotNo(_.find(defaultProps?.options || [], (d) => d.id === Data.lotNoId))
    }

    if (Data.fromDate) {
      setSelectedFromDate(moment(Data.fromDate))
    }

    if (Data.toDate) {
      setSelectedToDate(moment(Data.toDate))
    }

    if (Object.keys(Data).length > 0)
      setFilter(Data)
  }, [])

  const PreViewHandler = () => {
    let Data = {}
    if (invoiceType) {
      Data = { ...Data, "type": invoiceType }
    }
    if (lotNo) {
      Data = { ...Data, "lotNoId": lotNo.id }
    }
    if (selectedFromDate) {
      Data = { ...Data, "fromDate": moment(selectedFromDate).format('YYYY-MM-DD') }
    }
    if (selectedToDate) {
      Data = { ...Data, "toDate": moment(selectedToDate).format('YYYY-MM-DD') }
    }
    localStorage.setItem('BranchTransferDetailsReport', JSON.stringify(Data))
    localStorage.setItem('BranchTransferDetailsReportType', type)
    setFilter(Data)
  }

  const ClearViewHandler = () => {
    setInvoiceType('')
    setLotNo('')
    setSelectedFromDate(moment().subtract(7, "days"))
    setSelectedToDate(new Date())
    setAllDataSummary([])
    setAllDataDetails([])
    localStorage.removeItem('BranchTransferDetailsReport')
    localStorage.removeItem('BranchTransferDetailsReportType')
    setFilter({}, () => {
      refetch()
    })
  }

  let TITLE = type === "summary" ? "Branch Transfer Summary Report" : "Branch Transfer Details Report"
  let COLUMN = type === "summary" ? Summarycolumns : Detailscolumns
  let DATA = type === "summary" ? AllDataSummary : AllDataDetails

  const pdfHeader = () => {
    return <div style={{ marginBottom: 15 }}>
      <h2 style={{ textAlign: "center" }}>{TITLE}</h2>

      <div style={{ display: "flex", width: "100%" }}>
        <div style={{ width: "50%" }}>
          < p style={{ margin: 0, fontSize: 18, fontWeight: "bold" }}>Lotno : {lotNo?.lotNo || "All"}</p>
        </div>

      </div>
      <div style={{ display: "flex", width: "100%" }}>
        <div style={{ width: "50%" }}>
          < p style={{ margin: 0, fontSize: 18, fontWeight: "bold" }}>Invoice Type: {invoiceType ? invoiceType : "All"}</p>
        </div>
        <div style={{ textAlign: "end", width: "50%" }}>
          < p style={{ margin: 0, fontSize: 18, fontWeight: "bold" }}>{`Date : ${moment(selectedFromDate).format('DD-MM-YYYY')} To: ${moment(selectedToDate).format('DD-MM-YYYY')}`}</p>
        </div>
      </div>
    </div>
  }
  const TableHeader = () => {
    return <>
      <tr>
        <td colSpan={COLUMN.length}>
          <h2 style={{ textAlign: "center" }}>{TITLE}</h2>
        </td>
      </tr>
      <tr >
        <td colSpan={COLUMN.length}>
          < p style={{ margin: 0, fontSize: 18, fontWeight: "bold" }}>Lotno : {lotNo?.lotNo || "All"}</p>
        </td>
      </tr>
      <tr>
        <td colSpan={COLUMN.length - 2}>
          < p style={{ margin: 0, fontSize: 18, fontWeight: "bold" }}>Invoice Type: {invoiceType ? invoiceType : "All"}</p>
        </td>
        <td colSpan={2} style={{ textAlign: "center" }}>
          < p style={{ margin: 0, fontSize: 18, fontWeight: "bold" }}>{`Date : ${moment(selectedFromDate).format('DD-MM-YYYY')} To: ${moment(selectedToDate).format('DD-MM-YYYY')}`}</p>
        </td>
      </tr>
    </>
  }

  function exportExcelFile(workbook) {
    return XLSX.writeFile(workbook, `BranchTransferReport${moment()}.xlsx`);
  }

  const exportCsvData = () => {
    var workbook = XLSX.utils.book_new();
    var worksheet_data = document.getElementById("table-to-xls");
    var worksheet = XLSX.utils.table_to_sheet(worksheet_data);
    workbook.SheetNames.push("Test");
    workbook.Sheets["Test"] = worksheet;
    exportExcelFile(workbook);
  }

  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Filters</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container justify="space-around">
            <form className={"makeStyles-root-30"} noValidate autoComplete="off">
              <FormControl className={classes.formControl} style={{ paddingTop: 8 }}>
                <Select
                  value={type}
                  onChange={(e) => {
                    refetch()
                    setType(e.target.value)
                  }}
                  displayEmpty
                  className={classes.selectEmpty}
                  inputProps={{ 'aria-label': 'Without label' }}>
                  <MenuItem value="" >
                    <span style={{ color: "#808080" }}>Report Type</span>
                  </MenuItem>
                  <MenuItem value={"detail"}>DETAIL</MenuItem>
                  <MenuItem value={"summary"}>SUMMARY</MenuItem>
                </Select>
              </FormControl>
              <FormControl className={classes.formControl} style={{ paddingTop: 8 }}>
                <Select
                  value={invoiceType}
                  onChange={(e) => setInvoiceType(e.target.value)}
                  displayEmpty
                  className={classes.selectEmpty}
                  inputProps={{ 'aria-label': 'Without label' }}>
                  <MenuItem value="" >
                    <span style={{ color: "#808080" }}>Invoice Type</span>
                  </MenuItem>
                  <MenuItem value={"ALL"}>ALL</MenuItem>
                  <MenuItem value={"FINAL POLISH"}>FINAL POLISH</MenuItem>
                  <MenuItem value={"EXTRA TOPS"}>EXTRA TOPS</MenuItem>
                  <MenuItem value={"FAILED"}>FAILED</MenuItem>
                  <MenuItem value={"KACHU"}>KACHU</MenuItem>
                </Select>
              </FormControl>
              <FormControl className={classes.formControl} style={{ paddingTop: 0 }}>
                <Autocomplete
                  value={lotNo}
                  {...defaultProps}
                  onChange={(e, newdata) => {
                    if (newdata) {
                      setLotNo(newdata)
                    }
                  }}
                  className="lotmarginCustom"
                  renderInput={(params) => <TextField label="Lot No"  {...params} margin="normal" />}
                />
              </FormControl>&nbsp;&nbsp;
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-from"
                  label="From Date"
                  format="dd/MM/yyyy"
                  value={selectedFromDate}
                  onChange={e => setSelectedFromDate(e)}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                  style={{ marginTop: 0 }}
                />&nbsp;&nbsp;&nbsp;&nbsp;
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-to"
                  label="To Date"
                  format="dd/MM/yyyy"
                  value={selectedToDate}
                  onChange={e => setSelectedToDate(e)}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                  style={{ marginTop: 0 }}
                />
              </MuiPickersUtilsProvider>
              <Button
                onClick={() => PreViewHandler()}
                variant="contained"
                color="primary"
                style={{ height: 34, marginLeft: 15, marginTop: 12 }}>
                Preview
              </Button>

              <Button
                onClick={() => ClearViewHandler()}
                variant="contained"
                color="primary"
                style={{ height: 34, marginLeft: 10, marginTop: 12 }}>
                clear
              </Button>

            </form>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Grid container>
        <Grid item xs={12} style={{ position: "relative" }}>
          <img className="download-table-xls-button" src={ExcelPng} alt="alternative text" title="csv" onClick={() => exportCsvData()} />
          <ExportCsv
            TableHeader={TableHeader()}
            columns={COLUMN}
            AllData={DATA.filter(d => {
              if (d.date !== "Grand Total") {
                d["pktNoId.pktNo"] = d?.pktNoId?.pktNo
                d["lotNoId.lotNo"] = d?.lotNoId?.lotNo
              }
              return d
            })}

          />
          <MaterialTable
            title={TITLE}
            columns={COLUMN}
            isLoading={loading}
            tableRef={tableRef}
            data={DATA}
            options={{
              emptyRowsWhenPaging: false,
              padding: 'dense',
              paging: false,
              addRowPosition: 'first',
              filtering: true,
              sorting: true,
              search: false,
              filterCellStyle: { padding: '5px', },
              maxBodyHeight: window.innerHeight - 215,
              rowStyle: rowData => ({
                backgroundColor: (rowData.date === 'Grand Total') ? 'rgb(212, 218, 255)' : '#f5f5f5',
                fontWeight: (rowData.date === "Grand Total" || rowData.currency === "Grand Total") && 'bolder'
              }),
              headerStyle: { backgroundColor: 'whitesmoke' }
            }}
            actions={[
              {
                icon: 'print',
                tooltip: 'PRINT',
                isFreeAction: true,
                onClick: (event) => PrintDiv()
              },
            ]}
          />

        </Grid>
      </Grid>
      <CommponExport
        Title={TITLE}
        columns={COLUMN}
        pdfHeader={pdfHeader()}
        AllData={DATA.filter(d => {
          if (d.date !== "Grand Total") {
            d["pktNoId.pktNo"] = d?.pktNoId?.pktNo
            d["lotNoId.lotNo"] = d?.lotNoId?.lotNo
          }
          return d
        })} />
    </div>

  );
}
export default withRouter(BranchTransfer)
