import gql from 'graphql-tag';

export const KAPANSUMMARY = gql`
query getKapanMasterSummary($filter:String,$sort: kapanMasterSort){
  getKapanMasterSummary(filter:$filter,sort:$sort){
    count
    data{
   id
     roughId
     billNo
     date
     partyId{
       id
       partyName
     }
     lotNoList
     remainCts
     totalCts
     netCost
     purType
     amountZar
     createdBy{
      userName
     }
     updatedBy
     createdAt
     updatedAt
}
    summary
  }
}
`;

export const KAPAN = gql`
query getKapanMaster($page: Int, $limit: Int, $filter: String, $sort: kapanMasterSort){
  getKapanMaster(page:$page, limit:$limit, filter:$filter, sort:$sort){
   count
   data{
     id
     roughId
     billNo
     date
     partyId{
       id
       partyName
     }
     lotNoList
     remainCts
     totalCts
     netCost
     purType
     amountZar
     createdBy{
      userName
     }
     updatedBy
     createdAt
     updatedAt
   }
 }
 }
`;
// export const kAPANDETAIL = gql`
// query getAllKapanDetail{
//   getAllKapanDetail{
//     id
//     kapanMasterId
//     srNo
//     lotNo
//     pcs
//     cts
//     avgCts
//     rate
//     amount
//     labourRate
//     labourAmount
//     expense
//     netAmount
//     rateZar
//     amountZar
//     createdBy
//     updatedBy
//     createdAt
//     updatedAt
//   }
// }`;

export const KAPANDETAIL = gql`
query getAllKapanDetailForSelf($id: ID){
  getAllKapanDetailForSelf(id:$id){
    id
    kapanMasterId
    srNo
    lotNo
    pcs
    cts
    avgCts
    rate
    amount
    labourRate
    labourAmount
    expense
    netAmount
    rateZar
    amountZar
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}
`;

