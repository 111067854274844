import { useQuery } from '@apollo/react-hooks'
import { GETROUGHSTOCKFORINWARDDETAIL } from '../../pages/Transaction/Inward/InwordQuery';
export const GetRoughStockForInwardDetail = (lotNo, pktNo) => {
  const { data } = useQuery(GETROUGHSTOCKFORINWARDDETAIL, {
    variables: { lotNoId: lotNo, pktNoId: pktNo },
    fetchPolicy: 'cache-and-network',
  })
  let Data = data?.getRoughStockForInwardDetail || []
  return Data
}

