
import gql from 'graphql-tag';

export const GETSALESSTOCK = gql`
  query getSalesStock{
    getSalesStock{
      id
      lotNoId{
        id
        lotNo
      }
      pktNoId{
        id
        pktNo
      }
      location 
      cts
      colorId{
        id
        shortName
      }
      shapeId{
        id
        shortName
      }
      cutId{
        id
        shortName
      }
      polishId{
        id
        shortName
      }
      purityId{
        id
        shortName
      }
      symmId{
        id
        shortName
      }
      fluId{
        id
        shortName
      }
      status
      isCertified
      issuePartyId{
        id
        partyName
      }
    }
  }
`;

export const GROUPBY_LOCATION_WISE = gql`
query getSalesStockForGroupByLocationName{
  getSalesStockForGroupByLocationName{
    location
    status
    TotalPkt
    TotalCts
  }
}
`;
export const GROUPBY_CERTIFIED = gql`
query getSalesStockForGroupByCertified{
  getSalesStockForGroupByCertified{ 
    TotalPkt
    isCertified
    TotalCts
  }  
}
`;
export const GROUPBY_PARTY_NAME = gql`
    query getSalesStockForGroupByPartyName{
      getSalesStockForGroupByPartyName{
        issuePartyId{
          id
          partyName
        }
        TotalPkt
        TotalCts
      }
    }
`;
export const GROUPBY_DEPARTMENT_NAME = gql`
    query getSalesStockForGroupByDepartmentName{
      getSalesStockForGroupByDepartmentName{
        status
        TotalPkt
        TotalCts
      }
    }
`;
export const GROUPBY_LOTNO_NAME = gql`
    query getSalesStockForGroupByLotNoName($filter: String){
      getSalesStockForGroupByLotNoName(filter:$filter){
        lotNoId{
          id
          lotNo
        }
        TotalPkt
        TotalCts
      }
    }
`;

export const GET_SALES_FILTER_DATA = gql`
    query getSalesFilterData($filter: String){
      getSalesFilterData(filter:$filter){
        id
        lotNoId{
          id 
          lotNo
        }
        pktNoId{
          id
          pktNo
        }
        cts
        colorId{
          id
          shortName
        }
        shapeId{
          id
          shortName
        }
        cutId{
          id
          shortName
        }
        polishId{
          id
          shortName
        }
        purityId{
          id
          shortName
        }
        symmId{
          id
          shortName
        }
        fluId{
          id
          shortName
        }
      }
    }
`;