import React, { useState, useRef, useEffect } from 'react'
import MaterialTable from 'material-table';
import { useQuery } from '@apollo/react-hooks'
import { GETBRANCHTRANSFERMASTER } from './Query'
import { UserListAllData } from "../../../components/DropdownSelect/SelectComponents/UserNameSelectComponent";
import moment from 'moment'
import { FormatError } from "../../../components/Comman/FormatError";
import { CustomFilterComponent } from '../../../components/CustomFilterComponent/index'
import './style.css'
const columns = [
  { title: 'BT No', field: 'btNo' },
  {
    title: 'Trans Date', field: 'transDate', type: "date",
    render: (rowData) => {
      if (rowData.transDate)
        return moment(rowData?.transDate || 0).format('DD/MM/YYYY')
    }
  },
  {
    title: 'Packet Type', field: 'pktType',
    // lookup: packetTypeForBranchTransfer
  },
  {
    title: 'Department Name', field: 'deptName', render: (rowData) => rowData?.deptName?.deptName || "-",
    // lookup: {
    //   "SALES": "SALES", "STOCK": "STOCK"
    // }
  },
  { title: 'Remark', field: 'remark' },
  { title: "User", field: "createdBy", render: (rowData) => rowData?.createdBy?.userName || " ", editable: "never" }
]
export const MasterView = (props) => {
  const tableRef = useRef();
  const [pageSize, setPageSize] = useState(10)

  const {  fetchMore , refetch } = useQuery(GETBRANCHTRANSFERMASTER, {
    variables: { page: 1, limit: 10, filter: JSON.stringify({}), sort: { key: 'createdAt', type: -1 } },
    fetchPolicy: 'cache-and-network',
  })
  columns.map(d => {
    if (d.field === "createdBy") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{
          options: UserListAllData(),
          getOptionLabel: (option) => option.userName,
          multiple: true
        }} />
      }
    }
    return null
  })

  const GetBranchMasterData = (query) => {
    refetch();
    return new Promise(async (resolve, reject) => {
      let filter = {}, sort = { key: 'createdAt', type: -1 }
      query && query.filters.forEach(item => {
        if (item.column.field === "transDate") {
          if (item.value)
            filter = { ...filter, [item.column.field]: moment(item.value).format('YYYY-MM-DD') }
          else {
            delete filter[`${item.column.field}`]
            filter = { ...filter }
          }
        } else if (item.value.length === 0) {
          delete filter[`${item.column.field}`]
          filter = { ...filter }
        } else {
          filter = { ...filter, [item.column.field]: item.value }
        }
      });
      if (query && query.orderBy && query.orderDirection !== "") {
        sort = { key: query.orderBy.field, type: query.orderDirection === 'asc' ? 1 : -1 }
      }
      setPageSize(query.pageSize)
      try {
        let result = await fetchMore({
          variables: { page: query.page + 1, limit: query.pageSize, filter: JSON.stringify(filter), sort: sort },
          fetchPolicy: 'network-only',
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return fetchMoreResult;
          }
        })
        resolve({
          data: result?.data?.getBranchTransMaster?.data || [],
          page: query.page,
          totalCount: result?.data?.getBranchTransMaster?.count || 0,
        })
      } catch (error) {
        console.log(FormatError(error))
      }
    })
  };

  return <div className="mainMasterView">
    <MaterialTable
      title="Packet"
      tableRef={tableRef}
      columns={columns}
      data={query => GetBranchMasterData(query)}
      options={{
        emptyRowsWhenPaging: false,
        showTitle: false,
        debounceInterval: 300,
        padding: 'dense',
        pageSize: pageSize,
        pageSizeOptions: [5, 10, 15, 20, 25, 50],
        addRowPosition: 'first',
        filtering: true,
        sorting: true,
        search: false,
        filterCellStyle: { padding: '5px', },
        maxBodyHeight: window.innerHeight - 215,
        headerStyle: { backgroundColor: 'whitesmoke' }
      }}
      onRowClick={((evt, selectedRow) => {
        props.closeAccordian(false)
        props.MasterSelectedData(selectedRow)
      })}
    />
  </div>
}