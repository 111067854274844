import gql from 'graphql-tag';

export const ADD_TERMS = gql`
    mutation addNewTerm($input:termMasterInput!,$log: LogInput){
      addNewTerm(input:$input,log: $log){
        id
      TERMID
        termName
        Days
        alertDays
        isActive
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
    }
`;

export const UPDATE_TERMS = gql`
    mutation updateTerm($input:termUpdate!,$log: LogInput){
      updateTerm(input:$input,log: $log){
        id
      TERMID
        termName
        Days
        alertDays
        isActive
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
    }
`;

export const DELETE_TERMS = gql`
  mutation deleteTerm( $id: ID!,$log: LogInput) {
    deleteTerm(id:$id,log: $log)
  }
`;
