import gql from 'graphql-tag';


export const GET_SALES_STOCK_DATA_FOR_RETURN_MODAL = gql`
query getSalesStockForIssueData($status: String! $partyId: ID) {
  getSalesStockForIssueData(status: $status partyId: $partyId) {
    id
    location
    rfId
  pktNoId{
    id
    pktNo
  }
    lab {
      id
      partyName
    }
    certificateId
    cts
    colorId {
      id
      shortName
    }
    shapeId {
      id
      shortName
    }
    cutId {
      id
      shortName
    }
    polishId {
      id
      shortName
    }
    symmId {
      id
      shortName
    }
    fluId {
      id
      shortName
    }
    lab{
      id
      partyName
    }
    purityId {
      id
      shortName
    }
    polishId {
      id
      shortName
    }
  lotNoId{
    id
    lotNo
  }
  certificateId
    measurment
    brown
    rapRate
    stkPer
    rapAmount
    gradPer    
    depth 
  }
}
`;


export const ISSUERETURNQUERY = gql`
query getIssueReturnMaster($page: Int, $limit: Int, $filter: String, $sort: issueReturnMasterSort){
    getIssueReturnMaster(page: $page, limit: $limit, filter: $filter, sort: $sort){
      count
      data{
      id
      issueNo
      date
      type
      partyId{
        id
        partyName
      }
      totalPcs
      totalCts
      totalAmt
      amtZar
      remark
      createdBy{
        userName
      }
      updatedBy
      createdAt
      updatedAt
    }
    }
  }
`;
export const ISSUERETURNDETAILQUERY = gql`
query getAllIssueReturnDetail($id: ID) {
  getAllIssueReturnDetail(id:$id){
    id
    issueReturnMasterId
    location
    barcode
    lotNoId{
      id
      lotNo
    }
    pktNoId{
      id
      pktNo
    }
    labId{
      id
      partyName
    }
    certificateNo
    rfId
    cts
    colorId{
      id
      shortName
    }
    shapeId{
      id
      shortName
    }
    purityId{
      id
      shortName
    }
    cutId{
      id
      shortName
    }
    polishId{
      id
      shortName
    }
    symmId{
      id
      shortName
    }
    fluId{
      id
      shortName
    }
    measurment
    depth
    table
    gradePer
    rateRap
    stkDiscPer
    rateAfterDisc
    rapAmt
    rateZar
    amtZar
    isRoughStock
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}
`;

export const ROUGHSTOCKFROMLOTANDPKT = gql`
query getRoughStockFromLotandPkt($status:String,$lotNoId: ID,$pktNoId: ID,$issuePartyId:ID){
  getRoughStockFromLotandPkt(status:$status,lotNoId:$lotNoId,pktNoId:$pktNoId,issuePartyId:$issuePartyId){
    id
    rfId
    lab {
      id
      partyName
    }
    lotNoId{
      id
      lotNo
    }
    pktNoId{
      id
      pktNo
    }
    issueCts
    colorId{
      id
      shortName
    }
    shapeId{
      id
      shortName
    }
    purityId{
      id
      shortName
    }
    cutId{
      id
      shortName
    }
    polishId{
      id
      shortName
    }
    symmId{
      id
      shortName
    }
    fluId{
      id
      shortName
    }
    partyId
    rfId
    location
  }
}
`