import gql from 'graphql-tag';

export const RAPAPORT_SUBSCRIBE = gql`
  subscription RapaportUserChange {
    RapaportUserChange {
      keyType
      data{
        id
        rapaportUsername
        password
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
    }
  }
`;
