import gql from 'graphql-tag';

const BANKDETAIL = gql`
  query getBankDetail($page: Int, $limit: Int, $filter: String, $sort: bankDetailSort){
    getBankDetail(page:$page, limit:$limit, filter:$filter, sort:$sort){
      count
      data{
        id
        bankID
        accNo
        bankName
        branchCode
        detOf
        dolAccNO
        narration
        partyID{
          id
          partyName
        }
        companyID{
          id
          companyName
        }
        swiftCode
        isActive
        createdBy
              updatedBy
        createdAt
        updatedAt
    }
  }
}

`;

export default BANKDETAIL

