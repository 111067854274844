import gql from 'graphql-tag';

export const ROUGHSUMMARY = gql`
query getRoughMasterSummary($filter:String,$sort: roughMasterSort){
  getRoughMasterSummary(filter:$filter,sort:$sort){
    count
    data{
     id
      billNo
      date
      partyId{
        id
        partyName
      }
      brokerId{
        id
        partyName
      }
      dueDays
      dueDate
      totalCts
      totalCost
      totalZarAmt
      totalPcs
      brokerPer
      brokerAmount
      exportExpense
      netCost
      purType
      createdBy{
        userName
      }
      updatedBy
      createdAt
      updatedAt
    }
    summary    
  }
}
`;
export const ROUGH = gql`
query getRoughMaster($page: Int, $limit: Int, $filter: String, $sort: roughMasterSort){
  getRoughMaster(page:$page, limit:$limit, filter:$filter, sort:$sort){
    count
    data{
      id
      billNo
      date
      partyId{
        id
        partyName
      }
      brokerId{
        id
        partyName
      }
      dueDays
      dueDate
      totalCts
      totalCost
      totalZarAmt
      totalPcs
      brokerPer
      brokerAmount
      exportExpense
      netCost
      purType
      createdBy{
        userName
      }
      updatedBy
      createdAt
      updatedAt
    }
  }
}
`;
export const ROUGHDETAIL = gql`
query getAllRoughDetail($id: ID!) {
  getAllRoughDetail(id:$id) {
    id
    srNo
    cts
    roughMasterId
    rate
    roughAmountDollar
    rateZar
    amountZar
    createdBy
    updatedBy
    createdAt
    updatedAt
}
}

`;

