import { useQuery } from '@apollo/react-hooks'
import { GETUSERROLEFORLOOKUP } from '../../../pages/Master/Users/UserQuery';
import { FormatError } from '../../Comman/FormatError'
export const UserRoleListData = () => {
  const { error, data } = useQuery(GETUSERROLEFORLOOKUP, {
    fetchPolicy: 'cache-and-network',
  })
  if (error)
    console.log(FormatError(error));
  let Termsnewdata = data?.getRolseForLookup || []
  return Termsnewdata
}

export const UserRoleLookup = () => {
  let newtermsdata = {}
  UserRoleListData().map((d, index) => {
    newtermsdata[d.id] = d.role
    return null
  })
  return newtermsdata
}