import React, { useState, useEffect, useRef } from 'react'
import { withRouter } from "react-router-dom";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from "@date-io/date-fns";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useLazyQuery } from '@apollo/react-hooks'
import Grid from '@material-ui/core/Grid';
import MaterialTable from 'material-table';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import moment from 'moment'
import { GETJOBWORKREPORT } from './JobWorkQuery';
import _ from 'lodash'
import { LotNo } from '../../../components/DropdownSelect/LotNo';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Tofixed } from '../../../components/Comman/Tofix';
import './jobwork.css'
import { CommponExport } from '../../../components/ExportReport/CommponExport'
import GetAppIcon from '@material-ui/icons/GetApp';
import { ExportCsv } from '../../../components/ExportReport/ExportCsv'
import { PrintDiv } from '../../../components/Comman/exportPdfAndExcel'
import * as XLSX from 'xlsx/xlsx.mjs';
import ExcelPng from '../../../../Excel.png'
import { Accordion, AccordionSummary, AccordionDetails, useStyles } from '../../../components/Comman/Accordian'

const columns = [
  {
    title: 'Date', field: 'purDate', render: (rowData) => {
      if (rowData.purDate === "Grand Total") { return rowData.purDate }
      else { return moment(rowData.purDate).format('DD/MM/YYYY') }
    }
  },
  { title: 'Lot No', field: 'lotNo' },
  { title: 'Pkt No', field: 'pktNo' },
  {
    title: 'Cts',
    field: 'pktCts',
    render: (rowData) => Tofixed(rowData?.pktCts || 0)
  },
  // {
  //   title: 'Helium Chg $/Cts',
  //   field: 'heliumCharge',
  //   render: (rowData) => rowData?.heliumCharge ? Tofixed(rowData?.heliumCharge || 0) : ""
  // },
  // {
  //   title: 'Helium $ Amt',
  //   field: 'heliumAmt',
  //   render: (rowData) => Tofixed(rowData?.heliumAmt)
  // },
  {
    title: 'Mfg Chg $/Cts',
    field: 'majuri',
    render: (rowData) => rowData?.majuri ? Tofixed(rowData?.majuri) : ""
  },
  {
    title: 'Mfg $ Amt',
    field: 'mfgAmt',
    render: (rowData) => Tofixed(rowData.mfgAmt)
  },
  {
    title: 'Total $ Amt',
    field: 'totalDolAmt',
    render: (rowData) => Tofixed(rowData?.totalDolAmt)
  },
  {
    title: 'Rate',
    field: 'rate',
    render: (rowData) => rowData?.rate ? Tofixed(rowData?.rate) : ""
  },
  {
    title: 'Total Zar Amt',
    field: 'totalAmtZar',
    render: (rowData) => Tofixed(rowData?.totalAmtZar)
  },
  {
    title: 'Laser Cost',
    field: 'laserCost',
    render: (rowData) => Tofixed(rowData.laserCost)
  },
  {
    title: 'Helium/Galexy Cost',
    field: 'heliumCost',
    render: (rowData) => Tofixed(rowData.heliumCost)
  },
  {
    title: 'Certificate cost',
    field: 'certiCost',
    render: (rowData) => Tofixed(rowData.certiCost)
  },
  {
    title: 'Total Cost',
    field: 'totalCost',
    render: (rowData) => Tofixed(rowData.totalCost)
  },
]


function JobWork() {
  const classes = useStyles();
  const tableRef = useRef();
  const [filter, setFilter] = useState({})
  const [lotNo, setLotNo] = useState('');
  const [AllData, setAllData] = useState([]);
  const [data1, setData] = useState([]);

  const [selectedFromDate, setSelectedFromDate] = useState();

  const [selectedToDate, setSelectedToDate] = useState();

  const [loadGreeting, { called, loading, error, refetch, fetchMore }] = useLazyQuery(GETJOBWORKREPORT, {
    variables: { filter: JSON.stringify(filter) },
    fetchPolicy: 'network-only',
  }
  );


  useEffect(() => {
    tableRef.current && tableRef.current.onQueryChange()
  }, [data1]);

  const getLaserCount = () => {
    let notSubpacketFalseData = _.orderBy(_.filter((data1?.getJobWorkReport || []), (d) => !d.isSubPkt), 'pktNo', "asc")
    // let newArray = _.map(notSubpacketFalseData, (d) => {
    //   let lasercount = _.filter(data1?.getJobWorkReport || [], (d1) => (`${d1.pktNo}`).charAt(0) === d.pktNo)
    //   d.laserCost = _.sumBy(lasercount, (d) => d.laserCost)
    //   return d
    // })
    return notSubpacketFalseData
  }


  const getStockMasterAllData = () => {
    return new Promise((resolve, reject) => {
      if (error) {
        reject(error)
      }
      else {
        let result = []
        let a = getLaserCount()
        result = _.map(a, (d => {
          d.totalAmtZar = Tofixed((d.mfgAmt) * (d.rate))
          d.totalDolAmt = d?.mfgAmt || 0
          d.totalCost = Tofixed(parseFloat(d.totalAmtZar) + (d.certiCost) + (d.heliumCost) + (d.laserCost))
          return d
        }))

        let GetGrandTotalData = result.filter(d => d.purDate === "Grand Total")
        if (result.length !== 0 && GetGrandTotalData.length === 0) {
          let LastRow = {
            purDate: "Grand Total",
            pktCts: Tofixed(_.sum(_.map(result, 'pktCts'))),
            heliumAmt: Tofixed(_.sum(_.map(result, 'heliumAmt'))),
            mfgAmt: Tofixed(_.sum(_.map(result, 'mfgAmt'))),
            totalDolAmt: Tofixed(_.sum(_.map(result, 'mfgAmt'))),
            totalAmtZar: Tofixed(_.sum(_.map(result, (d) => parseFloat(d.totalAmtZar)))),
            certiCost: Tofixed(_.sum(_.map(result, 'certiCost'))),
            heliumCost: Tofixed(_.sum(_.map(result, 'heliumCost'))),
            laserCost: Tofixed(_.sum(_.map(result, 'laserCost'))),
            totalCost: Tofixed(_.sum(_.map(result, (d) => parseFloat(d.totalCost)))),
          }
          result.push(LastRow)
        }
        // }
        setAllData(result)
        resolve({
          data: result,
        })
      }
    })
  }


  useEffect(() => {
    loadGreeting()
  }, [])

  const PreViewHandler = async () => {
    let Data = {}
    if (lotNo) {
      Data = { ...Data, "lotNoId": lotNo.id }
      if (selectedFromDate) {
      }
      Data = { ...Data, "fromDate": moment(selectedFromDate).format('YYYY-MM-DD') }
    }
    if (selectedToDate) {
      Data = { ...Data, "toDate": moment(selectedToDate).format('YYYY-MM-DD') }
    }
    localStorage.setItem("JobWorkReport", JSON.stringify(Data))

    fetchMore({
      variables: { filter: JSON.stringify(Data) },
      fetchPolicy: 'network-only',
      updateQuery: (previousResult, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return previousResult;
        }
        return previousResult;
      },
    }).then((data) => {
      setData({ ...data?.data })
    })
    setFilter(Data)
  }

  const ClearViewHandler = () => {
    setLotNo('')
    setSelectedFromDate(moment().subtract(7, "days"))
    setSelectedToDate(new Date())
    setAllData([])
    localStorage.removeItem('JobWorkReport')
    setFilter({}, () => {
      refetch()
    })
  }

  const pdfHeader = () => {
    return <div style={{ marginBottom: 15 }}>
      <h2 style={{ textAlign: "center" }}>Job Work Report</h2>
      <div style={{ display: "flex", width: "100%" }}>
        <div style={{ width: "50%" }}>
          < p style={{ margin: 0, fontSize: 18, fontWeight: "bold" }}>Lotno : {lotNo?.lotNo || "All"}</p>
        </div>
        <div style={{ textAlign: "end", width: "50%" }}>
          < p style={{ margin: 0, fontSize: 18, fontWeight: "bold" }}>{`Date : ${moment(selectedFromDate).format('DD-MM-YYYY')} To: ${moment(selectedToDate).format('DD-MM-YYYY')}`}</p>
        </div>
      </div>

    </div>
  }

  const TableHeader = () => {
    return <>
      <tr>
        <td colSpan={columns.length}>
          <h2 style={{ textAlign: "center" }}>Job Work Report</h2>
        </td>
      </tr>
      <tr >
        <td colSpan={columns.length - 2}>
          < p style={{ margin: 0, fontSize: 18, fontWeight: "bold" }}>Lotno : {lotNo?.lotNo || "All"}</p>
        </td>
        <td colSpan={2} style={{ textAlign: "end" }}>
          < p style={{ margin: 0, fontSize: 18, fontWeight: "bold" }}>{`Date : ${moment(selectedFromDate).format('DD-MM-YYYY')} To: ${moment(selectedToDate).format('DD-MM-YYYY')}`}</p>
        </td>
      </tr>
    </>
  }
  function exportExcelFile(workbook) {
    return XLSX.writeFile(workbook, `JobWorkReport${moment()}.xlsx`);
  }

  const exportCsvData = () => {
    var workbook = XLSX.utils.book_new();
    var worksheet_data = document.getElementById("table-to-xls");
    var worksheet = XLSX.utils.table_to_sheet(worksheet_data);
    workbook.SheetNames.push("Test");
    workbook.Sheets["Test"] = worksheet;
    exportExcelFile(workbook);
  }

  let defaultProps = LotNo('', '', '').defaultProps
  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Filters</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid xs={12} item container>
              <Grid xs={4} item>
              </Grid>
              <Grid xs={6} item container>
                <Grid xs={2} item>
                  <Autocomplete
                    {...defaultProps}
                    onChange={(e, newdata) => {
                      if (newdata) {
                        setLotNo(newdata)
                      }
                    }}
                    renderInput={(params) => <TextField label="Lot No"  {...params} margin="normal" />}
                  />
                </Grid>
                {/* <Grid xs={2} item style={{ marginLeft: 10 }}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-from"
                    label="From Date"
                    format="dd/MM/yyyy"
                    value={selectedFromDate}
                    onChange={e => setSelectedFromDate(e)}
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                    style={{ marginTop: 16 }}
                  />
                </Grid>
                <Grid xs={2} item style={{ marginLeft: 10 }}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-to"
                    label="To Date"
                    format="dd/MM/yyyy"
                    value={selectedToDate}
                    onChange={e => setSelectedToDate(e)}
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                    style={{ marginTop: 16 }}
                  />
                </Grid> */}
                <Grid xs={4} item style={{ marginLeft: 10 }}>
                  <Button
                    onClick={() => PreViewHandler()}
                    variant="contained"
                    color="primary"
                    style={{ marginLeft: 15, marginTop: 20 }}>
                    Preview
                  </Button>
                  <Button
                    onClick={() => ClearViewHandler()}
                    variant="contained"
                    color="primary"
                    style={{ height: 34, marginLeft: 10, marginTop: 12 }}>
                    clear
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        </AccordionDetails>
      </Accordion>

      <Grid container>
        <Grid item xs={12} style={{ position: "relative" }}>
          <img className="download-table-xls-button" src={ExcelPng} alt="alternative text" title="csv" onClick={() => exportCsvData()} />
          <ExportCsv
            TableHeader={TableHeader()}
            columns={columns}
            AllData={AllData}
          />
          <MaterialTable
            title="Job Work Report"
            columns={columns}
            isLoading={loading}
            tableRef={tableRef}
            data={query => getStockMasterAllData()}
            actions={[
              {
                icon: 'print',
                tooltip: 'PRINT',
                isFreeAction: true,
                onClick: (event) => PrintDiv()
              },
              // {
              //   icon: GetAppIcon,
              //   tooltip: 'EXPORT',
              //   isFreeAction: true,
              //   onClick: (e) => exportTableToExcel("ExportTable", `Job_Work_Report_${moment()}`, e)
              // }
            ]}
            options={{
              emptyRowsWhenPaging: false,
              padding: 'dense',
              addRowPosition: 'first',
              filtering: false,
              sorting: true,
              search: false,
              paging: false,
              filterCellStyle: { padding: '5px', },
              maxBodyHeight: window.innerHeight - 215,
              rowStyle: rowData => ({
                backgroundColor: (rowData.purDate === "Grand Total") ? 'rgb(212, 218, 255)' : '#f5f5f5',
                fontWeight: (rowData.purDate === "Grand Total" || rowData.currency === "Grand Total") && 'bolder'
              }),
              headerStyle: { backgroundColor: 'whitesmoke' }
            }}
          />
        </Grid>
      </Grid>
      <CommponExport
        columns={columns}
        pdfHeader={pdfHeader()}
        AllData={AllData} />
    </div>

  );
}
export default withRouter(JobWork)
