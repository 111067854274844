import React from 'react'
import { withRouter } from "react-router-dom";
import MaterialTable from 'material-table';
import moment from 'moment'
import { Tofixed } from '../../../../components/Comman/Tofix'

const columns = [

  {
    title: 'Type', field: 'type',
    render: (rowData) => rowData?.salesDetail?.type || "",
    headerStyle: {
      backgroundColor: '#896c',
    }
  },
  {
    title: 'Rape Price', field: 'rapRate',
    render: (rowData) => rowData?.salesDetail?.rapRate ? Tofixed(rowData?.salesDetail?.rapRate || 0) : "",
    headerStyle: {
      backgroundColor: '#896c',
    }
  },
  {
    title: 'Back %', field: 'diffPer',
    render: (rowData) => {
      if (rowData.lotNo !== "Grand Total") {
        return rowData?.salesDetail?.diffPer || 0
      } else
        return ""
    },
    headerStyle: {
      backgroundColor: '#896c',
    }
  },
  {
    title: 'Amount($)', field: 'netRaprateAmt',
    render: (rowData) => Tofixed(rowData?.salesDetail?.netRaprateAmt || 0),
    headerStyle: {
      backgroundColor: '#896c',
    }
  },
  {
    title: 'Rate', field: 'rateZar',
    render: (rowData) => rowData?.salesDetail?.rateZar ? Tofixed(rowData?.salesDetail?.rateZar || 0) : 0,

    headerStyle: {
      backgroundColor: '#896c',
    }
  },
  {
    title: 'Amount(Zar)', field: 'amountZar',
    render: (rowData) => Tofixed(rowData?.salesDetail?.amountZar || 0),

    headerStyle: {
      backgroundColor: '#896c',
    }
  },
  {
    title: 'Comm.%', field: 'commisionPer',
    render: (rowData) => rowData?.salesDetail?.commisionPer || 0,
    headerStyle: {
      backgroundColor: '#896c',
    }
  },
  {
    title: 'Comm. amount', field: 'commision',
    render: (rowData) => rowData?.salesDetail?.commision ? Tofixed(rowData?.salesDetail?.commision || 0) : 0,
    headerStyle: {
      backgroundColor: '#896c',
    }
  },
  {
    title: 'Polish EXP.%', field: 'polishExpPer',
    render: (rowData) => rowData?.salesDetail?.polishExpPer || 0,
    headerStyle: {
      backgroundColor: '#896c',
    }
  },
  {
    title: 'Polish EXP. Amount', field: 'polishExpAmt',
    render: (rowData) => rowData?.salesDetail?.polishExpAmt ? Tofixed(rowData?.salesDetail?.polishExpAmt || 0) : 0,
    headerStyle: {
      backgroundColor: '#896c',
    },
    cellStyle: {
      minWidth: 150
    }
  },
  {
    title: 'Total Amount', field: 'netAmtZar',
    render: (rowData) => Tofixed(rowData?.salesDetail?.netAmtZar || 0),
    headerStyle: {
      backgroundColor: '#896c',
    }
  },
  {
    title: 'Party Name', field: 'partyName',
    render: (rowData) => rowData?.salesDetail?.partyName?.partyName || "",
    headerStyle: {
      backgroundColor: '#896c',
    },
    cellStyle: {
      minWidth: 250
    }
  },
  {
    title: 'Bill Date', field: 'billDate',
    render: (rowData) => {
      let date = rowData?.salesDetail?.billDate || null
      if (date)
        return moment(date || 0).format('DD/MM/YYYY')
    },
    headerStyle: {
      backgroundColor: '#896c',
    }
  },
  {
    title: 'Bill No', field: 'billNo',
    render: (rowData) => rowData?.salesDetail?.billNo || "",
    headerStyle: {
      backgroundColor: '#896c',
    }
  }
]
function Sales(props) {
  //   const [selectedRow, setSelectedRow] = useState(null);
  return (

    <MaterialTable
      title="Sales"
      columns={columns}
      tableRef={props.tableRef}
      data={props.AllData}
      isLoading={props.loading}
      options={{
        emptyRowsWhenPaging: false,
        padding: 'dense',
        // pageSize: pageSize,
        pageSizeOptions: [5, 10, 15, 20, 25, 50],
        addRowPosition: 'first',
        filtering: false,
        sorting: true,
        search: false,
        paging: false,
        filterCellStyle: { padding: '5px', },
        rowStyle: rowData => ({
          backgroundColor: (rowData.lotNo === 'Grand Total') ? 'rgb(212, 218, 255)' : '#f5f5f5',
          fontWeight: rowData.lotNo === "Grand Total" && 'bolder'
        })
      }}
    />
  );
}
export default withRouter(Sales)
