import React, { useEffect, useRef, useState } from 'react';
import MaterialTable from 'material-table';
import { withRouter } from "react-router-dom";
import { Grid } from '@material-ui/core';
import { useQuery, useMutation } from '@apollo/react-hooks'
import { KAPAN, KAPANSUMMARY } from './KapanQuery'
import moment from 'moment'
import { Kapanchild } from './KapanChild'
import { UPDATE_KAPANMASTER } from './KapanMutation'
import { toast } from 'react-toastify';
import { FormatError } from '../../../components/Comman/FormatError'
import { CompanyAndPartyAllData } from "../../../components/DropdownSelect/SelectComponents/companyAndPartySelectComponent";
import { UserListAllData } from "../../../components/DropdownSelect/SelectComponents/UserNameSelectComponent";
import { CustomFilterComponent } from '../../../components/CustomFilterComponent/index'
import { Tofixed } from '../../../components/Comman/Tofix'
import { useStyles, Accordion, AccordionSummary, AccordionDetails } from '../../../components/Comman/Accordian'
import { Button, Typography, FormControl } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


const columns = [
  { title: 'Bill No', field: 'billNo' },
  {
    title: 'Date', field: 'date', type: "date", render: (rowData) => {
      if (rowData.date)
        return moment(rowData?.date || 0).format('DD/MM/YYYY')
    }
  },
  { title: 'Pur. Type', field: 'purType' },
  {
    title: 'Party Name', field: 'partyId', render: (rowDate) => {
      return rowDate?.partyId?.partyName || "No Party "
    }
  },
  {
    title: 'Lot No List', field: 'lotNoList'
    , render: (rowData) => {
      return rowData?.lotNoList || "-"
    }
  },
  {
    title: 'Total Cts',
    field: 'totalCts',
    render: (rowData) => Tofixed(rowData?.totalCts || 0)
  },
  {
    title: 'Remaing Cts',
    field: 'remainCts',
    render: (rowData) => Tofixed(rowData?.remainCts || 0)
  },
  {
    title: 'Net Cost',
    field: 'netCost',
    render: (rowData) => Tofixed(rowData?.netCost || 0)

  },
  {
    title: 'Zar Amount ',
    field: 'amountZar',
    render: (rowData) => Tofixed(rowData?.amountZar || 0)
  },
  { title: "User", field: "createdBy", render: (rowData) => rowData?.createdBy?.userName || " ", editable: "never" }

]

function Kapan(props) {
  const classes = useStyles();
  const tableRef = useRef();

  const [pageSize, setPageSize] = useState(10);
  const [selectedRow, setSelectedRow] = useState(null);

  const [filter, setFilter] = useState({})
  const [selectedFromDate, setSelectedFromDate] = useState(moment().subtract(7, "days"));
  const [selectedToDate, setSelectedToDate] = useState(new Date());
  const { data, fetchMore } = useQuery(KAPAN, {
    variables: { page: 1, limit: 10, filter: JSON.stringify({}), sort: { key: 'createdAt', type: -1 } },
    fetchPolicy: 'network-only'
  })
  const { data: summaryData } = useQuery(KAPANSUMMARY, {
    variables: { filter: JSON.stringify(filter), "sort": { "key": "createdAt", "type": -1 } },
    fetchPolicy: 'network-only',
  })
  columns.map(d => {
    if (d.field === "partyId") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{
          options: CompanyAndPartyAllData("PARTY"),
          getOptionLabel: (option) => option.partyName,
          multiple: true
        }} />
      }
    }
    else if (d.field === "createdBy") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{
          options: UserListAllData(),
          autoHighlight: true,
          getOptionLabel: (option) => option.userName,
          multiple: true
        }} />
      }
    }
    return null
  })
  const PreViewHandler = () => {
    let Data = filter
    if (selectedFromDate) {
      Data = { ...Data, "from": moment(selectedFromDate).format('YYYY-MM-DD') }
    }
    if (selectedToDate) {
      Data = { ...Data, "to": moment(selectedToDate).format('YYYY-MM-DD') }
    }
    setFilter(Data)
  }
  const getKapanData = (query) => {
    return new Promise(async (resolve, reject) => {
      let filter = {}, sort = { key: 'createdAt', type: -1 }
      query && query.filters.forEach(item => {
        if ((item.column.field === "date" || item.column.field === "dueDate")) {
          if (item.value)
            filter = { ...filter, [item.column.field]: moment(item.value).format("YYYY-MM-DD") }
          else {
            delete filter[item.column.field]
            filter = { ...filter }
          }
        }
        else if ((item.column.field === "billNo" || item.column.field === "dueDays" || item.column.field === "brokerPer" || item.column.field === "brokerAmount")) {
          if (item.value)
            filter = { ...filter, [item.column.field]: parseInt(item.value) }
          else {
            delete filter[item.column.field]
            filter = { ...filter }
          }
        }
        else if ((item.column.field === "exportExpense" || item.column.field === "netCost")) {
          if (item.value)
            filter = { ...filter, [item.column.field]: parseFloat(item.value) }
          else {
            delete filter[item.column.field]
            filter = { ...filter }
          }
        }
        else if (item.value.length === 0) {
          delete filter[`${item.column.field}`]
          filter = { ...filter }
        } else
          filter = { ...filter, [item.column.field]: item.value }
      });
      if (query && query.orderBy && query.orderDirection !== "") {
        sort = { key: query.orderBy.field, type: query.orderDirection === 'asc' ? 1 : -1 }
      }
      setPageSize(query.pageSize)
      try {
        let result = await fetchMore({
          variables: { page: query.page + 1, limit: query.pageSize, filter: JSON.stringify(filter), sort: sort },
          fetchPolicy: 'network-only',
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return fetchMoreResult;
          }

        })
        resolve({
          data: result?.data?.getKapanMaster?.data || [],
          page: query.page,
          totalCount: result?.data?.getKapanMaster?.count || 0,
        })
      } catch (error) {
        console.log(FormatError(error))
      }
    })
  }
  const [UpdateKapanlMutation] = useMutation(UPDATE_KAPANMASTER)

  const UpdateKapanWithDetail = (newData) => {
    delete newData.createdBy
    newData.partyId = newData?.partyId?.id
    delete newData.__typename
    newData.amountZar = parseFloat(newData.amountZar).toFixed(2)
    newData.netCost = parseFloat(newData.netCost).toFixed(2)
    newData.totalCts = parseFloat(newData.totalCts).toFixed(2)
    newData.remainCts = parseFloat(newData.remainCts).toFixed(2)
    newData.amountZar = parseFloat(newData.amountZar)
    newData.netCost = parseFloat(newData.netCost)
    newData.totalCts = parseFloat(newData.totalCts)
    newData.remainCts = parseFloat(newData.remainCts)
    return new Promise((resolve, reject) => {
      let log = {
        action: 'UPDATE',
        actionOn: 'KapanMaster',
        actionName: 'updateKapanMaster',
        oldValue: "",
        message: `Kapan was updated.`
      }
      UpdateKapanlMutation({ variables: { input: newData, log } }).then((data) => {
        tableRef.current.onQueryChange();
        resolve()
      }).catch((e) => {
        reject()
        toast.error(FormatError(e))
      })

    })
  }

  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Filters</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container xs={24}  >
            <Grid xs={4}>
              {summaryData?.getKapanMasterSummary?.data &&
                <div>
                  <p>Total Cts : - {summaryData?.getKapanMasterSummary?.summary?.totalCts || ''}</p>
                  <p>Total Amount Zar : - {summaryData?.getKapanMasterSummary?.summary?.totalAmountZar || ''}</p>
                  <p>Total Net Cost : - {summaryData?.getKapanMasterSummary?.summary?.totalNetCost || ''}</p>
                  <p>Total kapan : - {summaryData?.getKapanMasterSummary?.summary?.totalKapan || ''}</p>
                </div>
              }
            </Grid>
            <Grid xs={4}>
              <form className={"makeStyles-root-30"} noValidate autoComplete="off">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-from"
                    label="From Date"
                    format="dd/MM/yyyy"
                    value={selectedFromDate}
                    onChange={e => setSelectedFromDate(e)}
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                    style={{ marginTop: 0 }}
                  />&nbsp;&nbsp;&nbsp;&nbsp;
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-to"
                    label="To Date"
                    format="dd/MM/yyyy"
                    value={selectedToDate}
                    onChange={e => setSelectedToDate(e)}
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                    style={{ marginTop: 0 }}
                  />
                </MuiPickersUtilsProvider>

                <Button
                  onClick={() => PreViewHandler()}
                  variant="contained"
                  color="primary"
                  style={{ height: 34, marginLeft: 10, marginTop: 12 }}>
                  Preview
                </Button>

              </form>
            </Grid>

          </Grid>

        </AccordionDetails>
        <Grid item xs={12}>
          {(filter.from && filter.to) ?
            <div className="parentTable">
              <MaterialTable
                title="Kapan"
                columns={columns}
                data={summaryData?.getKapanMasterSummary?.data || []}
                options={{
                  emptyRowsWhenPaging: false,
                  debounceInterval: 300,
                  padding: "dense",
                  pageSize: pageSize,
                  pageSizeOptions: [5, 10, 15, 20, 25, 50],
                  addRowPosition: "first",
                  filtering: false,
                  sorting: false,
                  search: false,
                  showTitle: true,
                  filterCellStyle: { padding: "5px" },
                  maxBodyHeight: window.innerHeight - 215,
                  rowStyle: (rowData) => ({
                    backgroundColor:
                      selectedRow === rowData.tableData.id
                        ? "rgb(212, 218, 255)"
                        : "#FFF",
                  }),
                  headerStyle: { backgroundColor: 'whitesmoke' }
                }}
              />
            </div> : ""}
        </Grid>
      </Accordion>


      <Grid container>
        <Grid item xs={12}>
          <MaterialTable
            title="Kapan"
            tableRef={tableRef}
            columns={columns}
            data={query => getKapanData(query)}
            options={{
              emptyRowsWhenPaging: false,
              debounceInterval: 300,
              padding: 'dense',
              pageSize: pageSize,
              pageSizeOptions: [5, 10, 15, 20, 25, 50],
              addRowPosition: 'first',
              filtering: true,
              sorting: true,
              search: false,
              filterCellStyle: { padding: '5px', },
              maxBodyHeight: window.innerHeight - 215,
              rowStyle: rowData => ({
                backgroundColor: (selectedRow === rowData.tableData.id) ? 'rgb(212, 218, 255)' : '#f5f5f5'
              }),
              headerStyle: { backgroundColor: 'whitesmoke' }
            }}
            detailPanel={rowData => {
              return (
                <Kapanchild
                  key={rowData.id}
                  session={props?.session}
                  location={props?.location}
                  Ids={rowData.id}
                  UpdateKapan={UpdateKapanWithDetail}
                  KapanMasterData={data?.getKapanMaster?.data || []}
                  tableRef={tableRef}
                />
              )
            }}
            onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
          />
        </Grid>
      </Grid>
    </div >
  );
}


export default withRouter(Kapan)