import gql from 'graphql-tag';

export const APPROVEPENDINGREPORT = gql`
query getApproPendingReport($filter:String!)
{
  getApproPendingReport(filter:$filter){
    date
    partyId{
      partyName
    }
    lotNoId{
      lotNo
    }
    pktNoId{
      pktNo
    }
    cts
    colorId{
       shortName
    }
    purityId{
      shortName
    }
    stkDiscPer
    shapeId{
      shortName
    }
    polishId{
      shortName
    }
    symmId{
      shortName
    }
    fluId{
      shortName
    }
    labId{
      partyName
    }
    measurment
    certificateNo
    rfId
    
  }
}

`;


