import gql from 'graphql-tag';

export const ADD_PROCESS = gql`
mutation addNewProcess($input: processInput!,$log: LogInput){
  addNewProcess(input:$input,log: $log){
    id
    PROCCODE
    procName
    isActive
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}
`;

export const UPDATE_PROCESS = gql`
    mutation updateProcess($input: processUpdate!,$log: LogInput){
      updateProcess(input:$input,log: $log){
      id
      PROCCODE
      procName
      isActive
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
    }
`;

export const DELETE_PROCESS = gql`
    mutation deleteProcess($id:ID!,$log: LogInput){
      deleteProcess(id:$id,log: $log)
    }
`;
