import React, { useRef, useEffect, useState } from "react";
import { Grid, TextField, } from "@material-ui/core";
import MaterialTable from "material-table";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { SALESDETAIL } from "./SalesQuery";
import _ from "lodash";
import { SalesDetailFromLotNoAndPacketNo } from "../../../components/DropdownSelect/salesDetailFromLotNoAndPacketNo";
import { toast } from "react-toastify";
import { SalesType } from "../../../components/Constant/index";
import { PacketSelectComponent } from "../../../components/DropdownSelect/SelectComponents/PktNoSelectComponent";
import { ADD_SALESDETAIL, UPDATE_SALESDETAIL, DELETE_SALESDETAIL, } from "./SalesMutation";
import { LotNoSelectComponent } from "../../../components/DropdownSelect/SelectComponents/LotSelectComponent";
import { Tofixed } from "../../../components/Comman/Tofix";
import { FormatError } from "../../../components/Comman/FormatError";
import { cellStyle, headerStyle, headerStyle100, cellStyle100 } from "../../../components/Comman/StyleCell";
import { GradingSelectComponent } from "../../../components/DropdownSelect/SelectComponents/ShortCodeSelectComponent";
import { GETALLSHORTCODETYPE } from "../../Utility/SortCode/SortCodeQuery";
import { PRICE_LIST } from "../../Utility/RapaPortUser/RapaPortUserQuery";

const columns = [
  {
    title: "Type*",
    field: "type",
    lookup: SalesType,
    editable: "onAdd",
    validate: (rowData) =>
      rowData.type && rowData.type !== ""
        ? true
        : { isValid: false, helperText: `can't be empty` },
  },
  {
    title: "Barcode",
    field: "barcode",
    editable: "onAdd",
  },
  {
    title: "Lot No*",
    field: "lotNoId",
    editable: "onAdd",
    cellStyle: cellStyle,
    headerStyle: headerStyle,
    render: (rowData) => rowData?.lotNoId?.lotNo || "",
  },
  {
    title: "Pkt No*",
    field: "pktNoId",
    editable: "onAdd",
    cellStyle: cellStyle,
    headerStyle: headerStyle,
    render: (rowData) => rowData?.pktNoId?.pktNo || "",
    editComponent: (props) => {
      let valueDefault = props?.rowData?.lotNoId?.id || props?.rowData?.lotNoId || null

      let valueDefaultPacketNo = props?.rowData?.pktNoId?.id || props?.rowData?.pktNoId || null;
      return (
        <PacketSelectComponent
          field={"pktNoId"}
          propsData={props}
          ids={valueDefault}
          valueDefaultPacketNo={valueDefaultPacketNo}
        />
      );
    },
  },
  {
    title: "Cts*",
    field: "cts",
    // editable: "onAdd",
    cellStyle: cellStyle100,
    headerStyle: headerStyle100,
    render: (rowData) => Tofixed(rowData?.cts || 0),
    // editComponent: ({ rowData }) => rowData?.cts || "-",
    type: "numeric",
    editComponent: (props) => {
      //   let valueDefault = props?.rowData?.brokerPer || 0;
      return (
        <TextField
          value={props?.rowData?.cts}
          type="number"
          onChange={(e) => {
            let Data = { ...props.rowData };
            Data.cts = e.target.value;
            props.onRowDataChange(Data);
          }}
        />
      );
    },
  },
  {
    title: "Lab",
    field: "lab",
    // editable: "onAdd",
    render: (rowData) => rowData?.lab?.partyName,
    editComponent: ({ rowData }) => rowData?.lab?.partyName || "-",
  },
  {
    title: "Certicate No",
    // editable: "onAdd",
    field: "certificateNo",
    render: (rowData) => rowData?.certificateNo || "",
    editComponent: ({ rowData }) => rowData?.certificateNo || "-",
  },
  {
    title: "Color",
    field: "colorId",
    // editable: "onAdd",
    render: (rowData) => rowData?.colorId?.shortName || "",
  },
  {
    title: "Shape",
    field: "shapeId",
    // editable: "onAdd",
    render: (rowData) => rowData?.shapeId?.shortName || "",
    editComponent: ({ rowData }) => {
      return rowData?.shapeId?.shortName || "-";
    },
  },
  {
    title: "Purity",
    field: "purityId",
    // editable: "onAdd",
    render: (rowData) => rowData?.purityId?.shortName || "",
    editComponent: ({ rowData }) => {
      return rowData?.purityId?.shortName || "-";
    },
  },
  {
    title: "Cut",
    field: "cutId",
    // editable: "onAdd",
    render: (rowData) => rowData?.cutId?.shortName || "",
    editComponent: ({ rowData }) => {
      return rowData?.cutId?.shortName || "-";
    },
  },
  {
    title: "Polish",
    field: "polishId",
    // editable: "onAdd",
    render: (rowData) => rowData?.polishId?.shortName || "",
    editComponent: ({ rowData }) => {
      return rowData?.polishId?.shortName || "-";
    },
  },
  {
    title: "Symm.",
    field: "symmId",
    // editable: "onAdd",
    render: (rowData) => rowData?.symmId?.shortName || "",
    editComponent: ({ rowData }) => {
      return rowData?.symmId?.shortName || "-";
    },
  },
  {
    title: "Flou.",
    field: "floId",
    // editable: "onAdd",
    render: (rowData) => rowData?.floId?.shortName || "",
    // editComponent: ({ rowData }) => {
    //   return rowData?.floId?.shortName || "-";
    // },
  },
  {
    title: "Rape Rate*",
    field: "rapRate",
    render: (rowData) => rowData?.rapRate ? Tofixed(rowData?.rapRate || 0) : "",
    type: "numeric",
    editComponent: (props) => {
      //   let valueDefault = props?.rowData?.brokerPer || 0;
      return (
        <TextField
          value={props?.rowData?.rapRate}
          type="number"
          onChange={(e) => {
            let Data = { ...props.rowData };
            Data.rapRate = e.target.value;
            props.onRowDataChange(Data);
          }}
        />
      );
    },
  },
  {
    title: "Rape Amt.",
    field: "rapAmt",
    render: (rowData) => rowData?.rapAmt ? Tofixed(rowData?.rapAmt || 0) : "",
    editComponent: (props) => {
      let Data = { ...props.rowData };
      let rapAmt = Tofixed(
        parseFloat(Data?.cts || 0.0) * parseFloat(Data?.rapRate || 0.0)
      );
      if (Data.rapAmt !== rapAmt) {
        Data.rapAmt = rapAmt;
        props.onRowDataChange(Data);
      }
      return rapAmt;
    },
  },
  {
    title: "STK Disc(%)",
    field: "stkDiscPer",
    type: "numeric",
    editComponent: (props) => {
      return (
        <TextField
          value={props?.rowData?.stkDiscPer}
          type="string"
          onChange={(e) => {
            let Data = { ...props.rowData };
            Data.stkDiscPer = e.target.value;
            // if ((Data.stkDiscPer >= 0 && Data.stkDiscPer <= 100) || (Data.stkDiscPer >= 0 && Data.stkDiscPer <= 100)) {
            props.onRowDataChange(Data);
            // } else {
            //   toast.warn(
            //     "please, enter stkDisc percentage value between 0 to 100."
            //   );
            // }
          }}
        />
      );
    },
  },
  {
    title: "sales Disc(%)",
    field: "salesDiscPer",
    type: "numeric",
    editComponent: (props) => {
      return (
        <TextField
          value={props?.rowData?.salesDiscPer}
          type="number"
          onChange={(e) => {
            let Data = { ...props.rowData };
            Data.salesDiscPer = e.target.value;
            // if (Data.salesDiscPer >= 0 && Data.salesDiscPer <= 100) {
            props.onRowDataChange(Data);
            // } else {
            //   toast.warn(
            //     "please, enter salesDisc percentage value between 0 to 100."
            //   );
            // }
          }}
        />
      );
    },
  },
  {
    title: "Diff(%)",
    field: "diffPer",
    editComponent: (props) => {
      let Data = { ...props.rowData };
      let diffPer = parseFloat(Tofixed(parseFloat(Data?.salesDiscPer || 0.0) - parseFloat(Data?.stkDiscPer || 0.0)));
      if (Data.diffPer !== diffPer) {
        Data.diffPer = diffPer;
        props.onRowDataChange(Data);
      }
      return diffPer;
    },
  },
  {
    title: "Net Reprate",
    field: "netRaprate",
    render: (rowData) => rowData?.netRaprate ? Tofixed(rowData?.netRaprate || 0) : "",
    editComponent: (props) => {
      let Data = { ...props.rowData };
      let netRaprate = parseFloat(
        Tofixed(
          parseFloat(Data?.rapRate || 0.0) -
          (parseFloat(Data?.rapRate || 0.0) *
            parseFloat(Data?.salesDiscPer || 0.0)) /
          100
        )
      );
      if (Data.netRaprate !== netRaprate) {
        Data.netRaprate = netRaprate;
        props.onRowDataChange(Data);
      }
      return netRaprate;
    },
  },
  {
    title: "Net Reprate Amt",
    field: "netRaprateAmt",
    render: (rowData) => Tofixed(rowData?.netRaprateAmt || 0),
    editComponent: (props) => {
      let Data = { ...props.rowData };
      let netRaprateAmt = parseFloat(
        Tofixed(
          parseFloat(Data?.cts || 0.0) * parseFloat(Data?.netRaprate || 0.0)
        )
      );
      if (Data.netRaprateAmt !== netRaprateAmt) {
        Data.netRaprateAmt = netRaprateAmt;
        props.onRowDataChange(Data);
      }
      return netRaprateAmt;
    },
  },
  {
    title: "Zar Rate", field: "rateZar", type: "numeric",
    editComponent: (props) => {
      //   let valueDefault = props?.rowData?.brokerPer || 0;
      return (
        <TextField
          value={props?.rowData?.rateZar}
          type="number"
          onChange={(e) => {
            let Data = { ...props.rowData };
            Data.rateZar = e.target.value;
            props.onRowDataChange(Data);
          }}
        />
      );
    }
  },
  {
    title: "Zar Amt",
    field: "amountZar",
    render: (rowData) => Tofixed(rowData?.amountZar || 0),
    editComponent: (props) => {
      let Data = { ...props.rowData };
      let amountZar = parseFloat(
        Tofixed(
          parseFloat(Data?.netRaprateAmt || 0.0) *
          parseFloat(Data?.rateZar || 0.0)
        )
      );
      if (Data.amountZar !== amountZar) {
        Data.amountZar = amountZar;
        props.onRowDataChange(Data);
      }
      return amountZar;
    },
  },
  {
    title: "Commision%",
    field: "commisionPer",
    type: "numeric",
    editComponent: (props) => {
      return (
        <TextField
          value={props?.rowData?.commisionPer}
          type="number"
          onChange={(e) => {
            let Data = { ...props.rowData };
            Data.commisionPer = e.target.value;
            if (Data.commisionPer >= 0 && Data.commisionPer <= 100) {
              props.onRowDataChange(Data);
            } else {
              toast.warn(
                "please, enter commision percentage value between 0 to 100."
              );
            }
          }}
        />
      );
    },
  },
  {
    title: "Commision",
    field: "commision",
    render: (rowData) => rowData?.commision ? Tofixed(rowData?.commision || 0) : "",
    editComponent: (props) => {
      let Data = { ...props.rowData };
      let commision = parseFloat(
        Tofixed(
          (parseFloat(Data?.amountZar || 0.0) *
            parseFloat(Data?.commisionPer || 0.0)) /
          100
        )
      );
      if (Data.commision !== commision) {
        Data.commision = commision;
        props.onRowDataChange(Data);
      }
      return commision;
    },
  },
  {
    title: "Polish Exp.(%)",
    field: "polishExpPer",
    type: "numeric",
    editComponent: (props) => {
      return (
        <TextField
          value={props?.rowData?.polishExpPer}
          type="number"
          onChange={(e) => {
            let Data = { ...props.rowData };
            Data.polishExpPer = e.target.value;
            if (Data.polishExpPer >= 0 && Data.polishExpPer <= 100) {
              props.onRowDataChange(Data);
            } else {
              toast.warn(
                "please, enter polishExpPer percentage value between 0 to 100."
              );
            }
          }}
        />
      );
    },
  },
  {
    title: "Polish Exp Amount",
    field: "polishExpAmt",
    render: (rowData) => Tofixed(rowData?.polishExpAmt || 0),

    editComponent: (props) => {
      let Data = { ...props.rowData };
      let polishExpAmt = parseFloat(
        Tofixed(
          (parseFloat(Data?.amountZar || 0.0) *
            parseFloat(Data?.polishExpPer || 0.0)) /
          100
        )
      );
      if (Data.polishExpAmt !== polishExpAmt) {
        Data.polishExpAmt = polishExpAmt;
        props.onRowDataChange(Data);
      }
      return polishExpAmt;
    },
  },
  {
    title: "Net Zar Amount",
    field: "netAmtZar",
    render: (rowData) => rowData?.netAmtZar ? Tofixed(rowData?.netAmtZar || 0) : "",
    editComponent: (props) => {
      let Data = { ...props.rowData };
      let netAmtZar = parseFloat(
        Tofixed(
          parseFloat(Data?.amountZar || 0.0) -
          (parseFloat(Data?.commision || 0.0) +
            parseFloat(Data?.polishExpAmt || 0.0))
        )
      );
      if (Data.netAmtZar !== netAmtZar) {
        Data.netAmtZar = netAmtZar;
        props.onRowDataChange(Data);
      }
      return netAmtZar;
    },
  },
];
function SalesDetails(props) {
  const tableRef = useRef();
  const permissions = props?.session?.me?.role?.permissions;
  const role = props?.session?.me?.role?.role;
  const path = props?.location?.pathname;
  const [pricelistData, setpricelistData] = useState([]);

  let permission = _.find(permissions, function (o) {
    return o.name === path;
  });
  const { data: shortCodeData } = useQuery(
    GETALLSHORTCODETYPE,
    {
      variables: { type: "All" },
      fetchPolicy: "cache-and-network",
    }
  );
  const { data: priceList } = useQuery(PRICE_LIST, {
    fetchPolicy: 'cache-and-network',
  })
  const getShortCodeData = (TYPE) => {
    return _.filter(shortCodeData?.getAllType || [], (d) => d.type === TYPE);
  };
  useEffect(() => {
    setpricelistData(priceList?.getRapaportPriceList || [])
  }, [priceList]);

  const SetDefaultValue = (value) => {
    return value?.id || value;
  };

  columns.map((d) => {
    if (d.field === "lotNoId") {
      d.editComponent = (props) => {
        let valueDefault = props?.rowData?.lotNoId?.id || props?.rowData?.lotNoId || null;
        return (
          <LotNoSelectComponent
            field="lotNoId"
            propsData={props}
            valueDefault={valueDefault}
          />
        );
      };
    }
    if (d.field === "colorId") {
      d.editComponent = (props) => {
        let TYPE = props?.rowData?.type || null;
        let lotNoId = props?.rowData?.lotNoId?.id || props?.rowData?.lotNoId || null;
        let pktNoId = props?.rowData?.pktNoId?.id || props?.rowData?.pktNoId || null;
        if (lotNoId && pktNoId && TYPE) {
          let InwardData = SalesDetailFromLotNoAndPacketNo(
            lotNoId,
            pktNoId,
            TYPE
          );
          let Data = { ...props.rowData };
          let Ids = InwardData[0]?.id || null;
          let Ids2 = Data?.setids || "";
          if (Ids && Ids2 !== Ids) {
            Data.colorId = InwardData[0]?.colorId || null;
            Data.floId = InwardData[0]?.fluId || null;
            Data.shapeId = InwardData[0]?.shapeId || null;
            Data.purityId = InwardData[0]?.purityId || null;
            Data.cutId = InwardData[0]?.cutId || null;
            Data.polishId = InwardData[0]?.polishId || null;
            Data.symmId = InwardData[0]?.symmId || null;
            Data.lab = InwardData[0]?.lab || null;
            Data.cts = InwardData[0]?.cts || InwardData[0]?.issueCts || 0;
            Data.rapRate = InwardData[0]?.rapRate || 0;
            Data.rapAmt = InwardData[0]?.amountRap || 0;
            Data.stkDiscPer = InwardData[0]?.stkPer || 0;
            Data.certificateNo =
              InwardData[0]?.certifiNo || InwardData[0]?.certificateId || "";
            Data.setids = InwardData[0].id;
            props.onRowDataChange(Data);
          }
          if (props?.rowData?.cts || null) {
            return (
              <GradingSelectComponent
                propsData={props}
                TYPE="colorId"
                componentName="IssueReturn"
                shortCodeData={shortCodeData}
                pricelistData={pricelistData}
                data={getShortCodeData("COLOR")}
                valueDefault={SetDefaultValue(props?.rowData?.colorId) || ""}
              />
            );
          } else {
            return "";
          }
        } else {
          return "-";
        }
      };
    }
    if (d.field === "shapeId") {
      d.editComponent = (props) => {
        if (props?.rowData?.cts || null) {
          return (
            <GradingSelectComponent
              propsData={props}
              componentName="IssueReturn"
              shortCodeData={shortCodeData}
              pricelistData={pricelistData}
              TYPE="shapeId"
              data={getShortCodeData("SHAPE")}
              valueDefault={SetDefaultValue(props?.rowData?.shapeId) || ""}
            />
          );
        } else {
          return "";
        }
      };
    }
    if (d.field === "purityId") {
      d.editComponent = (props) => {
        if (props?.rowData?.cts || null) {
          return (
            <GradingSelectComponent
              propsData={props}
              componentName="IssueReturn"
              shortCodeData={shortCodeData}
              pricelistData={pricelistData}
              TYPE="purityId"
              data={getShortCodeData("PURITY")}
              valueDefault={SetDefaultValue(props?.rowData?.purityId) || ""}
            />
          );
        } else {
          return "";
        }
      };
    }
    if (d.field === "cutId") {
      d.editComponent = (props) => {
        if (props?.rowData?.cts || null) {
          return (
            <GradingSelectComponent
              componentName="IssueReturn"
              propsData={props}
              TYPE="cutId"
              data={getShortCodeData("CUT")}
              valueDefault={SetDefaultValue(props?.rowData?.cutId) || ""}
            />
          );
        } else {
          return "";
        }
      };
    }
    if (d.field === "polishId") {
      d.editComponent = (props) => {
        if (props?.rowData?.cts || null) {
          return (
            <GradingSelectComponent
              componentName="IssueReturn"
              propsData={props}
              TYPE="polishId"
              data={getShortCodeData("POLISH")}
              valueDefault={SetDefaultValue(props?.rowData?.polishId) || ""}
            />
          );
        } else {
          return "";
        }
      };
    }
    if (d.field === "symmId") {
      d.editComponent = (props) => {
        if (props?.rowData?.cts || null) {
          return (
            <GradingSelectComponent
              propsData={props}
              componentName="IssueReturn"
              TYPE="symmId"
              data={getShortCodeData("SYMMENTRY")}
              valueDefault={SetDefaultValue(props?.rowData?.symmId) || ""}
            />
          );
        } else {
          return "";
        }
      };
    }
    if (d.field === "floId") {
      console.log("getShortCodeData(FLOURSENCE)", getShortCodeData("FLOURSENCE"))
      d.editComponent = (props) => {
        if (props?.rowData?.cts || null) {
          return (
            <GradingSelectComponent
              propsData={props}
              componentName="IssueReturn"
              TYPE="floId"
              data={getShortCodeData("FLOURSENCE")}
              valueDefault={SetDefaultValue(props?.rowData?.floId) || ""}
            />
          );
        } else {
          return "";
        }
      };
    }
    return null
  });

  const { error, data, refetch } = useQuery(SALESDETAIL, {
    variables: { id: props.Data.id },
    fetchPolicy: 'cache-and-network'
  });


  const HideRow = (rows) => {
    let aa = document.getElementsByClassName("childTable");
    for (let index = 0; index < aa.length; index++) {
      setTimeout(() => {
        if (rows && rows !== 0 && aa[index]) {
          let newD = aa[index].getElementsByClassName("MuiTableBody-root")[0]
            .rows[
            aa[index].getElementsByClassName("MuiTableBody-root")[0].rows
              .length - 1
          ];
          if (newD.getElementsByClassName("MuiTableCell-sizeSmall")[0]) {
            newD.getElementsByClassName("MuiTableCell-sizeSmall")[0].innerText = "Total";
            newD.getElementsByClassName("MuiTableCell-sizeSmall")[1].innerText = "";
            newD.style.background = "rgb(212, 218, 255)";
          }
        }
      }, 500);
    }
  };

  const getSalesDetail = () => {
    return new Promise((resolve, reject) => {
      if (error) reject(error);
      let result = [...data?.getAllSalesDetail || []]
      if (result.length !== 0) {
        let LastRow = {
          barcode: result.length.toString(),
          cts: Tofixed(_.sum(_.map(result, "cts"))),
          netRaprateAmt: Tofixed(_.sum(_.map(result, "netRaprateAmt"))),
          amountZar: Tofixed(_.sum(_.map(result, "amountZar"))),
          polishExpAmt: Tofixed(_.sum(_.map(result, "polishExpAmt"))),
        };
        result.push(LastRow);
      }
      HideRow(result.length);
      resolve({
        data: result,
      });
    });
  };

  const [AddSalesDetailMutation] = useMutation(ADD_SALESDETAIL);
  const [UpdateSalesDetailMutation] = useMutation(UPDATE_SALESDETAIL);
  const [DeleteSalesDetailMutation] = useMutation(DELETE_SALESDETAIL);

  const UpdateMasterFromDetails = (Data, type) => {
    let result = [...data?.getAllSalesDetail || []]
    let NewData = props.Data;
    let totalAmount = 0,
      zarAmount = 0,
      totalCommision = 0,
      totalZarAmount = 0,
      vat = 0,
      netZarAmt = 0;
    if (type === "ADD") {
      totalAmount = _.sum(_.map(result, "netRaprateAmt")) + Data.netRaprateAmt;
      zarAmount = _.sum(_.map(result, "amountZar")) + Data.amountZar;
      totalCommision = _.sum(_.map(result, "commision")) + Data.commision;
      totalZarAmount = _.sum(_.map(result, "amountZar")) + Data.amountZar;
      netZarAmt = _.sum(_.map(result, "netAmtZar")) + Data.netAmtZar;
      vat = (totalZarAmount * NewData.vatInPer) / 100;
      netZarAmt = netZarAmt + vat;
    } else if (type === "UPDATE") {
      totalAmount = _.sum(_.map(_.filter(result, (D) => D.id !== Data.id), "netRaprateAmt")) + Data.netRaprateAmt;
      zarAmount = _.sum(_.map(_.filter(result, (D) => D.id !== Data.id), "amountZar")) + Data.amountZar;
      totalCommision = _.sum(_.map(_.filter(result, (D) => D.id !== Data.id), "commision")) + Data.commision;
      totalZarAmount = _.sum(_.map(_.filter(result, (D) => D.id !== Data.id), "amountZar")) + Data.amountZar;
      netZarAmt = _.sum(_.map(_.filter(result, (D) => D.id !== Data.id), "netAmtZar")) + Data.netAmtZar;
      vat = (totalZarAmount * NewData.vatInPer) / 100;
      netZarAmt = netZarAmt + vat;
    } else if (type === "DELETE") {
      totalAmount = _.sum(_.map(_.filter(result, (D) => D.id !== Data.id), "netRaprateAmt"));
      zarAmount = _.sum(_.map(_.filter(result, (D) => D.id !== Data.id), "amountZar"));
      totalCommision = _.sum(_.map(_.filter(result, (D) => D.id !== Data.id), "commision"));
      totalZarAmount = _.sum(_.map(_.filter(result, (D) => D.id !== Data.id), "amountZar"));
      netZarAmt = _.sum(_.map(_.filter(result, (D) => D.id !== Data.id), "netAmtZar"));
      vat = (totalZarAmount * NewData.vatInPer) / 100;
      netZarAmt = netZarAmt + vat;
    }

    let a = NewData.tableData;
    delete NewData.tableData;

    NewData.totalAmount = totalAmount ? totalAmount : 0;
    NewData.zarAmount = zarAmount ? zarAmount : 0;
    NewData.totalCommision = totalCommision ? totalCommision : 0;
    NewData.totalZarAmount = totalZarAmount ? totalZarAmount : 0;
    NewData.vat = vat ? vat : 0;
    NewData.netZarAmt = netZarAmt ? netZarAmt : 0;
    if (props.UpdateSalesMaster(NewData)) {
      NewData.tableData = a;
      return true;
    } else {
      return false;
    }
  };
  const AddSalesDetails = (newData) => {
    return new Promise((resolve, reject) => {
      refetch()
      newData.salesMasterId = props.Data.id;
      newData.colorId = newData?.colorId?.id || null;
      newData.cutId = newData?.cutId?.id || null;
      newData.floId = newData?.floId?.id || null;
      newData.lab = newData?.lab?.id || null;
      newData.polishId = newData?.polishId?.id || null;
      newData.purityId = newData?.purityId?.id || null;
      newData.shapeId = newData?.shapeId?.id || null;
      newData.symmId = newData?.symmId?.id || null;
      newData.rapRate = parseFloat(Tofixed(newData?.rapRate || 0));
      newData.stkDiscPer = parseFloat(Tofixed(newData?.stkDiscPer || 0));
      newData.salesDiscPer = parseFloat(Tofixed(newData?.salesDiscPer || 0));
      newData.commisionPer = parseFloat(Tofixed(newData?.commisionPer || 0));
      newData.polishExpPer = parseFloat(Tofixed(newData?.polishExpPer || 0));
      newData.rateZar = parseFloat(Tofixed(newData?.rateZar || 0));
      newData.rapAmt = parseFloat(newData?.rapAmt || 0);
      newData.billNo = props.Data.billNo;
      newData.partyId = props.Data.partyId.id;
      delete newData.setids;
      if (newData.type === "ROUGH") {
        delete newData.rateRap
      }
      if (newData.lotNoId && newData.pktNoId && newData.cts && newData.rapRate) {
        if (UpdateMasterFromDetails(newData, "ADD")) {
          let log = {
            action: 'INSERT',
            actionOn: 'SalesDetail',
            actionName: 'addSalesDetail',
            oldValue: "",
            message: `Sales detail was created.`
          }
          AddSalesDetailMutation({ variables: { input: newData, log } })
            .then((data) => {
              refetch()
              toast.success("sales Detail created successfully");
              resolve();
            })
            .catch((e) => {
              reject();
              toast.error(FormatError(e));
            });
        } else {
          reject();
          toast.error("error On master update");
        }
      } else {
        reject();
        toast.warn("please, fill all required(*) fields.");
      }
    });
  };
  const UpdateSalesDetails = (newData, oldData) => {
    return new Promise((resolve, reject) => {
      refetch()
      delete newData.createdBy;
      delete newData.createdAt;
      delete newData.updatedBy;
      delete newData.updatedAt;
      delete newData.__typename;
      newData.colorId = newData?.colorId?.id || newData?.colorId || null;
      newData.cutId = newData?.cutId?.id || newData?.cutId || null;
      newData.floId = newData?.floId?.id || newData?.floId || null;
      newData.lab = newData?.lab?.id || newData?.lab || null;
      newData.polishId = newData?.polishId?.id || newData?.polishId || null;
      newData.purityId = newData?.purityId?.id || newData?.purityId || null;
      newData.shapeId = newData?.shapeId?.id || newData?.shapeId || null;
      newData.symmId = newData?.symmId?.id || newData?.symmId || null;
      newData.rapRate = parseFloat(Tofixed(newData?.rapRate || 0));
      newData.stkDiscPer = parseFloat(Tofixed(newData?.stkDiscPer || 0));
      newData.salesDiscPer = parseFloat(Tofixed(newData?.salesDiscPer || 0));
      newData.commisionPer = parseFloat(Tofixed(newData?.commisionPer || 0));
      newData.polishExpPer = parseFloat(Tofixed(newData?.polishExpPer || 0));
      newData.rateZar = parseFloat(Tofixed(newData?.rateZar || 0))
      newData.rapAmt = parseFloat(newData?.rapAmt || 0);
      newData.cts = parseFloat(newData?.cts || 0);
      delete newData.setids;
      newData.lotNoId = newData?.lotNoId?.id || newData?.lotNoId || null;
      newData.pktNoId = newData?.pktNoId?.id || newData?.pktNoId || null;
      newData.certificateNo = newData.certificateNo.toString();
      delete newData.rateRap
      if (newData.lotNoId && newData.pktNoId && newData.cts) {
        if (UpdateMasterFromDetails(newData, "UPDATE")) {
          let log = {
            action: 'UPDATE',
            actionOn: 'SalesDetail',
            actionName: 'updateSalesDetail',
            oldValue: JSON.stringify(oldData),
            message: `Sales detail of packet name ${oldData?.pktNoId?.pktNo || ''} was updated.`
          }
          UpdateSalesDetailMutation({ variables: { input: newData, log } })
            .then((data) => {
              toast.success("sales Detail updated successfully ");
              resolve();
            })
            .catch((e) => {
              reject();
              toast.error(FormatError(e));
            });
        } else {
          toast.error("error On MASTER Update");
        }
      } else {
        reject();
        toast.warn("please, fill all required(*) fields.");
      }
    });
  };
  const DeleteSalesDetails = (oldData) => {
    return new Promise((resolve, reject) => {
      refetch()
      let log = {
        action: 'DELETE',
        actionOn: 'SalesDetail',
        actionName: 'deleteSalesDetail',
        oldValue: JSON.stringify(oldData),
        message: `Sales detail of packet name ${oldData?.pktNoId?.pktNo || ''} was deleted.`
      }
      if (UpdateMasterFromDetails(oldData, "DELETE")) {
        DeleteSalesDetailMutation({ variables: { id: oldData.id, log } })
          .then((data) => {
            toast.success("sales Detail Deleted successfully");
            resolve();
          })
          .catch((e) => {
            reject();
            toast.error(FormatError(e));
          });
      } else {
        toast.error("error On MASTER Update");
      }
    });
  };

  useEffect(() => {
    tableRef.current && tableRef.current.onQueryChange();
  }, [data]);

  const addButton = (val) => {
    if (val || role === "admin")
      return { onRowAdd: (newData) => AddSalesDetails(newData) };
    else return {};
  };

  return (
    <Grid container direction="row" justify="flex-end" item xs={12}>
      <div className="childTable">
        <MaterialTable
          tableRef={tableRef}
          columns={columns}
          data={() => getSalesDetail()}
          options={{
            emptyRowsWhenPaging: false,
            padding: "dense",
            pageSize: 10,
            pageSizeOptions: [5, 10, 15, 20, 25, 50],
            addRowPosition: "first",
            filtering: false,
            sorting: false,
            search: false,
            paging: false,
            filterCellStyle: { padding: "5px" },
            maxBodyHeight: window.innerHeight - 215,
            showTitle: false,
            selection: true,
            headerStyle: { backgroundColor: 'whitesmoke' }
          }}
          onSelectionChange={(rows) => {
            let Ids = _.map(rows, (d) => d.id)
            props.setSelectedRowData([props?.Data])
            return props?.setselectedChildRowData(Ids)
          }}
          editable={!props.flag ? {
            // onRowAdd: (newData) => AddSalesDetails(newData),
            isEditHidden: (rowData) =>
              role === "admin" ? false : !permission.isUpdate,
            isDeleteHidden: (rowData) =>
              role === "admin" ? false : !permission.isDelete,
            ...addButton(permission?.isInsert),
            onRowUpdate: (newData, oldData) =>
              UpdateSalesDetails(newData, oldData),
            onRowDelete: (oldData) => DeleteSalesDetails(oldData),
          } : {}}
        />
      </div>
    </Grid>
  );
}
export default SalesDetails;
