import React, { useEffect, useState } from 'react'
import { Grid, Button, CircularProgress } from '@material-ui/core';
import MaterialTable from 'material-table';
import { toast } from 'react-toastify'
import _ from 'lodash'
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import TextField from '@material-ui/core/TextField';
import { useQuery } from '@apollo/react-hooks'
import { GETSUBPACKET } from './Query';


const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    color: 'white',
    background: '#8CB836'
  },
}));
const columns = [
  {
    title: 'Lot No',
    field: 'lotNoId.lotNo'
  },
  {
    title: 'Pkt No',
    field: 'pktNo',
  },
  {
    title: 'Pkt Type',
    field: 'pktType',
  },
  {
    title: 'Org Cts *',
    field: 'SubPktCts',
    type: "numeric"
  },
  {
    title: 'iss Cts',
    field: 'SubPktCts',
  },
  {
    title: 'loss Cts',
    field: 'lossCts',
    render: (rowData) => "0"
  },
  {
    title: 'Color',
    field: 'colorId',
    render: (rowData) => "-"
  },
  {
    title: 'Shape',
    field: 'shapeId',
    render: (rowData) => "-"
  },
  {
    title: 'Purtity',
    field: 'purityId',
    render: (rowData) => "-"
  },
  {
    title: 'Exp. Cts',
    field: 'SubPktCts',
  },
  {
    title: 'Exp.pol Amt',
    field: 'flsId',
    render: (rowData) => "0"
  },
]

function SubPacket(props) {
  let SubPacketMasterData = props?.SubPacketMasterData || []
  let packetNoId = SubPacketMasterData?.pktNoId?.subPktMasterId || SubPacketMasterData?.pktNoId?.id || null

  const { error, data, loading } = useQuery(GETSUBPACKET, {
    variables: { id: packetNoId },
    fetchPolicy: 'network-only',
  })
  let Atemp = data?.getSubPkt || []
  const [Data, setData] = useState([])

  const classes = useStyles();
  const [SelectedRows, setSelectedRows] = useState([])
  const [SubPacketCts, SetSubPacketCts] = useState('')
  const [packetName, SetpacketName] = useState('')
  const FromTableData = props?.FromTableData || []
  let TableData = _.filter(props?.FromTableData, (d) => d.id === SubPacketMasterData.id)[0]?.subPkt || []
  let pktIdsuniq = null
  if (Atemp.length !== 0)
    pktIdsuniq = _.uniq(_.map(_.filter(Atemp, (d) => d.isPlanning === false), 'subPktMasterId.pktNo'))
  let packetNoName = pktIdsuniq && pktIdsuniq.length > 0 ? pktIdsuniq : SubPacketMasterData?.pktNoId?.pktNo || null
  let Count = 0

  const SaveButtonHandler = () => {
    let Result = [...TableData];
    let NewResult = [...FromTableData]
    let CalculateCts = parseFloat(_.sum(_.map(TableData, 'SubPktCts'))) + parseFloat(SubPacketCts)
    if (CalculateCts <= parseFloat(SubPacketMasterData?.lossCts || 0)) {

      let Data = {
        "lotNoId": SubPacketMasterData?.lotNoId || null,
        "pktNo": packetName,
        "SubPktCts": parseFloat(SubPacketCts),
        "pktType": SubPacketMasterData?.pktType,
        "RemaingCts": parseFloat(SubPacketMasterData?.lossCts || 0) - parseFloat(CalculateCts),
        "pktNoId": SubPacketMasterData?.pktNoId?.id
      }
      Result.push(Data)
      let NewD = _.map(NewResult, (d => {
        if (d.id === SubPacketMasterData?.id) {
          d.subPkt = Result
        }
        return d
      }))

      props.SetFromTableData(NewD)

    } else {
      toast.warn("SubPacket Cts Is Not More Than Remaining Cts")
    }
  }

  const DeleteButtonHandler = () => {
    Count = 0
    let pktNumbers = _.map(SelectedRows, 'pktNo')
    let NewResult = [...FromTableData]
    let NewD = _.map(NewResult, (d => {
      if (d.id === SubPacketMasterData?.id) {
        d.subPkt = _.filter(TableData, (d) => !pktNumbers.includes(d.pktNo))
      }
      return d
    }))
    let newD = []
    _.map(Data, (d1) => {
      if (!pktNumbers.includes(d1.pktNo)) {
        newD.push(d1)
      }
    })
    setSelectedRows([])
    setData(newD)
    props.SetFromTableData(NewD)
  }

  const GetPacketNo = (count, Data) => {
    let Datas = [];
    Datas = [...Data]
    let PacketIds = _.map(Datas, 'pktNo')
    let Temp = _.map(TableData, 'pktNo')
    if (Temp.length > 0) {
      let Atemp1 = _.map(_.filter(Atemp, (d) => d.isPlanning === false), 'pktNo')
      PacketIds.push(...Atemp1)
    }
    PacketIds.push(...Temp)
    PacketIds = _.uniq(PacketIds)
    let StringData = ''
    if (PacketIds.length === 26) {
      Count = 0;
      StringData = `${packetNoName}${String.fromCharCode(66)}${String.fromCharCode(66 + Count)}`;
    } else if (PacketIds.length >= 26) {
      StringData = `${packetNoName}${String.fromCharCode(66)}${String.fromCharCode(66 + Count)}`;
    } else if (PacketIds.length <= 26) {
      StringData = `${packetNoName}${String.fromCharCode(66 + Count)}`
    }
    Count = Count + 1
    if (!PacketIds.includes(StringData)) {
      return StringData
    }
    else {
      return GetPacketNo(count, Data)
    }
  }

  const SetPacketNo = (Count, Data) => {
    let packetName1 = GetPacketNo(Count, Data)
    let TempData = [...Data]
    let Temp = TempData[TempData.length] = {
      "pktNo": packetName1
    }
    TempData.push(Temp)
    setData(TempData)
    SetpacketName(packetName1)
    return Data
  }
  useEffect(() => {
    SetPacketNo(Count, TableData)
  }, [TableData])

  useEffect(() => {
    if (Atemp.length > 0) {
      let attmpData = _.filter(_.cloneDeep(Atemp), (d) => d.isPlanning === false)
      setData(attmpData)
      SetPacketNo(Count, attmpData)
    }
  }, [Atemp])

  if (loading) {
    return <CircularProgress color={"white"} size={16} />
  } else
    return (
      <>
        <Grid xs={12} container style={{ marginTop: "0px !important" }}>
          <Grid xs={1}>
            <Button
              onClick={(e) => { SaveButtonHandler(e) }}
              variant="contained"
              color="primary"
              className={classes.button}
              startIcon={<SaveIcon />}
            >
              Save
            </Button>
          </Grid>
          <Grid xs={1}>
            <Button
              onClick={(e) => { DeleteButtonHandler(e) }}
              variant="contained"
              color="secondary"
              disabled={SelectedRows && SelectedRows.length > 0 ? false : true}
              className={classes.button}
              startIcon={<DeleteIcon />}
            >
              Delete
            </Button>
          </Grid>
          <Grid xs={3}>

          </Grid>


          <Grid xs={2}>
            Lot No : {SubPacketMasterData?.lotNoId?.lotNo}
          </Grid>
          <Grid xs={2}>
            Pkt No : {SubPacketMasterData?.pktNoId?.pktNo}
          </Grid>


          <Grid xs={2}>
            Our Rough Cts :{SubPacketMasterData?.issueCts}
          </Grid>
          <Grid xs={1}>
            Iss Cts : {SubPacketMasterData?.retCts}
          </Grid>

        </Grid>
        <Grid>
          <Grid xs={12} container>
            <Grid xs={3} >
              <p style={{ marginTop: 30 }}>Remain Cts : {SubPacketMasterData?.lossCts}</p>
            </Grid>
            <Grid xs={2}>
              <form className={classes.root} noValidate autoComplete="off">
                <TextField id="standard-basic" label="Sub Pkt Cts" onChange={(e) => {
                  SetSubPacketCts(e.target.value)
                }} type="text" style={{ width: 150, background: "white" }} />
              </form>
            </Grid>
            <Grid xs={2}>
              <form className={classes.root} noValidate autoComplete="off">
                <TextField id="standard-basic" value={packetName} label="Sub Pkt No" type="text" style={{ width: 150, background: "white" }} disabled />
              </form>
            </Grid>
            <Grid xs={2}>
              <form className={classes.root} noValidate autoComplete="off">
                <TextField id="standard-basic" label="Packet Type" type="text" style={{ width: 150, background: "white" }} value={SubPacketMasterData?.pktType} disabled />
              </form>
            </Grid>
            <Grid xs={2}>

            </Grid>
            <Grid xs={1}>
              <p style={{ marginTop: 30 }}> Jagad No : []</p>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justify="flex-end" item xs={12} >
          <div className="SubPacketTable" >
            <MaterialTable
              columns={columns}
              data={TableData}
              options={{
                emptyRowsWhenPaging: false,
                padding: 'dense',
                pageSize: 10,
                selection: true,
                pageSizeOptions: [5, 10, 15, 20, 25, 50],
                addRowPosition: 'first',
                filtering: false,
                sorting: false,
                search: false,
                paging: false,
                filterCellStyle: { padding: '5px', },
                maxBodyHeight: window.innerHeight - 215,
                showTitle: false,
                headerStyle: { backgroundColor: 'whitesmoke' }
              }}
              onSelectionChange={(rows) => {
                setSelectedRows(rows)
              }}
            />
          </div>
        </Grid>
      </>
    )
}
export default SubPacket