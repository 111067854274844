import gql from 'graphql-tag';

export const PACKETMASTER = gql`
    query getPacketMaster($page: Int, $limit: Int, $filter: String, $sort: packetMasterSort){
      getPacketMaster(page:$page,limit:$limit,filter:$filter,sort:$sort){
        count
        data{
          id
          transNo
          transDate
          lotNoId{
            id
            lotNo
          }
          totalPcs
          totalCts
          totalCost
          partyId{
            id
            partyName
          }
          tenderId
          Remark
          entryType
          pktPartyName
          createdBy{
            userName
          }
          updatedBy
          createdAt
          updatedAt

        }
      }
    }
`;
export const LOTDATA = gql`
query getAllKapanDetail($type:String){
  getAllKapanDetail(type:$type){
    id
    lotNo
  }
}
`;
export const LOTNOWITHPARTY = gql`
query getLotNoWithParty($id: ID, $type: String, $issuereturnMasterId: ID,$isRoughStockDetail:Boolean) {
  getLotNoWithParty(id: $id, type: $type, issuereturnMasterId: $issuereturnMasterId,isRoughStockDetail:$isRoughStockDetail) {
    id
    lotNo
    pcs
    cts
  }
}
`;
export const KAPANPARTYS = gql`
query getOnlyKapanParty{
  getOnlyKapanParty{
    id
    partyName
  }
}
`;

export const PACKETDETAIL = gql`
query  getAllPacketDetail($id: ID , $page: Int, $limit: Int, $filter: String, $sort: packetDetailSort) {
  getAllPacketDetail(id:$id , page:$page , limit:$limit , filter:$filter , sort:$sort){
  count
  data {
    id
    packetMasterId
    srNo
    pktNo
    pcs
    cts
    status
    colorId {
      id
      shortName
    }
    shapeId {
      id
      shortName
    }
    bcCode
    flsId {
      id
      shortName
    }
    subPktMasterId{
      id
      pktNo
    }
    isSubPkt
    tensionId {
      id
      shortName
    }
    majuriRate
    zarRate
    purityId {
      id
      shortName
    }
    expcts
    isPlanning
    subPkt
    totalSubPacket
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
  totalCts
  totalExpCts
}
}
`;

export const ROUGHLOTDATA = gql`
query getAllRoughKapan{
  getAllRoughKapan{
    id
    lotNo
  }
}
`;
export const MAJURILOTDATA = gql`
query getAllMajuriKapan($id: ID){
  getAllMajuriKapan(id: $id){
    id
    lotNo
  }
}
`;
