import gql from 'graphql-tag';



export const UPDATE_RAPAPORT = gql`
mutation updateRapaportUser($input: rapaportUserUpdate!){
  updateRapaportUser(input:$input){
    id
    companyId
    rapaportUsername
    password
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}
`;

export const DELETE_RAPAPORT = gql`
  mutation deleteRapaportUser($id: ID!){
    deleteRapaportUser(id:$id)
  }
`;

export const UPDATE_PRICELIST = gql`
  mutation updatePriceList{
    updatePriceList
  }
`;
