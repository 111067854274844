import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { useQuery } from "@apollo/react-hooks";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import { GETPURCHASEVSSALESREPORT } from "./PurchaseSalesQuery";
import _ from "lodash";
import { LotNo } from "../../../components/DropdownSelect/LotNo";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { CompanyAndPartyAllData } from '../../../components/DropdownSelect/SelectComponents/companyAndPartySelectComponent';

import "./purchase.css";
import { Tofixed } from "../../../components/Comman/Tofix";
import { Accordion, AccordionSummary, AccordionDetails, useStyles } from '../../../components/Comman/Accordian'
import GetAppIcon from '@material-ui/icons/GetApp';
import { CommponExport } from '../../../components/ExportReport/CommponExport'
import { ExportCsv } from '../../../components/ExportReport/ExportCsv'
import { PrintDiv } from '../../../components/Comman/exportPdfAndExcel'
import * as XLSX from 'xlsx/xlsx.mjs';
import moment from 'moment';
import ExcelPng from '../../../../Excel.png'
const columns = [
  {
    title: "Purchase Type",
    field: "purType",
    headerStyle: {
      backgroundColor: "rgb(3, 155, 229)"
    }
  },
  {
    title: "Purchase Date",
    field: "purDate",
    render: (rowData) => rowData.purDate === null || rowData.purDate === undefined ? "" : moment(rowData?.purDate).format("DD/MM/YYYY"),
    headerStyle: { backgroundColor: "rgb(3, 155, 229)" }
  },
  {
    title: "Party Name",
    field: "partyId.partyName", cellStyle: { minWidth: 250 },
    headerStyle: { backgroundColor: "rgb(3, 155, 229)" }
  },
  {
    title: "Rough Cost($)",
    field: "roughCostDollar",
    render: (rowData) => (rowData?.purType === "PURCHASE") ? Tofixed(rowData?.purchaseCostDollar || 0) : Tofixed(rowData?.roughCostDollar || 0),
    headerStyle: { backgroundColor: "rgb(3, 155, 229)" }
  },
  {
    title: "Rough Cost(ZAR)",
    field: "roughCostZar",
    render: (rowData) => Tofixed(rowData?.roughCostZar || 0),
    headerStyle: { backgroundColor: "rgb(3, 155, 229)" }
  },
  {
    title: "Packet No",
    field: "pktNo",
    headerStyle: { backgroundColor: "rgb(3, 155, 229)" }
  },
  {
    title: "Rough Cts",
    field: "roughCts",
    render: (rowData) => Tofixed(rowData?.roughCts),
    headerStyle: { backgroundColor: "rgb(3, 155, 229)" }
  },
  {
    title: "Department Name",
    field: "departmentId",
    headerStyle: { backgroundColor: "#00c9" },
    render: (rowData) => {
      if (rowData?.departmentId) { return rowData?.departmentId.deptName; }
      else { return rowData?.issueParty; }
    },
  },
  {
    title: "Process Name",
    field: "process",
    headerStyle: { backgroundColor: "#00c9" },
    render: (rowData) => {
      if (rowData?.process) { return rowData?.process.procName }
      else { return rowData?.processString || "SOLD" }
    }
  },
  {
    title: "Cts",
    field: "issueCts",
    render: (rowData) => Tofixed(rowData?.issueCts),
    headerStyle: { backgroundColor: "#00c9" }
  },
  {
    title: "Bill No",
    field: "billNo",
    headerStyle: { backgroundColor: "rgba(136, 153, 102, 0.8)" }
  },
  {
    title: "Bill Date",
    field: "billDate",
    render: (rowData) => {
      let DataDate = rowData?.billDate || null;
      if (DataDate) return moment(DataDate).format("DD/MM/YYYY");
      else return "";
    },

    headerStyle: { backgroundColor: "rgba(136, 153, 102, 0.8)" }
  },
  {
    title: "Party Name",
    field: "partyId",
    render: (rowData) => rowData?.salesParty || "", cellStyle: { minWidth: 250 },
    headerStyle: { backgroundColor: "rgba(136, 153, 102, 0.8)" }
  },
  {
    title: "Cts",
    field: "salesCts",
    render: (rowData) => Tofixed(rowData?.salesCts),
    headerStyle: { backgroundColor: "rgba(136, 153, 102, 0.8)" }
  },
  {
    title: "Rap Price",
    field: "rapePrice",
    render: (rowData) => Tofixed(rowData?.rapePrice),
    headerStyle: { backgroundColor: "rgba(136, 153, 102, 0.8)" }
  },
  {
    title: "Back %",
    field: "backPer",
    render: (rowData) => Tofixed(rowData?.backPer),
    headerStyle: { backgroundColor: "rgba(136, 153, 102, 0.8)" }
  },
  {
    title: "Amount($)",
    field: "amtDollar",
    render: (rowData) => Tofixed(rowData?.amtDollar),
    headerStyle: { backgroundColor: "rgba(136, 153, 102, 0.8)" }
  },
  {
    title: "Rate",
    field: "rateZar",
    render: (rowData) => Tofixed(rowData?.rateZar),
    headerStyle: { backgroundColor: "rgba(136, 153, 102, 0.8)", }
  },
  {
    title: "Amount(Zar)",
    field: "amtZar",
    render: (rowData) => Tofixed(rowData?.amtZar),
    headerStyle: { backgroundColor: "rgba(136, 153, 102, 0.8)" }
  },
  {
    title: "Commission Amount",
    field: "commision",
    render: (rowData) => Tofixed(rowData?.commision),
    headerStyle: { backgroundColor: "rgba(136, 153, 102, 0.8)" }
  },
  {
    title: "Total Amount(Zar) ",
    field: "totAmtZar",
    render: (rowData) => Tofixed(rowData?.totAmtZar),
    headerStyle: { backgroundColor: "rgba(136, 153, 102, 0.8)" }
  },
];


function PurchaseSales() {
  const classes = useStyles();
  const tableRef = useRef();

  const [filter, setFilter] = useState({});
  const [partyName, setPartyName] = useState("");
  const [lotNo, setLotNo] = useState("");
  const [AllData, setAllData] = useState([]);
  const { error, data, refetch, loading } = useQuery(GETPURCHASEVSSALESREPORT, {
    variables: { filter: JSON.stringify(filter) },
    fetchPolicy: "cache-and-network",
  });


  useEffect(() => {
    tableRef.current && tableRef.current.onQueryChange();
  }, [data]);

  const getPurchaseAndSalesReportAllData = () => {
    return new Promise((resolve, reject) => {
      if (error) {
        reject(error);
      } else {
        let result = _.orderBy(data?.getPurchaseVsSalesReport || [], ['pktNo'])
        let GetGrandTotalData = result.filter((d) => d.purType === "Grand Total");
        if (result.length !== 0 && GetGrandTotalData.length === 0) {
          let LastRow = {
            purType: "Grand Total",
            roughCostDollar: Tofixed(_.sum(_.map(result, "roughCostDollar"))),
            roughCostZar: Tofixed(_.sum(_.map(result, "roughCostZar"))),
            roughCostRs: Tofixed(_.sum(_.map(result, "roughCostRs"))),
            pktNo: result.length,
            process: { procName: result.length },
            issueCts: Tofixed(_.sum(_.map(result, "issueCts"))),
            salesCts: Tofixed(_.sum(_.map(result, "salesCts"))),
            amtDollar: Tofixed(_.sum(_.map(result, "amtDollar"))),
            amtZar: Tofixed(_.sum(_.map(result, "amtZar"))),
            commision: Tofixed(_.sum(_.map(result, "commision"))),
            totAmtZar: Tofixed(_.sum(_.map(result, "totAmtZar"))),
            roughCts: Tofixed(_.sum(_.map(result, "roughCts"))),
            partyId: { partyName: _.filter(result, (d) => d?.partyId?.partyName !== null).length },
          };
          result.push(LastRow);
        }
        let Data = _.cloneDeep(result)
        setAllData(Data)
        resolve({
          data: result,
        });
      }
    });
  };

  let defaultProps = LotNo("", "", "").defaultProps;
  let parties = CompanyAndPartyAllData("PARTY")

  const PreViewHandler = () => {
    let Data = {};
    if (lotNo) {
      Data = { ...Data, lotNoId: lotNo.id };
    }
    if (partyName) {
      Data = { ...Data, partyId: partyName.id };
    }
    localStorage.setItem("PurchaseVSSalesReport", JSON.stringify(Data))
    setFilter(Data, () => {
      refetch();
    });
  };
  const ClearViewHandler = () => {
    setLotNo('')
    setPartyName('')
    localStorage.removeItem('PurchaseVSSalesReport')
    setAllData([])
    setFilter({}, () => {
      refetch()
    })
  }

  useEffect(() => {
    let Data = JSON.parse(localStorage.getItem("PurchaseVSSalesReport"))
    setLotNo(_.find(defaultProps.options, (d) => d.id === Data?.lotNoId))
    setPartyName(_.find(parties, (d) => d.id === Data?.partyId))
    if (Data)
      setFilter(Data)
  }, [])


  const pdfHeader = () => {
    return <div style={{ marginBottom: 15 }}>
      <h2 style={{ textAlign: "center" }}>Purchase VS Sales Report</h2>
      <p style={{ margin: 0, fontSize: 18, fontWeight: "bold" }}>Party : {partyName?.partyName || "All"}</p>
      < p style={{ margin: 0, fontSize: 18, fontWeight: "bold" }}>Lotno : {lotNo?.lotNo || "All"}</p>
    </div >
  }
  const TableHeader = () => {
    return <>
      <tr>
        <td colSpan={columns.length}>
          <h2 style={{ textAlign: "center" }}>Purchase VS Sales Report</h2>
        </td>
      </tr>
      <tr >
        <td colSpan={columns.length}>
          <p style={{ margin: 0, fontSize: 18, fontWeight: "bold" }}>Party : {partyName?.partyName || "All"}</p>
        </td>
      </tr>
      <tr>
        <td colSpan={columns.length}>
          < p style={{ margin: 0, fontSize: 18, fontWeight: "bold" }}>Lotno : {lotNo?.lotNo || "All"}</p>
        </td>

      </tr>
    </>
  }

  function exportExcelFile(workbook) {
    return XLSX.writeFile(workbook, `PurchaseSalesReport${moment()}.xlsx`);
  }

  const exportCsvData = () => {
    var workbook = XLSX.utils.book_new();
    var worksheet_data = document.getElementById("table-to-xls");
    var worksheet = XLSX.utils.table_to_sheet(worksheet_data);
    workbook.SheetNames.push("Test");
    workbook.Sheets["Test"] = worksheet;
    exportExcelFile(workbook);
  }

  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="AccordianHeader"
        >
          <Grid container className="mt-0">
            <Grid xs={11} item container justify="center" className="mt-0">
              <Typography className={classes.heading}>
                Purchase VS Sales Report
              </Typography>
            </Grid>
            <Grid xs={1} item container justify="flex-end" className="mt-0">
              <Typography className={classes.heading}>Filter</Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid xs={12} item container justify="space-between">
              <Grid xs={4} item></Grid>
              <Grid xs={1} item>
                <Autocomplete
                  value={lotNo}
                  {...defaultProps}
                  onChange={(e, newdata) => {
                    if (newdata) {
                      setLotNo(newdata);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField label="Lot No" {...params} margin="normal" />
                  )}
                />
              </Grid>
              <Grid xs={1} item>
                <Autocomplete
                  value={partyName}
                  options={parties}
                  getOptionLabel={(option) => option.partyName}
                  onChange={(e, newdata) => {
                    if (newdata) {
                      setPartyName(newdata);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      label="Party Name"
                      {...params}
                      margin="normal"
                    />
                  )}
                />
              </Grid>
              <Grid xs={5} item style={{ marginLeft: 0 }}>
                <Button
                  onClick={() => PreViewHandler()}
                  variant="contained"
                  color="primary"
                  style={{ marginTop: 29 }}
                >
                  Preview
                </Button>
                <Button
                  onClick={() => ClearViewHandler()}
                  variant="contained"
                  color="primary"
                  style={{ height: 34, marginLeft: 10, marginTop: 16 }}>
                  clear
                </Button>
              </Grid>
              <Grid xs={4} item>
              </Grid>
            </Grid>
          </MuiPickersUtilsProvider>
        </AccordionDetails>
      </Accordion>

      <Grid container>
        <Grid item xs={12} style={{ position: "relative" }}>
          <img className="download-table-xls-button" src={ExcelPng} alt="alternative text" title="csv" onClick={() => exportCsvData()} />
          <ExportCsv
            TableHeader={TableHeader()}
            columns={columns}
            AllData={AllData.filter(d => {
              d["partyId.partyName"] = d?.partyId?.partyName
              d["lotNoId.lotNo"] = d?.lotNoId?.lotNo
              d["pktNoId.pktNo"] = d?.pktNoId?.pktNo
              return d
            })}
          />
          <MaterialTable
            title="Purchase VS Sales Report"
            columns={columns}
            isLoading={loading}
            tableRef={tableRef}
            data={(query) => getPurchaseAndSalesReportAllData()}
            actions={[
              {
                icon: 'print',
                tooltip: 'PRINT',
                isFreeAction: true,
                onClick: (event) => PrintDiv()
              },
              // {
              //   icon: GetAppIcon,
              //   tooltip: 'EXPORT',
              //   isFreeAction: true,
              //   onClick: (event) => exportTableToExcel("ExportTable", `Purchase_VS_Sales_Report_${moment()}`, event)
              // }
            ]}
            options={{
              emptyRowsWhenPaging: false,
              padding: "dense",
              addRowPosition: "first",
              filtering: false,
              sorting: true,
              search: false,
              filterCellStyle: { padding: "5px" },
              maxBodyHeight: window.innerHeight - 215,
              paging: false,
              rowStyle: (rowData) => ({
                backgroundColor: rowData.purType === "Grand Total" ? "rgb(212, 218, 255)" : "#FFF",
                fontWeight: rowData.purType === "Grand Total" && 'bolder'

              }),
            }}
          />
        </Grid>
      </Grid>
      <CommponExport
        columns={columns}
        pdfHeader={pdfHeader()}
        // AllData={AllData}
        AllData={AllData.filter(d => {
          if (d.date !== "Grand Total") {
            d["partyId.partyName"] = d?.partyId?.partyName
            d["process.procName"] = d?.process?.procName
            if (d?.departmentId) { d["departmentId"] = d?.departmentId.deptName; }
            else { d["departmentId"] = d?.issueParty; }
            if (d?.process) { d["process"] = d?.process.procName }
            else { d["process"] = d?.processString || "SOLD" }
            d["partyId"] = d?.salesParty || ""
            d.roughCostDollar = (d?.purType === "PURCHASE") ? Tofixed(d?.purchaseCostDollar || 0) : Tofixed(d?.roughCostDollar || 0)
            d.roughCostZar = Tofixed(d?.roughCostZar || 0)
            d.roughCts = Tofixed(d?.roughCts || 0)
            d.issueCts = Tofixed(d?.issueCts || 0)
            d.salesCts = Tofixed(d?.salesCts || 0)
            d.rapePrice = Tofixed(d?.rapePrice || 0)
            d.backPer = Tofixed(d?.backPer || 0)
            d.amtDollar = Tofixed(d?.amtDollar || 0)
            d.rateZar = Tofixed(d?.rateZar || 0)
            d.amtZar = Tofixed(d?.amtZar || 0)
            d.commision = Tofixed(d?.commision || 0)
            d.totAmtZar = Tofixed(d?.totAmtZar || 0)
          }
          return d
        })}
      />
    </div>
  );
}
export default withRouter(PurchaseSales);
