import React, { useRef, useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { withRouter } from "react-router-dom";
import { Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery, useMutation } from '@apollo/react-hooks'
import { Inwardchild } from './InwardChild'
import { INWARD } from './InwordQuery'
import { ADD_INWARDMASTER, UPDATE_INWARDMASTER, DELETE_INWARDMASTER } from './InwardMutation'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { TermsSelectComponent, TransAllData } from '../../../components/DropdownSelect/SelectComponents/TermsSelectComponent'
import { CompanyAndPartySelectComponent, CompanyAndPartyAllData } from '../../../components/DropdownSelect/SelectComponents/companyAndPartySelectComponent'
import { BrokerSelectComponent, BrokerSelectData } from '../../../components/DropdownSelect/SelectComponents/BrokerSelectComponent'
import { BillType, InwardType } from '../../../components/Constant/index'
import moment from 'moment'
import { FormatError } from '../../../components/Comman/FormatError'
import { Tofixed } from '../../../components/Comman/Tofix'
import { UserListAllData } from "../../../components/DropdownSelect/SelectComponents/UserNameSelectComponent";
import { headerStyle, cellStyle, headerStyle170, headerStyle172 } from '../../../components/Comman/StyleCell'
import { CustomFilterComponent } from '../../../components/CustomFilterComponent/index'
const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1, },
  noMargin: { margin: 0 }
}));

const amountTotalNet = (rowData) => {
  let brinksCharges = rowData?.brinksCharges || 0
  let dbCharges = rowData?.dbCharges || 0
  let bankCharges = rowData?.bankCharges || 0
  let netAmount = rowData?.netAmount || 0
  return parseFloat(netAmount + bankCharges + dbCharges + brinksCharges).toFixed(2)
}

const columns = [
  {
    title: 'Inward No', field: 'inwardNo', type: 'numeric',
    editable: "never"
  }, {
    title: 'Inward Type* ',
    field: 'inwardType',
    cellStyle: {
      minWidth: 180
    },
    validate: rowData => (rowData.inwardType && rowData.inwardType !== "") ? true : { isValid: false, helperText: `can't be empty` },
    lookup: InwardType
  },
  {
    title: 'Date*',
    field: 'date',
    type: "date",
    initialEditValue: moment(),
    render: (rowData) => {
      if (rowData.date)
        return moment(rowData?.date || 0).format('DD/MM/YYYY')
    },
    validate: rowData => (rowData.date && rowData.date !== "") ? true : { isValid: false, helperText: `can't be empty` },
  },
  {
    title: 'Party Name*',
    field: 'partyId',
    headerStyle: headerStyle,
    cellStyle: cellStyle,
    render: (rowData) => rowData?.partyId?.partyName || '-',
    editComponent: props => {
      let valueDefault = props?.rowData?.partyId?.id || props?.rowData?.partyId || '0'
      return <CompanyAndPartySelectComponent QueryName={"PARTY"} valueDefault={valueDefault} prosData={props} />
    }
  },
  {
    title: 'Terms*',
    field: 'termId',
    headerStyle: headerStyle,
    cellStyle: cellStyle,
    render: (rowData) => rowData?.termId?.termName || 'NO ANY TERMS',
    editComponent: props => {
      let valueDefault = props?.rowData?.termId?.id || null
      return <TermsSelectComponent filed="TermsWithdueDays" valueDefault={valueDefault} props={props} />
    }
  },
  {
    title: 'Less%(1)',
    field: 'lessPer1',
    type: 'numeric',
    editComponent: props => {
      return <TextField defaultValue={props?.rowData?.lessPer1 || 0} type="number" onChange={(e) => {
        let Data = { ...props.rowData }
        Data.lessPer1 = e.target.value
        if (Data.lessPer1 >= 0 && Data.lessPer1 <= 100) {
          props.onRowDataChange(Data)
        } else {
          toast.warn("please, enter lessPer1 percentage value between 0 to 100.")
        }
      }} />;
    }
  },
  {
    title: 'Less%(2)',
    field: 'lessPer2',
    type: 'numeric',
    editComponent: props => {
      return <TextField defaultValue={props?.rowData?.lessPer2 || 0} type="number" onChange={(e) => {
        let Data = { ...props.rowData }
        Data.lessPer2 = e.target.value
        if (Data.lessPer2 >= 0 && Data.lessPer2 <= 100) {
          props.onRowDataChange(Data)
        } else {
          toast.warn("please, enter broker percentage value between 0 to 100.")
        }
      }} />;
    }
  },
  {
    title: 'Broker Name*',
    field: 'brokerId',
    headerStyle: headerStyle170,
    cellStyle: {
      minWidth: 175
    },
    render: (rowData) => rowData?.brokerId?.partyName || 'NO ANY BROKER',
    editComponent: props => {
      let valueDefault = props?.rowData?.brokerId?.id || null
      return <BrokerSelectComponent props={props} valueDefault={valueDefault} TYPE={"BROKER"} />
    }
  },
  {
    title: 'Invoice No',
    field: 'invoiceNo',
  },
  {
    title: 'Due Days',
    field: 'dueDays',
    type: 'numeric',
    editComponent: (props) => props.rowData?.dueDays || 0
  },
  {
    title: 'Due Date*',
    field: 'dueDate',
    type: 'date',
    render: (rowData) => rowData?.dueDate ? moment(rowData.dueDate).format('DD/MM/YYYY') : '',
    editComponent: (props) => {
      const today = moment();
      return today.format('DD/MM/YYYY')
    }
  },
  {
    title: 'Bill type*',
    field: 'billType',
    lookup: BillType,
    validate: rowData => (rowData.billType && rowData.billType !== "") ? true : { isValid: false, helperText: `can't be empty` },
  },
  {
    title: 'Salesman Name*',
    field: 'salesmanId',
    headerStyle: headerStyle172,
    cellStyle: {
      minWidth: 200
    },
    render: (rowData) => rowData?.salesmanId?.partyName || 'NO ANY SALESMAN',
    editComponent: props => {
      let valueDefault = props?.rowData?.salesmanId?.id || null
      localStorage.setItem('Broker', valueDefault)
      return <BrokerSelectComponent props={props} valueDefault={valueDefault} TYPE={"SALESMAN"} />
    }
  },
  {
    title: 'Prefix',
    field: 'prefix'
  },
  {
    title: 'Comment',
    field: 'comment',
    headerStyle: headerStyle,
    cellStyle: cellStyle,
  },
  {
    title: 'Total Packets',
    field: 'totalPackets',
    type: 'numeric',
    editComponent: (props) => props.rowData?.totalPackets || 0
  },
  {
    title: 'Total CTS',
    field: 'totalCts',
    type: 'numeric',
    render: (rowData) => Tofixed(rowData?.totalCts || 0),
    editComponent: (props) => props.rowData?.totalCts || 0
  },
  {
    title: 'Net Amt.',
    field: 'netAmount',
    type: 'numeric',
    render: (rowData) => Tofixed(rowData?.netAmount || 0),
    editComponent: (props) => props.rowData?.netAmount || 0
  },
  {
    title: 'Zar Amt.',
    field: 'amountZar',
    type: 'numeric',
    render: (rowData) => Tofixed(rowData?.amountZar || 0),
    editComponent: (props) => props.rowData?.amountZar || 0
  },
  {
    title: 'Brinks charges',
    field: 'brinksCharges',
    render: (rowData) => Tofixed(rowData?.brinksCharges || 0),
    editComponent: (props) => {
      //   let valueDefault = props?.rowData?.brokerPer || 0;
      return (
        <TextField
          defaultValue={props?.rowData?.brinksCharges || 0}
          type="number"
          onChange={(e) => {
            let Data = { ...props.rowData };
            Data.brinksCharges = e.target.value;
            props.onRowDataChange(Data);
          }}
        />
      );
    },
    type: 'numeric'
  },
  {
    title: 'D.B Changes',
    field: 'dbCharges',
    render: (rowData) => Tofixed(rowData?.dbCharges || 0),
    type: 'numeric',
    editComponent: (props) => {
      //   let valueDefault = props?.rowData?.brokerPer || 0;
      return (
        <TextField
          value={props?.rowData?.dbCharges}
          type="number"
          onChange={(e) => {
            let Data = { ...props.rowData };
            Data.dbCharges = e.target.value;
            props.onRowDataChange(Data);
          }}
        />
      );
    },
  },
  {
    title: 'Bank Changes',
    field: 'bankCharges',
    render: (rowData) => Tofixed(rowData?.bankCharges || 0),
    type: 'numeric',
    editComponent: (props) => {
      //   let valueDefault = props?.rowData?.brokerPer || 0;
      return (
        <TextField
          value={props?.rowData?.bankCharges}
          type="number"
          onChange={(e) => {
            let Data = { ...props.rowData };
            Data.bankCharges = e.target.value;
            props.onRowDataChange(Data);
          }}
        />
      );
    },
  },
  {
    title: 'Total Net Amount',
    field: 'amountTotalNet',
    render: (rowData) => Tofixed(rowData?.amountTotalNet || 0),
    type: 'numeric',
    editComponent: (props) => {
      return amountTotalNet(props.rowData)
    }
  },
  { title: "User", field: "createdBy", render: (rowData) => rowData?.createdBy?.userName || " ", editable: "never" }

]

function Inward(props) {
  const classes = useStyles();
  const tableRef = useRef();

  const [pageSize, setPageSize] = useState(10)
  const [selectedRow, setSelectedRow] = useState(null);

  const permissions = props?.session?.me?.role?.permissions;
  const role = props?.session?.me?.role?.role;
  const path = props?.location?.pathname;
  let permission = _.find(permissions, function (o) { return o.name === path; })

  const { fetchMore, refetch } = useQuery(INWARD, {
    variables: { page: 1, limit: 10, filter: JSON.stringify({}), sort: { key: 'createdAt', type: -1 } },
    fetchPolicy: 'cache-and-network'
  })

  columns.map(col => {
    if (col.field === 'termId') {
      col.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: TransAllData(), getOptionLabel: (option) => option.termName, multiple: true }} />
      }
    }
    if (col.field === "partyId") {
      col.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: CompanyAndPartyAllData("PARTY"), getOptionLabel: (option) => option.partyName, multiple: true }} />
      }
    }
    if (col.field === "brokerId") {
      col.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: BrokerSelectData("BROKER"), getOptionLabel: (option) => option.partyName, multiple: true }} />
      }
    }
    if (col.field === "salesmanId") {
      col.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: BrokerSelectData("SALESMAN"), getOptionLabel: (option) => option.partyName, multiple: true }} />
      }
    }
    if (col.field === "createdBy") {
      col.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: UserListAllData(), getOptionLabel: (option) => option.userName, multiple: true }} />
      }
    }
    return null
  })

  const [AddInwardMasterMutation] = useMutation(ADD_INWARDMASTER)
  const [UpdateInwardMasterMutation] = useMutation(UPDATE_INWARDMASTER)
  const [DeleteInwardMasterMutation] = useMutation(DELETE_INWARDMASTER)
  const AddInward = (newData) => {
    refetch()
    newData.lessPer1 = parseFloat(Tofixed(newData.lessPer1))
    newData.lessPer2 = parseFloat(Tofixed(newData.lessPer2))
    newData.brinksCharges = parseFloat(Tofixed(newData.bankCharges))
    newData.dbCharges = parseFloat(Tofixed(newData.dbCharges))
    newData.bankCharges = parseFloat(Tofixed(newData.bankCharges))
    newData.dueDate = newData.date;
    newData.dueDays = 0
    return new Promise((resolve, reject) => {
      if (newData.partyId && newData.termId && newData.brokerId && newData.dueDate && newData.salesmanId) {
        let log = {
          action: 'INSERT',
          actionOn: 'InwardMaster',
          actionName: 'addNewInward',
          oldValue: "",
          message: `${newData?.inwardType || ''} inward type was created.`
        }
        AddInwardMasterMutation({ variables: { input: newData, log } }).then((data) => {
          toast.success('inward created successfully.');
          resolve()
        }).catch((e) => {
          reject()
          toast.error(FormatError(e))
        })
      } else {
        reject()
        toast.warn('please, fill all required(*) fields.')
      }
    })
  }
  const UpdateThrueInwardDetail = (newData, oldData) => {
    return new Promise((resolve, reject) => {
      refetch()
      delete newData.createdBy
      delete newData.createdAt
      delete newData.__typename
      delete newData.updatedAt
      delete newData.updatedBy
      newData.termId = newData?.termId?.id ? newData?.termId?.id : newData?.termId || null
      newData.partyId = newData?.partyId?.id ? newData?.partyId?.id : newData?.partyId || null
      newData.brokerId = newData?.brokerId?.id ? newData?.brokerId?.id : newData?.brokerId || null
      newData.salesmanId = newData?.salesmanId?.id ? newData?.salesmanId?.id : newData?.salesmanId || null
      newData.dbCharges = parseFloat(newData.dbCharges)
      newData.bankCharges = parseFloat(newData.bankCharges)
      newData.brinksCharges = parseFloat(newData.bankCharges)
      newData.lessPer1 = parseFloat(newData.lessPer1)
      newData.lessPer2 = parseFloat(newData.lessPer2)

      if (newData.partyId && newData.termId && newData.brokerId && newData.dueDate && newData.salesmanId) {
        UpdateInwardMasterMutation({ variables: { input: newData } }).then((data) => {
          resolve()
        }).catch((e) => {
          reject()
          toast.error(FormatError(e))
        })
      } else {
        reject()
        toast.warn('please, fill all required(*) fields.')
      }
    })
  }
  const UpdateInward = (newData, oldData) => {
    return new Promise((resolve, reject) => {
      delete newData.createdBy
      delete newData.createdAt
      delete newData.__typename
      delete newData.updatedAt
      delete newData.updatedBy
      newData.termId = newData?.termId?.id ? newData?.termId?.id : newData?.termId || null
      newData.partyId = newData?.partyId?.id ? newData?.partyId?.id : newData?.partyId || null
      newData.brokerId = newData?.brokerId?.id ? newData?.brokerId?.id : newData?.brokerId || null
      newData.salesmanId = newData?.salesmanId?.id ? newData?.salesmanId?.id : newData?.salesmanId || null
      newData.dbCharges = parseFloat(newData.dbCharges)
      newData.bankCharges = parseFloat(newData.bankCharges)
      newData.brinksCharges = parseFloat(newData.brinksCharges)
      newData.lessPer1 = parseFloat(newData.lessPer1)
      newData.lessPer2 = parseFloat(newData.lessPer2)
      newData.amountTotalNet = parseFloat(amountTotalNet(newData))
      if (newData.inwardType && newData.date && newData.partyId && newData.termId && newData.brokerId && newData.dueDate && newData.billType && newData.salesmanId) {
        let log = {
          action: 'UPDATE',
          actionOn: 'InwardMaster',
          actionName: 'updateInward',
          oldValue: JSON.stringify(oldData),
          message: `${oldData?.inwardType || ''} inward type was updated.`
        }
        UpdateInwardMasterMutation({ variables: { input: newData, log } }).then((data) => {
          tableRef.current.onQueryChange();
          toast.success('Inward updated successfully.');
          resolve()
        }).catch((e) => {
          reject()
          toast.error(FormatError(e))
        })
      } else {
        reject()
        toast.warn('please, fill all required(*) fields.')
      }
    })
  }
  const DeleteInward = (oldData) => {
    return new Promise((resolve, reject) => {
      refetch()
      let log = {
        action: 'DELETE',
        actionOn: 'InwardMaster',
        actionName: 'deleteInward',
        oldValue: JSON.stringify(oldData),
        message: `${oldData?.inwardType || ''} inward type was deleted.`
      }
      DeleteInwardMasterMutation({ variables: { id: oldData.id, log } }).then((data) => {
        toast.success('Inward deleted successfully');
        resolve()
      }).catch((e) => {
        reject()
        toast.error(FormatError(e))
      })
    })
  }
  const getInwardData = (query) => {
    return new Promise(async (resolve, reject) => {
      let filter = {}, sort = { key: 'createdAt', type: -1 }
      query && query.filters.forEach(item => {
        if ((item.column.field === "date" || item.column.field === "dueDate")) {
          if (item.value)
            filter = { ...filter, [item.column.field]: moment(item.value).format("YYYY-MM-DD") }
          else {
            delete filter[item.column.field]
            filter = { ...filter }
          }
        }
        else if (item.value.length === 0) {
          delete filter[`${item.column.field}`]
          filter = { ...filter }
        } else
          filter = { ...filter, [item.column.field]: item.value }
      });
      if (query && query.orderBy && query.orderDirection !== "") {
        sort = { key: query.orderBy.field, type: query.orderDirection === 'asc' ? 1 : -1 }
      }
      setPageSize(query.pageSize)
      try {
        let result = await fetchMore({
          variables: { page: query.page + 1, limit: query.pageSize, filter: JSON.stringify(filter), sort: sort },
          fetchPolicy: 'network-only',
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return fetchMoreResult;
          }
        })
        resolve({
          data: result?.data?.getInwardMaster?.data || [],
          page: query.page,
          totalCount: result?.data?.getInwardMaster?.count || 0,
        })
      } catch (error) {
        console.log(FormatError(error))
      }
    })
  }

  const addButton = (val) => {
    if (val || (role === 'admin'))
      return { onRowAdd: (newData) => AddInward(newData) }
    else
      return {}
  }
  useEffect(() => {
    document.getElementsByClassName("parentTable")[0].firstElementChild.firstElementChild.firstChild.remove()
  }, [])
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <div className="parentTable">
            <MaterialTable
              title="Inward"
              tableRef={tableRef}
              columns={columns}
              data={query => getInwardData(query)}
              icons={{ Add: props => { return (<div> <i className="fa fa-plus-square" ></i> Inward </div>) } }}
              options={{
                emptyRowsWhenPaging: false,
                showTitle: false,
                debounceInterval: 300,
                padding: 'dense',
                pageSize: pageSize,
                pageSizeOptions: [5, 10, 15, 20, 25, 50],
                addRowPosition: 'first',
                filtering: true,
                sorting: true,
                search: false,
                filterCellStyle: { padding: '5px', },
                maxBodyHeight: window.innerHeight - 215,
                rowStyle: rowData => ({
                  backgroundColor: (selectedRow === rowData.tableData.id) ? 'rgb(212, 218, 255)' : '#f5f5f5'
                }),
                headerStyle: { backgroundColor: 'whitesmoke' }
              }}
              editable={{
                isEditHidden: (rowData) => (role === 'admin') ? false : !permission.isUpdate,
                isDeleteHidden: (rowData) => (role === 'admin') ? false : !permission.isDelete,
                ...addButton(permission?.isInsert),
                onRowUpdate: (newData, oldData) => UpdateInward(newData, oldData),
                onRowDelete: (oldData) => DeleteInward(oldData)
              }}
              detailPanel={rowData => {
                return (
                  <Inwardchild
                    key={rowData.id}
                    session={props?.session}
                    location={props?.location}
                    Ids={rowData.id}
                    InwardMasterData={rowData}
                    UpdateThrueInwardDetail={UpdateThrueInwardDetail}
                    tableRef={tableRef}
                    updateMasterData={query => getInwardData(query)}
                  />
                )
              }}
            onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
            />
          </div>
        </Grid>
      </Grid>
    </div >
  );
}
export default withRouter(Inward)

