import React, { useRef, useEffect, useState } from 'react'
import { Grid, FilledInput } from '@material-ui/core';
import MaterialTable from 'material-table';
import { useQuery, useMutation } from '@apollo/react-hooks'
import { PAYMENTMASTERDETAIL } from './PaymentQuery'
import { PaymentMasterDetailReference } from '../../../components/Constant';
import { ADD_PAYMENTMASTER_DETAIL, DELETE_PAYMENTMASTER_DETAIL, UPDATE_PAYMENTMASTER_DETAIL } from './PaymentMutation'
import { PAYMENTMASTERDETAIL_SUBSCRIBE } from './PaymentSubscription'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { BillNoSelectComponent } from '../../../components/DropdownSelect/SelectComponents/GetBillNoForAccountSection'
import { Tofixed, RemoveDecimmal } from '../../../components/Comman/Tofix';
import { FormatError } from '../../../components/Comman/FormatError'
const NetAmountZarCalculate = (rowData) => {
  let billAmtZar = rowData?.billAmtZar || 0
  let disc = rowData?.disc || 0
  let otherAmt = rowData?.otherAmt || 0
  let roundOff = rowData?.roundOff || 0
  return Tofixed((billAmtZar) - ((billAmtZar * disc) / 100) - otherAmt - roundOff)
}

const NetAmountDiscCalculate = (rowData) => {
  let billAmtZar = rowData?.billAmtZar || 0
  let disc = rowData?.disc || 0
  return Tofixed(((billAmtZar * disc) / 100))
}

const columns = [
  {
    title: 'Bank Name*',
    field: 'bankName',
    editable: 'onAdd',
  },
  {
    title: 'Cheque No.*',
    field: 'chequeNo',
    editable: 'onAdd',
  },
  {
    title: 'Reference',
    field: 'reference',
    initialEditValue: "AGAINST BILL",
    lookup: PaymentMasterDetailReference,
    editable: 'onAdd',
  },
  {
    title: 'Bill No*.',
    field: 'billNo',
    editable: 'onAdd',
  },
  {
    title: 'Bill Amt(Zar).',
    field: 'billAmtZar',
    render: (rowData) => RemoveDecimmal(rowData?.billAmtZar || 0),

  },
  { title: 'Disc', field: 'disc' },
  {
    title: 'Disk Amt.',
    field: 'discAmt',
    render: (rowData) => rowData?.discAmt ? RemoveDecimmal(rowData?.discAmt || 0) : "",
    editComponent: ({ rowData }) => {
      return Math.round(NetAmountDiscCalculate(rowData))
    }
  },
  {
    title: 'Other Amt.',
    field: 'otherAmt',
    render: (rowData) => rowData?.otherAmt ? RemoveDecimmal(rowData?.otherAmt || 0) : "",

  },
  {
    title: 'RoundOff',
    field: 'roundOff',
    render: (rowData) => rowData?.roundOff ? Tofixed(rowData?.roundOff || 0) : "",
  },
  {
    title: 'Net Amount(Zar)',
    field: 'netAmountZar',
    render: (rowData) => RemoveDecimmal(rowData?.netAmountZar || 0),
    editComponent: ({ rowData }) => {
      return Math.round(NetAmountZarCalculate(rowData))
    }
  },
  {
    title: 'Remark',
    field: 'remark'
  },
]

export const PaymemtMasterChild = (props) => {
  const permissions = props?.session?.me?.role?.permissions;
  const role = props?.session?.me?.role?.role;
  const path = props?.location?.pathname;
  let permission = _.find(permissions, function (o) { return o.name === path; })
  const [loader, setLoader] = useState(false);

  const tableRef = useRef();
  const { error, data, subscribeToMore, loading } = useQuery(PAYMENTMASTERDETAIL, {
    variables: { id: props.Ids },
    fetchPolicy: 'cache-and-network'
  })

  useEffect(() => {
    setLoader(loading)
  }, [loading]);

  let BankName = props?.PaymentMasterData?.payMode || null
  let partyId = props?.PaymentMasterData?.partyId?.id || null
  let currency = props?.PaymentMasterData?.currency || null
  let type = props?.PaymentMasterData?.payOff || null
  columns.map((d, key) => {
    if (d.field === "bankName") {
      d.editComponent = (props) => {
        if (BankName === "CASH") {
          return "CASH"
        } else {
          return <FilledInput key={key} type="text"
            style={{ width: 100, background: "white" }} defaultValue={props?.rowData?.bankName || ""} onChange={(e) => {
              let Data = props.rowData
              Data.bankName = e.target.value
              props.onRowDataChange(Data)
            }} />
        }
      }
    }
    if (d.field === "billNo") {
      d.render = (rowData) => {
        return type === "JOB WORK" ? rowData.billName : rowData.billNo
      }
    }

    if (d.field === "chequeNo") {
      d.editComponent = (props) => {
        if (BankName === "CASH") {
          return <FilledInput key={key} type="text"
            style={{ width: 100, background: "white" }} placeholder="Cheque" disabled />
        } else {
          return <FilledInput key={key} type="text"
            style={{ width: 100, background: "white" }} defaultValue={props?.rowData?.bankName || ""} onChange={(e) => {
              let Data = props.rowData
              Data.chequeNo = e.target.value
              props.onRowDataChange(Data)
            }} />
        }
      }
    }
    if (d.field === "billNo") {
      d.editComponent = (props) => {
        if (type) {
          let valueDefault = props?.billNo || ''
          return <BillNoSelectComponent key={key} TYPE={type} currency={currency} PartyId={partyId} valueDefault={valueDefault} prosData={props} />
        } else {
          return "Please Enter Pay Off In master Tabel"
        }
      }
    }
    return null
  })

  const FooterRowCalculation = (rows) => {
    let aa = document.getElementsByClassName('childTable')
    for (let index = 0; index < aa.length; index++) {
      setTimeout(() => {
        if (rows && rows !== 0 && aa[index]) {
          let newD = aa[index].getElementsByClassName('MuiTableBody-root')[0].rows[aa[index].getElementsByClassName('MuiTableBody-root')[0].rows.length - 1]
          if (newD.getElementsByClassName('MuiTableCell-sizeSmall')[0]) {
            newD.getElementsByClassName('MuiTableCell-sizeSmall')[0].innerText = 'Total'
            newD.style.background = 'rgb(212, 218, 255)'
          }
        }
      }, 300);
    }
  }

  const PaymentMasterChildDetail = () => {
    return new Promise((resolve, reject) => {
      if (error) {
        reject(error)
      }
      else {
        let result = [...data?.getPaymentMasterDetails || []]
        if (result.length !== 0) {
          let LastRow = { 'chequeNo': 0, 'billAmtZar': 0, 'netAmountZar': 0 }
          LastRow.netAmountZar = _.sum(_.map(result, 'netAmountZar')).toFixed(2)
          LastRow.billAmtZar = _.sum(_.map(result, 'billAmtZar')).toFixed(2)
          LastRow.chequeNo = result.length
          result.push(LastRow)
        }
        FooterRowCalculation(result.length)

        resolve({
          data: result,
        })
      }
    })
  }
  const [addNewPaymentDetailMutation] = useMutation(ADD_PAYMENTMASTER_DETAIL)
  const [updatePaymentDetailMutation] = useMutation(UPDATE_PAYMENTMASTER_DETAIL)
  const [deletePaymentDetailMutation] = useMutation(DELETE_PAYMENTMASTER_DETAIL)

  const UpdatePaymentMasterCalculation = (Data, type) => {
    let type1 = props?.PaymentMasterData?.payOff || null
    let oldData = props.PaymentMasterData
    let newData1 = oldData
    let result = [...data?.getPaymentMasterDetails || []]

    let TotalZarAmount, billNo = []
    if (type === 'Add') {
      if (result.length === 0) {
        TotalZarAmount = Data.netAmountZar
        newData1.billNo = type1 === "JOB WORK" ? Data.billNoName : Data.billNo
      } else {
        TotalZarAmount = (_.sum(_.map(result, 'netAmountZar'))) + Data.netAmountZar
        if (type1 === "JOB WORK") {
          billNo = (_.map(result, 'billName'))

          billNo[_.map(result, 'billName').length] = Data.billNoName

        } else {
          billNo = (_.map(result, 'billNo'))
          billNo[_.map(result, 'billNo').length] = Data.billNo
        }
        newData1.billNo = _.uniq(billNo).join(',')
      }
    }

    else if (type === 'Update') {
      TotalZarAmount = (_.sum(_.map(_.filter(result, (d) => d.id !== Data.id), 'netAmountZar'))) + Data.netAmountZar
    }

    else if (type === 'Delete') {
      TotalZarAmount = (_.sum(_.map(_.filter(result, (d) => d.id !== Data.id), 'netAmountZar')))
      if (type1 === "JOB WORK") {
        billNo = _.map(_.filter(result, (d) => d.id !== Data.id), 'billName')
        newData1.billNo = _.uniq(billNo).join(',')
      } else {
        billNo = _.map(_.filter(result, (d) => d.id !== Data.id), 'billNo')
        newData1.billNo = _.uniq(billNo).join(',')
      }
    }

    newData1.netAmountZar = TotalZarAmount
    newData1.currencyAmount = parseFloat(Tofixed(TotalZarAmount * newData1.currencyRate))
    let a = newData1.tableData
    delete newData1.tableData
    if (props.UpdateByPaymentMasterDetail(newData1, oldData)) {
      newData1.tableData = a
      return true
    } else {
      return false
    }
  }
  const AddPaymentMasterChild = (newData) => {
    return new Promise((resolve, reject) => {
      if (BankName === "CASH") {
        newData.bankName = "CASH"
      }
      newData.billAmtZar = parseFloat(newData.billAmtZar)
      newData.disc = parseFloat(newData.disc)
      newData.otherAmt = parseFloat(newData.otherAmt)
      newData.roundOff = parseFloat(newData.roundOff)
      newData.roundOff = parseFloat(newData.roundOff)
      newData.discAmt = parseFloat(NetAmountDiscCalculate(newData))
      newData.netAmountZar = parseFloat(NetAmountZarCalculate(newData))
      newData.paymentMasterId = props.Ids
      newData.type = props?.PaymentMasterData?.payOff || null
      setLoader(true)
      if (newData.billNo) {
        if (UpdatePaymentMasterCalculation(newData, 'Add')) {
          addNewPaymentDetailMutation({ variables: { input: newData } }).then((data) => {
            setLoader(false)
            toast.success('payment master detail created successfully.');
            resolve()
          }).catch((e) => {
            setLoader(false)
            reject()
            toast.error(FormatError(e))
          })
        } else {
          setLoader(false)
          toast.error('Error On master Update')
        }
      } else {
        setLoader(false)
        reject()
        toast.warn('please,fill all required(*) fields.')
      }
    })
  }
  const UpdatePaymentMasterChild = (newData, oldData) => {
    return new Promise((resolve, reject) => {
      setLoader(true)
      delete newData.createdBy
      delete newData.createdAt
      delete newData.__typename
      delete newData.updatedBy
      delete newData.updatedAt
      let NewObject = {}
      NewObject.billAmtZar = parseFloat(newData.billAmtZar)
      NewObject.id = newData.id
      NewObject.billNo = newData.billNo
      NewObject.billNoName = newData.billName
      NewObject.disc = parseFloat(newData.disc)
      NewObject.otherAmt = parseFloat(newData.otherAmt)
      NewObject.roundOff = parseFloat(newData.roundOff)
      NewObject.roundOff = parseFloat(newData.roundOff)
      NewObject.discAmt = parseFloat(NetAmountDiscCalculate(newData))
      NewObject.netAmountZar = parseFloat(NetAmountZarCalculate(newData))
      NewObject.paymentMasterId = props.Ids
      NewObject.type = props?.PaymentMasterData?.payOff || null
      if (newData.billNo) {
        if (UpdatePaymentMasterCalculation(NewObject, 'Update')) {
          updatePaymentDetailMutation({ variables: { input: NewObject } }).then((data) => {
            setLoader(false)
            toast.success('payment master detail updated successfully.');
            resolve()
          }).catch((e) => {
            setLoader(false)
            reject()
            toast.error(FormatError(e))
          })
        } else {
          setLoader(false)
          toast.error('Error On master Update')
        }
      } else {
        setLoader(false)
        reject()
        toast.warn('please,fill all required(*) fields.')
      }
    })
  }
  const DeletePaymentMasterChild = (oldData) => {
    return new Promise((resolve, reject) => {
      setLoader(true)
      if (UpdatePaymentMasterCalculation(oldData, 'Delete')) {
        deletePaymentDetailMutation({ variables: { id: oldData.id } }).then((data) => {
          setLoader(false)
          toast.success('payment master detail deleted successfully.');
          resolve()
        }).catch((e) => {
          setLoader(false)
          reject()
          toast.error(FormatError(e))
        })
      } else {
        setLoader(false)
        toast.error('Error On master delete')
      }
    })
  }

  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: PAYMENTMASTERDETAIL_SUBSCRIBE,

      updateQuery: (previousResult, { subscriptionData }) => {

        if (!subscriptionData.data) {
          return previousResult;
        }
        const { PaymentMasterDetailChange } = subscriptionData.data;
        if (PaymentMasterDetailChange && PaymentMasterDetailChange.keyType === "UPDATE") {
          let updatePaymentChildDetailData = PaymentMasterDetailChange && PaymentMasterDetailChange.data;
          let AllPaymentChildDetailData = previousResult.getPaymentMasterDetails;
          let updatedPaymentDetailData = _.map(AllPaymentChildDetailData, (d) => {
            if (d.id === updatePaymentChildDetailData.id) {
              return updatePaymentChildDetailData
            }
            else return d
          })
          return {
            ...previousResult,
            getPaymentMasterDetails: [
              ...updatedPaymentDetailData
            ]
          }
        } else if (PaymentMasterDetailChange && PaymentMasterDetailChange.keyType === "DELETE") {
          let updatePaymentChildDetailData = PaymentMasterDetailChange && PaymentMasterDetailChange.data;
          let AllPaymentChildDetailData = previousResult.getPaymentMasterDetails;
          let updatedPaymentDetailData = _.reject(AllPaymentChildDetailData, d => {
            return d.id === updatePaymentChildDetailData.id
          })
          return {
            ...previousResult,
            getPaymentMasterDetails: [...updatedPaymentDetailData]
          }
        } else if (PaymentMasterDetailChange && PaymentMasterDetailChange.keyType === "INSERT") {
          let PaymentMasterChildDetails = [
            ...[PaymentMasterDetailChange.data],
            ...previousResult.getPaymentMasterDetails,
          ]
          return {
            ...previousResult,
            getPaymentMasterDetails: [
              ...PaymentMasterChildDetails
            ],
          };
        }
      },
    });
    return () => unsubscribe();
  }, [subscribeToMore]);

  useEffect(() => {
    tableRef.current && tableRef.current.onQueryChange()
  }, [data]);

  const addButton = (val) => {
    if (val || (role === 'admin'))
      return { onRowAdd: (newData) => AddPaymentMasterChild(newData) }
    else
      return {}
  }
  return <Grid
    container
    direction="row"
    justify="flex-end" item xs={12} >
    <div className="childTable" >
      <MaterialTable
        tableRef={tableRef}
        columns={columns}
        isLoading={loader}
        data={() => PaymentMasterChildDetail()}
        options={{
          emptyRowsWhenPaging: false,
          padding: 'dense',
          pageSize: 10,
          pageSizeOptions: [5, 10, 15, 20, 25, 50],
          addRowPosition: 'first',
          filtering: false,
          sorting: false,
          search: false,
          paging: false,
          filterCellStyle: { padding: '5px', },
          maxBodyHeight: window.innerHeight - 215,
          showTitle: false,
          headerStyle: { backgroundColor: 'whitesmoke' }
        }}
        editable={{
          isEditHidden: (rowData) => (role === 'admin') ? false : !permission.isUpdate,
          isDeleteHidden: (rowData) => (role === 'admin') ? false : !permission.isDelete,
          ...addButton(permission?.isInsert),
          onRowUpdate: (newData, oldData) => UpdatePaymentMasterChild(newData, oldData),
          onRowDelete: (oldData) => DeletePaymentMasterChild(oldData)
        }}
      />
    </div>
  </Grid>
}