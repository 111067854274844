import gql from 'graphql-tag';

const PROCESS = gql`
  query getProcessMaster($page: Int, $limit: Int, $filter: String, $sort: processMasterSort!){
    getProcessMaster(page:$page, limit:$limit, filter:$filter, sort:$sort){
      count
      data{
        id
        PROCCODE
        procName
        isActive
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
    }
  }
`;

export const AllPROCESSlIST = gql`
  query getAllProcess{
    getAllProcess{
      id
      procName
    }
  }
`;

export default PROCESS

