import { useQuery } from '@apollo/react-hooks'
import { GETSALESSTOCKDETAILFTOMLOTANDPACKETNOSTSTUS } from '../../pages/Transaction/Inward/InwordQuery';
export const SalesStockFromLotPktAndStatus = (status, lotNoId, pktNoId, partyId) => {
  const { data } = useQuery(GETSALESSTOCKDETAILFTOMLOTANDPACKETNOSTSTUS, {
    variables: { status: status, lotNoId: lotNoId, pktNoId: pktNoId, partyId: partyId ? partyId : '' },
    fetchPolicy: 'cache-and-network',
  })
  let Data = data?.getSalesStockFromStatus || []
  return Data
}
