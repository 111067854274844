import { useQuery } from '@apollo/react-hooks'
import ROLES from '../../pages/Master/Roles/RoleQuery';
import { FormatError } from '../Comman/FormatError'
export const RoleAll = () => {
  const { error, data } = useQuery(ROLES, {
    variables: { page: 1, limit: 50, filter: JSON.stringify({}), sort: { key: 'createdAt', type: -1 } },
    fetchPolicy: 'cache-and-network',
  })
  let RoleData = []
  if (error) {
    console.log(FormatError(error));
  } else {
    RoleData = data?.getRoles || []
  }

  return RoleData
}