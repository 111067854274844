import gql from 'graphql-tag';

export const ADD_GRADINGMASTER = gql`
mutation addNewGrading($input: gradingMasterInput!, $log: LogInput){
    addNewGrading(input:$input, log: $log){
      id
      gradNo
      gradDate
      lotNoId
      packetNoId
      shapeId
      colorId
      purityId
      polishId
      flourenceId
      milkyId
      centerBlackId
      centerWhiteId
      tableOpenId
      pavillionId
      weight
      tintId
      cutId
      symmId
      hAndAId
      eyeCleanId
      sideBlackId
      sideWhiteId
      crownOpenId
      comment1
      comment2
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;


export const UPDATE_GRADINGMASTER = gql`
mutation  updateGrading($input: gradingMasterUpdate!, $log: LogInput){
    updateGrading(input:$input, log: $log){
        id
        gradNo
        gradDate
        lotNoId
        packetNoId
        shapeId
        colorId
        purityId
        polishId
        flourenceId
        milkyId
        centerBlackId
        centerWhiteId
        tableOpenId
        pavillionId
        weight
        tintId
        cutId
        symmId
        hAndAId
        eyeCleanId
        sideBlackId
        sideWhiteId
        crownOpenId
        comment1
        comment2
        updatedBy
        updatedAt
    }
  }
`;


export const DELETE_GRADINGMASTER = gql`
  mutation deleteGrading($id: ID!, $log: LogInput){
    deleteGrading(id:$id, log: $log)
  }
`;