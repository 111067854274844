import React, { useRef, useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { withRouter } from "react-router-dom";
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery, useMutation } from '@apollo/react-hooks'
import { CostingDetails } from './CostingChild'
import { COSTING } from './CostingQuery'
import { ADD_COSTINGMASTER, UPDATE_COSTINGMASTER, DELETE_COSTINGMASTER } from './CostingMutation'
import { toast } from 'react-toastify'
import _ from 'lodash'
import { CostType } from '../../../components/Constant/index'
import moment from 'moment'
import { CompanyAndPartySelectComponent, CompanyAndPartyAllData } from '../../../components/DropdownSelect/SelectComponents/companyAndPartySelectComponent'
import { FormatError } from '../../../components/Comman/FormatError'
import { UserListAllData } from "../../../components/DropdownSelect/SelectComponents/UserNameSelectComponent";
import { CustomFilterComponent } from '../../../components/CustomFilterComponent/index'
import { Tofixed } from '../../../components/Comman/Tofix'
import { LotNoSelectComponent, RoughLotNoData } from '../../../components/DropdownSelect/SelectComponents/LotSelectComponent';

const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1, },
  noMargin: { margin: 0 }
}));


const columns = [
  {
    title: 'Invoice No', field: 'invoiceNo', type: "numeric",
    editable: "never"
  },
  {
    title: 'Date*', field: 'date', type: 'date',
    initialEditValue: moment(),
    render: (rowData) => {
      if (rowData.date)
        return moment(rowData?.date || 0).format('DD/MM/YYYY')
    },
    validate: rowData => (rowData.date && rowData.date !== "") ? true : { isValid: false, helperText: `can't be empty` }
  },
  {
    title: 'Lot No*',
    field: 'lotNoId',
    render: (rowData) => rowData?.lotNoId?.lotNo || "",
    editable: "onAdd",
    editComponent: (props) => {
      let valueDefault = props?.rowData?.lotNoId?.id || []
      return <LotNoSelectComponent field="lotNoId" propsData={props} valueDefault={valueDefault} />
    }
  },
  {
    title: 'Party Name*',
    field: 'partyId',
    render: (rowData) => rowData?.partyId?.partyName || 'SELECT PARTY'
    , editable: "onAdd"
    ,
    editComponent: props => {
      let valueDefault = props?.rowData?.partyId?.id || props?.rowData?.partyId || '0'
      return <CompanyAndPartySelectComponent QueryName={"PARTY"} valueDefault={valueDefault} prosData={props} />
    }
  },
  {
    title: 'Cost  Type*',
    field: 'costType',
    lookup: CostType,
    validate: rowData => (rowData.costType && rowData.costType !== "") ? true : { isValid: false, helperText: `can't be empty` }
  },
  {
    title: 'Total Cts',
    field: 'totalCts',
    render: (rowData) => Tofixed(rowData.totalCts),
    editComponent: props => props?.rowData?.totalCts || 0
  },
  {
    title: 'Total Cost $',
    field: 'totalCost',
    render: (rowData) => Tofixed(rowData.totalCost),
    editComponent: props => props?.rowData?.totalCost || 0
  },
  {
    title: 'Total Cost ZAR',
    field: 'totalCostZar',
    render: (rowData) => Tofixed(rowData.totalCostZar),
    editComponent: props => props?.rowData?.totalCostZar || 0
  },
  { title: "User", field: "createdBy", render: (rowData) => rowData?.createdBy?.userName || " ", editable: "never" }

]

function Costing(props) {
  const classes = useStyles();
  const tableRef = useRef();

  const [pageSize, setPageSize] = useState(10)
  const [selectedRow, setSelectedRow] = useState(null);

  const permissions = props?.session?.me?.role?.permissions;
  const role = props?.session?.me?.role?.role;
  const path = props?.location?.pathname;
  let permission = _.find(permissions, function (o) { return o.name === path; })

  const { data, fetchMore } = useQuery(COSTING, {
    variables: { page: 1, limit: 10, filter: JSON.stringify({}), sort: { key: 'createdAt', type: -1 } },
    fetchPolicy: 'cache-and-network'
  })

  columns.map((d, key) => {
    if (d.field === "lotNoId") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent
          propsData={props}
          defaultProps={{
            options: RoughLotNoData(),
            getOptionLabel: (option) => option.lotNo, multiple: true
          }}
        />
      }
    }
    if (d.field === "partyId") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent
          propsData={props}
          defaultProps={{
            options: CompanyAndPartyAllData("PARTY"),
            getOptionLabel: (option) => option.partyName, multiple: true
          }} />
      }
    }
    if (d.field === "createdBy") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent
          propsData={props}
          defaultProps={{
            options: UserListAllData(),
            getOptionLabel: (option) => option.userName, multiple: true
          }} />
      }
    }
    return null
  })
  const [AddCostingMasterMutation] = useMutation(ADD_COSTINGMASTER)
  const [UpdateCostingMasterMutation] = useMutation(UPDATE_COSTINGMASTER)
  const [DeleteCostingMasterMutation] = useMutation(DELETE_COSTINGMASTER)

  const AddCosting = (newData) => {
    return new Promise((resolve, reject) => {
      newData.invoiceNo = parseInt(newData.invoiceNo)
      if (newData.partyId) {
        let log = {
          action: 'INSERT',
          actionOn: 'CostingMaster',
          actionName: 'addNewCosting',
          oldValue: "",
          message: `Costing type ${newData?.costType || ''} was created.`
        }
        AddCostingMasterMutation({ variables: { input: newData, log } }).then((data) => {
          toast.success('Costing created successfully!');
          resolve()
        }).catch((e) => {
          reject()
          toast.error(FormatError(e))
        })
      } else {
        reject()
        toast.warn('please, fill all required(*) fields')
      }
    })
  }

  const UpdateCosting = (newData, oldData) => {
    return new Promise((resolve, reject) => {
      newData.invoiceNo = parseInt(newData.invoiceNo)
      delete newData.createdBy
      delete newData.createdAt
      delete newData.updatedBy
      delete newData.updatedAt
      delete newData.__typename
      newData.partyId = newData?.partyId?.id ? newData?.partyId?.id : newData?.partyId || null
      newData.lotNoId = newData?.lotNoId?.id ? newData?.lotNoId?.id : newData?.lotNoId || null
      if (newData.partyId) {
        let log = {
          action: 'UPDATE',
          actionOn: 'CostingMaster',
          actionName: 'updateCosting',
          oldValue: JSON.stringify(oldData),
          message: `Costing type ${oldData?.costType || ''} was updated.`
        }
        UpdateCostingMasterMutation({ variables: { input: newData, log } }).then((data) => {
          tableRef.current.onQueryChange();
          toast.success('Costing updated successfully!');
          resolve()
        }).catch((e) => {
          reject()
          toast.error(FormatError(e))
        })
      } else {
        reject()
        toast.warn('please, fill all required(*) fields')
      }
    })
  }

  const DeleteCosting = (oldData) => {
    return new Promise((resolve, reject) => {
      let log = {
        action: 'DELETE',
        actionOn: 'CostingMaster',
        actionName: 'deleteCosting',
        oldValue: JSON.stringify(oldData),
        message: `Costing type ${oldData?.costType || ''} was deleted.`
      }
      DeleteCostingMasterMutation({ variables: { id: oldData.id, log } }).then((data) => {
        toast.success('CostingMaster Deleted!');
        resolve()
      }).catch((e) => {
        toast.error(FormatError(e))
        reject()
      })
    })
  }
  const getCostingData = (query) => {
    return new Promise(async (resolve, reject) => {
      let filter = {}, sort = { key: 'createdAt', type: -1 }
      query && query.filters.forEach(item => {
        if ((item.column.field === "date")) {
          if (item.value)
            filter = { ...filter, [item.column.field]: moment(item.value).format("YYYY-MM-DD") }
          else {
            delete filter[item.column.field]
            filter = { ...filter }
          }
        }
        else if ((item.column.field === "invoiceNo")) {
          if (item.value)
            filter = { ...filter, [item.column.field]: parseInt(item.value) }
          else {
            delete filter[item.column.field]
            filter = { ...filter }
          }
        }
        else if ((item.column.field === "totalCts" || item.column.field === "totalCost" || item.column.field === "totalCostZar")) {
          if (item.value)
            filter = { ...filter, [item.column.field]: parseFloat(item.value) }
          else {
            delete filter[item.column.field]
            filter = { ...filter }
          }
        }
        else if (item.value.length === 0) {
          delete filter[`${item.column.field}`]
          filter = { ...filter }
        } else
          filter = { ...filter, [item.column.field]: item.value }
      });
      if (query && query.orderBy && query.orderDirection !== "") {
        sort = { key: query.orderBy.field, type: query.orderDirection === 'asc' ? 1 : -1 }
      }
      setPageSize(query.pageSize)
      try {
        let result = await fetchMore({
          variables: { page: query.page + 1, limit: query.pageSize, filter: JSON.stringify(filter), sort: sort },
          fetchPolicy: 'network-only',
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return fetchMoreResult;
          }
        })
        resolve({
          data: result?.data?.getCostingMaster?.data || [],
          page: query.page,
          totalCount: result?.data?.getCostingMaster?.count || 0,
        })
      } catch (error) {
        console.log(FormatError(error))
      }
    })
  }

  const addButton = (val) => {
    if (val || (role === 'admin'))
      return { onRowAdd: (newData) => AddCosting(newData) }
    else
      return {}
  }
  useEffect(() => {
    document.getElementsByClassName("parentTable")[0].firstElementChild.firstElementChild.firstChild.remove()
  }, [])

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <div className="parentTable">
            <MaterialTable
              title="Costing"
              tableRef={tableRef}
              columns={columns}
              data={query => getCostingData(query)}
              icons={{ Add: props => { return (<div> <i className="fa fa-plus-square" ></i> Costing </div>) } }}
              options={{
                emptyRowsWhenPaging: false,
                showTitle: false,
                debounceInterval: 300,
                padding: 'dense',
                pageSize: pageSize,
                pageSizeOptions: [5, 10, 15, 20, 25, 50],
                addRowPosition: 'first',
                filtering: true,
                sorting: true,
                search: false,
                filterCellStyle: { padding: '5px', },
                maxBodyHeight: window.innerHeight - 215,
                rowStyle: rowData => ({
                  backgroundColor: (selectedRow === rowData.tableData.id) ? 'rgb(212, 218, 255)' : '#f5f5f5'
                }),
                headerStyle: { backgroundColor: 'whitesmoke' }
              }}
              editable={{
                isEditHidden: (rowData) => (role === 'admin') ? false : !permission.isUpdate,
                isDeleteHidden: (rowData) => (role === 'admin') ? false : !permission.isDelete,
                ...addButton(permission?.isInsert),
                onRowUpdate: (newData, oldData) => UpdateCosting(newData, oldData),
                onRowDelete: (oldData) => DeleteCosting(oldData)
              }}
              detailPanel={rowData => {
                return (
                  <CostingDetails
                    key={rowData.id}
                    session={props?.session}
                    location={props?.location}
                    Data={rowData}
                    Ids={rowData.id}
                    costingType={rowData?.costType}
                    UpdateCosting={UpdateCosting}
                    CostingMasterData={rowData}
                    tableRef={tableRef}
                    updateMasterData={query => getCostingData(query)}
                  />
                )
              }}
              onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
            />
          </div>
        </Grid>
      </Grid>
    </div >
  );
}

export default withRouter(Costing)