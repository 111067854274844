import { useQuery } from '@apollo/react-hooks'
import { ROUGHSTOCKFROMLOTANDPKT } from '../../pages/Transaction/IssueReturnMaster/IssueReturnMasterQuery';
export const RoughStockFromLotandPktForIssue = (status, lotNoId, pktNoId, partyId) => {
    const { data } = useQuery(ROUGHSTOCKFROMLOTANDPKT, {
        variables: { status: status, lotNoId: lotNoId, pktNoId: pktNoId, issuePartyId: partyId },
        fetchPolicy: 'cache-and-network',
    })
    let Data = data?.getRoughStockFromLotandPkt || [];

    return Data
}

