import gql from 'graphql-tag';

export const ADD_SHORTCODE = gql`
  mutation addNewShortCode( $input: shortCodeInput!) {
    addNewShortCode(input:$input) {
      id
      codeId
      shortName
      fullName
      type
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_SHORTCODE = gql`
mutation updateShortCode( $input:  shortCodeUpdate!) {
  updateShortCode(input:$input) {
    id
    codeId
    shortName
    fullName
    type
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}
`;

export const DELETE_SHORTCODE = gql`
  mutation deleteShortCode( $id: ID!) {
    deleteShortCode(id:$id)
  }
`;
