
import React, { useState } from 'react';
import { ApolloConsumer } from 'react-apollo';
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
// import CircularProgress from '@material-ui/core/CircularProgress';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
// import Box from '@material-ui/core/Box';
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';
import SupervisorAccountOutlinedIcon from '@material-ui/icons/SupervisorAccountOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
// import Container from '@material-ui/core/Container';
// import _ from 'lodash';
import { UPDATE_PASSWORD } from './UserMutation';
import { useMutation } from "@apollo/react-hooks";
import { FormatError } from "../../../components/Comman/FormatError";

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 275,
        textAlign: 'center',
        marginTop: theme.spacing(15),

    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        backgroundColor: "#47528e"
    },
    buttonProgress: {
        color: "#f50357",
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    profile: {
        marginTop: theme.spacing(-15),
        marginLeft: theme.spacing(50),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    display: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center'
    },
    avatar: {
        marginRight: '10px',
        backgroundColor: theme.palette.secondary.main,
    },
}));



function UserProfile(props) {

    const user = props?.session?.me;

    const classes = useStyles();
    const [currentPassword, setCurrentPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [retypePassword, setReNewPassword] = useState("")
    const [UpdatePasswordMutation] = useMutation(UPDATE_PASSWORD);

    const onSubmit = (event, client) => {
        event.preventDefault();
        if (currentPassword && newPassword && retypePassword) {
            if (currentPassword === newPassword) {
                toast.warn("Your old password and new password can't be same.")
            }
            else if (newPassword !== retypePassword) {
                toast.warn('Your new password and confirm password must be same.')
            }
            else {
                UpdatePasswordMutation({ variables: { oldPassword: currentPassword, newPassword: newPassword } })
                    .then((data) => {
                        toast.success('Your password updated successfully.')
                        localStorage.clear()
                        client.resetStore()
                        sessionStorage.clear();
                        props.history.push('/');
                    })
                    .catch((e) => {
                        toast.error(FormatError(e));
                    });
            }
        } else {
            toast.warn('Please, fill all fields.')
        }
    };

    return (
        <Grid container xs={12}>
            <Grid container xs={6}>
                <div className={classes.profile}>

                    <Card className={classes.root}>
                        <CardContent>
                            <Grid xs={12} container style={{ marginTop: "0px !important" }}>
                                <Grid xs={3} container justify="flex-end" >
                                    <Avatar className={classes.avatar}>
                                        <PersonOutlinedIcon />
                                    </Avatar>
                                </Grid>
                                <Grid xs={6} container >
                                    <p style={{ fontSize: 20, marginBottom: 0, marginTop: 8 }}>
                                        {user?.userName}
                                    </p>
                                </Grid>



                            </Grid>

                            <Grid xs={12} container style={{ marginTop: "0px !important" }}>
                                <Grid xs={3} container justify="flex-end">
                                    <Avatar className={classes.avatar}>
                                        <MailOutlineOutlinedIcon />
                                    </Avatar>
                                </Grid>
                                <Grid xs={6} container >
                                    <p style={{ fontSize: 20, marginBottom: 0, marginTop: 8 }}>
                                        {user?.email}
                                    </p>
                                </Grid>

                            </Grid>
                            <Grid xs={12} container style={{ marginTop: "0px !important" }}>
                                <Grid xs={3} container justify="flex-end">
                                    <Avatar className={classes.avatar}>
                                        <SupervisorAccountOutlinedIcon />
                                    </Avatar>
                                </Grid>
                                <Grid xs={6} container >
                                    <p style={{ fontSize: 20, marginBottom: 0, marginTop: 8 }}>
                                        {user?.role?.role}
                                    </p>
                                </Grid>

                            </Grid>
                            {/* <Typography className={classes.title} variant="h5" component="h1" gutterBottom>
                                <div className={classes.display}>


                                    {user?.userName}
                                </div>

                            </Typography>

                            <Typography className={classes.title} color="textSecondary" variant="body">
                                <div className={classes.display}>
                                    <Avatar className={classes.avatar}>
                                        <MailOutlineOutlinedIcon />
                                    </Avatar>
                                    {user?.email}
                                </div>

                            </Typography>

                            <Typography className={classes.title} color="textSecondary" >
                                {user?.role?.role}
                            </Typography> */}

                        </CardContent>
                    </Card>
                </div>
            </Grid>
            <Grid container xs={6}>


                <div className={classes.paper}>

                    <Typography component="h1" variant="h5"> Change Password </Typography>
                    <ApolloConsumer>
                        {client => (
                            <form className={classes.form} noValidate onSubmit={event => onSubmit(event, client)} >

                                <TextField variant="outlined" margin="normal" required fullWidth id="currentPassword" type="password" label="Current Password" autoComplete="current-password" autoFocus
                                    value={currentPassword} onChange={(e) => { setCurrentPassword(e.target.value) }}
                                />
                                <TextField variant="outlined" margin="normal" required fullWidth name="new password" label="new password" type="password" id="newPassword" autoComplete="new-password"
                                    value={newPassword} onChange={(e) => { setNewPassword(e.target.value) }}
                                />
                                <TextField variant="outlined" margin="normal" required fullWidth name="confirm password" label="confirm password" type="password" id="retypeNewPassword" autoComplete="retype-new-password"
                                    value={retypePassword} onChange={(e) => { setReNewPassword(e.target.value) }}
                                />

                                <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit} >
                                    Submit
                                </Button>

                            </form>
                        )}
                    </ApolloConsumer>

                </div>

            </Grid>
        </Grid>
    );
}


export default withRouter(UserProfile)











