import React, { useState, useEffect, useRef } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks'
import MaterialTable from 'material-table';
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import moment from 'moment';
import { JOBWORKPKTLTD } from './JobWorkPktQuery'
import { JOBPKT_SUBSCRIBE } from './JobWorkPktSubscription'
import { ADD_JOBPKTLTD, UPDATE_JOBPKTLTD, DELETE_JOBPKTLTD } from './JobWorkPktMutation'
import { FormatError } from '../../../components/Comman/FormatError'
import { CustomFilterComponent } from '../../../components/CustomFilterComponent/index'
import { GETALLSHORTCODETYPE } from '../../Utility/SortCode/SortCodeQuery';
import { GradingSelectComponent } from '../../../components/DropdownSelect/SelectComponents/ShortCodeSelectComponent'
import { Tofixed } from '../../../components/Comman/Tofix'

const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1, },
  noMargin: { margin: 0 }
}));

const columns = [
  {
    title: 'Date*', field: 'date', type: "date",
    initialEditValue: moment(),
    render: (rowData) => {
      if (rowData.date)
        return moment(rowData?.date || 0).format('DD/MM/YYYY')
    },
    validate: rowData => (rowData.date && rowData.date !== "") ? true : { isValid: false, helperText: `can't be empty` },
  },
  {
    title: 'Lot No*', field: 'lotNo',
    validate: rowData => (rowData.lotNo && rowData.lotNo !== "") ? true : { isValid: false, helperText: `can't be empty` }
  },
  {
    title: 'Pkt No*', field: 'pktNo',
    validate: rowData => (rowData.pktNo && rowData.pktNo !== "") ? true : { isValid: false, helperText: `can't be empty` }
  },
  {
    title: 'Cts*', field: 'cts',
    render: (rowData) => Tofixed(rowData?.cts || 0),
    validate: rowData => (rowData.cts && rowData.cts !== "") ? true : { isValid: false, helperText: `can't be empty` }
  },
  {
    title: 'Color', field: 'colorId',
    render: (rowData) => rowData?.colorId?.shortName || "_"
  },
  {
    title: 'Shape', field: 'shapeId',
    render: (rowData) => rowData?.shapeId?.shortName || "_"
  },
  {
    title: 'Purity', field: 'purityId',
    render: (rowData) => rowData?.purityId?.shortName || "_"
  },
  {
    title: 'Cut', field: 'cutId',
    render: (rowData) => rowData?.cutId?.shortName || "_"
  },
  {
    title: 'Polish', field: 'polishId',
    render: (rowData) => rowData?.polishId?.shortName || "_"
  },
  {
    title: 'Symm', field: 'symmId',
    render: (rowData) => rowData?.symmId?.shortName || "_"
  },
  {
    title: 'Flu', field: 'flourenceseId',
    render: (rowData) => rowData?.flourenceseId?.shortName || "_"
  },
]

function JobWorkPkt(props) {
  const classes = useStyles();
  const tableRef = useRef();

  const [pageSize, setPageSize] = useState(10)
  const [selectedRow, setSelectedRow] = useState(null);
  const [loader, setLoader] = useState(false);

  const permissions = props?.session?.me?.role?.permissions;
  const role = props?.session?.me?.role?.role;
  const path = props?.location?.pathname;
  let permission = _.find(permissions, function (o) { return o.name === path; })

  const [addJobPktLtdMutation] = useMutation(ADD_JOBPKTLTD)
  const [updateJobPktLtdMutation] = useMutation(UPDATE_JOBPKTLTD)
  const [deleteJobPKtLtdMutation] = useMutation(DELETE_JOBPKTLTD)


  const { fetchMore, subscribeToMore } = useQuery(JOBWORKPKTLTD, {
    variables: { page: 1, limit: 10, filter: JSON.stringify({}), sort: { key: 'createdAt', type: -1 } },
    fetchPolicy: 'cache-and-network'
  })
  const { data: shortCodeData } = useQuery(GETALLSHORTCODETYPE, {
    variables: { type: "All" },
    fetchPolicy: 'cache-and-network',
  })

  const getShortCodeData = (TYPE) => {
    return _.filter(shortCodeData?.getAllType || [], (d) => d.type === TYPE);
  }

  columns.map(d => {
    if (d.field === "shapeId") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: getShortCodeData("SHAPE"), getOptionLabel: (option) => option.shortName, multiple: true }} />
      }
      d.editComponent = props => {
        let valueDefault = props?.rowData?.shapeId?.id || props?.rowData?.shapeId || null
        return <GradingSelectComponent propsData={props} TYPE={"shapeId"} data={getShortCodeData("SHAPE")} valueDefault={valueDefault} />
      }
    } else if (d.field === "colorId") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: getShortCodeData("COLOR"), getOptionLabel: (option) => option.shortName, multiple: true }} />
      }
      d.editComponent = props => {
        let valueDefault = props?.rowData?.colorId?.id || props?.rowData?.colorId || null
        return <GradingSelectComponent propsData={props} TYPE={"colorId"} data={getShortCodeData("COLOR")} valueDefault={valueDefault} />
      }
    } else if (d.field === "purityId") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: getShortCodeData("PURITY"), getOptionLabel: (option) => option.shortName, multiple: true }} />
      }
      d.editComponent = props => {
        let valueDefault = props?.rowData?.purityId?.id || props?.rowData?.purityId || null
        return <GradingSelectComponent propsData={props} TYPE={"purityId"} data={getShortCodeData("PURITY")} valueDefault={valueDefault} />
      }
    } else if (d.field === "polishId") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: getShortCodeData("POLISH"), getOptionLabel: (option) => option.shortName, multiple: true }} />
      }
      d.editComponent = props => {
        let valueDefault = props?.rowData?.polishId?.id || props?.rowData?.polishId || null
        return <GradingSelectComponent propsData={props} TYPE={"polishId"} data={getShortCodeData("POLISH")} valueDefault={valueDefault} />
      }
    } else if (d.field === "flourenceseId") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: getShortCodeData("FLOURSENCE"), getOptionLabel: (option) => option.shortName, multiple: true }} />
      }
      d.editComponent = props => {
        let valueDefault = props?.rowData?.flourenceseId?.id || props?.rowData?.flourenceseId || null
        return <GradingSelectComponent propsData={props} TYPE={"flourenceseId"} data={getShortCodeData("FLOURSENCE")} valueDefault={valueDefault} />
      }
    } else if (d.field === "cutId") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: getShortCodeData("CUT"), getOptionLabel: (option) => option.shortName, multiple: true }} />
      }
      d.editComponent = props => {
        let valueDefault = props?.rowData?.cutId?.id || props?.rowData?.cutId || null
        return <GradingSelectComponent propsData={props} TYPE={"cutId"} data={getShortCodeData("CUT")} valueDefault={valueDefault} />
      }
    } else if (d.field === "symmId") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: getShortCodeData("SYMMENTRY"), getOptionLabel: (option) => option.shortName, multiple: true }} />
      }
      d.editComponent = props => {
        let valueDefault = props?.rowData?.symmId?.id || props?.rowData?.symmId || null
        return <GradingSelectComponent propsData={props} TYPE={"symmId"} data={getShortCodeData("SYMMENTRY")} valueDefault={valueDefault} />
      }
    }
    return null
  })

  useEffect(() => {
    if (subscribeToMore) {
      const unsubscribe = subscribeToMore({
        document: JOBPKT_SUBSCRIBE,
        updateQuery: (previousResult, { subscriptionData }) => {
          if (!subscriptionData.data) {
            return previousResult;
          }
          tableRef.current && tableRef.current.onQueryChange()
          return previousResult;
        },
      });
      return () => unsubscribe();
    }
  }, [subscribeToMore]);

  const getJobPktLtdData = (query) => {
    setLoader(true)
    return new Promise(async (resolve, reject) => {
      let filter = {}, sort = { key: 'createdAt', type: -1 }
      query && query.filters.forEach(item => {
        if ((item.column.field === "date")) {
          if (item.value)
            filter = { ...filter, [item.column.field]: moment(item.value).format("YYYY-MM-DD") }
          else {
            delete filter[item.column.field]
            filter = { ...filter }
          }
        }
        else if ((item.column.field === "currencyRate" || item.column.field === "currencyAmount" || item.column.field === "partyAmtZar" || item.column.field === "adgAmtZar")) {
          if (item.value)
            filter = { ...filter, [item.column.field]: parseFloat(item.value) }
          else {
            delete filter[item.column.field]
            filter = { ...filter }
          }
        }
        else if (item.value.length === 0) {
          delete filter[`${item.column.field}`]
          filter = { ...filter }
        } else
          filter = { ...filter, [item.column.field]: item.value }
      });
      if (query && query.orderBy && query.orderDirection !== "") {
        sort = { key: query.orderBy.field, type: query.orderDirection === 'asc' ? 1 : -1 }
      }
      setPageSize(query.pageSize)
      try {
        let result = await fetchMore({
          variables: { page: query.page + 1, limit: query.pageSize, filter: JSON.stringify(filter), sort: sort },
          fetchPolicy: 'network-only',
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return fetchMoreResult;
          }
        })
        setLoader(false)
        resolve({
          data: result?.data?.getJobWorkPkt?.data || [],
          page: query.page,
          totalCount: result?.data?.getJobWorkPkt?.count || 0,
        })
      } catch (error) {
        console.log(FormatError(error))
        setLoader(false)
      }
    })
  }

  const AddJobPktLtd = (newData) => {
    return new Promise((resolve, reject) => {
      setLoader(true)
      addJobPktLtdMutation({ variables: { input: newData } }).then((data) => {
        setLoader(false)
        toast.success('jobworkpkt created successfully.');
        resolve()
      }).catch((e) => {
        setLoader(false)
        reject()
        toast.warn('please, fill all required(*) fields.')
      })
    })
  }

  const UpdateJobPktLtd = (newData, oldData) => {

    return new Promise((resolve, reject) => {
      setLoader(true)
      delete newData.createdAt
      delete newData.createdBy
      delete newData.updatedAt
      delete newData.updatedBy
      delete newData.__typename
      newData.shapeId = newData?.shapeId?.id || newData?.shapeId || null
      newData.colorId = newData?.colorId?.id || newData?.colorId || null
      newData.purityId = newData?.purityId?.id || newData?.purityId || null
      newData.polishId = newData?.polishId?.id || newData?.polishId || null
      newData.symmId = newData?.symmId?.id || newData?.symmId || null
      newData.flourenceseId = newData?.flourenceseId?.id || newData?.flourenceseId || null
      newData.cutId = newData?.cutId?.id || newData?.cutId || null
      if (newData.lotNo === '' || newData.pktNo === '') {
        reject()
        toast.warn('please, fill all required(*) fields.')
      }
      else {
        updateJobPktLtdMutation({ variables: { input: newData } }).then((data) => {
          setLoader(false)
          toast.success('jobworkpkt updated successfully.');
          resolve()
        }).catch((e) => {
          setLoader(false)
          reject()
          toast.warn('please, fill all required(*) fields.')
        })
      }
    })
  }

  const DeleteJobPktLtd = (oldData) => {
    return new Promise((resolve, reject) => {
      setLoader(true)
      deleteJobPKtLtdMutation({ variables: { id: oldData.id } }).then((data) => {
        setLoader(false)
        toast.success('jobworkpkt deleted successfully.');
        resolve()
      }).catch((e) => {
        reject()
        setLoader(false)
        toast.error('err -', e.message)
      })
    })
  }

  const addButton = (val) => {
    if (val || (role === 'admin'))
      return { onRowAdd: (newData) => AddJobPktLtd(newData) }
    else
      return {}
  }
  useEffect(() => {
    document.getElementsByClassName("parentTable")[0].firstElementChild.firstElementChild.firstChild.remove()
  }, [])

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <div className="parentTable">
            <MaterialTable
              title="Job Work Pkt Lab"
              tableRef={tableRef}
              columns={columns}
              isLoading={loader}
              data={query => getJobPktLtdData(query)}
              icons={{ Add: props => { return (<div> <i className="fa fa-plus-square" ></i> Job Work Pkt Lab </div>) } }}
              options={{
                emptyRowsWhenPaging: false,
                padding: 'dense',
                showTitle: false,
                debounceInterval: 300,
                pageSize: pageSize,
                pageSizeOptions: [5, 10, 15, 20, 25, 50],
                addRowPosition: 'first',
                filtering: true,
                sorting: true,
                search: false,
                filterCellStyle: { padding: '5px', },
                maxBodyHeight: window.innerHeight - 215,
                rowStyle: rowData => ({
                  backgroundColor: (selectedRow === rowData.tableData.id) ? 'rgb(212, 218, 255)' : '#f5f5f5'
                }),
                headerStyle: { backgroundColor: 'whitesmoke' }
              }}
              editable={{
                // onRowAdd: (newData) => AddJobPktLtd(newData),
                isEditHidden: (rowData) => (role === 'admin') ? false : !permission.isUpdate,
                isDeleteHidden: (rowData) => (role === 'admin') ? false : !permission.isDelete,
                ...addButton(permission?.isInsert),
                onRowUpdate: (newData, oldData) => UpdateJobPktLtd(newData, oldData),
                onRowDelete: (oldData) => DeleteJobPktLtd(oldData)
              }}
              // onChangeRowsPerPage={size => setPageSize(size)}
              // onChangePage={page => { setPage(page) }}
              onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}



export default withRouter(JobWorkPkt)
