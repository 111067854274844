import React from "react";
import { withRouter } from "react-router-dom";
import MaterialTable from "material-table";
import moment from "moment";
import { Tofixed } from '../../../../components/Comman/Tofix'
// import _ from "lodash";

// import './planning.css'
const columns = [
  {
    title: "Result Date ",
    field: "createdAt",
    render: (rowData) => {
      let Data = rowData?.labResult?.createdAt || null;
      return Data ? moment(Data).format("DD/MM/YYYY") : "";
    },
    // render: (rowData) => rowData.labResult.createdAt ? moment(rowData.labResult.createdAt) : "",
    headerStyle: {
      backgroundColor: "#896c",
    },
  },
  {
    title: "Cts ",
    field: "cts",
    render: (rowData) => Tofixed(rowData?.labResult?.cts || 0),
    headerStyle: {
      backgroundColor: "#896c",
    },
  },
  {
    title: "Clr ",
    field: "colorId",
    render: (rowData) => rowData?.labResult?.colorId?.shortName || "",
    headerStyle: {
      backgroundColor: "#896c",
    },
  },
  {
    title: "Shape ",
    field: "shapeId",
    render: (rowData) => rowData?.labResult?.shapeId?.shortName || "",
    headerStyle: {
      backgroundColor: "#896c",
    },
  },
  {
    title: "Purity ",
    field: "purityId",
    render: (rowData) => rowData?.labResult?.purityId?.shortName || "",
    headerStyle: {
      backgroundColor: "#896c",
    },
  },
  {
    title: "Cut ",
    field: "cutId",
    render: (rowData) => rowData?.labResult?.cutId?.shortName || "",
    headerStyle: {
      backgroundColor: "#896c",
    },
  },
  {
    title: "Polish ",
    field: "polishId",
    render: (rowData) => rowData?.labResult?.polishId?.shortName || "",
    headerStyle: {
      backgroundColor: "#896c",
    },
  },
  {
    title: "Symm ",
    field: "symmId",
    render: (rowData) => rowData?.labResult?.symmId?.shortName || "",
    headerStyle: {
      backgroundColor: "#896c",
    },
  },
  {
    title: "Flu ",
    field: "fluId",
    render: (rowData) => rowData?.labResult?.fluId?.shortName || "",
    headerStyle: {
      backgroundColor: "#896c",
      textAlign: "center",
    },
    cellStyle: {
      minWidth: 170,
      textAlign: "center",
    },
  },
];
function LabResult(props) {
  return (
    <MaterialTable
      title="Lab Result"
      columns={columns}
      tableRef={props.tableRef}
      data={props.AllData}
      isLoading={props.loading}
      options={{
        emptyRowsWhenPaging: false,
        padding: "dense",
        pageSizeOptions: [5, 10, 15, 20, 25, 50],
        addRowPosition: "first",
        filtering: false,
        sorting: true,
        search: false,
        filterCellStyle: { padding: "5px" },
        // maxBodyHeight: window.innerHeight - 215,
        paging: false,
        rowStyle: (rowData) => ({
          backgroundColor: rowData.lotNo === "Grand Total" ? "rgb(212, 218, 255)" : "#FFF",
          fontWeight: rowData.lotNo === "Grand Total" && 'bolder'

        }),
      }}
    />
  );
}
export default withRouter(LabResult);
