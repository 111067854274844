import React from 'react';
import { useQuery } from '@apollo/react-hooks'
import { ALLPARTYMASTERDETAILS } from '../../../pages/Master/PartyMaster/PartyMasterQuery';
import { ALLCOMPANYDETAILS } from '../../../pages/Master/Company/CompanyQuery';
import { KAPANPARTYS } from '../../../pages/Transaction/Packet/PacketQuery';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import { toast } from 'react-toastify';
import TextField from '@material-ui/core/TextField';
import _ from 'lodash'
import { FormatError } from '../../../components/Comman/FormatError';

export const KapanPartysData = (Type) => {
  const { error, data } = useQuery(KAPANPARTYS, {
    fetchPolicy: 'cache-and-network',
  })
  if (error) {
    console.log(FormatError(error));
  }
  // return
  let KapanPartydata = data?.getOnlyKapanParty || []
  return KapanPartydata
}

export const CompanyAndPartyAllData = (QueryName) => {
  const { error, data } = useQuery(QueryName === 'PARTY' ? ALLPARTYMASTERDETAILS : ALLCOMPANYDETAILS, {
    fetchPolicy: 'cache-and-network',
  })
  let CompanyAndPartyData = []
  if (error) {
    console.log(FormatError(error));
  } else {
    CompanyAndPartyData = QueryName === 'PARTY' ? data?.getAllParties || [] : data?.getAllCompanies || []
  }
  return CompanyAndPartyData
}

export const CompanyAndPartyLookup = (QueryName, TYPE) => {
  let Data = []
  if (TYPE === "ROUGH") {
    Data = KapanPartysData(TYPE)
  } else
    Data = CompanyAndPartyAllData(QueryName)
  let Lookup = {}
  Data.map((d, index) => {
    Lookup[d.id] = QueryName === "COMPANY" ? d.companyName : d.partyName
    return null
  })
  return Lookup
}

export const CompanyAndPartySelectComponent = (props) => {
  let Data = []
  if (props.TYPE === "ROUGH") {
    Data = KapanPartysData(props.TYPE)
  } else
    Data = CompanyAndPartyAllData(props.QueryName)
  const changeselect = (e, newValue) => {
    let props1 = props.prosData
    let Data = { ...props1.rowData }
    if (props.QueryName === 'PARTY') {
      Data.partyId = newValue.id
    } else if (props.QueryName === 'COMPANY') {
      Data.companyId = newValue.id
    }
    props1.onRowDataChange(Data)
  }
  const defaultProps = {
    options: Data,
    getOptionLabel: (option) => props.QueryName === "COMPANY" ? option.companyName : option.partyName
  };

  return <>
    <Autocomplete
      {...defaultProps}
      disableClearable
      onChange={(e, newValue) => changeselect(e, newValue, props.QueryName)}
      value={_.filter(Data, (newd) => newd.id === props.valueDefault)[0]}
      renderInput={(params) => <TextField   {...params} margin="normal" />}
    />
  </>
}