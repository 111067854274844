import React, { useEffect, useState } from 'react'
import MaterialTable from 'material-table';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from '@apollo/react-hooks'
import _ from 'lodash'
import { GETFILTERDATAFORLIVESCREEN } from '../LiveScreenQuery'
import { Table } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Tofixed } from '../../../../components/Comman/Tofix'
import { AutoSizer, List, CellMeasurer, CellMeasurerCache } from 'react-virtualized';

const useStyles = makeStyles(() => ({
    AccountTableCss: {
        "& .MuiToolbar-regular": {
            minHeight: 0
        }
    },
    LabelStart: {
        fontSize: 16,
        paddingBottom: 10,
        color: "rgb(70, 81, 142)"
    },
    Labelend: {
        fontSize: 16,
        paddingBottom: 10,
        color: "rgb(70, 81, 142)",
        textAlign: "end"
    },
    denseTable: {
        '& td': { padding: "2px 5px", cursor: "pointer", textAlign: "center" },
        '& th': { padding: "5px 5px", cursor: "pointer", textAlign: "center" }
    },
    loader: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: window.innerHeight - 460,
        pointerEvents: 'none',
        opacity: 0.5,
        paddingRight: 10,
    }
}))
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#8CB836",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);
const columns = [
    {
        title: 'Pkt Type',
        field: 'pktType',
        cellStyle: { textAlign: "center" },
        headerStyle: { textAlign: "center" }
    },
    {
        title: 'Pcs',
        field: 'totalpcs',
        cellStyle: { textAlign: "center" },
        headerStyle: { textAlign: "center" }
    },
]
const columns1 = [
    { title: 'Jangad no' },
    { title: 'Lot No', },
    { title: 'Pkt No' },
    { title: 'Pkt Type' },
    { title: 'Cts' }
]

export const SelectedData = (props) => {
    const [filter, setFilter] = useState({})
    const [PkTypeDataForLeftSideTable, setPkTypeDataForLeftSideTable] = useState([])
    const classes = useStyles();
    const { data, loading } = useQuery(GETFILTERDATAFORLIVESCREEN, {
        variables: { filter: JSON.stringify(filter) },
        fetchPolicy: 'cache-and-network'
    })

    const { Fillter } = props
    columns1.map(d => {
        if (d.field === "issueCts") {
            d.render = (rowData) => Tofixed(Fillter.extraTopLotNoId ? rowData?.cts || 0 : rowData?.issueCts || 0)
        }
        if (d.field === "pktType") {
            d.render = (rowData) => Fillter.extraTopLotNoId ? "Extra Tops" : rowData?.issueCts || 0
        }
        return null
    })
    columns.map(d => {
        if (d.field === "pktType") {
            d.render = (rowData) => Fillter.extraTopLotNoId ? "Extra Tops" : rowData?.pktType || ""
        }
        return null
    })

    useEffect(() => {
        if (Fillter.extraTopLotNoId) {
            setFilter({ "extraTopLotNoId": Fillter.extraTopLotNoId })
        } else
            setFilter(Fillter?.deptName ? { "departmentId": Fillter.id } : Fillter?.lotNo ? { "lotNoId": Fillter.id } : {})
    }, [props])

    useEffect(() => {
        let Data = [...data?.getFilterDataforLiveScreen || []]
        if (Data.length !== 0) {
            let MapData = []
            let groupByPktType = _.groupBy(Data, 'pktType')
            if (Object.keys(groupByPktType).length !== 0) {
                Object.keys(groupByPktType).map(d => {
                    let TempData = {
                        "pktType": d,
                        "totalpcs": groupByPktType[d]?.length || 0
                    }
                    MapData.push(TempData)
                    return null
                })
            }
            setPkTypeDataForLeftSideTable(MapData)
        }
    }, [data])
    const cache = React.useRef(
        new CellMeasurerCache({
            fixedWidth: true,
            defaultHeight: 100
        })
    )
    const ListRef = React.useRef();
    let TableData = data?.getFilterDataforLiveScreen || []
    if (loading) {
        return <Grid xs={12} item container justify="center" alignItems="center" className={classes.loader}  ><img src="https://i.gifer.com/4V0b.gif" alt="loading" /></Grid>
    } else
        return <>
            <Grid container justify="center" style={{ background: '#46518e', marginTop: 15, height: 30, color: "black" }} alignItems="center">
                {`${Fillter?.deptName || ""} || `}      {`Total Packets : ${data?.getFilterDataforLiveScreen?.length || 0} || Cts :  ${Tofixed(_.sumBy(data?.getFilterDataforLiveScreen, (d) => Fillter.extraTopLotNoId ? d?.cts : d.issueCts))}`}
            </Grid>
            <Grid container justify="center">
                <Grid xs={3} item style={{ paddingRight: 5 }}>
                    <Grid xs={12} item className={`${classes.AccountTableCss} DenseTable`}>
                        <MaterialTable
                            columns={columns}
                            isLoading={false}
                            data={PkTypeDataForLeftSideTable}
                            options={{
                                emptyRowsWhenPaging: false,
                                padding: 'dense',
                                selection: false,
                                search: false,
                                showTitle: false,
                                pageSize: 20,
                                pageSizeOptions: [20, 50, 100, 150, 200, 250, 300, 400, 500],
                                paging: false,
                                sorting: false,
                                filtering: false,
                                minBodyHeight: window.innerHeight - 500,
                                maxBodyHeight: window.innerHeight - 500,
                                headerStyle: {
                                    backgroundColor: '#8CB836',
                                    color: '#f5f5f5'
                                }
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid xs={6} item>
                    <Grid xs={12} item className={`${classes.AccountTableCss} DenseTable`}>
                        <TableContainer component={Paper}>
                            <Table className={classes.denseTable}>
                                <TableHead>
                                    <TableRow>
                                        {columns1.map((d, key) => {
                                            return <StyledTableCell key={key} style={{ width: '20%', textAlign: "center" }}>{d.title}</StyledTableCell>
                                        })
                                        }
                                    </TableRow>
                                </TableHead>
                            </Table>
                            <div style={{ width: "100%", height: 500 }}>
                                <AutoSizer>
                                    {({ width, height }) => {
                                        return <List
                                            ref={ListRef}
                                            height={height}
                                            width={width}
                                            rowHeight={20}
                                            deferredMeasurementCache={cache.current}
                                            rowCount={TableData.length}
                                            rowRenderer={({ key, index, style, parent }) => {
                                                const rowData = TableData[index]
                                                return <CellMeasurer key={key} cache={cache.current} parent={parent} columnIndex={0} rowIndex={index}>
                                                    <div style={style}>
                                                        <div className="tbody"  >
                                                            <Table>
                                                                <TableBody >
                                                                    <StyledTableRow >
                                                                        <StyledTableCell style={{ textAlign: "center", width: "20%" }}>{rowData?.returnId?.jagadNo || ""}</StyledTableCell>
                                                                        <StyledTableCell style={{ textAlign: "center", width: "20%" }}>{rowData?.lotNoId?.lotNo || ""}</StyledTableCell>
                                                                        <StyledTableCell style={{ textAlign: "center", width: "20%" }} >{rowData?.pktNoId?.pktNo || ""}</StyledTableCell>
                                                                        <StyledTableCell style={{ textAlign: "center", width: "20%" }} >{Fillter.extraTopLotNoId ? "Extra Tops" : rowData?.pktType || ""}</StyledTableCell>
                                                                        <StyledTableCell style={{ textAlign: "center", width: "20%" }} >{Fillter.extraTopLotNoId ? Tofixed(rowData?.cts || 0) : Tofixed(rowData?.issueCts || 0)}</StyledTableCell>
                                                                    </StyledTableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </div>
                                                    </div>
                                                </CellMeasurer>
                                            }}
                                        />
                                    }}
                                </AutoSizer>
                            </div>
                        </TableContainer>
                    </Grid>
                </Grid>
            </Grid >
        </>
}