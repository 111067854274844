import React from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { GETALLSHORTCODETYPE } from '../../../Utility/SortCode/SortCodeQuery'
import { useQuery, useMutation } from '@apollo/react-hooks'
import _ from 'lodash'
import { TextField } from '@material-ui/core';
import { useMemo } from 'react'

export const CommonShortCodeDropdown = ({ value, setvalue, type, label }) => {
    const { data, loading } = useQuery(GETALLSHORTCODETYPE, {
        variables: { type: "All" },
        fetchPolicy: 'cache-and-network',
    })

    const shortCodeDataDropDown = useMemo(() => {
        let shortCodeData = _.filter(data?.getAllType || [], (d) => d.type === type);
        return <Autocomplete
            multiple
            value={value}
            options={shortCodeData}
            getOptionLabel={(option) => option.shortName}
            onChange={(event, newValue) => {
                setvalue(newValue)
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="standard"
                    label={label}
                />
            )}
        />
    }, [data, value])


    if (loading) {
        return <>loading......</>
    } else
        return <>
            {shortCodeDataDropDown}
        </>
}