import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { ALLDEPARTMENT } from '../../../pages/Master/Department/DepartmentQuery'
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FormatError } from '../../Comman/FormatError'


export const FromDepartmentAllData = (props) => {
  const { error, data } = useQuery(ALLDEPARTMENT, {
    fetchPolicy: 'cache-and-network',
  })
  if (error)
    console.log(FormatError(error))
  let Data = data?.getAllDepartment || []
  return Data
}

export const FromDepartmentLookup = (props) => {
  let Data = FromDepartmentAllData()
  let Department = {}
  Data.map((d, index) => {
    Department[d.id] = d.deptName
    return null
  })
  return Department
}

export const FromDepartmentSelectComponent = (props) => {

  let Data = FromDepartmentAllData()

  const defaultProps = {
    options: Data,
    getOptionLabel: (option) => option.deptName
  };

  return <Autocomplete
    {...defaultProps}
    onChange={(e, newValue) => props.SetFromDepartmentFun(e, newValue)}
    value={props.valueDefault}
    renderInput={(params) => <TextField label="From Department"   {...params} margin="normal" />}
  />
}