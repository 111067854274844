import React from 'react'
import { makeStyles, FormControl } from '@material-ui/core';
import { useQuery } from '@apollo/react-hooks'
import { ALLDEPARTMENT } from '../../pages/Master/Department/DepartmentQuery'
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FormatError } from '../Comman/FormatError'
import _ from 'lodash'
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  Select: {
    marginTop: "0px !important",
    paddingTop: 0
  }
}));
export const ToDepartment = (props) => {
  const classes = useStyles();
  const { error, data } = useQuery(ALLDEPARTMENT, {
    fetchPolicy: 'cache-and-network',
  })
  if (error)
    console.log(FormatError(error));

  let Data = data?.getAllDepartment || []
  let NewData = _.filter(Data, (d) => d.deptName !== props?.FromDepartmentIds?.deptName || '')

  const defaultProps = {
    options: NewData,
    getOptionLabel: (option) => option.deptName
  };
  return <FormControl className={classes.formControl}>
    <Autocomplete
      {...defaultProps}
      onChange={(e, newValue) => props.SetToDepartmentFun(e, newValue)}
      value={props.Ids}
      disableClearable
      renderInput={(params) => <TextField label="To Department"  {...params} margin="normal" />}
    />

  </FormControl>
}
