import React, { useRef, useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { withRouter } from "react-router-dom";
import { Grid, FilledInput, Button, TextField } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useQuery, useMutation } from '@apollo/react-hooks'
import Typography from '@material-ui/core/Typography';
import MuiAccordion from '@material-ui/core/Accordion';
import { toast } from 'react-toastify'
import DateFnsUtils from "@date-io/date-fns";
import _ from 'lodash'
import moment from 'moment'
import PrintIcon from '@material-ui/icons/Print';
import SalesDetails from './SalesChild'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { SALES, SALES1 } from './SalesQuery'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import { ADD_SALESMASTER, UPDATE_SALESMASTER, DELETE_SALESMASTER } from './SalesMutation'
import { CompanyAndPartySelectComponent, CompanyAndPartyAllData } from '../../../components/DropdownSelect/SelectComponents/companyAndPartySelectComponent'
import { BrokerSelectComponent, BrokerSelectData } from '../../../components/DropdownSelect/SelectComponents/BrokerSelectComponent'
import { Currency, InvoiceTypes, CashOrCareditType } from '../../../components/Constant/index'
import { TermsSelectComponent, TransAllData } from '../../../components/DropdownSelect/SelectComponents/TermsSelectComponent'
import { FormatError } from '../../../components/Comman/FormatError'
import { Tofixed } from '../../../components/Comman/Tofix'
import { UserListAllData } from "../../../components/DropdownSelect/SelectComponents/UserNameSelectComponent";
import { cellStyle150, cellStyle170, headerStyle150, headerStyle170 } from "../../../components/Comman/StyleCell";
import { CustomFilterComponent } from '../../../components/CustomFilterComponent/index'
import { PrintDiv } from '../../../components/Comman/exportPdfAndExcel'
import { Accordion, AccordionDetails, AccordionSummary, useStyles } from '../../../components/Comman/Accordian'

import { PdfInvoice } from './pdfInvoice'
import Autocomplete from '@material-ui/lab/Autocomplete';
// const useStyles = makeStyles((theme) => ({
//   root: { flexGrow: 1, },
//   noMargin: { margin: 0 }
// }));

// const Accordion = withStyles({
//   root: {
//     border: '1px solid rgba(0, 0, 0, .125)',
//     boxShadow: 'none',
//     '&:before': {
//       display: 'none',
//     },
//     '&$expanded': {
//       margin: 'auto',
//     },
//   },
//   expanded: {},
// })(MuiAccordion);

const columns = [
  {
    title: 'Currency*',
    field: 'currency',
    lookup: Currency,
    cellStyle: cellStyle150,
    headerStyle: headerStyle150,
    validate: rowData => (rowData.currency && rowData.currency !== "") ? true : { isValid: false, helperText: `can't be empty` },
  },
  {
    title: 'Bill No',
    field: 'billNo',
    editable: "never",
    cellStyle: cellStyle150,
    headerStyle: headerStyle150,
    type: 'numeric',
  },
  {
    title: 'Invoice Type*',
    field: 'invoiceType',
    lookup: InvoiceTypes,
    validate: rowData => (rowData.invoiceType && rowData.invoiceType !== "") ? true : { isValid: false, helperText: `can't be empty` },
  },
  {
    title: 'Date*',
    field: 'date',
    type: 'date',
    initialEditValue: moment(),
    render: (rowData) => {
      if (rowData.date)
        return moment(rowData?.date || 0).format('DD/MM/YYYY')
    },
    validate: rowData => (rowData.date && rowData.date !== "") ? true : { isValid: false, helperText: `can't be empty` },
  },
  {
    title: 'Party Name*',
    field: 'partyId',
    cellStyle: {
      minWidth: 230
    },
    headerStyle: {
    },
    render: (rowData) => rowData?.partyId?.partyName || "-",
    editComponent: props => {
      let valueDefault = props?.rowData?.partyId?.id || props?.rowData?.partyId || '0'
      return <CompanyAndPartySelectComponent QueryName={"PARTY"} valueDefault={valueDefault} prosData={props} />
    }
  },
  {
    title: 'Broker Name*',
    field: 'brokerId',
    cellStyle: cellStyle170,
    headerStyle: headerStyle170,
    render: (rowData) => rowData?.brokerId?.partyName || "No Any Broker",
    editComponent: props => {
      let valueDefault = props?.rowData?.brokerId?.id || props?.rowData?.brokerId || '0'
      return <BrokerSelectComponent props={props} valueDefault={valueDefault} TYPE={"BROKER"} />
    }
  },
  {
    title: 'Term*',
    field: 'termId',
    cellStyle: cellStyle170,
    headerStyle: headerStyle170,
    render: (rowData) => rowData?.termId?.termName || "No Any Terms",
    editComponent: props => {
      let valueDefault = props?.rowData?.termId?.id || props?.rowData?.termId || '0'
      return <TermsSelectComponent filed="TermsWithdueDays" valueDefault={valueDefault} props={props} />
    }
  },
  {
    title: 'Cash/Credit*',
    field: 'cashOrCredit',
    lookup: CashOrCareditType,
    validate: rowData => (rowData.cashOrCredit && rowData.cashOrCredit !== "") ? true : { isValid: false, helperText: `can't be empty` },
  },
  {
    title: 'Due Days*',
    field: 'dueDays',
    render: (rowData) => {
      if (rowData.dueDays)
        return moment(rowData?.dueDays || 0).format('DD/MM/YYYY')
    },
    editComponent: props => {
      return props?.rowData?.dueDays || 0
    }
  },
  {
    title: 'Due Date*',
    field: 'dueDate',
    type: 'date',
    render: (rowData) => moment(rowData?.dueDate || 0).format('DD/MM/YYYY'),
    editComponent: (props) => {
      var d = moment()
      return props.rowData.dueDate ? moment(props?.rowData?.dueDate).format('DD-MM-YYYY') : d.format('DD-MM-YYYY')
    }
  },
  {
    title: 'Total Amt.',
    field: 'totalAmount',
    render: (rowData) => Tofixed(rowData?.totalAmount || 0),
    editComponent: (props) => {
      return props?.rowData?.totalAmount || 0
    }
  },
  {
    title: 'Zar Amt.',
    field: 'zarAmount',
    render: (rowData) => Tofixed(rowData?.totalZarAmount || 0),
    editComponent: (props) => {
      return props?.rowData?.totalZarAmount || 0
    }
  },
  {
    title: 'Total Commision',
    field: 'totalCommision',
    render: (rowData) => Tofixed(rowData?.totalCommision || 0),
    editComponent: (props) => {
      return props?.rowData?.totalCommision || 0
    }
  },
  {
    title: 'Total Zar Amt.',
    field: 'totalZarAmount',
    render: (rowData) => Tofixed((rowData?.totalZarAmount || 0) - (rowData?.totalCommision || 0)),
    editComponent: (props) => {
      return (props?.rowData?.totalZarAmount || 0 - props?.rowData?.totalCommision || 0)
    }
  },
  {
    title: 'Vat(%)*',
    field: 'vatInPer',
    editComponent: (props) => {
      let invoiceType = props?.rowData?.invoiceType || 0
      let per = invoiceType === "PERFOMA" || invoiceType === "TAX" ? 14 : 0
      if (props?.rowData?.id || null) {
        return <FilledInput onChange={(e) => {
          let Data = { ...props.rowData }
          Data.vatInPer = e.target.value
          props.onRowDataChange(Data)
        }} defaultValue={per} />
      } else {
        return per
      }
    }
  },
  {
    title: 'Vat',
    field: 'vat',
    type: "numeric",
    render: (rowData) => Tofixed(rowData?.vat || 0),
    editComponent: (props) => {
      let Data = { ...props.rowData }
      let vat = (parseInt(Data?.totalZarAmount || 0.00) * parseInt(Data?.vatInPer || 0.00) / 100)
      if (Data.vat !== vat) {
        Data.vat = vat
        props.onRowDataChange(Data)
      }
      return vat
    }
  },
  {
    title: 'Net Zar Amt.',
    field: 'netZarAmt',
    render: (rowData) => Tofixed(rowData?.netZarAmt || 0),
    editComponent: (props) => {
      let Data = { ...props.rowData }
      let netZarAmt = (parseInt(Data?.totalZarAmount || 0.00) + parseInt(Data?.vat || 0.00))
      if (Data.netZarAmt !== netZarAmt) {
        Data.netZarAmt = netZarAmt
        props.onRowDataChange(Data)
      }
      return netZarAmt
    }
  },
  { title: "User", field: "createdBy", render: (rowData) => rowData?.createdBy?.userName || " ", editable: "never" }

]

function Sales(props) {
  const classes = useStyles();
  const tableRef = useRef();
  const tableRef1 = useRef();

  const [filterData, setfilterData] = useState(10)
  const [filter, setfilter] = useState({})

  const [pageSize, setPageSize] = useState(10)
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowData, setSelectedRowData] = useState([]);
  const [selectedChildRowData, setselectedChildRowData] = useState([]);
  const [selectedFromDate, setSelectedFromDate] = useState(moment().subtract(7, "days"));
  const [selectedToDate, setSelectedToDate] = useState(new Date());
  const [partyName, setPartyName] = useState('');
  const [BrokerName, setBrokerName] = useState('');
  const permissions = props?.session?.me?.role?.permissions;
  const role = props?.session?.me?.role?.role;
  const path = props?.location?.pathname;
  let permission = _.find(permissions, function (o) { return o.name === path; })

  const { data, loading, refetch } = useQuery(SALES1, {
    variables: { filter: JSON.stringify({ ...filter }) },
    fetchPolicy: 'cache-and-network'
  })

  const { fetchMore, subscribeToMore } = useQuery(SALES, {
    variables: { page: 1, limit: 10, filter: JSON.stringify({}), sort: { key: 'createdAt', type: -1 } },
    fetchPolicy: 'cache-and-network'
  })

  columns.map(d => {
    if (d.field === "partyId") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: CompanyAndPartyAllData("PARTY"), getOptionLabel: (option) => option.partyName, multiple: true }} />
      }
    } else if (d.field === "brokerId") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: BrokerSelectData("BROKER"), getOptionLabel: (option) => option.partyName, multiple: true }} />
      }
    } else if (d.field === "termId") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: TransAllData(), getOptionLabel: (option) => option.termName, multiple: true }} />
      }
    }
    if (d.field === "createdBy") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: UserListAllData(), getOptionLabel: (option) => option.userName, multiple: true }} />
      }
    }
    return null
  })
  let parties = CompanyAndPartyAllData("PARTY");
  const [AddSalesMasterMutation] = useMutation(ADD_SALESMASTER)
  const [UpdateSalesMasterMutation] = useMutation(UPDATE_SALESMASTER)
  const [DeleteSalesMasterMutation] = useMutation(DELETE_SALESMASTER)

  const AddSalesMaster = (newData) => {
    return new Promise((resolve, reject) => {
      refetch()
      newData.vatInPer = parseFloat(newData.vatInPer ? newData.vatInPer : newData.invoiceType === "PERFOMA" || newData.invoiceType === "TAX" ? 14 : 0)
      newData.vat = parseFloat(newData.vat ? newData.vat : 0)
      newData.totalAmount = 0
      newData.zarAmount = 0
      newData.totalCommision = 0
      newData.totalZarAmount = 0
      newData.billNo = parseInt(newData?.billNo || 0)
      newData.netZarAmt = 0
      if ((0 <= newData.dueDays) && newData.partyId && newData.brokerId && newData.termId && (0 <= newData.vatInPer)) {
        if (!newData.dueDate) {
          newData.dueDate = moment()
        }
        let log = {
          action: 'INSERT',
          actionOn: 'SalesMaster',
          actionName: 'addSalesMaster',
          oldValue: "",
          message: `${newData?.invoiceType || ''} type sales was created.`
        }
        AddSalesMasterMutation({ variables: { input: newData, log } }).then((data) => {
          toast.success('sales master created successfully!');
          resolve()
        }).catch((e) => {
          reject()
          toast.error(FormatError(e))
        })
      } else {
        toast.warn('please, fill all required(*) fields.')
        reject()
      }
    })

  }

  const UpdateSalesMasterUsingDetails = (newData, oldData) => {
    return new Promise((resolve, reject) => {
      refetch()
      delete newData.createdBy
      delete newData.createdAt
      delete newData.updatedBy
      delete newData.updatedAt
      delete newData.__typename
      newData.partyId = newData.partyId.id ? newData.partyId.id : newData.partyId
      newData.brokerId = newData.brokerId.id ? newData.brokerId.id : newData.brokerId
      newData.billNo = parseInt(newData?.billNo || 0)
      newData.vatInPer = parseFloat(newData.vatInPer ? newData.vatInPer : newData.invoiceType === "PERFOMA" || newData.invoiceType === "TAX" ? 14 : 0)
      newData.termId = newData.termId.id ? newData.termId.id : newData.termId
      newData.vat = parseFloat(newData.vat)
      if ((0 <= newData.dueDays) && newData.partyId && newData.brokerId && newData.termId && (0 <= newData.vatInPer)) {
        UpdateSalesMasterMutation({ variables: { input: newData } }).then((data) => {
          resolve()
        }).catch((e) => {
          reject()
          toast.error(FormatError(e))
        })
      } else {
        toast.warn('please, fill all required(*) fields.')
        reject()
      }
    })
  }

  const UpdateSalesMaster = (newData, oldData) => {
    return new Promise((resolve, reject) => {
      refetch()
      delete newData.createdBy
      delete newData.createdAt
      delete newData.updatedBy
      delete newData.updatedAt
      delete newData.__typename
      newData.partyId = newData.partyId.id ? newData.partyId.id : newData.partyId
      newData.brokerId = newData.brokerId.id ? newData.brokerId.id : newData.brokerId
      newData.billNo = parseInt(newData?.billNo || 0)
      newData.vatInPer = parseFloat(newData.vatInPer ? newData.vatInPer : newData.invoiceType === "PERFOMA" || newData.invoiceType === "TAX" ? 14 : 0)
      newData.termId = newData.termId.id ? newData.termId.id : newData.termId
      newData.vat = parseFloat(Tofixed(newData.totalZarAmount * newData.vatInPer / 100))
      newData.netZarAmt = parseFloat(Tofixed(newData?.netZarAmt || 0))
      newData.totalAmount = parseFloat(Tofixed(newData?.totalAmount || 0))
      newData.totalCommision = parseFloat(Tofixed(newData?.totalCommision || 0))
      newData.vatInPer = parseFloat(Tofixed(newData?.vatInPer || 0))
      newData.zarAmount = parseFloat(Tofixed(newData?.zarAmount || 0))
      if ((0 <= newData.dueDays) && newData.partyId && newData.brokerId && newData.termId && (0 <= newData.vatInPer)) {
        let log = {
          action: 'UPDATE',
          actionOn: 'SalesMaster',
          actionName: 'updateSalesMaster',
          oldValue: JSON.stringify(oldData),
          message: `${oldData?.invoiceType || ''} type sales was updated.`
        }
        UpdateSalesMasterMutation({ variables: { input: newData, log } }).then((data) => {
          toast.success('sales master updated successfully ');
          resolve()
        }).catch((e) => {
          reject()
          toast.error(FormatError(e))
        })
      } else {
        toast.warn('please, fill all required(*) fields.')
        reject()
      }
    })
  }

  const DeleteSalesMaster = (oldData) => {
    return new Promise((resolve, reject) => {
      refetch()
      let log = {
        action: 'DELETE',
        actionOn: 'SalesMaster',
        actionName: 'deleteSalesMaster',
        oldValue: JSON.stringify(oldData),
        message: `${oldData?.invoiceType || ''} type sales was deleted.`
      }
      DeleteSalesMasterMutation({ variables: { id: oldData.id, log } }).then((data) => {
        toast.success('sales master Deleted successfully ');
        resolve()
      }).catch((e) => {
        reject()
        toast.error(FormatError(e))
      })
      resolve()
    })
  }

  const getSalesMasterData = (query) => {
    refetch()
    return new Promise(async (resolve, reject) => {
      let filter = {}, sort = { key: 'createdAt', type: -1 }
      query && query.filters.forEach(item => {
        if (item.column.field === "date" || item.column.field === "dueDate") {
          if (item.value)
            filter = { ...filter, [item.column.field]: moment(item.value).format('YYYY-MM-DD') }
          else {
            delete filter[`${item.column.field}`]
            filter = { ...filter }
          }
        } else if (item.value.length === 0) {
          delete filter[`${item.column.field}`]
          filter = { ...filter }
        } else {
          if (item.column.field === "invoiceNo") {
            item.value = parseInt(item.value)
          }
          if (item.column.field === "totalCts" || item.column.field === "totalCost" || item.column.field === "totalCostZar") {
            item.value = parseFloat(item.value)
          }
          filter = { ...filter, [item.column.field]: item.value }
        }
      });
      if (query && query.orderBy && query.orderDirection !== "") {
        sort = { key: query.orderBy.field, type: query.orderDirection === 'asc' ? 1 : -1 }
      }
      setPageSize(query.pageSize)
      try {
        let result = await fetchMore({
          variables: { page: query.page + 1, limit: query.pageSize, filter: JSON.stringify(filter), sort: sort },
          fetchPolicy: 'network-only',
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return fetchMoreResult;
          }
        })
        resolve({
          data: result?.data?.getSalesMaster?.data || [],
          page: query.page,
          totalCount: result?.data?.getSalesMaster?.count || 0,
        })
      } catch (error) {
        console.log(FormatError(error))
      }
    })
  }
  useEffect(() => {
    setfilterData(data)
  }, [data]);

  const addButton = (val) => {
    if (val || (role === 'admin'))
      return { onRowAdd: (newData) => AddSalesMaster(newData) }
    else
      return {}
  }

  const PreViewHandler = () => {
    let data = { ...filter }
    if (selectedFromDate && selectedToDate) {
      data = { ...data, 'startDate': moment(selectedFromDate).format('YYYY-MM-DD'), 'endDate': moment(selectedToDate).format('YYYY-MM-DD') }
    } else {
      toast.warn('please select from and to date')
    }
    if (BrokerName) {
      data = { ...data, "brokerId": BrokerName.id }
    }
    if (partyName) {
      data = { ...data, "partyId": partyName.id }
    }

    setfilter(data)
  }

  const ClearViewHandler = () => {
    setfilter({})
    refetch()
  }

  useEffect(() => {
    // document.getElementsByClassName("parentTable")[0].firstElementChild.firstElementChild.firstChild.remove()
  }, [])
  const PrintPdfData = () => {
    if (selectedRowData.length !== 0) {
      if (selectedRowData.length === 1) {
        PrintDiv()
      } else {
        toast.error('Sorry, Select Only One Jangad To Print!')
      }
    } else {
      toast.error('Please, Select Jangad To Print! ')
    }
  }
  let Broker = BrokerSelectData("BROKER");

  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid xs={12} item={true} container className="live-screen">
            <Grid item={true} xs={4} style={{ marginLeft: 10 }}>
              <PrintIcon onClick={(e) => PrintPdfData(e)} style={{ width: "50px", marginTop: 10 }} />
            </Grid>
            <Grid xs={4} item={true} style={{ textAlign: "center" }}>
              <Typography> Sales</Typography>
            </Grid>
          </Grid>
        </AccordionSummary>

        <AccordionDetails>
          <Grid container spacing={2} justify="center" alignItems="center">
            <Grid item lg={2} xs={4} sm={3}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-from"
                  label="From Date"
                  format="dd/MM/yyyy"
                  value={selectedFromDate}
                  onChange={e => setSelectedFromDate(e)}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                  style={{ marginTop: 0 }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item lg={2} xs={4} sm={3}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-to"
                  label="To Date"
                  format="dd/MM/yyyy"
                  value={selectedToDate}
                  onChange={e => setSelectedToDate(e)}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                  style={{ marginTop: 0 }}
                />

              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item lg={2} xs={4} sm={3} className="selectOption">
              {/* <FormControl className={classes.formControl} style={{ paddingTop: 0 }}> */}
              <Autocomplete
                value={partyName}
                options={parties}
                getOptionLabel={(option) => option.partyName}
                onChange={(e, newdata) => {
                  if (newdata) {
                    setPartyName(newdata)
                  }
                }}
                className="lotmarginCustom"
                renderInput={(params) => <TextField label="Party Name"  {...params} margin="normal" />}
              />
              {/* </FormControl> */}
            </Grid>
            <Grid item lg={2} xs={4} sm={3} className="selectOption">
              {/* <FormControl className={classes.formControl} style={{ paddingTop: 0 }}> */}
              <Autocomplete
                value={BrokerName}
                options={Broker}
                getOptionLabel={(option) => option.partyName}
                onChange={(e, newdata) => {
                  if (newdata) {
                    setBrokerName(newdata)
                  }
                }}
                className="lotmarginCustom"
                renderInput={(params) => <TextField label="Broker Name"  {...params} margin="normal" />}
              />
              {/* </FormControl> */}
            </Grid>
            <Grid item lg={4} xs={8} sm={6} className="selectOption" style={{ background: "red" }}>
              <Button
                onClick={() => PreViewHandler()}
                variant="contained"
                color="primary"
                style={{ height: 34, width: 100 }}>
                Preview
              </Button>
              <Button
                onClick={() => ClearViewHandler()}
                variant="contained"
                color="primary"
                style={{ height: 34, width: 100, marginLeft: 10 }}>
                clear
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
        <Grid container>
          <Grid item xs={12}>
            <div className="parentTable">
              <MaterialTable
                title="Sales"
                tableRef={tableRef1}
                columns={columns}
                isLoading={loading}
                data={filterData?.getSalesMaster1?.data || []}
                icons={{ Add: props => { return (<div> <i className="fa fa-plus-square" ></i>  </div>) } }}
                options={{
                  emptyRowsWhenPaging: false,
                  padding: 'dense',
                  showTitle: false,
                  debounceInterval: 300,
                  pageSize: pageSize,
                  pageSizeOptions: [5, 10, 15, 20, 25, 50],
                  addRowPosition: 'first',
                  filtering: false,
                  sorting: true,
                  search: false,
                  filterCellStyle: { padding: '5px', },
                  maxBodyHeight: window.innerHeight - 215,
                  selection: true,
                  rowStyle: rowData => ({
                    backgroundColor: (selectedRow === rowData.tableData.id) ? 'rgb(212, 218, 255)' : '#f5f5f5'
                  }),
                  headerStyle: { backgroundColor: 'whitesmoke' }
                }}
                detailPanel={rowData => {
                  return (
                    <SalesDetails
                      flag={true}
                      setSelectedRowData={(d) => setSelectedRowData(d)}
                      setselectedChildRowData={(d) => setselectedChildRowData(d)}
                      selectedRowData={selectedRowData}
                      session={props?.session}
                      location={props?.location}
                      Data={rowData}
                      UpdateSalesMaster={UpdateSalesMasterUsingDetails}
                    />
                  )
                }}
              // onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
              />
            </div>
          </Grid>
        </Grid>
      </Accordion>

      <Grid container>
        <Grid item xs={12}>
          <div className="parentTable">
            <MaterialTable
              title="Sales"
              tableRef={tableRef}
              columns={columns}
              data={query => getSalesMasterData(query)}
              icons={{ Add: props => { return (<div> <i className="fa fa-plus-square" ></i>  </div>) } }}
              options={{
                emptyRowsWhenPaging: false,
                padding: 'dense',
                showTitle: false,
                debounceInterval: 300,
                pageSize: pageSize,
                pageSizeOptions: [5, 10, 15, 20, 25, 50],
                addRowPosition: 'first',
                filtering: true,
                sorting: true,
                search: false,
                filterCellStyle: { padding: '5px', },
                maxBodyHeight: window.innerHeight - 215,
                selection: true,
                rowStyle: rowData => ({
                  backgroundColor: (selectedRow === rowData.tableData.id) ? 'rgb(212, 218, 255)' : '#f5f5f5'
                }),
                headerStyle: { backgroundColor: 'whitesmoke' }
              }}
              onSelectionChange={(rows) => setSelectedRowData(rows)}
              editable={{
                isEditHidden: (rowData) => (role === 'admin') ? false : !permission.isUpdate,
                isDeleteHidden: (rowData) => (role === 'admin') ? false : !permission.isDelete,
                ...addButton(permission?.isInsert),
                onRowUpdate: (newData, oldData) => UpdateSalesMaster(newData, oldData),
                onRowDelete: (oldData) => DeleteSalesMaster(oldData)
              }}
              detailPanel={rowData => {
                return (
                  <SalesDetails
                    setSelectedRowData={(d) => setSelectedRowData(d)}
                    setselectedChildRowData={(d) => setselectedChildRowData(d)}
                    selectedRowData={selectedRowData}
                    session={props?.session}
                    location={props?.location}
                    Data={rowData}
                    UpdateSalesMaster={UpdateSalesMasterUsingDetails}
                  />
                )
              }}
              onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
            />
          </div>
        </Grid>
      </Grid>

      <PdfInvoice selectedRowData={selectedRowData} selectedChildRowData={selectedChildRowData} />

    </div >
  );
}

export default withRouter(Sales)