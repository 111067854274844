import React from 'react'
import MaterialTable from 'material-table';
// import { useQuery, useMutation } from '@apollo/react-hooks'
// import { FilledInput } from '@material-ui/core'
import _ from 'lodash'


const columns = [
  {
    title: 'Lot No',
    field: 'lotNoId',
    cellStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    headerStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    customFilterAndSearch: (data, rowData) => {
      let regex = new RegExp(data, 'i')
      return (rowData?.lotNoId?.lotNo || "").match(regex)
    },
    render: (rowData) => rowData?.lotNoId?.lotNo || null
  },
  {
    title: 'Pkt No',
    field: 'pktNo',
    cellStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    headerStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    customFilterAndSearch: (data, rowData) => {
      let regex = new RegExp(data, 'i')
      return (rowData?.pktNoId?.pktNo || "").match(regex)
    },
    render: (rowData) => rowData?.pktNoId?.pktNo || null
  },
  {
    title: 'Location',
    field: 'location',
    cellStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    headerStyle: {
      minWidth: 150,
      textAlign: "center"
    },
  },
  {
    title: 'RFID',
    field: 'rfId',
    cellStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    headerStyle: {
      minWidth: 150,
      textAlign: "center"
    },
  },
  {
    title: 'Cts',
    field: 'cts',
    cellStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    headerStyle: {
      minWidth: 150,
      textAlign: "center"
    },
  },
  {
    title: 'Color',
    field: 'colorId',
    cellStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    headerStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    customFilterAndSearch: (data, rowData) => {
      let regex = new RegExp(data, 'i')
      return (rowData?.colorId?.shortName || "").match(regex)
    },
    render: (rowData) => rowData?.colorId?.shortName || "-"
  },

  {
    title: 'Shape',
    field: 'shapeId',
    cellStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    headerStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    customFilterAndSearch: (data, rowData) => {
      let regex = new RegExp(data, 'i')
      return (rowData?.shapeId?.shortName || "").match(regex)
    },
    render: (rowData) => rowData?.shapeId?.shortName || "-"
  },

  {
    title: 'Purity',
    field: 'purityId',
    cellStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    headerStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    customFilterAndSearch: (data, rowData) => {
      let regex = new RegExp(data, 'i')
      return (rowData?.purityId?.shortName || "").match(regex)
    },
    render: (rowData) => rowData?.purityId?.shortName || "-"
  },

  {
    title: 'Cut',
    field: 'cutId',
    cellStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    headerStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    customFilterAndSearch: (data, rowData) => {
      let regex = new RegExp(data, 'i')
      return (rowData?.cutId?.shortName || "").match(regex)
    },
    render: (rowData) => rowData?.cutId?.shortName || "-"
  },

  {
    title: 'Polish',
    field: 'polishId',
    cellStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    headerStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    customFilterAndSearch: (data, rowData) => {
      let regex = new RegExp(data, 'i')
      return (rowData?.polishId?.shortName || "").match(regex)
    },
    render: (rowData) => rowData?.polishId?.shortName || "-"
  },
  {
    title: 'Symm',
    field: 'symmId',
    cellStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    headerStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    customFilterAndSearch: (data, rowData) => {
      let regex = new RegExp(data, 'i')
      return (rowData?.symmId?.shortName || "").match(regex)
    },
    render: (rowData) => rowData?.symmId?.shortName || "-"
  },
  {
    title: 'Flue',
    field: 'fluId',
    cellStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    headerStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    customFilterAndSearch: (data, rowData) => {
      let regex = new RegExp(data, 'i')
      return (rowData?.fluId?.shortName || "").match(regex)
    },
    render: (rowData) => rowData?.fluId?.shortName || "-"
  },
  {
    title: 'Measure',
    field: 'measurment',
    cellStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    headerStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    // render: (rowData) => <FilledInput type="number" defaultValue={rowData?.expectedCts || 0} style={{ width: 50 }} onChange={(e) => {
    //   rowData.expectedCts = parseInt(e.target.value)
    // }} />
  },

  {
    title: 'Brown',
    field: 'brown',
    cellStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    headerStyle: {
      minWidth: 150,
      textAlign: "center"
    },
  },
  {
    title: 'Grad %',
    field: 'gradPer',
    cellStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    headerStyle: {
      minWidth: 150,
      textAlign: "center"
    },
  },
  {
    title: 'Rape Rate',
    field: 'rapRate',
    cellStyle: {
      minWidth: 150,
      textAlign: "center"
    },
    headerStyle: {
      minWidth: 150,
      textAlign: "center"
    },
  }

]


export const PacketDetails = (props) => {

  return <MaterialTable
    title={"Packet Details"}
    columns={columns}
    isLoading={props.loading}
    data={props?.PacketDetailsData || []}
    options={{
      emptyRowsWhenPaging: false,
      padding: 'dense',
      selection: true,
      search: false,
      paging: true,
      pageSize: 20,
      pageSizeOptions: [20, 50, 100, 150, 200, 250, 300, 400, 500],
      filtering: true,
      sorting: false,
      minBodyHeight: window.innerHeight - 500,
      maxBodyHeight: window.innerHeight - 500,
      headerStyle: {
        backgroundColor: '#8CB836',
        color: '#f5f5f5'
      }
    }}
    onSelectionChange={(rows) => {
      let Ids = _.map(rows, 'id')
      props.setPacketDetailsSelectedData(Ids)
    }}
  />
}