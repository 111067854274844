import React, { useState, useRef, useEffect } from 'react'
import MaterialTable from 'material-table';
import { DEPARTMENTISSUERETURN } from './Query'
import { useQuery } from '@apollo/react-hooks'
import moment from 'moment'
import './style.css'
import { FromDepartmentAllData } from '../../../components/DropdownSelect/SelectComponents/FromDepartmentSelectComponent'
import { ProcessAllData } from '../../../components/DropdownSelect/SelectComponents/ProcesSelectComponent'
import { UserListAllData } from "../../../components/DropdownSelect/SelectComponents/UserNameSelectComponent";
import { CustomFilterComponent } from '../../../components/CustomFilterComponent/index'
import { FormatError } from '../../../components/Comman/FormatError'
const columns = [
  { title: 'Jagad No', field: 'jagadNo' },
  {
    title: 'Return Date', field: 'returnDate', type: "date", render: (rowData) => {
      if (rowData.returnDate)
        return moment(rowData?.returnDate || 0).format('DD/MM/YYYY')
    }
  },
  { title: 'From Department', field: 'fromDept', render: (rowData) => rowData?.fromDept?.deptName || 0 },
  { title: 'To Department', field: 'toDept', render: (rowData) => rowData?.toDept?.deptName || 0 },
  { title: 'Process Name', field: 'process', render: (rowData) => rowData?.process?.procName || 0, },
  { title: "User", field: "createdBy", render: (rowData) => rowData?.createdBy?.userName || " ", editable: "never" }
]
export const MasterView = (props) => {
  const tableRef = useRef();
  const [pageSize, setPageSize] = useState(10)

  const { subscribeToMore, fetchMore, refetch } = useQuery(DEPARTMENTISSUERETURN, {
    variables: { page: 1, limit: 10, filter: JSON.stringify({}), sort: { key: 'createdAt', type: -1 } },
    fetchPolicy: 'cache-and-network',
  })
  columns.map(d => {
    if (d.field === "createdBy") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: UserListAllData(), getOptionLabel: (option) => option.userName, multiple: true }} />
      }
    }
    if (d.field === "fromDept") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: FromDepartmentAllData(), getOptionLabel: (option) => option.deptName, multiple: true }} />
      }
    }
    if (d.field === "toDept") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: FromDepartmentAllData(), getOptionLabel: (option) => option.deptName, multiple: true }} />
      }
    }
    if (d.field === "process") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: ProcessAllData(), getOptionLabel: (option) => option.procName, multiple: true }} />
      }
    }
    return null
  })

  const getDepartmentIssueReturnData = (query) => {
    refetch()
    return new Promise(async (resolve, reject) => {
      let filter = {}, sort = { key: 'createdAt', type: -1 }
      query && query.filters.forEach(item => {

        if (item.column.field === "returnDate") {
          if (item.value)
            filter = { ...filter, [item.column.field]: moment(item.value).format('YYYY-MM-DD') }
          else {
            delete filter[`${item.column.field}`]
            filter = { ...filter }
          }
        } else if (item.value.length === 0) {
          delete filter[`${item.column.field}`]
          filter = { ...filter }
        } else {
          filter = { ...filter, [item.column.field]: item.value }
        }
      });
      if (query && query.orderBy && query.orderDirection !== "") {
        sort = { key: query.orderBy.field, type: query.orderDirection === 'asc' ? 1 : -1 }
      }
      setPageSize(query.pageSize)
      try {
        let result = await fetchMore({
          variables: { page: query.page + 1, limit: query.pageSize, filter: JSON.stringify(filter), sort: sort },
          fetchPolicy: 'network-only',
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return fetchMoreResult;
          }
        })
        resolve({
          data: result?.data?.getDepartmentIssueReturn?.data || [],
          page: query.page,
          totalCount: result?.data?.getDepartmentIssueReturn?.count || 0,
        })
      } catch (error) {
        console.log(FormatError(error))
      }
    })
  };

  return <div className="mainMasterView">
    <MaterialTable
      tableRef={tableRef}
      columns={columns}
      data={query => getDepartmentIssueReturnData(query)}
      options={{
        emptyRowsWhenPaging: false,
        debounceInterval: 300,
        showTitle: false,
        padding: 'dense',
        pageSize: pageSize,
        pageSizeOptions: [5, 10, 15, 20, 25, 50],
        addRowPosition: 'first',
        filtering: true,
        sorting: true,
        search: false,
        filterCellStyle: { padding: '5px', },
        maxBodyHeight: window.innerHeight - 215,
        headerStyle: { backgroundColor: 'whitesmoke' }
      }}
      onChangeRowsPerPage={size => setPageSize(size)}
      onRowClick={((evt, selectedRow) => {
        props.closeAccordian(false)
        props.MasterSelectedData(selectedRow)
      })}
    />
  </div>
}