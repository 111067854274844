import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { ALLTERMS } from '../../../pages/Master/Terms/TermsQuery';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import _ from 'lodash'
import moment from 'moment'
import { FormatError } from '../../Comman/FormatError'
export const TransAllData = () => {
  const { error, data } = useQuery(ALLTERMS, {
    fetchPolicy: 'cache-and-network',
  })
  if (error)
    console.log(FormatError(error));
  let Termsnewdata = data?.getAllTerms || []
  return Termsnewdata
}

export const TermsLookup = () => {
  let newtermsdata = {}
  TransAllData().map((d, index) => {
    newtermsdata[d.id] = d.termName
    return null
  })
  return newtermsdata
}
export const TermsSelectComponent = (props) => {

  const changeselect = (e, newValue) => {
    let propsData = props.props
    let Data = { ...propsData.rowData }
    if (props.filed === "TermsWithdueDays") {
      Data.dueDays = newValue.Days
      Data.dueDate = moment().add(parseInt(Data.dueDays), 'days').toISOString()
    }
    Data.termId = newValue.id
    propsData.onRowDataChange(Data)
  }
  const defaultProps = {
    options: TransAllData(),
    getOptionLabel: (option) => option.termName
  };

  return <Autocomplete
    {...defaultProps}
    disableClearable
    onChange={(e, newValue) => changeselect(e, newValue)}
    defaultValue={_.filter(TransAllData(), (newd) => newd.id === props.valueDefault)[0]}
    renderInput={(params) => <TextField   {...params} margin="normal" />}
  />

}