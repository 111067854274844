import gql from 'graphql-tag';

export const ADD_SALESMASTER = gql`
    mutation addSalesMaster($input: salesMasterInput!, $log: LogInput) {
      addSalesMaster(input: $input, log: $log) {
        id
        date
        currency
        invoiceType
        billNo
        partyId
        brokerId
        termId
        cashOrCredit
        dueDays
        dueDate
        totalAmount
        zarAmount
        createdBy
        createdAt
        updatedBy
        totalCommision
        totalZarAmount
        vatInPer
        vat
        netZarAmt
      }
    }
`;

export const UPDATE_SALESMASTER = gql`
    mutation updateSalesMaster($input:salesMasterUpdate!, $log: LogInput){
      updateSalesMaster(input:$input, log: $log){
          id
          date
          currency
          invoiceType
          billNo
          partyId
          brokerId
          termId
          cashOrCredit
          dueDays
          dueDate
          totalAmount
          zarAmount
          createdBy
          createdAt
          updatedBy
          totalCommision
          totalZarAmount
          vatInPer
          vat
          netZarAmt
      }
    }
`;
export const DELETE_SALESMASTER = gql`
mutation deleteSalesMaster($id:ID!, $log: LogInput){
  deleteSalesMaster(id: $id, log: $log)
}
`;

export const ADD_SALESDETAIL = gql`
mutation  addSalesDetail($input: salesDetailInput!, $log: LogInput){
  addSalesDetail(input:$input, log: $log){
    id
    salesMasterId
    type
    barcode
    lotNoId
    pktNoId
    cts
    lab
    certificateNo
    colorId
    shapeId
    purityId
    cutId
    polishId
    symmId
    floId
    rapRate
    rapAmt
    stkDiscPer
    salesDiscPer
    diffPer
    netRaprate
    netRaprateAmt
    rateZar
    amountZar
    commisionPer
    commision
    polishExpPer
    polishExpAmt
    netAmtZar
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}
`;


export const UPDATE_SALESDETAIL = gql`
mutation updateSalesDetail($input: salesDetailUpdate!, $log: LogInput){
  updateSalesDetail(input:$input, log: $log){
     id
    salesMasterId
    type
    barcode
    lotNoId
    pktNoId
    cts
    lab
    certificateNo
    colorId
    shapeId
    purityId
    cutId
    polishId
    symmId
    floId
    rapRate
    rapAmt
    stkDiscPer
    salesDiscPer
    diffPer
    netRaprate
    netRaprateAmt
    rateZar
    amountZar
    commisionPer
    commision
    polishExpPer
    polishExpAmt
    netAmtZar
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}
`;



export const DELETE_SALESDETAIL = gql`
  mutation deleteSalesDetail($id:ID!, $log: LogInput){
    deleteSalesDetail(id: $id, log: $log)
  }
`;
