import React from "react";
import { withRouter } from "react-router-dom";
import MaterialTable from "material-table";
import moment from "moment";
import { Tofixed } from "../../../../components/Comman/Tofix";
// import _ from "lodash";

// import './planning.css'
const columns = [
  {
    title: "Plan Date",
    field: "createdAt",
    render: (rowData) => {
      let Data = rowData?.roughPlanning?.createdAt || null;
      return Data ? moment(Data).format("DD/MM/YYYY") : "";
    },
    headerStyle: {
      backgroundColor: "#0b9",
    },
  },
  {
    title: "Exp. Cts",
    field: "expCts",
    render: (rowData) => Tofixed(rowData?.roughPlanning?.expCts || 0),
    headerStyle: {
      backgroundColor: "#0b9",
    },
  },
  {
    title: "Shape",
    field: "shapeId",
    render: (rowData) => rowData?.roughPlanning?.shapeId?.shortName || "",
    headerStyle: {
      backgroundColor: "#0b9",
    },
  },
  {
    title: "Color",
    field: "colorId",
    render: (rowData) => rowData?.roughPlanning?.colorId?.shortName || "",
    headerStyle: {
      backgroundColor: "#0b9",
    },
  },
  {
    title: "Purity",
    field: "purityId",
    render: (rowData) => rowData?.roughPlanning?.purityId?.shortName || "",
    headerStyle: {
      backgroundColor: "#0b9",
    },
  },
  {
    title: "Cut",
    field: "cutId",
    render: (rowData) => rowData?.roughPlanning?.cutId?.shortName || "",
    headerStyle: {
      backgroundColor: "#0b9",
    },
  },
  {
    title: "Polish",
    field: "polishId",
    render: (rowData) => rowData?.roughPlanning?.polishId?.shortName || "",
    headerStyle: {
      backgroundColor: "#0b9",
    },
  },
  {
    title: "Symm",
    field: "symmId",
    render: (rowData) => rowData?.roughPlanning?.symmId?.shortName || "",
    headerStyle: {
      backgroundColor: "#0b9",
    },
  },
  {
    title: "Flu",
    field: "fluId",
    render: (rowData) => rowData?.roughPlanning?.fluId?.shortName || "",
    cellStyle: {
      minWidth: 150,
      textAlign: "center",
    },
    headerStyle: {
      backgroundColor: "#0b9",
      textAlign: "center",
    },
  },
  {
    title: "Comment",
    field: "comment",
    render: (rowData) => rowData?.roughPlanning?.comment || "",
    headerStyle: {
      backgroundColor: "#0b9",
    },
    cellStyle: {
      minWidth: 250,
      textAlign: "center",
    },
  },
];
function Planing(props) {
  //   const [selectedRow, setSelectedRow] = useState(null);
  return (
    <MaterialTable
      title="Planing"
      columns={columns}
      tableRef={props.tableRef}
      isLoading={props.loading}
      data={props.AllData}
      options={{
        emptyRowsWhenPaging: false,
        padding: "dense",
        pageSizeOptions: [5, 10, 15, 20, 25, 50],
        addRowPosition: "first",
        filtering: false,
        sorting: true,
        search: false,
        paging: false,
        filterCellStyle: { padding: "5px" },
        // maxBodyHeight: window.innerHeight - 215,
        rowStyle: (rowData) => ({
          backgroundColor: rowData.lotNo === "Grand Total" ? "rgb(212, 218, 255)" : "#FFF",
          fontWeight: rowData.lotNo === "Grand Total" && 'bolder'
        }),
      }}
    />
  );
}
export default withRouter(Planing);
