import gql from 'graphql-tag';

export const GET_ME = gql`
  {
    me {
      id
      userName
      email
      userType
      role{
        id
        role
        permissions
      }
    }
  }
`;
