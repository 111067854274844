import gql from 'graphql-tag';

export const ADD_INWARDMASTER = gql`
mutation addNewInward($input: inwardMasterInput!, $log: LogInput){
  addNewInward(input:$input, log: $log){
    id
    inwardNo
    inwardType
    date
    partyId
    termId
    lessPer1
    lessPer2
    brokerId
    invoiceNo
    dueDays
    dueDate
    billType
    salesmanId
    prefix
    comment
    totalPackets
    totalCts
    amountZar
    amountTotalNet
    brinksCharges
    dbCharges
    bankCharges
    netAmount
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}
`;

export const UPDATE_INWARDMASTER = gql`
mutation updateInward($input: inwardMasterUpdate!, $log: LogInput){
  updateInward(input:$input, log: $log){
    id
    inwardNo
    inwardType
    date
    partyId
    termId
    lessPer1
    lessPer2
    brokerId
    invoiceNo
    dueDays
    dueDate
    billType
    salesmanId
    prefix
    comment
    totalPackets
    totalCts
    amountZar
    amountTotalNet
    brinksCharges
    dbCharges
    bankCharges
    netAmount
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}
`;

export const DELETE_INWARDMASTER = gql`
  mutation deleteInward($id:ID!, $log: LogInput){
    deleteInward(id:$id, log: $log)
  }
`;


export const ADD_INWARDDETAIL = gql`
mutation addNewInwardDetail($input: inwardDetailInput!, $log: LogInput){
  addNewInwardDetail(input:$input, log: $log){
    id
    inwardMasterId
    srNo
    location
    lotNo
    packetNo
    lab
    certifiNo
    refNo
    rfId
    cts
    depth
    table
    colorId
    shapeId
    purityId
    cutId
    polishId
    symmId
    fluId
    measurment
    brown
    gridlePer
    rateRap
    amountRap
    purityDiscountPer
    stkDiscountPer
    lessPer1
    lessPer2
    brokeragePer
    brokerageAmount
    transExpensesPer
    transExpensesPerAmount
    totalAmount
    netAmountDollar
    rateZar
    amountZar
    btTransId
    btSrNo
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}
`;


export const UPDATE_UPDATEINWARDDETAIL = gql`
mutation updateInwardDetail($input: inwardDetailUpdate!, $log: LogInput){
  updateInwardDetail(input:$input, log: $log){
    id
    inwardMasterId
    srNo
    location
    lotNo
    packetNo
    lab
    certifiNo
    refNo
    rfId
    cts
    colorId
    shapeId
    purityId
    cutId
    polishId
    symmId
    fluId
    measurment
    brown
    depth
    table
    gridlePer
    rateRap
    amountRap
    purityDiscountPer
    stkDiscountPer
    lessPer1
    lessPer2
    brokeragePer
    brokerageAmount
    transExpensesPer
    transExpensesPerAmount
    totalAmount
    netAmountDollar
    rateZar
    amountZar
    btTransId
    btSrNo
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}
`;


export const DELETE_INWARDDETAIL = gql`
  mutation deleteInwardDetail($id: ID!, $log: LogInput){
    deleteInwardDetail(id:$id, log: $log)
  }
`;
