/********************* Libray **************** */
import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useQuery } from '@apollo/react-hooks'
import { CircularProgress } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import Typography from '@material-ui/core/Typography';
import PrintIcon from '@material-ui/icons/Print';
import _ from 'lodash'
import { Grid, Button } from '@material-ui/core';
import './PolishLiveScreen.css';
import { GROUPBY_LOCATION_WISE, GROUPBY_DEPARTMENT_NAME, GROUPBY_PARTY_NAME, GROUPBY_CERTIFIED, GET_SALES_FILTER_DATA, GROUPBY_LOTNO_NAME } from './query'
import { LocationWise } from './Pages/LocationWise'
import { DepartmentWise } from './Pages/DepartmentWise'
import { PartyWise } from './Pages/PartyWise'
import { CertifiedWise } from './Pages/CertifiedWise'
import { SelectedData } from './Pages/SelectedData'
import { LotNoWise } from './Pages/LotNoWise'
import { PrintDiv } from '../../../components/Comman/exportPdfAndExcel'
import ExcelPng from '../../../../Excel.png'
import { CommponExport } from '../../../components/ExportReport/CommponExport'
import { Tofixed } from '../../../components/Comman/Tofix';
import { ExportCsv } from '../../../components/ExportReport/ExportCsv'
import * as XLSX from 'xlsx/xlsx.mjs';
import moment from 'moment';


const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);



const columns = [
  { title: 'party Name', field: 'partyName' },
  { title: 'Total pkts', field: 'TotalPkt' },
  { title: 'Total Cts', field: 'TotalCts' },
]

const useStyles = makeStyles(() => ({
  denseTable: {
    '& td': { padding: "2px 5px", cursor: "pointer", textAlign: "center" },
    '& th': { padding: "5px 5px", cursor: "pointer", textAlign: "center" }
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: window.innerHeight - 120,
    pointerEvents: 'none',
    opacity: 0.5
  }
}))

function PolishLiveScreen() {
  const classes = useStyles();
  const [filter, SetFilter] = React.useState({})
  const [isRefresh, SetIsRefresh] = React.useState(false)

  const { data: locationWiseData, loading: locationWiseLoading, refetch: locationWiseRefetch } = useQuery(GROUPBY_LOCATION_WISE, {
    fetchPolicy: 'cache-and-network'
  })
  const { data: DepartmentWiseData, loading: DepartmentWiseLoading, refetch: DepartmentWiseRefetch } = useQuery(GROUPBY_DEPARTMENT_NAME, {
    fetchPolicy: 'cache-and-network'
  })
  const { data: PartyWiseData, loading: PartyWiseLoading, refetch: PartyWiseRefetch } = useQuery(GROUPBY_PARTY_NAME, {
    fetchPolicy: 'cache-and-network'
  })
  const { data: CertifiedData, loading: CertifiedLoading, refetch: CertifiedRefetch } = useQuery(GROUPBY_CERTIFIED, {
    fetchPolicy: 'cache-and-network'
  })
  const { data: SelectedFilterData, loading: SelectedFilterLoading, refetch: SelectedFilterRefetch } = useQuery(GET_SALES_FILTER_DATA, {
    variables: { filter: JSON.stringify(filter) },
    fetchPolicy: 'cache-and-network'
  })
  const { data: LotNoWiseFilterData, loading: LotNoWiseFilterLoading, refetch: LotNoWiseFilterRefetch } = useQuery(GROUPBY_LOTNO_NAME, {
    variables: { filter: JSON.stringify(filter) },
    fetchPolicy: 'cache-and-network'
  })
  const pdfHeader = () => {
    return <div style={{ marginBottom: 15 }}>
      <h2 style={{ textAlign: "center" }}>Party wise </h2>
      <div style={{ display: "flex", width: "100%" }}>
        <div style={{ width: "50%" }}>
          < p style={{ margin: 0, fontSize: 18, fontWeight: "bold" }}>Tot Pkts: : {_.sumBy(PartyWiseData?.getSalesStockForGroupByPartyName || [], (d) => d.TotalPkt) || 0}</p>
        </div>
        <div style={{ width: "50%", textAlign: "end" }}>
          < p style={{ margin: 0, fontSize: 18, fontWeight: "bold" }}>Cts : {Tofixed(_.sum(_.map(PartyWiseData?.getSalesStockForGroupByPartyName || [], (d) => parseFloat(d.TotalCts))))}</p>
        </div>
      </div>
    </div>
  }
  const TableHeader = () => {
    return <>
      <tr>
        <td colSpan={columns.length}>
          <h2 style={{ textAlign: "center" }}>Party wise  </h2>
        </td>
      </tr>
      <tr >
        <td colSpan={columns.length - 2}>
          < p style={{ margin: 0, fontSize: 18, fontWeight: "bold" }}>Tot Pkts: : {_.sumBy(PartyWiseData?.getSalesStockForGroupByPartyName || [], (d) => d.TotalPkt) || 0}</p>
        </td>
        <td colSpan={2} style={{ textAlign: "end" }}>
          < p style={{ margin: 0, fontSize: 18, fontWeight: "bold" }}>Cts : {Tofixed(_.sum(_.map(PartyWiseData?.getSalesStockForGroupByPartyName || [], (d) => parseFloat(d.TotalCts))))}</p>
        </td>
      </tr>
    </>
  }
  const RefreshButton = () => {
    SetIsRefresh(true)
    SetFilter({})
    locationWiseRefetch()
    DepartmentWiseRefetch()
    PartyWiseRefetch()
    CertifiedRefetch()
    SelectedFilterRefetch()
    LotNoWiseFilterRefetch()
  }
  function exportExcelFile(workbook) {
    return XLSX.writeFile(workbook, `PolishLiveScreen${moment()}.xlsx`);
  }

  const exportCsvData = () => {
    var workbook = XLSX.utils.book_new();
    var worksheet_data = document.getElementById("table-to-xls");
    var worksheet = XLSX.utils.table_to_sheet(worksheet_data);
    workbook.SheetNames.push("Test");
    workbook.Sheets["Test"] = worksheet;
    exportExcelFile(workbook);
  }  
  React.useEffect(() => {
    SetIsRefresh(false)
  }, [isRefresh])
  if (locationWiseLoading || DepartmentWiseLoading || PartyWiseLoading || CertifiedLoading) {
    return <Grid container className={classes.loader} ><img src="https://i.gifer.com/4V0b.gif" alt="loading" /></Grid>
  } else
    return (
      <>
        <Accordion>
          <Grid container className="polish-live-screen">
            <Grid xs={1} item >
              <PrintIcon
                onClick={(e) => PrintDiv(e)}
                style={{ width: "50px", marginTop: 10 }} />
              <div className="planing-compaision-ReportExcel" style={{ position: "absolute", top: 6 }}>
                <img id="amits" src={ExcelPng} alt="alternative text" title="csv" onClick={() => exportCsvData()} style={{ position: "absolute", left: 50, top: 10 }} />
                <ExportCsv
                  TableHeader={TableHeader()}
                  columns={columns}
                  AllData={
                    _.map(PartyWiseData?.getSalesStockForGroupByPartyName || [], (d) => {
                      d.partyName = d?.issuePartyId?.partyName || ''
                      d.TotalCts = Tofixed(d?.TotalCts || 0)
                      return d
                    })
                  }
                />
              </div>
            </Grid>
            <Grid item xs={3} style={{ marginLeft: 10 }}>
              <Button type="submit" className={classes.form} variant="contained" disabled={isRefresh} onClick={e => RefreshButton(e)} color="primary" >
                {isRefresh && <CircularProgress size={24} className={classes.buttonProgress} />} Refresh
              </Button>
            </Grid>

            <Grid xs={4} item style={{ textAlign: "center" }}><Typography>Polish Live Screen</Typography></Grid>
          </Grid>
        </Accordion>

        <Grid container alignContent="space-between">
          {/* <LocationWise Data={locationWiseData?.getSalesStockForGroupByLocationName || []} SetFilter={(d) => SetFilter(d)} /> */}
          <DepartmentWise Data={DepartmentWiseData?.getSalesStockForGroupByDepartmentName || []} SetFilter={(d) => SetFilter(d)} />
          <PartyWise Data={PartyWiseData?.getSalesStockForGroupByPartyName || []} SetFilter={(d) => SetFilter(d)} />
          {/* <CertifiedWise Data={CertifiedData?.getSalesStockForGroupByCertified || []} SetFilter={(d) => SetFilter(d)} /> */}
        </Grid>
        {Object.keys(filter).length > 0 ?
          <Grid container alignContent="space-between" justify="space-between">
            <SelectedData Data={SelectedFilterData?.getSalesFilterData || []} loading={SelectedFilterLoading} />
            <LotNoWise Data={LotNoWiseFilterData?.getSalesStockForGroupByLotNoName || []} loading={LotNoWiseFilterLoading} />
          </Grid> : ""
        }
        <CommponExport
          columns={columns}
          pdfHeader={pdfHeader()}
          AllData={
            _.map(PartyWiseData?.getSalesStockForGroupByPartyName || [], (d) => {
              d.partyName = d?.issuePartyId?.partyName || ''
              d.TotalCts = Tofixed(d?.TotalCts || 0)
              return d
            })
          } />
      </>
    )
}

export default PolishLiveScreen;