import React, { useRef } from 'react'
import MaterialTable from 'material-table';
import { useQuery } from '@apollo/react-hooks'
// import _ from 'lodash'
import { Grid } from '@material-ui/core';
import { GETPURCHASEDATAFORDASHBOARD } from './Query'
import moment from 'moment'
const columns = [
  {
    title: 'Invoice No',
    field: 'invoiceNo',
  },
  {
    title: 'Inward Date',
    field: 'date',
    render: (rowData) => {
      let Date = rowData?.date || null
      if (Date) {
        return moment(Date).format('DD-MM-YYYY')
      }
    }
  },
  {
    title: 'Party Name',
    field: 'partyId.partyName',
  },
  {
    title: 'Term Name',
    field: 'termId.termName'
  },
  {
    title: 'Reminder  Day',
    field: 'dueDays'
  }
]


export const PurchaseTermsRemainder = (props) => {
  const tableRef = useRef();
  const { data, loading } = useQuery(GETPURCHASEDATAFORDASHBOARD, {
    variables: { filter: JSON.stringify({ dueDate: moment().format("YYYY-MM-DD") }) },
    fetchPolicy: 'cache-and-network'
  })


  let Data = data?.getPurchaseDataForDashboard || []
  let TotalsData = Data.filter(d => d.invoiceNo === "Total")
  if (Data.length !== 0 && TotalsData.length === 0) {
    let LastRow = {
      invoiceNo: "Total",
      dueDays: Data.length,
    }
    Data.push(LastRow)
  }


  return <Grid>

    <MaterialTable
      ref={tableRef}
      title={`Purchase Term Reminder`}
      columns={columns}
      isLoading={loading}
      data={Data}
      options={{
        emptyRowsWhenPaging: false,
        padding: 'dense',
        search: false,
        paging: false,
        sorting: false,
        filtering: true,
        minBodyHeight: window.innerHeight - 750,
        maxBodyHeight: window.innerHeight - 750,
        headerStyle: {
          backgroundColor: '#8CB836',
          color: '#f5f5f5'
        },
        rowStyle: rowData => ({
          backgroundColor: (rowData.invoiceNo === "Total") ? 'rgb(212, 218, 255)' : '#f5f5f5'
        })
      }}
    />
  </Grid>
}