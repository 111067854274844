import gql from 'graphql-tag';

export const ADD_COSTINGMASTER = gql`
mutation  addNewCosting($input: costingMasterInput!, $log: LogInput){
  addNewCosting(input:$input, log: $log){
    id
    invoiceNo
    date
    lotNoId
    partyId
    costId
    costType
    totalCts
    totalCost
    totalCostZar
    createdBy
    updatedBy
    createdAt
  }
}
`;

export const UPDATE_COSTINGMASTER = gql`
  mutation updateCosting($input: costingMasterUpdate!, $log: LogInput){
    updateCosting(input:$input, log: $log){
      id
      invoiceNo
      date
      lotNoId
      partyId
      costId
      costType
      totalCts
      totalCost
      totalCostZar
      updatedBy
      updatedAt
    }
  }
`;
export const DELETE_COSTINGMASTER = gql`
mutation deleteCoasting($id:ID!, $log: LogInput){
  deleteCosting(id: $id, log: $log)
}
`;

export const ADD_COSTINGDETAIL = gql`
  mutation addNewCostingDetail($input: costingDetailInput!, $log: LogInput){
    addNewCostingDetail(input:$input, log: $log){
      id
      srNo
      costingMasterId
      lotNoId
      pktNoId
      cts
      rate
      cost
      isEditable
      rateZar
      costZar
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;


export const UPDATE_COSTINGDETAIL = gql`
mutation updateCostingDetail($input: costingDetailUpdate!, $log: LogInput){
  updateCostingDetail(input:$input, log: $log){
    id
    srNo
    costingMasterId
    lotNoId
    pktNoId
    cts
    rate
    cost
    rateZar
    costZar
    isEditable
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}
`;



export const DELETE_COSTINGDETAIL = gql`
    mutation deleteCostingDetail($id: ID!, $log: LogInput){
      deleteCostingDetail(id:$id, log: $log)
    }
`;
