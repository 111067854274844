import gql from 'graphql-tag';

export const ADD_ROUGHPLANNING = gql`
mutation addNewRoughPlanning($input: roughPlanningInput!, $log: LogInput){
  addNewRoughPlanning(input:$input, log: $log){
    id
    isApprove
    partyId
    barcode
    lotNoId
    pktNoId
    planNo
    part
    orgCts
    expCts
    colorId
    shapeId
    purityId
    cutId
    polishId
    symmId
    fluId
    comment
    isMake
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}
`;


export const UPDATE_ROUGHPLANNING = gql`
    mutation updateRoughPlanning($input: roughPlanningUpdate!, $log: LogInput){
  updateRoughPlanning(input:$input, log: $log){
     id
    isApprove
    partyId
    barcode
    lotNoId
    pktNoId
    planNo
    part
    orgCts
    expCts
    colorId
    shapeId
    purityId
    cutId
    polishId
    symmId
    fluId
    comment
    isMake
    updatedBy
    updatedAt
  }
}

`;


export const DELETE_ROUGHPLANNING = gql`
mutation  deleteRoughPlanning($id: ID!, $log: LogInput){
  deleteRoughPlanning(id:$id, log: $log)
  }
`;



