import React from 'react'
import moment from 'moment'
export const ExportCsv = (props) => {
  return <div
  style={{ display: "none" }}
  >
    <table id={props?.tableid || "table-to-xls"} className="exportTable" style={{ width: "100%", border: "1px solid", borderCollapse: "collapse" }} >
      <tbody>
        {props.TableHeader}
        <tr>
          {props.columns.map((d, key) => {
            let headerStyle = d?.headerStyle?.backgroundColor || null
            if (d.field == 'imageUrl') {
              return <th key={key} style={{ fontSize: 10, border: "1px solid #ddd", textAlign: "center", background: headerStyle, color: "white" }}>{"Image"}  </th>
            } else if (d.field == 'videoUrl') {
              return <th key={key} style={{ fontSize: 10, border: "1px solid #ddd", textAlign: "center", background: headerStyle, color: "white" }}>{"Video"}  </ th>
            }else if (d.field == 'videoV360Url') {
              return <th key={key} style={{ fontSize: 10, border: "1px solid #ddd", textAlign: "center", background: headerStyle, color: "white" }}>{"videoV360Url"}  </ th>
            }
             else if (headerStyle) {
              return <th key={key} style={{ fontSize: 10, border: "1px solid #ddd", textAlign: "center", background: headerStyle, color: "white" }}>{d.title}  </th>
            } else
              return <th key={key} style={{ fontSize: 10, border: "1px solid #ddd", textAlign: "center" }}>{d.title}  </th>
          })}
        </tr>

        {props.AllData.map((d, i) => {
          return <tr key={i} style={{ textAlign: "center" }}>
            {props.columns.map((newd, key) => {
              if ((newd.field === "date" || newd.field === "billDate" || newd.field === "purDate") && d[newd.field] !== "Grand Total") {
                if (d[newd.field] === null || d[newd.field] === undefined) {
                  return <td key={key} style={{ fontSize: 10, border: "1px solid #ddd", textAlign: "center" }}></td>
                } else {
                  return <td key={key} style={{ fontSize: 10, border: "1px solid #ddd", textAlign: "center" }}>
                    {moment(d[newd.field]).format('DD-MM-YYYY')}  </td>
                }
              }
              else if (["videoUrl","imageUrl","isCertified"].includes(newd.field)  ) {
                return <td key={key} style={{ fontSize: 10, border: "1px solid #ddd", textAlign: "center" }}>
                  &nbsp;{d[newd.field] ? <a href={(d[newd.field]).toString()}>{(d[newd.field]).toString()}</a> : ""}
                </td>

              }
              else
                return <td key={key} style={{ fontSize: 10, border: "1px solid #ddd", textAlign: "center" }}>
                  &nbsp;{d[newd.field] ? (d[newd.field]).toString() : ""}  </td>
              // }
            })}
          </tr>
        })}
      </tbody>
    </table>
  </div>
}