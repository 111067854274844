import React, { useState, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Divider from '@material-ui/core/Divider';
import { ApolloConsumer } from 'react-apollo';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  root: { backgroundColor: "#8CB836 !important", position: 'fixed' },
  menuButton: { marginRight: theme.spacing(2), },
  title: { flexGrow: 1, },
  paper: { marginRight: theme.spacing(2), },
  logoWidth: { width: 200 },
  white: { margin: "5px", color: "white" },
  loggedUserName: { margin: "5px", color: "white", marginLeft: "auto" },
  logout: { marginRight: theme.spacing(2) },
  MenuSubHeader: {
    fontWeight: 600,
    color: "#808080ab"
  },
  menuList: { zIndex: 1000 }
}));

export default function Header(props) {

  const classes = useStyles();
  const user = JSON.parse(localStorage.getItem('loggedUser'));
  const [open, setOpen] = useState(null);

  const anchorRef = useRef(null);
  const anchorRef1 = useRef(null);
  const anchorRef2 = useRef(null);
  const anchorRef3 = useRef(null);
  const anchorRef4 = useRef(null);
  const anchorRef5 = useRef(null);
  const anchorRef6 = useRef(null);

  const handleToggle = (name) => setOpen(name);

  const handleClose = (event) => {

    if (anchorRef?.current?.contains(event.target) || anchorRef1?.current?.contains(event.target) || anchorRef2?.current?.contains(event.target) ||
      anchorRef3?.current?.contains(event.target) || anchorRef4?.current?.contains(event.target) || anchorRef5?.current?.contains(event.target))
      return;

    setOpen(null);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(null);
    }
  }


  const signOut = client => {
    localStorage.clear()
    sessionStorage.clear();
    client.resetStore();
    sessionStorage.clear()
    toast.success("user logged out successfully!")
  };

  return (
    <AppBar position="static" className={classes.root}>
      <Toolbar>
        <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" className={classes.logoWidth}> Kismet </Typography>

        <div>

          <Button ref={anchorRef} aria-controls={open === 'Master' ? 'menu-list-grow' : undefined} aria-haspopup="true" onClick={() => handleToggle("Master")} className={classes.white} > Master <KeyboardArrowDownIcon /></Button>
          <Popper open={open === "Master"} anchorEl={anchorRef.current} role={undefined} transition disablePortal className={classes.menuList}>
            {({ TransitionProps, placement }) => (
              <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }} >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList autoFocusItem={open ? true : false} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                      <MenuItem disableRipple className={classes.MenuSubHeader}>BASIC</MenuItem>
                      <MenuItem onClick={(e) => { props.history.push('/company'); handleClose(e) }}>Company Master</MenuItem>
                      <MenuItem onClick={(e) => { props.history.push('/partymaster'); handleClose(e) }}>Party Master</MenuItem>
                      {user?.role?.role === 'admin' && <MenuItem onClick={(e) => { props.history.push('/roles'); handleClose(e) }}>Role Master</MenuItem>}
                      {user?.role?.role === 'admin' && <MenuItem onClick={(e) => { props.history.push('/users'); handleClose(e) }}>User Master</MenuItem>}
                      <Divider />
                      <MenuItem disableRipple className={classes.MenuSubHeader}>MANUFACTURING</MenuItem>
                      <MenuItem onClick={(e) => { props.history.push('/process'); handleClose(e) }}>Process Master</MenuItem>
                      <MenuItem onClick={(e) => { props.history.push('/department'); handleClose(e) }}>Department Master</MenuItem>
                      <MenuItem onClick={(e) => { props.history.push('/deptwiseprocess'); handleClose(e) }}>Department Wise Process Master</MenuItem>
                      <Divider />
                      <MenuItem disableRipple className={classes.MenuSubHeader}>SALES</MenuItem>
                      <MenuItem onClick={(e) => { props.history.push('/boxsize'); handleClose(e) }}>Box Size Master</MenuItem>
                      <MenuItem onClick={(e) => { props.history.push('/terms'); handleClose(e) }}>Term Master</MenuItem>
                      <MenuItem onClick={(e) => { props.history.push('/bankdetails'); handleClose(e) }}>Bank Detail</MenuItem>
                      <MenuItem onClick={(e) => { props.history.push('/mailsetting'); handleClose(e) }}>Mail Setting</MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>

          <Button ref={anchorRef1} aria-controls={open === 'Trans' ? 'menu-list-grow' : undefined} aria-haspopup="true" onClick={() => handleToggle("Trans")} className={classes.white} > Transactions <KeyboardArrowDownIcon /></Button>
          <Popper open={open === "Trans"} anchorEl={anchorRef1.current} role={undefined} transition disablePortal className={classes.menuList}>
            {({ TransitionProps, placement }) => (
              <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }} >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList autoFocusItem={open ? true : false} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                      <MenuItem disableRipple className={classes.MenuSubHeader}>MANUFACTURING</MenuItem>
                      <MenuItem onClick={(e) => { props.history.push('/rough'); handleClose(e) }}>Rough Master</MenuItem>
                      <MenuItem onClick={(e) => { props.history.push('/kapan'); handleClose(e) }}>Kapan Details</MenuItem>
                      <MenuItem onClick={(e) => { props.history.push('/packet'); handleClose(e) }}>Packet Master</MenuItem>
                      <MenuItem onClick={(e) => { props.history.push('/departmentisuuereturn'); handleClose(e) }}>Department Issue Return</MenuItem>
                      <MenuItem onClick={(e) => { props.history.push('/roughplanning'); handleClose(e) }}>Rough Planning</MenuItem>
                      <MenuItem onClick={(e) => { props.history.push('/grad'); handleClose(e) }}>Grading Master</MenuItem>
                      <MenuItem onClick={(e) => { props.history.push('/branchtransfer'); handleClose(e) }}>Branch Transfer</MenuItem>
                      <MenuItem onClick={(e) => { props.history.push('/costing'); handleClose(e) }}>Costing Master</MenuItem>
                      <MenuItem disableRipple className={classes.MenuSubHeader}>SALES</MenuItem>
                      <MenuItem onClick={(e) => { props.history.push('/inward'); handleClose(e) }}>Inward Master</MenuItem>
                      <MenuItem onClick={(e) => { props.history.push('/issuereturn'); handleClose(e) }}>Issue/Return Master</MenuItem>
                      <MenuItem onClick={(e) => { props.history.push('/recertifiedmaster'); handleClose(e) }}>Recertified Master</MenuItem>
                      <MenuItem onClick={(e) => { props.history.push('/sales'); handleClose(e) }}>Sales Master</MenuItem>
                      <MenuItem onClick={(e) => { props.history.push('/sizewisestock'); handleClose(e) }}>Size Wise Stock Master</MenuItem>
                      <MenuItem onClick={(e) => { props.history.push('/packetdeasso'); handleClose(e) }}>Packet Deassociation Master</MenuItem>
                      <MenuItem onClick={(e) => { props.history.push('/jobpkt'); handleClose(e) }}>Job Work Pkt Lab Result</MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>

          {/* <Button ref={anchorRef2} aria-controls={open === 'Acc' ? 'menu-list-grow' : undefined} aria-haspopup="true"
            onClick={(e) => { props.history.push('/payment') }} className={classes.white} > Payment Master
          </Button> */}

          <Button ref={anchorRef2} aria-controls={open === 'Acc' ? 'menu-list-grow' : undefined} aria-haspopup="true" onClick={() => handleToggle("Acc")} className={classes.white} > Accounts <KeyboardArrowDownIcon /></Button>
          <Popper open={open === "Acc"} anchorEl={anchorRef2.current} role={undefined} transition disablePortal className={classes.menuList}>
            {({ TransitionProps, placement }) => (
              <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }} >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList autoFocusItem={open ? true : false} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                      <MenuItem onClick={(e) => { props.history.push('/payment'); handleClose(e) }}>Payment Master</MenuItem>
                      {/* <MenuItem onClick={(e) => { props.history.push('/accounts'); handleClose(e) }}>Accounts </MenuItem> */}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>

          <Button ref={anchorRef3} aria-controls={open === 'Report' ? 'menu-list-grow' : undefined} aria-haspopup="true" onClick={() => handleToggle("Report")} className={classes.white} > Reports <KeyboardArrowDownIcon /></Button>
          <Popper open={open === "Report"} anchorEl={anchorRef3.current} role={undefined} transition disablePortal className={classes.menuList}>
            {({ TransitionProps, placement }) => (
              <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }} >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList autoFocusItem={open ? true : false} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                      {/* <MenuItem onClick={handleClose}>Sales Report</MenuItem> */}
                      <MenuItem disableRipple className={classes.MenuSubHeader}>MANUFACTURING</MenuItem>
                      <MenuItem onClick={(e) => { props.history.push('/mfgkapanstock'); handleClose(e) }}>MFG Kapan Stock Report</MenuItem>
                      <MenuItem onClick={(e) => { props.history.push('/kapanwisecosting'); handleClose(e) }}>Kapan Wise Costing Report</MenuItem>
                      <MenuItem onClick={(e) => { props.history.push('/purchasesales'); handleClose(e) }}>Purchase V/S Sales</MenuItem>
                      <MenuItem onClick={(e) => { props.history.push('/planningcomparision'); handleClose(e) }}>Planning Comparision Report</MenuItem>
                      <MenuItem onClick={(e) => { props.history.push('/jobwork'); handleClose(e) }}>Job Work Report</MenuItem>
                      <MenuItem onClick={(e) => { props.history.push('/branchtransferreport'); handleClose(e) }}>Branch Transfer Report</MenuItem>
                      <MenuItem disableRipple className={classes.MenuSubHeader}>SALES</MenuItem>
                      <MenuItem onClick={(e) => { props.history.push('/salesreport'); handleClose(e) }}>Sales Report</MenuItem>
                      <MenuItem onClick={(e) => { props.history.push('/labpending'); handleClose(e) }}>Lab Pending Report</MenuItem>
                      <MenuItem onClick={(e) => { props.history.push('/appropending'); handleClose(e) }}>Appro Pending Report</MenuItem>
                      <MenuItem onClick={(e) => { props.history.push('/salesoutstanding'); handleClose(e) }}>Sales Outstanding Report</MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>

          <Button ref={anchorRef4} aria-controls={open === 'Utility' ? 'menu-list-grow' : undefined} aria-haspopup="true" onClick={() => handleToggle("Utility")} className={classes.white} > Utility <KeyboardArrowDownIcon /></Button>
          <Popper open={open === "Utility"} anchorEl={anchorRef4.current} role={undefined} transition disablePortal className={classes.menuList}>
            {({ TransitionProps, placement }) => (
              <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }} >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList autoFocusItem={open ? true : false} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                      <MenuItem onClick={(e) => { props.history.push('/rapaport'); handleClose(e) }}>Rapaport User Master</MenuItem>
                      <MenuItem onClick={(e) => { props.history.push('/shortcode'); handleClose(e) }}>Short Code Master</MenuItem>
                      {user?.role?.role === 'admin' && <MenuItem onClick={(e) => { props.history.push('/auditlog'); handleClose(e) }}>Audit Log</MenuItem>}
                      {user?.role?.role === 'admin' && user?.userName === "superadmin" && <MenuItem onClick={(e) => { props.history.push('/backup_and_restore'); handleClose(e) }}>Backups</MenuItem>}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>

          <Button ref={anchorRef5} aria-controls={open === 'Power' ? 'menu-list-grow' : undefined} aria-haspopup="true" onClick={() => handleToggle("Power")} className={classes.white} > Power Menu <KeyboardArrowDownIcon /></Button>
          <Popper open={open === "Power"} anchorEl={anchorRef5.current} role={undefined} transition disablePortal className={classes.menuList}>
            {({ TransitionProps, placement }) => (
              <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }} >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList autoFocusItem={open ? true : false} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                      <MenuItem onClick={(e) => { props.history.push('/livescreen'); handleClose(e) }}>Live Screen</MenuItem>
                      <MenuItem onClick={(e) => { props.history.push('/polishlivescreen'); handleClose(e) }}>Polish Live Screen</MenuItem>
                      <MenuItem onClick={(e) => { props.history.push('/packethistory'); handleClose(e) }}>Packet History</MenuItem>
                      <MenuItem onClick={(e) => { props.history.push('/stock'); handleClose(e) }}>Stock Master</MenuItem>
                      <MenuItem onClick={(e) => { props.history.push('/paymentdashboard'); handleClose(e) }}>Payment Dashboard</MenuItem>
                      <MenuItem onClick={(e) => { props.history.push('/updatelocation'); handleClose(e) }}>Update Location</MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>

        </div>


        <Button ref={anchorRef6} aria-controls={open === 'Power' ? 'menu-list-grow' : undefined} aria-haspopup="true" onClick={() => handleToggle("Profile")} className={classes.white, classes.loggedUserName} > {user && user.userName} <KeyboardArrowDownIcon /></Button>
        {user?.role?.role === 'admin' &&
        <Popper open={open === "Profile"} anchorEl={anchorRef6.current} role={undefined} transition disablePortal className={classes.menuList}>
          {({ TransitionProps, placement }) => (
            <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }} >

              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open ? true : false} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    <MenuItem onClick={(e) => { props.history.push('/profile'); handleClose(e) }}>Change Password</MenuItem>
                    <MenuItem onClick={(e) => { props.history.push('/twostep'); handleClose(e) }}>Two-step verification</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
    }
        <ApolloConsumer>
          {client => (
            <IconButton edge="start" className={classes.logout} onClick={() => signOut(client)} color="inherit" aria-label="menu">
              <ExitToAppIcon />
            </IconButton>
          )}
        </ApolloConsumer>


      </Toolbar>
    </AppBar>
  )

}
