/********************* Libray **************** */
import React, { useRef, useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { GETBRANCHTRANSFERDETAILS } from './Query'
import { Grid, Table, Button, CircularProgress } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { InputLabel } from '@material-ui/core';
import _, { filter } from 'lodash'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { toast } from 'react-toastify'
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import { FormControl, TextField } from '@material-ui/core';
import { ALLDEPARTMENT } from '../../Master/Department/DepartmentQuery'
import { Fromtable } from './FromTable'
import { PacketDetails } from './PacketDetails'
import { MasterView } from './MasterView';
import { PacketType } from '../../../components/DropdownSelect/PacketType';
import { packetTypeForBranchTransfer } from '../../../components/Constant/index'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ADDNEWBRANCHTRANS, DELETEBRANCHTRANSFERDETAIL } from './mutations'
import { FormatError } from '../../../components/Comman/FormatError'
import { GETROUGHSTOCKFROMPKT, EXTRATOPSDATA } from './Query'
import { Accordion, AccordionSummary, AccordionDetails } from '../../../components/Comman/Accordian'

const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1, },
  noMargin: { margin: 0 },

  GridLeftSpace: {
    paddingLeft: 5
  },
  GridFillter: {
    textAlign: "end"
  },
  SetAccordianHeade: {
    paddingTop: 0,
    marginTop: "0px !important"
  },
  fitGrid: {
    maxHeight: 170,
    overflowY: "scroll"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  Select: {
    marginTop: "0px !important",
    paddingTop: 0,
    // paddingRight: 10
  },
  setRemark: {
    marginTop: 8
  },
  denseTable: {
    '& td': { padding: "2px 5px", textAlign: "center" },
    '& th': { padding: "5px 5px", textAlign: "center" }
  },
  tableHeading:{
    background: '#8CB836 !important',
    color: '#f5f5f5'
   },
}));

function BranchTransfer(props) {
  const tableRef = useRef();
  const classes = useStyles();
  const [selectedFromDate, setSelectedFromDate] = useState(new Date());
  const [Loader, SetLoader] = useState(false)
  const [remark, setRemark] = useState('');
  const [SelectedData, SetSelectedData] = useState([])
  const [Department, SetDepartment] = useState([])
  const [PacketTypes, SetPacketType] = useState('')
  const [expanded, setExpanded] = useState(false);
  const [StockData, SetStockData] = useState([])
  const [SelectedMasterData, SetSelectedMasterData] = useState([])
  const [PacketDetailsData, setPacketDetailsData] = useState([])
  const [PacketDetailsSelectedData, setPacketDetailsSelectedData] = useState([])
  const [AddNewBranchTrans] = useMutation(ADDNEWBRANCHTRANS)
  const [DeleteBranchTransferDetails] = useMutation(DELETEBRANCHTRANSFERDETAIL)


  /*******************************From Table Data *************************/
  const { loading, error, data, refetch } = useQuery(PacketTypes === "EXTRA TOPS" ? EXTRATOPSDATA : GETROUGHSTOCKFROMPKT, {
    variables: { pktType: PacketTypes ? PacketTypes : '0' },
    fetchPolicy: 'cache-and-network'
  })
  let result = PacketTypes === "EXTRA TOPS" ? data?.getExtraTops || [] : data?.getRoughStockFromPktType || []
  useEffect(() => {
    SetStockData(result)
    tableRef.current && tableRef.current.onQueryChange()
  }, [data]);

  useEffect(() => {
    SetStockData([])
  }, [error]);

  const SaveButtonHandler = (e) => {
    e.stopPropagation();
    return new Promise((resolve, reject) => {
      let SelectedTempData = _.cloneDeep(SelectedData)
      if (!Department?.id || null) {
        toast.warn('please select department')
      } else if (!PacketTypes) {
        toast.warn('please select packet Type')
      } else {
        let newData = {
          "transDate": selectedFromDate,
          "pktType": PacketTypes,
          "deptName": Department?.id || null,
          "remark": remark,
          "data": _.map(SelectedTempData, (d) => {
            d.lotNoId = d?.lotNoId?.id || null
            d.colorId = d?.colorId?.id || d?.roughStockId?.colorId?.id || null
            d.pktNoId = d?.pktNoId?.id || null
            d.purityId = d?.purityId?.id || d?.roughStockId?.purityId?.id || null
            d.cutId = d?.cutId?.id || d?.roughStockId?.cutId?.id || null
            d.fluId = d?.fluId?.id || d?.roughStockId?.fluId?.id || null
            d.polishId = d?.polishId?.id || d?.roughStockId?.polishId?.id || null
            d.shapeId = d?.shapeId?.id || d?.roughStockId?.shapeId?.id || null
            d.symmId = d?.symmId?.id || d?.roughStockId?.symmId?.id || null
            if (PacketTypes === "EXTRA TOPS") {
              d.id = d?.id || null
            }
            delete d.tableData
            return d
          })
        }
        SetLoader(true)
        AddNewBranchTrans({ variables: { input: newData } }).then((data) => {
          refetch()
          toast.success('save sucessfully');
          SetLoader(false)
          SetSelectedData([])
          resolve()
        }).catch((e) => {
          SetLoader(false)
          reject()
          toast.error(FormatError(e))
        })
      }
    })
  }
  /*************************************End *****************************************/

  /**************************PacketDetails Api calls Start *****************************/
  const {
    loading: packetDetailLoading,
    error: packetDetailError,
    data: datapacketDetailData,
    refetch: packetDetailRefetch
  } = useQuery(GETBRANCHTRANSFERDETAILS, {
    variables: { id: SelectedMasterData?.id || '' },
    fetchPolicy: 'cache-and-network',
  })
  useEffect(() => {
    setPacketDetailsData(datapacketDetailData?.getBranchTransferDetail || [])
  }, [datapacketDetailData]);

  useEffect(() => {
    setPacketDetailsData([])
  }, [packetDetailError]);
  const RemoveBranchTranferedData = (e) => {
    return new Promise((resolve, reject) => {
      e.stopPropagation();
      let newData = [...PacketDetailsSelectedData]
      SetLoader(true)
      DeleteBranchTransferDetails({ variables: { ids: newData } }).then((data) => {
        SetLoader(false)
        toast.success('Remove sucessfully');
        packetDetailRefetch()
        refetch()
        setPacketDetailsSelectedData([])
        resolve()
      }).catch((e) => {
        SetLoader(false)
        reject()
        toast.error(FormatError(e))
      })
    })
  }
  /******************************PacketDetails Api calls End ****************************/





  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "rgb(0, 0, 0)",
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  const handleDateChange = date => {
    setSelectedFromDate(date)
  };

  const { error: AllDepartmentError, data: AllDepartmentData } = useQuery(ALLDEPARTMENT, {
    fetchPolicy: 'cache-and-network',
  })
  if (AllDepartmentError)
    console.log(FormatError(AllDepartmentError));
  let Data = AllDepartmentData?.getAllDepartment || []
  const GetDepartment = (departmentName) => {
    let department = ""
    if (departmentName === "FINAL POLISH") {
      department = "SALES"
    } else {
      department = "STOCK"
    }
    let FromDataArray = filter(Data, (d) => d.deptName === department)
    SetDepartment(FromDataArray[0])
  }
  const setPacketTypeFun = (e) => {
    SetPacketType(e.target.value)
    GetDepartment(e.target.value)
  }
  const setDataFromMasterView = (Data) => {
    SetSelectedMasterData(Data)
    SetPacketType(Data.pktType)
    GetDepartment(Data.pktType)
  }
  const defaultProps = {
    options: Data,
    getOptionLabel: (option) => option.deptName
  };
  let lotwisetock = _.groupBy(StockData, 'lotNoId.lotNo')


  const RefreshButtonHandler = (e) => {
    e.stopPropagation();
    SetSelectedMasterData([])
    SetPacketType('')
    GetDepartment('')
  }
  return (
    <div className={classes.root}>
      <Accordion expanded={expanded} className="AccordianHeaderBranchMaster" onChange={() => setExpanded(!expanded)} >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid container>
            <Grid xs={4} item>
              <Button variant="contained" color="primary" style={{ marginRight: 10 }} onClick={(e) => RefreshButtonHandler(e)}
              >refresh</Button>
              {SelectedData.length !== 0 &&
                <Button type="submit" className={classes.form} variant="contained" disabled={Loader} onClick={e => SaveButtonHandler(e)} color="primary" >
                  {Loader && <CircularProgress size={24} className={classes.buttonProgress} />} Save
                </Button>
              }
              {
                PacketDetailsSelectedData.length !== 0 &&
                <Button type="submit" className={classes.form} variant="contained" disabled={Loader} onClick={e => RemoveBranchTranferedData(e)} color="primary" >
                  {Loader && <CircularProgress size={24} className={classes.buttonProgress} />} Remove
                </Button>
              }
            </Grid>
            <Grid xs={4} item container justify="center">
              <Typography className={classes.heading}> Branch Transfer Master</Typography>
            </Grid>
            <Grid xs={4} item container justify="flex-end">
              <Typography className={classes.heading}>Master  View</Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Typography component={'span'} variant={'body2'}>
            <MasterView
              MasterSelectedData={(data) => setDataFromMasterView(data)}
              closeAccordian={(val) => setExpanded(val)}
            />
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Grid container alignContent="space-between">
        <Grid xs={4} item>
          <Grid container>
            <Grid xs={3} item>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-from"
                  label="Trans Date"
                  format="dd/MM/yyyy"
                  value={selectedFromDate}
                  onChange={handleDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date"
                  }}
                  style={{ marginTop: 9 }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid xs={6} item>
              <PacketType PacketTypes={PacketTypes} Data={packetTypeForBranchTransfer} setPacketTypeFun={setPacketTypeFun} packetTypedisable={false} />
            </Grid>
          </Grid>
          <Grid container>
            <FormControl className={classes.formControl}>
              <Autocomplete
                disabled={true}
                {...defaultProps}
                value={Department}
                renderInput={(params) => <TextField    {...params} margin="normal" />}
              />
            </FormControl>
            <TextField id="standard-basic" className={classes.setRemark} label="Remark" name="remark" onChange={e => setRemark(e.target.value)} />&nbsp;&nbsp;
            </Grid>
        </Grid>

        <Grid xs={4} style={{ paddingTop: 10 }} item container justify="center" className={classes.SetAccordianHeade}>
          <Grid xs={6} item>
            <InputLabel style={{ fontSize: 18, paddingBottom: 10, color: "rgb(0, 0, 0)" }} id="demo-simple-select-label">Lot Wise Stock</InputLabel>
            <TableContainer component={Paper} style={{ height: 150 }}>
              <Table className={classes.denseTable} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell className={classes.tableHeading}>Lot No</StyledTableCell>
                    <StyledTableCell className={classes.tableHeading}>Tot. Pkt</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(lotwisetock).map((row, key) => {
                    return <StyledTableRow key={key}>
                      <StyledTableCell >{row}</StyledTableCell>
                      <StyledTableCell >{_.filter(StockData, (d) => {
                        if (d?.lotNoId?.lotNo)
                          return d?.lotNoId?.lotNo === row
                      }).length}</StyledTableCell>
                    </StyledTableRow>
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        {SelectedMasterData.btNo && <Grid xs={4} style={{ paddingTop: 10 }} item container justify="flex-end" className={classes.SetAccordianHeade}>
          Jangad No {SelectedMasterData.btNo}
        </Grid>
        }

      </Grid>
      <Grid container alignContent="space-between">
        <Grid item xs={6} className="Fromtablebetweenspace">
          <Fromtable
            loading={loading}
            StockData={StockData}
            pktType={PacketTypes}
            SetStockData={(data) => SetStockData(data)}
            SetSelectedData={(data) => SetSelectedData(data)} />
        </Grid>
        <Grid item xs={6} className="Totablebetweenspace">
          <PacketDetails
            PacketDetailsData={PacketDetailsData}
            loading={packetDetailLoading}
            setPacketDetailsSelectedData={(data) => setPacketDetailsSelectedData(data)}
          />
        </Grid>
      </Grid>
    </div >
  );
}

export default withRouter(BranchTransfer)