import React, { useRef } from 'react'
import { Grid } from '@material-ui/core';
import MaterialTable from 'material-table';
import { useQuery, useMutation } from '@apollo/react-hooks'
import { COSTINGDETAIL } from './CostingQuery'
import { TextField, Checkbox } from '@material-ui/core'
import { ADD_COSTINGDETAIL, DELETE_COSTINGDETAIL, UPDATE_COSTINGDETAIL } from './CostingMutation'
import { toast } from 'react-toastify'
import _ from 'lodash'
import { LotNoSelectComponent } from '../../../components/DropdownSelect/SelectComponents/LotSelectComponent'
import { PacketSelectComponent } from '../../../components/DropdownSelect/SelectComponents/PktNoSelectComponent'
import { GetRoughStockForCosting } from '../../../components/DropdownSelect/GetRoughStockForCostingDetail'
import { FormatError } from '../../../components/Comman/FormatError'
import { Tofixed } from '../../../components/Comman/Tofix'
const CostCalculation = (RowData) => {
  let Cts = RowData?.cts || 0
  let Rate = RowData?.rate || 0
  return (Cts * Rate).toFixed(2)
}
const CostZarCalculation = (RowData) => {
  let RateZar = RowData?.rateZar || 0
  return (CostCalculation(RowData) * RateZar).toFixed(2)
}

const columns = [
  {
    title: 'Lot No*', field: 'lotNoId',
    render: (rowData) => rowData?.lotNoId?.lotNo || "",
    editable: "never"
  },
  {
    title: 'Pkt No*', field: 'pktNoId',
    render: (rowData) => rowData?.pktNoId?.pktNo || "",
  },
  {
    title: 'Cts*', field: 'cts', type: "numeric",
    validate: rowData => (rowData.cts && rowData.cts !== "") ? true : { isValid: false, helperText: `can't be empty` }
  },
  {
    title: 'Rate($)', field: 'rate', type: "numeric",
    editComponent: (props) => {
      if (props?.rowData?.isEditable || false) {
        return 0
      } else {
        return <TextField type="number" value={props?.rowData?.rate || 0} id="standard-basic" onChange={(e) => {
          let Data = { ...props.rowData }
          Data.rate = e.target.value
          props.onRowDataChange(Data)
        }} />
      }
    }
  },
  {
    title: 'Cost($)', field: 'cost', type: "numeric", editComponent: ({ rowData }) => {
      return CostCalculation(rowData)
    }
  },
  {
    title: 'Zar Rate', field: 'rateZar', type: "numeric",
    editComponent: (props) => {
      if (props?.rowData?.isEditable || false) {
        return 0
      } else {
        return <TextField type="number" id="standard-basic" value={props?.rowData?.rateZar || 0} onChange={(e) => {
          let Data = { ...props.rowData }
          Data.rateZar = e.target.value
          props.onRowDataChange(Data)
        }} />
      }
    }
  },
  {
    title: '', field: 'isEditable', type: "boolean",
    editComponent: (props) => {
      return <Checkbox
        checked={props?.rowData?.isEditable || false}
        onChange={(e) => {
          let Data = { ...props.rowData }
          Data.rate = 0
          Data.rateZar = 0
          Data.isEditable = !props?.rowData?.isEditable || false
          props.onRowDataChange(Data)
        }}
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
    }
  },
  {
    title: 'Zar Cost', field: 'costZar', type: "numeric",
    editComponent: (props) => {
      if (props?.rowData?.isEditable || false) {
        return <TextField type="number" value={props?.rowData?.costZar || 0} id="standard-basic" onChange={(e) => {
          let Data = { ...props.rowData }
          Data.costZar = e.target.value
          props.onRowDataChange(Data)
        }} />
      } else {
        return CostZarCalculation(props.rowData)
      }
    }
  },
]

export const CostingDetails = (props) => {
  const tableRef = useRef();
  const permissions = props?.session?.me?.role?.permissions;
  const role = props?.session?.me?.role?.role;
  const path = props?.location?.pathname;
  let permission = _.find(permissions, function (o) { return o.name === path; })
  let costingType = props.costingType

  let LotNoIdDefault = props?.Data?.lotNoId || null
  const { error, data , loading} = useQuery(COSTINGDETAIL, {
    variables: { id: props.Data.id },
    fetchPolicy: 'cache-and-network'
  })
  const HideRow = (rows) => {
    let aa = document.getElementsByClassName('childTable')
    for (let index = 0; index < aa.length; index++) {
      setTimeout(() => {
        if (rows && rows !== 0 && aa[index]) {
          let newD = aa[index].getElementsByClassName('MuiTableBody-root')[0].rows[aa[index].getElementsByClassName('MuiTableBody-root')[0].rows.length - 1]
          if (newD.getElementsByClassName('MuiTableCell-sizeSmall')[0]) {
            newD.getElementsByClassName('MuiTableCell-sizeSmall')[0].innerText = 'Total'
            newD.style.background = 'rgb(212, 218, 255)'
          }
        }
      }, 300);
    }
  }
  // let PartyIdDefault = props?.Data?.partyId?.id || null
  // let PartyNameDefault = props?.Data?.partyId?.partyName || null
  columns.map((d, key) => {
    if (d.field === "pktNoId") {
      d.editComponent = props => {
        let valueDefault = LotNoIdDefault?.id || null
        let valueDefaultPacketNo = props?.rowData?.pktNoId?.id || props?.rowData?.pktNoId || null
        return <PacketSelectComponent field={"pktNoId"} propsData={props} ids={valueDefault} valueDefaultPacketNo={valueDefaultPacketNo} />
      }
    }

    if (d.field === "cts") {
      d.editComponent = (props) => {
        let lotNoId = LotNoIdDefault?.id || null
        let pktNoId = props?.rowData?.pktNoId?.id || props?.rowData?.pktNoId || null
        if (lotNoId && pktNoId) {
          let costingData = GetRoughStockForCosting(lotNoId, pktNoId)
          let Data = { ...props.rowData }
          let Ids = null, Ids2 = ''
          Ids = costingData ? costingData[0]?.id : null
          Ids2 = Data?.setids || ''
          if (Ids && Ids2 !== Ids) {
            Data.cts = costingType === "POLISH" || costingType === "POLISH EXPENSE" ? costingData[0].orgCts || null : costingData[0]?.issueCts || null
            Data.setids = costingData[0]?.id
            props.onRowDataChange(Data)
          }
          if (props?.rowData?.cts || props?.rowData?.cts === 0) {
            return <TextField defaultValue={props?.rowData?.cts || 0} onChange={(e) => {
              let Data = { ...props.rowData }
              if (e.target.value === "" || e.target.value === undefined || e.target.value === null) {
                Data.cts = 0
              } else {
                Data.cts = e.target.value
              }
              props.onRowDataChange(Data)
            }} />
          }
          else {
            return 0
          }
        } else {
          return ""
        }
      }
    }
  })

  const getCostingDetail = () => {
    return new Promise((resolve, reject) => {
      if (error)
        reject(error)
      let result = [...data?.getAllCostingDetail || []]
      if (result.length !== 0) {
        let LastRow = { 'cts': '', 'cost': '', "costZar": "" }
        LastRow.cts = Tofixed(_.sum(_.map(result, 'cts')))
        LastRow.cost = Tofixed(_.sum(_.map(result, 'cost')))
        LastRow.costZar = Tofixed(_.sum(_.map(result, 'costZar')))
        result.push(LastRow)
      }
      HideRow(result.length)
      resolve({
        data: result,
      })
    })
  }
  const [AddCostingDetailMutation] = useMutation(ADD_COSTINGDETAIL)
  const [UpdateCostingDetailMutation] = useMutation(UPDATE_COSTINGDETAIL)
  const [DeleteCostingDetailMutation] = useMutation(DELETE_COSTINGDETAIL)

  const updateMasterDataFromChild = (Type, newData) => {
    let PacketMasterData = props.CostingMasterData
    let result = [...data?.getAllCostingDetail || []] || []
    let TotalCts, TotalPackets
    if (Type === "ADD") {
      TotalPackets = result.length + 1
      TotalCts = _.sum(_.map(result, 'cts')) + newData.cts
    } else if (Type === "UPDATE") {
      TotalPackets = result.length
      TotalCts = _.sum(_.map(_.filter(result, (d) => d.id !== newData.id), 'cts')) + newData.cts
    } else if (Type === "DELETE") {
      if (result.length !== 0) {
        TotalPackets = result.length - 1
        TotalCts = _.sum(_.map(_.filter(result, (d) => d.id !== newData.id), 'cts')) - newData.cts
      } else {
        TotalPackets = 0
        TotalCts = 0
      }
    }
    let a = PacketMasterData.tableData
    delete PacketMasterData.tableData

    props.UpdateCosting(PacketMasterData, props.PacketMasterData)
    PacketMasterData.tableData = a
    return true
  }

  const AddCostingDetails = (newData) => {
    return new Promise((resolve, reject) => {
      let result = [...data?.getAllCostingDetail || []]
      newData.cts = parseFloat(Tofixed(newData.cts))
      newData.rate = parseFloat(Tofixed(newData.rate ? newData.rate : 0))
      newData.cost = parseFloat(Tofixed(CostCalculation(newData)))
      newData.rateZar = parseFloat(Tofixed(newData.rateZar ? newData.rateZar : 0))
      newData.costZar = parseFloat(Tofixed((newData?.isEditable || false ? newData.costZar : CostZarCalculation(newData))))
      newData.costingMasterId = props.Data.id
      newData.totalCts = parseFloat(Tofixed(_.sum(_.map(result, 'cts')) + newData.cts))
      newData.totalCost = parseFloat(Tofixed(_.sum(_.map(result, 'cost')) + newData.cost))
      newData.totalCostZar = parseFloat(Tofixed(_.sum(_.map(result, 'costZar')) + newData.costZar))
      newData.costType = props?.Data?.costType
      newData.lotNoId = props?.Data?.lotNoId?.id || null
      delete newData.setids
      if (newData.lotNoId && newData.pktNoId) {
        let log = {
          action: 'INSERT',
          actionOn: 'CostingDetail',
          actionName: 'addNewCostingDetail',
          oldValue: "",
          message: `Costing detail was created.`
        }
        if (updateMasterDataFromChild("ADD", newData)) {
          AddCostingDetailMutation({ variables: { input: newData, log } }).then((data) => {
            toast.success('Costing detail created successfully');
            resolve()
          }).catch((e) => {
            toast.error(FormatError(e))
            reject()
          })
        }
      } else {
        toast.warn('please, fill all required(*) fields')
        reject()
      }
    })
  }
  const UpdateCostingDetails = (newData, oldData) => {
    return new Promise((resolve, reject) => {
      let result = [...data?.getAllCostingDetail || []]
      delete newData.createdBy
      delete newData.createdAt
      delete newData.updatedBy
      delete newData.updatedAt
      delete newData.__typename
      delete newData.setids
      newData.cts = parseFloat(Tofixed(newData.cts))
      newData.rate = parseFloat(Tofixed(newData.rate ? newData.rate : 0))
      newData.cost = parseFloat(Tofixed(CostCalculation(newData)))
      newData.rateZar = parseFloat(Tofixed(newData.rateZar ? newData.rateZar : 0))
      newData.costZar = parseFloat(Tofixed((newData?.isEditable || false ? newData.costZar : CostZarCalculation(newData))))
      newData.lotNoId = props?.Data?.lotNoId?.id || newData.lotNoId.id || null
      newData.pktNoId = newData.pktNoId.id ? newData.pktNoId.id : newData.pktNoId
      newData.totalCts = parseFloat(Tofixed(_.sum(_.map(_.filter(result, (d) => d.id !== newData.id), 'cts')) + newData.cts))
      newData.totalCost = parseFloat(Tofixed(_.sum(_.map(_.filter(result, (d) => d.id !== newData.id), 'cost')) + newData.cost))
      newData.totalCostZar = parseFloat(Tofixed(_.sum(_.map(_.filter(result, (d) => d.id !== newData.id), 'costZar')) + newData.costZar))
      if (newData.lotNoId && newData.pktNoId) {
        let log = {
          action: 'UPDATE',
          actionOn: 'CostingDetail',
          actionName: 'updateCostingDetail',
          oldValue: JSON.stringify(oldData),
          message: `Costing detail of packet name ${oldData?.pktNoId?.pktNo || ''} was updated.`
        }
        if (updateMasterDataFromChild("UPDATE", newData)) {
          UpdateCostingDetailMutation({ variables: { input: newData, log } }).then((data) => {
            toast.success('Costing detail updated successfully !');
            resolve()
          }).catch((e) => {
            reject()
            toast.error(FormatError(e))
          })
        }
      } else {
        reject()
        toast.warn('please, fill all required(*) fields')
      }
    })
  }
  const DeleteCostingDetails = (oldData) => {
    return new Promise((resolve, reject) => {
      let log = {
        action: 'DELETE',
        actionOn: 'CostingDetail',
        actionName: 'deleteCostingDetail',
        oldValue: JSON.stringify(oldData),
        message: `Costing detail of packet name ${oldData?.pktNoId?.pktNo || ''} was deleted.`
      }
      if (updateMasterDataFromChild("DELETE", oldData)) {
        DeleteCostingDetailMutation({ variables: { id: oldData.id, log } }).then((data) => {
          toast.success('Costing detail Deleted successfully!');
          resolve()
        }).catch((e) => {
          reject()
          toast.error(FormatError(e))
        })
      }
    })
  }

  const addButton = (val) => {
    if (val || (role === 'admin'))
      return { onRowAdd: (newData) => AddCostingDetails(newData) }
    else
      return {}
  }

  return <Grid
    container
    direction="row"
    justify="flex-end" item xs={12} >
    <div className="childTable">
      {!loading &&       
      <MaterialTable
        tableRef={tableRef}
        columns={columns}
        data={() => getCostingDetail()}
        options={{
          emptyRowsWhenPaging: false,
          padding: 'dense',
          pageSize: 10,
          pageSizeOptions: [5, 10, 15, 20, 25, 50],
          addRowPosition: 'first',
          filtering: false,
          sorting: false,
          search: false,
          paging: false,
          filterCellStyle: { padding: '5px', },
          maxBodyHeight: window.innerHeight - 215,
          showTitle: false,
          headerStyle: { backgroundColor: 'whitesmoke' }
        }}
        editable={{
          // onRowAdd: (newData) => AddCostingDetails(newData),
          isEditHidden: (rowData) => (role === 'admin') ? false : !permission.isUpdate,
          isDeleteHidden: (rowData) => (role === 'admin') ? false : !permission.isDelete,
          ...addButton(permission?.isInsert),
          onRowUpdate: (newData, oldData) => UpdateCostingDetails(newData, oldData),
          onRowDelete: (oldData) => DeleteCostingDetails(oldData)
        }}

      />
      }
    </div>
  </Grid>
}