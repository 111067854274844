import React, { useRef, useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { withRouter } from "react-router-dom";
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GETAUDITLOG } from './AuditLog'
import { useQuery } from '@apollo/react-hooks'
import moment from 'moment';
import { FormatError } from '../../../components/Comman/FormatError'
const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1, },
  noMargin: { margin: 0 }
}));

const columns = [
  {
    title: 'Date', field: 'createdAt', type: "date", render: (rowData) => {
      return moment(rowData.createdAt).format('DD-MM-YYYY-HH-mm-ss')
    }
  },
  { title: 'Action', field: 'action' },
  { title: 'Table', field: 'actionOn' },
  { title: 'Message', field: 'message' },
]
function AuditLog(props) {
  const classes = useStyles();
  const tableRef = useRef();
  const [loader, setLoader] = useState(false);
  const [pageSize, setPageSize] = useState(10)
  const [selectedRow, setSelectedRow] = useState(null);


  const { fetchMore, loading } = useQuery(GETAUDITLOG, {
    variables: { page: 1, limit: 10, filter: JSON.stringify({}), sort: { key: 'createdAt', type: -1 } },
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    setLoader(loading)
  }, [loading]);

  const getAuditLogData = (query) => {
    setLoader(true)
    return new Promise(async (resolve, reject) => {
      let filter = {}, sort = { key: 'createdAt', type: -1 }
      query && query.filters.forEach(item => {
        if (item.column.field === "createdAt") {
          if (item.value)
            filter = { ...filter, [item.column.field]: moment(item.value).format("YYYY-MM-DD") };
          else {
            delete filter[`${item.column.field}`];
            filter = { ...filter };
          }
        } else if (item.value.length === 0) {
          delete filter[`${item.column.field}`]
          filter = { ...filter }
        } else
          filter = { ...filter, [item.column.field]: item.value }
      });
      if (query && query.orderBy && query.orderDirection !== "") {
        sort = { key: query.orderBy.field, type: query.orderDirection === 'asc' ? 1 : -1 }
      }
      setPageSize(query.pageSize)
      try {
        let result = await fetchMore({
          variables: { page: query.page + 1, limit: query.pageSize, filter: JSON.stringify(filter), sort: sort },
          fetchPolicy: 'network-only',
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return fetchMoreResult;
          }
        })
        setLoader(false)
        resolve({
          data: result?.data?.getAuditLog?.data || [],
          page: query.page,
          totalCount: result?.data?.getAuditLog?.count || 0,
        })
      } catch (error) {
        console.log(FormatError(error))
        setLoader(false)
      }
    })
  };

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <MaterialTable
            title="Audit Log"
            tableRef={tableRef}
            columns={columns}
            isLoading={loader}
            data={query => getAuditLogData(query)}
            options={{
              emptyRowsWhenPaging: false,
              padding: 'dense',
              debounceInterval: 300,
              pageSize: pageSize,
              pageSizeOptions: [5, 10, 15, 20, 25, 50],
              addRowPosition: 'first',
              filtering: true,
              sorting: true,
              search: false,
              filterCellStyle: { padding: '5px', },
              maxBodyHeight: window.innerHeight - 215,
              rowStyle: rowData => ({
                backgroundColor: (selectedRow === rowData.tableData.id) ? 'rgb(212, 218, 255)' : '#f5f5f5'
              }),
              headerStyle: { backgroundColor: 'whitesmoke' }
            }}
            onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
          />
        </Grid>
      </Grid>
    </div >
  );
}


export default withRouter(AuditLog)
