import gql from 'graphql-tag';

export const ADD_DEPTWISEPROCESS = gql`
mutation addNewDeptWiseProcess($input: deptWiseProcessInput!,$log: LogInput){
    addNewDeptWiseProcess(input:$input,log: $log){
      id
      fromDept
      toDept
      process
      pktType
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_DEPTWISEPROCESS = gql`
mutation updateDeptWiseProcess($input: deptWiseProcessUpdate!,$log: LogInput){
    updateDeptWiseProcess(input:$input,log: $log){
      id
      fromDept
      toDept
      process
      pktType
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_DEPTWISEPROCESS = gql`
  mutation deleteDeptWiseProcess($id: ID!,$log: LogInput){
    deleteDeptWiseProcess(id:$id,log: $log)
  }
`;
