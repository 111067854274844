import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core';
import MaterialTable from 'material-table';
import { useQuery, useMutation } from '@apollo/react-hooks'
import { KAPANDETAIL } from './KapanQuery'
import { ADD_KAPANDETAIL, DELETE_KAPANDETAIL, UPDATE_KAPANDETAIL } from './KapanMutation'
import { toast } from 'react-toastify'
import _ from 'lodash'
import { FormatError } from '../../../components/Comman/FormatError'
import { Tofixed } from '../../../components/Comman/Tofix'
import { TextField } from '@material-ui/core';

const CtsAvgCal = (rowData) => {
  let Cts = rowData?.cts || 0
  let pcs = rowData?.pcs || 0
  let a = Tofixed(Cts / pcs)
  return a === "NaN" ? 0 : a
}
const AmountCal = (rowData) => {
  let Cts = rowData?.cts || 0
  let Rate = rowData?.rate || 0
  return Tofixed(Cts * Rate)
}
const LabourAmountCal = (rowData) => {
  let Cts = rowData?.cts || 0
  let labourRate = rowData?.labourRate || 0
  return Tofixed(Cts * labourRate)
}

const NetAmountCal = (rowData) => {
  let expense = rowData?.expense || 0
  return Tofixed(AmountCal(rowData) + LabourAmountCal(rowData) + parseInt(expense))
}

const amountZarCal = (rowData) => {
  let rateZar = rowData?.rateZar || 0
  return Tofixed(NetAmountCal(rowData) * rateZar)
}

const columns = [
  {
    title: 'Lot No*',
    field: 'lotNo'
  },
  {
    title: 'Pcs*',
    field: 'pcs',
    type: "numeric"
  },
  {
    title: 'CTS*',
    field: 'cts',
    render: (rowData) => Tofixed(rowData?.cts || 0),
    type: "numeric",
    editComponent: (props) => {
      //   let valueDefault = props?.rowData?.brokerPer || 0;
      return (
        <TextField
          value={props?.rowData?.cts}
          type="number"
          onChange={(e) => {
            let Data = { ...props.rowData };
            Data.cts = e.target.value;
            props.onRowDataChange(Data);
          }}
        />
      );
    },
  },
  {
    title: 'Avg cts*',
    field: 'avgCts',
    render: (rowData) => rowData.avgCts ? Tofixed(rowData?.avgCts || 0) : "",
    editComponent: ({ rowData }) => { return CtsAvgCal(rowData) }
  },
  {
    title: 'Rate*',
    field: 'rate',
    render: (rowData) => rowData.rate ? Tofixed(rowData?.rate || 0) : "",
    type: "numeric",
    editComponent: (props) => {
      //   let valueDefault = props?.rowData?.brokerPer || 0;
      return (
        <TextField
          value={props?.rowData?.rate}
          type="number"
          onChange={(e) => {
            let Data = { ...props.rowData };
            Data.rate = e.target.value;
            props.onRowDataChange(Data);
          }}
        />
      );
    },
  },
  {
    title: 'Amount*',
    field: 'amount',
    render: (rowData) => rowData.amount ? Tofixed(rowData?.amount || 0) : "",
    editComponent: ({ rowData }) => {
      return AmountCal(rowData)
    }
  },
  {
    title: 'Labour Rate*',
    field: 'labourRate',
    render: (rowData) => rowData.labourRate ? Tofixed(rowData?.labourRate || 0) : "",
    type: "numeric",
    editComponent: (props) => {
      //   let valueDefault = props?.rowData?.brokerPer || 0;
      return (
        <TextField
          value={props?.rowData?.labourRate}
          type="number"
          onChange={(e) => {
            let Data = { ...props.rowData };
            Data.labourRate = e.target.value;
            props.onRowDataChange(Data);
          }}
        />
      );
    },
  },
  {
    title: 'Labour Amount*',
    field: 'labourAmount',
    render: (rowData) => rowData.labourAmount ? Tofixed(rowData?.labourAmount || 0) : "",
    editComponent: ({ rowData }) => {
      return LabourAmountCal(rowData)
    }
  },
  {
    title: 'Expense*',
    field: 'expense',
    type: "numeric",
    render: (rowData) => rowData.expense ? Tofixed(rowData?.expense || 0) : "",
    editComponent: (props) => {
      //   let valueDefault = props?.rowData?.brokerPer || 0;
      return (
        <TextField
          value={props?.rowData?.expense}
          type="number"
          onChange={(e) => {
            let Data = { ...props.rowData };
            Data.expense = e.target.value;
            props.onRowDataChange(Data);
          }}
        />
      );
    },
  },
  {
    title: 'Net Amount*',
    field: 'netAmount',
    render: (rowData) => Tofixed(rowData?.netAmount || 0),
    editComponent: ({ rowData }) => {
      return NetAmountCal(rowData)
    }
  },
  {
    title: 'Zar Rate*',
    field: 'rateZar',
    type: "numeric",
    render: (rowData) => rowData.rateZar ? Tofixed(rowData?.rateZar || 0) : "",
    editComponent: (props) => {
      //   let valueDefault = props?.rowData?.brokerPer || 0;
      return (
        <TextField
          value={props?.rowData?.rateZar}
          type="number"
          onChange={(e) => {
            let Data = { ...props.rowData };
            Data.rateZar = e.target.value;
            props.onRowDataChange(Data);
          }}
        />
      );
    },
  },
  {
    title: 'Zar Amount*',
    field: 'amountZar',
    render: (rowData) => Tofixed(rowData?.amountZar || 0),
    editComponent: ({ rowData }) => {
      return amountZarCal(rowData)
    }
  }
]
export const Kapanchild = (props) => {
  const permissions = props?.session?.me?.role?.permissions;
  const role = props?.session?.me?.role?.role;
  const path = props?.location?.pathname;
  let permission = _.find(permissions, function (o) { return o.name === path; })

  const { data, error, loading } = useQuery(KAPANDETAIL, {
    variables: { id: props.Ids },
    fetchPolicy: 'cache-and-network'
  })
  const HideRow = (rows) => {
    let aa = document.getElementsByClassName('childTable')
    for (let index = 0; index < aa.length; index++) {
      setTimeout(() => {
        if (rows && rows !== 0 && aa[index]) {
          let newD = aa[index].getElementsByClassName('MuiTableBody-root')[0].rows[aa[index].getElementsByClassName('MuiTableBody-root')[0].rows.length - 1]
          if (newD.getElementsByClassName('MuiTableCell-sizeSmall')[0]) {
            newD.getElementsByClassName('MuiTableCell-sizeSmall')[0].innerText = 'Total'
            newD.style.background = 'rgb(212, 218, 255)'
          }
        }
      }, 300);
    }
  }
  const [AddKapanDetailMutation] = useMutation(ADD_KAPANDETAIL)
  const [DeleteKapanDetailMutation] = useMutation(DELETE_KAPANDETAIL)
  const [UpdatekapanDetailMutation] = useMutation(UPDATE_KAPANDETAIL)
  const UpdateMasterDataFromDetails = (newData, type) => {
    let result = [...data?.getAllKapanDetailForSelf || []] || []
    let a = props?.KapanMasterData || []
    let KapanMaster = a.filter(d => d?.id === props?.Ids);
    if (KapanMaster?.length !== 0 && KapanMaster[0]?.totalCts) {
      let TotalCts = 0, NetCost = 0, ZarAmount = 0, LotList = []
      if (type === "ADD") {
        TotalCts = _.sum(_.map(result, 'cts')) + newData.cts
        NetCost = _.sum(_.map(result, 'netAmount')) + newData.netAmount
        ZarAmount = _.sum(_.map(result, 'amountZar')) + newData.amountZar
        LotList = _.map(result, 'lotNo')
        LotList.push(newData?.lotNo)
      } else if (type === "UPDATE") {
        TotalCts = _.sum(_.map(_.filter(result, (d) => d.id !== newData.id), 'cts')) + newData.cts
        NetCost = _.sum(_.map(_.filter(result, (d) => d.id !== newData.id), 'netAmount')) + newData.netAmount
        ZarAmount = _.sum(_.map(_.filter(result, (d) => d.id !== newData.id), 'amountZar')) + newData.amountZar
        LotList = _.map(_.filter(result, (d) => d.id !== newData.id), 'lotNo')
        LotList.push(newData.lotNo)
      } if (type === "DELETE") {
        TotalCts = _.sum(_.map(_.filter(result, (d) => d.id !== newData.id), 'cts'))
        NetCost = _.sum(_.map(_.filter(result, (d) => d.id !== newData.id), 'netAmount'))
        ZarAmount = _.sum(_.map(_.filter(result, (d) => d.id !== newData.id), 'amountZar'))
        LotList = _.map(_.filter(result, (d) => d.id !== newData.id), 'lotNo')
      }
      if (TotalCts <= parseFloat(Tofixed(KapanMaster[0].totalCts))) {
        let a = KapanMaster[0].tableData
        delete KapanMaster[0].tableData
        KapanMaster[0].remainCts = parseFloat(Tofixed(KapanMaster[0].totalCts - TotalCts))
        KapanMaster[0].netCost = NetCost
        KapanMaster[0].amountZar = ZarAmount
        KapanMaster[0].lotNoList = LotList.join(',')
        if (props.UpdateKapan(KapanMaster[0])) {
          KapanMaster[0].tableData = a
          return true
        }
      } else {
        return false
      }
    } else {
      return false
    }
  }
  const AddKapanChild = (newData) => {
    return new Promise((resolve, reject) => {
      let result = [...data?.getAllKapanDetailForSelf || []]
      newData.pcs = parseInt(newData.pcs)
      newData.cts = parseFloat(Tofixed(newData.cts))
      newData.expense = parseFloat(Tofixed(newData.expense))
      newData.rateZar = parseFloat(Tofixed(newData.rateZar))
      newData.rate = parseFloat(Tofixed(newData.rate))
      newData.labourRate = parseFloat(Tofixed(newData.labourRate))
      newData.avgCts = parseFloat(Tofixed(CtsAvgCal(newData)))
      newData.amount = parseFloat(Tofixed(AmountCal(newData)))
      newData.labourAmount = parseFloat(Tofixed(LabourAmountCal(newData)))
      newData.netAmount = parseFloat(Tofixed(NetAmountCal(newData)))
      newData.amountZar = parseFloat(Tofixed(amountZarCal(newData)))


      newData.kapanMasterId = props.Ids
      if (newData.lotNo && newData.pcs && newData.cts && newData.avgCts && newData.rate && newData.amount && (0 <= newData.labourRate) && (0 <= newData.labourAmount) && (0 <= newData.expense) && newData.netAmount && newData.rateZar && newData.amountZar) {
        if (_.findIndex(result, function (o) { return (o.lotNo)?.trim() === (newData.lotNo)?.trim() }) === -1) {
          let log = {
            action: 'INSERT',
            actionOn: 'KapanDetail',
            actionName: 'addNewKapanDetail',
            oldValue: "",
            message: `${newData?.cts || 0} carats kapanDetail was created.`
          }
          if (UpdateMasterDataFromDetails(newData, "ADD")) {
            AddKapanDetailMutation({
              variables: { input: newData, log }
            }).then((data) => {
              toast.success('kapan detail created successfully');
              resolve()
            }).catch((e) => {
              reject()
              toast.error(FormatError(e))
            })
          } else {
            reject()
            let RemainingCts = _.map((props?.KapanMasterData || []).filter(d => props.Ids), 'remainCts')
            toast.error(`remaining carats are ${RemainingCts[0]}`)
          }
        } else {
          reject()
          toast.error('Lot No already exist')
        }
      } else {
        toast.warn('please, fill all required(*) fields.')
        reject()
      }

    })
  }

  const UpdateKapanChild = (newData, oldData) => {
    return new Promise(async (resolve, reject) => {
      let result = [...data?.getAllKapanDetailForSelf || []]
      delete newData.createdBy
      delete newData.createdAt
      delete newData.__typename
      delete newData.updatedBy
      delete newData.updatedAt
      newData.pcs = parseInt(newData.pcs)
      newData.cts = parseFloat(Tofixed(newData.cts))
      newData.expense = parseFloat(Tofixed(newData.expense))
      newData.rateZar = parseFloat(Tofixed(newData.rateZar))
      newData.rate = parseFloat(Tofixed(newData.rate))
      newData.labourRate = parseFloat(Tofixed(newData?.labourRate || 0))
      newData.avgCts = parseFloat(Tofixed(CtsAvgCal(newData)))
      newData.amount = parseFloat(Tofixed(AmountCal(newData)))
      newData.labourAmount = parseFloat(Tofixed(LabourAmountCal(newData)))
      newData.netAmount = parseFloat(Tofixed(NetAmountCal(newData)))
      newData.amountZar = parseFloat(Tofixed(amountZarCal(newData)))
      newData.kapanMasterId = props.Ids
      if (newData.lotNo && newData.pcs && (0 <= newData.cts) && (0 <= newData.avgCts) && (0 <= newData.rate) && (0 <= newData.amount) && (0 <= newData.labourRate) && (0 <= newData.labourAmount) && (0 <= newData.expense) && (0 <= newData.netAmount) && (0 <= newData.rateZar) && (0 <= newData.amountZar)) {
        if (_.findIndex(result, function (o) { return o.lotNo && (o?.lotNo).trim() === (newData?.lotNo).trim() }) === -1 || (newData?.lotNo || '') === (oldData?.lotNo)) {
          let log = {
            action: 'UPDATE',
            actionOn: 'KapanDetail',
            actionName: 'updateKapanDetail',
            oldValue: JSON.stringify(oldData),
            message: `${oldData?.cts || 0} carats kapanDetail was updated.`
          }
          if (UpdateMasterDataFromDetails(newData, "UPDATE")) {
            UpdatekapanDetailMutation({
              variables: { input: newData, log }
            }).then((data) => {
              toast.success('kapan detail updated successfully');
              resolve()
            }).catch((e) => {
              reject()
              toast.error(FormatError(e))
            })
          } else {
            reject()
            let RemainingCts = _.map((props?.KapanMasterData || []).filter(d => props.Ids), 'remainCts')
            toast.error(`remaining carats are ${RemainingCts}`)
          }
        } else {
          reject()
          toast.error('Lot No already exist')
        }
      } else {
        toast.warn('please, fill all required(*) fields.')
        reject()
      }
    })
  }
  const DeleteKapanChild = (oldData) => {
    return new Promise((resolve, reject) => {
      let log = {
        action: 'DELETE',
        actionOn: 'KapanDetail',
        actionName: 'deleteKapanDetail',
        oldValue: JSON.stringify(oldData),
        message: `${oldData?.cts || 0} carats kapanDetail was deleted.`
      }
      if (UpdateMasterDataFromDetails(oldData, "DELETE")) {
        DeleteKapanDetailMutation({ variables: { id: oldData.id, log } }).then((data) => {
          toast.success('kapan deleted successfully!');
          resolve()
        }).catch((e) => {
          reject()
          toast.error(FormatError(e))
        })
      }
    })
  }
  const getRoughDetail = () => {
    return new Promise((resolve, reject) => {
      if (error)
        reject(error)
      let result = [...data?.getAllKapanDetailForSelf || []]
      if (result.length !== 0) {
        let LastRow = {
          'lotNo': '',
          'pcs': _.sum(_.map(result, 'pcs')),
          'cts': Tofixed(_.sum(_.map(result, 'cts'))),
          'avgCts': '',
          'rate': '',
          'amount': '',
          'labourRate': '',
          'labourAmount': '',
          'expense': "",
          'netAmount': Tofixed(_.sum(_.map(result, 'netAmount'))),
          'rateZar': '',
          'amountZar': Tofixed(_.sum(_.map(result, 'amountZar')))
        }
        result.push(LastRow)
      }
      HideRow(result.length)
      resolve({
        data: result,
      })
    })
  }
  const addButton = (val) => {
    if (val || (role === 'admin'))
      return { onRowAdd: (newData) => AddKapanChild(newData) }
    else
      return {}
  }

  return <Grid
    container
    direction="row"
    justify="flex-end" item xs={12} >
    <div className="childTable increaseWidth" >
      {!loading &&
        <MaterialTable
          columns={columns}
          data={() => getRoughDetail()}
          options={{
            emptyRowsWhenPaging: false,
            padding: 'dense',
            pageSize: 10,
            pageSizeOptions: [5, 10, 15, 20, 25, 50],
            addRowPosition: 'first',
            filtering: false,
            sorting: false,
            search: false,
            paging: false,
            filterCellStyle: { padding: '5px', },
            maxBodyHeight: window.innerHeight - 215,
            showTitle: false,
            headerStyle: { backgroundColor: 'whitesmoke' }
          }}
          editable={{
            isEditHidden: (rowData) => (role === 'admin') ? false : !permission.isUpdate,
            isDeleteHidden: (rowData) => (role === 'admin') ? false : !permission.isDelete,
            ...addButton(permission?.isInsert),
            onRowUpdate: (newData, oldData) => UpdateKapanChild(newData, oldData),
            onRowDelete: (oldData) => DeleteKapanChild(oldData)
          }}

        />}
    </div>
  </Grid>
}