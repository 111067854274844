import gql from 'graphql-tag';

export const USERS_SUBSCRIBE = gql`
  subscription UsersChange {
    UsersChange {
      keyType
      data{
        id
        userId
        userType
        userName
        role{
          id
          role
          permissions
        }
        email
        password
        isActive
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
    }
  }
`;
