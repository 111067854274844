import gql from 'graphql-tag';

const SIGN_IN = gql`
  mutation($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      token
      user {
        id
        userName
        email
        userType
        role {
          role
        }
        TwoFA{
          isEnable
		      secret
		      qrCode
        }
      }
    }
  }
`;

export default SIGN_IN

