import { Card, CardContent, Checkbox, FormControlLabel, Grid, TextField, FormLabel, Button } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react'
import { useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { BrokerSelectData } from '../../../components/DropdownSelect/SelectComponents/BrokerSelectComponent';
import { LotNoData } from '../../../components/DropdownSelect/SelectComponents/LotSelectComponent'
import { PacketNoData } from '../../../components/DropdownSelect/SelectComponents/PktNoSelectComponent';
import { GETALLSHORTCODETYPE } from '../../Utility/SortCode/SortCodeQuery';
import _ from 'lodash';
import { GETSALESSTOCKDETAILFTOMLOTANDPACKETNOSTSTUS1 } from '../Inward/InwordQuery';
import { ISSUERETURNDETAILQUERY, ROUGHSTOCKFROMLOTANDPKT } from './IssueReturnMasterQuery';
import { useEffect } from 'react';
import { PRICE_LIST } from '../../Utility/RapaPortUser/RapaPortUserQuery';
import { Tofixed } from '../../../components/Comman/Tofix';
import { GetRapeRate } from '../../../components/DropdownSelect/SelectComponents/Getraprate';
import { ADD_ISSUERETURN_DETAIL } from './IssueReturnMasterMutation';
import { toast } from 'react-toastify';
import { FormatError } from '../../../components/Comman/FormatError';

export const AddIssueReturnData = (props) => {
    const [filedData, SetfiledData] = useState({ isRoughStock: false })
    const [isRoughStock, SetIsRoughStock] = useState(false)
    const [pricelistData, setpricelistData] = useState([]);
    const [loader, setLoader] = useState(false);

    const { data } = useQuery(ISSUERETURNDETAILQUERY, {
        variables: { id: props?.match?.params?.id || '' },
        fetchPolicy: 'cache-and-network'
    });

    const { data: priceList } = useQuery(PRICE_LIST, {
        fetchPolicy: 'cache-and-network',
    })

    useEffect(() => {
        setpricelistData(priceList?.getRapaportPriceList || [])
    }, [priceList]);

    const [addNewIssueReturnDetailMutation] = useMutation(ADD_ISSUERETURN_DETAIL);

    const { data: SalesStockData, loading: SalesStockLoading } = useQuery(GETSALESSTOCKDETAILFTOMLOTANDPACKETNOSTSTUS1, {
        variables: { status: filedData?.issueReturnType, lotNoId: filedData?.lotNoId, pktNoId: filedData?.pktNoId, partyId: filedData?.PartyIdDefault },
        fetchPolicy: 'cache-and-network',
    })

    const { data: RoughStockData, loading: RoughStockLoading } = useQuery(ROUGHSTOCKFROMLOTANDPKT, {
        variables: { status: filedData?.issueReturnType, lotNoId: filedData?.lotNoId, pktNoId: filedData?.pktNoId, issuePartyId: filedData?.PartyIdDefault },
        fetchPolicy: 'cache-and-network',
    })

    useEffect(() => {
        let Data = SalesStockData?.getSalesStockFromStatus || []
        if (Data?.length > 0 && !filedData.isRoughStock) {
            let dataNew = {
                ...filedData,
                "colorId": Data[0]?.colorId?.id || "",
                "fluId": Data[0]?.fluId?.id || "",
                "shapeId": Data[0]?.shapeId?.id || "",
                "purityId": Data[0]?.purityId?.id || "",
                "cutId": Data[0]?.cutId?.id || "",
                "polishId": Data[0]?.polishId?.id || "",
                "symmId": Data[0]?.symmId?.id || "",
                "cts": Data[0]?.cts || 0,
                "labId": Data[0]?.lab?.id || "",
                "rateRap": Data[0]?.rapRate || 0,
                "rapAmt": Data[0]?.rapAmount || 0,
                "stkDiscPer": Data[0]?.stkPer || 0,
                "certificateNo": Data[0]?.certificateId || "",
                "rfId": Data[0]?.rfId || "",
                "measurment": Data[0]?.measurment || "0",
                "location": Data[0]?.location || "",
                "setids": Data[0].id,
                "gradePer": Data[0].gradPer
            }
            SetfiledData({ ...dataNew })
        }
    }, [SalesStockData, isRoughStock])

    useEffect(() => {
        let Data = RoughStockData?.getRoughStockFromLotandPkt || []
        if (Data?.length > 0 && filedData.isRoughStock) {
            let dataNew = {
                ...filedData,
                "colorId": Data[0]?.colorId?.id || "",
                "fluId": Data[0]?.fluId?.id || "",
                "shapeId": Data[0]?.shapeId?.id || "",
                "purityId": Data[0]?.purityId?.id || "",
                "cutId": Data[0]?.cutId?.id || "",
                "polishId": Data[0]?.polishId?.id || "",
                "symmId": Data[0]?.symmId?.id || "",
                "cts": Data[0]?.cts || Data[0]?.issueCts || 0,
                "labId": Data[0]?.lab?.id || "",
                "rateRap": Data[0]?.rapRate || 0,
                "rapAmt": Data[0]?.rapAmount || 0,
                "stkDiscPer": Data[0]?.stkPer || 0,
                "certificateNo": Data[0]?.certificateId || "",
                "rfId": Data[0]?.rfId || "",
                "measurment": Data[0]?.measurment || "0",
                "location": Data[0]?.location || "",
                "setids": Data[0].id,
                "gradePer": Data[0].gradPer
            }
            SetfiledData({ ...dataNew })
        }
    }, [RoughStockData, isRoughStock])


    const LotNoDefaultProps = {
        options: LotNoData(),
        getOptionLabel: (option) => option.lotNo
    };

    const PktNoDefaultProps = {
        options: PacketNoData(filedData.lotNoId || null),
        getOptionLabel: (option) => option.pktNo
    }
    let LabData = BrokerSelectData("LAB1")
    const LabNoDefaultProps = {
        options: LabData,
        getOptionLabel: (option) => option.partyName
    };

    const { data: shortCodeData } = useQuery(
        GETALLSHORTCODETYPE,
        {
            variables: { type: "All" },
            fetchPolicy: "cache-and-network",
        }
    );

    const getShortCodeData = (TYPE) => {
        return _.filter(shortCodeData?.getAllType || [], (d) => d.type === TYPE);
    };

    const ShortCodsdefaultProps = (TYPE) => ({
        options: getShortCodeData(TYPE),
        getOptionLabel: (option) => option.shortName
    })

    const SetDataInDropdown = (TYPE, id) => {
        let Data = getShortCodeData(TYPE)
        let NewData = _.find(Data, (newd) => newd.id === id)
        return NewData
    }

    const get = (filedData) => {
        let rateRap = filedData?.rateRap || 0;
        let stkDiscPer = filedData?.stkDiscPer || 0;
        let discountedAmt = (rateRap * stkDiscPer) / 100;
        filedData['rateAfterDisc'] = parseFloat(rateRap) + parseFloat(discountedAmt);

        if (filedData?.cts && filedData?.rateRap) {
            let rapAmt = parseFloat(filedData?.cts || 0.0) * parseFloat(filedData?.rateRap || 0.0);
            let discounedAmt = (parseFloat(rapAmt || 0.0) * parseFloat(filedData?.stkDiscPer || 0.0)) / 100;
            filedData['rapAmt'] = parseFloat(rapAmt) + parseFloat(discounedAmt);
        }

        if (filedData?.rateZar) {
            let rateZar = parseFloat(filedData?.rateZar || 0);
            let rapAmt = parseFloat(filedData?.cts || 0.0) * parseFloat(filedData?.rateRap || 0.0);
            let discounedAmt = (parseFloat(rapAmt || 0.0) * parseFloat(filedData?.stkDiscPer || 0.0)) / 100;
            let totalRapAmt = parseFloat(rapAmt) + parseFloat(discounedAmt);
            filedData['amtZar'] = parseFloat(totalRapAmt) * parseFloat(rateZar);
        }
        SetfiledData({ ...filedData })
    }

    const changeRateWithShortCode = (cts, shapeId, purityId, colorId) => {
        if (cts && shapeId && purityId && colorId) {
            let raprate = GetRapeRate(pricelistData, 10, purityId, colorId, shapeId, shortCodeData)
            if (raprate?.rapRate && raprate?.rapAmount) {
                filedData['rateRap'] = raprate?.rapRate || 0
                filedData['rapAmt'] = raprate?.rapAmount || 0
            }
        }
    }

    const onchangeHandler = (key, value) => {
        filedData[`${key}`] = value
        if (filedData?.lotNoId && filedData?.pktNoId) {
            filedData[`issueReturnType`] = props?.location?.state?.issueReturnType
            filedData[`PartyIdDefault`] = props?.location?.state?.PartyIdDefault
        }
        get(filedData)
    }


    const AddNewIssueReturnChild = () => {
        return new Promise((resolve, reject) => {
            setLoader(true)
            let newData = { ...filedData }
            newData.cts = parseFloat(filedData.cts);
            newData.labId = filedData?.labId || null;
            newData.lotNoId = filedData?.lotNoId || null;
            newData.pktNoId = filedData?.pktNoId || null;
            newData.colorId = filedData?.colorId || null;
            newData.shapeId = filedData.shapeId || null;
            newData.purityId = filedData?.purityId || null;
            newData.cutId = filedData?.cutId || null;
            newData.polishId = filedData?.polishId || null;
            newData.symmId = filedData?.symmId || null;
            newData.fluId = filedData?.fluId || null;
            newData.issueReturnMasterId = props?.match?.params?.id;
            newData.issueNo = props?.location?.state?.issueNo;
            newData.partyId = props?.location?.state?.PartyIdDefault;
            newData.type = props?.location?.state?.issueReturnType;
            newData.gradePer = parseFloat(Tofixed(filedData.gradePer));
            newData.stkDiscPer = parseFloat(Tofixed(filedData.stkDiscPer));
            newData.rateZar = parseFloat(Tofixed(filedData.rateZar));
            newData.amtZar = parseFloat(Tofixed(filedData.amtZar));
            newData.rateRap = parseFloat(Tofixed(filedData.rateRap));
            newData.rapAmt = parseFloat(Tofixed(filedData.rapAmt));
            newData.rateAfterDisc = parseFloat(Tofixed(filedData.rateAfterDisc));

            let result = [...data?.getAllIssueReturnDetail || []]

            newData.totalCts = parseFloat(Tofixed(_.sum(_.map(result, "cts")) + newData.cts));
            newData.totalAmt = parseFloat(Tofixed(_.sum(_.map(result, "rapAmt")) + newData.rapAmt));
            newData.totalAmtZar = parseFloat(Tofixed(_.sum(_.map(result, "amtZar")) + newData.amtZar));
            newData.totalPcs = parseInt(result.length) + 1;
            if (!newData.setids) {
                reject();
                toast.warn("Packet no is in progress");
            } else {
                delete newData.setids;
                delete newData.lab;
                delete newData.PartyIdDefault;
                delete newData.issueReturnType;
            }
            if (newData.lotNoId && newData.pktNoId && newData.labId && newData.cts) {
                let log = {
                    action: 'INSERT',
                    actionOn: 'IssueReturnDetail',
                    actionName: 'addNewIssueReturnDetail',
                    oldValue: "",
                    message: `Issue Return Detail was created.`
                }
                addNewIssueReturnDetailMutation({ variables: { input: newData, log } })
                    .then((data) => {
                        setLoader(false)
                        SetfiledData({ isRoughStock: false })
                        toast.success("issue return master detail created successfully.");
                        resolve();
                    })
                    .catch((e) => {
                        setLoader(false)
                        reject();
                        toast.error(FormatError(e));
                    });
            } else {
                setLoader(false)
                reject();
                toast.warn("please, fill all required(*) fields.");
            }
        });
    };



    return <Card sx={{ minWidth: 275 }}>
        <CardContent>
            {/* {filedData.length > 0 && filedData.map((d, i) => {
                return  */}
            <div
            // style={{ border: "3px solid #8cb836", padding: 15, position: "relative", marginTop: 10 }}
            >
                {/* {filedData.length > 1 && <RemoveCircle style={{ position: "absolute", right: 0, top: -10, right: -8, fontSize: 30 }} onClick={() => removeElement(d.id)} />} */}
                <Grid container spacing={1}>
                    <Grid item xs={2}>
                        <FormLabel> Stock</FormLabel>
                        <Checkbox defaultChecked={filedData.isRoughStock} onChange={({ target }) => { SetIsRoughStock(target.checked); onchangeHandler("isRoughStock", target.checked) }} />
                    </Grid>

                    <Grid item xs={2}>
                        <FormLabel> Lot No</FormLabel>
                        <Autocomplete
                            {...LotNoDefaultProps}
                            disableClearable
                            onChange={(e, newValue) => onchangeHandler("lotNoId", newValue?.id || null)}
                            defaultValue={_.find(LotNoData(), (newd) => newd.id === (filedData?.lotNoId?.id || filedData?.lotNoId))}
                            renderInput={(params) => <TextField placeholder="Lot No"   {...params} margin="normal" variant="outlined" />}
                        />
                    </Grid>

                    <Grid item xs={2}>
                        <FormLabel> Pkt No</FormLabel>
                        <Autocomplete
                            {...PktNoDefaultProps}
                            disableClearable
                            onChange={(e, newValue) => onchangeHandler("pktNoId", newValue?.id || null)}
                            defaultValue={_.find(PacketNoData(filedData.lotNoId || null), (newd) => newd.id === (filedData?.pktNoId?.id || filedData?.pktNoId))}
                            renderInput={(params) => <TextField placeholder="pkt No"   {...params} margin="normal" variant="outlined" />}
                        />
                    </Grid>
                </Grid>
                {filedData?.cts ?
                    <Grid container spacing={1}>
                        <Grid item xs={1}>
                            <FormLabel> Lab</FormLabel>
                            <Autocomplete
                                {...LabNoDefaultProps}
                                disableClearable
                                onChange={(e, newValue) => onchangeHandler("labId", newValue?.id || null)}
                                defaultValue={_.find(LabData, (newd) => newd.id === filedData?.labId)}
                                renderInput={(params) => <TextField placeholder="Lab No"   {...params} margin="normal" variant="outlined" />}
                            />
                        </Grid>

                        <Grid item xs={1}>
                            <FormLabel> Cts</FormLabel>
                            <TextField
                                onChange={({ target }) => {
                                    changeRateWithShortCode(target.value, filedData?.shapeId, filedData?.purityId, filedData?.colorId)
                                    onchangeHandler("cts", target.value)
                                }}
                                defaultValue={filedData?.cts}
                                id="outlined-basic"
                                placeholder="CTS"
                                variant="outlined"
                                style={{ width: "100%", paddingTop: 13, textAlign: "center" }}
                                disabled
                            />
                        </Grid>

                        <Grid item xs={1}>
                            <FormLabel> Location</FormLabel>
                            <TextField
                                defaultValue={filedData?.location || 0}
                                onChange={({ target }) => onchangeHandler("location", target.value)}
                                id="outlined-basic"
                                placeholder="Location"
                                variant="outlined"
                                style={{ width: "100%", paddingTop: 13, textAlign: "center" }} />
                        </Grid>

                        <Grid item xs={1}>
                            <FormLabel> Barcode</FormLabel>
                            <TextField
                                defaultValue={filedData?.barcode || 0}
                                onChange={({ target }) => onchangeHandler("barcode", target.value)}
                                id="outlined-basic"
                                placeholder="Barcode"
                                variant="outlined"
                                style={{ width: "100%", paddingTop: 13, textAlign: "center" }} />
                        </Grid>

                        <Grid item xs={1}>
                            <FormLabel> Certicate No.</FormLabel>
                            <TextField
                                defaultValue={filedData?.certificateNo || 0}
                                onChange={({ target }) => onchangeHandler("certificateNo", target.value)}
                                id="outlined-basic"
                                placeholder="Certicate No."
                                variant="outlined"
                                style={{ width: "100%", paddingTop: 13, textAlign: "center" }} />
                        </Grid>

                        <Grid item xs={1}>
                            <FormLabel> RFID</FormLabel>
                            <TextField
                                defaultValue={filedData?.rfId || 0}
                                onChange={({ target }) => onchangeHandler("rfId", target.value)}
                                id="outlined-basic"
                                placeholder="RFID"
                                variant="outlined"
                                style={{ width: "100%", paddingTop: 13, textAlign: "center" }} />
                        </Grid>

                        <Grid item xs={1}>
                            <FormLabel> Color</FormLabel>
                            <Autocomplete
                                {...ShortCodsdefaultProps("COLOR")}
                                disableClearable
                                onChange={(e, newValue) => {
                                    changeRateWithShortCode(filedData.cts, filedData?.shapeId, filedData?.purityId, newValue?.id)
                                    onchangeHandler("colorId", newValue?.id || null)
                                }}
                                defaultValue={SetDataInDropdown("COLOR", filedData?.colorId)}
                                renderInput={(params) => <TextField placeholder="Color"  {...params} margin="normal" variant="outlined" />}
                            />
                        </Grid>

                        <Grid item xs={1}>
                            <FormLabel> Shape</FormLabel>
                            <Autocomplete
                                {...ShortCodsdefaultProps("SHAPE")}
                                disableClearable
                                onChange={(e, newValue) => {
                                    changeRateWithShortCode(filedData?.cts, newValue?.id, filedData?.purityId, filedData?.colorId)
                                    onchangeHandler("shapeId", newValue?.id || null)
                                }}
                                defaultValue={SetDataInDropdown("SHAPE", filedData?.shapeId)}
                                renderInput={(params) => <TextField placeholder="Shape"  {...params} margin="normal" variant="outlined" />}
                            />
                        </Grid>

                        <Grid item xs={1}>
                            <FormLabel> Purity</FormLabel>
                            <Autocomplete
                                {...ShortCodsdefaultProps("PURITY")}
                                disableClearable
                                onChange={(e, newValue) => {
                                    changeRateWithShortCode(filedData?.cts, filedData?.shapeId, newValue?.id, filedData?.colorId)
                                    onchangeHandler("purityId", newValue?.id || null)
                                }}
                                defaultValue={SetDataInDropdown("PURITY", filedData?.purityId)}
                                renderInput={(params) => <TextField placeholder="Purity"  {...params} margin="normal" variant="outlined" />}
                            />
                        </Grid>

                        <Grid item xs={1}>
                            <FormLabel> Cut</FormLabel>
                            <Autocomplete
                                {...ShortCodsdefaultProps("CUT")}
                                disableClearable
                                onChange={(e, newValue) => onchangeHandler("cutId", newValue?.id || null)}
                                defaultValue={SetDataInDropdown("CUT", filedData?.cutId)}
                                renderInput={(params) => <TextField placeholder="Cut"  {...params} margin="normal" variant="outlined" />}
                            />
                        </Grid>

                        <Grid item xs={1}>
                            <FormLabel> Polish</FormLabel>
                            <Autocomplete
                                {...ShortCodsdefaultProps("POLISH")}
                                disableClearable
                                onChange={(e, newValue) => onchangeHandler("polishId", newValue?.id || null)}
                                defaultValue={SetDataInDropdown("POLISH", filedData?.polishId)}
                                renderInput={(params) => <TextField placeholder="Polish"  {...params} margin="normal" variant="outlined" />}
                            />
                        </Grid>

                        <Grid item xs={1}>
                            <FormLabel> Symmentry</FormLabel>
                            <Autocomplete
                                {...ShortCodsdefaultProps("SYMMENTRY")}
                                disableClearable
                                onChange={(e, newValue) => onchangeHandler("symmId", newValue?.id || null)}
                                defaultValue={SetDataInDropdown("SYMMENTRY", filedData?.symmId)}
                                renderInput={(params) => <TextField placeholder="Symmentry"  {...params} margin="normal" variant="outlined" />}
                            />
                        </Grid>

                        <Grid item xs={1}>
                            <FormLabel> Floursence</FormLabel>
                            <Autocomplete
                                {...ShortCodsdefaultProps("FLOURSENCE")}
                                disableClearable
                                onChange={(e, newValue) => onchangeHandler("fluId", newValue?.id || null)}
                                defaultValue={SetDataInDropdown("FLOURSENCE", filedData?.symmId)}
                                renderInput={(params) => <TextField placeholder="Floursence"  {...params} margin="normal" variant="outlined" />}
                            />
                        </Grid>

                        <Grid item xs={1}>
                            <FormLabel> Measurment</FormLabel>
                            <TextField
                                defaultValue={filedData?.measurment}
                                onChange={({ target }) => onchangeHandler("measurment", target.value)}
                                id="outlined-basic"
                                placeholder="Measurment"
                                variant="outlined"
                                style={{ width: "100%", paddingTop: 13, textAlign: "center" }} />
                        </Grid>

                        <Grid item xs={1}>
                            <FormLabel> Depth</FormLabel>
                            <TextField
                                defaultValue={filedData?.depth}
                                onChange={({ target }) => onchangeHandler("depth", target.value)}
                                id="outlined-basic"
                                placeholder="Depth"
                                variant="outlined"
                                style={{ width: "100%", paddingTop: 13, textAlign: "center" }} />
                        </Grid>

                        <Grid item xs={1}>
                            <FormLabel> Table</FormLabel>
                            <TextField
                                defaultValue={filedData?.table}
                                onChange={({ target }) => onchangeHandler("table", target.value)}
                                id="outlined-basic"
                                placeholder="Table"
                                variant="outlined"
                                style={{ width: "100%", paddingTop: 13, textAlign: "center" }} />
                        </Grid>

                        <Grid item xs={1}>
                            <FormLabel> Grd %</FormLabel>
                            <TextField
                                defaultValue={filedData?.gradePer}
                                onChange={({ target }) => onchangeHandler("gradePer", target.value)}
                                id="outlined-basic"
                                placeholder="Grd %"
                                variant="outlined"
                                style={{ width: "100%", paddingTop: 13, textAlign: "center" }} />
                        </Grid>
                        <Grid item xs={1}>
                            <FormLabel> Rap Rate</FormLabel>
                            <TextField
                                value={filedData?.rateRap}
                                // defaultValue={filedData?.rateRap}
                                onChange={({ target }) => onchangeHandler("rateRap", target.value)}
                                id="outlined-basic"
                                placeholder="Rap Rate"
                                variant="outlined"
                                style={{ width: "100%", paddingTop: 13, textAlign: "center" }} />
                        </Grid>

                        <Grid item xs={1}>
                            <FormLabel> STK Disc.%</FormLabel>
                            <TextField
                                defaultValue={filedData?.stkDiscPer}
                                onChange={({ target }) => onchangeHandler("stkDiscPer", target.value)}
                                id="outlined-basic"
                                placeholder="STK Disc.%"
                                variant="outlined"
                                style={{ width: "100%", paddingTop: 13, textAlign: "center" }} />
                        </Grid>

                        <Grid item xs={1}>
                            <FormLabel> Rate After Disc</FormLabel>
                            <TextField
                                value={filedData?.rateAfterDisc}
                                onChange={({ target }) => onchangeHandler("rateAfterDisc", target.value)}
                                id="outlined-basic"
                                placeholder="Rate After Disc"
                                variant="outlined"
                                style={{ width: "100%", paddingTop: 13, textAlign: "center" }}
                                disabled
                            />
                        </Grid>

                        <Grid item xs={1}>
                            <FormLabel> Rap Amt($)</FormLabel>
                            <TextField
                                value={filedData?.rapAmt}
                                onChange={({ target }) => onchangeHandler("rapAmt", target.value)}
                                id="outlined-basic"
                                placeholder="Rap Amt($)"
                                variant="outlined"
                                style={{ width: "100%", paddingTop: 13, textAlign: "center" }}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <FormLabel> Zar Rate</FormLabel>
                            <TextField
                                defaultValue={filedData?.rateZar}
                                onChange={({ target }) => onchangeHandler("rateZar", target.value)}
                                id="outlined-basic"
                                placeholder="Zar Rate"
                                variant="outlined"
                                style={{ width: "100%", paddingTop: 13, textAlign: "center" }} />
                        </Grid>
                        <Grid item xs={1}>
                            <FormLabel> Zar Amt</FormLabel>
                            <TextField
                                value={filedData?.amtZar}
                                onChange={({ target }) => onchangeHandler("amtZar", target.value)}
                                id="outlined-basic"
                                placeholder="Zar Amt"
                                variant="outlined"
                                style={{ width: "100%", paddingTop: 13, textAlign: "center" }}
                                disabled
                            />
                        </Grid>
                    </Grid>
                    : SalesStockLoading || RoughStockLoading && <div>loading....</div>}
            </div>
            {/* })} */}

            < Grid container spacing={1} style={{ display: "flex", justifyContent: "space-around", width: 150, alignItems: "center" }}>
                <Button variant="contained" color="primary" style={{ height: 35, marginTop: 10 }} onClick={() => AddNewIssueReturnChild()}>Submit</Button>
                {/* <AddCircle style={{ fontSize: 30 }} onClick={() => AddElement()} /> */}
            </Grid>

        </CardContent>
    </Card >
}