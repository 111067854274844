import gql from "graphql-tag";


export const GENERATE_TWO_FA_SECRET = gql`
  mutation GenerateTwoFASecret {
    generateTwoFASecret {
      secret
      qrCode
    }
  }
`;

export const VALIDATE_TWO_FA_SECRET = gql`
  mutation VerifyTwoFA($secretKey: String, $code: String) {
    verifyTwoFA(secretKey: $secretKey, code: $code) {
      token
      user {
        id
        TwoFA {
          isEnable
          secret
          qrCode
        }
        email
      }
    }
  }
`;
