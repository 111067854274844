import React, { useRef, useState, useEffect } from "react";
import MaterialTable from "material-table";
import { withRouter } from "react-router-dom";
import { Grid, TextField } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Roughchild } from "./RoughChild";
import { ROUGH, ROUGHSUMMARY } from "./RoughQuery";
import { ADD_ROUGHMASTER, UPDATE_ROUGHMASTER, DELETE_ROUGHMASTER } from "./RoughMutation";
import { toast } from "react-toastify";
import _ from "lodash";
import moment from "moment";
import { FormatError } from "../../../components/Comman/FormatError";
import { CompanyAndPartySelectComponent, CompanyAndPartyAllData } from "../../../components/DropdownSelect/SelectComponents/companyAndPartySelectComponent";
import { UserListAllData } from "../../../components/DropdownSelect/SelectComponents/UserNameSelectComponent";
import { BrokerSelectComponent, BrokerSelectData } from "../../../components/DropdownSelect/SelectComponents/BrokerSelectComponent";
import { Tofixed } from "../../../components/Comman/Tofix";
import { CustomFilterComponent } from '../../../components/CustomFilterComponent/index'
import { useStyles, Accordion, AccordionSummary, AccordionDetails } from '../../../components/Comman/Accordian'
import { Button, Typography, FormControl } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";


const columns = [
  { title: "Bill No ", field: "billNo", type: "numeric", editable: "never" },
  {
    title: "Date *", field: "date", type: "date", initialEditValue: moment(), render: (rowData) => {
      if (rowData.date)
        return moment(rowData?.date || 0).format('DD/MM/YYYY')
    }
  },
  {
    title: "Party Name*",
    field: "partyId",
    render: (rowData) => rowData?.partyId?.partyName || "SELECT PARTY",
    cellStyle: {
      minWidth: 220
    },
    editComponent: (props) => {
      let valueDefault = props?.rowData?.partyId?.id || props?.rowData?.partyId || '0';
      return <CompanyAndPartySelectComponent QueryName={"PARTY"} valueDefault={valueDefault} prosData={props} />
    }
  },
  {
    title: "Broker Name",
    field: "brokerId",
    cellStyle: {
      minWidth: 180
    },
    render: (rowData) => rowData?.brokerId?.partyName || "NO ANY BROKER",
    editComponent: (props) => {
      let valueDefault = props?.rowData?.brokerId?.id || null;
      return <BrokerSelectComponent props={props} valueDefault={valueDefault} TYPE={"BROKER"} />
    },
  },
  {
    title: "Due Days *",
    field: "dueDays",
    type: "numeric",
    editComponent: (props) => {
      return (
        <TextField
          type="number"
          defaultValue={props?.rowData?.dueDays || 0}
          onChange={(e) => {
            let Data = { ...props.rowData };
            var d = props?.rowData?.date
              ? new Date(props?.rowData?.date)
              : new Date();
            Data.dueDays = e.target.value ? e.target.value : 0;
            Data.dueDate = new Date(
              d.setDate(
                d.getDate() + parseInt(e.target.value ? e.target.value : 0)
              )
            ).toISOString();
            props.onRowDataChange(Data);
          }}
        />
      );
    },
  },
  {
    title: "Due Date", field: "dueDate", type: "date", initialEditValue: moment(),
    render: (rowData) => {
      if (rowData.dueDate)
        return moment(rowData?.dueDate || 0).format('DD/MM/YYYY')
    }
  },
  {
    title: "Broker(%) *",
    field: "brokerPer",
    type: "numeric",
    editComponent: (props) => {
      //   let valueDefault = props?.rowData?.brokerPer || 0;
      return (
        <TextField
          defaultValue={props?.rowData?.brokerPer || 0}
          type="number"
          onChange={(e) => {
            let Data = { ...props.rowData };
            Data.brokerPer = e.target.value;
            if (Data.brokerPer >= 0 && Data.brokerPer <= 100) {
              props.onRowDataChange(Data);
            } else {
              toast.warn(
                "please, enter broker percentage value between 0 to 100."
              );
            }
          }}
        />
      );
    },
  },
  {
    title: "Broker Amt.",
    field: "brokerAmount",
    type: "numeric",
    render: (rowData) => Tofixed(rowData.brokerAmount),
    editComponent: (props) => {
      if (props.rowData.id) {
        let a = props.rowData.totalCost;
        localStorage.setItem("ROUGHCOSET", a);
        let brokerPer = props?.rowData?.brokerPer || 0;
        let brokerAmount = (a * brokerPer) / 100;
        return brokerAmount;
      }
      return 0;
    },
  },
  {
    title: "Export Expense*",
    field: "exportExpense",
    type: "numeric",
    render: (rowData) => Tofixed(rowData?.exportExpense || 0),
    editComponent: (props) => {
      //   let valueDefault = props?.rowData?.brokerPer || 0;
      return (
        <TextField
          value={props?.rowData?.exportExpense}
          type="number"
          onChange={(e) => {
            let Data = { ...props.rowData };
            Data.exportExpense = e.target.value;
            props.onRowDataChange(Data);
          }}
        />
      );
    },
  },
  {
    title: "Net Cost",
    field: "netCost",
    type: "numeric",
    render: (rowData) => Tofixed(rowData.netCost),
    editComponent: (props) => {
      if (props.rowData.id) {
        let a = props.rowData.totalCost;
        localStorage.setItem("ROUGHCOSET", a);
        let brokerPer = props?.rowData?.brokerPer || 0;
        let exportExpense = props?.rowData?.exportExpense || 0;
        let brokerAmount = (a * brokerPer) / 100;
        return brokerAmount + parseInt(exportExpense) + a;
      }
      return 0;
    },
  },
  { title: "User", field: "createdBy", render: (rowData) => rowData?.createdBy?.userName || " ", editable: "never" }
];

function Rough(props) {
  const classes = useStyles();
  const tableRef = useRef();
  const [pageSize, setPageSize] = useState(10);
  const [selectedRow, setSelectedRow] = useState(null);
  const [filter, setFilter] = useState({})
  const [selectedFromDate, setSelectedFromDate] = useState(moment().subtract(7, "days"));
  const [selectedToDate, setSelectedToDate] = useState(new Date());


  const permissions = props?.session?.me?.role?.permissions;
  const role = props?.session?.me?.role?.role;
  const path = props?.location?.pathname;
  let permission = _.find(permissions, function (o) {
    return o.name === path;
  });

  const { data, fetchMore, refetch } = useQuery(ROUGH, {
    variables: { page: 1, limit: 10, filter: JSON.stringify({}), sort: { key: 'createdAt', type: -1 } },
    fetchPolicy: 'cache-and-network'
  });
  const { data: summaryData } = useQuery(ROUGHSUMMARY, {
    variables: { filter: JSON.stringify(filter), "sort": { "key": "createdAt", "type": -1 } },
    fetchPolicy: 'network-only',
  })
  columns.map((d) => {
    if (d.field === "partyId") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{
          options: CompanyAndPartyAllData("PARTY"),
          getOptionLabel: (option) => option.partyName,
          multiple: true
        }} />
      }
    } else if (d.field === "brokerId") {
      // d.lookup = BrokerSelectData("BROKER");
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{
          options: BrokerSelectData("BROKER"),
          getOptionLabel: (option) => option.partyName,
          multiple: true
        }} />
      }
    } else if (d.field === "createdBy") {
      // d.lookup = UserListAllData();
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{
          options: UserListAllData(),
          getOptionLabel: (option) => option.userName,
          multiple: true
        }} />
      }
    }
    return null;
  });
  const [AddRoughMasterMutation] = useMutation(ADD_ROUGHMASTER);
  const [UpdateRoughMasterMutation] = useMutation(UPDATE_ROUGHMASTER);
  const [DeleteRoughMasterMutation] = useMutation(DELETE_ROUGHMASTER);
  const AddRough = (newData) => {
    return new Promise((resolve, reject) => {
      newData.billNo = parseInt(newData?.billNo || 0);
      newData.dueDays = parseInt(newData.dueDays ? newData.dueDays : 0);
      newData.brokerPer = parseInt(newData.brokerPer ? newData.brokerPer : 0);
      newData.brokerAmount = parseInt("0.00");
      newData.netCost = parseFloat("0.00");
      newData.exportExpense = parseFloat(Tofixed(newData.exportExpense ? newData.exportExpense : 0));

      if (0 <= newData.dueDays && 0 <= newData.brokerPer && 0 <= newData.exportExpense && newData.partyId && newData.brokerId) {
        let log = {
          action: 'INSERT',
          actionOn: 'roughMaster',
          actionName: 'addNewRough',
          oldValue: "",
          message: `Rough was created.`
        }
        AddRoughMasterMutation({ variables: { input: newData, log } })
          .then((data) => {
            toast.success("rough created successfully");
            resolve();
          })
          .catch((e) => {
            reject();
            toast.error(FormatError(e));
          });
      } else {
        toast.warn("please, fill all required(*) fields.");
        reject();
      }
    });
  };

  const UpdateRough = (newData, oldData) => {
    return new Promise((resolve, reject) => {
      refetch();
      let a = parseInt(localStorage.getItem("ROUGHCOSET"));
      delete newData.createdBy;
      delete newData.createdAt;
      delete newData.__typename;
      newData.billNo = parseInt(newData.billNo);
      newData.dueDays = parseInt(newData.dueDays);
      newData.exportExpense = parseFloat(Tofixed(newData.exportExpense));
      newData.brokerAmount = (a * newData.brokerPer) / 100;
      newData.netCost = a + newData.brokerAmount + newData.exportExpense;
      newData.brokerPer = parseInt(newData.brokerPer);
      newData.partyId = newData?.partyId?.id ? newData?.partyId?.id : newData?.partyId?.id || newData.partyId;
      newData.brokerId = newData?.brokerId?.id ? newData?.brokerId?.id : newData?.brokerId?.id || newData.brokerId;

      if (0 <= newData.dueDays && 0 <= newData.brokerPer && 0 <= newData.exportExpense && newData.partyId && newData.brokerId) {
        let log = {
          action: 'UPDATE',
          actionOn: 'roughMaster',
          actionName: 'updateRough',
          oldValue: JSON.stringify(oldData),
          message: `${oldData?.billNo || ''} rough was updated.`
        }
        UpdateRoughMasterMutation({ variables: { input: newData, log } })
          .then((data) => {
            toast.success("rough updated successfully.");
            resolve();
          })
          .catch((e) => {
            reject();
            toast.error(FormatError(e));
          });
      } else {
        toast.warn("please, fill all required(*) fields.");
        reject();
      }
    });
  };
  const UpdateRoughWithDetail = (newData, oldData) => {
    return new Promise((resolve, reject) => {
      refetch();
      delete newData.createdBy;
      delete newData.createdAt;
      delete newData.__typename;
      newData.billNo = parseInt(newData.billNo);
      newData.dueDays = parseInt(newData.dueDays);
      newData.exportExpense = parseFloat(newData.exportExpense);
      newData.brokerPer = parseInt(newData.brokerPer);
      let NetCost = newData?.netCost || 0;
      let BrokerPer = newData?.brokerPer || 0;
      newData.brokerAmount = parseFloat(((NetCost * BrokerPer) / 100).toFixed(2));
      let BrokerAmount = newData?.brokerAmount || 0;
      let ExportExpense = parseFloat(Tofixed(newData?.exportExpense || 0));
      newData.netCost = parseFloat((NetCost + BrokerAmount + ExportExpense).toFixed(2));
      newData.partyId = newData?.partyId?.id || null;
      newData.brokerId = newData?.brokerId?.id || null;
      newData.avgCts = parseFloat(Tofixed(newData?.avgCts || 0));
      UpdateRoughMasterMutation({ variables: { input: newData } }).then((data) => {
        resolve();
      })
        .catch((e) => {
          reject();
          toast.error(FormatError(e));
        });
    });
  };

  const DeleteRough = (oldData) => {
    return new Promise((resolve, reject) => {
      refetch();
      let log = {
        action: 'DELETE',
        actionOn: 'roughMaster',
        actionName: 'deleteRough',
        oldValue: JSON.stringify(oldData),
        message: `${oldData?.billNo || ''} rough was deleted.`
      }
      DeleteRoughMasterMutation({ variables: { id: oldData.id, log } })
        .then((data) => {
          toast.success("rough deleted successfully.");
          resolve();
        })
        .catch((e) => {
          reject();
          toast.error(FormatError(e));
        });
    });
  };
  const getRoughData = (query) => {
    return new Promise(async (resolve, reject) => {
      let filter = {}, sort = { key: 'createdAt', type: -1 }
      query && query.filters.forEach(item => {
        if ((item.column.field === "date" || item.column.field === "dueDate")) {
          if (item.value)
            filter = { ...filter, [item.column.field]: moment(item.value).format("YYYY-MM-DD") }
          else {
            delete filter[item.column.field]
            filter = { ...filter }
          }
        }
        else if ((item.column.field === "billNo" || item.column.field === "dueDays" || item.column.field === "brokerPer" || item.column.field === "brokerAmount")) {
          if (item.value)
            filter = { ...filter, [item.column.field]: parseInt(item.value) }
          else {
            delete filter[item.column.field]
            filter = { ...filter }
          }
        }
        else if ((item.column.field === "exportExpense" || item.column.field === "netCost")) {
          if (item.value)
            filter = { ...filter, [item.column.field]: parseFloat(item.value) }
          else {
            delete filter[item.column.field]
            filter = { ...filter }
          }
        }
        else if (item.value.length === 0) {
          delete filter[`${item.column.field}`]
          filter = { ...filter }
        } else
          filter = { ...filter, [item.column.field]: item.value }
      });
      if (query && query.orderBy && query.orderDirection !== "") {
        sort = { key: query.orderBy.field, type: query.orderDirection === 'asc' ? 1 : -1 }
      }
      setPageSize(query.pageSize)
      try {
        let result = await fetchMore({
          variables: { page: query.page + 1, limit: query.pageSize, filter: JSON.stringify(filter), sort: sort },
          fetchPolicy: 'network-only',
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return fetchMoreResult;
          }
        })
        resolve({
          data: result?.data?.getRoughMaster?.data || [],
          page: query.page,
          totalCount: result?.data?.getRoughMaster?.count || 0,
        })
      } catch (error) {
        console.log(FormatError(error))
      }
    })
  };

  const addButton = (val) => {
    if (val || role === "admin")
      return { onRowAdd: (newData) => AddRough(newData).then(() => refetch()) };
    else return {};
  };

  useEffect(() => {
    document.getElementsByClassName("parentTable")[0].firstElementChild.firstElementChild.firstChild.remove()
  }, [])

  const PreViewHandler = () => {
    let Data = filter
    if (selectedFromDate) {
      Data = { ...Data, "from": moment(selectedFromDate).format('YYYY-MM-DD') }
    }
    if (selectedToDate) {
      Data = { ...Data, "to": moment(selectedToDate).format('YYYY-MM-DD') }
    }
    setFilter(Data)
  }
  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>Filters</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container xs={24}  >
            <Grid xs={4}>
              {summaryData?.getRoughMasterSummary?.data &&
                <div>
                  <p>Total Cts : - {summaryData?.getRoughMasterSummary?.summary?.totalCts || ''}</p>
                  <p>Total Amount Zar : - {summaryData?.getRoughMasterSummary?.summary?.totalAmountZar || ''}</p>
                  <p>Total Amount Dollar : - {summaryData?.getRoughMasterSummary?.summary?.totalAmountDollar || ''}</p>
                  <p>Total Rough : - {summaryData?.getRoughMasterSummary?.summary?.totalRough || ''}</p>
                </div>
              }
            </Grid>
            <Grid xs={4}>
              <form className={"makeStyles-root-30"} noValidate autoComplete="off">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-from"
                    label="From Date"
                    format="dd/MM/yyyy"
                    value={selectedFromDate}
                    onChange={e => setSelectedFromDate(e)}
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                    style={{ marginTop: 0 }}
                  />&nbsp;&nbsp;&nbsp;&nbsp;
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-to"
                    label="To Date"
                    format="dd/MM/yyyy"
                    value={selectedToDate}
                    onChange={e => setSelectedToDate(e)}
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                    style={{ marginTop: 0 }}
                  />
                </MuiPickersUtilsProvider>

                <Button
                  onClick={() => PreViewHandler()}
                  variant="contained"
                  color="primary"
                  style={{ height: 34, marginLeft: 10, marginTop: 12 }}>
                  Preview
                </Button>

              </form>
            </Grid>

          </Grid>

        </AccordionDetails>
        <Grid item xs={12}>
          {(filter.from && filter.to) ?
            <div className="parentTable">
              <MaterialTable
                title="Rough"
                columns={columns}
                data={summaryData?.getRoughMasterSummary?.data || []}
                options={{
                  emptyRowsWhenPaging: false,
                  debounceInterval: 300,
                  padding: "dense",
                  pageSize: pageSize,
                  pageSizeOptions: [5, 10, 15, 20, 25, 50],
                  addRowPosition: "first",
                  filtering: false,
                  sorting: false,
                  search: false,
                  showTitle: true,
                  filterCellStyle: { padding: "5px" },
                  maxBodyHeight: window.innerHeight - 215,
                  rowStyle: (rowData) => ({
                    backgroundColor:
                      selectedRow === rowData.tableData.id
                        ? "rgb(212, 218, 255)"
                        : "#FFF",
                  }),
                  headerStyle: { backgroundColor: 'whitesmoke' }
                }}
              />
            </div> : ""}
        </Grid>
      </Accordion>

      <Grid container>
        <Grid item xs={12}>
          <div className="parentTable increaseWidth">
            <MaterialTable
              title="Rough"
              tableRef={tableRef}
              columns={columns}
              data={(query) => getRoughData(query)}
              icons={{
                Add: props => {
                  return (<div> <i className="fa fa-plus-square" ></i> Rough </div>
                  )
                }
              }}
              options={{
                emptyRowsWhenPaging: false,
                debounceInterval: 300,
                padding: "dense",
                pageSize: pageSize,
                pageSizeOptions: [5, 10, 15, 20, 25, 50],
                addRowPosition: "first",
                filtering: true,
                sorting: true,
                search: false,
                showTitle: false,
                filterCellStyle: { padding: "5px" },
                maxBodyHeight: window.innerHeight - 215,
                rowStyle: (rowData) => ({
                  backgroundColor:
                    selectedRow === rowData.tableData.id
                      ? "rgb(212, 218, 255)"
                      : "#FFF",
                }),
                headerStyle: { backgroundColor: 'whitesmoke' }
              }}
              editable={{
                isEditHidden: (rowData) =>
                  role === "admin" ? false : !permission.isUpdate,
                isDeleteHidden: (rowData) =>
                  role === "admin" ? false : !permission.isDelete,
                ...addButton(permission?.isInsert),
                onRowUpdate: (newData, oldData) => UpdateRough(newData, oldData),
                onRowDelete: (oldData) => DeleteRough(oldData),
              }}
              detailPanel={(rowData) => {
                return (
                  <Roughchild
                    session={props?.session}
                    location={props?.location}
                    Ids={rowData.id}
                    RoughMasterData={data?.getRoughMaster?.data || []}
                    UpdateRough={UpdateRoughWithDetail}
                  />
                );
              }}
              onRowClick={(evt, selectedRow) =>
                setSelectedRow(selectedRow.tableData.id)
              }
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default withRouter(Rough);
