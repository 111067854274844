/* Libray */
import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import { Grid, Table, Button, Typography, Select, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, InputLabel } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MenuItem from '@material-ui/core/MenuItem';
import _ from 'lodash';
import groupBy from 'lodash/groupBy'
import { useQuery } from '@apollo/react-hooks';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';

/* Component Imports */
import { GETFILTERROUGHSTOCK } from './Query';
import { FormControl } from '@material-ui/core';
import { LotNo } from '../../../components/DropdownSelect/LotNo';
import { Accordion, AccordionDetails, AccordionSummary } from '../../../components/Comman/Accordian'
import { Tofixed } from '../../../components/Comman/Tofix'
import { PrintDiv } from '../../../components/Comman/exportPdfAndExcel'
import * as XLSX from 'xlsx/xlsx.mjs';
import moment from 'moment';
import ExcelPng from '../../../../Excel.png'

/* CSS */
import '../../PowerMenu/StockMaster/stock.css'
import './style.css';

const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1, },
  noMargin: { margin: 0 },

  GridLeftSpace: {
    paddingLeft: 5
  },
  GridFillter: {
    textAlign: "end"
  },
  SetAccordianHeade: {
    paddingTop: 0,
    marginTop: "0px !important"
  },
  fitGrid: {
    maxHeight: "186px !important",
    overflowY: "scroll"
  },
  pr_5: {
    paddingRight: "5px"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  Select: {
    marginTop: "0px !important",
    paddingTop: 0,
    // paddingRight: 10
  },
  denseTable: {
    '& td': { padding: "2px 5px", cursor: "pointer", textAlign: "center" },
    '& th': { padding: "5px 5px", textAlign: "center" },
  }
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#8CB836",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);


function MfgKapanStockReport(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [type, setType] = useState('');
  const [lotNo, setLotNo] = useState(null);
  const [filter, setfilter] = useState({});
  const [KapanSummary, setKapansSummary] = useState([])
  const [subPkt, setSubPkt] = useState([])
  const [TotalMainPkt, setTotalMainPkt] = useState([])
  const [totalPKTInProcess, setTotalPKTInProcess] = useState({})
  const [polishPKT, setPolishPKT] = useState({})
  const [finalPKT, setFinalPKT] = useState({})
  const [kapan, setKapan] = useState("")
  const [kapanDetail, setKapanDetail] = useState([])
  let [roughData, setRoughData] = useState([]);
  let [GroupByDept, setGroupByDept] = useState({});
  let [DefaultLotNo, setDefaultLotNo] = useState({});

  const { loading, data } = useQuery(GETFILTERROUGHSTOCK, {
    variables: { filter: JSON.stringify(filter) },
    fetchPolicy: 'cache-and-network',
  })

  const handleTypeChange = (event) => {
    setType(event.target.value);
  };
  useEffect(() => {
    let Data = JSON.parse(localStorage.getItem('MFGKapanStockReport'))
    if (Data?.lotNo) {
      setDefaultLotNo(_.find(defaultProps.options, (d) => d.id === Data?.lotNo))
      setfilter({ lotNo: Data?.lotNo || '' })
      setType(Data.type)
    }
  }, [])

  useEffect(() => {
    setRoughData(data?.getFilterRoughStock?.data || []);
  }, [data])

  useEffect(() => {
    if (roughData?.length === 0) {
      setKapansSummary([])
      setSubPkt({})
      setTotalPKTInProcess({})
      setPolishPKT({})
      setFinalPKT({})
      setKapan("")
      setKapanDetail([])
    }
    setGroupByDept(groupBy(_.filter(roughData, (d) => d?.departmentId?.deptName !== "Branch Transfer"), 'departmentId.deptName'))
  }, [roughData])

  roughData = roughData.map(d => {
    if (d.transferId !== null) {
      d.departmentId.deptName = "Branch Transfer"
    }
    return d
  })

  const GroupByLotNo = groupBy(roughData, 'lotNoId.lotNo');
  const TotalsubPacket = _.filter(roughData, (d) => d?.pktNoId?.isPlanning === false && d?.pktNoId?.isSubPkt === true)
  const TotalMainPacket = _.filter(roughData, (d) => d?.pktNoId?.isSubPkt === false)
  const extracts = _.sum(_.map(roughData, 'extraCts'))
  const failedCts = _.sum(_.map(_.filter(roughData, (d) => d?.pktType === "FAILED"), 'issueCts'))

  const originPkt = [];
  const originalCts = Object.keys(GroupByLotNo).map(groupLot => {
    originPkt.push(GroupByLotNo[groupLot].length);
    return Tofixed(GroupByLotNo[groupLot].reduce((prev, curr) => prev + curr.issueCts, 0));
  })
  const originalPkt = originPkt.reduce((prev, curr) => prev + curr, 0);

  const onOriginalCts = () => {
    const updateKapanSummary = [], totalPKTInProcess = [], polishPKT = [], finalPKT = [];
    Object.keys(GroupByLotNo).forEach((lot, i) => {
      GroupByLotNo[lot].forEach((groupLot, i) => {
        if ((groupLot?.process?.procName !== "UNISSUE") && (groupLot?.process?.procName !== "FINAL POLISH") &&
          (groupLot?.pktType !== "KACHU" && groupLot?.pktType !== "FINAL POLISH"))
          totalPKTInProcess.push(groupLot)
        else if (groupLot?.pktType === "FINAL POLISH")
          polishPKT.push(groupLot)
        else if (groupLot?.pktType === "KACHU")
          finalPKT.push(groupLot)
      })
    })
    const KapanSummary = [{ "Total(Main) Pkt": "" }, { "Sub Pkt": "" }, { "Total Pkt In Process": "" }, { "Polish Pkt": "" }, { "Failed Pkt": "" }]
    KapanSummary.forEach(ks => {
      const tempObj = {};
      const key = Object.keys(ks).join();
      if (key === "Total(Main) Pkt")
        tempObj[key] = TotalMainPacket.length || 0
      else if (key === "Sub Pkt")
        tempObj[key] = TotalsubPacket?.length || 0
      else if (key === "Total Pkt In Process")
        tempObj[key] = totalPKTInProcess.length
      else if (key === "Polish Pkt")
        tempObj[key] = polishPKT.length
      else if (key === "Failed Pkt")
        tempObj[key] = finalPKT.length
      updateKapanSummary.push(tempObj)
    })
    setKapansSummary(updateKapanSummary)
    const TotalMainPkt = groupBy(TotalMainPacket, 'departmentId.deptName');
    const subPkts = groupBy(TotalsubPacket, 'departmentId.deptName');
    const totalPktInProcess = groupBy(totalPKTInProcess, 'departmentId.deptName');
    const polishPkt = groupBy(polishPKT, 'departmentId.deptName');
    const finalPkt = groupBy(finalPKT, 'departmentId.deptName');
    setSubPkt(subPkts)
    setTotalPKTInProcess(totalPktInProcess)
    setPolishPKT(polishPkt)
    setFinalPKT(finalPkt)
    setTotalMainPkt(TotalMainPkt)
  }

  useEffect(() => {
    KapanSummary.map(kapanSum => {
      const key = Object.keys(kapanSum).join();
      if (kapan === key && kapanSum[key] === 0)
        setKapanDetail([])
      return null
    })
  }, [kapan])

  const onKapanSummarySelect = (key, kapanName) => {
    if (kapanName === "Total(Main) Pkt") {
      setKapanDetail(TotalMainPkt[key] ? TotalMainPkt[key] : [])
    } else if (kapanName === "Sub Pkt") {
      setKapanDetail(subPkt[key] ? subPkt[key] : [])
    } else {
      const kapanDetails = []
      Object.keys(GroupByLotNo).map((groupLot, i) => {
        GroupByLotNo[groupLot].map(d => {
          let departmentName = d?.departmentId?.deptName
          if (departmentName === key) {
            kapanDetails.push(d)
          }
          return null
        }
        )
        return null
      })
      setKapanDetail(kapanDetails ? kapanDetails : [])
    }
  }
  let KapanSummarryTotalPacket = 0
  if (KapanSummary.length !== 0) {
    KapanSummary.map(d => {
      if (d['Total(Main) Pkt']) {
        KapanSummarryTotalPacket = KapanSummarryTotalPacket + d['Total(Main) Pkt']
      } else if (d["Sub Pkt"]) {
        KapanSummarryTotalPacket = KapanSummarryTotalPacket + d["Sub Pkt"]
      }
      return null
    })
  }
  const onsubmitHandler = () => {
    setKapansSummary([])
    setSubPkt({})
    setTotalPKTInProcess({})
    setPolishPKT({})
    setFinalPKT({})
    setKapan("")
    setKapanDetail([])
    setGroupByDept({})
    setfilter({ lotNo })
    localStorage.setItem('MFGKapanStockReport', JSON.stringify({ type, lotNo }))
  }

  function exportExcelFile(workbook) {
    return XLSX.writeFile(workbook, `MFGKapanWiseReport${moment()}.xlsx`);
  }

  const exportCsvData = () => {
    var workbook = XLSX.utils.book_new();
    var worksheet_data = document.getElementById("table-to-xls");
    var worksheet = XLSX.utils.table_to_sheet(worksheet_data);
    workbook.SheetNames.push("Test");
    workbook.Sheets["Test"] = worksheet;
    exportExcelFile(workbook);
  }

  let defaultProps = LotNo('', '', '').defaultProps
  let DropDown = ''
  return (
    <>
      <div className={classes.root}>
        <Accordion expanded={expanded} className="AccordianHeader" onChange={() => setExpanded(!expanded)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            style={{ width: 'auto' }}
          >
            <Grid container className="mt-0">
              <Grid xs={11} item container justify="center" className="mt-0">
                <Typography className={classes.heading}>MFG Kapan Stock Report</Typography>
              </Grid>
              <Grid xs={1} item container justify="flex-end" className="mt-0">
                <Typography className={classes.heading}>Filter</Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Grid item container justify="center" style={{ paddingBottom: 15 }}>
              <Grid xs={1} item>
                <Autocomplete
                  value={DefaultLotNo}
                  {...defaultProps}
                  onChange={(e, newdata) => {
                    if (newdata) {
                      setLotNo(newdata.id)
                      setDefaultLotNo(newdata)
                    }
                  }}
                  renderInput={(params) => <TextField label="Lot No"   {...params} margin="normal" />}
                />
              </Grid>
              <Grid xs={1} item>
                <FormControl className={classes.formControl} style={{ paddingTop: 7 }}>
                  <InputLabel id="demo-simple-select-label">Report Type </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={type}
                    onChange={handleTypeChange}
                  >
                    <MenuItem value={"detail"}>DETAIL</MenuItem>
                    <MenuItem value={"summary"}>SUMMARY</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Button variant="contained" color="primary" style={{ height: 34, marginLeft: 15, marginTop: 12 }}
                onClick={(e) => onsubmitHandler(e)} >
                Search
              </Button>
              {/* </form> */}
            </Grid>
          </AccordionDetails>
        </Accordion>
        {loading ? <>Loading...</> : null}
        <Grid xs={12} item container alignContent="space-between">
          <Grid xs={4} item container className={classes.fitGrid, classes.pr_5}  >
            <Grid xs={8} item={true}>
              <InputLabel style={{ fontSize: 16, paddingBottom: 10, color: "rgb(70, 81, 142)" }}>Original Cts</InputLabel>
            </Grid>
            <Grid xs={4} item={true}>
              <InputLabel style={{ fontSize: 16, paddingBottom: 10, color: "rgb(70, 81, 142)" }}>{`Total PKT : ${originalPkt} | CTS : ${originalCts}`}</InputLabel>
            </Grid>
            <TableContainer component={Paper}>
              <Table className={classes.denseTable} >
                <TableHead>
                  <TableRow>
                    <StyledTableCell className="tableCell">Lot No</StyledTableCell>
                    <StyledTableCell className="tableCell">Total Cts</StyledTableCell>
                    <StyledTableCell className="tableCell">Total Pkt</StyledTableCell>
                  </TableRow>
                </TableHead>
              </Table>
              <div className="tbody">
                <Table aria-label="customized table" className={classes.denseTable} >
                  <TableBody>
                    {Object.keys(GroupByLotNo).map((row, key) => <StyledTableRow key={key} onDoubleClick={() => onOriginalCts(row)}>
                      <StyledTableCell className="tableCell">{row}</StyledTableCell>
                      <StyledTableCell className="tableCell">{Tofixed(GroupByLotNo[row].reduce((prev, curr) => prev + curr.issueCts, 0))}</StyledTableCell>
                      <StyledTableCell className="tableCell">{GroupByLotNo[row].length}</StyledTableCell>
                    </StyledTableRow>
                    )}
                  </TableBody>
                </Table>
              </div>
            </TableContainer>
          </Grid>

          <Grid xs={4} item container className={classes.fitGrid, classes.pr_5}>
            <Grid xs={7} item={true}>
              <InputLabel style={{ fontSize: 16, paddingBottom: 10, color: "rgb(70, 81, 142)" }}>Location Wise Kapan Summary</InputLabel>
            </Grid>
            <Grid xs={5} item={true}>
              <InputLabel style={{ fontSize: 16, paddingBottom: 10, color: "rgb(70, 81, 142)", textAlign: "end" }}>{`Total PKT :
            ${Object.keys(kapan === "Total(Main) Pkt"
                ?
                TotalMainPkt : kapan === "Sub Pkt"
                  ?
                  subPkt : kapan === "Total Pkt In Process"
                    ?
                    totalPKTInProcess : kapan === "Polish Pkt"
                      ?
                      polishPKT : kapan === "Failed Pkt" ?
                        finalPKT : []).reduce((prev, curr) => {
                          return prev + (kapan === "Total(Main) Pkt" ? TotalMainPkt : kapan === "Sub Pkt" ? subPkt : kapan === "Total Pkt In Process" ? totalPKTInProcess : kapan === "Polish Pkt" ? polishPKT : kapan === "Failed Pkt" ? finalPKT : [])[curr].length;
                        }, 0)}

                      | CTS : ${Tofixed(Object.keys(kapan === "Total(Main) Pkt" ? TotalMainPkt : kapan === "Sub Pkt" ? subPkt : kapan === "Total Pkt In Process" ? totalPKTInProcess : kapan === "Polish Pkt" ? polishPKT : kapan === "Failed Pkt" ? finalPKT : []).reduce((prev, curr) => {
                          return prev + (kapan === "Total(Main) Pkt" ? TotalMainPkt : kapan === "Sub Pkt" ? subPkt : kapan === "Total Pkt In Process" ? totalPKTInProcess : kapan === "Polish Pkt" ? polishPKT : kapan === "Failed Pkt" ? finalPKT : [])[curr].reduce((prev, curr) => prev + curr.issueCts, 0);
                        }, 0))}`} &nbsp;&nbsp;</InputLabel>
            </Grid>
            <TableContainer component={Paper}>
              <Table className={classes.denseTable}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell className="tableCell">Location</StyledTableCell>
                    <StyledTableCell className="tableCell">Total Pkt</StyledTableCell>
                    <StyledTableCell className="tableCell">Total Cts</StyledTableCell>
                  </TableRow>
                </TableHead>
              </Table>
              <div className="tbody">
                <Table aria-label="customized table" className={classes.denseTable}>
                  <TableBody>
                    {Object.keys(kapan === "Total(Main) Pkt" ? TotalMainPkt : kapan === "Sub Pkt" ? subPkt : kapan === "Total Pkt In Process" ? totalPKTInProcess : kapan === "Polish Pkt" ? polishPKT : kapan === "Failed Pkt" ? finalPKT : []).map((row, key) => {
                      return <StyledTableRow key={key} onClick={() => onKapanSummarySelect(row, kapan)}>
                        <StyledTableCell className="tableCell">{row}</StyledTableCell>
                        <StyledTableCell className="tableCell">{(kapan === "Total(Main) Pkt" ? TotalMainPkt : kapan === "Sub Pkt" ? subPkt : kapan === "Total Pkt In Process" ? totalPKTInProcess : kapan === "Polish Pkt" ? polishPKT : kapan === "Failed Pkt" ? finalPKT : [])[row].length}</StyledTableCell>
                        <StyledTableCell className="tableCell">{Tofixed((kapan === "Total(Main) Pkt" ? TotalMainPkt : kapan === "Sub Pkt" ? subPkt : kapan === "Total Pkt In Process" ? totalPKTInProcess : kapan === "Polish Pkt" ? polishPKT : kapan === "Failed Pkt" ? finalPKT : [])[row].reduce((prev, curr) => prev + curr.issueCts, 0))}</StyledTableCell>
                      </StyledTableRow>
                    })}
                  </TableBody>
                </Table>
              </div>
            </TableContainer>
          </Grid>

          <Grid xs={4} item container className={classes.fitGrid, classes.pr_5}>
            <Grid xs={10} item={true}>
              <InputLabel style={{ fontSize: 16, paddingBottom: 10, color: "rgb(70, 81, 142)" }}>Kapan Wise Total Packets in Proccess {type} Report</InputLabel>
            </Grid>
            <Grid xs={2} item={true} style={{ position: "relative" }}>
              <InputLabel onClick={() => PrintDiv()} style={{ fontSize: 16, paddingBottom: 10, color: "rgb(70, 81, 142)", textAlign: "end" }}><i className="fa fa-print" aria-hidden="true"></i> Print</InputLabel>
              <div className="mfgKapanReport">
                <img className="download-table-xls-button" src={ExcelPng} alt="alternative text" title="csv" onClick={() => exportCsvData()} style={{ top: 0 }} />
              </div>
            </Grid>
            <TableContainer component={Paper}>
              <Table className={classes.denseTable}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell className="table-row">{type === "detail" ? "Department Name" : "Lot No"}</StyledTableCell>
                    <StyledTableCell className="table-row">{type === "detail" ? "Lot No" : "Department Name"}</StyledTableCell>
                    <StyledTableCell className="table-row">{type === "detail" ? "Pkt No" : "Main Pkts"}</StyledTableCell>
                    <StyledTableCell className="table-row">{type === "detail" ? "Cts" : "Sub Pkts"}</StyledTableCell>
                  </TableRow>
                </TableHead>
              </Table>
              <div className="tbody">
                {type &&
                  <Table aria-label="customized table" className={classes.denseTable}>
                    <TableBody>
                      {Object.keys(GroupByDept).map((row, i) => {
                        if (type === "detail")
                          return GroupByDept[row].map((r, j, key) => {
                            return <StyledTableRow key={key}>
                              <StyledTableCell className="table-row">{row}</StyledTableCell>
                              <StyledTableCell className="table-row">{r.lotNoId.lotNo}</StyledTableCell>
                              <StyledTableCell className="table-row">{r.pktNoId.pktNo}</StyledTableCell>
                              <StyledTableCell className="table-row">{Tofixed(r.issueCts)}</StyledTableCell>
                            </StyledTableRow>
                          })
                        return (<StyledTableRow key={i}>
                          <StyledTableCell className="table-row">{GroupByDept[row][i]?.lotNoId?.lotNo || ''}</StyledTableCell>
                          <StyledTableCell className="table-row">{row}</StyledTableCell>
                          <StyledTableCell className="table-row">{_.filter(TotalMainPacket, (d) => d?.departmentId?.deptName === row).length}</StyledTableCell>
                          <StyledTableCell className="table-row">{_.filter(TotalsubPacket, (d) => d?.departmentId?.deptName === row).length}</StyledTableCell>
                        </StyledTableRow>)
                      })}
                    </TableBody>
                  </Table>
                }
              </div>
            </TableContainer>
          </Grid>
        </Grid>

        <Grid xs={12} item container alignContent="space-between">
          <Grid xs={4} item container className={classes.fitGrid, classes.pr_5}>
            <Grid xs={7} item={true}>
              <InputLabel style={{ fontSize: 16, paddingBottom: 10, color: "rgb(70, 81, 142)" }}>Kapan Summary</InputLabel>
            </Grid>
            <Grid xs={5} item={true}>
              <InputLabel style={{ fontSize: 16, paddingBottom: 10, color: "rgb(70, 81, 142)", textAlign: "end" }}>{`Total PKT : ${KapanSummarryTotalPacket}`}</InputLabel>
            </Grid>
            <TableContainer component={Paper}>
              <Table className={classes.denseTable}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell style={{ width: '50%', textAlign: "center" }}>Type</StyledTableCell>
                    <StyledTableCell style={{ width: '50%', textAlign: "center" }}>Total Pkt</StyledTableCell>
                  </TableRow>
                </TableHead>
              </Table>
              <div className="tbody">
                <Table aria-label="customized table" className={classes.denseTable}>
                  <TableBody>
                    {
                      KapanSummary.map((kapanSum, i) => {
                        const key = Object.keys(kapanSum);
                        return (<StyledTableRow key={i} onDoubleClick={() => setKapan(key.join())}>
                          <StyledTableCell style={{ width: '50%', textAlign: "center" }}>{key}</StyledTableCell>
                          <StyledTableCell style={{ width: '50%', textAlign: "center" }}>{kapanSum[key]}</StyledTableCell>
                        </StyledTableRow>)
                      })
                    }
                  </TableBody>
                </Table>
              </div>
            </TableContainer>
          </Grid>

          <Grid xs={4} item container className={classes.fitGrid, classes.pr_5}>
            <Grid xs={7} item={true}>
              <InputLabel style={{ fontSize: 16, paddingBottom: 10, color: "rgb(70, 81, 142)" }}>Location Wise Kapan Details</InputLabel>
            </Grid>
            <Grid xs={5} item={true}>
              <InputLabel style={{ fontSize: 16, paddingBottom: 10, color: "rgb(70, 81, 142)", textAlign: "end" }}>{`Total PKT : ${kapanDetail.length} |
             CTS:${Tofixed(_.sum(_.map(kapanDetail, 'issueCts')))}`}
              </InputLabel>
            </Grid>
            <TableContainer component={Paper}>
              <Table className={classes.denseTable}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell className="tableCell">Location</StyledTableCell>
                    <StyledTableCell className="tableCell">Packet No</StyledTableCell>
                    <StyledTableCell className="tableCell">Cts</StyledTableCell>
                  </TableRow>
                </TableHead>
              </Table>
              <div className="tbody">
                <Table aria-label="customized table" className={classes.denseTable}>
                  <TableBody>
                    {kapanDetail.map((row, i) => {
                      return <StyledTableRow key={i}>
                        <StyledTableCell className="tableCell">{row.departmentId.deptName}</StyledTableCell>
                        <StyledTableCell className="tableCell">{row.pktNoId.pktNo}</StyledTableCell>
                        <StyledTableCell className="tableCell">{Tofixed(row.issueCts)}</StyledTableCell>
                      </StyledTableRow>
                    }
                    )}
                  </TableBody>
                </Table>
              </div>
            </TableContainer>
          </Grid>

          <Grid xs={4} item container className={classes.fitGrid, classes.pr_5}>
            <Grid xs={7} item={true}>
              <InputLabel style={{ fontSize: 16, paddingBottom: 10, color: "rgb(70, 81, 142)" }}>Extra Top Cts</InputLabel>
            </Grid>
            <Grid xs={5} item={true}>
              <InputLabel style={{ fontSize: 16, paddingBottom: 10, color: "rgb(70, 81, 142)", textAlign: "end" }}>Total CTS :{Tofixed(extracts + failedCts)} </InputLabel>
            </Grid>
            <TableContainer component={Paper}>
              <Table className={classes.denseTable}>
                <TableHead>
                  <TableRow>
                    <StyledTableCell style={{ width: '50%', textAlign: "center" }}>Detail</StyledTableCell>
                    <StyledTableCell style={{ width: '50%', textAlign: "center" }}>Total Cts</StyledTableCell>
                  </TableRow>
                </TableHead>
              </Table>
              <div className="tbody">
                <Table aria-label="customized table" className={classes.denseTable}>
                  <TableBody>
                    <StyledTableRow >
                      <StyledTableCell style={{ width: '50%', textAlign: "center" }}>{"Extra cts"}</StyledTableCell>
                      <StyledTableCell style={{ width: '50%', textAlign: "center" }}>{extracts}</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow >
                      <StyledTableCell style={{ width: '50%', textAlign: "center" }}>{"Failed Cts"}</StyledTableCell>
                      <StyledTableCell style={{ width: '50%', textAlign: "center" }}>{failedCts}</StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </div>
            </TableContainer>
          </Grid>
        </Grid>
      </div >

      <div id="printdivcontent"
        style={{ display: "none" }}
      >
        <div>

          <table className="exportTable" id="table-to-xls" style={{ width: "100%", border: "1px solid", borderCollapse: "collapse" }}>
            <tr>
              <td colSpan={4}><h3 style={{ textAlign: "center" }}>Kapan Wise Total Packets in Proccess {type} Report</h3></td>
            </tr>
            <tbody>
              <tr>
                <th style={{ color: "white", border: "1px solid #ddd", background: "black" }}> {type === "detail" ? "Department Name" : "Lot No"}</th>
                <th style={{ color: "white", border: "1px solid #ddd", background: "black" }}> {type === "detail" ? "Lot No" : "Department Name"}</th>
                <th style={{ color: "white", border: "1px solid #ddd", background: "black" }}> {type === "detail" ? "Pkt No" : "Main Pkts"}</th>
                <th style={{ color: "white", border: "1px solid #ddd", background: "black" }}> {type === "detail" ? "Cts" : "Sub Pkts"}</th>
              </tr>

              {Object.keys(GroupByDept).map((row, i) => {
                if (type === "detail")
                  return GroupByDept[row].map((r, j, key) => {
                    return <tr key={key}>
                      <td style={{ border: "1px solid #ddd", textAlign: "center" }}>{row}  </td>
                      <td style={{ border: "1px solid #ddd", textAlign: "center" }} >{r.lotNoId.lotNo}</td>
                      <td style={{ border: "1px solid #ddd", textAlign: "center" }} >{r.pktNoId.pktNo}</td>
                      <td style={{ border: "1px solid #ddd", textAlign: "center" }} >{r.issueCts}</td>
                    </tr>
                  })
                return (<tr key={i}>
                  <td style={{ border: "1px solid #ddd", textAlign: "center" }} >{GroupByDept[row][i]?.lotNoId?.lotNo || ''}</td>
                  <td style={{ border: "1px solid #ddd", textAlign: "center" }} >{row}</td>
                  <td style={{ border: "1px solid #ddd", textAlign: "center" }} >{_.filter(TotalMainPacket, (d) => d?.departmentId?.deptName === row).length}</td>
                  <td style={{ border: "1px solid #ddd", textAlign: "center" }} >{_.filter(TotalsubPacket, (d) => d?.departmentId?.deptName === row).length}</td>
                </tr>)
              })}
            </tbody>

          </table>
        </div>
      </div>
    </>
  );
}
export default withRouter(MfgKapanStockReport)