import gql from 'graphql-tag';

export const NEWDEPARTMENTISSUERETURN = gql`
    mutation  addNewDeptIssueReturn($input: departmentIssueReturnInput!){
      addNewDeptIssueReturn(input:$input){
        id
        jagadNo
        returnDate
        fromDept
        toDept
        process
        pktType
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
    }
`;
export const DELETEDEPARTMENTISSUERETURNDETAIL = gql`
mutation deleteDepartmentIssueReturnDetail($ids: [ID]){
  deleteDepartmentIssueReturnDetail(ids:$ids)
}
`;