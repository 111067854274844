export const headerStyle = {
  minWidth: 130,
  textAlign: "center"
}
export const cellStyle = {
  minWidth: 130,
  textAlign: "center"
}
export const headerStyle170 = {
  minWidth: 170,
  textAlign: "center"
}
export const cellStyle170 = {
  minWidth: 170,
  textAlign: "center"
}
export const headerStyle172 = {
  minWidth: 172,
  textAlign: "center"
}
export const cellStyle172 = {
  minWidth: 172,
  textAlign: "center"
}
export const headerStyle50 = {
  minWidth: 50,
  textAlign: "center"
}
export const cellStyle50 = {
  minWidth: 50,
  textAlign: "center"
}
export const headerStyle100 = {
  minWidth: 100,
  textAlign: "center"
}
export const cellStyle100 = {
  minWidth: 100,
  textAlign: "center"
}

export const headerStyle150 = {
  minWidth: 150,
  textAlign: "center"
}
export const cellStyle150 = {
  minWidth: 150,
  textAlign: "center"
}