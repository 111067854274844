import gql from 'graphql-tag';

export const SALES1 = gql`
    query getSalesMaster1($filter: String ){
      getSalesMaster1(filter: $filter){
        count
        data{
        id
          currency
          billNo
          invoiceType
          date
          partyId{
            id
            partyName
            street
            road
            city
            mobile1
            phone1
          }
          brokerId{
            id
            partyName
          }
          termId{
            id
            termName
          }
          cashOrCredit
          dueDays
          dueDate
          totalAmount
          zarAmount
          totalCommision
          totalZarAmount
          vatInPer
          vat
          netZarAmt
          createdBy{
            id
            userName
          }
          createdAt
        }
      }
    }
`;
export const SALES = gql`
    query getSalesMaster($page: Int, $limit: Int, $filter: String, $sort: salesMasterSort){
      getSalesMaster(page: $page, limit: $limit, filter: $filter, sort: $sort){
        count
        data{
        id
          currency
          billNo
          invoiceType
          date
          partyId{
            id
            partyName
            street
            road
            city
            mobile1
            phone1
          }
          brokerId{
            id
            partyName
          }
          termId{
            id
            termName
          }
          cashOrCredit
          dueDays
          dueDate
          totalAmount
          zarAmount
          totalCommision
          totalZarAmount
          vatInPer
          vat
          netZarAmt
          createdBy{
            id
            userName
          }
          createdAt
        }
      }
    }
`;
export const SALESDETAILFROMSALESSTOCK = gql`
query salesDetailFromSalesStock($lotNoId: ID, $pktNoId: ID){
  salesDetailFromSalesStock(lotNoId:$lotNoId,pktNoId:$pktNoId){
 id
  lotNoId{
    id
    lotNo
  }
  pktNoId{
    id
    pktNo
  }
 cts
  certificateId
  lab{
    id
    partyName
  }

  colorId{
    id
    shortName
  }
  shapeId{
    id
    shortName
  }
  purityId{
    id
    shortName
  }
  cutId{
    id
    shortName
  }
  polishId{
    id
    shortName
  }
  symmId{
    id
    shortName
  }
  fluId{
    id
    shortName
  }
  rapRate
  stkPer
}
}
`;

export const SALESDETAILFROMROUGHSTOCK = gql`
query salesDetailFromRoughStock($lotNoId: ID, $pktNoId: ID){
  salesDetailFromRoughStock(lotNoId:$lotNoId,pktNoId:$pktNoId){
 id
  lotNoId{
    id
    lotNo
  }
  pktNoId{
    id
    pktNo
  }
 issueCts
  colorId{
    id
    shortName
  }
  shapeId{
    id
    shortName
  }
  purityId{
    id
    shortName
  }
  cutId{
    id
    shortName
  }
  polishId{
    id
    shortName
  }
  symmId{
    id
    shortName
  }
  fluId{
    id
    shortName
  }
  rapRate
}
}
`;

export const SALESDETAIL = gql`
      query getAllSalesDetail($id: ID!){
        getAllSalesDetail(id:$id){
          id
          salesMasterId
          type
          barcode
          lotNoId{
            id
            lotNo
          }
          pktNoId{
            id
            pktNo
          }
          cts
          lab{
            id
            partyName
          }
          certificateNo
          colorId{
            id
            shortName
          }
          shapeId{
            id
            shortName
          }
          purityId{
            id
            shortName
          }
          cutId{
            id
            shortName
          }
          polishId{
            id
            shortName
          }
          symmId{
            id
            shortName
          }
          floId{
            id
            shortName
          }
          rapRate
          rapAmt
          stkDiscPer
          salesDiscPer
          diffPer
          netRaprate
          netRaprateAmt
          rateZar
          amountZar
          commisionPer
          commision
        polishExpPer
        polishExpAmt
        netAmtZar
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
    }
`;
// export const BROKER = gql`
// query getAllBroker{
//   getAllBroker{
//     id
//     partyName
//   }
// }
// `;

