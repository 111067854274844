import React, { useState, useRef, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks'
import MaterialTable from 'material-table';
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TERMS from './TermsQuery';
import { ADD_TERMS, UPDATE_TERMS, DELETE_TERMS } from './TermsMutation';
import { TERMS_SUBSCRIBE } from './TermsSubscription'
import _ from 'lodash'
import { FormatError } from '../../../components/Comman/FormatError'
const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1, },
  noMargin: { margin: 0 }
}));

const columns = [
  {
    title: 'Terms Name*', field: 'termName',
    validate: rowData => (rowData.termName && rowData.termName !== "") ? true : { isValid: false, helperText: `can't be empty` }
  },
  {
    title: 'Days*',
    field: 'Days',
    type: "numeric",
    validate: rowData => (rowData.Days && rowData.Days !== undefined) ? true : { isValid: false, helperText: `can't be empty` }

  },
  { title: 'Alert Days', field: 'alertDays', type: "numeric" },
  { title: 'Status', field: 'isActive', type: "boolean", initialEditValue: true, sorting: false },
]

function Terms(props) {

  const classes = useStyles();

  const tableRef = useRef();

  const [pageSize, setPageSize] = useState(10)
  const [selectedRow, setSelectedRow] = useState(null);
  const [loader, setLoader] = useState(false);

  const permissions = props?.session?.me?.role?.permissions;
  const role = props?.session?.me?.role?.role;
  const path = props?.location?.pathname;
  let permission = _.find(permissions, function (o) { return o.name === path; })

  const { fetchMore, subscribeToMore } = useQuery(TERMS, {
    variables: { page: 1, limit: 10, filter: JSON.stringify({}), sort: { key: 'createdAt', type: -1 } },
    fetchPolicy: 'cache-and-network'
  })

  const [AddTermsMutation] = useMutation(ADD_TERMS)
  const [updateTermsMutation] = useMutation(UPDATE_TERMS)
  const [deleteTermsMutation] = useMutation(DELETE_TERMS)

  useEffect(() => {
    if (subscribeToMore) {
      const unsubscribe = subscribeToMore({
        document: TERMS_SUBSCRIBE,
        updateQuery: (previousResult, { subscriptionData }) => {
          if (!subscriptionData.data) {
            return previousResult;
          }
          tableRef.current && tableRef.current.onQueryChange()
          return previousResult;
        },
      });
      return () => unsubscribe();
    }
  }, [subscribeToMore]);

  const getTermsData = (query) => {
    setLoader(true)
    return new Promise(async (resolve, reject) => {
      let filter = {}, sort = { key: 'createdAt', type: -1 }
      query && query.filters.forEach(item => {
        if (item.column.field === "isActive") {
          if (item.value)
            filter = { ...filter, [item.column.field]: item.value === "checked" ? true : false }
          else {
            delete filter.isActive
            filter = { ...filter }
          }
        }
        else if ((item.column.field === "alertDays" || item.column.field === "Days")) {
          if (item.value)
            filter = { ...filter, [item.column.field]: parseInt(item.value) }
          else {
            delete filter[item.column.field]
            filter = { ...filter }
          }
        }
        else if (item.value.length === 0) {
          delete filter[`${item.column.field}`]
          filter = { ...filter }
        } else
          filter = { ...filter, [item.column.field]: item.value }
      });
      if (query && query.orderBy && query.orderDirection !== "") {
        sort = { key: query.orderBy.field, type: query.orderDirection === 'asc' ? 1 : -1 }
      }
      setPageSize(query.pageSize)
      try {
        let result = await fetchMore({
          variables: { page: query.page + 1, limit: query.pageSize, filter: JSON.stringify(filter), sort: sort },
          fetchPolicy: 'network-only',
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return fetchMoreResult;
          }
        })
        setLoader(false)
        resolve({
          data: result?.data?.getTermMaster?.data || [],
          page: query.page,
          totalCount: result?.data?.getTermMaster?.count || 0,
        })
      } catch (error) {
        console.log(FormatError(error))
        setLoader(false)
      }

    })
  }

  const AddTerms = (newData) => {
    newData.Days = parseInt(newData.Days)
    newData.alertDays = parseInt(newData.alertDays)
    return new Promise((resolve, reject) => {
      setLoader(true)
      newData.alertDays = parseInt(newData?.alertDays || 0)
      if (newData.termName && newData.Days) {
        let log = {
          action: 'INSERT',
          actionOn: 'termMaster',
          actionName: 'addNewTerm',
          oldValue: "",
          message: `${newData?.termName || ''} term was created.`
        }
        AddTermsMutation({ variables: { input: newData, log } }).then((data) => {
          setLoader(false)
          toast.success('terms created successfully.');
          resolve()
        }).catch((e) => {
          setLoader(false)
          toast.warn(FormatError(e))
          reject()
        })
      } else {
        setLoader(false)
        toast.warn('please,fill all required(*) fields.')
        reject()
      }
    })
  }
  const UpdateTerms = (newData, oldData) => {
    delete newData.__typename
    newData.Days = parseInt(newData.Days)
    newData.alertDays = parseInt(newData?.alertDays || 0)
    delete newData.createdBy
    delete newData.updatedBy
    delete newData.createdAt
    delete newData.updatedAt
    return new Promise((resolve, reject) => {
      setLoader(true)
      if (newData.termName && newData.Days) {
        let log = {
          action: 'UPDATE',
          actionOn: 'termMaster',
          actionName: 'updateTerm',
          oldValue: JSON.stringify(oldData),
          message: `${oldData?.termName || ''} term was updated.`
        }
        updateTermsMutation({ variables: { input: newData, log } }).then((data) => {
          toast.success('terms updated successfully.');
          setLoader(false)
          resolve()
        }).catch((e) => {
          setLoader(false)
          reject()
          toast.warn(FormatError(e))
        })
      } else {
        setLoader(false)
        toast.warn('please,fill all required(*) fields.')
        reject()
      }

    })
  }
  const DeleteTerms = (oldData) => {
    return new Promise((resolve, reject) => {
      setLoader(true)
      let log = {
        action: 'DELETE',
        actionOn: 'termMaster',
        actionName: 'deleteTerm',
        oldValue: JSON.stringify(oldData),
        message: `${oldData?.termName || ''} term was deleted.`
      }
      deleteTermsMutation({ variables: { id: oldData.id, log } }).then((data) => {
        toast.success('terms deleted successfully.');
        setLoader(false)
        resolve()
      }).catch((e) => {
        setLoader(false)
        toast.warn(FormatError(e))
        reject()
      })
    })
  }

  const addButton = (val) => {
    if (val || (role === 'admin'))
      return { onRowAdd: (newData) => AddTerms(newData) }
    else
      return {}
  }
  useEffect(() => {
    document.getElementsByClassName("parentTable")[0].firstElementChild.firstElementChild.firstChild.remove()
  }, [])
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <div className="parentTable">
            <MaterialTable
              title="Terms"
              tableRef={tableRef}
              columns={columns}
              isLoading={loader}
              data={query => getTermsData(query)}
              icons={{ Add: props => { return (<div> <i className="fa fa-plus-square" ></i> Terms </div>) } }}
              options={{
                emptyRowsWhenPaging: false,
                debounceInterval: 300,
                padding: 'dense',
                showTitle: false,
                pageSize: pageSize,
                pageSizeOptions: [5, 10, 15, 20, 25, 50],
                addRowPosition: 'first',
                filtering: true,
                sorting: true,
                search: false,
                filterCellStyle: { padding: '5px', },
                maxBodyHeight: window.innerHeight - 215,
                rowStyle: rowData => ({
                  backgroundColor: (selectedRow === rowData.tableData.id) ? 'rgb(212, 218, 255)' : '#f5f5f5'
                }),
                headerStyle: { backgroundColor: 'whitesmoke' }
              }}
              editable={{
                isEditHidden: (rowData) => (role === 'admin') ? false : !permission.isUpdate,
                isDeleteHidden: (rowData) => (role === 'admin') ? false : !permission.isDelete,
                ...addButton(permission?.isInsert),
                onRowUpdate: (newData, oldData) => UpdateTerms(newData, oldData),
                onRowDelete: (oldData) => DeleteTerms(oldData)
              }}
              onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}


export default withRouter(Terms)