import { useQuery } from '@apollo/react-hooks'
import { GETUSERFORLOOKUP } from '../../../pages/Master/Users/UserQuery';
import { FormatError } from '../../Comman/FormatError';
export const UserListAllData = () => {
  const { error, data } = useQuery(GETUSERFORLOOKUP, {
    fetchPolicy: 'cache-and-network',
  })
  if (error)
    console.log(FormatError(error));
  let Termsnewdata = data?.getUserForLookup || []
  return Termsnewdata
}

export const UserNameLookup = () => {
  let newtermsdata = {}
  UserListAllData().map((d, index) => {
    newtermsdata[d.id] = d.userName
    return null
  })
  return newtermsdata
}
