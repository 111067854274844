import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { LOTDATA, ROUGHLOTDATA, MAJURILOTDATA } from '../../../pages/Transaction/Packet/PacketQuery';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import _ from 'lodash'
import { LOTNOWITHPARTY } from '../../../pages/Transaction/Packet/PacketQuery';
import { FormatError } from '../../Comman/FormatError'

export const LotNoWithPartys = (props) => {

  const { error, data } = useQuery(LOTNOWITHPARTY, {
    variables: { id: props?.id ,type : props?.type,issuereturnMasterId:props?.issuereturnMasterId,isRoughStockDetail: props?.isRoughStockDetail},
    fetchPolicy: 'cache-and-network',
  })
  if (error)
    console.log(FormatError(error));
  let LotNoWithPartydata = data?.getLotNoWithParty || []
  return LotNoWithPartydata
}

export const LotNoData = (props) => {
  const { error, data } = useQuery(LOTDATA, {
    variables:{type: props?.type},
    fetchPolicy: 'cache-and-network',
  
  })
  if (error)
    console.log(FormatError(error));
  let Lortdata = data?.getAllKapanDetail || []
  return Lortdata
}

export const RoughLotNoData = (ids) => {
  const { error, data } = useQuery(ROUGHLOTDATA, {
    fetchPolicy: 'cache-and-network',
  })
  if (error)
    console.log(FormatError(error));
  let Lortdata = data?.getAllRoughKapan || []
  return Lortdata
}

export const MajuriLotNoWithPartys = (id) => {
  const { error, data } = useQuery(MAJURILOTDATA, {
    variables: { id: id },
    fetchPolicy: 'cache-and-network',
  })
  if (error)
    console.log(FormatError(error));
  let LotNoWithPartydata = data?.getAllMajuriKapan || []
  return LotNoWithPartydata
}

export const LotNoLookup = () => {
  const Lortdata = LotNoData()
  let lotNodata = {}
  Lortdata.map((d, index) => {
    lotNodata[d.id] = d.lotNo
    return null
  })
  return lotNodata
}

export const LotNoSelectComponent = (props) => {
  let Lortdata = []
  if (props.from === 'costing') {
    if (props?.PartyName === 'KISMET MFG')
      Lortdata = RoughLotNoData()
    else
      // Lortdata = LotNoWithPartys(props.PartyId ,props?.type ,props?.kapanMasterId)
      Lortdata = LotNoWithPartys({
        id: props.PartyId,
        type: props?.type,
        issuereturnMasterId: props?.issuereturnMasterId,
        isRoughStockDetail:props?.isRoughStockDetail
      })
    // Lortdata = MajuriLotNoWithPartys(props.PartyId)
  } else {
    if (props.PartyId)
      // Lortdata = LotNoWithPartys(props.PartyId, props?.type,props?.kapanMasterId)
      Lortdata = LotNoWithPartys({
        id: props.PartyId,
        type: props?.type,
        issuereturnMasterId: props?.issuereturnMasterId,
        isRoughStockDetail:props?.isRoughStockDetail
      })
    else
      Lortdata = LotNoData({ type: props?.propsData?.rowData?.type})
  }
  Lortdata = _.filter(Lortdata, (d) => d.lotNo !== null)
  const changeselect = (e, newValue) => {
    let Data = { ...props.propsData.rowData }
    if (props.TYPE) {
      Data.totalPcs = newValue.pcs
      Data.totalCts = newValue.cts
    }
    if (props.field === "lotNoId") {
      Data.lotNoId = newValue.id
    } else {
      Data.lotNo = newValue.id
    }
    props.propsData.onRowDataChange(Data);
  }
  const defaultProps = {
    options: Lortdata,
    getOptionLabel: (option) => option.lotNo
  };
  return <Autocomplete
    {...defaultProps}
    disableClearable
    onChange={(e, newValue) => changeselect(e, newValue)}
    defaultValue={_.filter(Lortdata, (newd) => newd.id === props.valueDefault)[0]}
    renderInput={(params) => <TextField   {...params} margin="normal" />}
  />
}