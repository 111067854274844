import React, { useRef, useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { withRouter } from "react-router-dom";
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SHORTCODE } from './SortCodeQuery'
import { ADD_SHORTCODE, UPDATE_SHORTCODE, DELETE_SHORTCODE } from './ShortCodeMutation'
import { SHORT_SUBSCRIBE } from './ShortCodeSubscription'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { toast } from 'react-toastify';
import { ShortType } from '../../../components/Constant/index'
import _ from 'lodash'
import { FormatError } from '../../../components/Comman/FormatError'
const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1, },
  noMargin: { margin: 0 }
}));

const columns = [
  { title: 'Type*', field: 'type', lookup: ShortType },
  { title: 'Shortcode*', field: 'shortName' },
  { title: 'Rapnet*', field: 'fullName' },
]
function ShortCode(props) {
  const classes = useStyles();
  const tableRef = useRef();
  const [loader, setLoader] = useState(false);
  const [pageSize, setPageSize] = useState(10)
  const [selectedRow, setSelectedRow] = useState(null);
  const permissions = props?.session?.me?.role?.permissions;
  const role = props?.session?.me?.role?.role;
  const path = props?.location?.pathname;
  let permission = _.find(permissions, function (o) { return o.name === path; })

  const { fetchMore, subscribeToMore } = useQuery(SHORTCODE, {
    variables: { page: 1, limit: 10, filter: JSON.stringify({}), sort: { key: 'createdAt', type: -1 } },
    fetchPolicy: 'cache-and-network',
  })


  const [AddShortCodeMutation] = useMutation(ADD_SHORTCODE)
  const [updateShortCodeMutation] = useMutation(UPDATE_SHORTCODE)
  const [deleteShortCodeMutation] = useMutation(DELETE_SHORTCODE)


  const AddShortCode = (newData) => {
    return new Promise((resolve, reject) => {
      setLoader(true)
      if (newData.shortName && newData.fullName && newData.type) {
        AddShortCodeMutation({ variables: { input: newData } }).then((data) => {
          setLoader(false)
          toast.success('shortcode created successfully.');
          resolve()
        }).catch((e) => {
          setLoader(false)
          reject()
          toast.warn(FormatError(e))
        })
      } else {
        setLoader(false)
        toast.warn('please,fill all required(*) fields.')
        reject()
      }
    })
  }

  const UpdateShortCode = (newData, oldData) => {
    return new Promise((resolve, reject) => {
      if (newData.shortName && newData.fullName && newData.type) {
        setLoader(true)
        delete newData.createdAt
        delete newData.createdBy
        delete newData.updatedAt
        delete newData.updatedBy
        delete newData.__typename
        updateShortCodeMutation({ variables: { input: newData } }).then((data) => {
          setLoader(false)
          toast.success('shortcode updated successfully.');
          resolve()
        }).catch((e) => {
          setLoader(false)
          reject()
          toast.warn(FormatError(e))
        })
      } else {
        setLoader(false)
        toast.warn('please,fill all required(*) fields.')
        reject()
      }
    })
  }

  const DeleteShortCode = (oldData) => {
    return new Promise((resolve, reject) => {
      setLoader(true)
      deleteShortCodeMutation({ variables: { id: oldData.id } }).then((data) => {
        setLoader(false)
        toast.success('shortcode deleted successfully.');
        resolve()
      }).catch((e) => {
        setLoader(false)
        reject()
        toast.warn(FormatError(e))
      })
      resolve()
    })
  }
  const getShortCodeData = (query) => {
    setLoader(true)
    return new Promise(async (resolve, reject) => {
      let filter = {}, sort = { key: 'createdAt', type: -1 }
      query && query.filters.forEach(item => {
        if (item.value.length === 0) {
          delete filter[`${item.column.field}`]
          filter = { ...filter }
        } else
          filter = { ...filter, [item.column.field]: item.value }
      });
      if (query && query.orderBy && query.orderDirection !== "") {
        sort = { key: query.orderBy.field, type: query.orderDirection === 'asc' ? 1 : -1 }
      }
      setPageSize(query.pageSize)
      try {
        let result = await fetchMore({
          variables: { page: query.page + 1, limit: query.pageSize, filter: JSON.stringify(filter), sort: sort },
          fetchPolicy: 'network-only',
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return fetchMoreResult;
          }
        })
        setLoader(false)
        resolve({
          data: result?.data?.getShortCode?.data || [],
          page: query.page,
          totalCount: result?.data?.getShortCode?.count || 0,
        })
      } catch (error) {
        console.log(FormatError(error))
        setLoader(false)
      }
    })
  }
  useEffect(() => {
    if (subscribeToMore) {
      const unsubscribe = subscribeToMore({
        document: SHORT_SUBSCRIBE,
        updateQuery: (previousResult, { subscriptionData }) => {
          if (!subscriptionData.data) {
            return previousResult;
          }
          tableRef.current && tableRef.current.onQueryChange()
          return previousResult;
        },
      });
      return () => unsubscribe();
    }
  }, [subscribeToMore]);

  const addButton = (val) => {
    if (val || (role === 'admin'))
      return { onRowAdd: (newData) => AddShortCode(newData) }
    else
      return {}
  }

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <MaterialTable
            title="Short Code"
            tableRef={tableRef}
            columns={columns}
            isLoading={loader}
            data={query => getShortCodeData(query)}
            options={{
              emptyRowsWhenPaging: false,
              padding: 'dense',
              debounceInterval: 300,
              pageSize: pageSize,
              pageSizeOptions: [5, 10, 15, 20, 25, 50],
              addRowPosition: 'first',
              filtering: true,
              sorting: true,
              search: false,
              filterCellStyle: { padding: '5px', },
              maxBodyHeight: window.innerHeight - 215,
              rowStyle: rowData => ({
                backgroundColor: (selectedRow === rowData.tableData.id) ? 'rgb(212, 218, 255)' : '#f5f5f5'
              }),
              headerStyle: { backgroundColor: 'whitesmoke' }
            }}
            editable={{
              isEditHidden: (rowData) => (role === 'admin') ? false : !permission.isUpdate,
              isDeleteHidden: (rowData) => (role === 'admin') ? false : !permission.isDelete,
              ...addButton(permission?.isInsert),
              onRowUpdate: (newData, oldData) => UpdateShortCode(newData, oldData),
              onRowDelete: (oldData) => DeleteShortCode(oldData)
            }}
            // onChangeRowsPerPage={size => setPageSize(size)}
            // onChangePage={page => { setPage(page) }}
            onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
          // components={{
          //   FilterRow: props => {

          //     return <MTableFilterRow
          //       {...props}
          //       columns={props.columns}
          //       onFilterChanged={(columnId, value) => {
          //         props.onFilterChanged(columnId, value);
          //         generateFilterString(props.columns[columnId].field, value)
          //       }}
          //     />
          //   },
          //   Header: props => {
          //     return <MTableHeader
          //       {...props}
          //       orderBy={orderBy}
          //       orderDirection={orderDirection}
          //       onOrderChange={(orderBy, orderDirection) => {
          //         props.onOrderChange(orderBy, orderDirection)
          //         setSorting(orderBy, orderDirection)
          //       }}
          //     />
          //   }
          // }}
          />
        </Grid>
      </Grid>
    </div >
  );
}


export default withRouter(ShortCode)
