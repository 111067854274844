import React from 'react'
import { Grid, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash'
import TableContainer from '@material-ui/core/TableContainer';
import { withStyles } from '@material-ui/core/styles';
import { Table } from '@material-ui/core';

import Paper from '@material-ui/core/Paper';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Tofixed } from '../../../../components/Comman/Tofix'
import { AutoSizer, List, CellMeasurer, CellMeasurerCache } from 'react-virtualized';
const useStyles = makeStyles(() => ({
    AccountTableCss: {
        "& .MuiToolbar-regular": {
            minHeight: 0
        }
    },
    LabelStart: {
        fontSize: 16,
        paddingBottom: 10,
        color: "rgb(70, 81, 142)"
    },
    Labelend: {
        fontSize: 16,
        paddingBottom: 10,
        color: "rgb(70, 81, 142)",
        textAlign: "end"
    },
    denseTable: {
        '& td': { padding: "2px 5px", cursor: "pointer", textAlign: "center" },
        '& th': { padding: "5px 5px", cursor: "pointer", textAlign: "center" }
    },
}))
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#8CB836",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);
export const DepartmentWiseStockPage = ({ data, loading, setFillter }) => {
    const classes = useStyles();
    const cache = React.useRef(
        new CellMeasurerCache({
            fixedWidth: true,
            defaultHeight: 100
        })
    )
    const ListRef = React.useRef();
    let TableData = data?.getDepartmentWiseDatForLiveScreen || []
    return <Grid item={true} xs={4} container alignContent="space-between" style={{ float: "left", paddingRight: 5  }}>
        <Grid xs={12} item container>
            <Grid xs={5} item={true}>
                <InputLabel className={classes.LabelStart}>Total Packets in Process</InputLabel>
            </Grid>
            <Grid xs={7} item={true}>
                <InputLabel className={classes.Labelend}>{`Tot Dept: ${TableData?.length || 0} | Cts: ${Tofixed(_.sumBy(TableData || [], (d) => d.totalCts))}`}</InputLabel>
            </Grid>
        </Grid>
        <Grid xs={12} item className={`${classes.AccountTableCss} DenseTable`}>
            <TableContainer component={Paper}>
                <Table className={classes.denseTable}>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell style={{ width: '33.33%', textAlign: "center" }}>Department Name</StyledTableCell>
                            <StyledTableCell style={{ width: '33.33%', textAlign: "center" }}>total Pkt</StyledTableCell>
                            <StyledTableCell style={{ width: '33.33%', textAlign: "center" }}>Total Cts</StyledTableCell>
                        </TableRow>
                    </TableHead>
                </Table>
                <div style={{ width: "100%", height: 200, marginBottom: 10 }}>
                    <AutoSizer>
                        {({ width, height }) => {
                            return <List
                                ref={ListRef}
                                height={height}
                                width={width}
                                rowHeight={20}
                                deferredMeasurementCache={cache.current}
                                rowCount={TableData.length}
                                rowRenderer={({ key, index, style, parent }) => {
                                    const rowData = TableData[index]
                                    return <CellMeasurer key={key} cache={cache.current} parent={parent} columnIndex={0} rowIndex={index} >
                                        <div style={style}>
                                            <div className="tbody"  >
                                                <Table style={{ cursor: "pointer" }}>
                                                    <TableBody onClick={() => setFillter(rowData.deptName)} >
                                                        <StyledTableRow >
                                                            <StyledTableCell style={{ textAlign: "center", width: "33.33%" }}>{rowData?.deptName?.deptName || ""}</StyledTableCell>
                                                            <StyledTableCell style={{ textAlign: "center", width: "33.33%" }} >{rowData?.totalPkt || ""}</StyledTableCell>
                                                            <StyledTableCell style={{ textAlign: "center", width: "33.33%" }}>{Tofixed(rowData?.totalCts || 0)}</StyledTableCell>
                                                        </StyledTableRow>
                                                    </TableBody>
                                                </Table>
                                            </div>
                                        </div>
                                    </CellMeasurer>
                                }}
                            />
                        }}
                    </AutoSizer>
                </div>
            </TableContainer>
        </Grid>
    </Grid>
}