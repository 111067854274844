import gql from 'graphql-tag';

export const PROCESS_SUBSCRIBE = gql`
  subscription ProcessChange {
    ProcessChange {
      keyType
      data{
        id
        PROCCODE
        procName
        isActive
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
    }
  }
`;
