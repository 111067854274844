import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { useQuery } from '@apollo/react-hooks'
import { Tofixed } from '../../../../components/Comman/Tofix'

import { GETALLROUGHSTOCK, GETROUGHSTOCKFOREXTRATOPS } from '../LiveScreenQuery';

export const PrintPdf = () => {
    const [PdfData, setPdfData] = useState([])

    const { data } = useQuery(GETALLROUGHSTOCK, {
        fetchPolicy: 'cache-and-network'
    })
    const { data: ExtatopsData } = useQuery(GETROUGHSTOCKFOREXTRATOPS, {
        variables: { pktType: "EXTRA TOPS" },
        fetchPolicy: 'cache-and-network',
    })

    useEffect(() => {
        const DepartmentGroupBy = _.groupBy(data?.getAllRoughStock || [], 'departmentId.deptName')
        let UnissueStock = _.filter(data?.getAllRoughStock || [], (stock) => stock?.process?.procName === "UNISSUE");
        if (UnissueStock.length !== 0)
            DepartmentGroupBy["UNISSUE"] = UnissueStock
        let kachuData = _.filter(data?.getAllRoughStock || [], (stock) => stock?.pktType === "KACHU");
        if (kachuData.length !== 0)
            DepartmentGroupBy["KACHU"] = kachuData

        if (DepartmentGroupBy.undefined) {
            delete DepartmentGroupBy.undefined
        }
        setPdfData(DepartmentGroupBy)
    }, [data])

    useEffect(() => {
        const DepartmentGroupBy = _.cloneDeep(PdfData)
        let Data = ExtatopsData?.getExtraTops || []
        if (Data.length !== 0)
            DepartmentGroupBy["EXTRATOPS"] = Data
        setPdfData(DepartmentGroupBy)
    }, [ExtatopsData])

    const GetPdfData = (Data) => {
        let a = _.cloneDeep(PdfData)
        let temp = a[Data] ? a[Data] : []
        if (Data === "EXTRATOPS") {
            temp = temp.map(d => {
                d.issueCts = d.cts
                return d
            })
        }
        let total = _.sumBy(temp, (d) => d.issueCts)
        temp[temp.length] = {
            pktNoId: {
                pktNo: "Total"
            },
            issueCts: total
        }
        return temp
    }
    return <div id="printdivcontent" style={{ display: "none" }} >
        <div style={{ width: "100%", display: "inline-block" }}>
            {Object.keys(PdfData).map((d, i) => {
                if (d !== undefined) {
                    return <div key={i} style={{ width: "20%", display: "inline-block" }}>
                        <h3 style={{ margin: "0px 2px 0px 0px", background: "black", color: "white", textAlign: "center" }}>{d}</h3>
                        <table className="exportTable" style={{ width: "100%", border: "1px solid", borderCollapse: "collapse" }}>
                            <tbody>
                                <tr>
                                    <th style={{ border: "1px solid #ddd", textAlign: "left" }}>{"lot No"}  </th>
                                    <th style={{ border: "1px solid #ddd", textAlign: "left" }}>{"packets"}  </th>
                                    <th style={{ border: "1px solid #ddd", textAlign: "left" }}>{"cts"}  </th>
                                </tr>
                                {GetPdfData(d).map((newD, key) => {
                                    return <tr key={key} style={newD?.pktNoId?.pktNo.substring(0, 5) === "Total" ? { textAlign: "center", background: "aqua" } : { textAlign: "center" }}>
                                        <td style={newD?.lotNoId?.lotNo.substring(0, 5) === "Total" ? { textAlign: "center" } : { border: "1px solid #ddd", textAlign: "left" }}>{newD?.lotNoId?.lotNo || ""}  </td>
                                        <td style={newD?.pktNoId?.pktNo.substring(0, 5) === "Total" ? { textAlign: "center" } : { border: "1px solid #ddd", textAlign: "left" }}>{newD?.pktNoId?.pktNo || ""}  </td>
                                        <td style={{ border: "1px solid #ddd", textAlign: "left" }}> {Tofixed(newD?.issueCts || 0)}  </td>
                                    </tr>
                                })}
                            </tbody>
                        </table>
                    </div>
                }
            })}

        </div>
    </div >
}