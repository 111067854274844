import React from 'react'
import { ALLDEPARTMENT } from '../../../pages/Master/Department/DepartmentQuery'
import { useQuery } from '@apollo/react-hooks'
import _ from 'lodash'
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FormatError } from '../../Comman/FormatError'

export const DepartmentAllData = () => {
  const { error, data } = useQuery(ALLDEPARTMENT, {
    fetchPolicy: 'cache-and-network',
  })
  if (error)
    console.log(FormatError(error));

  let Data = data?.getAllDepartment || []
  return Data
}

export const DepartmentLookup = () => {
  let Data = DepartmentAllData()
  let NewDepartment = {}
  _.map(Data, (d) => {
    NewDepartment[d.id] = d.deptName
    return null
  })
  return NewDepartment
}

export const DepartmentSelectComponent = (props) => {
  let Data = DepartmentAllData()
  const defaultProps = {
    options: Data,
    getOptionLabel: (option) => option.deptName
  };
  return <Autocomplete
    {...defaultProps}
    onChange={(e, newValue) => {
      let propsData = props.propsData
      let Data = { ...propsData.rowData }
      Data[props.field] = newValue.id
      propsData.onRowDataChange(Data)
    }}
    defaultValue={_.filter(Data, (newd) => newd.id === props.valueDefault)[0]}
    renderInput={(params) => <TextField  {...params} margin="normal" />}
  />

}
