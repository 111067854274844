import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import SaveIcon from '@material-ui/icons/Save';
import { forms } from '../../../components/Constant'

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    display: 'flex'
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  table: {
    minWidth: 650,
  },
  tableContainer: {
    overflow: 'unset',
    // marginLeft: 150,
    width: 'auto',
    maxHeight: 215,
    overflowY: 'auto'
  }
});


export default function RoleChild(props) {
  const classes = useStyles();

  const createChildData = () => {
    let data = []
    forms.forEach(form => {
      data.push({ name: form, isView: false, isInsert: false, isUpdate: false, isDelete: false })
    });
    return data
  }

  const [childData, setChildData] = useState((props.rowData.permissions && props.rowData.permissions.length > 0) ? props.rowData.permissions : createChildData())
  const onRoleSave = () => {
    props.onSavePermissions(props.rowData, childData, props?.rowData?.role)
  }
  const onCheckboxClick = (val, name, type) => {
    let newData = childData.map((item) => {
      if (item.name === name) {
        return { ...item, [type]: val }
      } else
        return item
    })
    setChildData(newData)
  }


  return (
    <Grid className={classes.root}>
      <Grid item xs={1} className={classes.center}>
        <IconButton color="primary" aria-label="Save" component="span" onClick={onRoleSave} >
          <SaveIcon />
        </IconButton>
      </Grid>
      <Grid item xs={11}>
        <TableContainer component={Paper} className={classes.tableContainer}>
          <Table stickyHeader className={classes.table} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Form Name</TableCell>
                <TableCell>View</TableCell>
                <TableCell>Insert</TableCell>
                <TableCell>Update</TableCell>
                <TableCell>Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {childData.map((row, key) => (
                <TableRow key={key}>
                  <TableCell component="th" scope="row"> {row.name} </TableCell>
                  {['isView', 'isInsert', 'isUpdate', 'isDelete'].map((k, key) => {
                    return (
                      <TableCell key={key} >
                        <Checkbox
                          defaultChecked={false}
                          color="primary"
                          inputProps={{ 'aria-label': 'checkbox' }}
                          style={{ padding: 0 }}
                          checked={row[k]}
                          onChange={(e) => onCheckboxClick(e.target.checked, row.name, k)}
                        />
                      </TableCell>
                    )
                  })
                  }
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid >
  );
}

