import React, { useRef, useEffect } from 'react';
import MaterialTable from 'material-table';
import { withRouter } from "react-router-dom";
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { REPAPORT, PRICE_LIST } from './RapaPortUserQuery'
import { UPDATE_RAPAPORT, DELETE_RAPAPORT, UPDATE_PRICELIST } from './RapaPortUserMutation'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { toast } from 'react-toastify';
import _ from 'lodash'
import { RAPAPORT_SUBSCRIBE } from './RapaPortUserSubscription'
import moment from 'moment';
const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1, },
  noMargin: { margin: 0 }
}));

const columns = [
  { title: 'Rapaport UserName', filtering: false, field: 'rapaportUsername' },
  {
    title: 'Password', filtering: false, field: 'password',
    render: (rowData) => {
      let data = []
      let l = (rowData.password).length;
      for (let index = 0; index < l; index++) {
        data.push('.')
      }
      return <label style={{ fontSize: 25 }}> {data.join("")}</label>
    },
  },
]
const columns2 = [
  { title: 'Date', field: 'date' },
  { title: 'Shape', field: 'shape' },
  { title: 'Low Size', field: 'low_size' },
  { title: 'High Size', field: 'high_size' },
  { title: 'Color', field: 'color' },
  { title: 'Clarity', field: 'clarity' },
  { title: 'Price', field: 'caratprice' },

]
function Rapaport(props) {
  const classes = useStyles();
  const tableRef = useRef();
  const table1Ref = useRef();
  const permissions = props?.session?.me?.role?.permissions;
  const role = props?.session?.me?.role?.role;
  const path = props?.location?.pathname;
  let permission = _.find(permissions, function (o) { return o.name === path; })

  const { error, data, subscribeToMore, loading } = useQuery(REPAPORT, {
    variables: { page: 1, limit: 5, filter: JSON.stringify({}), sort: { key: 'createdAt', type: -1 } },
    fetchPolicy: 'cache-and-network',
  })

  const { loading: priceLoader, data: priceList, refetch } = useQuery(PRICE_LIST, {
    fetchPolicy: 'cache-and-network',
  })

  const pricelistData = priceList?.getRapaportPriceList || []

  const [updateRepaPortMutation] = useMutation(UPDATE_RAPAPORT)
  const [deleteRapaportMutation] = useMutation(DELETE_RAPAPORT)
  const [updatePriceListMutation] = useMutation(UPDATE_PRICELIST)

  const UpdateRapaPort = (newData, oldData) => {
    return new Promise((resolve, reject) => {
      resolve()
      delete newData.createdAt
      delete newData.createdBy
      delete newData.updatedAt
      delete newData.updatedBy
      delete newData.__typename
      updateRepaPortMutation({ variables: { input: newData } }).then((data) => {
        toast.success('Rapaport updated successfully.');
        resolve()
      }).catch((e) => {
        reject()
        toast.warn('Please,fill all required(*) fields.')
      })
    })
  }

  const DeleteShortCode = (oldData) => {
    return new Promise((resolve, reject) => {
      deleteRapaportMutation({ variables: { id: oldData.id } }).then((data) => {
        toast.success('Rapaport deleted successfully.');
        resolve()
      }).catch((e) => {
        reject()
        toast.error('err -', e)
      })
      resolve()
    })
  }

  const getRapaportUserData = () => {
    return new Promise((resolve, reject) => {
      if (error)
        reject(error)
      let result = data?.getRapaportUser?.data || []
      resolve({
        data: result,
        page: 0,
        totalCount: data?.getRapaportUser?.count || 0,
      })
    })
  }
  const CallRepaportFun = () => {
    if (window.confirm("Are you sure?")) {
      return new Promise((resolve, reject) => {
        updatePriceListMutation().then((data) => {
          toast.success('Price List Updated successfully.');
          resolve()
          refetch()
        }).catch((e) => {
          reject()
          toast.error(e.message)
        })
      })
    }
  }
  const updatePriceList = () => {
    let Data = priceList.getRapaportPriceList || []
    if (Data.length > 0) {
      if (moment(Data[0].date).format('DD-MM-YYYY') === moment().day(-2).format('DD-MM-YYYY')) {
        toast.success('Price already upto Date ');
      } else {
        CallRepaportFun()
      }
    } else {
      CallRepaportFun()
    }
  }

  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: RAPAPORT_SUBSCRIBE,
      updateQuery: (previousResult, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return previousResult;
        }
        const { RapaportUserChange } = subscriptionData.data;
        if (RapaportUserChange && RapaportUserChange.keyType === "UPDATE") {
          let updateRapaportData = RapaportUserChange && RapaportUserChange.data;
          let AllRapaportData = previousResult.getRapaportUser.data;
          let updatedRapaportData = _.map(AllRapaportData, (d) => {
            if (d.id === updateRapaportData.id) {
              return updateRapaportData
            }
            else return d
          })
          return {
            ...previousResult,
            getRapaportUser: {
              ...previousResult.getRapaportUser,
              count: previousResult.getRapaportUser.count,
              data: [
                ...updatedRapaportData
              ]
            }
          }
        } else if (RapaportUserChange && RapaportUserChange.keyType === "DELETE") {
          let updateRapaportData = RapaportUserChange && RapaportUserChange.data;
          let AllRapaportData = previousResult.getRapaportUser.data;
          let updatedRapaportData = _.reject(AllRapaportData, d => {
            return d.id === updateRapaportData.id
          })
          return {
            ...previousResult,
            getRapaportUser: {
              ...previousResult.getRapaportUser,
              count: parseInt(previousResult.getRapaportUser.count) - 1,
              data: [...updatedRapaportData]
            }
          }
        }
      },
    });
    return () => unsubscribe();
  }, [subscribeToMore]);

  useEffect(() => {
    tableRef.current && tableRef.current.onQueryChange()
  }, [data]);


  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <MaterialTable
            title="Rapaport"
            tableRef={tableRef}
            columns={columns}
            isLoading={loading}
            data={query => getRapaportUserData()}
            options={{
              emptyRowsWhenPaging: false,
              padding: 'dense',
              filtering: false,
              sorting: false,
              search: false,
              paging: false,
              filterCellStyle: { padding: '5px', },
              maxBodyHeight: 215,
              headerStyle: { backgroundColor: 'whitesmoke' }
            }}
            editable={{
              isEditHidden: (rowData) => (role === 'admin') ? false : !permission.isUpdate,
              isDeleteHidden: (rowData) => (role === 'admin') ? false : !permission.isDelete,
              onRowUpdate: (newData, oldData) => UpdateRapaPort(newData, oldData),
              onRowDelete: (oldData) => DeleteShortCode(oldData)
            }}
          />
        </Grid>

        <Grid item xs={12} style={{ paddingTop: '20px' }}>
          <MaterialTable
            title="Rapaport Price List"
            tableRef={table1Ref}
            columns={columns2}
            loading={priceLoader}
            data={pricelistData}
            options={{
              emptyRowsWhenPaging: false,
              padding: 'dense',
              filtering: true,
              sorting: true,
              search: false,
              paging: true,
              pageSize: 50,
              pageSizeOptions: [10, 20, 30, 50, 100],
              filterCellStyle: { padding: '5px', },
              maxBodyHeight: window.innerHeight - 400,
              headerStyle: { backgroundColor: 'whitesmoke' }
            }}
            actions={[
              {
                icon: 'update',
                tooltip: 'Update Price List',
                isFreeAction: true,
                onClick: (event) => updatePriceList()
              }
            ]}
          />
        </Grid>
      </Grid>
    </div >
  );
}

export default withRouter(Rapaport)