import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks'
import MaterialTable from 'material-table';
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import { Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import COMPANY from './CompanyQuery';
import { ADD_COMPANY, UPDATE_COMPANY, DELETE_COMPANY } from './CompanyMutation';
import { COMPANY_SUBSCRIBE } from './CompanySubscription'
import { ValidateEmail, ValidateMobile, ValidateStringWithNumber } from '../../../components/Comman/validate.js'
import { toString } from 'lodash';
import _ from 'lodash';
import { FormatError } from '../../../components/Comman/FormatError'
import { headerStyle100, cellStyle100 } from '../../../components/Comman/StyleCell';

const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1, },
  noMargin: { margin: 0 }
}));
const columns = [
  {
    title: 'Status',
    field: 'isActive',
    type: "boolean",
    initialEditValue: true,
    sorting: false
  },
  {
    title: 'Company Name*',
    field: 'companyName',
    validate: rowData => (rowData.companyName && rowData.companyName !== "") ? true : { isValid: false, helperText: `can't be empty` },
    headerStyle: headerStyle100,
    cellStyle: cellStyle100
  },
  {
    title: 'Short Name*',
    field: 'shortName',
    validate: rowData => (rowData.shortName && rowData.shortName !== "") ? true : { isValid: false, helperText: `can't be empty` },
    headerStyle: headerStyle100,
    cellStyle: cellStyle100
  },
  {
    title: 'Contact Name',
    field: 'contactName',
    headerStyle: headerStyle100,
    cellStyle: cellStyle100
  },
  {
    title: 'Email*',
    field: 'email',
    headerStyle: headerStyle100,
    cellStyle: cellStyle100,
    validate: rowData => {
      if (rowData.email && rowData.email !== "")
        return ValidateEmail(rowData.email) ? true : { isValid: false, helperText: `email is not valid` }
      else
        return { isValid: false, helperText: `can't be empty` }
    }
  },
  {
    title: 'Website*',
    field: 'website',
    validate: rowData => (rowData.website && rowData.website !== "") ? true : { isValid: false, helperText: `can't be empty` },
    headerStyle: headerStyle100,
    cellStyle: cellStyle100
  },
  {
    title: 'Address(1)',
    field: 'address1', headerStyle: headerStyle100,
    cellStyle: cellStyle100
  },
  {
    title: 'Address(2)',
    field: 'address2',
    headerStyle: headerStyle100,
    cellStyle: cellStyle100
  },
  {
    title: 'Address(3)',
    field: 'address3',
    headerStyle: headerStyle100,
    cellStyle: cellStyle100
  },
  {
    title: 'City',
    field: 'city',
    headerStyle: headerStyle100,
    cellStyle: cellStyle100
  },
  {
    title: 'Pin*',
    field: 'pinCode',
    validate: rowData => {
      if (rowData.pinCode && rowData.pinCode !== "") {
        return ValidateStringWithNumber(rowData.pinCode) ? true : { isValid: false, helperText: `pincode is not valid` }
      }
      else {
        return { isValid: false, helperText: `can't be empty` }
      }
    }
    , headerStyle: headerStyle100,
    cellStyle: cellStyle100
  },
  {
    title: 'State',
    field: 'state',
    headerStyle: headerStyle100,
    cellStyle: cellStyle100
  },
  {
    title: 'Country',
    field: 'country',
    headerStyle: headerStyle100,
    cellStyle: cellStyle100
  },
  {
    title: 'Phone(1)',
    field: 'phone1',
    validate: rowData => {
      if (rowData.phone1 && rowData.phone1 !== "") {
        return ValidateStringWithNumber(rowData.phone1) ? true : { isValid: false, helperText: `phone number is not valid` }
      } else
        return true
    },
    headerStyle: headerStyle100,
    cellStyle: cellStyle100
  },
  {
    title: 'Phone(2)',
    field: 'phone2',
    validate: rowData => {
      if (rowData.phone2 && rowData.phone2 !== "") {
        return ValidateStringWithNumber(rowData.phone2) ? true : { isValid: false, helperText: `phone number is not valid` }
      } else
        return true
    },
    headerStyle: headerStyle100,
    cellStyle: cellStyle100
  },
  {
    title: 'Mobile*',
    field: 'mobile',
    validate: rowData => {
      if (rowData.mobile && rowData.mobile !== "")
        return ValidateMobile(rowData.mobile) ? true : { isValid: false, helperText: `mobile is not valid` }
      else
        return { isValid: false, helperText: `can't be empty` }
    },
    headerStyle: headerStyle100,
    cellStyle: cellStyle100
  },
  {
    title: 'Fax',
    field: 'fax',
    type: "numeric",
    editComponent: (props) => {
      //   let valueDefault = props?.rowData?.brokerPer || 0;
      return (
        <TextField
          value={props?.rowData?.fax}
          type="number"
          onChange={(e) => {
            let Data = { ...props.rowData };
            Data.fax = parseInt(e.target.value);
            props.onRowDataChange(Data);
          }}
        />
      );
    },
    headerStyle: headerStyle100,
    cellStyle: cellStyle100
  },
  {
    title: 'Intercom',
    field: 'intercom',
    type: "numeric",
    headerStyle: headerStyle100,
    cellStyle: cellStyle100,
    editComponent: (props) => {
      //   let valueDefault = props?.rowData?.brokerPer || 0;
      return (
        <TextField
          value={props?.rowData?.intercom}
          type="number"
          onChange={(e) => {
            let Data = { ...props.rowData };
            Data.intercom = parseInt(e.target.value);
            props.onRowDataChange(Data);
          }}
        />
      );
    },
  },
  {
    title: 'Repnet ID',
    field: 'RapnetId',
    headerStyle: headerStyle100,
    cellStyle: cellStyle100
  },
  {
    title: 'TAN No',
    field: 'tanNo',
    headerStyle: headerStyle100,
    cellStyle: cellStyle100
  },
  {
    title: 'VAT No', field: 'vatNo',
    headerStyle: headerStyle100,
    cellStyle: cellStyle100
  },
  {
    title: 'PAN No',
    field: 'panNo',
    headerStyle: headerStyle100,
    cellStyle: cellStyle100
  },
  {
    title: 'GST Tin No',
    field: 'gstTinNo',
    headerStyle: headerStyle100,
    cellStyle: cellStyle100
  },
  {
    title: 'CST Tin No',
    field: 'cstTinNo',
    headerStyle: headerStyle100,
    cellStyle: cellStyle100
  },
  {
    title: 'CK Reg No',
    field: 'ckRegNo',
    headerStyle: headerStyle100,
    cellStyle: cellStyle100
  },
  {
    title: 'Currency',
    field: 'currency',
    headerStyle: headerStyle100,
    cellStyle: cellStyle100
  },
  {
    title: 'Contact Person Name(1)',
    field: 'contactPerson1Name',
    headerStyle: headerStyle100,
    cellStyle: cellStyle100
  },
  {
    title: 'Contact Person Name(2)',
    field: 'contactPerson2Name',
    headerStyle: headerStyle100,
    cellStyle: cellStyle100
  },
  {
    title: 'Mobile No(1)',
    field: 'mobileNo1',
    validate: rowData => {
      if (rowData.mobileNo1 && rowData.mobileNo1 !== "") {
        return ValidateStringWithNumber(rowData.mobileNo1) ? true : { isValid: false, helperText: `mobile number is not valid` }
      } else
        return true
    },
    headerStyle: headerStyle100,
    cellStyle: cellStyle100
  },
  {
    title: 'Mobile No(2)',
    field: 'mobileNo2',
    validate: rowData => {
      if (rowData.mobileNo2 && rowData.mobileNo2 !== "") {
        return ValidateStringWithNumber(rowData.mobileNo2) ? true : { isValid: false, helperText: `mobile number is not valid` }
      } else
        return true
    },
    headerStyle: headerStyle100,
    cellStyle: cellStyle100
  }
]

function Company(props) {
  const classes = useStyles();
  const tableRef = React.createRef()
  const [loader, setLoader] = useState(false)
  const [pageSize, setPageSize] = useState(10)
  const [selectedRow, setSelectedRow] = useState(null);

  const permissions = props?.session?.me?.role?.permissions;
  const role = props?.session?.me?.role?.role;
  const path = props?.location?.pathname;
  let permission = _.find(permissions, function (o) { return o.name === path; })

  const { fetchMore, subscribeToMore } = useQuery(COMPANY, {
    variables: { page: 1, limit: 10, filter: JSON.stringify({}), sort: { key: 'createdAt', type: -1 } },
    fetchPolicy: 'cache-and-network'
  })

  useEffect(() => {
    if (subscribeToMore) {
      const unsubscribe = subscribeToMore({
        document: COMPANY_SUBSCRIBE,
        updateQuery: (previousResult, { subscriptionData }) => {
          if (!subscriptionData.data) {
            return previousResult;
          }
          tableRef.current && tableRef.current.onQueryChange()
          return previousResult;
        },
      });
      return () => unsubscribe();
    }
  }, [subscribeToMore]);


  const [AddCompanyMutation] = useMutation(ADD_COMPANY)
  const [UpdateCompanyMutation] = useMutation(UPDATE_COMPANY)
  const [DeleteCompanyMutation] = useMutation(DELETE_COMPANY)

  const AddCompany = (newData) => {
    setLoader(true)
    return new Promise((resolve, reject) => {
      setLoader(true)
      let log = {
        action: 'INSERT',
        actionOn: 'companies',
        actionName: 'addNewCompany',
        oldValue: "",
        message: `${newData?.companyName || ''} company was created.`
      }
      newData.mobile = toString(newData.mobile)
      AddCompanyMutation({ variables: { input: newData, log } }).then((data) => {
        toast.success('company created successfully.');
        setLoader(false)
        resolve()
      }).catch((e) => {
        setLoader(false)
        reject()
        toast.error(FormatError(e))
      })
    })
  }

  const UpdateCompany = (newData, oldData) => {
    setLoader(true)
    return new Promise((resolve, reject) => {
      let log = {
        action: 'UPDATE',
        actionOn: 'companies',
        actionName: 'updateCompany',
        oldValue: JSON.stringify(oldData),
        message: `${oldData?.companyName || ''} company was updated.`
      }
      UpdateCompanyMutation({ variables: { input: newData, log } }).then((data) => {
        toast.success('company updated successfully.');
        setLoader(false)
        resolve()
      }).catch((e) => {
        setLoader(false)
        reject()
        toast.error(FormatError(e))
      })
    })
  }

  const DeleteCompany = (oldData) => {
    setLoader(true)
    return new Promise((resolve, reject) => {
      setLoader(true)
      let log = {
        action: 'DELETE',
        actionOn: 'companies',
        actionName: 'deleteCompany',
        oldValue: JSON.stringify(oldData),
        message: `${oldData?.companyName || ''} company was deleted.`
      }
      DeleteCompanyMutation({ variables: { id: oldData.id, log } }).then((data) => {
        setLoader(false)
        toast.success('company deleted successfully.');
        setLoader(false)
        resolve()
      }).catch((e) => {
        setLoader(false)
        reject()
        toast.error(FormatError(e))
      })
    })
  }

  const getCompanyData = (query) => {
    setLoader(true)
    return new Promise(async (resolve, reject) => {
      let filter = {}, sort = { key: 'createdAt', type: -1 }
      query && query.filters.forEach(item => {
        if (item.column.field === "isActive") {
          if (item.value)
            filter = { ...filter, [item.column.field]: item.value === "checked" ? true : false }
          else {
            delete filter.isActive
            filter = { ...filter }
          }
        } else if (item.value.length === 0) {
          delete filter[`${item.column.field}`]
          filter = { ...filter }
        } else
          filter = { ...filter, [item.column.field]: item.value }
      });

      if (query && query.orderBy && query.orderDirection !== "") {
        sort = { key: query.orderBy.field, type: query.orderDirection === 'asc' ? 1 : -1 }
      }
      setPageSize(query.pageSize)
      try {
        let result = await fetchMore({
          variables: { page: query.page + 1, limit: query.pageSize, filter: JSON.stringify(filter), sort: sort },
          fetchPolicy: 'network-only',
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return fetchMoreResult;
          }
        })
        setLoader(false)
        resolve({
          data: result?.data?.getCompanies?.data || [],
          page: query.page,
          totalCount: result?.data?.getCompanies?.count || 0,
        })
      } catch (error) {
        console.log(FormatError(error))
        setLoader(false)
      }
    })
  }

  const addButton = (val) => {
    if (val || (role === 'admin'))
      return { onRowAdd: (newData) => AddCompany(newData) }
    else
      return {}
  }
  useEffect(() => {
    document.getElementsByClassName("parentTable")[0].firstElementChild.firstElementChild.firstChild.remove()
  }, [])
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <div className="parentTable">
            <MaterialTable
              title="Company "
              columns={columns}
              isLoading={loader}
              tableRef={tableRef}
              data={query => getCompanyData(query)}
              icons={{ Add: props => { return (<div> <i className="fa fa-plus-square" ></i> Company </div>) } }}
              options={{
                emptyRowsWhenPaging: false,
                showTitle: false,
                debounceInterval: 300,
                padding: 'dense',
                pageSize: pageSize,
                pageSizeOptions: [5, 10, 15, 20, 25, 50],
                addRowPosition: 'first',
                filtering: true,
                sorting: true,
                search: false,
                filterCellStyle: { padding: '5px', },
                maxBodyHeight: window.innerHeight - 215,
                rowStyle: rowData => ({
                  backgroundColor: (selectedRow === rowData.tableData.id) ? 'rgb(212, 218, 255)' : '#f5f5f5'
                }),
                headerStyle: { backgroundColor: 'whitesmoke' }
              }}
              editable={{
                isEditHidden: (rowData) => (role === 'admin') ? false : !permission.isUpdate,
                isDeleteHidden: (rowData) => (role === 'admin') ? false : !permission.isDelete,
                ...addButton(permission?.isInsert),
                onRowUpdate: (newData, oldData) => UpdateCompany(newData, oldData),
                onRowDelete: (oldData) => DeleteCompany(oldData)
              }}
              onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default withRouter(Company)