import React, { useState, useEffect, useRef } from 'react';
import { useQuery } from '@apollo/react-hooks'
import MaterialTable, { MTableFilterRow, MTableHeader } from 'material-table';
import { withRouter } from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
// import _ from 'lodash';
import moment from 'moment';
import { PACKETHISTORY } from './PacketHistoryQuery';
// import { PKTHISTORY_SUBSCRIBE } from './PackertHistorySubscribe';
import { PktNoFromLotNo } from '../../../components/DropdownSelect/PktNoFromLotNo';
import {
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { LotNo } from '../../../components/DropdownSelect/LotNo';
import { Tofixed } from '../../../components/Comman/Tofix';


const useStyles = makeStyles((theme) => ({
    root: { flexGrow: 1, },
    noMargin: { margin: 0 }
}));

const columns = [
    {
        title: 'Trans No',
        field: 'transNo',
        headerStyle: { minWidth: 130 },
        filtering: false,
    },
    {
        title: 'From Department',
        field: 'fromDept',
        filtering: false,
        headerStyle: { minWidth: 180 },
        render: (rowData) => rowData?.fromDept?.deptName || "-"
    },
    {
        title: 'To Department',
        field: 'toDept',
        filtering: false,
        headerStyle: { minWidth: 170 },
        render: (rowData) => rowData?.toDept?.deptName || "-"
    },
    {
        title: 'Transfer Name',
        field: 'transName',
        filtering: false,
        headerStyle: { minWidth: 170 },
        render: (rowData) => rowData?.transName || "-"
    },
    {
        title: 'Party Name',
        field: 'partyId',
        filtering: false,
        headerStyle: { minWidth: 150 },
        render: (rowData) => rowData?.partyId?.partyName
    },
    {
        title: 'Pkt Type',
        field: 'pktType',
        headerStyle: { minWidth: 150 },
        filtering: false,
    },
    {
        title: 'Lot No',
        field: 'lotNoId',
        filtering: false,
        headerStyle: { minWidth: 150 },
        render: (rowData) => rowData?.lotNoId?.lotNo
    },
    {
        title: 'Pkt No',
        field: 'pktNoId',
        filtering: false,
        headerStyle: { minWidth: 150 },
        render: (rowData) => rowData?.pktNoId?.pktNo
    },
    {
        title: 'Cts',
        field: 'cts',
        render: (rowData) => Tofixed(rowData?.cts || 0),
        filtering: false,
    },
    {
        title: 'Return Cts',
        field: 'retCts',
        headerStyle: { minWidth: 140 },
        render: (rowData) => Tofixed(rowData?.retCts || 0),
        filtering: false,
    },
    {
        title: 'Loss Cts',
        field: 'lossCts',
        headerStyle: { minWidth: 140 },
        render: (rowData) => Tofixed(rowData?.retCts || 0),
        filtering: false,
    },
    {
        title: 'Loss(%)',
        field: 'lossPer',
        filtering: false,
    },
    {
        title: 'Extra Cts',
        field: 'extraCts',
        headerStyle: { minWidth: 140 },
        render: (rowData) => Tofixed(rowData?.extraCts || 0),
        filtering: false,
    },
    {
        title: 'Color',
        field: 'colorId',
        filtering: false,
        render: (rowData) => rowData?.colorId?.shortName
    },
    {
        title: 'Shape',
        field: 'shapeId',
        filtering: false,
        render: (rowData) => rowData?.shapeId?.shortName
    },
    {
        title: 'Purity',
        field: 'purityId',
        filtering: false,
        render: (rowData) => rowData?.purityId?.shortName
    },
    {
        title: 'Cut',
        field: 'cutId',
        filtering: false,
        render: (rowData) => rowData?.cutId?.shortName
    },
    {
        title: 'Polish',
        field: 'polishId',
        filtering: false,
        render: (rowData) => rowData?.polishId?.shortName
    },
    {
        title: 'Symm',
        field: 'symmId',
        filtering: false,
        render: (rowData) => rowData?.symmId?.shortName
    },
    {
        title: 'Flu',
        field: 'fluId',
        filtering: false,
        render: (rowData) => rowData?.fluId?.shortName
    },
    {
        title: 'Exp. Cts',
        field: 'expectedCts',
        headerStyle: { minWidth: 140 },
        render: (rowData) => Tofixed(rowData?.expectedCts || 0),
        filtering: false,
    },
    {
        title: 'Exp. Pol. Amt',
        field: 'expPolAmt',
        headerStyle: { minWidth: 160 },
        render: (rowData) => Tofixed(rowData?.expPolAmt || 0),
        filtering: false,
    },
    {
        title: 'Date Time',
        field: 'entryDate',
        filtering: false,
        type: 'datetime',
        headerStyle: { minWidth: 140 },
        render: (rowData) => moment(rowData.entryDate).format("DD-MM-YYYY HH:mm")

    },
    {
        title: 'TotSubPkt',
        field: 'totalSubPkt',
        filtering: false,
    },
    {
        title: 'Sub Pkt',
        field: 'subPkt',
        filtering: false,
        headerStyle: { minWidth: 140 },
    },
    {
        title: 'Tot SubPkt Cts',
        field: 'totalSubPktCts',
        headerStyle: { minWidth: 180 },
        render: (rowData) => Tofixed(rowData?.totalSubPktCts || 0),
        filtering: false,
    },
    {
        title: 'SubPkt Cts',
        field: 'subPktCts',
        filtering: false,
        headerStyle: { minWidth: 140 },
    },
]

function PacketHistory(props) {
    const classes = useStyles();
    const tableRef = useRef();

    const [filter, setFilter] = useState({})
    const [sort, setSort] = useState({ key: 'createdAt', type: 1 });
    const [orderBy, setOrderBy] = useState(-1);
    const [orderDirection, setOrderDirection] = useState("");
    const [lotNo, setLotNo] = useState(null);
    const [pktNo, setPktNo] = useState(null);


    const { error, data, loading } = useQuery(PACKETHISTORY, {
        variables: { filter: JSON.stringify(filter), sort: sort },
        fetchPolicy: 'cache-and-network'
    })

    // const { loterror, lotdata } = useQuery(LOTDATA, {
    //     fetchPolicy: 'cache-and-network',
    // })
    // if (loterror)
    //      toast('Network error')

    // let Lortdata = lotdata?.getAllKapanDetail || []
    // let lotNodata = {}
    // Lortdata.map((d, index) => {
    //     lotNodata[d.id] = d.lotNo
    //     return null
    //   })
    // const defaultProps = {
    //     options: Lortdata,
    //     getOptionLabel: (option) => option.lotNo
    //   };

    // useEffect(() => {
    //   const unsubscribe = subscribeToMore({
    //     document: PKTHISTORY_SUBSCRIBE,
    //     updateQuery: (previousResult, { subscriptionData }) => {
    //       if (!subscriptionData.data) {
    //         return previousResult;
    //       }
    //       const { PacketHistoryChange } = subscriptionData.data;
    //       if (PacketHistoryChange && PacketHistoryChange.keyType === "UPDATE") {
    //         let updatePacketHistoryData = PacketHistoryChange && PacketHistoryChange.data;
    //         let AllPacketHistoryData = previousResult.getPacketDeassociation.data;
    //         let updatedPacketHistoryData = _.map(AllPacketHistoryData, (d) => {
    //           if (d.id === updatePacketHistoryData.id) {
    //             return updatePacketHistoryData
    //           }
    //           else return d
    //         })
    //         return {
    //           ...previousResult,
    //           getPacketHistory: {
    //             ...previousResult.getPacketHistory,
    //             count: previousResult.getPacketHistory.count,
    //             data: [
    //               ...updatedPacketHistoryData
    //             ]
    //           }
    //         }
    //       } else if (PacketHistoryChange && PacketHistoryChange.keyType === "DELETE") {
    //         let updatePacketHistoryData = PacketHistoryChange && PacketHistoryChange.data;
    //         let AllPacketHistoryData = previousResult.getPacketHistory.data;
    //         let updatedPacketHistoryData = _.reject(AllPacketHistoryData, d => {
    //           return d.id === updatePacketHistoryData.id
    //         })
    //         return {
    //           ...previousResult,
    //           getPacketHistory: {
    //             ...previousResult.getPacketHistory,
    //             count: parseInt(previousResult.getPacketHistory.count) - 1,
    //             data: [...updatedPacketHistoryData]
    //           }
    //         }
    //       } else if (PacketHistoryChange && PacketHistoryChange.keyType === "INSERT") {
    //         let users = [
    //           ...[PacketHistoryChange.data],
    //           ...previousResult.getPacketHistory.data,
    //         ]
    //         return {
    //           ...previousResult,
    //           getPacketHistory: {
    //             ...previousResult.getPacketHistory,
    //             count: parseInt(previousResult.getPacketHistory.count) + 1,
    //             data: users.slice(0, pageSize),
    //           },
    //         };
    //       }
    //     },
    //   });
    //   return () => unsubscribe();
    // }, [pageSize, subscribeToMore]);

    useEffect(() => {
        tableRef.current && tableRef.current.onQueryChange()
    }, [data]);

    const getPacketHistoryData = () => {
        return new Promise((resolve, reject) => {
            if (error)
                reject(error)
            let result
            if (lotNo && pktNo) {
                result = data?.getPacketHistory?.data || []
            } else {
                result = []
            }
            resolve({
                data: result,
            })
        })
    }

    const generateFilterString = (field, filString) => {
        let data = filter
        if (field === "date") {
            if (filString)
                data = { ...filter, [field]: moment(filString).toISOString() }
            else {
                delete filter[`${field}`]
                data = { ...filter }
            }
        } else if (filString.length === 0) {
            delete filter[`${field}`]
            data = { ...filter }
        } else {
            if (field === "currencyRate" || field === "currencyAmount" || field === "partyAmtZar" || field === "adgAmtZar") {
                filString = parseFloat(filString)
                data = { ...filter, [field]: filString }
            }
            else {
                data = { ...filter, [field]: filString }
            }
        }
        setFilter(data)
    }

    const setSorting = (colId, type) => {
        if (!type) {
            setSort({ key: 'createdAt', type: -1 })
            setOrderBy(-1)
            setOrderDirection("")
        } else {
            setSort({ key: columns[colId].field, type: type === 'asc' ? 1 : -1 })
            setOrderBy(colId)
            setOrderDirection(type)
        }
    }

    const getRecertyMasterDataV1 = () => {
        setFilter({ "lotNo": lotNo, "packetNo": pktNo })
    }
    let defaultProps = LotNo('', '', '').defaultProps
    let PktData = PktNoFromLotNo(lotNo ? lotNo : null)
    const defaultProps2 = {
        options: PktData,
        getOptionLabel: (option) => option.pktNo
    };

    return (
        <div className={classes.root}>
            <div className="row">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>

                    <Grid xs={12} item container>
                        <Grid xs={4} item>

                        </Grid>
                        <Grid xs={5} item container>
                            <Grid xs={4} item>
                                <Autocomplete
                                    {...defaultProps}
                                    onChange={(e, newdata) => {
                                        if (newdata) {
                                            setLotNo(newdata.id)
                                        }
                                    }}
                                    renderInput={(params) => <TextField label="Lot No"  {...params} margin="normal" />}
                                />
                            </Grid>

                            <Grid xs={4} item style={{ marginLeft: 5 }}>
                                <Autocomplete
                                    {...defaultProps2}
                                    onChange={(e, newdata) => {
                                        if (newdata) {
                                            setPktNo(newdata.id)
                                        }

                                    }}
                                    renderInput={(params) => <TextField label="PKT No"  {...params} margin="normal" />}
                                />
                            </Grid>
                            <Grid xs={3} item style={{ marginTop: 15 }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={{ height: 34, marginLeft: 15, marginTop: 12 }} onClick={e => getRecertyMasterDataV1()}>
                                    Find
                                </Button>
                            </Grid>
                        </Grid>

                    </Grid>

                </MuiPickersUtilsProvider>
            </div>

            <Grid container>
                <Grid item xs={12}>
                    <MaterialTable
                        className="PacketHistory"
                        title="Packet History"
                        tableRef={tableRef}
                        columns={columns}
                        isLoading={loading}
                        data={query => getPacketHistoryData()}
                        options={{
                            emptyRowsWhenPaging: false,
                            padding: 'dense',
                            paging: false,
                            addRowPosition: 'first',
                            filtering: true,
                            sorting: true,
                            search: false,
                            filterCellStyle: { padding: '5px', },
                            maxBodyHeight: window.innerHeight - 215,
                            rowStyle: rowData => ({
                                fontSize: 14
                            }),
                            headerStyle: { backgroundColor: 'whitesmoke' }
                        }}
                        components={{
                            FilterRow: props => {

                                return <MTableFilterRow
                                    {...props}
                                    columns={props.columns}
                                    onFilterChanged={(columnId, value) => {
                                        props.onFilterChanged(columnId, value);
                                        generateFilterString(props.columns[columnId].field, value)
                                    }}
                                />
                            },
                            Header: props => {
                                return <MTableHeader
                                    {...props}
                                    orderBy={orderBy}
                                    orderDirection={orderDirection}
                                    onOrderChange={(orderBy, orderDirection) => {
                                        props.onOrderChange(orderBy, orderDirection)
                                        setSorting(orderBy, orderDirection)
                                    }}
                                />
                            }
                        }}
                    />
                </Grid>
            </Grid>
        </div>
    );
}



export default withRouter(PacketHistory)
