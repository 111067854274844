import gql from 'graphql-tag';

export const GETSALESDATAFORDASHBOARD = gql`
query getSalesMasterForDayPayment($page: Int, $limit: Int, $filter: String, $sort: salesMasterSort){
  getSalesMasterForDayPayment(page: $page, limit: $limit, filter: $filter, sort: $sort){
    count
    data{
        date
        currency
        billNo
        partyId{
          partyName
        }
        dueDate
        totalAmount
        netZarAmt
        remainingAmt
        dueDate
        totalZarAmount
        zarAmount
      }
    }
  }
`;
export const GETPURCHASEDATAFORDASHBOARD = gql`
    query getPurchaseDataForDashboard($filter:String){
      getPurchaseDataForDashboard(filter:$filter){
        inwardNo
        invoiceNo
        date
        partyId{
          partyName
        }
        termId{
          termName
        }
        dueDays
      }
      }
`;
export const GETJOBWORKFORDASHBOARD = gql`
query getJobWorkForDashboard( $page: Int $limit: Int  $filter: String $sort: packetMasterSort ) {
  getJobWorkForDashboard(page: $page, limit: $limit, filter: $filter,sort: $sort) {
    count
    data{          
      lotNoId{
        id
        lotNo
      }
      partyId{
        id
        partyName
      }
        remainingAmt
        totalPkts
        transferId
        extraTopsTransferId
      }
	}
  }
`;