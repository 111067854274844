import gql from 'graphql-tag';

export const BOXSIZE_SUBSCRIBE = gql`
  subscription BoxSizeChange {
    BoxSizeChange {
      keyType
      data{
        id
        boxNo
      fromSize
        toSize
        rfId
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
    }
  }
`;
