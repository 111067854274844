import React from "react";
import { withRouter } from "react-router-dom";
import MaterialTable from "material-table";
import { Tofixed } from '../../../../components/Comman/Tofix'
// import _ from "lodash";

// import './planning.css'
const columns = [
  {
    title: "Kapan",
    field: "lotNo",
    headerStyle: {
      backgroundColor: "#039be5",
    },
    cellStyle: {
      minWidth: 200,
    },
  },
  {
    title: "Pkt No",
    field: "pktNo",
    headerStyle: {
      backgroundColor: "#039be5",
    },
    cellStyle: {
      minWidth: 150
    },
    render: (rowData) => rowData?.packetDetail?.pktNo || null,
  },
  {
    title: "Rough Cts",
    field: "cts",
    render: (rowData) => Tofixed(rowData?.packetDetail?.cts),
    headerStyle: {
      backgroundColor: "#039be5",
    },
  },
  {
    title: "Exp. Cts",
    field: "rfId",
    render: (rowData) => Tofixed(rowData?.packetDetail?.expcts || null),
    headerStyle: {
      backgroundColor: "#039be5",
    },
  },
  {
    title: "Clr",
    field: "colorId",
    headerStyle: {
      backgroundColor: "#039be5",
    },
    render: (rowData) => rowData?.packetDetail?.colorId?.shortName || null,
  },
  {
    title: "Purity",
    field: "purityId",
    render: (rowData) => rowData?.packetDetail?.purityId?.shortName || null,
    headerStyle: {
      backgroundColor: "#039be5",
    },
  },
  {
    title: "Flu",
    field: "flsId",
    render: (rowData) => rowData?.packetDetail?.flsId?.shortName || null,
    headerStyle: {
      backgroundColor: "#039be5",
      textAlign: "center",
      minWidth: 250,
      maxWidth: 250,
    },
    cellStyle: {
      minWidth: 250,
      maxWidth: 250,
      textAlign: "center",
    },
  },
];
function Rough(props) {
  //   const [selectedRow, setSelectedRow] = useState(null);
  return (
    // <div className="childTable" >
    <MaterialTable
      title="Rough"
      columns={columns}
      data={props.AllData}
      isLoading={props.loading}
      options={{
        emptyRowsWhenPaging: false,
        padding: "dense",
        pageSizeOptions: [5, 10, 15, 20, 25, 50],
        addRowPosition: "first",
        filtering: false,
        sorting: true,
        search: false,
        filterCellStyle: { padding: "5px" },
        // maxBodyHeight: window.innerHeight - 215,
        paging: false,
        rowStyle: (rowData) => ({
          backgroundColor: rowData.lotNo === "Grand Total" ? "rgb(212, 218, 255)" : "#FFF",
          fontWeight: rowData.lotNo === "Grand Total" && 'bolder'
        }),
      }}
    />
    // </div>
  );
}
export default withRouter(Rough);
