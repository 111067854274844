import React, { useState, useEffect, useRef } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks'
import MaterialTable from 'material-table';
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import PROCESS from './ProcessQuery';
import { ADD_PROCESS, UPDATE_PROCESS, DELETE_PROCESS } from './ProcessMutation';
import { PROCESS_SUBSCRIBE } from './ProcessSubscription'
import { FormatError } from '../../../components/Comman/FormatError'
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1, },
  noMargin: { margin: 0 }
}));

const columns = [
  {
    title: 'Process Name*', field: 'procName',
    validate: rowData => (rowData.procName && rowData.procName !== "") ? true : { isValid: false, helperText: `can't be empty` }
  },
  { title: 'Status', field: 'isActive', type: "boolean", initialEditValue: true, },
]

function Process(props) {

  const classes = useStyles();
  const tableRef = useRef();
  const [loader, setLoader] = useState(false);
  const [pageSize, setPageSize] = useState(10)
  const [selectedRow, setSelectedRow] = useState(null);

  const permissions = props?.session?.me?.role?.permissions;
  const role = props?.session?.me?.role?.role;
  const path = props?.location?.pathname;
  let permission = _.find(permissions, function (o) { return o.name === path; })

  const { fetchMore, subscribeToMore } = useQuery(PROCESS, {
    variables: { page: 1, limit: 10, filter: JSON.stringify({}), sort: { key: 'createdAt', type: -1 } },
    fetchPolicy: 'cache-and-network'
  })

  useEffect(() => {
    if (subscribeToMore) {
      const unsubscribe = subscribeToMore({
        document: PROCESS_SUBSCRIBE,
        updateQuery: (previousResult, { subscriptionData }) => {
          if (!subscriptionData.data) {
            return previousResult;
          }
          tableRef.current && tableRef.current.onQueryChange()
          return previousResult;
        },
      });
      return () => unsubscribe();
    }
  }, [subscribeToMore]);

  const [AddProcessMutation] = useMutation(ADD_PROCESS)
  const [updateProcessMutation] = useMutation(UPDATE_PROCESS)
  const [deleteProcessMutation] = useMutation(DELETE_PROCESS)

  const AddProcess = (newData) => {
    return new Promise((resolve, reject) => {
      setLoader(true)
      if (newData.procName) {
        let log = {
          action: 'INSERT',
          actionOn: 'prcoessMaster',
          actionName: 'addNewProcess',
          oldValue: "",
          message: `${newData?.procName || ''} process was created.`
        }
        AddProcessMutation({ variables: { input: newData, log } }).then((data) => {
          toast.success('process created successfully.');
          setLoader(false)
          resolve()
        }).catch((e) => {
          reject()
          setLoader(false)
          toast.error(FormatError(e))
        })
      } else {
        reject()
        setLoader(false)
        toast.warn('please, fill all required(*) fields.')
      }
    })
  }
  const UpdateProcess = (newData, oldData) => {
    delete newData.__typename
    delete newData.createdBy
    delete newData.updatedBy
    delete newData.createdAt
    delete newData.updatedAt

    return new Promise((resolve, reject) => {
      setLoader(true)
      if (newData.procName) {
        let log = {
          action: 'UPDATE',
          actionOn: 'prcoessMaster',
          actionName: 'updateProcess',
          oldValue: JSON.stringify(oldData),
          message: `${oldData?.procName || ''} process was updated.`
        }
        updateProcessMutation({ variables: { input: newData, log } }).then((data) => {
          toast.success('process updated successfully.');
          setLoader(false)
          resolve()
        }).catch((e) => {
          reject()
          setLoader(false)
          toast.error(FormatError(e))
        })
      } else {
        reject()
        setLoader(false)
        toast.warn('please, fill all required(*) fields.')
      }
    })
  }
  const DeleteProcess = (oldData) => {
    return new Promise((resolve, reject) => {
      setLoader(true)
      let log = {
        action: 'DELETE',
        actionOn: 'prcoessMaster',
        actionName: 'deleteProcess',
        oldValue: JSON.stringify(oldData),
        message: `${oldData?.procName || ''} process was deleted.`
      }
      deleteProcessMutation({ variables: { id: oldData.id, log } }).then((data) => {
        setLoader(false)
        toast.success('process deleted successfully.');
        resolve()
      }).catch((e) => {
        setLoader(false)
        reject()
        toast.error(FormatError(e))
      })
    })
  }
  const getProcessData = (query) => {
    setLoader(true)
    return new Promise(async (resolve, reject) => {
      let filter = {}, sort = { key: 'createdAt', type: -1 }
      query && query.filters.forEach(item => {
        if (item.column.field === "isActive") {
          if (item.value)
            filter = { ...filter, [item.column.field]: item.value === "checked" ? true : false }
          else {
            delete filter.isActive
            filter = { ...filter }
          }
        }
        else if (item.value.length === 0) {
          delete filter[`${item.column.field}`]
          filter = { ...filter }
        } else
          filter = { ...filter, [item.column.field]: item.value }
      });
      if (query && query.orderBy && query.orderDirection !== "") {
        sort = { key: query.orderBy.field, type: query.orderDirection === 'asc' ? 1 : -1 }
      }
      setPageSize(query.pageSize)
      try {
        let result = await fetchMore({
          variables: { page: query.page + 1, limit: query.pageSize, filter: JSON.stringify(filter), sort: sort },
          fetchPolicy: 'network-only',
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return fetchMoreResult;
          }
        })
        setLoader(false)
        resolve({
          data: result?.data?.getProcessMaster?.data || [],
          page: query.page,
          totalCount: result?.data?.getProcessMaster?.count || 0,
        })
      } catch (error) {
        console.log(FormatError(error))
        setLoader(false)
      }
    })
  }

  const addButton = (val) => {
    if (val || (role === 'admin'))
      return { onRowAdd: (newData) => AddProcess(newData) }
    else
      return {}
  }

  useEffect(() => {
    document.getElementsByClassName("parentTable")[0].firstElementChild.firstElementChild.firstChild.remove()
  }, [])

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <div className="parentTable">
            <MaterialTable
              title="Process"
              tableRef={tableRef}
              columns={columns}
              isLoading={loader}
              data={query => getProcessData(query)}
              icons={{ Add: props => { return (<div> <i className="fa fa-plus-square" ></i> Process </div>) } }}
              options={{
                emptyRowsWhenPaging: false,
                showTitle: false,
                padding: 'dense',
                debounceInterval: 300,
                pageSize: pageSize,
                pageSizeOptions: [5, 10, 15, 20, 25, 50],
                addRowPosition: 'first',
                filtering: true,
                sorting: true,
                search: false,
                filterCellStyle: { padding: '5px', },
                maxBodyHeight: window.innerHeight - 215,
                rowStyle: rowData => ({
                  backgroundColor: (selectedRow === rowData.tableData.id) ? 'rgb(212, 218, 255)' : '#f5f5f5'
                }),
                headerStyle: { backgroundColor: 'whitesmoke' }
              }}
              editable={{
                isEditHidden: (rowData) => (role === 'admin') ? false : !permission.isUpdate,
                isDeleteHidden: (rowData) => (role === 'admin') ? false : !permission.isDelete,
                ...addButton(permission?.isInsert),
                onRowUpdate: (newData, oldData) => UpdateProcess(newData, oldData),
                onRowDelete: (oldData) => DeleteProcess(oldData)
              }}
              onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default withRouter(Process)