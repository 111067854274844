import gql from 'graphql-tag';

const DEPARTMENTWISEPROCESS = gql`
query getDeptWiseProcess($page: Int, $limit: Int, $filter: String, $sort: deptWiseProcessSort){
    getDeptWiseProcess(page: $page, limit: $limit, filter:$filter,sort:$sort){
      count
      data{
        id
        fromDept{
          id
          deptName
        }
        toDept{
          id
          deptName
        }
        process{
          id
          procName
        }
        pktType
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
    }
  }
`;

export default DEPARTMENTWISEPROCESS