import gql from 'graphql-tag';

const DASHBOARD = gql`
   query getDashboardData($filter:String){
    getDashboardData(filter:$filter){
        todayRoughCount
    	monthRoughCount
    	todayPlanningCount
        monthPlanningCount
    	todayInwardCount
    	monthInwardCount
    	todayFinalPolishCount
    	monthFinalPolishCount
    	todayBranchTransferCount
    	monthBranchTransferCount
    	todaySalesMasterCount
    	monthSalesMasterCount
    }
  }
`;

export default DASHBOARD

