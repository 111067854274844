import gql from 'graphql-tag';

export const GETAUDITLOG = gql`
query getAuditLog($page: Int, $limit: Int, $filter: String, $sort: auditLogSort){
  getAuditLog(page: $page, limit: $limit, filter: $filter sort: $sort){
    count
    data{
      action
      actionOn
      actor
      message
      actionName
      oldValue
      newValue
      createdAt
      updatedAt
    }
  }
}
`;