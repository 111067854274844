import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { LOTDATA } from '../../pages/Transaction/Packet/PacketQuery';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import _ from 'lodash'
import { FormatError } from '../Comman/FormatError';
export const LotNo = (valueDefault, changeselect, props) => {
  const { error, data } = useQuery(LOTDATA, {
    fetchPolicy: 'cache-and-network',
  })
  if (error)
    console.log(FormatError(error))
  let Lortdata = _.filter(data?.getAllKapanDetail || [], (d) => d.lotNo !== null)
  let lotNodata = {}
  Lortdata.map((d, index) => {
    lotNodata[d.id] = d.lotNo
    return null
  })
  const defaultProps = {
    options: Lortdata,
    getOptionLabel: (option) => option.lotNo
  };

  return {
    "lookup": lotNodata,
    "defaultProps": defaultProps,
    "SELECT": <Autocomplete
      {...defaultProps}
      onChange={(e, newValue) => changeselect(e, newValue)}
      defaultValue={_.filter(Lortdata, (newd) => newd.id === valueDefault)[0]}
      renderInput={(params) => <TextField   {...params} margin="normal" />}
    />
  }
}