import React, { useRef, useEffect } from "react";
import { Grid } from "@material-ui/core";
import MaterialTable from "material-table";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { GET_SALES_STOCK_DATA_FOR_RETURN_MODAL } from "./IssueReturnMasterQuery";
import _ from "lodash";
import { Tofixed } from "../../../components/Comman/Tofix";
import { headerStyle, cellStyle, headerStyle50, cellStyle50, headerStyle100, cellStyle100 } from '../../../components/Comman/StyleCell'
import { Button } from "@material-ui/core";
import { useState } from "react";
import { ADD_MUTIPLE_ISSUERETURN_DETAIL } from "./IssueReturnMasterMutation";
import { toast } from "react-toastify";
import { FormatError } from "../../../components/Comman/FormatError";
import { GradingSelectComponent } from "../../../components/DropdownSelect/SelectComponents/ShortCodeSelectComponent";
import { GETALLSHORTCODETYPE } from "../../Utility/SortCode/SortCodeQuery";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { LabPartyFilterDropdown } from '../../PowerMenu/StockMaster/LabPartyFilterDropdown/index'
import { LabPARTYMASTERDETAILS } from "../../Master/PartyMaster/PartyMasterQuery";
import { GetRapeRate } from "../../../components/DropdownSelect/SelectComponents/Getraprate";
import { useStyles } from "../../Dashboard/Style";
import CloseIcon from '@material-ui/icons/Close';
const columns = [
    {
        title: "Lot No*",
        field: "lotNoId",
        render: (rowData) => rowData?.lotNoId?.lotNo || "",
        headerStyle: headerStyle,
        cellStyle: cellStyle,
    },
    {
        title: "Pkt No*",
        field: "pktNoId",
        render: (rowData) => rowData?.pktNoId?.pktNo || "",
        headerStyle: headerStyle,
        cellStyle: cellStyle,
    },
    {
        title: "Lab*",
        field: "labId",
        render: (rowData) => rowData?.lab?.partyName || "",
        headerStyle: headerStyle,
        cellStyle: cellStyle,
    },
    {
        title: "Certicate No.",
        field: "certificateId",
        headerStyle: headerStyle,
        cellStyle: cellStyle,

    },
    {
        title: "RFID",
        field: "rfId",
        headerStyle: headerStyle,
        cellStyle: cellStyle,

    },
    {
        title: "Cts*",
        field: "cts",
        type: "numeric",
        headerStyle: headerStyle50,
        cellStyle: cellStyle50,
        render: (rowData) => Tofixed(rowData?.cts || 0),
        validate: (rowData) =>
            rowData.cts && rowData.cts !== ""
                ? true
                : { isValid: false, helperText: `can't be empty` },
    },
    {
        title: "Color",
        field: "colorId",
        headerStyle: headerStyle,
        cellStyle: cellStyle,
        render: (rowData) => rowData?.colorId?.shortName || "",
    },
    {
        title: "Shape",
        field: "shapeId",
        headerStyle: headerStyle,
        cellStyle: cellStyle,
        render: (rowData) => rowData?.shapeId?.shortName || "",
        editComponent: ({ rowData }) => {
            return rowData?.shapeId?.shortName || "";
        },
    },
    {
        title: "Purity",
        field: "purityId",
        headerStyle: headerStyle,
        cellStyle: cellStyle,
        render: (rowData) => rowData?.purityId?.shortName || "",
        editComponent: ({ rowData }) => {
            return rowData?.purityId?.shortName || "";
        },
    },
    {
        title: "Cut",
        field: "cutId",
        headerStyle: headerStyle,
        cellStyle: cellStyle,
        render: (rowData) => rowData?.cutId?.shortName || "",
        editComponent: ({ rowData }) => {
            return rowData?.cutId?.shortName || "";
        },
    },
    {
        title: "Polish",
        field: "polishId",
        headerStyle: headerStyle,
        cellStyle: cellStyle,
        render: (rowData) => rowData?.polishId?.shortName || "",
        editComponent: ({ rowData }) => {
            return rowData?.polishId?.shortName || "";
        },
    },
    {
        title: "Flou.",
        field: "fluId",
        headerStyle: headerStyle,
        cellStyle: cellStyle,
        render: (rowData) => rowData?.fluId?.shortName || "",
        editComponent: ({ rowData }) => {
            return rowData?.fluId?.shortName || "";
        },
    },
    {
        title: "Rap Rate",
        field: "rapRate",
        type: "numeric",
        render: (rowData) => rowData?.rapRate ? Tofixed(rowData?.rapRate || 0) : "",
        headerStyle: headerStyle100,
        cellStyle: cellStyle100,
    },
    // {
    //     title: "STK Disc.%",
    //     field: "stkPer",
    //     type: "numeric",
    //     headerStyle: headerStyle50,
    //     cellStyle: cellStyle50,
    // },
    {
        title: "Rap Amt($)",
        field: "rapAmount",
        render: (rowData) => Tofixed(rowData?.rapAmount || 0),
        type: "numeric",
        headerStyle: headerStyle,
        cellStyle: cellStyle,
    },

];

export const MultiplalAddInTable = ({ status, PartyIdDefault, issueReturnMasterId, issueNo, handleClose, pricelistData, getShortCodeData, shortCodeData }) => {
    const classes = useStyles();

    const tableRef = useRef();
    const [selectedData, SetselectedData] = useState([])
    const [AllData, SetAllData] = useState([])
    const [Loader, setLoader] = useState(false)
    let statusData = status == "APPRO RETURN" ? "APPRO ISSUE" : status == "LAB RETURN" ? "LAB ISSUE" : status == "REPAIR RETURN" && "REPAIR ISSUE"

    const { error, data, loading } = useQuery(GET_SALES_STOCK_DATA_FOR_RETURN_MODAL, {
        variables: { status: statusData, partyId: PartyIdDefault },
        fetchPolicy: 'cache-and-network'
    });
    useEffect(() => {
        setLoader(loading)
    }, [loading])

    useEffect(() => {
        let result = [...data?.getSalesStockForIssueData || []]
        SetAllData(result)
    }, [data])

    const [addNewIssueReturnDetailMutation] = useMutation(ADD_MUTIPLE_ISSUERETURN_DETAIL);

    // const IssueReturnMasterChildDetail = () => {
    //     return new Promise((resolve, reject) => {
    //         if (error) {
    //             reject(error);
    //         } else {
    //             let result = [...data?.getSalesStockForIssueData || []]
    //             resolve({
    //                 data: result,
    //             });
    //         }
    //     });
    // };

    useEffect(() => {
        tableRef.current && tableRef.current.onQueryChange();
    }, [data]);

    const addButtonHandler = () => {
        return new Promise((resolve, reject) => {
            setLoader(true)
            let Data = _.map(selectedData, (d) => {
                return {
                    issueReturnMasterId: issueReturnMasterId,
                    lotNoId: d?.lotNoId.id,
                    pktNoId: d?.pktNoId.id,
                    issueNo: issueNo,
                    partyId: PartyIdDefault,
                    type: status,
                    colorId: d.colorId?.id,
                    purityId: d.purityId?.id,
                    shapeId: d.shapeId?.id,
                    cutId: d.cutId?.id,
                    polishId: d.polishId?.id,
                    fluId: d.fluId?.id,
                    rapAmt: d.rapAmount,
                    rateRap: d.rapRate,
                    labId: d.lab?.id,
                }
            })
            let log = {
                action: 'INSERT',
                actionOn: 'IssueReturnDetail',
                actionName: 'addNewIssueReturnDetail',
                oldValue: "",
                message: `Issue Return Detail was created.`
            }
            addNewIssueReturnDetailMutation({ variables: { input: Data, log } }).then((data) => {
                toast.success("issue return master detail created successfully.");
                handleClose()
                setLoader(false)
                resolve();
            }).catch((e) => {
                setLoader(false)
                reject();
                toast.error(FormatError(e));
            });
        })

    }

    const { data: partyData } = useQuery(LabPARTYMASTERDETAILS, {
        fetchPolicy: 'cache-and-network',
    })

    const changeselect = (e, newValue, rowData) => {
        if (newValue.type === "COLOR") {
            rowData.colorId = newValue
        }
        else if (newValue.type === "PURITY") {
            rowData.purityId = newValue
        }
        else if (newValue.type === "SHAPE") {
            rowData.shapeId = newValue
        }
        else if (newValue.type === "CUT") {
            rowData.cutId = newValue
        }
        else if (newValue.type === "POLISH") {
            rowData.polishId = newValue
        }
        else if (newValue.type === "FLOURSENCE") {
            rowData.fluId = newValue
        }
        let raprate = GetRapeRate(pricelistData, rowData.cts, rowData?.purityId?.id, rowData?.colorId?.id, rowData?.shapeId?.id, shortCodeData)
        if (raprate?.rapRate && raprate?.rapAmount) {
            rowData.rapAmount = raprate?.rapAmount || 0
            rowData.rapRate = raprate?.rapRate || 0
        }

        let newData = _.map(AllData, (d) => d?.id === rowData?.id ? rowData : d)
        SetAllData(newData)
    }

    const changeLab = (e, newValue, rowData) => {
        rowData.lab = newValue
        let newData = _.map(AllData, (d) => d?.id === rowData?.id ? rowData : d)
        SetAllData(newData)
    }
    const ColorData = getShortCodeData("COLOR")
    const purityData = getShortCodeData("PURITY")
    const SHAPEData = getShortCodeData("SHAPE")
    const CUTData = getShortCodeData("CUT")
    const POLISHData = getShortCodeData("POLISH")
    const FLOURSENCEData = getShortCodeData("FLOURSENCE")
    const PartyAllData = partyData?.getLabParties

    columns.map((d, key) => {
        if (d.field === "labId") {
            d.render = rowData => {
                let valueDefault = rowData?.lab?.id || rowData?.lab || null
                return <Autocomplete
                    options={PartyAllData}
                    getOptionLabel={(option) => option.partyName}
                    disableClearable
                    onChange={(e, newValue) => changeLab(e, newValue, rowData)}
                    value={_.find(PartyAllData || [], (newd) => newd.id === valueDefault)}
                    renderInput={(params) => <TextField   {...params} margin="normal" />}
                />
            }
        }
        if (d.field === "colorId") {
            d.render = rowData => {
                let valueDefault = rowData?.colorId?.id || rowData?.colorId || null
                return <Autocomplete
                    options={ColorData}
                    getOptionLabel={(option) => option.shortName}
                    disableClearable
                    onChange={(e, newValue) => changeselect(e, newValue, rowData)}
                    value={_.find(ColorData, (newd) => newd.id === valueDefault)}
                    renderInput={(params) => <TextField   {...params} margin="normal" />}
                />
            }
        }
        if (d.field === "purityId") {
            d.render = rowData => {
                let valueDefault = rowData?.purityId?.id || rowData?.purityId || null
                return <Autocomplete
                    options={purityData}
                    getOptionLabel={(option) => option.shortName}
                    disableClearable
                    onChange={(e, newValue) => changeselect(e, newValue, rowData)}
                    value={_.find(purityData, (newd) => newd.id === valueDefault)}
                    renderInput={(params) => <TextField   {...params} margin="normal" />}
                />
            }
        }
        if (d.field === "shapeId") {
            d.render = rowData => {
                let valueDefault = rowData?.shapeId?.id || rowData?.shapeId || null
                return <Autocomplete
                    options={SHAPEData}
                    getOptionLabel={(option) => option.shortName}
                    disableClearable
                    onChange={(e, newValue) => changeselect(e, newValue, rowData)}
                    value={_.find(SHAPEData, (newd) => newd.id === valueDefault)}
                    renderInput={(params) => <TextField   {...params} margin="normal" />}
                />
            }
        }
        if (d.field === "cutId") {
            d.render = rowData => {
                let valueDefault = rowData?.cutId?.id || rowData?.cutId || null
                return <Autocomplete
                    options={CUTData}
                    getOptionLabel={(option) => option.shortName}
                    disableClearable
                    onChange={(e, newValue) => changeselect(e, newValue, rowData)}
                    value={_.find(CUTData, (newd) => newd.id === valueDefault)}
                    renderInput={(params) => <TextField   {...params} margin="normal" />}
                />
            }
        }
        if (d.field === "polishId") {
            d.render = rowData => {
                let valueDefault = rowData?.polishId?.id || rowData?.polishId || null
                return <Autocomplete
                    options={POLISHData}
                    getOptionLabel={(option) => option.shortName}
                    disableClearable
                    onChange={(e, newValue) => changeselect(e, newValue, rowData)}
                    value={_.find(POLISHData, (newd) => newd.id === valueDefault)}
                    renderInput={(params) => <TextField   {...params} margin="normal" />}
                />
            }
        }
        if (d.field === "fluId") {
            d.render = rowData => {
                let valueDefault = rowData?.fluId?.id || rowData?.fluId || null
                return <Autocomplete
                    options={FLOURSENCEData}
                    getOptionLabel={(option) => option.shortName}
                    disableClearable
                    onChange={(e, newValue) => changeselect(e, newValue, rowData)}
                    value={_.find(FLOURSENCEData, (newd) => newd.id === valueDefault)}
                    renderInput={(params) => <TextField   {...params} margin="normal" />}
                />
            }
        }
    })
    if (loading) {
        return <Grid container className={classes.loader} ><img src="https://i.gifer.com/4V0b.gif" alt="loading" /></Grid>
    }
    else
        return (
            <Grid container direction="row" item xs={12} style={{ position: "relative", paddingLeft: 15, paddingRight: 15, paddingBottom: 15 }}>
                <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <h1>Add Multiple Issue Return </h1>
                    <div style={{ width: "10%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Button variant="contained" color="primary" style={{ height: 35, marginTop: 10 }} onClick={() => addButtonHandler()}>Submit</Button>
                        <CloseIcon onClick={() => handleClose()} style={{ cursor: "pointer" }} />
                    </div>
                </div>

                <div className="childTable" style={{ width: "100%" }}>
                    <MaterialTable
                        // tableRef={tableRef}
                        columns={columns}
                        data={AllData}
                        options={{
                            emptyRowsWhenPaging: false,
                            padding: "dense",
                            addRowPosition: "first",
                            filtering: false,
                            sorting: false,
                            search: false,
                            paging: false,
                            filterheaderStyle: { padding: "5px" },
                            maxBodyHeight: window.innerHeight - 215,
                            showTitle: false,
                            selection: true,
                            headerStyle: { backgroundColor: 'whitesmoke' }
                        }}
                        onSelectionChange={(rows) => {
                            SetselectedData(rows)
                        }}
                    />
                </div>
            </Grid>
        );
};
