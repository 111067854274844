import React, { useState, useRef, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks'
import MaterialTable from 'material-table';
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import BANKDETAIL from './BankDetailsQuery';
import { DetailOf } from '../../../components/Constant/index'
import _ from 'lodash'
import { ADD_BANKDETAIL, UPDATE_BANKDETAIL, DELETE_BANKDETAIL } from './BankDetaisMutation';
import { ValidateStringWithNumber } from '../../../components/Comman/validate.js'
import { BANKDETAILS_SUBSCRIBE } from './BankDetailsSubscription'
import { FormatError } from '../../../components/Comman/FormatError';
import { CompanyAndPartySelectComponent } from "../../../components/DropdownSelect/SelectComponents/companyAndPartySelectComponent";

const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1, },
  noMargin: { margin: 0 }
}));

const columns = [
  {
    title: 'Bank Name*',
    field: 'bankName',
    validate: rowData => (rowData.bankName && rowData.bankName !== "") ? true : { isValid: false, helperText: `can't be empty` }
  },
  {
    title: 'Branch Code*', field: 'branchCode',
    validate: rowData => (rowData.branchCode && rowData.branchCode !== "") ? true : { isValid: false, helperText: `can't be empty` }
  },
  { title: 'Detail Of', field: 'detOf', initialEditValue: "PARTY", lookup: DetailOf },
  {
    title: 'Company/Party Name*', filtering: false, field: 'partyName',
    render: (rowData) => (rowData.detOf === "PARTY" ? rowData?.partyID?.partyName || 'SELECT PARTY' : rowData?.companyID?.companyName || 'SELECT COMPANY'),
    editComponent: (props) => {
      let valueDefault = (props?.rowData?.detOf === "PARTY" ? props?.rowData?.partyID?.id : props?.rowData?.companyID?.id) || '0'
      return <CompanyAndPartySelectComponent QueryName={props.rowData.detOf ? props.rowData.detOf : "PARTY"} valueDefault={valueDefault} prosData={props} />
    }
  },
  {
    title: 'Zar A/C. No*', field: 'accNo',
    validate: rowData => {
      if (rowData.accNo && (rowData.accNo !== "" || rowData.accNo !== undefined)) {
        return ValidateStringWithNumber(rowData.accNo) ? true : { isValid: false, helperText: `account number is not valid` }
      }
      else {
        return { isValid: false, helperText: `can't be empty` }
      }
    }
  },
  {
    title: '$ A/C. No',
    field: 'dolAccNO',
    validate: rowData => {
      if (rowData.dolAccNO && (rowData.dolAccNO !== "" || rowData.dolAccNO !== undefined)) {
        return ValidateStringWithNumber(rowData.dolAccNO) ? true : { isValid: false, helperText: `dollar account number is not valid` }
      }
      else {
        return true
      }
    }
  },
  { title: 'Swift Code', field: 'swiftCode' },
  { title: 'Narration', field: 'narration' },
]

function BankDetails(props) {
  const classes = useStyles();
  const tableRef = useRef();

  const [loader, setLoader] = useState(false);
  const [pageSize, setPageSize] = useState(10)
  const [selectedRow, setSelectedRow] = useState(null);

  const permissions = props?.session?.me?.role?.permissions;
  const role = props?.session?.me?.role?.role;
  const path = props?.location?.pathname;
  let permission = _.find(permissions, function (o) { return o.name === path; })

  const { fetchMore, subscribeToMore } = useQuery(BANKDETAIL, {
    variables: { page: 1, limit: 10, filter: JSON.stringify({}), sort: { key: 'createdAt', type: -1 } },
    fetchPolicy: 'cache-and-network'
  })

  const [AddBankDetailMutation] = useMutation(ADD_BANKDETAIL)
  const [updateBankDetailMutation] = useMutation(UPDATE_BANKDETAIL)
  const [deleteBankDetailMutation] = useMutation(DELETE_BANKDETAIL)


  const AddBankDetails = (newData) => {
    return new Promise((resolve, reject) => {
      setLoader(true)
      if (newData.bankName && newData.accNo && newData.branchCode) {
        if (newData.detOf === "PARTY") {
          newData.partyID = newData.partyId
          newData.companyID = null
          delete newData.partyId
        } else {
          newData.companyID = newData.companyId
          newData.partyID = null
          delete newData.companyId
        }
        let log = {
          action: 'INSERT',
          actionOn: 'bankDetail',
          actionName: 'addNewBankDetail',
          oldValue: "",
          message: `${newData?.accNo || ''} bankDetail was created.`
        }
        AddBankDetailMutation({ variables: { input: newData, log } }).then((data) => {
          toast.success('bank detail created successfully.');
          setLoader(false)
          resolve()
        }).catch((e) => {
          setLoader(false)
          reject()
          toast.warn(FormatError(e))
        })

      } else {
        setLoader(false)
        toast.warn('please,fill all required(*) fields.')
        reject()
      }
    })
  }
  const UpdateBankDetails = (newData, oldData) => {
    delete newData.createdBy
    delete newData.updatedBy
    delete newData.createdAt
    delete newData.updatedAt
    delete newData.__typename
    return new Promise((resolve, reject) => {
      setLoader(true)
      if (newData.detOf === "PARTY") {
        newData.partyID = newData?.partyID?.id || newData.partyId || null
        newData.companyID = null
        delete newData.partyId
      } else {
        newData.companyID = newData?.companyID?.id || newData?.companyId || null
        newData.partyID = null
        delete newData.companyId
      }

      if (newData.bankName && (newData.partyID || newData.companyID) && newData.accNo && newData.branchCode) {
        let log = {
          action: 'UPDATE',
          actionOn: 'bankDetail',
          actionName: 'updateBankDetail',
          oldValue: JSON.stringify(oldData),
          message: `${oldData?.accNo || ''} bankDetail was updated.`
        }
        updateBankDetailMutation({ variables: { input: newData, log } }).then((data) => {
          setLoader(false)
          toast.success('bank detail updated successfully.');
          resolve()
        }).catch((e) => {
          setLoader(false)
          reject()
          toast.warn(FormatError(e))
        })
      } else {
        setLoader(false)
        toast.warn('please,fill all required(*) fields.')
        reject()
      }
    })
  }
  const DeleteBankDetails = (oldData) => {
    return new Promise((resolve, reject) => {
      setLoader(true)
      let log = {
        action: 'DELETE',
        actionOn: 'bankDetail',
        actionName: 'deleteBankDetail',
        oldValue: JSON.stringify(oldData),
        message: `${oldData?.accNo || ''} bankDetail was deleted.`
      }
      deleteBankDetailMutation({ variables: { id: oldData.id, log } }).then((data) => {
        toast.success('bank detail deleted successfully.');
        setLoader(false)
        resolve()
      }).catch((e) => {
        reject()
        setLoader(false)
        toast.error('err -', e)
      })
      resolve()
    })
  }


  useEffect(() => {
    if (subscribeToMore) {
      const unsubscribe = subscribeToMore({
        document: BANKDETAILS_SUBSCRIBE,
        updateQuery: (previousResult, { subscriptionData }) => {
          if (!subscriptionData.data) {
            return previousResult;
          }
          tableRef.current && tableRef.current.onQueryChange()
          return previousResult;
        },
      });
      return () => unsubscribe();
    }
  }, [subscribeToMore]);

  const getBankDetailsData = (query) => {

    setLoader(true)
    return new Promise(async (resolve, reject) => {
      let filter = {}, sort = { key: 'createdAt', type: -1 }
      query && query.filters.forEach(item => {
        if (item.value.length === 0) {
          delete filter[`${item.column.field}`]
          filter = { ...filter }
        } else
          filter = { ...filter, [item.column.field]: item.value }
      });
      if (query && query.orderBy && query.orderDirection !== "") {
        sort = { key: query.orderBy.field, type: query.orderDirection === 'asc' ? 1 : -1 }
      }
      setPageSize(query.pageSize)
      try {
        let result = await fetchMore({
          variables: { page: query.page + 1, limit: query.pageSize, filter: JSON.stringify(filter), sort: sort },
          fetchPolicy: 'network-only',
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return fetchMoreResult;
          }
        })
        setLoader(false)
        resolve({
          data: result?.data?.getBankDetail?.data || [],
          page: query.page,
          totalCount: result?.data?.getBankDetail?.count || 0,
        })
      } catch (error) {
        console.log(FormatError(error))
        setLoader(false)
      }
    })
  }
  const addButton = (val) => {
    if (val || (role === 'admin'))
      return { onRowAdd: (newData) => AddBankDetails(newData) }
    else
      return {}
  }

  useEffect(() => {
    document.getElementsByClassName("parentTable")[0].firstElementChild.firstElementChild.firstChild.remove()
  }, [])
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <div className="parentTable">
            <MaterialTable
              title="Bank Details Master"
              tableRef={tableRef}
              columns={columns}
              isLoading={loader}
              data={query => getBankDetailsData(query)}
              icons={{ Add: props => { return (<div> <i className="fa fa-plus-square" ></i> Bank Details Master </div>) } }}
              options={{
                emptyRowsWhenPaging: false,
                padding: 'dense',
                debounceInterval: 300,
                pageSize: pageSize,
                showTitle: false,
                pageSizeOptions: [5, 10, 15, 20, 25, 50],
                addRowPosition: 'first',
                filtering: true,
                sorting: true,
                search: false,
                filterCellStyle: { padding: '5px', },
                maxBodyHeight: window.innerHeight - 215,
                rowStyle: rowData => ({
                  backgroundColor: (selectedRow === rowData.tableData.id) ? 'rgb(212, 218, 255)' : '#f5f5f5'
                }),
                headerStyle: { backgroundColor: 'whitesmoke' }
              }}
              editable={{
                isEditHidden: (rowData) => (role === 'admin') ? false : !permission.isUpdate,
                isDeleteHidden: (rowData) => (role === 'admin') ? false : !permission.isDelete,
                ...addButton(permission?.isInsert),
                onRowUpdate: (newData, oldData) => UpdateBankDetails(newData, oldData),
                onRowDelete: (oldData) => DeleteBankDetails(oldData)
              }}
              onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
export default withRouter(BankDetails)