import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import _ from 'lodash'

export const CustomFilterComponent = (props) => {
    return <Autocomplete
        {...props.defaultProps}
        onChange={(e, newValue) => {
            props.propsData.onFilterChanged(props.propsData.columnDef.tableData.id, _.map(newValue, 'id'))
        }}
        value={_.filter(props?.defaultProps?.options, (d) => (props?.propsData?.columnDef?.tableData?.filterValue || []).includes(d.id))}
        renderInput={(params) => <TextField style={{ margin: 0, display: "flex" }}  {...params} margin="normal" />}
    />
}
