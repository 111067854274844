import gql from 'graphql-tag';

export const GETLABPENDINGREPORT = gql`
query getLabPendingReport($filter: String!){
  getLabPendingReport(filter:$filter){
    date
    partyId{
      partyName
    }
    lotNoId{
      lotNo
    }
    pktNoId{
      pktNo
    }
    cts
  }
}
`;


