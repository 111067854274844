import gql from 'graphql-tag';

const DEPARTMENT = gql`
query getDepartmentMaster($page: Int, $limit: Int, $filter: String, $sort: departmentMasterSort){
  getDepartmentMaster(page:$page, limit: $limit, filter: $filter ,sort: $sort){
    count
    data{
      id
      deptCode
      deptName
      deptType
      catagory
      defaultProcess{
        id
        procName
      }
      isActive
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
}
`;

export const ALLDEPARTMENT = gql`
query getAllDepartment{
  getAllDepartment{
    id
    deptName
    deptType
    catagory
    defaultProcess{
     id
      procName
    }
  }
}
`;

export default DEPARTMENT

