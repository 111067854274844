import gql from 'graphql-tag';

export const GETJOBWORKREPORT = gql`
  query getJobWorkReport($filter:String!){
    getJobWorkReport(filter:$filter){
      purDate
      lotNo
      pktNo
      pktCts
      heliumCharge
      heliumAmt
      majuri
      totalDolAmt
      rate
      mfgAmt
      certiCost
      heliumCost
      laserCost
      isSubPkt
    }
  }
`;

