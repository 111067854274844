import gql from 'graphql-tag';

export const DEPARTMENTISSUERETURN = gql`
query  getDepartmentIssueReturn($page: Int, $limit: Int, $filter: String, $sort: departmentIssueReturnSort){
  getDepartmentIssueReturn(page: $page, limit: $limit, filter: $filter, sort:$sort){
    count
    data{
      id
      jagadNo
      returnDate
      fromDept{
        id
        deptName
      }
      toDept{
        id
        deptName
      }
      process{
        id
        procName
      }
      pktType
      createdBy{
        userName
      }
      updatedBy
      createdAt
      updatedAt

    }
  }
}
`;
export const RoughStockFrom = gql`
query getRoughStock($departmentId: ID){
  getRoughStock(departmentId:$departmentId){
    id
    planningId
    pktType
    issueCts
    retCts
    subPkt
    lossCts
    orgPktType
    extraCts
    orgPktType
    process{
      id
      procName
    }
    lotNoId{
      id
      lotNo
    }
    pktNoId{
      id
      pktNo
      isSubPkt
      subPktMasterId
    }
    purityId{
      id
      shortName
    }
    colorId{
      id
      shortName
    }
    shapeId{
      id
      shortName
    }
    expectedCts
    expPolAmt
    returnId{
      id
      jagadNo
    }
    partyId
    orgCts
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}
`;
export const DEPARTMENTISSUERETURNDETAIL = gql`
query getDepartmentIssueReturnDetail($id:ID){
  getDepartmentIssueReturnDetail(id:$id){
    id
    deptIssueReturnId
    lotNoId{
      id
      lotNo
    }
    pktNoId{
      id
      pktNo
    }
    pktType
    orgCts
    issueCts
    retCts
    lossCts
    subPkt
    colorId{
      id
      shortName
    }
    shapeId{
      id
      shortName
    }
    purityId{
      id
      shortName
    }
    expectedCts
    expPolAmt
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}
`;
export const GETPROCESSFROMDEPARTMENT = gql`
query getProcessPktFromDept($fromDept: ID,$toDept: ID){
  getProcessPktFromDept(fromDept:$fromDept,toDept:$toDept){
    id
    process{
      id
      procName
    }
    pktType
  }
}`;
export const GETSUBPACKET = gql`
    query getSubPkt($id: ID!){
      getSubPkt(id:$id){
        id
        pktNo
        isPlanning
        subPktMasterId{
          id
          pktNo
        }
        colorId{
          id
          shortName
        }
        shapeId{
          id
          shortName
        }
        tensionId{
          id
          shortName
        }
        flsId{
          id
          shortName
        }
        purityId{
          id
          shortName
        }
        expcts
    }}
`;