import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { Tofixed } from '../../../components/Comman/Tofix'
const column = [
    /*Rough*/
    { title: "Kapan", field: "RoughLotNo" },
    { title: "Pkt No", field: "RoughPktNo" },
    { title: "Rough Cts", field: "RoughCts" },
    { title: "Exp. Cts", field: "RoughRfId" },
    { title: "Clr", field: "RoughColorId" },
    { title: "Purity", field: "RoughPurityId" },
    { title: "Flu", field: "RoughFlsId" },
    /*Planining*/
    { title: "Plan Date", field: "PlaniningCreatedAt" },
    { title: "Exp. Cts", field: "PlaniningExpCts" },
    { title: "Shape", field: "PlaniningShapeId" },
    { title: "Purity", field: "PlaniningPurityId" },
    { title: "Cut", field: "PlaniningCutId" },
    { title: "Polish", field: "PlaniningPolishId" },
    { title: "Symm", field: "PlaniningSymmId" },
    { title: "Flu", field: "PlaniningFluId" },
    { title: "Comment", field: "PlaniningComment" },
    /* Gradding */
    { title: "Grade Date", field: "GraddingGradDate" },
    { title: "Cts", field: "GraddingWeight" },
    { title: "Clr ", field: "GraddingColorId" },
    { title: "Shape ", field: "GraddingShapeId" },
    { title: "Purity ", field: "GraddingPurityId" },
    { title: "Cut ", field: "GraddingCutId" },
    { title: "Polish ", field: "GraddingPolishId" },
    { title: "Symm ", field: "GraddingSymmId" },
    { title: "flu ", field: "GraddingFlourenceId" },
    /*labresult*/
    { title: "Result Date ", field: "labresultCreatedAt", },
    { title: "Cts ", field: "labresultCts" },
    { title: "Clr ", field: "labresultColorId" },
    { title: "Shape ", field: "labresultShapeId" },
    { title: "Purity ", field: "labresultPurityId" },
    { title: "Cut ", field: "labresultCutId" },
    { title: "Polish ", field: "labresultPolishId" },
    { title: "Symm ", field: "labresultSymmId" },
    { title: "Flu ", field: "labresultFluId" },


]
export const PdfView = (props) => {
    const [AllData, SetAllData] = useState([])

    useEffect(() => {
        let Data = props?.AllData || []
        let AllData = []
        Data.map(d => {

            let TempData = {
                RoughLotNo: d?.lotNo || "",
                RoughPktNo: d?.packetDetail?.pktNo || null,
                RoughCts: d?.packetDetail?.cts || 0,
                RoughRfId: d?.packetDetail?.expcts || null,
                RoughColorId: d?.packetDetail?.colorId?.shortName || null,
                RoughPurityId: d?.packetDetail?.purityId?.shortName || null,
                RoughFlsId: d?.packetDetail?.flsId?.shortName || null,

                PlaniningCreatedAt: d?.roughPlanning?.createdAt || null,
                PlaniningExpCts: d?.roughPlanning?.expCts || 0,
                PlaniningShapeId: d?.roughPlanning?.shapeId?.shortName || "",
                PlaniningPurityId: d?.roughPlanning?.purityId?.shortName || "",
                PlaniningCutId: d?.roughPlanning?.cutId?.shortName || "",
                PlaniningPolishId: d?.roughPlanning?.polishId?.shortName || "",
                PlaniningSymmId: d?.roughPlanning?.symmId?.shortName || "",
                PlaniningFluId: d?.roughPlanning?.fluId?.shortName || "",
                PlaniningComment: d?.roughPlanning?.comment || "",

                GraddingGradDate: d?.gradingDetail?.gradDate || null,
                GraddingWeight: d?.gradingDetail?.weight || "0",
                GraddingColorId: d?.gradingDetail?.colorId?.shortName || "",
                GraddingShapeId: d?.gradingDetail?.shapeId?.shortName || "",
                GraddingPurityId: d?.gradingDetail?.purityId?.shortName || "",
                GraddingCutId: d?.gradingDetail?.cutId?.shortName || "",
                GraddingPolishId: d?.gradingDetail?.polishId?.shortName || "",
                GraddingSymmId: d?.gradingDetail?.symmId?.shortName || "",
                GraddingFlourenceId: d?.gradingDetail?.flourenceId?.shortName || "",
                // /*labresult*/
                labresultCreatedAt: d?.labResult?.createdAt || null,
                labresultCts: d?.labResult?.cts || 0,
                labresultColorId: d?.labResult?.colorId?.shortName || "",
                labresultShapeId: d?.labResult?.shapeId?.shortName || "",
                labresultPurityId: d?.labResult?.purityId?.shortName || "",
                labresultCutId: d?.labResult?.cutId?.shortName || "",
                labresultPolishId: d?.labResult?.polishId?.shortName || "",
                labresultSymmId: d?.labResult?.symmId?.shortName || "",
                labresultFluId: d?.labResult?.fluId?.shortName || "",

            }
            AllData.push(TempData)
            return null
        })
        SetAllData(AllData)
    }, [props.AllData])
    return <div id="printdivcontent"
        style={{ display: "none" }}
    >
        <div style={{ marginBottom: 15 }}>
            <h2 style={{ textAlign: "center" }}>Planning Comparision Report </h2>
            < p style={{ margin: 0, fontSize: 18, fontWeight: "bold" }}>Lotno : {props?.lotNo}</p>
        </div >
        <div style={{ width: "100%", overflow: "scroll", display: "flex" }}>
            <div >
                <table style={{ width: '100%', border: "1px solid", borderCollapse: "collapse" }} id="ExportTable">
                    <tr>
                        <th colSpan={3}></th>
                        <th>Rough</th>
                        <th colSpan={3} style={{ borderRight: "1px solid black" }}></th>

                        <th colSpan={4}></th>
                        <th>Planing</th>
                        <th colSpan={4} style={{ borderRight: "1px solid black" }}></th>
                        <th colSpan={4} ></th>
                        <th>Grading</th>
                        <th colSpan={4} style={{ borderRight: "1px solid black" }}></th>
                        <th colSpan={4} ></th>
                        <th>Lab Result</th>
                        <th colSpan={4} ></th>
                    </tr>
                    <tr >
                        {
                            _.map(column, (d) => {
                                return <th style={{ width: 100, fontSize: 10, border: "1px solid #ddd", textAlign: "center" }}> {d.title}</th>
                            })
                        }
                    </tr>

                    {_.map(AllData, (Roughd) => {
                        return <tr >
                            {
                                _.map(column, (d) => {
                                    if (d.field === "PlaniningCreatedAt" || d.field === "GraddingGradDate" || d.field === "labresultCreatedAt")
                                        return <td style={{ fontSize: 10, border: "1px solid #ddd", textAlign: "center" }}>{Roughd[d.field] ? moment(Roughd[d.field]).format('DD/MM/YYYY') : ""}</td>
                                    else if (d.field === "RoughCts" || d.field === "RoughRfId" || d.field === "PlaniningExpCts" || d.field === "GraddingWeight" || d.field === "labresultCts") {
                                        return <td style={{ fontSize: 10, border: "1px solid #ddd", textAlign: "center" }}>{Tofixed(Roughd[d.field])}</td>
                                    }
                                    else
                                        return <td style={{ fontSize: 10, border: "1px solid #ddd", textAlign: "center" }}>{Roughd[d.field]}</td>
                                })
                            }
                        </tr>
                    })}
                </table>
            </div>

        </div>
    </div >
}