import React, { useState, useEffect, useRef } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks'
import MaterialTable from 'material-table';
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import ROLES from './RoleQuery';
import { INSERT_ROLE, UPDATE_ROLE, DELETE_ROLE } from './RoleMutation';
import { ROLES_SUBSCRIBE } from './RoleSubscription';
// import { ValidateEmail } from '../../../components/Comman/validate.js'
// import { RoleTypes } from '../../../components/Constant'
import { FormatError } from '../../../components/Comman/FormatError';
import RoleChild from './RoleChild'

const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1, },
  noMargin: { margin: 0 }
}));

const columns = [
  {
    title: 'Role*',
    field: 'role',
    validate: rowData => (rowData.role && rowData.role !== "") ? true : { isValid: false, helperText: `can't be empty` }
  },
  { title: 'Active', field: 'isActive', type: "boolean", initialEditValue: true, sorting: false },
]


function Roles(props) {
  const classes = useStyles();
  const tableRef = useRef();

  const [pageSize, setPageSize] = useState(10)
  const [selectedRow, setSelectedRow] = useState(null);
  const [loader, setLoader] = useState(false);

  const [roleMutation] = useMutation(INSERT_ROLE)
  const [updateMutation] = useMutation(UPDATE_ROLE)
  const [deleteMutation] = useMutation(DELETE_ROLE)

  const { subscribeToMore, fetchMore } = useQuery(ROLES, {
    variables: { page: 1, limit: 10, filter: JSON.stringify({}), sort: { key: 'createdAt', type: -1 } },
    fetchPolicy: 'cache-and-network'
  })

  useEffect(() => {
    if (subscribeToMore) {
      const unsubscribe = subscribeToMore({
        document: ROLES_SUBSCRIBE,
        updateQuery: (previousResult, { subscriptionData }) => {
          if (!subscriptionData.data) {
            return previousResult;
          }
          tableRef.current && tableRef.current.onQueryChange()
          return previousResult;
        },
      });
      return () => unsubscribe();
    }
  }, [subscribeToMore]);

  const getRolesData = (query) => {
    setLoader(true)
    return new Promise(async (resolve, reject) => {
      let filter = {}, sort = { key: 'createdAt', type: -1 }
      query && query.filters.forEach(item => {
        if (item.column.field === "isActive") {
          if (item.value)
            filter = { ...filter, [item.column.field]: item.value === "checked" ? true : false }
          else {
            delete filter.isActive
            filter = { ...filter }
          }
        } else if (item.value.length === 0) {
          delete filter[`${item.column.field}`]
          filter = { ...filter }
        } else
          filter = { ...filter, [item.column.field]: item.value }
      });

      if (query && query.orderBy && query.orderDirection !== "") {
        sort = { key: query.orderBy.field, type: query.orderDirection === 'asc' ? 1 : -1 }
      }
      setPageSize(query.pageSize)
      try {
        let result = await fetchMore({
          variables: { page: query.page + 1, limit: query.pageSize, filter: JSON.stringify(filter), sort: sort },
          fetchPolicy: 'network-only',
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return fetchMoreResult;
          }
        })
        setLoader(false)
        resolve({
          data: result?.data?.getRoles?.data || [],
          page: query.page,
          totalCount: result?.data?.getRoles?.count || 0,
        })
      } catch (error) {
        console.log(FormatError(error))
        setLoader(false)
      }
    })
  }


  const AddRole = (newData) => {
    return new Promise((resolve, reject) => {
      setLoader(true)
      let log = {
        action: 'INSERT',
        actionOn: 'roles',
        actionName: 'insertRole',
        oldValue: "",
        message: `${newData?.role || ''} role was created.`
      }
      if ((newData.role).trim().toLowerCase() === 'admin') {
        setLoader(false)
        reject()
        toast.warning('You can not create admin.');
      } else {
        roleMutation({ variables: { input: newData, log } }).then((data) => {
          setLoader(false)
          toast.success('Role created successfully.');
          resolve()
        }).catch((e) => {
          setLoader(false)
          reject()
          toast.error(FormatError(e))
        })
      }
    })
  }
  const UpdateRole = (newData, oldData) => {
    return new Promise((resolve, reject) => {
      setLoader(true)
      let log = {
        action: 'UPDATE',
        actionOn: 'roles',
        actionName: 'updateRole',
        oldValue: JSON.stringify(oldData),
        message: `${oldData?.role || ''} role was updated.`
      }
      if ((oldData.role).toLowerCase() === 'admin') {
        reject()
        setLoader(false)
        toast.warning('You can not update admin.');
      } else {
        let update = { role: newData.role, isActive: newData.isActive, id: newData.id, permissions: newData.permissions }
        updateMutation({ variables: { input: update, log } }).then((data) => {
          setLoader(false)
          toast.success('Role updated successfully.');
          resolve()
        }).catch((e) => {
          reject()
          setLoader(false)
          toast.error(FormatError(e))
        })
      }
    })
  }
  const DeleteRole = (oldData) => {
    return new Promise((resolve, reject) => {
      if ((oldData.role).toLowerCase() === 'admin') {
        reject()
        toast.warning('You can not delete admin.');
      } else {
        setLoader(true)
        let log = {
          action: 'DELETE',
          actionOn: 'roles',
          actionName: 'deleteRole',
          oldValue: JSON.stringify(oldData),
          message: `${oldData?.role || ''} role was deleted.`
        }
        deleteMutation({ variables: { id: oldData.id, log } }).then((data) => {
          setLoader(false)
          toast.success('Role deleted successfully.');
          resolve()
        }).catch((e) => {
          setLoader(false)
          reject()
          toast.error(FormatError(e))
        })
      }
    })
  }

  const onSavePermissions = (row, permissions, name) => {
    row.permissions = permissions
    UpdateRole(row, { role: name })
  }
  useEffect(() => {
    document.getElementsByClassName("parentTable")[0].firstElementChild.firstElementChild.firstChild.remove()
  }, [])

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <div className="parentTable">
            <MaterialTable
              title="Roles"
              tableRef={tableRef}
              isLoading={loader}
              columns={columns}
              data={query => getRolesData(query)}
              icons={{ Add: props => { return (<div> <i className="fa fa-plus-square" ></i> Roles </div>) } }}
              options={{
                emptyRowsWhenPaging: false,
                debounceInterval: 300,
                padding: 'dense',
                showTitle: false,
                pageSize: pageSize,
                pageSizeOptions: [5, 10, 15, 20, 25, 50],
                addRowPosition: 'first',
                filtering: true,
                sorting: true,
                search: false,
                filterCellStyle: { padding: '5px', },
                maxBodyHeight: window.innerHeight - 215,
                rowStyle: rowData => ({
                  backgroundColor: (selectedRow === rowData.tableData.id) ? 'rgb(212, 218, 255)' : '#f5f5f5'
                }),
                headerStyle: { backgroundColor: 'whitesmoke' }
              }}
              editable={{
                onRowAdd: (newData) => AddRole(newData),
                onRowUpdate: (newData, oldData) => UpdateRole(newData, oldData),
                onRowDelete: (oldData) => DeleteRole(oldData)
              }}
              onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
              detailPanel={rowData => <RoleChild rowData={rowData} onSavePermissions={(row, permissions, name) => onSavePermissions(row, permissions, name)} />}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default withRouter(Roles)