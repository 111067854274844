import { FormControl, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import React, { useMemo } from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { useStyles } from '../../../../components/Comman/Accordian'
import { LOTDATA } from '../../../Transaction/Packet/PacketQuery'
import _ from 'lodash'
export const LotNoFilterOption = ({ value, setValue }) => {
    const classes = useStyles()

    const { error, data, loading } = useQuery(LOTDATA, {
        fetchPolicy: 'cache-and-network',
    })

    const shortCodeDataDropDown = useMemo(() => {
        let Lortdata = _.filter(data?.getAllKapanDetail || [], (d) => d.lotNo !== null)
        const defaultProps = {
            options: Lortdata,
            getOptionLabel: (option) => option.lotNo
        };
        return <Autocomplete
            options={defaultProps.options}
            value={value}
            getOptionLabel={(option) => option.lotNo}
            onChange={(event, newValue) => {
                setValue(newValue)
            }}
            className="lotmarginCustom"
            renderInput={(params) => <TextField label="Lot No"  {...params} margin="normal" />}
        />
    }, [data, value])


    if (error)
        return <>error..........</>
    else if (loading)
        return <>loading..........</>
    else
        return <FormControl className={classes.formControl} style={{ paddingTop: 0 }}>
            {shortCodeDataDropDown}
        </FormControl>
}