import gql from 'graphql-tag';

export const GETBRANCHTRANSFERREPORT = gql`
query getBranchTransferReport($filter:String!){
  getBranchTransferReport(filter:$filter){
    date
    btNo
    pktType
    lotNoId{
      lotNo
    }
    pktNoId{
      pktNo
    }
    cts
    roughCts
  }
}
`;

