import gql from 'graphql-tag';

export const STOCKMASTER = gql`
query getStockMasterData($page: Int, $limit: Int, $filter: String, $sort: stockMasterSort){
  getStockMasterData(page:$page, limit:$limit, filter:$filter, sort:$sort){
    count
    data{
      id
        inwardDetailId
        lotNoId{
          id
          lotNo
        }
        pktNoId{
          id
          pktNo
        }
        location
        rfId
        cts
        colorId {
          id
          shortName
        }
        shapeId {
          id
          shortName
        }
        cutId{
          id
          shortName
        }
        purityId{
          id
          shortName
        }
        symmId{
          id
          shortName
        }
        fluId {
          id
          shortName
        }
        measurment
        depth
        table
        brown
        gradPer
        rapRate
        createdBy
        updatedBy
        createdAt
        updatedAt
    }
  }
}
`;
export const GETPLANINGCOMPARISIONREPORT = gql`
query getPlanningComparisionReport($filter: String!){
  getPlanningComparisionReport(filter:$filter){
      lotNo
    packetDetail {
      cts
      pktNo
      expcts
      colorId{
        shortName
      }
      purityId{
        shortName
      }
      flsId{
        shortName
      }
    }
    subPkt{
      pktNo
      cts
      colorId{
        shortName
      }
      flsId{
        shortName
      }
      purityId{
        shortName
      }
      expcts
    }
    gradingDetail{
      gradDate
      weight
      colorId{
        shortName
      }
      shapeId{
       shortName
      }
      purityId{
        shortName
      }
      cutId{
        shortName
      }
      polishId{
        shortName
      }
      symmId{
        shortName
      }
      flourenceId{
        shortName
      }
    }
    roughPlanning{
      createdAt
      expCts
      colorId{
        shortName
      }
      shapeId{
        shortName
      }
      purityId{
        shortName
      }
      cutId{
        shortName
      }
      polishId{
        shortName
      }
      symmId{
        shortName
      }
      fluId{
        shortName
      }
      comment
    }
    labResult{
      createdAt
      cts
      colorId{
        shortName
      }
      shapeId{
        shortName
      }
      purityId{
        shortName
      }
      cutId{
        shortName
      }
      polishId{
        shortName
      }
      symmId{
        shortName
      }
      fluId{
        shortName
      }
    }
  }
}
`;

