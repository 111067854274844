import React, { useState, useEffect, useRef } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks'
import MaterialTable, { MTableFilterRow, MTableHeader } from 'material-table';
import { withRouter } from "react-router-dom";
import Button from '@material-ui/core/Button';
import "date-fns";
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from "@date-io/date-fns";
import { TextField } from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { makeStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import _ from 'lodash';
import moment from 'moment';
import { UPDATELOCATION } from './UpdateLocationQuery';
import { UPDATE_ISSUERETURN_DETAIL } from '../../Transaction/IssueReturnMaster/IssueReturnMasterMutation';
import { UPDATELOCATION_SUBSCRIBE } from './UpdateLocationSubscription'
import { LotNo } from '../../../components/DropdownSelect/LotNo';
import { PktNoFromLotNo } from '../../../components/DropdownSelect/PktNoFromLotNo';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { GetShortCodeData, GradingLocationSelectComponent } from '../../../components/DropdownSelect/SelectComponents/ShortCodeSelectComponent'
import { Tofixed } from '../../../components/Comman/Tofix';


const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1, },
  noMargin: { margin: 0 },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 160,
  },
}));



const columns = [
  { title: 'Location', field: 'location' },
  {
    title: 'Lot No',
    field: 'lotNoId',
    headerStyle: { minWidth: 130 },
    render: (rowData) => rowData?.lotNoId?.lotNo || null,
    editComponent: props => {
      return props?.rowData?.lotNoId?.lotNo || null
    }
  },
  {
    title: 'Pkt No',
    field: 'pktNoId',
    disabled: true,
    headerStyle: { minWidth: 130 },
    render: (rowData) => rowData?.pktNoId?.pktNo || "-",
    editComponent: props => {
      return props?.rowData?.pktNoId?.pktNo || null
    }
  },
  {
    title: 'Rfid',
    field: 'rfId',
    editable: 'never',
    render: (rowData) => rowData?.rfId || "-",
  },
  {
    title: 'Lab',
    field: 'labId',
    render: (rowData) => rowData?.labId?.partyName,
    editComponent: props => {
      return props?.rowData?.labId?.partyName || null
    }
  },
  {
    title: 'Certificate',
    field: 'certificateNo',
    editable: 'never',
    render: (rowData) => rowData?.certificateNo || "-"
  },
  {
    title: 'Cts',
    field: 'cts',
    render: (rowData) => Tofixed(rowData?.cts || 0),
    editable: 'never'
  },
  {
    title: 'Color', field: 'colorId', editable: 'never',
    render: (rowData) => rowData?.colorId?.shortName || "_"
  },
  {
    title: 'Shape', field: 'shapeId', editable: 'never',
    render: (rowData) => rowData?.shapeId?.shortName || "_"
  },
  {
    title: 'Purity', field: 'purityId', editable: 'never',
    render: (rowData) => rowData?.purityId?.shortName || "_"
  },
  {
    title: 'Cut', field: 'cutId', editable: 'never',
    render: (rowData) => rowData?.cutId?.shortName || "_"
  },
  {
    title: 'Polish', field: 'polishId', editable: 'never',
    render: (rowData) => rowData?.polishId?.shortName || "_"
  },
  {
    title: 'Symmentery', field: 'symmId', editable: 'never',
    render: (rowData) => rowData?.symmId?.shortName || "_"
  },
  {
    title: 'Flu', field: 'fluId', editable: 'never',
    render: (rowData) => rowData?.fluId?.shortName || "_"
  },
  { title: 'Status', field: 'status', editable: 'never' },
  { title: 'Measurment', field: 'measurement', editable: 'never' },
  { title: 'Brown', field: 'brown', editable: 'never', },
  { title: 'Grd %', field: 'gradePer', editable: 'never', headerStyle: { minWidth: 120 }, },
  {
    title: 'Raprate', field: 'rateRap',
    editable: 'never',
    render: (rowData) => Tofixed(rowData?.rateRap || 0),
  },
  {
    title: 'Rap Amt', field: 'rapAmt',
    editable: 'never',
    headerStyle: { minWidth: 130 },
    render: (rowData) => Tofixed(rowData?.rapAmt || 0),
  },
  { title: 'Stk %', field: 'stkDiscPer', editable: 'never', headerStyle: { minWidth: 120 } },
]

function UpdateLocation(props) {
  const classes = useStyles();
  const tableRef = useRef();

  const [filter, setFilter] = useState({})
  const [pageSize, setPageSize] = useState(10)
  const [page, setPage] = useState(0)
  const [selectedRow, setSelectedRow] = useState(null);
  const [sort, setSort] = useState({ key: 'createdAt', type: -1 });
  const [orderBy, setOrderBy] = useState(-1);
  const [orderDirection, setOrderDirection] = useState("");
  const [lotNo, setLotNo] = useState(null);
  const [pktNo, setPktNo] = useState(null);
  const [location, setLocation] = useState(null);
  const permissions = props?.session?.me?.role?.permissions;
  const role = props?.session?.me?.role?.role;
  const path = props?.location?.pathname;
  let permission = _.find(permissions, function (o) { return o.name === path; })

  const [updateLocationMasterMutation] = useMutation(UPDATE_ISSUERETURN_DETAIL)

  const { error, data, subscribeToMore, loading } = useQuery(UPDATELOCATION, {
    variables: { page: page + 1, limit: pageSize, filter: JSON.stringify(filter), sort: sort },
    fetchPolicy: 'cache-and-network'
  })


  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: UPDATELOCATION_SUBSCRIBE,
      updateQuery: (previousResult, { subscriptionData }) => {
        if (!subscriptionData.data) {
          return previousResult;
        }
        const { IssueReturnDetailChange } = subscriptionData.data;
        if (IssueReturnDetailChange && IssueReturnDetailChange.keyType === "UPDATE") {
          let updatePaymentData = IssueReturnDetailChange && IssueReturnDetailChange.data;
          let AllPaymentData = previousResult.getIssueReturnDetailForUpdateLocation.data;
          let updatedPaymentData = _.map(AllPaymentData, (d) => {
            if (d.id === updatePaymentData.id) {
              return updatePaymentData
            }
            else return d
          })
          return {
            ...previousResult,
            getIssueReturnDetailForUpdateLocation: {
              ...previousResult.getIssueReturnDetailForUpdateLocation,
              count: previousResult.getIssueReturnDetailForUpdateLocation.count,
              data: [
                ...updatedPaymentData
              ]
            }
          }
        } else if (IssueReturnDetailChange && IssueReturnDetailChange.keyType === "DELETE") {
          let updatePaymentData = IssueReturnDetailChange && IssueReturnDetailChange.data;
          let AllPaymentData = previousResult.getIssueReturnDetailForUpdateLocation.data;
          let updatedPaymentData = _.reject(AllPaymentData, d => {
            return d.id === updatePaymentData.id
          })
          return {
            ...previousResult,
            getIssueReturnDetailForUpdateLocation: {
              ...previousResult.getIssueReturnDetailForUpdateLocation,
              count: parseInt(previousResult.getIssueReturnDetailForUpdateLocation.count) - 1,
              data: [...updatedPaymentData]
            }
          }
        } else if (IssueReturnDetailChange && IssueReturnDetailChange.keyType === "INSERT") {
          let users = [
            ...[IssueReturnDetailChange.data],
            ...previousResult.getIssueReturnDetailForUpdateLocation.data,
          ]
          return {
            ...previousResult,
            getIssueReturnDetailForUpdateLocation: {
              ...previousResult.getIssueReturnDetailForUpdateLocation,
              count: parseInt(previousResult.getIssueReturnDetailForUpdateLocation.count) + 1,
              data: users.slice(0, pageSize),
            },
          };
        }
      },
    });
    return () => unsubscribe();
  }, [pageSize, subscribeToMore]);

  useEffect(() => {
    tableRef.current && tableRef.current.onQueryChange()
  }, [data]);

  columns.map((d, i) => {
    if (d.field === "location") {
      d.editComponent = (props) => {
        let Cts = props?.rowData?.cts || null
        if (Cts) {
          return <GradingLocationSelectComponent key={i} propsData={props} TYPE="location" data={GetShortCodeData("LOCATION")} valueDefault={props?.rowData?.location || null} />
        } else return ""
      }
    }
    return null
  })
  const getRecertyMasterDataV1 = () => {
    setFilter({ "lotNo": lotNo, "pktNo": pktNo })
  }
  const getRecertyMasterData = () => {
    return new Promise((resolve, reject) => {
      if (error) {
        reject(error)
      }
      else {
        let result = data?.getIssueReturnDetailForUpdateLocation?.data || []
        resolve({
          data: result,
          page: page,
          totalCount: data?.getIssueReturnDetailForUpdateLocation?.count || 0,
        })
      }
    })
  }

  const generateFilterString = (field, filString) => {
    let data = filter
    if (field === "date") {
      if (filString)
        data = { ...filter, [field]: moment(filString).toISOString() }
      else {
        delete filter[`${field}`]
        data = { ...filter }
      }
    } else if (filString.length === 0) {
      delete filter[`${field}`]
      data = { ...filter }
    } else {
      if (field === "currencyRate" || field === "currencyAmount" || field === "partyAmtZar" || field === "adgAmtZar") {
        filString = parseFloat(filString)
        data = { ...filter, [field]: filString }
      }
      else {
        data = { ...filter, [field]: filString }
      }
    }
    setFilter(data)
  }

  const setSorting = (colId, type) => {
    if (!type) {
      setSort({ key: 'createdAt', type: -1 })
      setOrderBy(-1)
      setOrderDirection("")
    } else {
      setSort({ key: columns[colId].field, type: type === 'asc' ? 1 : -1 })
      setOrderBy(colId)
      setOrderDirection(type)
    }
  }

  let defaultProps = LotNo('', '', '').defaultProps
  let PktData = PktNoFromLotNo(lotNo ? lotNo : null)
  const defaultProps2 = {
    options: PktData,
    getOptionLabel: (option) => option.pktNo
  };
  const defaultProps3 = {
    options: GetShortCodeData("LOCATION"),
    getOptionLabel: (option) => option.shortName
  };

  const UpdateLocationDetails = (newData, oldData) => {
    delete newData.createdBy
    delete newData.updatedBy
    delete newData.createdAt
    delete newData.updatedAt
    delete newData.__typename
    let updateObj = {
      id: newData.id,
      location: location && location != null ? location : newData.location
    }
    return new Promise((resolve, reject) => {
      updateLocationMasterMutation({ variables: { input: updateObj } }).then((data) => {
        setLocation("")
        toast.success('new location updated successfully.');
        resolve()
      }).catch((e) => {
        reject()
        toast.warn("data duplication found,please try another.")
      })
    })
  }

  return (
    <div className={classes.root}>
      <div className="row">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid item container justify="space-around" style={{ paddingBottom: 15 }}>
            <Grid xs={4} item>
            </Grid>

            <Grid xs={1} item>
              <Autocomplete
                {...defaultProps}
                onChange={(e, newdata) => {
                  if (newdata) {
                    setLotNo(newdata.id)
                  }
                }}
                className="lotmarginCustom"
                renderInput={(params) => <TextField label="Lot No"  {...params} margin="normal" />}
              />
            </Grid>
            <Grid xs={1} item style={{ marginLeft: 5 }}>
              <Autocomplete
                {...defaultProps2}
                onChange={(e, newdata) => {
                  if (newdata) {
                    setPktNo(newdata.id)
                  }

                }}
                className="lotmarginCustom"
                renderInput={(params) => <TextField label="PKT No"  {...params} margin="normal" />}
              />
            </Grid>
            <Grid xs={1} item style={{ marginLeft: 5 }}>
              <Button
                variant="contained"
                color="primary"
                style={{ height: 34, marginLeft: 5, marginTop: 12 }} onClick={e => getRecertyMasterDataV1()}>
                Search
              </Button>
            </Grid>
            <Grid xs={1} item style={{ marginLeft: 0 }}>
              <Autocomplete
                {...defaultProps3}
                onChange={(e, newdata) => {
                  if (newdata) {
                    setLocation(newdata.shortName)
                  }

                }}
                className="lotmarginCustom"
                renderInput={(params) => <TextField label="New Location"  {...params} margin="normal" />}
              />
            </Grid>

            <Grid xs={3} item>
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
      </div>

      <Grid container>
        <Grid item xs={12}>
          <MaterialTable
            title="Update Location"
            tableRef={tableRef}
            columns={columns}
            isLoading={loading}
            data={query => getRecertyMasterData()}
            options={{
              emptyRowsWhenPaging: false,
              padding: 'dense',
              pageSize: pageSize,
              pageSizeOptions: [5, 10, 15, 20, 25, 50],
              addRowPosition: 'first',
              filtering: false,
              sorting: true,
              search: false,
              filterCellStyle: { padding: '5px', },
              maxBodyHeight: window.innerHeight - 215,
              rowStyle: rowData => ({
                backgroundColor: (selectedRow === rowData.tableData.id) ? 'rgb(212, 218, 255)' : '#f5f5f5',
                fontSize: 14
              }),
              headerStyle: { backgroundColor: 'whitesmoke' }
            }}
            editable={{
              isEditHidden: (rowData) => (role === 'admin') ? false : !permission.isUpdate,
              onRowUpdate: (newData, oldData) => UpdateLocationDetails(newData, oldData),
            }}
            onChangeRowsPerPage={size => setPageSize(size)}
            onChangePage={page => setPage(page)}
            onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
            components={{
              FilterRow: props => {
                return <MTableFilterRow
                  {...props}
                  columns={props.columns}
                  onFilterChanged={(columnId, value) => {
                    props.onFilterChanged(columnId, value);
                    generateFilterString(props.columns[columnId].field, value)
                  }}
                />
              },
              Header: props => {
                return <MTableHeader
                  {...props}
                  orderBy={orderBy}
                  orderDirection={orderDirection}
                  onOrderChange={(orderBy, orderDirection) => {
                    props.onOrderChange(orderBy, orderDirection)
                    setSorting(orderBy, orderDirection)
                  }}
                />
              }
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}



export default withRouter(UpdateLocation)
