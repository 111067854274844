import React, { useState } from 'react'
import AuthCode from 'react-auth-code-input'
import { Card, Button, Container } from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';

import './style.css'

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        backgroundColor: "#47528e",
    },
    buttonProgress: {
        color: "#f50357",
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    textRight: {
        justifyContent: 'flex-end'
    }
}));

export const TwoFa = () => {
    const classes = useStyles();
    const [Otp, setOtp] = useState('')
    const handleOnChange = (res) => {
        setOtp(res)
    }

    const cancelHandler = () => {
        // history.push('/')
    }

    return <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
            <Avatar className={classes.avatar}> <LockOutlinedIcon /> </Avatar>
            <Typography component="h1" variant="h5"> Sign-in Code </Typography>
            <div className="tfa-main" style={{ padding: 10 }}>
                <div
                    title=""
                    style={{ justifyContent: 'center', alignItems: 'center', width: 500 }}
                    className="tfa-card"
                >
                    <div className="tfa-data">

                        <div className="otpInput">
                            <AuthCode onChange={handleOnChange} allowedCharacters="numeric" />
                            <div id="captcha_react"></div>
                        </div>
                        <div className="tfa-footer" style={{ display: "flex" }}>
                            <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}
                                style={{ marginRight: 20 }}
                            // onClick={() => OtpVerificationData()}
                            >
                                Submit
                            </Button>
                            <Button
                                variant="contained"
                                color="primary" className={classes.submit}
                            >
                                {' '}
                                Resend code{' '}
                            </Button>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    </Container>
}