import React, { useState } from 'react';
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import SIGN_IN from './LoginMutation';
import { useMutation } from "@apollo/react-hooks";
import { FormatError } from "../../components/Comman/FormatError";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#47528e"
  },
  buttonProgress: {
    color: "#f50357",
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  textRight: {
    justifyContent: 'flex-end'
  }
}));

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Kismet Diamonds
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

function Login(props) {

  const classes = useStyles();
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)
  const [signInMutation] = useMutation(SIGN_IN)

  const onSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (!form.checkValidity()) {
      event.stopPropagation();
      form.classList.add('was-validated');
      toast.warn('Please fill the required fields...')
    } else {
      setLoading(true)
      localStorage.removeItem('token')
      signInMutation({ variables: { email: email, password: password } }).then(async ({ data }) => {
        localStorage.setItem('token', data.signIn.token);
        localStorage.setItem('loggedUser', JSON.stringify(data.signIn.user));
        await props.refetch();
        props.history.push('/dashboard');
        toast.success("user logged in successfully!")
      }).catch((e) => {
        toast.error(FormatError(e));
        setLoading(false)
      });
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}> <LockOutlinedIcon /> </Avatar>
        <Typography component="h1" variant="h5"> Sign in </Typography>

        <form className={classes.form} noValidate onSubmit={event => onSubmit(event)}>

          <TextField variant="outlined" margin="normal" required fullWidth id="email" label="Email Address" name="email" autoComplete="email" autoFocus
            value={email} onChange={(e) => { setEmail(e.target.value) }}
          />
          <TextField variant="outlined" margin="normal" required fullWidth name="password" label="Password" type="password" id="password" autoComplete="current-password"
            value={password} onChange={(e) => { setPassword(e.target.value) }}
          />

          <Button type="submit" fullWidth variant="contained" disabled={loading} color="primary" className={classes.submit} >
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />} Sign In
                </Button>

          <Grid container className={classes.textRight}>
            <Grid item>
              <Link href="/forgotpassword" variant="body2"> Forgot password? </Link>
            </Grid>
          </Grid>
        </form>

      </div>
      <Box mt={8}> <Copyright /> </Box>
    </Container >
  );
}


export default withRouter(Login)










