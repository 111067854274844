import React, { useState, useEffect } from 'react'
import { useQuery } from "@apollo/react-hooks";
import { Tofixed } from '../../../components/Comman/Tofix'
import { ISSUERETURNDETAILQUERY } from './IssueReturnMasterQuery'
import _ from 'lodash'
import moment from 'moment'
import COMPANY from '../../Master/Company/CompanyQuery';

export const PdfInvoice = ({ selectedRowData, selectedChildRowData }) => {
    const [Result, setResult] = useState([])
    const [comanyResult, setComanyResult] = useState(null)

    const { data: comanyData } = useQuery(COMPANY, {
        variables: { page: 1, limit: 10, filter: JSON.stringify({}), sort: { key: 'createdAt', type: -1 } },
        fetchPolicy: 'cache-and-network'
    })

    useEffect(() => {
        let comanyData1 = comanyData?.getCompanies?.data || []
        if (comanyData1.length > 0) {
            setComanyResult(comanyData1[0])
        }
    }, [comanyData])


    let Data = selectedRowData[0]
    const { data } = useQuery(ISSUERETURNDETAILQUERY, {
        variables: { id: Data?.id },
        fetchPolicy: 'cache-and-network'
    });

    useEffect(() => {
        let res = data?.getAllIssueReturnDetail || []
        let Tempres = []
        res.map((d) => {
            let temp = {
                lotNo: d?.lotNoId?.lotNo || "",
                cts: d?.cts || 0,
                lab: d?.lab?.partyName || "",
                rapAmt: d?.rapAmt || 0,
                pktNo: d?.pktNoId?.pktNo || ""
            }
            if (selectedChildRowData.length > 0) {
                if (selectedChildRowData.includes(d.id)) {
                    Tempres.push(temp)
                }
            } else {
                Tempres.push(temp)
            }
            return null
        })
        setResult(Tempres)
    }, [data, selectedChildRowData])

    useEffect(() => {
        let res = data?.getAllIssueReturnDetail || []
        let Tempres = []
        res.map((d) => {
            let temp = {
                lotNo: d?.lotNoId?.lotNo || "",
                cts: d?.cts || 0,
                lab: d?.labId?.partyName || "",
                rapAmt: d?.rapAmt || 0,
                pktNo: d?.pktNoId?.pktNo || ""
            }
            if (selectedChildRowData.length > 0) {
                if (selectedChildRowData.includes(d.id)) {
                    Tempres.push(temp)
                }
            } else {
                Tempres.push(temp)
            }
            return null
        })
        setResult(Tempres)
    }, [selectedChildRowData, data])
    return <div style={{ display: "none" }} id="printdivcontent">
        <div style={{ width: "100%", display: "flex" }}>
            <div style={{ width: "49%", border: "1px solid #666", marginRight: 15 }}>
                <div style={{ display: "flex" }}>
                    <div style={{ width: "83%", textAlign: "center", margin: 10 }}>
                        <h1 style={{ margin: 0 }}>Kismet Diamonds</h1>
                    </div>
                    <div style={{ width: "17%", padding: 10, borderLeft: "1px dotted black", borderBottom: "1px dotted black" }}>
                        <p style={{ margin: 0 }}>Reg</p>
                        <p style={{ margin: 0 }}>Vat No. : {comanyResult?.vatNo || "-"}</p>
                    </div>
                </div>
                <div>
                    <p style={{ padding: 5, fontSize: 15, margin: 0 }}>Physical Address</p>
                    <p style={{ margin: "2px 10px 2px 72px", fontSize: 15, textAlign: "-webkit-center" }}>
                        {comanyResult?.address1 || "-"}, {comanyResult?.address2} {comanyResult?.address3}, {comanyResult?.city} ,{comanyResult?.state}
                    </p>
                </div>
                <div style={{ display: "flex" }}>
                    <div style={{ width: "35%" }}>
                        <p style={{ margin: 0, padding: 5, fontSize: 15 }}>Phone : {comanyResult?.phone1}, {comanyResult?.phone2} </p>
                    </div>
                    <div style={{ width: "65%" }}>
                        <p style={{ margin: 0, padding: 5, fontSize: 15 }}>{comanyResult?.contactPerson1Name} : {comanyResult?.mobileNo1}  </p>
                    </div>
                </div>
                <div style={{ display: "flex" }}>
                    <div style={{ width: "36%" }}>
                        <p style={{ margin: 0, paddingLeft: 5, fontSize: 15 }}>{comanyResult?.email} </p>
                    </div>
                    <div style={{ width: "49%" }}>
                        <p style={{ margin: 0, fontSize: 15 }}> {comanyResult?.contactPerson2Name} : {comanyResult?.mobileNo2}  </p>
                    </div>
                    <div style={{ width: "15%" }}>
                        <p style={{ margin: 0, fontSize: 15 }}>No.: {Data?.issueNo || ""}</p>
                    </div>
                </div>

                <div>
                    <table style={{ width: "100%", borderCollapse: "collapse" }}>
                        <tbody style={{ display: "table-row-group" }}>
                            <tr>
                                <td rowSpan="2" style={{ border: "1px solid #666", textAlign: "end", fontSize: 13, height: 15 }}> To:</td>
                                <td colSpan="4" style={{ border: "1px solid #666", fontSize: 13, height: 15 }}>{Data?.partyId?.partyName || ''}</td>
                            </tr>
                            <tr>
                                <td colSpan="4" style={{ border: "1px solid #666", fontSize: 13, height: 15 }}></td>
                            </tr>
                            <tr>
                                <td style={{ border: "1px solid #666", fontSize: 13, height: 15, textAlign: "end" }}> Rate:</td>
                                <td colSpan="4" style={{ border: "1px solid #666", fontSize: 13, textAlign: "end", height: 15 }}>Date: {moment(Data?.date).format('DD/MM/YYYY')}</td>
                            </tr>
                            <tr>
                                <td style={{ border: "1px solid #666", fontSize: 15, height: 15, textAlign: "center", fontWeight: "bold", width: "10%" }}> Stone No</td>
                                <td style={{ border: "1px solid #666", fontSize: 15, height: 15, textAlign: "center", fontWeight: "bold", width: "10%" }}> Weight</td>
                                <td style={{ border: "1px solid #666", fontSize: 15, height: 15, textAlign: "center", fontWeight: "bold", width: "10%" }}> Lab</td>
                                <td style={{ border: "1px solid #666", fontSize: 15, height: 15, textAlign: "center", fontWeight: "bold", width: "10%" }}> Amount $</td>
                                <td style={{ border: "1px solid #666", fontSize: 15, height: 15, textAlign: "center", fontWeight: "bold", width: "60%" }}> Remark</td>
                            </tr>
                            {Result.map((d) => {
                                return <tr>
                                    <td style={{ border: "1px solid #666", fontSize: 13, height: 15, textAlign: "center", width: "10%" }}> {`${d.lotNo} - ${d.pktNo}`} </td>
                                    <td style={{ border: "1px solid #666", fontSize: 13, height: 15, textAlign: "center", width: "10%" }}> {Tofixed(d.cts)}</td>
                                    <td style={{ border: "1px solid #666", fontSize: 13, height: 15, textAlign: "center", width: "10%" }}> {d.lab} </td>
                                    <td style={{ border: "1px solid #666", fontSize: 13, height: 15, textAlign: "center", width: "10%" }}> {Tofixed(d.rapAmt)}</td>
                                    <td style={{ border: "1px solid #666", fontSize: 13, height: 15, textAlign: "center", width: "60%" }}> {Data?.remark || ""} </td>
                                </tr>
                            })
                            }

                            {Result.length < 22 ?
                                [...Array(22 - Result.length)].map((d, key) => {
                                    return <tr key={key}>
                                        <td style={{ border: "1px solid #666", fontSize: 13, height: 15, textAlign: "center", width: "10%" }}>  </td>
                                        <td style={{ border: "1px solid #666", fontSize: 13, height: 15, textAlign: "center", width: "10%" }}> </td>
                                        <td style={{ border: "1px solid #666", fontSize: 13, height: 15, textAlign: "center", width: "10%" }}>  </td>
                                        <td style={{ border: "1px solid #666", fontSize: 13, height: 15, textAlign: "center", width: "10%" }}> </td>
                                        <td style={{ border: "1px solid #666", fontSize: 13, height: 15, textAlign: "center", width: "60%" }}>  </td>
                                    </tr>
                                })
                                : ""
                            }
                            <tr>
                                <td style={{ border: "1px solid #666", fontSize: 13, height: 15, textAlign: "center", width: "10%" }}> Total : {Result?.length || 0} </td>
                                <td style={{ border: "1px solid #666", fontSize: 13, height: 15, textAlign: "center", width: "10%" }}>{Tofixed(_.sumBy(Result, (d) => d.cts))} </td>
                                <td colSpan={2} style={{ border: "1px solid #666", fontSize: 13, height: 15, textAlign: "right", width: "20%" }}>
                                    {Tofixed(_.sumBy(Result, (d) => d.rapAmt))}
                                </td>
                                <td style={{ border: "1px solid #666", fontSize: 13, height: 15, textAlign: "center", width: "60%" }}> </td>
                            </tr>
                            <tr>
                                <td colSpan="4" style={{ fontSize: 15, height: 15, textAlign: "center", width: "10%" }}>

                                </td>
                                <td style={{ fontSize: 15, height: 15, textAlign: "center", width: "10%" }}> For,<span style={{ fontSize: 18, fontWeight: "bold" }}>
                                    Kismet Diamonds</span> </td>
                            </tr>
                            <tr>
                                <td colSpan="5" style={{ fontSize: 15, height: 15, textAlign: "left", width: "10%" }}>
                                    Acknowledge of Entrustment. :
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="5" style={{ fontSize: 15, height: 15, textAlign: "left", width: "10%" }}>
                                    Receive above merchandise. :
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="4" style={{ fontSize: 15, height: 15, textAlign: "left", width: "10%" }}>
                                    with myown risk & liability. :
                                </td>
                                <td style={{ fontSize: 15, height: 15, textAlign: "center", width: "10%" }}>
                                    Receiver's Sign
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div style={{ width: "49%", border: "1px solid #666", marginLeft: 15 }}>
                <div style={{ display: "flex" }}>
                    <div style={{ width: "83%", textAlign: "center", margin: 10 }}>
                        <h1 style={{ margin: 0 }}>Kismet Diamonds</h1>
                    </div>
                    <div style={{ width: "17%", padding: 10, borderLeft: "1px dotted black", borderBottom: "1px dotted black" }}>
                        <p style={{ margin: 0 }}>Reg</p>
                        <p style={{ margin: 0 }}>Vat No. : 4050262452</p>
                    </div>
                </div>
                <div>
                    <p style={{ padding: 5, fontSize: 15, margin: 0 }}>Physical Address</p>
                    <p style={{ margin: "2px 10px 2px 72px", fontSize: 15, textAlign: "-webkit-center" }}>
                        {comanyResult?.address1 || "-"}, {comanyResult?.address2} {comanyResult?.address3}, {comanyResult?.city} ,{comanyResult?.state}
                    </p>
                </div>
                <div style={{ display: "flex" }}>
                    <div style={{ width: "35%" }}>
                        <p style={{ margin: 0, padding: 5, fontSize: 15 }}>Phone : {comanyResult?.phone1}, {comanyResult?.phone2} </p>
                    </div>
                    <div style={{ width: "65%" }}>
                        <p style={{ margin: 0, padding: 5, fontSize: 15 }}>{comanyResult?.contactPerson1Name} : {comanyResult?.mobileNo1}  </p>
                    </div>
                </div>
                <div style={{ display: "flex" }}>
                    <div style={{ width: "36%" }}>
                        <p style={{ margin: 0, paddingLeft: 5, fontSize: 15 }}>{comanyResult?.email} </p>
                    </div>
                    <div style={{ width: "49%" }}>
                        <p style={{ margin: 0, fontSize: 15 }}> {comanyResult?.contactPerson2Name} : {comanyResult?.mobileNo2}  </p>
                    </div>
                    <div style={{ width: "15%" }}>
                        <p style={{ margin: 0, fontSize: 15 }}>No.: {Data?.issueNo || ""}</p>
                    </div>
                </div>


                <div>
                    <table style={{ width: "100%", borderCollapse: "collapse" }}>
                        <tbody style={{ display: "table-row-group" }}>
                            <tr>
                                <td rowSpan="2" style={{ border: "1px solid #666", textAlign: "end", fontSize: 13, height: 15 }}> To:</td>
                                <td colSpan="4" style={{ border: "1px solid #666", fontSize: 13, height: 15 }}>{Data?.partyId?.partyName || ''}</td>
                            </tr>
                            <tr>
                                <td colSpan="4" style={{ border: "1px solid #666", fontSize: 13, height: 15 }}></td>
                            </tr>
                            <tr>
                                <td style={{ border: "1px solid #666", fontSize: 13, height: 15, textAlign: "end" }}> Rate:</td>
                                <td colSpan="4" style={{ border: "1px solid #666", fontSize: 13, textAlign: "end", height: 15 }}>Date: {moment(Data?.date).format('DD/MM/YYYY')}</td>
                            </tr>
                            <tr>
                                <td style={{ border: "1px solid #666", fontSize: 15, height: 15, textAlign: "center", fontWeight: "bold", width: "10%" }}> Stone No</td>
                                <td style={{ border: "1px solid #666", fontSize: 15, height: 15, textAlign: "center", fontWeight: "bold", width: "10%" }}> Weight</td>
                                <td style={{ border: "1px solid #666", fontSize: 15, height: 15, textAlign: "center", fontWeight: "bold", width: "10%" }}> Lab</td>
                                <td style={{ border: "1px solid #666", fontSize: 15, height: 15, textAlign: "center", fontWeight: "bold", width: "10%" }}> Amount $</td>
                                <td style={{ border: "1px solid #666", fontSize: 15, height: 15, textAlign: "center", fontWeight: "bold", width: "60%" }}> Remark</td>
                            </tr>
                            {Result.map((d) => {

                                return <tr>
                                    <td style={{ border: "1px solid #666", fontSize: 13, height: 15, textAlign: "center", width: "10%" }}> {`${d.lotNo} - ${d.pktNo}`} </td>
                                    <td style={{ border: "1px solid #666", fontSize: 13, height: 15, textAlign: "center", width: "10%" }}> {Tofixed(d.cts)}</td>
                                    <td style={{ border: "1px solid #666", fontSize: 13, height: 15, textAlign: "center", width: "10%" }}> {d.lab} </td>
                                    <td style={{ border: "1px solid #666", fontSize: 13, height: 15, textAlign: "center", width: "10%" }}> {Tofixed(d.rapAmt)}</td>
                                    <td style={{ border: "1px solid #666", fontSize: 13, height: 15, textAlign: "center", width: "60%" }}> {Data?.remark || ""} </td>
                                </tr>
                            })
                            }

                            {Result.length < 22 ?
                                [...Array(22 - Result.length)].map((d, key) => {
                                    return <tr key={key}>
                                        <td style={{ border: "1px solid #666", fontSize: 13, height: 15, textAlign: "center", width: "10%" }}>  </td>
                                        <td style={{ border: "1px solid #666", fontSize: 13, height: 15, textAlign: "center", width: "10%" }}> </td>
                                        <td style={{ border: "1px solid #666", fontSize: 13, height: 15, textAlign: "center", width: "10%" }}>  </td>
                                        <td style={{ border: "1px solid #666", fontSize: 13, height: 15, textAlign: "center", width: "10%" }}> </td>
                                        <td style={{ border: "1px solid #666", fontSize: 13, height: 15, textAlign: "center", width: "60%" }}>  </td>
                                    </tr>
                                })
                                : ""
                            }
                            <tr>
                                <td style={{ border: "1px solid #666", fontSize: 13, height: 15, textAlign: "center", width: "10%" }}> Total : {Result?.length || 0} </td>
                                <td style={{ border: "1px solid #666", fontSize: 13, height: 15, textAlign: "center", width: "10%" }}>{Tofixed(_.sumBy(Result, (d) => d.cts))} </td>
                                <td colSpan={2} style={{ border: "1px solid #666", fontSize: 13, height: 15, textAlign: "right", width: "20%" }}>
                                    {Tofixed(_.sumBy(Result, (d) => d.rapAmt))}
                                </td>
                                <td style={{ border: "1px solid #666", fontSize: 13, height: 15, textAlign: "center", width: "60%" }}> </td>
                            </tr>
                            <tr>
                                <td colSpan="4" style={{ fontSize: 15, height: 15, textAlign: "center", width: "10%" }}>

                                </td>
                                <td style={{ fontSize: 15, height: 15, textAlign: "center", width: "10%" }}> For,<span style={{ fontSize: 18, fontWeight: "bold" }}>
                                    Kismet Diamonds</span> </td>
                            </tr>
                            <tr>
                                <td colSpan="5" style={{ fontSize: 15, height: 15, textAlign: "left", width: "10%" }}>
                                    Acknowledge of Entrustment. :
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="5" style={{ fontSize: 15, height: 15, textAlign: "left", width: "10%" }}>
                                    Receive above merchandise. :
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="4" style={{ fontSize: 15, height: 15, textAlign: "left", width: "10%" }}>
                                    with myown risk & liability. :
                                </td>
                                <td style={{ fontSize: 15, height: 15, textAlign: "center", width: "10%" }}>
                                    Receiver's Sign
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div >
    </div>
}