import gql from 'graphql-tag';

export const PAYMENT = gql`
query getPaymentMaster($page: Int, $limit: Int, $filter: String, $sort: paymentMasterSort){
    getPaymentMaster(page: $page, limit: $limit, filter: $filter, sort: $sort){
      count
      data {
        id
        payMasterNo
        transDate
        payMode
        payOff
        transType
        currency
        partyId{
          id
          partyName
        }
        currencyRate
        currencyAmount
        payedBillNo
        partyAmtZar
        adgAmtZar
        createdBy{
          id
          userName
        }
        updatedBy
      }
    }
  }
`;
export const PAYMENTMASTERDETAIL = gql`
query getPaymentMasterDetails($id: ID!){
  getPaymentMasterDetails(id:$id){
      id
	    bankName
      chequeNo
      reference
      billNo
      billAmtZar
      disc
      discAmt
      otherAmt
      billName
      roundOff
      netAmountZar
      remark
      createdAt
      paymentMasterId
      createdBy
      createdAt
      updatedAt
      updatedBy
  }
}
`;
export const GETBILLNODATA = gql`
query getBillNo($partyId:ID!){
  getBillNo(partyId:$partyId){
    billNo
    remainingAmt
  }
}
`;

export const GETBILLNODATAFORPAYMENT = gql`
query getBillNoForAccount($partyId:ID! $currency:String){
  getBillNoForAccount(partyId:$partyId currency:$currency){
    billNo
    remainingAmt
  }
}
`;
export const GETJOBWORKBILL = gql`
    query getJobWorkBill($partyId:ID){
      getJobWorkBill(partyId:$partyId){
        lotNoId{
          id
          lotNo
        }
        remainingAmt
      }
    }
`;


