import gql from 'graphql-tag';

export const RECERTYMASTER = gql`
query getSalesStockForCertified($filter: String,$sort: stockMasterSort){
  getSalesStockForCertified(filter:$filter,sort:$sort){
  count
   data{
     id
  lotNoId{
  id
  lotNo
  }
  pktNoId{
  id
  pktNo
  }
  cts
  certificateId
  lab{
  id
  partyName
  }

  colorId{
  id
  shortName
  }
  shapeId{
  id
  shortName
  }
  purityId{
  id
  shortName
  }
  cutId{
  id
  shortName
  }
  polishId{
  id
  shortName
  }
  symmId{
  id
  shortName
  }
  fluId{
  id
  shortName
  }
  rapRate
  stkPer
   }
  }
 }
`;
