import React, { useState } from 'react'
import { Button, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { GENERATE_TWO_FA_SECRET, VALIDATE_TWO_FA_SECRET } from './mutation';
import { useMutation } from 'react-apollo';
import { toast } from 'react-toastify';
import { FormatError } from '../../components/Comman/FormatError'
const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

export const TwoStepVerification = () => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [code, setCode] = useState("")
    const [secretKey, setSecretKey] = useState("")
    const [qrCode, setQrcode] = useState("")

    const [validateTwoFASecretMutation] = useMutation(VALIDATE_TWO_FA_SECRET)
    const [generateTwoFASecretMutation] = useMutation(GENERATE_TWO_FA_SECRET)
    const user = JSON.parse(localStorage.getItem('loggedUser'));
    const handleOpen = () => {
        setOpen(true);
    };

    const toggle = () => {
        setOpen(!open);
    };



    const handleModalSubmit = (e) => {
        e.preventDefault();
        if (!code) {
            toast.error("Please enter 6 digit code")
        } else {
            validateTwoFASecretMutation({ variables: { secretKey, code } })
                .then(({ data }) => {
                    if (data?.verifyTwoFA?.user?.TwoFA?.isEnable) {
                        toast.success("Two FA enabled sucessfully")
                        toggle()
                        setCode("")
                        setSecretKey("")
                        setQrcode("")
                    } else {
                        toast.error("Something went wrong")
                        setCode("")
                        setSecretKey("")
                        setQrcode("")
                        toggle()
                    }
                }).catch((error) => {
                    toast.error(FormatError(error))
                    setCode("")
                    setSecretKey("")
                    setQrcode("")
                })
        }
    }

    const OnSubmitHandler = (e) => {
        generateTwoFASecretMutation()
            .then(({ data }) => {
                if (data?.generateTwoFASecret) {
                    if (!user?.TwoFA?.isEnable) {
                        setSecretKey(data?.generateTwoFASecret?.secret)
                        setQrcode(data?.generateTwoFASecret?.qrCode)
                        toggle()
                    } else {
                        toggle()
                        setSecretKey("")
                        setQrcode("")
                    }
                } else {
                    toast.error("Something went wrong")
                }
            }).catch(error => {
                toast.error(FormatError(error))
            })
    }

    return <div>
        <h2 style={{ fontWeight: '800' }}>Two-step verification</h2>
        <hr />
        <h3 style={{ fontWeight: 700 }}>Two factor authentication is not enabled yet.</h3>
        <p style={{ width: '600px', margin: '20px 0px', lineHeight: '25px' }}>Two-factor authentication adds an additional layer of security to your account by requiring
            more than just a password to log in. Learn more.</p>
        <Button onClick={OnSubmitHandler} variant="contained" color="primary" style={{ height: 35, marginTop: 10 }}>Enable two-factor authentication</Button>

        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={toggle}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 200,
            }}
            style={{ width: '700px', margin: 'auto', padding: '50px' }}
        >
            <Fade in={open}>
                <div className={classes.paper}>
                    <h2 style={{ textAlign: 'center' }}>Add Authenticator App</h2>
                    <h3>Authenticator Apps</h3>
                    <p style={{ marginTop: '5px', lineHeight: '25px' }}>Using an authenticator app like Google Authenticator, Microsoft Authenticator, Authy, or 1Password, scan the QR code. It will generate a 6 digit code for you to enter below.</p>
                    <div style={{ display: 'flex', justifyContent: "center" }}>
                        <img style={{ alignItems: 'center' }} src={qrCode} alt="" />
                    </div>
                    <div style={{ width: '100%', backgroundColor: 'rgba(255 159 67 / 0.2)', padding: '7px 15px', textAlign: 'left', boxShadow: '0 6px 15px -7px rgba(255 159 67 / 0.4' }}><span style={{ fontSize: '1rem', fontWeight: '600', color: 'rgba(255 159 67 / 0.98)' }}>{secretKey}</span></div>
                    <div style={{ width: '100%', backgroundColor: 'rgba(255 159 67 / 0.2)', padding: '7px 15px', textAlign: 'left', boxShadow: '0 6px 15px -7px rgba(255 159 67 / 0.4' }}><span style={{ fontSize: '1rem', fontWeight: '600', color: 'rgba(255 159 67 / 0.98)' }}>If you having trouble using the QR code, select manual entry on your app</span></div>
                    <TextField onChange={({ target }) => setCode(target.value)} style={{ width: '100%', marginTop: '30px' }} id="outlined-basic" label="Enter authentication code" variant="outlined" />
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button variant="outline" style={{ height: 35, margin: '20px 10px' }} onClick={() => toggle()}>Cancle</Button>
                        <Button onClick={handleModalSubmit} variant="contained" color="primary" style={{ height: 35, margin: '20px 10px' }}>Complate</Button>
                    </div>
                </div>
            </Fade>
        </Modal>
    </div>
}