import gql from 'graphql-tag';

export const GETPURCHASEVSSALESREPORT = gql`
query getPurchaseVsSalesReport($filter:String!){
  getPurchaseVsSalesReport(filter:$filter){
    lotNo
    billDate
    purType
    purDate
    partyId{
      partyName
    }
    roughCostDollar
    roughCostZar
    pktNo
    roughCts
    departmentId{
      deptName
    }
    process{
      procName
    }
    processString
    issueCts
    billNo
    salesParty
    salesCts
    rapePrice
    backPer
    amtDollar
    rateZar
    commision
    totAmtZar
    issueParty
    amtZar
    purchaseCostDollar
  }
}
`;

