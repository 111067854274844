import React from 'react';
import { useQuery } from '@apollo/react-hooks'
import { GETBILLNODATAFORPAYMENT, GETJOBWORKBILL } from '../../../pages/Accounts/Payment/PaymentQuery';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import _ from 'lodash'
import { FormatError } from '../../Comman/FormatError'




export const BillNoAllData = (partyId, currency, Type) => {
    let varibales = Type === "SALES" ? { partyId: partyId, currency: currency } : { partyId: partyId }

    const { error, data } = useQuery(Type === "SALES" ? GETBILLNODATAFORPAYMENT : GETJOBWORKBILL, {
        variables: { ...varibales },
        fetchPolicy: 'cache-and-network',
    })

    let BillData = []
    if (error) {
        console.log(FormatError(error));
    } else {
        BillData = data?.getBillNoForAccount || []
        BillData = Type === "SALES" ? data?.getBillNoForAccount || [] : Type === "JOB WORK" ? data?.getJobWorkBill || [] : []
    }
    return BillData
}


export const BillNoSelectComponent = (props) => {
    let Type = props.TYPE
    let currency = props.currency
    let Data = BillNoAllData(props.PartyId, currency, Type)
    const changeselect = (e, newValue) => {
        let props1 = props.prosData
        let Data = { ...props1.rowData }

        if (Type === "SALES") {
            Data.billNo = (newValue.billNo).toString()
            Data.billAmtZar = Math.round(newValue.remainingAmt)
        } else if (Type === "JOB WORK") {
            Data.billNo = newValue.lotNoId.id
            Data.billNoName = newValue.lotNoId.lotNo
            Data.billAmtZar = Math.round(newValue.remainingAmt)
        }

        props1.onRowDataChange(Data)
    }
    const defaultProps = {
        options: Data,
        getOptionLabel: (option) => Type === "SALES" ? (option.billNo).toString() : (option.lotNoId.lotNo).toString()
    };

    return <>
        <Autocomplete
            {...defaultProps}
            disableClearable
            onChange={(e, newValue) => changeselect(e, newValue)}
            value={_.filter(Data, (newd) => newd.id === props.valueDefault)[0]}
            renderInput={(params) => <TextField   {...params} margin="normal" />}
        />
    </>
}