import React, { useState, useEffect, useRef } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks'
import MaterialTable from 'material-table';
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import { Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import moment from 'moment';
import { PaymemtMasterChild } from './PaymentMasterChild'
import { PayMode, PayOff, TransType, Currency } from '../../../components/Constant';
import { PAYMENT } from './PaymentQuery'
import { PAYMENT_SUBSCRIBE } from './PaymentSubscription'
import { ADD_PAYMENTMASTER, UPDATE_PAYMENTMASTER, DELETE_PAYMENTMASTER } from './PaymentMutation'
import { MenuItem, Select } from '@material-ui/core';
import { CompanyAndPartySelectComponent, CompanyAndPartyAllData } from '../../../components/DropdownSelect/SelectComponents/companyAndPartySelectComponent'
import { Tofixed, RemoveDecimmal } from '../../../components/Comman/Tofix';
import { FormatError } from '../../../components/Comman/FormatError'
import { CustomFilterComponent } from '../../../components/CustomFilterComponent/index'
const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1, },
  noMargin: { margin: 0 }
}));

const CalculateCurrencyAmount = (rowData) => {
  let currencyRate = rowData?.currencyRate || 0
  let adgAmtZar = rowData?.adgAmtZar || 0
  return Tofixed(currencyRate * adgAmtZar)
}
const columns = [
  {
    title: 'TransDate*', field: 'transDate', type: "date",
    initialEditValue: moment(),
    validate: rowData => (rowData.transDate && rowData.transDate !== undefined) ? true : { isValid: false, helperText: `can't be empty` }
  },
  {
    title: 'Pay Mode', field: 'payMode', initialEditValue: "CHEQUE", lookup: PayMode,
  },
  {
    title: 'Pay Off', field: 'payOff', initialEditValue: "ROUGH PURCHASE", lookup: PayOff,
    editComponent: (props) => {
      return <Select
        value={props?.rowData?.payOff || ""}
        onChange={(e) => {
          let Data = { ...props.rowData }
          Data.payOff = e.target.value
          if (Data.payOff === "JOB WORK" || Data.payOff === "SALES")
            Data.transType = "RECEIVE"
          else
            Data.transType = "PAYMENT"
          props.onRowDataChange(Data)
        }}
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}>
        <MenuItem value={"JOB WORK"}>JOB WORK</MenuItem>
        <MenuItem value={"SALES"}>SALES</MenuItem>
      </Select >
    }
  },
  {
    title: 'Trans Type', field: 'transType', initialEditValue: "PAYMENT", lookup: TransType, editComponent: (props) => {
      return props?.rowData?.transType || ""
    }
  },
  {
    title: 'Party Name*', field: 'partyId', render: (rowData) => rowData?.partyId?.partyName || 'SELECT PARTY'
    ,
    cellStyle: {
      minWidth: 220
    },
    editComponent: props => {
      let valueDefault = props?.rowData?.partyId?.id || props?.rowData?.partyId || 0
      return <CompanyAndPartySelectComponent QueryName={"PARTY"} valueDefault={valueDefault} prosData={props} />
    }
  },
  {
    title: 'Currency',
    field: 'currency',
    initialEditValue: "ZAR TO ZAR", lookup: Currency,

  },
  {
    title: 'Currency Rate',
    field: 'currencyRate',
    type: "numeric",
    editComponent: (props) => {
      return (
        <TextField
          value={props?.rowData?.currencyRate}
          type="number"
          onChange={(e) => {
            let Data = { ...props.rowData };
            Data.currencyRate = e.target.value;
            props.onRowDataChange(Data);
          }}
        />
      );
    },
    render: (rowData) => RemoveDecimmal(rowData?.currencyRate || 0)
  },
  {
    title: 'Currency Amt.',
    field: 'currencyAmount',
    type: "numeric",
    render: (rowData) => RemoveDecimmal(rowData?.currencyAmount || 0),
    editComponent: (props) => CalculateCurrencyAmount(props.rowData)
  },
  {
    title: 'Paid Bill No.',
    field: 'payedBillNo',
    editComponent: (props) => props?.rowData?.payedBillNo || ""
  },
  {
    title: 'Party Amt(ZAR)',
    field: 'partyAmtZar',
    editComponent: (props) => {
      //   let valueDefault = props?.rowData?.brokerPer || 0;
      return (
        <TextField
          value={props?.rowData?.partyAmtZar}
          type="number"
          onChange={(e) => {
            let Data = { ...props.rowData };
            Data.partyAmtZar = e.target.value;
            props.onRowDataChange(Data);
          }}
        />
      );
    },
    render: (rowData) => RemoveDecimmal(rowData?.partyAmtZar || 0),
    type: "numeric"
  },
  {
    title: 'Adg. Amt.(ZAR)',
    field: 'adgAmtZar',
    type: "numeric",
    render: (rowData) => RemoveDecimmal(rowData?.adgAmtZar || 0),
    editComponent: (props) => props?.rowData?.adgAmtZar || ""
  },
]

function Payment(props) {
  const classes = useStyles();
  const tableRef = useRef();

  const [loader, setLoader] = useState(false);
  const [pageSize, setPageSize] = useState(10)
  const [selectedRow, setSelectedRow] = useState(null);

  const permissions = props?.session?.me?.role?.permissions;
  const role = props?.session?.me?.role?.role;
  const path = props?.location?.pathname;
  let permission = _.find(permissions, function (o) { return o.name === path; })

  const [addPaymentMutation] = useMutation(ADD_PAYMENTMASTER)
  const [updatePaymentMutation] = useMutation(UPDATE_PAYMENTMASTER)
  const [deletePaymentMutation] = useMutation(DELETE_PAYMENTMASTER)


  const { fetchMore, subscribeToMore } = useQuery(PAYMENT, {

    variables: { page: 1, limit: 10, filter: JSON.stringify({}), sort: { key: 'createdAt', type: -1 } },
    fetchPolicy: 'cache-and-network'
  })

  columns.map(d => {
    if (d.field === "partyId") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: CompanyAndPartyAllData("PARTY"), getOptionLabel: (option) => option.partyName, multiple: true }} />
      }
    }
    return null
  })
  useEffect(() => {
    if (subscribeToMore) {
      const unsubscribe = subscribeToMore({
        document: PAYMENT_SUBSCRIBE,
        updateQuery: (previousResult, { subscriptionData }) => {
          if (!subscriptionData.data) {
            return previousResult;
          }
          tableRef.current && tableRef.current.onQueryChange()
          return previousResult;
        },
      });
      return () => unsubscribe();
    }
  }, [subscribeToMore]);

  const getPaymentData = (query) => {
    setLoader(true)
    return new Promise(async (resolve, reject) => {
      let filter = {}, sort = { key: 'createdAt', type: -1 }
      query && query.filters.forEach(item => {
        if ((item.column.field === "transDate")) {
          if (item.value)
            filter = { ...filter, [item.column.field]: moment(item.value).format("YYYY-MM-DD") }
          else {
            delete filter[item.column.field]
            filter = { ...filter }
          }
        }
        else if ((item.column.field === "currencyRate" || item.column.field === "currencyAmount" || item.column.field === "partyAmtZar" || item.column.field === "adgAmtZar")) {
          if (item.value)
            filter = { ...filter, [item.column.field]: parseFloat(item.value) }
          else {
            delete filter[item.column.field]
            filter = { ...filter }
          }
        }
        else if (item.value.length === 0) {
          delete filter[`${item.column.field}`]
          filter = { ...filter }
        } else
          filter = { ...filter, [item.column.field]: item.value }
      });
      if (query && query.orderBy && query.orderDirection !== "") {
        sort = { key: query.orderBy.field, type: query.orderDirection === 'asc' ? 1 : -1 }
      }
      setPageSize(query.pageSize)
      try {
        let result = await fetchMore({
          variables: { page: query.page + 1, limit: query.pageSize, filter: JSON.stringify(filter), sort: sort },
          fetchPolicy: 'network-only',
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return fetchMoreResult;
          }
        })
        setLoader(false)
        // setData(result)
        resolve({
          data: result?.data?.getPaymentMaster?.data || [],
          page: query.page,
          totalCount: result?.data?.getPaymentMaster?.count || 0,
        })
      } catch (error) {
        console.log(FormatError(error))
        setLoader(false)
      }
    })
  }

  const AddPayment = (newData) => {
    return new Promise((resolve, reject) => {
      setLoader(true)
      newData.currencyRate = parseFloat(Tofixed(newData?.currencyRate || 0))
      newData.currencyAmount = parseFloat(CalculateCurrencyAmount(newData))
      newData.partyAmtZar = parseFloat(Tofixed(newData?.partyAmtZar || 0))
      newData.adgAmtZar = parseFloat(Tofixed(newData?.adgAmtZar || 0))
      if (newData.partyId && newData.transDate) {
        addPaymentMutation({ variables: { input: newData } }).then((data) => {
          setLoader(false)
          toast.success('payment master created successfully.');
          resolve()
        }).catch((e) => {
          setLoader(false)
          reject()
          toast.error(FormatError(e))
        })
      } else {
        setLoader(false)
        toast.warn('please, fill all required(*) fields.')
        reject()
      }

    })
  }

  const updatePayment = (newData, oldData) => {

    return new Promise((resolve, reject) => {
      setLoader(true)
      delete newData.createdAt
      delete newData.createdBy
      delete newData.updatedAt
      delete newData.updatedBy
      delete newData.__typename
      newData.partyId = newData?.partyId?.id || newData?.partyId || 0
      newData.currencyRate = parseFloat(Tofixed(newData?.currencyRate || 0))
      newData.currencyAmount = parseFloat(CalculateCurrencyAmount(newData))
      newData.partyAmtZar = parseFloat(Tofixed(newData?.partyAmtZar || 0))
      newData.adgAmtZar = parseFloat(Tofixed(newData?.adgAmtZar || 0))

      if (newData.partyId && newData.transDate) {
        updatePaymentMutation({ variables: { input: newData } }).then((data) => {
          setLoader(false)
          toast.success('payment master updated successfully.');
          resolve()
        }).catch((e) => {
          setLoader(false)
          reject()
          toast.error(FormatError(e))
        })
      }
      else {
        setLoader(false)
        toast.warn('please, fill all required(*) fields.')
        reject()
      }
    })
  }

  const DeletePayment = (oldData) => {
    return new Promise((resolve, reject) => {
      setLoader(true)
      deletePaymentMutation({ variables: { id: oldData.id } }).then((data) => {
        setLoader(false)
        toast.success('payment master deleted successfully.');
        resolve()
      }).catch((e) => {
        setLoader(false)
        reject()
        toast.error(FormatError(e))
      })
    })
  }

  const UpdateByPaymentMasterChild = (newData, oldData) => {
    return new Promise((resolve, reject) => {
      setLoader(true)
      delete newData.createdAt
      delete newData.createdBy
      delete newData.updatedAt
      delete newData.updatedBy
      delete newData.__typename
      let updateObj = {}
      updateObj.adgAmtZar = newData?.netAmountZar || 0
      updateObj.currencyAmount = newData?.currencyAmount || 0
      let billNo = newData?.billNo || null
      if (billNo) {
        updateObj.payedBillNo = billNo
      }
      updateObj.id = newData.id

      updatePaymentMutation({ variables: { input: updateObj } }).then((data) => {
        setLoader(false)
        resolve()
      }).catch((e) => {
        setLoader(false)
        reject()
        toast.error(FormatError(e))
      })

    })
  }

  const addButton = (val) => {
    if (val || (role === 'admin'))
      return { onRowAdd: (newData) => AddPayment(newData) }
    else
      return {}
  }
  useEffect(() => {
    document.getElementsByClassName("parentTable")[0].firstElementChild.firstElementChild.firstChild.remove()
  }, [])

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <div className="parentTable">
            <MaterialTable
              title="Payment"
              tableRef={tableRef}
              columns={columns}
              isLoading={loader}
              // data={users}
              data={query => getPaymentData(query)}
              icons={{ Add: props => { return (<div> <i className="fa fa-plus-square" ></i> Payment </div>) } }}
              options={{
                emptyRowsWhenPaging: false,
                debounceInterval: 300,
                showTitle: false,
                padding: 'dense',
                pageSize: pageSize,
                pageSizeOptions: [5, 10, 15, 20, 25, 50],
                addRowPosition: 'first',
                filtering: true,
                sorting: true,
                search: false,
                filterCellStyle: { padding: '5px', },
                maxBodyHeight: window.innerHeight - 215,
                rowStyle: rowData => ({
                  backgroundColor: (selectedRow === rowData.tableData.id) ? 'rgb(212, 218, 255)' : '#f5f5f5'
                }),
                headerStyle: { backgroundColor: 'whitesmoke' }
              }}
              editable={{
                // onRowAdd: (newData) => AddPayment(newData),
                isEditHidden: (rowData) => (role === 'admin') ? false : !permission.isUpdate,
                isDeleteHidden: (rowData) => (role === 'admin') ? false : !permission.isDelete,
                ...addButton(permission?.isInsert),
                onRowUpdate: (newData, oldData) => updatePayment(newData, oldData),
                onRowDelete: (oldData) => DeletePayment(oldData)
              }}
              detailPanel={rowData => {
                return (
                  <PaymemtMasterChild
                    session={props?.session}
                    location={props?.location}
                    Ids={rowData.id}
                    PaymentMasterData={rowData}
                    UpdateByPaymentMasterDetail={UpdateByPaymentMasterChild} />
                )
              }}
              onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default withRouter(Payment)
