import gql from 'graphql-tag';

export const KAPANWISECOSTING = gql`
query getKapanWiseCostingReport($filter: String!){
  getKapanWiseCostingReport(filter:$filter){
    amountZar
 rateZar
 netAmount
    expense    
    majuri
      lotNo
         purchaseDate
    partyId{
      partyName
    }
    roughCost
    zarRate
    roughCostDollar
    pktNo
    roughCts
    polishCts
    kachuCts
    extraTops
    roughStock{
      colorId{
        shortName
      }
      shapeId{
        shortName
      }
      purityId{
        shortName
      }
      cutId{
        shortName
      }
      polishId{
        shortName
      }
      symmId{
        shortName
      }
      fluId{
        shortName
      }
            departmentId{
        deptName
      }
      process{
        procName
      }
      status
    }
      salesStock{
      issuePartyId{
        partyName
      }
      status
      cts
      colorId{
        shortName
      }
      shapeId{
        shortName
      }
      purityId{
        shortName
      }
      cutId{
        shortName
      }
      polishId{
        shortName
      }
      symmId{
        shortName
      }
      fluId{
        shortName
      }
    }
    laserCost
    galaxyCost
    boilCost
    heliumCost
    polishCost
    certiCost
    exportCharge
    importCharge
    interest
    adatExpense
    polishExpense
    rateDiff
    salesDetail{
      type
    rapRate
    rapAmt
    diffPer
    netRaprate
    netRaprateAmt
    rateZar
    amountZar
    commision
    commisionPer
    netAmtZar
    polishExpPer
    polishExpAmt
    partyName{
      partyName
    }
    billNo
    billDate
    }
  }
}
`;
