import React, { useState, useEffect } from 'react'
import { withRouter } from "react-router-dom";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import PrintIcon from '@material-ui/icons/Print';
import Typography from '@material-ui/core/Typography';
import { useQuery } from '@apollo/react-hooks'
import Grid from '@material-ui/core/Grid';
import { GETPLANINGCOMPARISIONREPORT } from './PurchaseSalesQuery';
import _ from 'lodash'
import { LotNo } from '../../../components/DropdownSelect/LotNo';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Rough from './PlaningReportPages/Rough'
import Planing from './PlaningReportPages/Planing'
import Grading from './PlaningReportPages/Grading'
import LabResult from './PlaningReportPages/LabResult'
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import './planning.css'
import { Tofixed } from '../../../components/Comman/Tofix'
import { Accordion, AccordionSummary, AccordionDetails, useStyles } from '../../../components/Comman/Accordian'
import { PdfView } from './PdfView'
import { ExcelView } from './ExcelView'

import { ExportCsv } from '../../../components/ExportReport/ExportCsv'
import { PrintDiv } from '../../../components/Comman/exportPdfAndExcel'
import ExcelPng from '../../../../Excel.png'
import * as XLSX from 'xlsx/xlsx.mjs';
import moment from 'moment';

function PlanningComparisionReport() {

  const classes = useStyles();
  const [filter, setFilter] = useState({})
  const [AllData, setAllData] = useState([])
  const [CalAllData, setCalAllData] = useState([])
  const [lotNo, setLotNo] = useState('');

  const { refetch, data, loading } = useQuery(GETPLANINGCOMPARISIONREPORT, {
    variables: { filter: JSON.stringify(filter) },
    fetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    let Data = _.orderBy(data?.getPlanningComparisionReport || [], ['packetDetail.pktNo'])
    let token = false
    Data.map(d => {
      if (d.gradingDetail || d.labResult || d.packetDetail || d.roughPlanning) {
        token = true
      }
      return null
    })
    if (token)
      setCalAllData(Data)
    else {
      setCalAllData([])
    }
  }, [data]);

  useEffect(() => {
    let Temp = [...CalAllData]
    let Subpacketsid = []
    let NewData = _.map(Temp, d => {
      if (d.subPkt) {
        if (!Subpacketsid.includes(d.subPkt.pktNo)) {
          Subpacketsid.push(d.subPkt.pktNo)
          return d
        } else {
          delete d.subPkt
          return d
        }
      } else {
        delete d.subPkt
        return d
      }
    })
    let FinalData = []
    _.map(NewData, d => {
      if (d?.subPkt) {
        FinalData.push(d)
        let newd = {
          lotNo: d.lotNo,
          packetDetail: {
            colorId: d?.subPkt.colorId,
            cts: d?.subPkt.cts,
            expcts: d?.subPkt.expcts,
            flsId: d?.subPkt.flsId,
            pktNo: d?.subPkt.pktNo,
            purityId: d?.subPkt.purityId,
            __typename: d?.subPkt.__typename
          },
          roughPlanning: {},
          gradingDetail: {},
          labResult: {}
        }
        FinalData.push(newd)
      } else {
        FinalData.push(d)
      }
    })

    let Datanew = FinalData && FinalData.filter(d => d.lotNo === "Grand Total")
    if (FinalData.length !== 0 && Datanew.length === 0) {
      let LastRow = {
        lotNo: "Grand Total",
        packetDetail: {
          pktNo: FinalData?.length || 0,
          cts: Tofixed(_.sum(_.map(FinalData, 'packetDetail.cts'))),
          expcts: Tofixed(_.sum(_.map(FinalData, 'packetDetail.expcts'))),
        },
        roughPlanning: {
          expCts: Tofixed(_.sum(_.map(FinalData, 'roughPlanning.expCts'))),
        },
        gradingDetail: {
          weight: Tofixed(_.sum(_.map(FinalData, 'gradingDetail.weight'))),
        },
        labResult: {
          cts: Tofixed(_.sum(_.map(FinalData, 'labResult.cts'))),
        }
      }
      FinalData.push(LastRow)
    }
    if (FinalData.length !== 0)
      setAllData(FinalData)
  }, [CalAllData])


  let defaultProps = LotNo('', '', '').defaultProps
  useEffect(() => {
    let Data = JSON.parse(localStorage.getItem("PlanningComparisionReport"))
    if (Data) {
      setFilter(Data)
    }
  }, [])

  const PreviewHandler = (e) => {
    let filter = { "lotNoId": lotNo.id }
    setFilter(filter)
    localStorage.setItem("PlanningComparisionReport", JSON.stringify(filter))
  }
  const ClearViewHandler = () => {
    setLotNo('')
    localStorage.removeItem('PlanningComparisionReport')
    setFilter({})
    setAllData([])
    refetch()
  }

  function exportExcelFile(workbook) {
    return XLSX.writeFile(workbook, `PlanningComparisionReport${moment()}.xlsx`);
  }

  const exportCsvData = () => {
    var workbook = XLSX.utils.book_new();
    var worksheet_data = document.getElementById("table-to-xls");
    var worksheet = XLSX.utils.table_to_sheet(worksheet_data);
    workbook.SheetNames.push("Test");
    workbook.Sheets["Test"] = worksheet;
    exportExcelFile(workbook);
  }

  return (
    <div className={classes.root}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className="AccordianHeader"
        >
          <Grid container className="mt-0" style={{ position: "relative" }}>
            <Grid xs={1} item >
              <PrintIcon
                onClick={(e) => PrintDiv(e)}
                style={{ width: "50px", marginTop: 10 }} />
              <div className="planing-compaision-ReportExcel">
                <img id="amits" src={ExcelPng} alt="alternative text" title="csv" onClick={() => exportCsvData()} style={{ position: "absolute", left: 50, top: 10 }} />
              </div>
              {/* <GetAppIcon onClick={(e) => exportTableToExcel("ExportTable", `Planning_Comparision_Report_${moment()}`, e)} /> */}
            </Grid>

            <Grid xs={10} item container justify="center" className="mt-0">
              <Typography className={classes.heading}>Planning Comparision Report</Typography>
            </Grid>
            <Grid xs={1} item container justify="flex-end" className="mt-0">
              <Typography className={classes.heading}>Filter</Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid xs={12} item container>
              <Grid xs={4} item>
              </Grid>
              <Grid xs={6} item container>
                <Grid xs={3} item>
                  <Autocomplete
                    value={lotNo}
                    {...defaultProps}
                    onChange={(e, newdata) => {
                      if (newdata) {
                        setLotNo(newdata)
                      }
                    }}
                    renderInput={(params) => <TextField label="Lot No"  {...params} margin="normal" />}
                  />
                </Grid>&nbsp;&nbsp;
                <Grid xs={4} item style={{ marginLeft: 0 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={(e) => PreviewHandler(e)}
                    style={{ marginLeft: 15, marginTop: 20 }}>
                    Preview
                  </Button>
                  <Button
                    onClick={() => ClearViewHandler()}
                    variant="contained"
                    color="primary"
                    style={{ height: 34, marginLeft: 10, marginTop: 12 }}>
                    clear
                  </Button>
                </Grid>
              </Grid>

            </Grid>
          </MuiPickersUtilsProvider>
        </AccordionDetails>
      </Accordion>
      <Grid style={{ width: "100%", overflowY: "scroll", height: window.innerHeight - 200 }}>
        <Grid xs={12} item container >
          <div style={{ display: "flex" }}>
            <Rough AllData={AllData} loading={loading} />
            <Planing AllData={AllData} loading={loading} />
            <Grading AllData={AllData} loading={loading} />
            <LabResult AllData={AllData} loading={loading} />
          </div>
        </Grid>
      </Grid>
      <PdfView lotNo={lotNo?.lotNo || "All"} AllData={AllData} />
      <ExcelView lotNo={lotNo?.lotNo || "All"} AllData={AllData} />
    </div >
  );
}
export default withRouter(PlanningComparisionReport)
