import gql from 'graphql-tag';

export const ADD_DEPARTMENT = gql`
    mutation addNewDepartment($input:departmentInput!,$log: LogInput){
      addNewDepartment(input:$input,log: $log){
        id
        deptCode
        deptName
        deptType
        catagory
        isActive
        defaultProcess
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
    }
`;

export const UPDATE_DEPARTMENT = gql`
    mutation updateDepartment($input: departmentUpdate!,$log: LogInput){
      updateDepartment(input:$input,log: $log){
      id
      deptCode
      deptName
      deptType
      catagory
      isActive
      defaultProcess
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
    }
`;

export const DELETE_DEPARTMENT = gql`
  mutation deleteDepartment($id: ID!,$log: LogInput){
    deleteDepartment(id:$id,log: $log)
  }
`;
