import gql from 'graphql-tag';

export const ROLES_SUBSCRIBE = gql`
  subscription RolesChange {
    RolesChange {
      keyType
      data{
        id
        role
        permissions       
        isActive
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
    }
  }
`;
