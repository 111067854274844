import React, { useState, useEffect } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { CompanyAndPartyAllData } from '../../../../components/DropdownSelect/SelectComponents/companyAndPartySelectComponent';
import _ from 'lodash'
import { TextField } from '@material-ui/core';
import { useMutation, useQuery } from '@apollo/react-hooks'
import { ALLPARTYMASTERDETAILS } from '../../../Master/PartyMaster/PartyMasterQuery';

export const LabPartyFilterDropdown = ({ value, setValue }) => {
    const { error, data, loading } = useQuery(ALLPARTYMASTERDETAILS, {
        fetchPolicy: 'cache-and-network',
    })
    const [partyData, setPartyData] = useState([])

    useEffect(() => {
        let parties = data?.getAllParties || []
        let labParties = _.filter(parties, function (code) {
            return code.partyType === "LAB"
        })
        setPartyData(labParties)
    }, [data])


    return <Autocomplete
        multiple
        value={value}
        options={partyData}
        getOptionLabel={(option) => option.partyName}
        onChange={(event, newValue) => {
            setValue(newValue)
        }}
        className="custom-margin-top"
        renderInput={(params) => (
            <TextField
                {...params}
                variant="standard"
                label="Lab"
            />
        )}
    />
}