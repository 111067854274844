import React, { useRef, useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { GETJOBWORKFORDASHBOARD } from './Query'
import MaterialTable from 'material-table';
import _ from 'lodash'
import { CustomFilterComponent } from '../../../components/CustomFilterComponent/index'
import { LotNoData } from '../../../components/DropdownSelect/SelectComponents/LotSelectComponent'
import { CompanyAndPartyAllData } from "../../../components/DropdownSelect/SelectComponents/companyAndPartySelectComponent";
import { RemoveDecimmal } from '../../../components/Comman/Tofix'

const columns = [
  {
    title: 'Party Name',
    field: 'partyId',
    render: (rowData) => rowData?.partyId?.partyName || "-"
  },
  {
    title: 'Bill No',
    field: 'lotNoId',
    render: (rowData) => rowData?.lotNoId?.lotNo || "-"
  },
  {
    title: 'Remain Zar Amt',
    field: 'remainingAmt',
    render: (rowData) => rowData?.remainingAmt ? RemoveDecimmal(rowData?.remainingAmt || 0) : "",
  }
]

export const JobWorkDuePayment = (props) => {
  const tableRef = useRef();
  const [loader, setLoader] = useState(false);
  const [pageSize, setPageSize] = useState(10)

  const { fetchMore } = useQuery(GETJOBWORKFORDASHBOARD, {
    variables: { page: 1, limit: 10, filter: JSON.stringify({}), sort: { key: 'createdAt', type: -1 } },
    fetchPolicy: 'cache-and-network'
  })

  columns.map(col => {
    if (col.field === 'lotNoId')
      col.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: LotNoData(), getOptionLabel: (option) => option.lotNo, multiple: true }} />
      }
    if (col.field === 'partyId')
      col.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: CompanyAndPartyAllData("PARTY"), getOptionLabel: (option) => option.partyName, multiple: true }} />
      }
    return null
  })

  const getJobWorkDuePaymentData = (query) => {
    setLoader(true)
    return new Promise(async (resolve, reject) => {
      let filter = {}, sort = { key: 'createdAt', type: -1 }
      query && query.filters.forEach(item => {
        if (item.value.length === 0) {
          delete filter[`${item.column.field}`]
          filter = { ...filter }
        } else {
          filter = { ...filter, [item.column.field]: item.value }
        }
      });
      if (query && query.orderBy && query.orderDirection !== "") {
        sort = { key: query.orderBy.field, type: query.orderDirection === 'asc' ? 1 : -1 }
      }
      setPageSize(query.pageSize)
      try {
        let result = await fetchMore({
          variables: { page: query.page + 1, limit: query.pageSize, filter: JSON.stringify(filter), sort: sort },
          fetchPolicy: 'network-only',
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return fetchMoreResult;
          }
        })
        setLoader(false)
        let Result = result?.data?.getJobWorkForDashboard?.data || []

        let TotalsData = Result.filter(d => (d?.partyId?.partyName).substring(0, 5) === "Total")
        if (Result.length !== 0 && TotalsData.length === 0) {
          let LastRow = {
            partyId: {
              partyName: `Total : ${Result.length}`
            },
          }
          Result.push(LastRow)
        }

        resolve({
          data: Result,
          page: query.page,
          totalCount: result?.data?.getJobWorkForDashboard?.count || 0,
        })
      } catch (error) {
        setLoader(false)
      }
    })
  }

  return <div>
    <MaterialTable
      ref={tableRef}
      title={`Job Work Due Payment`}
      columns={columns}
      isLoading={loader}
      data={(query) => getJobWorkDuePaymentData(query)}
      options={{
        emptyRowsWhenPaging: false,
        padding: 'dense',
        pageSize: pageSize + 1,
        search: false,
        paging: false,
        sorting: false,
        filtering: true,
        minBodyHeight: window.innerHeight - 545,
        maxBodyHeight: window.innerHeight - 545,
        headerStyle: {
          backgroundColor: '#8CB836',
          color: '#f5f5f5'
        },
        rowStyle: rowData => {
          let IDS = _.filter(rowData?.transferId || [], (d) => d !== null).length
          let totalPkts = rowData?.totalPkts || 0
          let totalExtraPkts = _.filter(rowData?.extraTopsTransferId || [], (d) => d !== null).length
          let extraTopsTransferId = _.filter(rowData?.extraTopsTransferId || [], (d) => d !== null).length
          if (((rowData.partyId?.partyName).substring(0, 5) === "Total")) {
            return ({
              backgroundColor: 'rgb(212, 218, 255)'
            })
          }
          else if (IDS === totalPkts && totalExtraPkts === extraTopsTransferId) {
            return ({
              backgroundColor: 'rgba(0, 128, 0, 0.5)'
            })
          }
          else {
            return ({
              backgroundColor: '#f5f5f5'
            })
          }
        }
      }}
    />
  </div>
}