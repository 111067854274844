import gql from 'graphql-tag';

const USERS = gql`
   query getUsers($page: Int, $limit: Int, $filter:String, $sort:userSort){
    getUsers(page:$page, limit:$limit, filter:$filter, sort:$sort){
      count
      data{
        id
        userId
        userType
        userName
        email,
        password,
        role{
          id
          role
          permissions
        }
        isActive
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
    }
  }
`;

export default USERS

export const GETUSERFORLOOKUP = gql`
    query getUserForLookup{
      getUserForLookup{
        id
        userName
      }
    }
`;
export const GETUSERROLEFORLOOKUP = gql`
    query getRolseForLookup{
      getRolseForLookup{
        id
        role
      }
    }
`;

