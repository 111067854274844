import React, { useState, useEffect, useRef } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks'
import MaterialTable from 'material-table';
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import USERS from './UserQuery';
import { SIGN_UP, UPDATE_USER, DELETE_USER } from './UserMutation';
import { USERS_SUBSCRIBE } from './UserSubscription';
import { ValidateEmail } from '../../../components/Comman/validate.js'
import { FormatError } from '../../../components/Comman/FormatError';
import { RolesSelectComponent } from '../../../components/DropdownSelect/SelectComponents/RoleSelectComponent'
import { UserRoleListData } from "../../../components/DropdownSelect/SelectComponents/UserRoleSlectComponent";
import { CustomFilterComponent } from '../../../components/CustomFilterComponent/index'
const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1, },
  noMargin: { margin: 0 }
}));

const columns = [
  {
    title: 'User Name*',
    field: 'userName',
    validate: rowData => (rowData.userName && rowData.userName !== "") ? true : { isValid: false, helperText: `can't be empty` }
  },
  {
    title: 'Email*', field: 'email',
    validate: rowData => {
      if (rowData.email && rowData.email !== "")
        return ValidateEmail(rowData.email) ? true : { isValid: false, helperText: `email is not valid` }
      else
        return { isValid: false, helperText: `can't be empty` }
    }
  },
  {
    title: 'Password', field: 'password', render: (rowdata) => "*******"
  },
  {
    title: 'Role',
    field: 'role',
    render: (rowData) => rowData?.role?.role || "No Any Role",
    editComponent: props => {
      let valueDefault = props?.rowData?.role?.id || props?.rowData?.role || null
      return <RolesSelectComponent valueDefault={valueDefault} props={props} />
    }
  },
  { title: 'Active', field: 'isActive', type: "boolean", initialEditValue: true, sorting: false },
]


function Users(props) {
  const classes = useStyles();
  const tableRef = useRef();

  const [pageSize, setPageSize] = useState(10)
  const [selectedRow, setSelectedRow] = useState(null);
  const [loader, setLoader] = useState(false);

  const [signUpMutation] = useMutation(SIGN_UP)
  const [updateUserMutation] = useMutation(UPDATE_USER)
  const [deleteUserMutation] = useMutation(DELETE_USER)


  const { fetchMore, subscribeToMore } = useQuery(USERS, {
    variables: { page: 1, limit: 10, filter: JSON.stringify({}), sort: { key: 'createdAt', type: -1 } },
    fetchPolicy: 'cache-and-network'
  })

  columns.map(d => {
    if (d.field === "role") {
      d.lookup = UserRoleListData();
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: UserRoleListData(), getOptionLabel: (option) => option.role, multiple: true }} />
      }
    }
    return null
  })

  useEffect(() => {
    if (subscribeToMore) {
      const unsubscribe = subscribeToMore({
        document: USERS_SUBSCRIBE,
        updateQuery: (previousResult, { subscriptionData }) => {
          if (!subscriptionData.data) {
            return previousResult;
          }
          tableRef.current && tableRef.current.onQueryChange()
          return previousResult;
        },
      });
      return () => unsubscribe();
    }
  }, [subscribeToMore]);
  const getUsersData = (query) => {
    setLoader(true)
    return new Promise(async (resolve, reject) => {
      let filter = {}, sort = { key: 'createdAt', type: -1 }
      query && query.filters.forEach(item => {
        if (item.column.field === "isActive") {
          if (item.value)
            filter = { ...filter, [item.column.field]: item.value === "checked" ? true : false }
          else {
            delete filter.isActive
            filter = { ...filter }
          }
        }
        else if (item.value.length === 0) {
          delete filter[`${item.column.field}`]
          filter = { ...filter }
        } else
          filter = { ...filter, [item.column.field]: item.value }
      });
      if (query && query.orderBy && query.orderDirection !== "") {
        sort = { key: query.orderBy.field, type: query.orderDirection === 'asc' ? 1 : -1 }
      }
      setPageSize(query.pageSize)
      try {
        let result = await fetchMore({
          variables: { page: query.page + 1, limit: query.pageSize, filter: JSON.stringify(filter), sort: sort },
          fetchPolicy: 'network-only',
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return fetchMoreResult;
          }
        })
        setLoader(false)
        resolve({
          data: result?.data?.getUsers?.data || [],
          page: query.page,
          totalCount: result?.data?.getUsers?.count || 0,
        })
      } catch (error) {
        console.log(FormatError(error))
        setLoader(false)
      }
    })
  }

  const AddUser = (newData) => {
    return new Promise((resolve, reject) => {
      setLoader(true)
      if (newData.userName && newData.email) {
        let createObj = {
          userName: newData.userName,
          email: newData.email,
          role: newData.role,
          userType: "LOCAL USER",
          isActive: newData.isActive
        }
        if (ValidateEmail(createObj.email)) {
          signUpMutation({ variables: { input: createObj } }).then((data) => {
            setLoader(false)
            toast.success('user created successfully.');
            resolve()
          }).catch((e) => {
            reject()
            setLoader(false)
            toast.error(FormatError(e))
          })
        } else {
          setLoader(false)
          toast.warn('please, fill valid email.')
          reject()
        }
      } else {
        setLoader(false)
        toast.warn('please, fill all required(*) fields.')
        reject()
      }
    })
  }

  const UpdateUser = (newData, oldData) => {
    return new Promise((resolve, reject) => {
      console.log("UpdateUser============", newData, oldData)
      if (oldData.email === "superadmin@admin.com" || oldData.email === "admin@admin.com") {
        toast.warn('you can not update this user.')
        reject()
      } else {
        setLoader(true)
        if (newData.userName && newData.email) {
          if (ValidateEmail(newData.email)) {
            newData.role = newData?.role?.id || newData?.role || null
            let updatePass = newData.password
            let update = { userName: newData.userName, userType: newData.userType, isActive: newData.isActive, email: newData.email, id: newData.id, role: newData.role, password: updatePass }
            updateUserMutation({ variables: { input: update } }).then((data) => {
              setLoader(false)
              toast.success('user updated successfully.');
              resolve()
            }).catch((e) => {
              setLoader(false)
              reject()
              toast.error(FormatError(e))
            })
          } else {
            setLoader(false)
            toast.warn('please, fill valid email.')
            reject()
          }
        } else {
          setLoader(false)
          toast.warn('please, fill all required(*) fields.')
          reject()
        }
      }
    })
  }
  const DeleteUser = (oldData) => {
    return new Promise((resolve, reject) => {
      if (oldData.email === "superadmin@admin.com" || oldData.email === "admin@admin.com") {
        toast.warn('you can not delete this user.')
        reject()
      } else {
        setLoader(true)
        deleteUserMutation({ variables: { id: oldData.id } }).then((data) => {
          setLoader(false)
          toast.success('user deleted successfully.');
          resolve()
        }).catch((e) => {
          reject()
          setLoader(false)
          toast.error(FormatError(e))
        })
      }
    })
  }
  useEffect(() => {
    document.getElementsByClassName("parentTable")[0].firstElementChild.firstElementChild.firstChild.remove()
  }, [])
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <div className="parentTable">
            <MaterialTable
              title="Users"
              tableRef={tableRef}
              columns={columns}
              isLoading={loader}
              // data={users}
              data={query => getUsersData(query)}
              icons={{ Add: props => { return (<div> <i className="fa fa-plus-square" ></i> Users </div>) } }}
              options={{
                emptyRowsWhenPaging: false,
                debounceInterval: 300,
                padding: 'dense',
                showTitle: false,
                pageSize: pageSize,
                pageSizeOptions: [5, 10, 15, 20, 25, 50],
                addRowPosition: 'first',
                filtering: true,
                sorting: true,
                search: false,
                filterCellStyle: { padding: '5px', },
                maxBodyHeight: window.innerHeight - 215,
                rowStyle: rowData => ({
                  backgroundColor: (selectedRow === rowData.tableData.id) ? 'rgb(212, 218, 255)' : '#f5f5f5'
                }),
                headerStyle: { backgroundColor: 'whitesmoke' }
              }}
              editable={{
                onRowAdd: (newData) => AddUser(newData),
                onRowUpdate: (newData, oldData) => UpdateUser(newData, oldData),
                onRowDelete: (oldData) => DeleteUser(oldData)
              }}
              onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}


export default withRouter(Users)










