import gql from 'graphql-tag';

export const SHORTCODE = gql`
query getShortCode($page: Int, $limit: Int, $filter: String, $sort: shortCodeSort){
  getShortCode(page:$page,limit:$limit,filter:$filter,sort:$sort){
        count
    data{
      id
      codeId
      shortName
      fullName
      type
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
}
`;
export const GETALLSHORTCODETYPE = gql`
query getAllType($type:String){
  getAllType(type:$type){
    id
    codeId
    shortName
    fullName
    type
  }
}
`;
