import gql from 'graphql-tag';

export const ADD_PAYMENTMASTER = gql`
mutation addNewPayment($input: paymentMasterInput!) {
    addNewPayment(input: $input) {
     id
      payMasterNo
      payOff
      payMode
      partyId
      transDate
      transType
      payedBillNo
      updatedBy
      updatedAt
      createdBy
      createdAt
      currency
      currencyRate
      currencyAmount
      partyAmtZar
      adgAmtZar
    }
  }
`;


export const UPDATE_PAYMENTMASTER = gql`
mutation updatePayment($input:paymentMasterUpdate!){
    updatePayment(input:$input){
      id
      payMasterNo
      payOff
      payMode
      partyId
      transDate
      transType
      payedBillNo
      updatedBy
      updatedAt
      createdBy
      createdAt
      currency
      currencyRate
      currencyAmount
      partyAmtZar
      adgAmtZar
    }
  }
`;


export const DELETE_PAYMENTMASTER = gql`
mutation  deletePayment($id: ID!){
    deletePayment(id:$id)
  }
`;

export const ADD_PAYMENTMASTER_DETAIL = gql`
mutation addNewPaymentDetail($input: PaymentMasterDetailInput!){
  addNewPaymentDetail(input: $input) {
    id
	  bankName
    chequeNo
    reference
    billNo
    billAmtZar
    billName
    disc
    discAmt
    otherAmt
    roundOff
    netAmountZar
    remark
    paymentMasterId
    createdBy
    createdAt
    updatedBy
  }
}
`;


export const UPDATE_PAYMENTMASTER_DETAIL = gql`
mutation updatePaymentDetail($input: PaymentMasterDetailUpdate!){
  updatePaymentDetail(input:$input){
    id
    bankName
    chequeNo
    reference
    billNo
    billAmtZar
    billName
    disc
    discAmt
    otherAmt
    roundOff
    netAmountZar
    remark
    paymentMasterId
    createdBy
    createdAt
    updatedBy
  }
}
`;


export const DELETE_PAYMENTMASTER_DETAIL = gql`
  mutation deletePaymentDetail($id: ID!){
    deletePaymentDetail(id:$id)
  }
`;
