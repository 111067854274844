import React, { useState, useEffect, useRef } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks'
import MaterialTable, { MTableFilterRow, MTableHeader } from 'material-table';
import { withRouter } from "react-router-dom";
import Button from '@material-ui/core/Button';
import "date-fns";
import { Grid, Typography } from '@material-ui/core';
import DateFnsUtils from "@date-io/date-fns";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { TextField } from '@material-ui/core';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import _ from 'lodash';
import { RECERTYMASTER } from './RecertifiedMasterQuery'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { LotNo } from '../../../components/DropdownSelect/LotNo';
import { PktNoFromLotNo } from '../../../components/DropdownSelect/PktNoFromLotNo';
import { UPDATECERTIFIED } from './RecertifiedMasterMutation'
import { toast } from 'react-toastify'
import { FormatError } from '../../../components/Comman/FormatError'
import { GETALLSHORTCODETYPE } from '../../Utility/SortCode/SortCodeQuery';
import { useStyles, Accordion, AccordionSummary, AccordionDetails } from '../../../components/Comman/Accordian'
import { CustomFilterComponent } from '../../../components/CustomFilterComponent/index'
import { Tofixed } from '../../../components/Comman/Tofix';



const columns = [
  { title: 'Packet Name', field: 'pktNoId', render: (rowData) => rowData?.pktNoId?.pktNo || "-", },
  {
    title: 'Cts', field: 'cts',
    render: (rowData) => Tofixed(rowData?.cts || 0),
  },
  {
    title: 'Shape', field: 'shapeId',
    render: (rowData) => rowData?.shapeId?.shortName || "_"
  },
  {
    title: 'Color', field: 'colorId',
    render: (rowData) => rowData?.colorId?.shortName || "_"
  },
  {
    title: 'Purity', field: 'purityId',
    render: (rowData) => rowData?.purityId?.shortName || "_"
  },
  {
    title: 'Cut', field: 'cutId',
    render: (rowData) => rowData?.cutId?.shortName || "_"
  },
  {
    title: 'Polish', field: 'polishId',
    render: (rowData) => rowData?.polishId?.shortName || "_"
  },
  {
    title: 'Symm', field: 'symmId',
    render: (rowData) => rowData?.symmId?.shortName || "_"
  },
  {
    title: 'Flu', field: 'fluId',
    render: (rowData) => rowData?.fluId?.shortName || "_",
  },
]

function RecertifiedMaster(props) {
  const classes = useStyles();
  const tableRef = useRef();

  const [filter, setFilter] = useState({})
  const [selectedRow, setSelectedRow] = useState(null);
  const [sort, setSort] = useState({ key: 'createdAt', type: -1 });
  const [orderBy, setOrderBy] = useState(-1);
  const [orderDirection, setOrderDirection] = useState("");
  const [lotNo, setLotNo] = useState('');
  const [pktNo, setPktNo] = useState('');
  const [SelectedData, setSelectedData] = useState([]);


  const { error, data, refetch } = useQuery(RECERTYMASTER, {
    variables: { filter: JSON.stringify(filter), sort: sort },
    fetchPolicy: 'cache-and-network'
  })
  const [UpdateCertifiedMaster] = useMutation(UPDATECERTIFIED)


  let defaultProps = LotNo('', '', '').defaultProps
  let PktData = PktNoFromLotNo(lotNo ? lotNo : null)
  const defaultProps2 = {
    options: PktData,
    getOptionLabel: (option) => option.pktNo
  };

  const { data: shortCodeData, loading } = useQuery(GETALLSHORTCODETYPE, {
    variables: { type: "All" },
    fetchPolicy: 'cache-and-network',
  })
  const getShortCodeData = (TYPE) => {
    return _.filter(shortCodeData?.getAllType || [], (d) => d.type === TYPE);
  }
  columns.map(d => {
    if (d.field === "shapeId") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: getShortCodeData("SHAPE"), getOptionLabel: (option) => option.shortName, multiple: true }} />
      }
    } else if (d.field === "colorId") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: getShortCodeData("COLOR"), getOptionLabel: (option) => option.shortName, multiple: true }} />
      }
    } else if (d.field === "purityId") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: getShortCodeData("PURITY"), getOptionLabel: (option) => option.shortName, multiple: true }} />
      }
    } else if (d.field === "polishId") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: getShortCodeData("POLISH"), getOptionLabel: (option) => option.shortName, multiple: true }} />
      }
    } else if (d.field === "fluId") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: getShortCodeData("FLOURSENCE"), getOptionLabel: (option) => option.shortName, multiple: true }} />
      }
    } else if (d.field === "cutId") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: getShortCodeData("CUT"), getOptionLabel: (option) => option.shortName, multiple: true }} />
      }
    } else if (d.field === "symmId") {
      d.filterComponent = (props) => {
        return <CustomFilterComponent propsData={props} defaultProps={{ options: getShortCodeData("SYMMENTRY"), getOptionLabel: (option) => option.shortName, multiple: true }} />
      }
    }
    return null
  })

  useEffect(() => {
    tableRef.current && tableRef.current.onQueryChange()
  }, [data]);

  const getRecertyMasterDataV1 = () => {
    if ((lotNo && lotNo !== "" && pktNo && pktNo !== "")) {
      setFilter({ "lotNoId": lotNo, "pktNoId": pktNo })
    }
    else {
      toast.error("please select lot and pkt")
    }
  }
  const getRecertyMasterData = () => {
    return new Promise((resolve, reject) => {
      if (error) {
        reject(error)
      }
      else {
        if ((lotNo && lotNo != null) || (pktNo && pktNo != null)) {
          let result = data?.getSalesStockForCertified?.data || []
          resolve({
            data: result,
            totalCount: data?.getSalesStockForCertified?.count || 0,
          })
        }
        else {
          let result = []
          resolve({
            data: result,
            totalCount: 0,
          })
        }
      }
    })
  }

  const generateFilterString = (field, filString) => {
    let data = filter
    if (filString.length === 0) {
      delete filter[`${field}`]
      data = { ...filter }
    }
    else {
      data = { ...filter, [field]: filString }
    }
    setFilter(data)
  }

  const setSorting = (colId, type) => {
    if (!type) {
      setSort({ key: 'createdAt', type: -1 })
      setOrderBy(-1)
      setOrderDirection("")
    } else {
      setSort({ key: columns[colId].field, type: type === 'asc' ? 1 : -1 })
      setOrderBy(colId)
      setOrderDirection(type)
    }
  }
  const saveButtonHandler = () => {
    return new Promise((resolve, reject) => {
      let newData = {
        Ids: _.map(SelectedData, 'id')
      }
      UpdateCertifiedMaster({ variables: { input: newData } }).then((data) => {
        toast.success('save sucessfully');
        setSelectedData([])
        refetch()
        resolve()
      }).catch((e) => {
        reject()
        toast.error(FormatError(e))
      })
    })
  }
  return (
    <div className={classes.root}>
      <div className="row">
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Grid container>
              <Grid xs={8} item>
                {SelectedData.length !== 0 &&
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ height: 34 }} onClick={e => saveButtonHandler()}>Save</Button>
                }
              </Grid>
              <Grid xs={4} item container justify="flex-end"> <Typography className={classes.heading}>Filters</Typography></Grid>

            </Grid>


          </AccordionSummary>
          <AccordionDetails>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid item container justify="center">

                <Grid xs={5} item container justify="center">
                  <Grid xs={4} item>
                    <Autocomplete
                      {...defaultProps}
                      onChange={(e, newdata) => {
                        if (newdata) {
                          setLotNo(newdata.id)
                        }
                      }}
                      renderInput={(params) => <TextField label="Lot No"  {...params} margin="normal" />}
                    />
                  </Grid>
                  <Grid xs={4} item style={{ marginLeft: 5 }}>
                    <Autocomplete
                      {...defaultProps2}
                      onChange={(e, newdata) => {
                        if (newdata) {
                          setPktNo(newdata.id)
                        }

                      }}
                      renderInput={(params) => <TextField label="PKT No"  {...params} margin="normal" />}
                    />
                  </Grid>
                  <Grid xs={3} item style={{ marginTop: 15 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ height: 34, marginLeft: 15, marginTop: 12 }} onClick={e => getRecertyMasterDataV1()}>
                      Find
                    </Button>
                  </Grid>
                </Grid>

              </Grid>
            </MuiPickersUtilsProvider>
          </AccordionDetails>
        </Accordion>

      </div>

      <Grid container>
        <Grid item xs={12}>
          <MaterialTable
            title="Recertified Master"
            tableRef={tableRef}
            columns={columns}
            isLoading={loading}
            data={query => getRecertyMasterData()}
            options={{
              emptyRowsWhenPaging: false,
              padding: 'dense',
              selection: true,
              addRowPosition: 'first',
              filtering: true,
              sorting: true,
              search: false,
              paging: false,
              filterCellStyle: { padding: '5px', },
              rowStyle: rowData => ({
                backgroundColor: (selectedRow === rowData.tableData.id) ? 'rgb(212, 218, 255)' : '#f5f5f5'
              }),
              headerStyle: { backgroundColor: 'whitesmoke' }
            }}
            onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
            components={{
              FilterRow: props => {
                return <MTableFilterRow
                  {...props}
                  columns={props.columns}
                  onFilterChanged={(columnId, value) => {
                    props.onFilterChanged(columnId, value);
                    generateFilterString(props.columns[columnId].field, value)
                  }}
                />
              },
              Header: props => {
                return <MTableHeader
                  {...props}
                  orderBy={orderBy}
                  orderDirection={orderDirection}
                  onOrderChange={(orderBy, orderDirection) => {
                    props.onOrderChange(orderBy, orderDirection)
                    setSorting(orderBy, orderDirection)
                  }}
                />
              }
            }}
            onSelectionChange={(rows) => {
              setSelectedData(rows)
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
}



export default withRouter(RecertifiedMaster)
