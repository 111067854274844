import React from 'react'
import { AllPROCESSlIST } from '../../../pages/Master/Process/ProcessQuery'
import { useQuery } from '@apollo/react-hooks'
import _ from 'lodash'
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FormatError } from '../../Comman/FormatError'

export const ProcessAllData = () => {
  const { error, data } = useQuery(AllPROCESSlIST, {
    fetchPolicy: 'cache-and-network',
  })
  if (error)
    console.log(FormatError(error));
  let Data = data?.getAllProcess || []
  return Data
}

export const ProcessAllLookup = () => {
  let Data = ProcessAllData()
  let NewProcess = {}
  _.map(Data, (d) => {
    NewProcess[d.id] = d.procName
    return null
  })
  return NewProcess
}

export const ProcessSelectComponent = (props) => {
  let Data = ProcessAllData()
  const defaultProps = {
    options: Data,
    getOptionLabel: (option) => option.procName
  };
  return <Autocomplete
    {...defaultProps}
    onChange={(e, newValue) => {
      let propsData = props.propsData
      let Data = { ...propsData.rowData }
      Data[props.field] = newValue.id
      propsData.onRowDataChange(Data)
    }}
    defaultValue={_.filter(Data, (newd) => newd.id === props?.valueDefault)[0]}
    renderInput={(params) => <TextField   {...params} margin="normal" />}
  />
}
