import gql from 'graphql-tag';

const ROLES = gql`
   query getRoles($page: Int, $limit: Int, $filter:String, $sort:roleSort){
    getRoles(page:$page, limit:$limit, filter:$filter, sort:$sort){
      count
      data{
        id
        role
        permissions
        isActive
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
    }
  }
`;

export default ROLES

