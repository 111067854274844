import React, { useState, useEffect, useRef } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import DASHBOARD from './DashboardQuery';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Grid } from '@material-ui/core';
import AirplanemodeActiveRoundedIcon from '@material-ui/icons/AirplanemodeActiveRounded';
import { useStyles } from './Style'
export default function Dashboard(props) {
  const classes = useStyles();
 
  const { loading,data } = useQuery(DASHBOARD, {
    variables: { filter: JSON.stringify({}) },
    fetchPolicy: 'cache-and-network'
  })
  let todayRoughCount = data?.getDashboardData?.todayRoughCount || 0;
  let monthRoughCount = data?.getDashboardData?.monthRoughCount || 0
  let todayPlanningCount = data?.getDashboardData?.todayPlanningCount || 0
  let monthPlanningCount = data?.getDashboardData?.monthPlanningCount || 0
  let todayInwardCount = data?.getDashboardData?.todayInwardCount || 0
  let monthInwardCount = data?.getDashboardData?.monthInwardCount || 0
  let todayFinalPolishCount = data?.getDashboardData?.todayFinalPolishCount || 0
  let monthFinalPolishCount = data?.getDashboardData?.monthFinalPolishCount || 0
  let todayBranchTransferCount = data?.getDashboardData?.todayBranchTransferCount || 0
  let monthBranchTransferCount = data?.getDashboardData?.monthBranchTransferCount || 0
  let todaySalesMasterCount = data?.getDashboardData?.todaySalesMasterCount || 0
  let monthSalesMasterCount = data?.getDashboardData?.monthSalesMasterCount || 0


  // if(loading){
  //   return <Grid container className={classes.loader} ><img src="https://i.gifer.com/4V0b.gif" alt="loading" /></Grid>
  // }
  // else{
  return (
    <Grid xs={12} item container className={classes.maindiv}  >
      <Grid xs={12} item container spacing={1} className={classes.todateLogo}>
        <Grid xs={6} item className={classes.todateLogo}>
          <Grid xs={12} item container spacing={1} className={classes.todateLogo}>
            <Grid xs={4} item>
              <Card className={classes.root}>
                <CardContent className={classes.content}>
                  <Grid xs={12} item container className={classes.main3div}>
                    <Grid xs={6} item>
                      <h3 style={{ margin: 0 }}> Rough</h3>
                    </Grid>
                    <Grid xs={6} item justify="flex-end" container className={classes.todateLogo}>
                      <div className={classes.MuiChip_Root}  ><span className={classes.MuiChip_label}>Today</span></div>
                    </Grid>
                  </Grid>
                  <Grid xs={12} item container className={classes.main3div}>
                    <h2 style={{ margin: 0, fontWeight: 400 }}> {todayRoughCount} </h2>
                  </Grid>
                  {/* <Grid xs={12} item container className={classes.todateLogo}>
                    <h3 style={{ margin: 0, fontWeight: 400 }} ><span className={classes.kiGFCc_Span}>+14%</span> Since last week</h3>
                  </Grid> */}
                </CardContent>
              </Card>
            </Grid>
            <Grid xs={4} item>
              <Card className={classes.root}>
                <CardContent className={classes.content}>
                  <Grid xs={12} item container className={classes.main3div}>
                    <Grid xs={6} item>
                      <h3 style={{ margin: 0 }}> Planing</h3>
                    </Grid>
                    <Grid xs={6} item justify="flex-end" container className={classes.todateLogo}>
                      {/* <AirplanemodeActiveRoundedIcon style={{ color: "rgb(92, 107, 192)", fontSize: 26, fontWeight: 400 }} /> */}
                      <div className={classes.MuiChip_Root}  ><span className={classes.MuiChip_label}>Today</span></div>
                    </Grid>
                  </Grid>
                  <Grid xs={12} item container className={classes.main3div}>
                    <h2 style={{ margin: 0, fontWeight: 400 }}> {todayPlanningCount} </h2>
                  </Grid>
                  {/* <Grid xs={12} item container className={classes.todateLogo}>
                    <h3 style={{ margin: 0, fontWeight: 400 }} ><span className={classes.kiGFCc_Span}>+14%</span> Since last week</h3>
                  </Grid> */}
                </CardContent>
              </Card>
            </Grid>
            <Grid xs={4} item>
              <Card className={classes.root}>
                <CardContent className={classes.content}>
                  <Grid xs={12} item container className={classes.main3div}>
                    <Grid xs={6} item>
                      <h3 style={{ margin: 0 }}> Inward</h3>
                    </Grid>
                    <Grid xs={6} item justify="flex-end" container className={classes.todateLogo}>
                      <div className={classes.MuiChip_Root}  ><span className={classes.MuiChip_label}>Today</span></div>
                    </Grid>
                  </Grid>
                  <Grid xs={12} item container className={classes.main3div}>
                    <h2 style={{ margin: 0, fontWeight: 400 }}> {todayInwardCount} </h2>
                  </Grid>
                  {/* <Grid xs={12} item container className={classes.todateLogo}>
                    <h3 style={{ margin: 0, fontWeight: 400 }} ><span className={classes.kiGFCc_Span}>+14%</span> Since last week</h3>
                  </Grid> */}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid xs={12} item container spacing={1} className={classes.todateLogo}>
            <Grid xs={4} item>
              <Card className={classes.root}>
                <CardContent className={classes.content}>
                  <Grid xs={12} item container className={classes.main3div}>
                    <Grid xs={6} item>
                      <h3 style={{ margin: 0 }}> Final Polish</h3>
                    </Grid>
                    <Grid xs={6} item justify="flex-end" container className={classes.todateLogo}>
                      <div className={classes.MuiChip_Root}  ><span className={classes.MuiChip_label}>Today</span></div>
                    </Grid>
                  </Grid>
                  <Grid xs={12} item container className={classes.main3div}>
                    <h2 style={{ margin: 0, fontWeight: 400 }}> {todayFinalPolishCount} </h2>
                  </Grid>
                  {/* <Grid xs={12} item container className={classes.todateLogo}>
                    <h3 style={{ margin: 0, fontWeight: 400 }} ><span className={classes.kiGFCc_Span}>+14%</span> Since last week</h3>
                  </Grid> */}
                </CardContent>
              </Card>
            </Grid>
            <Grid xs={4} item>
              <Card className={classes.root}>
                <CardContent className={classes.content}>
                  <Grid item xs={12} container className={classes.main3div}>
                    <Grid xs={7} item>
                      <h3 style={{ margin: 0 }}> Branch Transfer</h3>
                    </Grid>
                    <Grid item xs={5} justify="flex-end" container className={classes.todateLogo}>
                      {/* <AirplanemodeActiveRoundedIcon style={{ color: "rgb(92, 107, 192)", fontSize: 26, fontWeight: 400 }} /> */}
                      <div className={classes.MuiChip_Root}  ><span className={classes.MuiChip_label}>Today</span></div>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container className={classes.main3div}>
                    <h2 style={{ margin: 0, fontWeight: 400 }}> {todayBranchTransferCount} </h2>
                  </Grid>
                  {/* <Grid xs={12} item container className={classes.todateLogo}>
                    <h3 style={{ margin: 0, fontWeight: 400 }} ><span className={classes.kiGFCc_Span}>+14%</span> Since last week</h3>
                  </Grid> */}
                </CardContent>
              </Card>
            </Grid>
            <Grid xs={4} item>
              <Card className={classes.root}>
                <CardContent className={classes.content}>
                  <Grid xs={12} item container className={classes.main3div}>
                    <Grid xs={6} item>
                      <h3 style={{ margin: 0 }}> Sales packet</h3>
                    </Grid>
                    <Grid xs={6} item justify="flex-end" container className={classes.todateLogo}>
                      <div className={classes.MuiChip_Root}  ><span className={classes.MuiChip_label}>Today</span></div>
                    </Grid>
                  </Grid>
                  <Grid xs={12} item container className={classes.main3div}>
                    <h2 style={{ margin: 0, fontWeight: 400 }}> {todaySalesMasterCount} </h2>
                  </Grid>
                  {/* <Grid xs={12} item container className={classes.todateLogo}>
                    <h3 style={{ margin: 0, fontWeight: 400 }} ><span className={classes.kiGFCc_Span}>+14%</span> Since last week</h3>
                  </Grid> */}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={6} item className={classes.todateLogo}>
          <Grid xs={12} item container spacing={1} className={classes.todateLogo}>
            <Grid xs={4} item>
              <Card className={classes.root}>
                <CardContent className={classes.content}>
                  <Grid xs={12} item container className={classes.main3div}>
                    <Grid xs={6} item>
                      <h3 style={{ margin: 0 }}> Rough</h3>
                    </Grid>
                    <Grid xs={6} item justify="flex-end" container className={classes.todateLogo}>
                      <div className={classes.MuiChip_Root}  ><span className={classes.MuiChip_label}>Month</span></div>
                    </Grid>
                  </Grid>
                  <Grid xs={12} item container className={classes.main3div}>
                    <h2 style={{ margin: 0, fontWeight: 400 }}> {monthRoughCount} </h2>
                  </Grid>
                  {/* <Grid xs={12} item container className={classes.todateLogo}>
                    <h3 style={{ margin: 0, fontWeight: 400 }} ><span className={classes.kiGFCc_Span}>+14%</span> Since last week</h3>
                  </Grid> */}
                </CardContent>
              </Card>
            </Grid>
            <Grid xs={4} item>
              <Card className={classes.root}>
                <CardContent className={classes.content}>
                  <Grid xs={12} item container className={classes.main3div}>
                    <Grid xs={6} item>
                      <h3 style={{ margin: 0 }}> Planing</h3>
                    </Grid>
                    <Grid xs={6} item justify="flex-end" container className={classes.todateLogo}>
                      <div className={classes.MuiChip_Root}  ><span className={classes.MuiChip_label}>Month</span></div>
                      {/* <AirplanemodeActiveRoundedIcon style={{ color: "rgb(92, 107, 192)", fontSize: 26, fontWeight: 400 }} /> */}
                    </Grid>
                  </Grid>
                  <Grid xs={12} item container className={classes.main3div}>
                    <h2 style={{ margin: 0, fontWeight: 400 }}> {monthPlanningCount} </h2>
                  </Grid>
                  {/* <Grid xs={12} item container className={classes.todateLogo}>
                    <h3 style={{ margin: 0, fontWeight: 400 }} ><span className={classes.kiGFCc_Span}>+14%</span> Since last week</h3>
                  </Grid> */}
                </CardContent>
              </Card>
            </Grid>
            <Grid xs={4} item>
              <Card className={classes.root}>
                <CardContent className={classes.content}>
                  <Grid xs={12} item container className={classes.main3div}>
                    <Grid xs={6} item>
                      <h3 style={{ margin: 0 }}> Inward</h3>
                    </Grid>
                    <Grid xs={6} item justify="flex-end" container className={classes.todateLogo}>
                      <div className={classes.MuiChip_Root}  ><span className={classes.MuiChip_label}>Month</span></div>
                    </Grid>
                  </Grid>
                  <Grid xs={12} item container className={classes.main3div}>
                    <h2 style={{ margin: 0, fontWeight: 400 }}> {monthInwardCount} </h2>
                  </Grid>
                  {/* <Grid xs={12} item container className={classes.todateLogo}>
                    <h3 style={{ margin: 0, fontWeight: 400 }} ><span className={classes.kiGFCc_Span}>+14%</span> Since last week</h3>
                  </Grid> */}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid xs={12} item container spacing={1} className={classes.todateLogo}>
            <Grid xs={4} item>
              <Card className={classes.root}>
                <CardContent className={classes.content}>
                  <Grid xs={12} item container className={classes.main3div}>
                    <Grid xs={6} item>
                      <h3 style={{ margin: 0 }}> Final Polish</h3>
                    </Grid>
                    <Grid xs={6} item justify="flex-end" container className={classes.todateLogo}>
                      <div className={classes.MuiChip_Root}  ><span className={classes.MuiChip_label}>Month</span></div>
                    </Grid>
                  </Grid>
                  <Grid xs={12} item container className={classes.main3div}>
                    <h2 style={{ margin: 0, fontWeight: 400 }}> {monthFinalPolishCount} </h2>
                  </Grid>
                  {/* <Grid xs={12} item container className={classes.todateLogo}>
                    <h3 style={{ margin: 0, fontWeight: 400 }} ><span className={classes.kiGFCc_Span}>+14%</span> Since last week</h3>
                  </Grid> */}
                </CardContent>
              </Card>
            </Grid>
            <Grid xs={4} item>
              <Card className={classes.root}>
                <CardContent className={classes.content}>
                  <Grid item xs={12} container className={classes.main3div}>
                    <Grid xs={7} item>
                      <h3 style={{ margin: 0 }}> Branch Transfer</h3>
                    </Grid>
                    <Grid item xs={5} justify="flex-end" container className={classes.todateLogo}>
                      <div className={classes.MuiChip_Root}  ><span className={classes.MuiChip_label}>Month</span></div>
                      {/* <AirplanemodeActiveRoundedIcon style={{ color: "rgb(92, 107, 192)", fontSize: 26, fontWeight: 400 }} /> */}
                    </Grid>
                  </Grid>
                  <Grid item xs={12} container className={classes.main3div}>
                    <h2 style={{ margin: 0, fontWeight: 400 }}> {monthBranchTransferCount} </h2>
                  </Grid>
                  {/* <Grid xs={12} item container className={classes.todateLogo}>
                    <h3 style={{ margin: 0, fontWeight: 400 }} ><span className={classes.kiGFCc_Span}>+14%</span> Since last week</h3>
                  </Grid> */}
                </CardContent>
              </Card>
            </Grid>
            <Grid xs={4} item>
              <Card className={classes.root}>
                <CardContent className={classes.content}>
                  <Grid xs={12} item container className={classes.main3div}>
                    <Grid xs={6} item>
                      <h3 style={{ margin: 0 }}> Sales packet</h3>
                    </Grid>
                    <Grid xs={6} item justify="flex-end" container className={classes.todateLogo}>
                      <div className={classes.MuiChip_Root}  ><span className={classes.MuiChip_label}>Month</span></div>
                    </Grid>
                  </Grid>
                  <Grid xs={12} item container className={classes.main3div}>
                    <h2 style={{ margin: 0, fontWeight: 400 }}> {monthSalesMasterCount} </h2>
                  </Grid>
                  {/* <Grid xs={12} item container className={classes.todateLogo}>
                    <h3 style={{ margin: 0, fontWeight: 400 }} ><span className={classes.kiGFCc_Span}>+14%</span> Since last week</h3>
                  </Grid> */}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>

      </Grid >
    </Grid >
  )
}
// }

