import React from 'react'
import _ from 'lodash'
import { Grid, InputLabel, Table } from '@material-ui/core';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';

import { AutoSizer, List, CellMeasurer, CellMeasurerCache } from 'react-virtualized';

import { Tofixed } from '../../../../components/Comman/Tofix'
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#8CB836",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 13,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const useStyles = makeStyles(() => ({
    denseTable: {
        '& td': { padding: "2px 5px", cursor: "pointer", textAlign: "center" },
        '& th': { padding: "5px 5px", cursor: "pointer", textAlign: "center" }
    },
    loader: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: window.innerHeight - 120,
        background: '#8080803b',
        pointerEvents: 'none',
        opacity: 0.5
    }
}))
export const DepartmentWise = ({ Data, SetFilter }) => {
    const classes = useStyles();
    const cache = React.useRef(
        new CellMeasurerCache({
            fixedWidth: true,
            defaultHeight: 100
        })
    )
    const ListRef = React.useRef();
    const onRowClickHandler = (e, data) => {
        SetFilter({ 'status': data?.status })
    }


    return <>
        <Grid xs={6} item className="pr-5">
            <Grid container>
                <Grid xs={5} item>
                    <InputLabel style={{ fontSize: 16, paddingBottom: 10, color: "rgb(70, 81, 142)" }}>
                        Department Wise
                    </InputLabel>
                </Grid>
                <Grid xs={7} item>
                    <InputLabel style={{ fontSize: 16, paddingBottom: 10, color: "rgb(70, 81, 142)", textAlign: "end" }}>
                        {`Tot Pkts: ${_.sumBy(Data, (d) => d.TotalPkt)} | Cts: ${Tofixed(_.sumBy(Data, (d) => d.TotalCts))}`}&nbsp;
              </InputLabel>
                </Grid>
            </Grid>

            <TableContainer component={Paper}>
                <Table className={classes.denseTable}>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell style={{ width: '50%', textAlign: "center" }} >{"Department"}</StyledTableCell>
                            <StyledTableCell style={{ width: '25%', textAlign: "center" }} >{"Total Pkts"}</StyledTableCell>
                            <StyledTableCell style={{ width: '25%', textAlign: "center" }} >{"Total CTS"}</StyledTableCell>
                        </TableRow>
                    </TableHead>
                </Table>

                <div style={{ width: "100%", height: 200, marginBottom: 10 }}>
                    <AutoSizer>
                        {({ width, height }) => {
                            return <List
                                ref={ListRef}
                                height={height}
                                width={width}
                                rowHeight={20}
                                deferredMeasurementCache={cache.current}
                                rowCount={Data?.length || 0}
                                rowRenderer={({ key, index, style, parent }) => {

                                    const row = Data[index]
                                    return <CellMeasurer key={key} cache={cache.current} parent={parent} columnIndex={0} rowIndex={index}>
                                        <div style={style}>
                                            <div className="tbody"  >
                                                <Table aria-label="customized table" className={classes.denseTable} style={{ padding: 10 }}>
                                                    <TableBody onDoubleClick={(e) => onRowClickHandler(e, row)} style={{ cursor: "pointer" }}>
                                                        <StyledTableRow key={key} >
                                                            <StyledTableCell style={{ width: '50%', textAlign: "center" }}>
                                                                {row["status"] === "null" || row["status"] === "undefined" ? "" : row["status"]}
                                                            </StyledTableCell>
                                                            <StyledTableCell style={{ width: '25%', textAlign: "center" }}>
                                                                {row["TotalPkt"]}
                                                            </StyledTableCell>
                                                            <StyledTableCell style={{ width: '25%', textAlign: "center" }}>
                                                                {Tofixed(row["TotalCts"])}
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    </TableBody>
                                                </Table>
                                            </div>
                                        </div>
                                    </CellMeasurer>

                                }}
                            />
                        }}
                    </AutoSizer>
                </div>
            </TableContainer>

        </Grid>
    </>
}