import React, { Component } from "react";
import { Router, Route, Redirect, Switch } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import _ from 'lodash';
import 'react-toastify/dist/ReactToastify.css';

import NavBar from './ui/layouts/Navbar';
import Login from './ui/pages/Login/Login';

/********************* Admin  Page **************** */
import Roles from './ui/pages/Master/Roles/Roles';
import Users from './ui/pages/Master/Users/Users';
import PartyMaster from './ui/pages/Master/PartyMaster';
import Company from './ui/pages/Master/Company';
import Process from './ui/pages/Master/Process';
import Department from './ui/pages/Master/Department';
import BoxSize from './ui/pages/Master/BoxSize';
import Terms from './ui/pages/Master/Terms';
import BankDetail from './ui/pages/Master/BankDetails';
import MailSetting from './ui/pages/Master/MailSetting';
import DepartmentWiseProcessMaster from './ui/pages/Master/DeptWiseProcess';

/********************* Transaction  Page **************** */
import Rough from './ui/pages/Transaction/Rough';
import Costing from './ui/pages/Transaction/Costing';
import Inward from './ui/pages/Transaction/Inward';
import Kapan from './ui/pages/Transaction/Kapan';
import Grad from './ui/pages/Transaction/Grad';
import Packet from './ui/pages/Transaction/Packet';
import StockMaster from './ui/pages/PowerMenu/StockMaster/index';
import DepartmentIsuueReturn from './ui/pages/Transaction/DepartmentIsuueReturn/index';
import JobWorkPkt from './ui/pages/Transaction/JobWorkPkt/index';
import PacketDeassociation from './ui/pages/Transaction/PacketDeassociation/index';
import SizeWiseStockMaster from './ui/pages/Transaction/SizeWiseStockMaster/index';
import RecertifiedMaster from './ui/pages/Transaction/RecertifiedMaster/index';
import Sales from './ui/pages/Transaction/Sales/index';
import IssueReturnMaster from './ui/pages/Transaction/IssueReturnMaster/index';
import BranchTransfer from './ui/pages/Transaction/BranchTransfer/index';
import RoughPlanning from './ui/pages/Transaction/RoughPlanning/index';

/********************* Account  Page **************** */
import Payment from './ui/pages/Accounts/Payment';
// import Account from './ui/pages/Accounts/Accounts';

/********************* Reports  Page **************** */
import MFGKapanStock from './ui/pages/Reports/MFGKapanStock/index';
import KapanWiseCosting from './ui/pages/Reports/KapanWiseCosting/index';
import PurchaseSales from './ui/pages/Reports/PurchaseSales/index';
import PlanningComparision from './ui/pages/Reports/PlanningComparision/index';
import JobWork from './ui/pages/Reports/JobWork/index';
import BranchTransferReport from './ui/pages/Reports/BranchTransfer/index';
import SalesReport from './ui/pages/Reports/Sales/index';
import LabPending from './ui/pages/Reports/LabPending/index';
import ApproPending from './ui/pages/Reports/ApproPending/index';
import SalesOutstanding from './ui/pages/Reports/SalesOutstanding/index';

/********************* Utility  Page **************** */
import ShortCode from './ui/pages/Utility/SortCode/index';
import RapaPortUser from './ui/pages/Utility/RapaPortUser/index';
import AuditLog from './ui/pages/Utility/AuditLog/index';
import Backup from './ui/pages/Utility/Backup/index';

/********************* Power Menu  Page **************** */
import LiveScreen from './ui/pages/PowerMenu/LiveScreen/index';
import PolishLiveScreen from './ui/pages/PowerMenu/PolishLiveScreen/index';
import PaymentDashboard from './ui/pages/PowerMenu/PaymentDashboard/index';
import UpdateLocation from './ui/pages/PowerMenu/UpdateLocation/index';
import PacketHistory from './ui/pages/PowerMenu/PacketHistory';

import Dashboard from './ui/pages/Dashboard/Dashboard';
import ForgotPassword from './ui/pages/ForgotPassword';

import UserProfile from './ui/pages/Master/Users/UserProfile';
import history from './constants/history';
import withSession from './ui/components/Session/withSession';
import AccessDenied from './ui/components/Comman/AccessDenied';
import { AddIssueReturnData } from './ui/pages/Transaction/IssueReturnMaster/addIssueReturnData';
import welcome from "./ui/pages/welcome";
import { TwoStepVerification } from "./ui/pages/TwoStepVerification";
import { TwoFa } from "./ui/pages/welcome/TwoFa";


class AppRouter extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }



  render() {
    const { session, refetch } = this.props;
    return (
      <Router history={history}>
        <div>
          <NavBar session={session} history={history} {...this.props} />

          <div>
            <Switch>
              <PublicRoute exact session={session} path="/" component={welcome} />
              <PublicRoute exact session={session} path="/signin2ndStep" component={TwoFa} />
              <PublicRoute exact session={session} path="/login" component={() => <Login refetch={refetch} />} />
              <PublicRoute exact session={session} path="/forgotpassword" component={ForgotPassword} />

              <UserRoute exact session={session} path="/dashboard" component={Dashboard} />
              <UserRoute exact session={session} path="/addissuereturn/:id" component={AddIssueReturnData} />
              <AdminRoute exact session={session} path="/roles" component={Roles} />
              <AdminRoute exact session={session} path="/users" component={Users} />
              <AdminRoute exact session={session} path="/auditlog" component={AuditLog} />
              <AdminRoute exact session={session} path="/backup_and_restore" component={Backup} />
              {/* <AdminRoute exact session={session} path="/accounts" component={Account} /> */}

              <UserRoute exact session={session} path="/company" component={Company} />
              <UserRoute exact session={session} path="/partymaster" component={PartyMaster} />
              <UserRoute exact session={session} path="/department" component={Department} />
              <UserRoute exact session={session} path="/boxsize" component={BoxSize} />
              <UserRoute exact session={session} path="/process" component={Process} />
              <UserRoute exact session={session} path="/terms" component={Terms} />
              <UserRoute exact session={session} path="/bankdetails" component={BankDetail} />
              <UserRoute exact session={session} path="/mailsetting" component={MailSetting} />
              <UserRoute exact session={session} path="/deptwiseprocess" component={DepartmentWiseProcessMaster} />
              <UserRoute exact session={session} path="/rough" component={Rough} />
              <UserRoute exact session={session} path="/shortcode" component={ShortCode} />
              <UserRoute exact session={session} path="/rapaport" component={RapaPortUser} />
              <UserRoute exact session={session} path="/inward" component={Inward} />
              <UserRoute exact session={session} path="/kapan" component={Kapan} />
              <UserRoute exact session={session} path="/costing" component={Costing} />
              <UserRoute exact session={session} path="/grad" component={Grad} />
              <UserRoute exact session={session} path="/stock" component={StockMaster} />
              <UserRoute exact session={session} path="/sales" component={Sales} />
              <UserRoute exact session={session} path="/departmentisuuereturn" component={DepartmentIsuueReturn} />
              <UserRoute exact session={session} path="/branchtransfer" component={BranchTransfer} />
              <UserRoute exact session={session} path="/payment" component={Payment} />
              <UserRoute exact session={session} path="/packet" component={Packet} />
              <UserRoute exact session={session} path="/jobpkt" component={JobWorkPkt} />
              <UserRoute exact session={session} path="/packetdeasso" component={PacketDeassociation} />
              <UserRoute exact session={session} path="/sizewisestock" component={SizeWiseStockMaster} />
              <UserRoute exact session={session} path="/recertifiedmaster" component={RecertifiedMaster} />
              <UserRoute exact session={session} path="/issuereturn" component={IssueReturnMaster} />
              <UserRoute exact session={session} path="/roughplanning" component={RoughPlanning} />
              {/* <UserRoute exact session={session} path="/departmentwiseprocess" component={DepartmentWiseProcess} /> */}
              <UserRoute exact session={session} path="/mfgkapanstock" component={MFGKapanStock} />
              <UserRoute exact session={session} path="/kapanwisecosting" component={KapanWiseCosting} />
              <UserRoute exact session={session} path="/purchasesales" component={PurchaseSales} />
              <UserRoute exact session={session} path="/planningcomparision" component={PlanningComparision} />
              <UserRoute exact session={session} path="/jobwork" component={JobWork} />
              <UserRoute exact session={session} path="/branchtransferreport" component={BranchTransferReport} />
              <UserRoute exact session={session} path="/salesreport" component={SalesReport} />
              <UserRoute exact session={session} path="/labpending" component={LabPending} />
              <UserRoute exact session={session} path="/appropending" component={ApproPending} />
              <UserRoute exact session={session} path="/salesoutstanding" component={SalesOutstanding} />
              <UserRoute exact session={session} path="/livescreen" component={LiveScreen} />
              <UserRoute exact session={session} path="/polishlivescreen" component={PolishLiveScreen} />
              <UserRoute exact session={session} path="/paymentdashboard" component={PaymentDashboard} />
              <UserRoute exact session={session} path="/updatelocation" component={UpdateLocation} />
              <UserRoute exact session={session} path="/packethistory" component={PacketHistory} />
              <UserRoute exact session={session} path="/profile" component={UserProfile} />
              <UserRoute exact session={session} path="/twostep" component={TwoStepVerification} />
              <UserRoute exact session={session} path="/not-authorized" component={AccessDenied} />
            </Switch>
          </div>
          <ToastContainer />
        </div>
      </Router >
    )
  }
}

const isAdmin = (role) => {
  if ((role.toLowerCase() === 'admin'))
    return true
  else
    return false
}

const isView = (role, page) => {
  if (isAdmin(role.role) || page === '/not-authorized' || page === '/profile') {
    return true
  } else {
    let PageRoute = page.split("/")
    if (PageRoute?.length > 2) {
      if (PageRoute[1] === "addissuereturn") {
        return true
      }
    }
    let permission = _.find(role.permissions, function (o) { return o.name === page; })
    return permission ? permission.isView : false
  }
}

const PublicRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        let me = rest && rest.session && rest?.session?.me;
        return me ?
          <Redirect to={{ pathname: "/dashboard", state: { from: props.location } }} />
          :
          <Component {...props} {...rest} />
      }}
    />
  );
};

const UserRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        let me = rest && rest.session && rest?.session?.me;
        let role = rest?.session?.me?.role;
        return me ?
          (role && isView(role, props.location.pathname) ?
            <div style={{ padding: '15px', paddingTop: '80px' }}>
              <Component  {...props} {...rest} />
            </div>
            :
            <Redirect to={{ pathname: "/not-authorized", state: { from: props.location } }} />
          )
          :
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      }}
    />
  );
};

const AdminRoute = ({ component: Component, ...rest }) => {

  return (
    <Route
      {...rest}
      render={props => {
        let me = rest && rest.session && rest?.session?.me;
        let role = rest?.session?.me?.role?.role;
        return me ?
          (role && isAdmin(role) ?
            <div style={{ padding: '15px', paddingTop: '80px' }}>
              <Component  {...props} {...rest} />
            </div>
            :
            <Redirect to={{ pathname: "/not-authorized", state: { from: props.location } }} />
          )
          :
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      }}
    />
  );
};


export default withSession(AppRouter)

