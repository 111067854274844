import gql from 'graphql-tag';

export const GETSALESREPORT = gql`
query getSalesReport($filter: String!){
  getSalesReport(filter:$filter){
    date
    partyId{
      partyName
    }
    brokerId{
      partyName
    }
    billNo
    lotNoId{
      lotNo
    }
    pktNoId{
      pktNo
    }
    certificateNo
    shapeId{
      shortName
    }
    cts
    colorId{
      shortName
    }
    purityId{
      shortName
    }
    cutId{
      shortName
    }
    polishId{
      shortName
    } 
    symmId{
      shortName
    } 
    floId{
      shortName
    }
    rapRate
    diffPer
    netRaprate
    netRaprateAmt
    rateZar
    amountZar
    commisionPer
    commision
    polishExpPer
    polishExpAmt
    netAmtZar
    vatAmount
    billtype
  }
}
`;


