import React, { useRef, useState } from 'react'
import { Grid, Button } from '@material-ui/core';
import MaterialTable from 'material-table';
import { TextField } from '@material-ui/core';
import { useQuery, useMutation } from '@apollo/react-hooks'
import { PACKETDETAIL } from './PacketQuery'
import { ADD_PACKETDETAIL, UPDATE_PACKETDETAIL, DELETE_PACKETDFETAIL } from './PacketMutation'
import { toast } from 'react-toastify'
import _ from 'lodash'
import { FormatError } from '../../../components/Comman/FormatError'
import { Tofixed } from '../../../components/Comman/Tofix'
import SubPacketModal from '../../../components/Modals/SubPacket'
import { SubPacket } from './SubPacket'
import { GradingSelectComponent } from '../../../components/DropdownSelect/SelectComponents/ShortCodeSelectComponent'
import { GETALLSHORTCODETYPE } from '../../Utility/SortCode/SortCodeQuery';


const columns = [
  { title: 'Pkt No *', field: 'pktNo' },
  {
    title: 'cts *', field: 'cts', type: "numeric",
    render: (rowData) => Tofixed(rowData?.cts || 0),
    validate: rowData => (rowData.cts && rowData.cts !== "") ? true : { isValid: false, helperText: `can't be empty` },
    editComponent: (props) => {
      //   let valueDefault = props?.rowData?.brokerPer || 0;
      return (
        <TextField
          value={props?.rowData?.cts}
          type="number"
          onChange={(e) => {
            let Data = { ...props.rowData };
            Data.cts = e.target.value;
            props.onRowDataChange(Data);
          }}
        />
      );
    },
  },
  {
    title: 'Color *',
    field: 'colorId',
    render: (rowData) => rowData?.colorId?.shortName || "",
  },
  {
    title: 'Shape *',
    field: 'shapeId',
    render: (rowData) => rowData?.shapeId?.shortName || "",
  },
  {
    title: 'Purity *',
    field: 'purityId',
    render: (rowData) => rowData?.purityId?.shortName || "",
  },
  {
    title: 'Exp Cts ',
    field: 'expcts',
    type: "numeric",
    editComponent: (props) => {
      //   let valueDefault = props?.rowData?.brokerPer || 0;
      return (
        <TextField
          value={props?.rowData?.expcts}
          type="number"
          onChange={(e) => {
            let Data = { ...props.rowData };
            Data.expcts = e.target.value;
            props.onRowDataChange(Data);
          }}
        />
      );
    },
    render: (rowData) => Tofixed(rowData?.expcts || 0),
  },
  {
    title: 'Floursence',
    field: 'flsId',
    render: (rowData) => rowData?.flsId?.shortName || ""
  },
  {
    title: 'Tension *',
    field: 'tensionId',
    render: (rowData) => rowData?.tensionId?.shortName || ""
  },
  {
    title: 'Majuri Rate ',
    field: 'majuriRate',
    type: "numeric",
    editComponent: (props) => {
      //   let valueDefault = props?.rowData?.brokerPer || 0;
      return (
        <TextField
          value={props?.rowData?.majuriRate}
          type="number"
          onChange={(e) => {
            let Data = { ...props.rowData };
            Data.majuriRate = e.target.value;
            props.onRowDataChange(Data);
          }}
        />
      );
    },
    render: (rowData) => rowData?.majuriRate ? Tofixed(rowData?.majuriRate || 0) : ""
  },
  {
    title: 'Zar Rate ',
    field: 'zarRate',
    type: "numeric",
    editComponent: (props) => {
      //   let valueDefault = props?.rowData?.brokerPer || 0;
      return (
        <TextField
          value={props?.rowData?.zarRate}
          type="number"
          onChange={(e) => {
            let Data = { ...props.rowData };
            Data.zarRate = e.target.value;
            props.onRowDataChange(Data);
          }}
        />
      );
    },
    render: (rowData) => rowData?.zarRate ? Tofixed(rowData?.zarRate || 0) : "",

  },
  {
    title: 'SubPkt', field: 'subPkt',
    editComponent: () => "-"
  },
]
export const Packetchild = (props) => {
  const [open, setOpen] = useState(false);
  const [SubPacketData, setSubPacketData] = useState([]);
  const [PacketChildData, setPacketChildData] = useState([]);
  const [TotalRows, setTotalRows] = useState();
  const [pageSize, setPageSize] = useState(10);
  const packetDetailTableRef = useRef()
  const permissions = props?.session?.me?.role?.permissions;
  const role = props?.session?.me?.role?.role;
  const path = props?.location?.pathname;
  let permission = _.find(permissions, function (o) { return o.name === path; })
  
  const { data, fetchMore } = useQuery(PACKETDETAIL, {
    variables: { id: props.Ids, page: 1, limit: 100, filter: JSON.stringify({}), sort: { key: 'createdAt', type: 1 } },
    fetchPolicy: 'network-only'
  })
  
  const modalOpenAndClose = (d, rowData) => {
    if (rowData) {
      setPacketChildData(rowData)
    }
    setOpen(!open);
  };


  const { data: shortCodeData } = useQuery(GETALLSHORTCODETYPE, {
    variables: { type: "All" },
    fetchPolicy: 'cache-and-network',
  })

  const getShortCodeData = (TYPE) => {
    return _.filter(shortCodeData?.getAllType || [], (d) => d.type === TYPE);
  }


  columns.map((d, key) => {

    if (d.field === "colorId") {
      d.editComponent = props => {
        let valueDefault = props?.rowData?.colorId?.id || props?.rowData?.colorId || null
        return <GradingSelectComponent propsData={props} TYPE={"colorId"} data={getShortCodeData("COLOR")} valueDefault={valueDefault} />
      }
    }
    if (d.field === "shapeId") {
      d.editComponent = props => {
        let valueDefault = props?.rowData?.shapeId?.id || props?.rowData?.shapeId || null
        return <GradingSelectComponent propsData={props} TYPE={"shapeId"} data={getShortCodeData("SHAPE")} valueDefault={valueDefault} />
      }
    }
    if (d.field === "purityId") {
      d.editComponent = props => {
        let valueDefault = props?.rowData?.purityId?.id || props?.rowData?.purityId || null
        return <GradingSelectComponent propsData={props} TYPE={"purityId"} data={getShortCodeData("PURITY")} valueDefault={valueDefault} />
      }
    }
    if (d.field === "flsId") {
      d.editComponent = props => {
        let valueDefault = props?.rowData?.flsId?.id || props?.rowData?.flsId || null
        return <GradingSelectComponent propsData={props} TYPE={"flsId"} data={getShortCodeData("FLOURSENCE")} valueDefault={valueDefault} />
      }
    }
    if (d.field === "tensionId") {
      d.editComponent = props => {
        let valueDefault = props?.rowData?.tensionId?.id || props?.rowData?.tensionId || null
        return <GradingSelectComponent propsData={props} TYPE={"tensionId"} data={getShortCodeData("TENSION")} valueDefault={valueDefault} />
      }
    }

    if (d.field === "subPkt") {
      d.render = (rowData) => {
        let subPacket = rowData?.subPkt || null
        let Ids = rowData?.id || null
        let SubPacketIds = _.filter(SubPacketData, (d) => {
          return d?.subPktMasterId?.id === Ids
        })
        if (subPacket === "yes") {
          return null
        } else {
          return <Button key={key} variant="contained" color="primary" className="sub-packet" onClick={(d) => modalOpenAndClose(d, rowData)}>
            subPkt  ({rowData?.totalSubPacket || 0})
          </Button>
        }
      }
    }
    return null
  })
  const getPacketDetail = (query) => {
    return new Promise(async (resolve, reject) => {
      let filter = {}, sort = { key: 'createdAt', type: 1 }
      query && query.filters.forEach(item => {
        if ((item.column.field === "pktNo" || item.column.field === "cts" || item.column.field === "expcts" || item.column.field === "subPkt")) {
          if (item.value)
            filter = { ...filter, [item.column.field]: parseInt(item.value) }
          else {
            delete filter[item.column.field]
            filter = { ...filter }
          }
        }
        if (item.value.length === 0) {
          delete filter[`${item.column.field}`]
          filter = { ...filter }
        } else
          filter = { ...filter, [item.column.field]: item.value }
      });
      if (query && query.orderBy && query.orderDirection !== "") {
        sort = { key: query.orderBy.field, type: query.orderDirection === 'asc' ? 1 : -1 }
      }
      setPageSize(query.pageSize)
      try {
        let result = await fetchMore({
          variables: { page: query.page + 1, limit: query.pageSize, filter: JSON.stringify(filter), sort: sort },
          fetchPolicy: 'network-only',
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            return fetchMoreResult;
          }
        })
        let SubPacketData = result?.data?.getAllPacketDetail?.data || []
        let Data = _.filter(SubPacketData, (d => d.isSubPkt === false))
        if (SubPacketData.length !== 0) {
          let LastRow = {
            'pktNo': result?.data?.getAllPacketDetail?.count,
            'cts': result?.data?.getAllPacketDetail?.totalCts,
            'expcts': result?.data?.getAllPacketDetail?.totalExpCts,
            'subPkt': "yes"
          }
          setTotalRows(LastRow)
        }
        // let SubPacketresult = _.filter([...SubPacketData || []] || [], (d) => d.isSubPkt === true && d.isPlanning === true)
        //   setSubPacketData(SubPacketresult)
        resolve({
          data: Data || [],
          page: query.page,
          totalCount: result?.data?.getAllPacketDetail?.count || 0,
        })

      } catch (error) {

      }

    })
  }
  const [AddPacketDetailMutation] = useMutation(ADD_PACKETDETAIL)
  const [UpdatePacketDetailMutation] = useMutation(UPDATE_PACKETDETAIL)
  const [DeletePacketDetailMutation] = useMutation(DELETE_PACKETDFETAIL)

  const updateMasterDataFromChild = (Type, newData) => {
    let PacketMasterData = props.PacketMasterData
    let result = data?.getAllPacketDetail || [].filter(d => d.isSubPkt === false)
    let TotalCts, TotalPackets
    if (Type === "ADD") {
      TotalPackets = result.length + 1
      TotalCts = _.sum(_.map(result, 'cts')) + newData.cts
    } else if (Type === "UPDATE") {
      TotalPackets = result.length
      TotalCts = _.sum(_.map(_.filter(result, (d) => d.id !== newData.id), 'cts')) + newData.cts
    } else if (Type === "DELETE") {
      if (result.length !== 0) {
        TotalPackets = result.length - 1
        TotalCts = _.sum(_.map(_.filter(result, (d) => d.id !== newData.id), 'cts')) - newData.cts
      } else {
        TotalPackets = 0
        TotalCts = 0
      }
    }
    let a = PacketMasterData.tableData
    delete PacketMasterData.tableData

    props.UpdatePacket(PacketMasterData, props.PacketMasterData)
    PacketMasterData.tableData = a
    return true


  }
  const UpdatePacketChild = (newData, oldData) => {
    return new Promise((resolve, reject) => {
      let result = data?.getAllPacketDetail || []
      delete newData.createdBy
      delete newData.createdAt
      delete newData.__typename
      delete newData.updatedAt
      delete newData.updatedBy
      newData.colorId = newData?.colorId?.id || newData?.colorId || null
      newData.tensionId = newData?.tensionId?.id || newData?.tensionId || null
      newData.purityId = newData?.purityId?.id || newData?.purityId || null
      newData.flsId = newData?.flsId?.id || newData?.flsId || null

      newData.cts = parseFloat(Tofixed(newData.cts))
      newData.majuriRate = parseFloat(Tofixed(newData.majuriRate))
      newData.zarRate = parseFloat(Tofixed(newData.zarRate))
      newData.expcts = parseFloat(Tofixed(newData.expcts))
      newData.packetMasterId = props.Ids
      newData.shapeId = newData?.shapeId?.id || newData?.shapeId
      delete newData.isPlanning
      if (newData.pktNo && newData.cts && (0 <= newData.expcts)) {
        if (newData.cts >= newData.expcts) {
          if (_.findIndex(result, function (o) { return (o.pktNo).trim() === (newData.pktNo).trim() }) === -1) {
            let log = {
              action: 'UPDATE',
              actionOn: 'PacketDetail',
              actionName: 'updatePacketDetail',
              oldValue: JSON.stringify(oldData),
              message: `${oldData?.pktNo || ''} packet was updated.`
            }
            if (updateMasterDataFromChild("UPDATE", newData)) {
              UpdatePacketDetailMutation({ variables: { input: newData, log } }).then((data) => {
                toast.success('packet master updated successfully.');
                props.tableRef.current.onQueryChange();
                resolve()
              }).catch((e) => {
                reject()
                toast.error(FormatError(e))
              })
            } else {
              reject()
            }
          }
          else {
            reject()
            toast.error('packet name already exist')
          }
        } else {
          reject()
          toast.error('expect carats is max than carats ')
        }
      } else {
        reject()
        toast.warn('please, fill all required(*) fields.')
      }
    })
  }
  const AddPacketChild = (newData) => {
    return new Promise((resolve, reject) => {
      let result = data?.getAllPacketDetail || []

      newData.packetMasterId = props.Ids
      newData.entryType = props.PacketMasterData.entryType
      newData.partyId = props.PacketMasterData.partyId.id
      newData.cts = parseFloat(newData?.cts || 0)
      newData.majuriRate = parseFloat(Tofixed(newData?.majuriRate || 0))
      newData.zarRate = parseFloat(Tofixed(newData?.zarRate || 0))
      newData.expcts = parseFloat(Tofixed(newData?.expcts || 0))
      newData.lotNoId = props.PacketMasterData.lotNoId?.id || props.PacketMasterData.lotNoId


      if (newData.pktNo && (0 <= newData.expcts)) {
        if (newData.cts >= newData.expcts) {
          if (_.findIndex(result, function (o) { return (o.pktNo).trim() === (newData.pktNo).trim() }) === -1) {
            let log = {
              action: 'INSERT',
              actionOn: 'PacketDetail',
              actionName: 'addNewPacketDetail',
              oldValue: "",
              message: `${newData?.pktNo || ''} packet was created.`
            }
            if (updateMasterDataFromChild("ADD", newData)) {
              AddPacketDetailMutation({ variables: { input: newData, log } }).then((data) => {
                toast.success('packet detail created successfully.');
                props.tableRef.current.onQueryChange();
                resolve()
              }).catch((e) => {
                reject()
                toast.error(FormatError(e))
              })
            } else {
              reject()
            }
          } else {
            reject()
            toast.error('packet name already exist')
          }
        } else {
          reject()
          toast.error('expect carats is max than carats ')
        }
      } else {
        reject()
        toast.warn('please, fill all required(*) fields.')
      }
    })
  }

  const DeletePacketChild = (oldData) => {
    return new Promise((resolve, reject) => {
      let log = {
        action: 'DELETE',
        actionOn: 'PacketDetail',
        actionName: 'deletePacketDetail',
        oldValue: JSON.stringify(oldData),
        message: `${oldData?.pktNo || ''} packet was deleted.`
      }
      if (updateMasterDataFromChild("DELETE", oldData)) {
        DeletePacketDetailMutation({ variables: { id: oldData.id, log } }).then((data) => {
          toast.success('packet detail deleted successfully!');
          resolve()
        }).catch((e) => {
          reject()
          toast.error(FormatError(e))
        })
      } else {
        reject()
        toast.error('err')
      }
    })
  }


  const addButton = (val) => {
    if (val || (role === 'admin')) {
      return { onRowAdd: (newData) => AddPacketChild(newData) }
    }
    else
      return {}
  }
  return <Grid
    container
    direction="row"
    justify="flex-end" item xs={12} >

    <SubPacketModal modalClose={modalOpenAndClose} open={open} Title="Sub Packet Information">
      <SubPacket
        key={props.Ids}
        DeletePacketDetailMutation={DeletePacketDetailMutation}
        UpdatePacketDetailMutation={UpdatePacketDetailMutation}
        AddPacketDetailMutation={AddPacketDetailMutation}
        TotalSubPacket={SubPacketData}
        PacketChildData={PacketChildData}
        PacketMasterData={props.PacketMasterData}
        tableRef={packetDetailTableRef}
        updateMasterData={props.updateMasterData} />
    </SubPacketModal>

    <div className="stockCustomTableComponent" style={{ position: "relative", width: "98%" }}>
      <div style={{ display: "flex", position: "absolute", zIndex: 99, left: 0, top: 15 }}>
        <div style={{ width: 200 }}> Total PKT :- {TotalRows?.pktNo || 0}</div>
        <div style={{ width: 200 }}> Total cts :- {TotalRows?.cts || 0} </div>
        <div style={{ width: 200 }}> Exp Cts  :- {TotalRows?.expcts || 0} </div>
      </div>
      <MaterialTable
        columns={columns}
        tableRef={packetDetailTableRef}
        data={(query) => getPacketDetail(query)}
        options={{
          emptyRowsWhenPaging: false,
          padding: 'dense',
          pageSize: pageSize,
          pageSizeOptions: [5, 10, 15, 20, 25, 50],
          addRowPosition: 'first',
          filtering: true,
          sorting: true,
          search: false,
          paging: true,
          filterCellStyle: { padding: '5px', },
          maxBodyHeight: window.innerHeight - 215,
          showTitle: false,
          headerStyle: { backgroundColor: 'whitesmoke' }
        }}
        editable={{
          isEditHidden: (rowData) => (role === 'admin') ? false : !permission.isUpdate,
          isDeleteHidden: (rowData) => (role === 'admin') ? false : !permission.isDelete,
          ...addButton(permission?.isInsert),
          onRowUpdate: (newData, oldData) => UpdatePacketChild(newData, oldData),
          onRowDelete: (oldData) => DeletePacketChild(oldData)
        }}
      />
    </div>
  </Grid>
}