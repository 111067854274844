import React, { useState, useEffect } from 'react'
import { makeStyles, Select, InputLabel, MenuItem, FormControl } from '@material-ui/core';
import { GETPROCESSFROMDEPARTMENT } from '../../pages/Transaction/DepartmentIsuueReturn/Query'
import { useQuery } from '@apollo/react-hooks'
import _ from 'lodash'
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
  Select: {
    marginTop: "0px !important",
    paddingTop: 0,
    paddingRight: 10
  }
}));
export const ProcessSelect = (props) => {
  const classes = useStyles();
  const [ProcessRenderData, SetProcessRenderData] = useState([])
  const [SelectDisable, SetSelectDisable] = useState(false)

  const { data } = useQuery(GETPROCESSFROMDEPARTMENT, {
    variables: { fromDept: props?.fromDept || null, toDept: props?.toDept || null },
    fetchPolicy: 'cache-and-network',
    skip :!props?.fromDept || !props?.toDept,
  })
  let Data = data?.getProcessPktFromDept || []
  useEffect(() => {
    if (Data.length !== 0 && props.fromDept && props.toDept) {
      let ProcessData = _.map(Data, 'process')
      if (ProcessData.length === 1) {
        props.SetProcessFun(Data[0].process.id)
        props.SetPacketType(Data[0].pktType)
        SetSelectDisable(true)
      } else {
        SetSelectDisable(false)
      }
      SetProcessRenderData(ProcessData)
    }
  }, [Data])

  return < FormControl className={classes.formControl} >
    <InputLabel id="demo-simple-select-label">Process </InputLabel>
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={props.Ids}
      onChange={(data, newValue) => {
        let D = _.filter(Data, (d) => d.process.id === data.target.value)[0]
        props.SetProcessFun(D.process.id)
        props.SetPacketType(D.pktType)
      }}
      disabled={SelectDisable}
    >
      {_.map(ProcessRenderData, (d, key) => {
        return <MenuItem key={key} value={d.id}>{d.procName}</MenuItem>
      })}
    </Select>
  </FormControl >
}
