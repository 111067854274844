import gql from 'graphql-tag';

export const COMPANY_SUBSCRIBE = gql`
  subscription CompanyChange {
    CompanyChange {
      keyType
      data{
        id
        companyName
        shortName
        contactName
        address1
        address2
        address3
        city
        state
        pinCode
        country
        phone1
        phone2
        mobile
        fax
        intercom
        email
        website
        RapnetId
        tanNo
        vatNo
        panNo
        gstTinNo
        cstTinNo
        ckRegNo
        currency
        contactPerson1Name
        mobileNo1
        contactPerson2Name
        mobileNo2
        isActive
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
    }
  }
`;
